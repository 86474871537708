import React from 'react';
import {inject, observer} from "mobx-react";
import ButtonConfirmation from "../../elements/buttonDel";
import Button from "../../elements/button";
import Input from "../../elements/input";
import ButtonLoad from "../../elements/buttonLoad";
import ButtonDel from "../../elements/buttonDel";
import AccountInfoTop from "../../elements/accountInfoTop";

export default
@inject('initialStore')
@observer
class AccountProxyIndex extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        if (initialStore.templates === 'desktop') {
            return <div className="settings">
                        <span className="settings-notifications__user">{this.props.account}</span>
                        <div className="chief__typical h1">
                            <h1>{text[3]}</h1>
                        </div>

                        <div className="settings-proxies">
                            {this.props.status ?
                                <>
                                    {this.props.statusJob.status ?
                                        <span className="settings-proxies__status yes">{text[54]}</span>
                                        :
                                        <span className="settings-proxies__status">{text[55]}</span>
                                    }
                                </>
                            : null}


                            <div className="settings-proxies__form row">
                                <div className="col col-70">
                                    <span className="input-label">{text[56]}</span>
                                    <Input onChange={this.props.ipChange}>{this.props.ip}</Input>
                                </div>
                                <div className="col col-30">
                                    <span className="input-label">{text[57]}</span>
                                    <Input onChange={this.props.portChange}>{this.props.port}</Input>
                                </div>
                                <div className="col col-50">
                                    <span className="input-label">{text[58]}</span>
                                    <Input onChange={this.props.loginChange}>{this.props.login}</Input>
                                </div>
                                <div className="col col-50">
                                    <span className="input-label">{text[59]}</span>
                                    <Input onChange={this.props.passwordChange}>{this.props.password}</Input>
                                </div>
                            </div>

                            {/*{this.props.status ?
                            <div className="settings-proxies__statistic">
                                <span className="title-statistic">Статистика сбоев за месяц</span>
                                {this.props.statusJob.listError.length > 0 ?
                                    <ul className="list-statistic">
                                        <li>
                                            <span>27 Ноября 2019 г.</span>
                                            <span>00:54 — 01:04</span>
                                        </li>
                                    </ul>
                                :
                                    <span>Сбоев не обнаруженно</span>
                                }
                            </div>
                            :null}*/}

                            <div className="more-btn bottom">
                                {this.props.status ?
                                    <ButtonDel action={this.props.delite}>accountProxy</ButtonDel>
                                    :
                                    null
                                }
                                {this.props.statusRed ?
                                    <ButtonLoad action={this.props.save}>{this.props.status ? 'saveChanges' : 'addProxy'}</ButtonLoad>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>;
        } else {
            return <div className="settings">

                <AccountInfoTop
                    type={this.props.typeAccount}
                    name={this.props.account}
                    img={this.props.imgAccount}
                />

                <div className="settings-proxies" style={{marginTop: '16px'}}>
                    {this.props.status ?
                        <>
                            {this.props.statusJob.status ?
                                <span className="settings-proxies__status yes">{text[54]}</span>
                                :
                                <span className="settings-proxies__status">{text[55]}</span>
                            }
                        </>
                        : null}


                    <div className="settings-proxies__form row">
                        <div className="col col-70">
                            <span className="input-label">{text[56]}</span>
                            <Input onChange={this.props.ipChange}>{this.props.ip}</Input>
                        </div>
                        <div className="col col-30">
                            <span className="input-label">{text[57]}</span>
                            <Input onChange={this.props.portChange}>{this.props.port}</Input>
                        </div>
                        <div className="col col-50">
                            <span className="input-label">{text[58]}</span>
                            <Input onChange={this.props.loginChange}>{this.props.login}</Input>
                        </div>
                        <div className="col col-50">
                            <span className="input-label">{text[59]}</span>
                            <Input onChange={this.props.passwordChange}>{this.props.password}</Input>
                        </div>
                    </div>

                    {/*{this.props.status ?
                        <div className="settings-proxies__statistic">
                            <span className="title-statistic">Статистика сбоев за месяц</span>
                            {this.props.statusJob.listError.length > 0 ?
                                <ul className="list-statistic">
                                    <li>
                                        <span>27 Ноября 2019 г.</span>
                                        <span>00:54 — 01:04</span>
                                    </li>
                                </ul>
                                :
                                <span>Сбоев не обнаруженно</span>
                            }
                        </div>
                        :null}*/}

                    <div className="more-btn bottom">
                        {this.props.status ?
                            <ButtonDel action={this.props.delite}>accountProxy</ButtonDel>
                            :
                            null
                        }
                        {this.props.statusRed ?
                            <ButtonLoad action={this.props.save}>{this.props.status ? 'saveChanges' : 'addProxy'}</ButtonLoad>
                            :
                            null
                        }
                    </div>
                </div>
            </div>;
        }

    }
}