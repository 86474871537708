import React from 'react';
import {inject, observer} from "mobx-react";

export default
@inject('menuStore')
@inject('accountsStore')
@inject('initialStore')
@observer
class AccountDirectUsers extends React.Component {

    constructor(props) {
        super(props);

        this.scroll = this.scroll.bind(this);

        this.activeAccount=false;

    }

    render() {
        const initialStore = this.props.initialStore;

        return (
            <div ref={(e) =>{this.props.AddconteynerRef(e)}} onScroll={this.scroll} className="chat-users">
                {this.props.children}
            </div>
        );
    }

    scroll (event) {
        let element = event.target;

        if (element.scrollHeight - element.scrollTop < (element.clientHeight+60)) {
            this.startCursorLoad();
        }
    }

    startCursorLoad = () => {
        if (this.props.cursor) {
            if (this.props.cursor !== 'load') {
                this.props.cursorLoad();
            }
        }
    }

}