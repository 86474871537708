import React from 'react';
import HeaderBoxLiElement from "../../templates/header/boxLIElement";
import HeaderBox from "../../templates/header/box";
import {inject, observer} from "mobx-react";

@inject('initialStore')
@observer
class HeaderSettings extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.settings;

        let activeElementName = text.basicSettings;

        if (this.props.location.pathname === '/settings/security') {
            activeElementName = text.safety;
        } else if (this.props.location.pathname === '/settings/notifications/offline' || this.props.location.pathname === '/settings/notifications/online') {
            activeElementName = text.notifications;
        } else if (this.props.location.pathname === '/settings/groups') {
            activeElementName = text.accountGroups;
        } else if (this.props.location.pathname === '/settings/referrals') {
            activeElementName = text.affiliateProgram;
        }

        return <HeaderBox activeElementName={activeElementName}>
                    <HeaderBoxLiElement
                        url={'/settings'}
                        active={this.props.location.pathname === '/settings' ? true: false}
                        name={text.basicSettings}/>
                    <HeaderBoxLiElement
                        url={'/settings/security'}
                        active={this.props.location.pathname === '/settings/security' ? true: false}
                        name={text.safety}/>
                    <HeaderBoxLiElement
                        url={'/settings/notifications/offline'}
                        active={this.props.location.pathname === '/settings/notifications/offline' || this.props.location.pathname === '/settings/notifications/online' ? true: false}
                        name={text.notifications}/>
                    <HeaderBoxLiElement
                        url={'/settings/groups'}
                        active={this.props.location.pathname === '/settings/groups' ? true: false}
                        name={text.accountGroups}/>
                    <HeaderBoxLiElement
                        url={'/settings/referrals'}
                        active={this.props.location.pathname === '/settings/referrals' ? true: false}
                        name={text.affiliateProgram}/>
                </HeaderBox>;
    }

}

export default HeaderSettings;