import React from 'react';
import {inject, observer} from "mobx-react";
import {getGeo, getGeoNew} from "../../../actions/account/geo";
import {observable} from "mobx";


@inject('mediaStore', 'initialStore', 'accountsStore')
@observer
class GeoSearh extends React.Component {

    @observable focusInput = false;

    constructor (props) {
        super(props);
        this.input = React.createRef();
        this.inputOnChange = this.inputOnChange.bind(this);
        this.arrayObject = this.arrayObject.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        const mediaStore = this.props.mediaStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;




        let render = null;
        if (!mediaStore.geo) {
            return render;
        }

        let poleName = false;
        let poleNameActive = false
        let openSearh = false;

        const accoint = this.props.accoint;
        let geo = mediaStore.geoObject;

        if (accoint) {
            for (let geoObjectFullElement of mediaStore.geoObjectFull.slice()) {
                if (geoObjectFullElement.idAccount === accoint) {
                    geo = geoObjectFullElement;
                    break;
                }
            }
        }



        if (this.focusInput) {
            render = (
                <div className="event-search">
                    <div className={geo.load ? "chat-search is-active btn-primary-animate-content" : "chat-search is-active"}>
                        <span className="chat-search__icon">
                            <i className="icon form-control-geo-icon-input"></i>
                        </span>
                        <div className="chat-search__input">
                            <input placeholder={text[84]} autoFocus value={geo.search} onChange={this.inputOnChange} onBlur={this.onBlur}/>
                        </div>
                        <span className="chat-search__remove" onMouseDown={this.actionDeliteGeo}>
                            <i className="icon icon-calendar-clear"></i>
                        </span>
                    </div>

                    {geo.array.length ?
                        <div className="event-search__result">
                            <div className="inner-result">
                                {this.arrayObject()}
                            </div>
                        </div>
                    :null}
                </div>

            );

            openSearh = true;
        } else {
            if (geo.id === false) {

                render = null;
                poleName = text[85];

            } else {
                render = null;
                poleName = geo.array[geo.id].name;
                poleNameActive = true;
            }
        }
        return (

            <div className={openSearh ? "event-drop dropdown is-open" : "event-drop dropdown"}>
                <div className={poleNameActive ? "form-control form-control-geo-icon-active-geo" : "form-control form-control-geo-icon"} onClick={this.onFocus}>{poleName}</div>
                {render}
            </div>
        );
    }
    arrayObject () {
        const mediaStore = this.props.mediaStore;

        const accoint = this.props.accoint;
        let geo = mediaStore.geoObject;

        if (accoint) {
            for (let geoObjectFullElement of mediaStore.geoObjectFull.slice()) {
                if (geoObjectFullElement.idAccount === accoint) {
                    geo = geoObjectFullElement;
                    break;
                }
            }
        }

        let array = geo.array.map((value, key) => {
            return (
                <span className="item-result" onMouseDown={() => {this.onClick(key)}}>{value.name}<br/><span className="item-result-adress">{value.address}</span></span>
            );
        });
        return array;
    }
    arrayObjectHash () {

        const mediaStore = this.props.mediaStore;
        const accountsStore = this.props.accountsStore;
        const accoint = this.props.accoint;

        const accountsOK = mediaStore.accounts.slice();
        const accounts = accountsStore.list.slice();



        let geoLists = [];
        for (let indexGeo = 0; indexGeo< 10; indexGeo ++) {
            for (let key in accountsOK) {
                for (let keys in accounts) {
                    if (accountsOK[key].id === accounts[keys].id) {
                        if (accounts[keys].tip === "instagram") {
                            if (accounts[keys].hash_geo.length >= indexGeo+1) {
                                if (accoint) {
                                    if (accounts[keys].id === accoint) {
                                        geoLists.push(accounts[keys].hash_geo[indexGeo]);
                                    }
                                } else {
                                    geoLists.push(accounts[keys].hash_geo[indexGeo]);
                                }
                            }
                        }
                    }
                }
            }
        }

        let array = [];
        let max = 0;
        for (let geoListID in geoLists ) {

            max++;
            if (max >= 10) break;

            let copy = false;
            for (let arrayKey in array) {
                if (array[arrayKey].name === geoLists[geoListID].name) {
                    copy = true;
                    break;
                }
            }

            if (!copy) {
                array.push({
                    lat: geoLists[geoListID].lat,
                    lng: geoLists[geoListID].lng,
                    name: geoLists[geoListID].name,
                    address: geoLists[geoListID].address,
                    id: geoLists[geoListID].facebook_places_id,
                });
            }
        }

        if (accoint) {
            for (let geoObjectFullElementKey in mediaStore.geoObjectFull.slice()) {
                if (mediaStore.geoObjectFull[geoObjectFullElementKey].idAccount === accoint) {
                    mediaStore.geoObjectFull[geoObjectFullElementKey].array = array;
                    break;
                }
            }
        } else {
            mediaStore.geoObject.array = array;
        }

        return array;
    }

    actionDeliteGeo = () => {
        const mediaStore = this.props.mediaStore;

        const accoint = this.props.accoint;

        if (!accoint) {
            mediaStore.geoObject.id = false;
            mediaStore.geoObject.search = '';
            mediaStore.geoObject.array = [];
        } else {
            const newGeoObjectFull = [];
            for (let geoObjectFullElement of mediaStore.geoObjectFull.slice()) {
                if (geoObjectFullElement.idAccount === accoint) {
                    newGeoObjectFull.push({
                        ...geoObjectFullElement,
                        id: false,
                        search: '',
                        array: [],
                    });
                } else {
                    newGeoObjectFull.push({...geoObjectFullElement});
                }
            }

            mediaStore.geoObjectFull = newGeoObjectFull;
        }

    }

    onClick (id) {
        const mediaStore = this.props.mediaStore;
        const accoint = this.props.accoint;
        mediaStore.actionGeoObjectIdTrue(id, accoint);
    }
    onFocus () {

        const mediaStore = this.props.mediaStore;
        let geo = mediaStore.geoObject;

        if (geo.search === '') {
            this.arrayObjectHash();
        }


        this.focusInput = true;
    }
    onBlur () {
        this.focusInput = false;
    }
    inputOnChange (e) {

        const mediaStore = this.props.mediaStore;
        const value = e.target.value;


        const accoint = this.props.accoint;

        mediaStore.actionGeoObjectSearch(value, accoint);

        if (!accoint) {
            if (mediaStore.geoObject.id !== false) {
                if (value != mediaStore.geoObject.array[mediaStore.geoObject.id].name) {
                    mediaStore.actionGeoObjectId(false, accoint);
                }
            }

        } else {
            const newGeoObjectFull = [];
            for (let geoObjectFullElement of mediaStore.geoObjectFull.slice()) {
                if (geoObjectFullElement.idAccount === accoint) {
                    let id = geoObjectFullElement.id;
                    if (geoObjectFullElement.id !== false) {
                        id = false;
                    }
                    newGeoObjectFull.push({
                        ...geoObjectFullElement,
                        id: id,
                    });
                } else {
                    newGeoObjectFull.push({...geoObjectFullElement});
                }
            }
            mediaStore.geoObjectFull = newGeoObjectFull;


        }

        getGeoNew(accoint);

        if (value === '') {
            this.arrayObjectHash();
        }
    }
}
export default GeoSearh;
