import React from 'react';
import {inject, observer} from "mobx-react";

import Input from "../elements/input";
import ReactDOM from "react-dom";
import iconEdit from "../../media/svg/icons-edit-2.svg";
import iconDel from "../../media/svg/icons-del.svg";
import iconDrop from "../../media/svg/media-draggable.svg";
import {observable} from "mobx";

export default
@inject('initialStore', 'mediaStore')
@observer
class PostTemplateMediaIconsDraggable extends React.Component {

    @observable open = false;

    constructor(props) {
        super(props);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.window = React.createRef();

        this.openWin = false;
    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return(
                <div className="events-download">
                    <span className="event-download edit" onClick={this.props.editorOpen}>
                        <img src={iconEdit}/>
                    </span>
                    <span className="event-download remove" onClick={this.props.DelElement}>
                        <img src={iconDel}/>
                    </span>
                    {this.props.DragHandle}
                </div>
            );
        } else {

            if (!this.open) {
                if (this.openWin) {
                    this.openWin=false;

                    this.updateStatusCloseFon();
                }
            } else {
                if (!this.openWin) {
                    this.openWin=true;
                    this.updateStatusCloseFon();
                }
            }

            if (!this.open) {
                return(
                    <div className="events-download events-download-mobile no-fastclick" onTouchStart={e => e.stopPropagation()} onTouchEnd={e => this.openElementsEvent(e)}>

                    </div>
                );
            } else {
                return(
                    <div className="events-download events-download-mobile" ref={this.window}>
                        <span className="event-download edit" onClick={this.props.editorOpen}>
                            <img src={iconEdit}/>
                        </span>
                        <span className="event-download remove" onClick={this.props.DelElement}>
                            <img src={iconDel}/>
                        </span>
                        {this.props.DragHandle}
                    </div>
                );
            }
        }

    }

    updateStatusCloseFon = () => {



        if (this.open) {
            document.addEventListener('touchend', this.handleClickOutside, false);
        } else {
            document.removeEventListener('touchend', this.handleClickOutside, false);
        }
    }

    openElementsEvent = (e) => {
        if (!this.open) {
            this.open = true;
        }

        if (document.activeElement) {
            if (document.activeElement.localName === 'textarea' || document.activeElement.localName === 'input') {
                document.activeElement.blur();
            }
        }
        e.stopPropagation();
    }

    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент

            if (!node.contains(e.target)) {
                // Если клик не производился и на кнопку открытия окна смайлов, то скрываем блок.
                this.open = false;


            }

        } catch(error) {
            return null;
        }
    }


}

