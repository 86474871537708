import React from 'react';
import {inject, observer} from "mobx-react";

import ElementMenuCreatePostButtonMobile from "../../templates/elements/ElementMenuCreatePost/buttonMobile";
import Time from "../../model/time";

@inject("menuStore", "mediaStore", "accountsStore")
@observer
class MenuCreatePostButtonMobile extends React.Component {

    render() {

        return <ElementMenuCreatePostButtonMobile open={this.open} />;
    }

    open = () => {
        const menuStore = this.props.menuStore;
        const mediaStore = this.props.mediaStore;

        if (mediaStore.collapsed) {
            this.openPost();
        } else {
            menuStore.openMenuCreatePost = true;
        }

    }

    openPost = () => {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        //выставляем аккаунт который выбран
        if (mediaStore.accounts.length === 0 && accountsStore.active !== null) {

            const time = Time.dateTimetableGenerator(accountsStore.list[accountsStore.active].id, 'post');

            mediaStore.actionAccount(accountsStore.list[accountsStore.active].id, time[0]);

        }

        this.props.mediaStore.actionOpen(true);
    }

}

export default MenuCreatePostButtonMobile;