import React from 'react';
import {inject, observer} from "mobx-react";
import NotificationItem from "../templates/elements/notificationItem";

import souds from "../media/sound/notifikation.mp3"

export default
@inject('notificationStore')
@observer
class Notification extends React.Component {

    constructor(props) {
        super(props);

        setInterval(() => {
            const notificationStore = this.props.notificationStore;
            const notificationStoreElements = notificationStore.elements.slice();

            for (const elementKey in notificationStoreElements) {

                if (elementKey > 2) break;

                //const elementsBD  = this.props.notificationStore.elements.slice();
                //if (elementKey < elementsBD.length ) {

                const onlineTime = new Date()/1000;

                if (notificationStoreElements[elementKey].sekStart === 0) {
                    notificationStoreElements[elementKey].sekStart = onlineTime;
                }

                const finish = notificationStoreElements[elementKey].sekStart + notificationStoreElements[elementKey].sekEnd;

                if (finish <= onlineTime) {
                    notificationStore.del(elementKey);
                }
                /*this.props.notificationStore.elements[elementKey].sekStart = this.props.notificationStore.elements[elementKey].sekStart + 1;

                if (notificationStoreElements[elementKey].sekStart >=  notificationStoreElements[elementKey].sekEnd) {

                    console.log(elementKey);
                }*/
                //}

            }
        }, 1000);
    }

    render() {
        const notificationStore = this.props.notificationStore;
        const notificationStoreElements = notificationStore.elements.slice();
        const elements = [];

        let sound = false;

        for (const elementKey in notificationStoreElements) {

            if (elementKey > 2) break;

            elements.push(
                <NotificationItem
                    key={elementKey}
                    name={notificationStoreElements[elementKey].name}
                    text={notificationStoreElements[elementKey].text}
                    buttonName={notificationStoreElements[elementKey].buttonName}
                    buttonClick={() => {
                        notificationStoreElements[elementKey].buttonClick();
                        notificationStore.del(elementKey);
                    }}
                    delFunction={() => {notificationStore.del(elementKey)}}
                />
            );
            if (notificationStoreElements[elementKey].sekStart === 0) {
                sound=true;
            }
        }

        /*if (sound) {
            const audio = new Audio();
            audio.preload = 'auto';
            audio.src = souds;
            audio.play();
        }*/

        return elements;
    }
}