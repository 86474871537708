import {action, observable} from "mobx";

class modalSearhUrlPost {

    @observable open = false;


    @action openActive = () => {
        this.open = true;
    }
}

export default modalSearhUrlPost;