import APIposts from "../../api/posts";

import mainStore from "../../stor/mainStore";
import Time from "../../model/time";
import APIfile from "../../api/file";
import loadFileAndBloobUrl from "../../model/loadFileAndBloobUrl";
import file, {getIconImage, getIconVideo} from "../../model/file";
import loadFileObrabotchik from "../../model/loadFileObrabotchik";


export function createLoadPost(data, actionAccount = true) {
    const mediaStore = mainStore.mediaStore;
    const accountsStore = mainStore.accountsStore;

    //меняем тип на редактор
    mediaStore.actionEditorType(data.type);

    //выставляем аккаунт который выбран
    //if (actionAccount) {
        if (mediaStore.accounts.length === 0 && accountsStore.active !== null) {
            const time = Time.dateTimetableGenerator(accountsStore.list[accountsStore.active].id, data.type);

            mediaStore.actionAccount(accountsStore.list[accountsStore.active].id, time[0]);





            mediaStore.actionText(0, data.text);
        }

        mediaStore.actionTextSavePost(data.text);
    //}

    //комментарий
    if (data.comment !== false && !data.commentOff) {
        mediaStore.actionComment(true);
        mediaStore.actionCommentText(data.comment);
    }

    //отключение комментария
    if (data.commentOff) {
        mediaStore.actionCommentOff(data.commentOff);
    }

    //mediaStore.actionMediaLoading(data.media);

    //категория
    mediaStore.category = data.category;

    mediaStore.name = data.name;

    mediaStore.open = true;


    if (data.media.length) {

        loadFileObrabotchik(data.media, true);
    }
}
