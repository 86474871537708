import qs from "qs";
import React from "react";

import instance from "./base";


const APIpatterns = {

    getPatterns (hash) {
        return instance.post('patterns', qs.stringify({
            hesh:hash,
        })).then(data => {
            return data;
        });
    },

    getPatternsAdd (account, name, text) {
        return instance.post('patterns/add', qs.stringify({
            account:account,
            name:name,
            text:text,
        })).then(data => {
            return data;
        });
    },

    getPatternsRed (id, account, name, text) {
        return instance.post('patterns/red', qs.stringify({
            id: id,
            account:account,
            name:name,
            text:text,
        })).then(data => {
            return data;
        });
    },

    getPatternsDel (id) {
        return instance.post('patterns/del', qs.stringify({
            id: id,
        })).then(data => {
            return data;
        });
    },



}

export default APIpatterns;