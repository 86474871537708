import qs from "qs";
import React from "react";

import instance from "./base";


const APIaccountsOk = {

    getOKUrl () {
        return instance.post('/accountsok/ok_url', qs.stringify({

        })).then(data => {
            return data;
        });
    },
}

export default APIaccountsOk;