import React from 'react';
import {inject, observer} from "mobx-react";
import ButtonConfirmation from "../../elements/buttonDel";
import Button from "../../elements/button";
import Input from "../../elements/input";
import ButtonLoad from "../../elements/buttonLoad";
import ButtonDel from "../../elements/buttonDel";
import AccountInfoTop from "../../elements/accountInfoTop";

export default
@inject('initialStore')
@observer
class AccountTimetableIndex extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        if (initialStore.templates === 'desktop') {
            return <div className="settings">
                <span className="settings-notifications__user">{this.props.account}</span>
                <div className="chief__typical h1 flex">
                    <h1>{text[1]}</h1>
                </div>

                <p className="settings__help">
                    {text[22]}
                </p>

                {this.props.filter}

                <div className="schedule">
                    {this.props.children}
                </div>
            </div>;
        } else {
            return <div className="settings">

                <AccountInfoTop
                    type={this.props.typeAccount}
                    name={this.props.account}
                    img={this.props.imgAccount}
                />

                <p className="settings__help">
                    {text[22]}
                </p>

                {this.props.filter}

                <div className="schedule">
                    {this.props.children}
                </div>
            </div>;
        }

    }
}

