import React from 'react';
import {inject, observer} from "mobx-react";

import Input from "../elements/input";
import ReactDOM from "react-dom";
import {observable} from "mobx";
import Select from "../elements/select";

export default
@inject('initialStore', 'mediaStore')
@observer
class PostTemplateEditor extends React.Component {

    @observable selectValue = 'size';

    @observable openWindow = false;

    @observable loadSave = false;

    constructor(props) {
        super(props);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.window = React.createRef();
    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        let classButtonSave = 'btn btn-primary';
        if (this.loadSave) {
            classButtonSave = 'btn btn-primary btn-primary-animate';
        }

        if (initialStore.templates === 'desktop') {

            let classElement = "info-wrap info-scroll-animation info-scroll-close";
            let classElement3 = "info-overlay info-overlay-animation";

            if (this.openWindow) {
                classElement = "info-wrap info-scroll-animation info-scroll-open";
                classElement3 = "info-overlay info-overlay-animation info-overlay-animation-finish";

            }

            return(
                <>
                    <div className={classElement}>
                        <div className="info-scroll">
                            <div className="info-box-wrap">
                                <div className="info-box chief width-5" ref={this.props.refWindow}>

                                    <div className="editor">

                                        {this.props.componentOpen}

                                        <div className="editor-type">

                                            <div className={this.props.elementOpen === 'size' || this.props.elementOpen === 'crop' ? "item-type is-checked":"item-type"} onClick={this.props.onSize}>
                                                <div className="label-type"><span>{text[103]}</span></div>
                                            </div>

                                            {this.props.type === 'video' ? <>
                                                <div className={this.props.elementOpen === 'cover' ? "item-type is-checked":"item-type"} onClick={this.props.onCover}>
                                                    <div className="label-type"><span>{text[104]}</span></div>
                                                </div>
                                                <div className={this.props.elementOpen === 'duration' ? "item-type is-checked":"item-type"} onClick={this.props.onDuration}>
                                                    <div className="label-type"><span>{text[105]}</span></div>
                                                </div>
                                            </>:null}

                                            <div className={this.props.elementOpen === 'red' ? "item-type is-checked":"item-type"} onClick={this.props.onRed}>
                                                <div className="label-type"><span>{text[106]}</span></div>
                                            </div>

                                            {this.props.otmetitPolzovateley ?
                                                <div className={this.props.elementOpen === 'users' ? "item-type is-checked":"item-type"} onClick={this.props.onUsers}>
                                                    <div className="label-type"><span>{text[107]}</span></div>
                                                </div>
                                                :null}

                                            {/*<div className="item-type">
                                                <div className="label-type"><span>Настройки</span></div>
                                            </div>*/}
                                        </div>


                                    </div>

                                    <div className="more-btn btns-box right">
                                        <button type="button" className="btn border btn-primary" onClick={this.close}>{text[108]}</button>
                                        <button type="button" className={classButtonSave} onClick={this.save}>{text[109]}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classElement3}></div>
                </>
            );
        } else {

            let classElement = "info-wrap";
            let classElement2 = "info-box chief width-5";
            let classElement3 = "info-overlay";
            let classElement4 = "info-scroll";
            let classElementPhoneBar = "top-bar-phone";
            if (this.props.initialStore.width <= 500) {

                classElement2 = "info-box info-boxW chief width-5";

                classElement4 = "info-scroll info-scroll-wite top-bar-phone-padding";

                //основное окно
                if (this.openWindow) {
                    classElement = "info-wrap editor-wrap info-scroll-animation info-scroll-open";
                    classElement3 = "info-overlay info-overlayW info-scroll-animation info-scroll-open";
                    classElementPhoneBar = "top-bar-phone top-bar-phone-open";
                } else {
                    classElement = "info-wrap editor-wrap info-scroll-animation info-scroll-close";
                    classElement3 = "info-overlay info-overlayW info-scroll-animation info-scroll-close";
                    classElementPhoneBar = "top-bar-phone top-bar-phone-close";
                }

            }

            const options = [
                {key: 'size', value: text[103]}
            ];

            if (this.props.type === 'video') {
                options.push({key: 'cover', value: text[104]});
                options.push({key: 'duration', value: text[105]});

            }

            options.push({key: 'red', value: text[106]});

            if (this.props.type === 'image' && this.props.otmetitPolzovateley) {

            }

            options.push({key: 'users', value: text[107]});

            return(
                <>
                    <div className={classElementPhoneBar}></div>
                    <div className={classElement}>
                        <div className={classElement4}>
                            <div className="info-box-wrap">
                                <div className={classElement2} ref={this.props.refWindow}>

                                    <div className="editor">

                                        {this.props.componentOpen}

                                        <div className="editor-type">

                                            <Select
                                                top
                                                nonTopValueElement
                                                className="editor-type-mobile-type-red"
                                                viewElementsList={10}
                                                action={(val) => this.onChangeSelect(val, false)}
                                                options={options}>{this.selectValue}
                                            </Select>
                                        </div>

                                    </div>

                                    <div className="more-btn btns-box right">
                                        <button type="button" className="btn border btn-primary" onClick={this.close}>{text[108]}</button>
                                        <button type="button" className={classButtonSave} onClick={this.save}>{text[109]}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classElement3}></div>
                </>
            );

        }

    }

    save = () => {
        if (!this.loadSave) {
            this.loadSave = true;
            this.props.save();
        }

    }

    close = () => {
        this.openWindow = false;
        setTimeout(() => {
            this.props.closeEditor();
        }, 250);
    }

    componentDidMount() {
        if (!this.openWindow) {
            setTimeout(() => {
                this.openWindow = true;
            }, 1);
        }
    }

    onChangeSelect = (event, target = true) => {

        const value = target ? event.target.value : event;

        if (value === 'size') {
            this.props.onSize();
            this.selectValue = 'size';
        } else if (value === 'cover') {
            this.props.onCover();
            this.selectValue = 'cover';
        } else if (value === 'duration') {
            this.props.onDuration();
            this.selectValue = 'duration';
        } else if (value === 'red') {
            this.props.onRed();
            this.selectValue = 'red';
        } else if (value === 'users') {
            this.props.onUsers();
            this.selectValue = 'users';
        }
    }

    updateStatusCloseFon = () => {
        const mediaStore = this.props.mediaStore;

        if (mediaStore.openMenuMobile) {
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент

            if (!node.contains(e.target)) {
                // Если клик не производился и на кнопку открытия окна смайлов, то скрываем блок.
                const mediaStore = this.props.mediaStore;

                mediaStore.openMenuMobile = false;
            }
        } catch(error) {
            return null;
        }
    }
}
