import React from 'react';

import APIdirect from "../api/direct";

import mainStore from "../stor/mainStore";
import {autorun} from "mobx";
import Time from "./time";
import souds from "../media/sound/notifikation.mp3";


const timeStartDirect = Time.getUnix();

let redirect = false;

export function directLoop( redirectAdd ) {

    redirect = redirectAdd;
    setTimeout(loop, 2000);

    let actives = [];
    autorun(() => {
        const accountsStore = mainStore.accountsStore;

        for (const account of accountsStore.list.slice()) {

            if (actives.length >= account.id) {
                if (actives[account.id] != account.direct.account.active) {
                    if (account.direct.account.active !== false) {
                        loop(false);
                    }
                }
            }

            actives[account.id] = account.direct.account.active;
        }
    });
}

function loop(reStart = true) {

    const accountsStore = mainStore.accountsStore;
    const notificationStore = mainStore.notificationStore;

    let data = [];
    for (const account of accountsStore.list.slice()) {

        if (account.proxyInstall && account.rights.direct) {

            let dialogs = [];

            for (const element of account.direct.account.list.slice()) {
                dialogs.push({
                    id: element.thread_id,
                    hash: element.posts.hash,
                });
            }


            data.push({
                id: account.id,
                dialogs: dialogs,
                hash: account.direct.account.hash,
            });
        }
    }

    if (data.length) {
        APIdirect.index(data).then((array) => {

            if (array.status) {

                //

                for (const account of array.account) {

                    let idAccount = false;
                    for (const accountListID in accountsStore.list.slice()) {
                        if (accountsStore.list[accountListID].id === account.id) {
                            idAccount = accountListID;
                        }
                    }
                    if (idAccount === false) continue;


                    accountsStore.list[idAccount].direct.status = account.status;
                    accountsStore.list[idAccount].direct.info = account.info;

                    if (!account.info && account.status) {
                        accountsStore.updateDirectList(idAccount, account.dialogs.list, account.dialogs.hash, account.dialogs.cursor, account.viewer);

                        for (const item of account.items) {

                            //система информирования
                            if (item.list) {
                                for (const itemsNew of item.list) {

                                    //если время сообщения до время инициализации программ то игнорим
                                    if (itemsNew.timestamp < timeStartDirect) continue;

                                    //если сообщения от самого себя то пропускаем
                                    if (itemsNew.user_id === accountsStore.list[idAccount].direct.viewer.user_id) continue;

                                    const listDialogs = accountsStore.list[idAccount].direct.account.list.slice();

                                    let IdDialog = false;

                                    for (const dailogSearhID in listDialogs) {
                                        if (item.thread_id === listDialogs[dailogSearhID].thread_id) {
                                            IdDialog = dailogSearhID;
                                            continue;
                                        }
                                    }

                                    if (IdDialog === false) return false;

                                    const listMessageHistory = accountsStore.list[idAccount].direct.account.list[IdDialog].posts.list.slice();

                                    let yesNotification = true;

                                    if (listMessageHistory) {
                                        for (const listMessageHistoryElement of listMessageHistory) {
                                            console.log(itemsNew);
                                            if (listMessageHistoryElement.item_id === itemsNew.item_id) {
                                                yesNotification = false;
                                            }

                                        }
                                    }

                                    if (yesNotification) {
                                        console.log(accountsStore.list[idAccount].direct.account.list[IdDialog]);
                                        console.log(itemsNew);

                                        let text = null;
                                        if (itemsNew.item_type === 'text') {
                                            text = <><br/><b>Сообщение:</b> {itemsNew.item_info.text}</>
                                        }

                                        let nameDialog = '';
                                        let usersDialog = accountsStore.list[idAccount].direct.account.list[IdDialog].user;
                                        for (const user of usersDialog) {
                                            nameDialog = nameDialog === '' ? user.username : nameDialog + ' ' + user.username;
                                        }

                                        const message = <>
                                            <b>Аккаунт:</b> {accountsStore.list[idAccount].name}<br/>
                                            <b>Диалог:</b> {nameDialog}
                                            {text}
                                            </>;

                                        const openDialog = () => {
                                            //перенаправляем пользователя на страницу директа
                                            redirect(`/account/${accountsStore.list[idAccount].id}/direct`);
                                            const thread_id = mainStore.accountsStore.list[idAccount].direct.account.list[IdDialog].thread_id;
                                            //устанавливаем активный директ который надо
                                            mainStore.accountsStore.list[idAccount].direct.account.active = thread_id;
                                        }

                                        const initialStore = mainStore.initialStore;
                                        if (initialStore.notification) {
                                            if (initialStore.notification[1].win) {
                                                notificationStore.add('Новое сообщение', message, 10, 'Открыть диалог', openDialog);
                                            }

                                            //Воспроизводим звук
                                            if (initialStore.notification[1].sound) {
                                                const audio = new Audio();
                                                audio.preload = 'auto';
                                                audio.src = souds;
                                                audio.play();
                                            }
                                        }





                                    }

                                }

                            }

                            accountsStore.updateDirectListPost(idAccount, item.thread_id, item.list, item.hash, item.cursor);
                        }
                    }
                }
            } else {

            }
            if (reStart) {
                setTimeout(loop, 3000);
            }

        }).catch((error) => {
            console.log(error);
            if (reStart) {
                setTimeout(loop, 3000);
            }
        });

    } else {
        if (reStart) {
            setTimeout(loop, 3000);
        }
    }


}