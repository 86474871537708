import React from 'react';
import {inject, observer} from "mobx-react";
import ReactDOM from "react-dom";

import ButtonLoad from "../../templates/elements/buttonLoad";
import APIaccounts from "../../api/accounts";
import APIuser from "../../api/user";
import Input from "../../templates/elements/input";
import Select from "../../templates/elements/select";
import APIposts from "../../api/posts";
import mainStore from "../../stor/mainStore";

@inject("notificationStore", "redPostMiniStore", "accountsStore")
@observer
class ModalRedPostTime extends React.Component {

    constructor (props) {
        super(props);
    }

    render() {
        const redPostMiniStore = this.props.redPostMiniStore;

        if (!redPostMiniStore.open) {
            return null;
        }

        const tip = redPostMiniStore.tip;
        const status = redPostMiniStore.status;
        const int = redPostMiniStore.int;


        const content = <div className="delMediaFile">
            <div className="modal-inner">
                <span className="title-modal">Редактирование поста</span>

                <div className="delite-post">
                    <div className="delite-post-box-type">
                        <div className="delite-post-box-type-w-100-1">
                            <div className="delite-post-box-type-w-50">
                                <button style={{width: '95%'}} type="button" className={status === 1 ? "btn btn-primary" : "btn border btn-primary"} onClick={this.del}>Удаление</button>
                            </div>
                            <div className="delite-post-box-type-w-50">
                                <button style={{width: '95%', padding: '12px 0px 12px'}} type="button" className={status === 2 ? "btn btn-primary" : "btn border btn-primary"} onClick={this.arhive}>Архивирование</button>
                            </div>
                        </div>
                        <div className="delite-post-box-type-w-100-2">
                            <button type="button" style={{width: '98%', padding: '12px 0px 12px'}} className={status === 0 ? "btn btn-primary" : "btn border btn-primary"} onClick={this.non}>Отменить Удаление/Архивирование</button>
                        </div>
                    </div>

                    {!status ?
                        <div className="elite-post-box-info-none">
                            Удаление или Архивирование<br/>будет отменено
                        </div>

                        :

                        <div className="elite-post-box-info">

                            <div className="elite-post-box-info-e1">
                                <span className="title">Количество</span>
                                <Input onChange={this.intAction}>{int}</Input>
                            </div>

                            <div className="elite-post-box-info-e2">
                                <span className="title">Тип измерения</span>
                                <Select
                                    top
                                    nonTopValueElement
                                    action={this.selectAction}
                                    options={[
                                        {key: 0, value: 'Минут'},
                                        {key: 1, value: 'Часов'},
                                        {key: 2, value: 'Дней'},
                                        {key: 3, value: 'Недель'},
                                    ]}>{tip}</Select>
                            </div>

                        </div>
                    }

                </div>

                <div className="more-btn">
                    <button type="button" className="btn border btn-primary" onClick={this.close}>Отмена</button>
                    <ButtonLoad type="button" className="btn btn-primary" action={this.ok} color>savePost</ButtonLoad>
                </div>
            </div>
        </div>;

        let render = <>
                <div className="info-wrap">
                    <div className="info-scroll">
                        <div className="info-box-wrap">
                            <div className="info-box chief width-4">
                                <span onClick={this.close} className="close-box"><i className="icon icon-close-info"></i></span>
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-overlay"></div>
            </>;

        return render;
    }

    ok = (promise) => {
        const redPostMiniStore = this.props.redPostMiniStore;
        const notificationStore = this.props.notificationStore;
        const accountsStore = this.props.accountsStore;

        APIposts.getEditorMini(redPostMiniStore.id, redPostMiniStore.code, redPostMiniStore.status, redPostMiniStore.tip, redPostMiniStore.int).then((data) => {

            const idAccount = accountsStore.active;
            const idAccountReal = accountsStore.list[idAccount].id;
            const idAccountRealHashPost = accountsStore.list[idAccount].post.hash;
            const idAccountRealHashStory = accountsStore.list[idAccount].story.hash;

            accountsStore.getPostLoad(idAccount);
            accountsStore.getStoryLoad(idAccount);

            APIposts.getPosts(idAccountReal, idAccountRealHashPost, idAccountRealHashStory).then((data) => {

                accountsStore.getPost(idAccount, data.hashPosts, data.posts, data.rate);
                accountsStore.getStory(idAccount, data.hashStory, data.story);

                notificationStore.add('Пост отредактирован');

                redPostMiniStore.open = false;
                promise(true);
            }).catch((error) => {
                redPostMiniStore.open = false;
                promise(true);
            });



        }).catch(() => {
            notificationStore.add('Ошибка', 'Произошла ошибка, повториет через пару минут.');
            promise(true);
        });

    }

    selectAction = (value) => {
        const redPostMiniStore = this.props.redPostMiniStore;
        redPostMiniStore.tip = value;
    }

    intAction = (value) => {
        const redPostMiniStore = this.props.redPostMiniStore;
        let int = redPostMiniStore.int;

        int = /\d+/.test(Number(value)) ? value : int;

        redPostMiniStore.int = int;
    }

    del = () => {
        const redPostMiniStore = this.props.redPostMiniStore;
        redPostMiniStore.status = 1;
    }
    arhive = () => {
        const redPostMiniStore = this.props.redPostMiniStore;
        redPostMiniStore.status = 2;
    }
    non = () => {
        const redPostMiniStore = this.props.redPostMiniStore;
        redPostMiniStore.status = 0;
    }

    close = () => {
        const redPostMiniStore = this.props.redPostMiniStore;
        redPostMiniStore.open = false;
    }

}

export default ModalRedPostTime;