import React from 'react';
import {inject, observer} from "mobx-react";
import TitleH1 from "../elements/text/titleH1";
import Input from "../elements/input";
import ButtonLoad from "../elements/buttonLoad";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Timeonline from "../../pages/settings/components/timepnline";
import Select from "../elements/select";
import MaskedInput from 'react-text-mask';
export default
@inject('initialStore')
@observer
class SettingsMain extends React.Component {

    render() {

        const initialStore = this.props.initialStore;
        const text = initialStore.text.settings;

        if (initialStore.templates === 'desktop') {

            return (
                <div className="settings">
                    <TitleH1>{text.basicSettings}</TitleH1>

                    <div className="settings-chief">

                        <div className="settings-chief__col col-user">
                            <span className="input-label">{text.avatar}</span>
                            <div className="settings-chief__user" onClick={this.props.avatarRed}>
                                <div className="wrap-img" style={{backgroundColor: '#f5f6fa'}}><img src={this.props.avatarUrl}/></div>
                                <div className="edit-user"><span>{text.edit}</span></div>
                            </div>
                            <input className="no-fastclick" style={{display: 'none'}} type="file" accept="image/*" ref={this.props.avatarInputRef} onChange={this.props.avatarInputChange} />
                        </div>

                        <div className="settings-chief__col col-inputs">
                            <div className="settings-chief__inputs">
                                <div className="row-inputs row">

                                    <div className="col col-5">
                                        <span className="input-label">{text.name}</span>
                                        <Input onChange={this.props.nameChange}>{this.props.nameValue}</Input>
                                    </div>

                                    <div className="col col-5">
                                        <span className="input-label">{text.timezone} ({<Timeonline timeZone={this.props.timeValue}/>})</span>
                                        <Select search={true} options={this.props.timeOption} action={this.props.timeChange}>{this.props.timeValue}</Select>
                                    </div>
                                </div>
                                <div className="row-inputs row">
                                    <div className="col col-5">
                                        <span className="input-label">{text.email}</span>
                                        <div className="form-control">
                                            <span className="title-select">{this.props.email}</span>
                                        </div>

                                    </div>
                                    <div className="col col-5">
                                        <span className="input-label">{text.serviceLanguage}</span>
                                        <Select search={false} options={this.props.languageOption} action={this.props.languageChange}>{this.props.languageValue}</Select>
                                    </div>
                                </div>
                                <div className="row-inputs row">
                                    <div className="col col-5">
                                        <span className="input-label">{text.telegram}</span>

                                        {this.props.telegramNameAccount ?
                                        <>
                                            <div className="form-control" style={{marginBottom: 10}}>
                                                <span className="title-select">{this.props.telegramNameAccount}</span>
                                            </div>
                                            <span className="input-event" onClick={this.props.telegramButtonAction}>{this.props.telegramButtonStatus ? text.disable[0]:text.disable[1]}</span>
                                        </>
                                        :
                                        <>
                                            {this.props.telegramCode ?
                                                <div>
                                                    <p className="hint-inputs">{text.telegramInstallText[0]} <a className="link-hint" href="tg://resolve?domain=likepro_ru_bot">@likepro_ru_bot</a> {text.telegramInstallText[1]} {this.props.telegramCode}.</p>
                                                    <span className="input-event" onClick={this.props.telegramButtonAction}>{this.props.telegramButtonStatus ? text.refresh[0]:text.refresh[1]}</span>
                                                </div>

                                            :
                                                <div style={{marginTop: 23}}>
                                                    <span className="input-event" onClick={this.props.telegramButtonAction}>{this.props.telegramButtonStatus ? text.addTelegram[0]:text.addTelegram[1]}</span>
                                                </div>
                                            }
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {this.props.save ?
                            <div className="more-btn bottom">
                                <ButtonLoad action={this.props.save} color={true}>accountEmployeeRedAccount</ButtonLoad>
                            </div>
                            :null}
                        </div>

                    </div>



                </div>
            );
        } else {

            let teg_a_add_telegram = <a className="link-hint no-fastclick" href="tg://resolve?domain=likepro_ru_bot">@likepro_ru_bot</a>;

            if (initialStore.platform === 'ios' || initialStore.platform === 'android') {
                teg_a_add_telegram = <a onClick={() => {window.openUrl("tg://resolve?domain=likepro_ru_bot")}} className="link-hint no-fastclick">@likepro_ru_bot</a>;
            }

            return (
                <div className="settings">

                    <div className="settings-chief">



                        <div className="settings-chief__col col-inputs">
                            <div className="settings-chief__inputs">
                                <div className="row-inputs row">

                                    <div className="col col-5">
                                        <span className="input-label">{text.name}</span>
                                        <Input onChange={this.props.nameChange}>{this.props.nameValue}</Input>
                                    </div>

                                    <div className="col col-5">
                                        <span className="input-label">{text.timezone} ({<Timeonline timeZone={this.props.timeValue}/>})</span>
                                        <Select search={true} options={this.props.timeOption} action={this.props.timeChange}>{this.props.timeValue}</Select>
                                    </div>
                                </div>
                                <div className="row-inputs row">
                                    <div className="col col-5">
                                        <span className="input-label">{text.email}</span>
                                        <div className="form-control">
                                            <span className="title-select">{this.props.email}</span>
                                        </div>

                                    </div>
                                </div>
                                <div className="row-inputs row">
                                    <div className="col col-5">
                                        <span className="input-label">{text.telegram}</span>

                                        {this.props.telegramNameAccount ?
                                            <>
                                                <div className="form-control" style={{marginBottom: 10}}>
                                                    <span className="title-select">{this.props.telegramNameAccount}</span>
                                                </div>
                                                <span className="input-event no-fastclick" onClick={this.props.telegramButtonAction}>{this.props.telegramButtonStatus ? text.disable[0]:text.disable[1]}</span>
                                            </>
                                            :
                                            <>
                                                {this.props.telegramCode ?
                                                    <div>
                                                        <p className="hint-inputs">{text.telegramInstallText[0]} {teg_a_add_telegram} {text.telegramInstallText[1]} {this.props.telegramCode}.</p>
                                                        <span className="input-event no-fastclick" onClick={this.props.telegramButtonAction}>{this.props.telegramButtonStatus ? text.refresh[0]:text.refresh[1]}</span>
                                                    </div>

                                                    :
                                                    <div style={{marginTop: 23}}>
                                                        <span className="input-event no-fastclick" onClick={this.props.telegramButtonAction}>{this.props.telegramButtonStatus ? text.addTelegram[0]:text.addTelegram[1]}</span>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="col col-5">
                                        <span className="input-label">{text.serviceLanguage}</span>
                                        <Select search={false} options={this.props.languageOption} action={this.props.languageChange}>{this.props.languageValue}</Select>
                                    </div>
                                </div>
                            </div>

                            <div className="more-btn bottom">
                                <ButtonLoad action={this.props.save} color={true}>accountEmployeeRedAccount</ButtonLoad>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}