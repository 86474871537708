import React from 'react';
import {inject, observer} from "mobx-react"
import {observable} from "mobx";
import Time from "../model/time";
import ym from "react-yandex-metrika";

@inject('initialStore', 'menuStore', 'mediaStore')
@observer
class ChatraSetup extends React.Component {

    @observable chatra = null;
    status = false;

    render() {

        const initialStore = this.props.initialStore;
        const menuStore = this.props.menuStore;
        const mediaStore = this.props.mediaStore;

        const restart = initialStore.chatraRestart;

        /*if (initialStore.cordova) {
            return null;
        }*/
        let email = initialStore.mail;

        let phoneCode = initialStore.phoneCode;
        let phone = initialStore.phone;

        let phoneEnter = 'non';

        if (phone !== '') {
            phoneEnter = phoneCode + ' ' + phone;
        }

        const timeRate = Time.convertUnixToDate(initialStore.rateTime);

        if (this.chatra === null) {

        } else {
            if (!this.status) {

                window.Chatra = this.chatra;

                if (email) {
                    this.chatra('setIntegrationData', {
                        name: initialStore.name,
                        email: email,
                        phone: phoneEnter,
                        notes: 'New interface',
                        'Тариф': "Аккаунтов: " + initialStore.rateAccount,
                        'тарифа заканчивается': timeRate.date + ' в ' + timeRate.h + ':' + timeRate.m,
                        'Аккаунты': "123"
                    });

                    window.ChatraSetup = {
                        colors: {
                            buttonText: '#fff',
                            buttonBg: '#7b5c9b',
                        },
                        buttonSize: 50,
                        language: initialStore.language,
                        clientId: initialStore.clientId,
                        disableChatOpenHash: true,
                    };

                    if (!initialStore.cordova) {
                        ym('setUserID', ''+initialStore.idUser+'');
                        ym('userParams', {
                            email: email,
                            id_client: initialStore.idUser,
                            UserID: initialStore.idUser,
                        });
                    }


                } else {
                    window.ChatraSetup = {
                        colors: {
                            buttonText: '#fff',
                            buttonBg: '#7b5c9b',
                        },
                        buttonSize: 50,
                        language: initialStore.language,
                        disableChatOpenHash: true,
                        mode: 'frame',
                    };


                }

                this.status = true;
            } else {

                if (email) {
                    window.Chatra('setIntegrationData', {
                        name: initialStore.name,
                        email: email,
                        phone: phoneEnter,
                        notes: 'New interface',
                        'Тариф': "Аккаунтов: " + initialStore.rateAccount,
                        'тарифа заканчивается': timeRate.date + ' в ' + timeRate.h + ':' + timeRate.m,
                        'Аккаунты': "123"
                    });

                    window.ChatraSetup = {
                        language: initialStore.language,
                        clientId: initialStore.clientId,
                    };
                } else {
                    window.Chatra('setIntegrationData', {
                        name: 'Non name',
                        email: 'non Email',
                        phone: 'Non Phone',
                        notes: 'New interface / No autorisation',
                        'Тариф': "non",
                        'тарифа заканчивается': 'non',
                        'Аккаунты': "non"
                    });

                    window.ChatraSetup = {
                        language: initialStore.language,
                    };
                }

            }

            if (initialStore.templates === 'desktop') {
                if (menuStore.activeComponentAccount === 'direct') {
                    window.Chatra('hide');
                } else if (mediaStore.open) {
                    window.Chatra('hide');
                } else {
                    window.Chatra('show');
                }
            }


        }



        return null;
    }

    componentDidMount() {
        const initialStore = this.props.initialStore;

        /*if (initialStore.cordova) {
            return null;
        }*/

        setTimeout(() => {

            (function(d, w, c) {
                w.ChatraID = 'rM9Y8a9hEekzEMZcx';
                var s = d.createElement('script');
                w[c] = w[c] || function() {
                    (w[c].q = w[c].q || []).push(arguments);
                };
                s.async = true;
                s.src = 'https://call.chatra.io/chatra.js';
                if (d.head) d.head.appendChild(s);
            })(document, window, 'Chatra');

            this.chatra = window.Chatra;



        }, 1000);
    }

    componentDidUpdate() {
        const initialStore = this.props.initialStore;

        const restart = initialStore.chatraRestart;

        if (restart) {

            this.status = false;
            initialStore.chatraRestart = false;
            window.Chatra('restart');
            this.chatra = window.Chatra;
        }
    }

    componentWillUnmount () {
        window.Chatra('kill');
    }

}

export default ChatraSetup;