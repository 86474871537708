import {Link} from "react-router-dom";
import React from "react";
import Button from "../../templates/elements/button";
import Password from "../../templates/elements/password";
import {inject, observer} from "mobx-react";

export default

@inject('initialStore')
@observer
class FormCode extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.login;

        return (
            <div className="floatScreen">
                <div className="auth">

                    <div>

                        <div className="logo_white"></div>

                        <div className="title1">{text[14]}</div>


                        <div className="form_group">

                            <div className="input-box">
                                <span className="input-label1">{text[19]}</span>
                                <div className="group-form">
                                    <div className="column-form w-100">
                                        <Password className="form-control view-22" load={this.props.load} onChange={this.props.passwordOnChange}>{this.props.password}</Password>
                                    </div>
                                </div>
                            </div>

                            <div className="input-box">
                                <span className="input-label1">{text[20]}</span>
                                <div className="group-form">
                                    <div className="column-form w-100">
                                        <Password className="form-control view-22" load={this.props.load} onChange={this.props.passwordCopyOnChange}>{this.props.passwordCopy}</Password>
                                    </div>
                                </div>
                            </div>

                            {this.props.inform !== '' ?
                                <div className="informError">
                                    {this.props.inform}
                                </div>
                                :null}

                            <div className="input-box">
                                <Button div={true} className="btn1 border1 btn-primary1" style={{width: '100%'}} load={this.props.load} action={this.props.okOnClick}>rePasswordFinish</Button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    }

}