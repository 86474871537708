import React from 'react';

import MediaWindow from "../MediaWindow";

import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import Fabric from "../../../../model/fabric";
import MediaRedButton from "./MediaRedButton";
import MediaRedImage from "./MediaRedImage";
import MediaRedGif from "./MediaRedGif";

@inject('editorStore', 'mediaStore', 'accountsStore', 'initialStore')
@observer
class MediaRed extends React.Component {


    @observable widthRedactor = 600;
    @observable max = this.widthRedactor-60;
    @observable durationDisplay = 'none';
    @observable type = false;
    @observable openListElements = false;

    @observable openElement = false;

    @observable elementInfoDelite = false;

    @observable openGIF = false;

    constructor (props) {
        super(props);

        const editorStore = this.props.editorStore;
        const mediaStore = this.props.mediaStore;
        const fileStore = editorStore.file;

        this.allType = false;

        if (fileStore.type === 'image') {
            this.type = 'image';
        } else {
            if (JSON.stringify(fileStore.elements.video)===JSON.stringify(fileStore.elements.image)) {
                this.allType = true;
            }
        }
        if (mediaStore.type === 'story' || mediaStore.type === 'editStory' || mediaStore.type === 'multyStory') {
            this.allType = true;
            this.type = 'all';
        }

        this.typeBeck = false;
    }

    render() {
        const mediaStore = this.props.mediaStore;
        const editorStore = this.props.editorStore;
        const initialStore = this.props.initialStore;
        const accountsStore = this.props.accountsStore;

        const accounts = accountsStore.list.slice();
        const accountsOK = mediaStore.accounts.slice();

        const socSetEnter = {
            instagram: 0,
            vk: 0,
            fb: 0,
            ok: 0,
            tg: 0,
            twitter: 0,
            fb_instagram: 0,
        };

        for (const accountsOKOne of accountsOK) {
            for (let keys in accounts) {
                if (accounts[keys].id === accountsOKOne.id) {
                    if (accounts[keys].tip === 'fb') {
                        socSetEnter.fb++;
                    } else if (accounts[keys].tip === 'tg') {
                        socSetEnter.tg++;
                    } else if (accounts[keys].tip === 'ok') {
                        socSetEnter.ok++;
                    } else if (accounts[keys].tip === 'vk') {
                        socSetEnter.vk++;
                    } else if (accounts[keys].tip === 'instagram') {
                        socSetEnter.instagram++;
                    } else if (accounts[keys].tip === 'twitter') {
                        socSetEnter.twitter++;
                    } else if (accounts[keys].tip === 'fb-instagram') {
                        socSetEnter.fb_instagram++;
                    }
                }
            }
        }

        const text = initialStore.text.addPost;

        const MediaWindowProps = {
            backgroud: this.props.backgroud,
            stateObertka: this.props.stateObertka,
            content: this.props.content,
            draggable: this.props.draggable,
            getDraggableBounds: this.props.getDraggableBounds,
            handleDrag: false,
            type: this.props.type,
            url: this.props.url,
            onMouseDownBox: this.props.onMouseDownBox,
            boxRef: this.props.boxRef,
            disabled: true,
            currentTime: editorStore.file.duration.cover,
        };

        const styleBox = {width: `100%`, height: `${this.props.backgroud.height}px`, position: 'absolute'};
        const styleTypeRedactor = {width: `${this.props.backgroud.width}px`, height: `${this.props.backgroud.height}px`, position: 'relative', margin: '0 auto'};

        let content = false;
        if (!this.type) {
            content = (
                <div className="items-type">

                    {this.allType ?
                    <button type="button" className="btn border btn-block btn-block-mar-b-20" onClick={() => this.redType('all')}>{text[117]}</button>
                    :null}
                    <button type="button" className="btn border btn-block btn-block-mar-b-20" onClick={() => this.redType('image')}>{text[118]}</button>
                    <button type="button" className="btn border btn-block" onClick={() => this.redType('video')}>{text[119]}</button>
                </div>
            );
        } else {

            let elementsStory = false;

            if (mediaStore.type === 'story' || mediaStore.type === 'editStory' || mediaStore.type === 'multyStory') {
                elementsStory = true;
                if (socSetEnter.vk>0) {
                    elementsStory = false;
                }
            }

            let classesElements = 'item-type';
            if (editorStore.file.proportionsBackground <= 0.8) {
                classesElements = 'item-type item-type-w-50';
            }

            content = (
                <>
                    <div className="items-type">
                        <div className={classesElements}><button type="button" className="btn border btn-block no-fastclick" onClick={() => {Fabric.addText('new', false, text[206]); this.openListElements = false}}>{text[121]}</button></div>
                        <div className={classesElements}><button type="button" className="btn border btn-block no-fastclick" onClick={() => {Fabric.addSquare(); this.openListElements = false}}>{text[122]}</button></div>
                        <div className={classesElements}><button type="button" className="btn border btn-block no-fastclick" onClick={() => {Fabric.addTriangle(); this.openListElements = false}}>{text[123]}</button></div>
                        <div className={classesElements}><button type="button" className="btn border btn-block no-fastclick" onClick={() => {Fabric.addCircle(); this.openListElements = false}}>{text[124]}</button></div>

                        {elementsStory ?
                        <>
                            <div className={classesElements}><button type="button" className="btn border btn-block no-fastclick" onClick={() => {Fabric.addGEO(); this.openListElements = false}}>{text[125]}</button></div>
                            <div className={classesElements}><button type="button" className="btn border btn-block no-fastclick" onClick={() => {Fabric.addHashtag(); this.openListElements = false}}>{text[126]}</button></div>
                            <div className={classesElements}><button type="button" className="btn border btn-block no-fastclick" onClick={() => {Fabric.addUser(); this.openListElements = false}}>{text[127]}</button></div>
                            <div className={classesElements}><button type="button" className="btn border btn-block no-fastclick" onClick={() => {Fabric.addQuestionsOne(); this.openListElements = false}}>{text[128]}</button></div>
                            <div className={classesElements}><button type="button" className="btn border btn-block no-fastclick" onClick={() => {Fabric.addPollOne(); this.openListElements = false}}>{text[129]}</button></div>

                            <div className={classesElements}><button type="button" className="btn border btn-block no-fastclick" onClick={() => {Fabric.addSlayderOne(); this.openListElements = false}}>{text[130]}</button></div>
                            <div className={classesElements}><button type="button" className="btn border btn-block no-fastclick" onClick={() => {Fabric.addQuizOne(); this.openListElements = false}}>{text[131]}</button></div>
                            <div className={classesElements}><button type="button" className="btn border btn-block no-fastclick" onClick={() => {Fabric.addUrl(); this.openListElements = false}}>{text[133]}</button></div>
                        </>
                        :null}
                        <div className={classesElements}><button type="button" className="btn border btn-block no-fastclick" onClick={this.openGifFun}>{text[132]}</button></div>
                        <MediaRedImage className={classesElements} clouse={() => {this.openListElements = false}} fabric={Fabric}/>

                    </div>
                    <div className="close-type">
                        <button type="button" className="btn border btn-block" onClick={() => this.openListElements = false}>{text[134]}</button>
                    </div>
                </>
            );
        }

        let buttonElement = null;
        if (this.openListElements || !this.type) {
            buttonElement = (
                <div style={styleBox}>
                    <div className="editor-img__redactor">
                        <div className="type-redactor" style={styleTypeRedactor}>
                            {content}
                        </div>
                    </div>
                </div>
            );
        }

        if (this.openGIF) {
            buttonElement = (
                <div style={styleBox}>
                    <div className="editor-img__redactor">
                        <div className="type-redactor" style={styleTypeRedactor}>
                            <MediaRedGif closeFunction={this.closeGifFun} fabric={Fabric} width={this.props.backgroud.width}/>
                        </div>
                    </div>
                </div>
            );
        }

        let redactor = (
            <div style={styleBox}>
                <div className="type-redactor-convas" style={styleTypeRedactor}>
                    <canvas id="canvas" width={this.props.backgroud.width} height={this.props.backgroud.height}/>
                </div>
            </div>
        );

        let elementInfoDelite = null;
        if (this.elementInfoDelite) {
            elementInfoDelite = (
                <div className="delite-element-info-box">
                    <div className="delite-element-info" style={{width: `${this.props.backgroud.width}px`}}>
                        {text[135]}
                    </div>
                </div>
            );
        }


        const component = <>
            <div className="editor-top">
                {buttonElement}
                {redactor}
                {elementInfoDelite}
                <MediaWindow {...MediaWindowProps}/>
            </div>
            {this.type ?
            <MediaRedButton
                openElement={this.openElement}
                openListElements={() => this.openListElements = true}
                fabric={Fabric}
            />
            :null}
        </>;

        return component;
    }

    openGifFun = () => {
        this.openListElements = false;
        this.openGIF = true;
    }
    closeGifFun = (type = true) => {
        this.openListElements = type;
        this.openGIF = false;
    }

    redType = (type) => {
        this.type = type;
    }

    infoNonRab = () => {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;
        alert(text[120]);
    }

    componentDidUpdate() {
        this.startFabric();
    }
    componentDidMount() {
        this.startFabric();
    }

    startFabric = () => {
        const editorStore = this.props.editorStore;

        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        const accounts = accountsStore.list.slice();
        const accountsOK = mediaStore.accounts.slice();

        const fileStore = editorStore.file;


        if (this.type && this.typeBeck !== this.type) {

            this.typeBeck = this.type;

            let elements = [];
            if (this.type === 'all') {
                elements = fileStore.elements.image;
            } else if (this.type === 'image') {
                elements = fileStore.elements.image;
            } else if (this.type === 'video') {
                elements = fileStore.elements.video;
            }

            const funOpenInfoDel = (status) => {
                this.elementInfoDelite = status;
            }

            let iconsAccount = false;

            if (!accountsOK.length) {

                for (let element of accounts) {

                    if (element.tip === 'instagram') {
                        iconsAccount = element.img;
                        break;
                    }
                }
            } else {
                for (let element of accounts) {
                    if (element.id === accountsOK[0].id) {
                        iconsAccount = element.img;
                        break;
                    }
                }
            }

            Fabric.start('canvas', this.props.backgroud.width, this.props.backgroud.height, elements, this.saveElements, this.typeElement, funOpenInfoDel, iconsAccount);
        }

        this.saveElements();
    }


    typeElement = (element) => {
        this.openElement = element;
    }
    saveElements = () => {
        const editorStore = this.props.editorStore;
        const fileStore = editorStore.file;

        Fabric.save().then((data) => {
            if (this.type === 'all') {
                fileStore.elements.video = data;
                fileStore.elements.image = data;
            } else if (this.type === 'image') {
                fileStore.elements.image = data;
            } else if (this.type === 'video') {
                fileStore.elements.video = data;
            }
        }).catch((e) => {

        });
    }

    componentWillUnmount () {
        this.saveElements();
    }
}

export default MediaRed;
