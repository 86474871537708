import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import style from "../../../component/menu/index.module.css";

export default
@inject('initialStore')
@observer
class MenuElementAddAccount extends React.Component {



    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <li>
                    <span style={{cursor:'pointer'}} onClick={this.props.fun} className="sidebar-nav__item">
                        <span className="icon-item"><i className={'icon ' + this.props.iconClass}></i></span>
                        <span className="title-item">{this.props.name}</span>
                    </span>
                </li>;
        } else {
            return <li>
                    <span style={{cursor:'pointer'}} onClick={this.props.fun} className="sidebar-nav__item">
                        <span className="icon-item"><i className={'icon ' + this.props.iconClass}></i></span>
                        <span className="title-item">{this.props.name}</span>
                    </span>
            </li>;
        }
    }
}

