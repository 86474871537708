import React from 'react';
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";

export default
@inject('initialStore')
@observer
class HelpElementCategory extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <div className="help-category">{this.props.name}</div>;
        } else {
            return <div className="help-category">{this.props.name}</div>;
        }

        /*if (initialStore.templates === 'desktop') {
            return <Link to={"/help/"+this.props.id} className={this.props.active ? "is-active":null}>
                        <span>{this.props.name}</span>
                    </Link>;
        } else {
            return <Link to={"/help/"+this.props.id} className={this.props.active ? "is-active":null}>
                <span>{this.props.name}</span>
            </Link>;
        }*/

    }
}

