import React from 'react';
import {inject, observer} from "mobx-react";

import icons8Add from "../../media/img/account/icons-8-add.svg";
import InfoContainer from "../../templates/infoConteiner";


@inject("accountsStore")
@observer
class Error404 extends React.Component {

    render() {

        const accountsStore = this.props.accountsStore.list.slice();

        /*if (accountsStore.length > 0) {
            ;

            return null;
        }*/
        return <InfoContainer
            /*icon={icons8Add}*/
            title={'Ошибка 404'}
            text={'Такой страницы не существует или она была удалена'}
            buttonName={'Перейти на главную страницу'}
            buttonClick={() => {
                if (accountsStore.length > 0) {
                    this.props.history.push(`/account/${accountsStore[0].id}`);
                } else {
                    this.props.history.push(`/`);
                }

            }}
        />;


    }

    componentDidMount() {
        if (this.props.accountsStore.length > 0) {
            this.props.history.push(`/account/${this.props.accountsStore.list[0].id}`);
        }
    }

}

export default Error404;