import qs from "qs";
import React from "react";

import instance from "./base";
import axios from "axios";

const typeServer = true;

const APIurl = {

    getVk (url) {

        return instance.post('url/vk', qs.stringify({
            url: url,
        })).then((result) => {
            if (!result.status) {
                return {
                    status: false,
                    code: result.code,
                };
            }

            let text = result.data.text;

            const media = [];

            for (const mediasElement of result.data.media) {

                if (mediasElement.type === 'video') {
                    media.push({
                        tip: 'video',
                        urlVideo: mediasElement.url,
                    });
                } else {
                    media.push({
                        tip: 'image',
                        urlImage: mediasElement.url,
                        proportions: mediasElement.proportions, //округление до сотых
                    });
                }
            }

            let alert = {
                text: text,
                media: media,
            }

            return {
                status: true,
                ...alert,
            }

        }).catch((e) => {
            return {
                status: false,
                code: 404,
            };
        });

    },
    getOk (url) {

        return instance.post('url/ok', qs.stringify({
            url: url,
        })).then((result) => {
            if (!result.status) {
                return {
                    status: false,
                    code: result.code,
                };
            }

            let text = result.data.text;

            const media = [];

            for (const mediasElement of result.data.media) {

                if (mediasElement.type === 'video') {
                    media.push({
                        tip: 'video',
                        urlVideo: mediasElement.url,
                    });
                } else {
                    media.push({
                        tip: 'image',
                        urlImage: mediasElement.url,
                        proportions: mediasElement.proportions, //округление до сотых
                    });
                }
            }

            let alert = {
                text: text,
                media: media,
            }

            return {
                status: true,
                ...alert,
            }

        }).catch((e) => {
            return {
                status: false,
                code: 404,
            };
        });

    },
}

export default APIurl;