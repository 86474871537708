import APIposts from "../../api/posts";

import mainStore from "../../stor/mainStore";

export function getPost(id, code) {

        APIposts.getPost(id, code).then((data) => {

            if (!data.status) {
                mainStore.postStore.open = false;
                mainStore.postStore.load = false;
                mainStore.postStore.loadContent = false;
                mainStore.notificationStore.add(mainStore.initialStore.text.code[28], mainStore.initialStore.text.code[data.code]);
                return;
            } else {
                mainStore.postStore.dateAction(data.date);
                mainStore.postStore.load = false;
            }
        }).catch((error) => {
            alert('Ошибка получения данных, проверьте интернет соединение и повторите попытку');
            mainStore.postStore.open = false;
            mainStore.postStore.load = false;
        });
}