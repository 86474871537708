import qs from "qs";
import React from "react";

import instance from "./base";


const APIproxy = {

    getProxy (account, hash) {
        return instance.post('proxy', qs.stringify({
            account: account,
            hesh:hash,
        })).then(data => {
            return data;
        });
    },

    save (account, ip, port, login, password) {
        return instance.post('proxy/save', qs.stringify({
            account: account,
            ip: ip,
            port: port,
            login: login,
            password: password,
        })).then(data => {
            return data;
        });
    },

    del (account) {
        return instance.post('proxy/del', qs.stringify({
            account: account,
        })).then(data => {
            return data;
        });
    },





}

export default APIproxy;