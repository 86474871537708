import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import style from "../../../component/menu/index.module.css";
import {animated, config, Spring} from "react-spring/renderprops";
import ButtonUrlPosts from "../../../component/menu/ButtonUrlPosts";
import ButtonLibraryPosts from "../../../component/menu/ButtonLibraryPosts";

export default
@inject('initialStore')
@observer
class ElementMenuCreatePostProseed extends React.Component {

    @observable num = 0;

    constructor(props) {
        super(props);

        setInterval(() => {

            if (this.num === 5) {
                this.num = 0;
            } else {
                this.num++;
            }

        }, 300);
    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.menuCreatePost;

        return <div className="sidebar-control">

            <div className="sidebar-control__nav unselectable sidebar-control__nav_font">
                <div className="sidebar-control__add chief" onClick={this.props.openPost}>
                    <span className="icon-item"><i className="icon icon-control-edit"></i></span>
                    <span className="title-item">{text.proceed[this.num]}</span>
                </div>

            </div>
        </div>;
    }
}

