import APIfile from "../api/file";
import file, {getIconImage} from "./file";
import mediaHelpers from "./mediaHelpers";
import editorMediaClass from "./eritorMedia";

import mainStore from "../stor/mainStore";
import Time from "./time";
import loadFileAndBloobUrl from "./loadFileAndBloobUrl";

export default async function loadFileObrabotchik(files, typeURL = false) {

    const mediaStore = mainStore.mediaStore;
    const accountsStore = mainStore.accountsStore;
    const notificationStore = mainStore.notificationStore;

    const initialStore = mainStore.initialStore;
    const text = initialStore.text.addPost;

    const accountsOK = mediaStore.accounts.slice();
    const accounts = accountsStore.list.slice();

    if (mediaStore.obrabotkaMediaFiles) {
        notificationStore.add(text[17], text[192]);
        return  false;
    }

    if (!files.length) {
        return false;
    }

    //получаем ключ для загрузки медиа если его нет
    if (mediaStore.type !== 'multyPost' && mediaStore.type !== 'multyStory') {
        if (mediaStore.idLoad === false) {

            const getKey = await APIfile.getKey();

            if (getKey.status) {
                mediaStore.actionIdLoad(getKey.id);
            } else {
                alert('Error setIdLoad');
                return false;
            }
        }
    }

    //делаем спиоск выбранных социальных сетей
    const socSetEnter = {
        instagram: 0,
        vk: 0,
        fb: 0,
        ok: 0,
        tg: 0,
        twitter: 0,
        fb_instagram: 0,
    };

    let accountOneMultyPost = 0;

    for (const accountsOKOne of accountsOK) {
        for (let keys in accounts) {
            if (accounts[keys].id === accountsOKOne.id) {
                if (accounts[keys].tip === 'fb') {
                    socSetEnter.fb++;
                } else if (accounts[keys].tip === 'tg') {
                    socSetEnter.tg++;
                } else if (accounts[keys].tip === 'ok') {
                    socSetEnter.ok++;
                } else if (accounts[keys].tip === 'vk') {
                    socSetEnter.vk++;
                } else if (accounts[keys].tip === 'instagram') {
                    socSetEnter.instagram++;
                } else if (accounts[keys].tip === 'twitter') {
                    socSetEnter.twitter++;
                } else if (accounts[keys].tip === 'fb-instagram') {
                    socSetEnter.fb_instagram++;
                }
                accountOneMultyPost = accounts[keys].id;
            }
        }
    }

    //делаем список медиа файлов уже добавленных
    const mediaEnter = {
        video: 0,
        image: 0,
    };
    for (const mediaOne of mediaStore.media.slice()) {
        if (mediaOne.type === 'video') {
            mediaEnter.video++;
        } else {
            mediaEnter.image++;
        }
    }

    const maxFile = mediaStore.maxMedia;
    const fileLoad = mediaStore.media.slice().length;
    const fileLimit = maxFile - fileLoad;

    try {

        mediaStore.obrabotkaMediaFiles = '(0/'+files.length+')';
        let numFile = 0;


        let filesNew = [];
        if (!typeURL) {
            filesNew = await file.FileMaxSizeAndTypeAndMaxFiles(files,fileLimit);
        } else {
            filesNew = [];
            for (const element of files) {

                if (filesNew.length >= fileLimit) {
                    notificationStore.add('Ошибка', 'Привышен лифит файлов, файл ' + element.name + ' не был добавлен');
                    continue;
                }
                filesNew.push(element);
            }
        }

        //лимиты от социальных сетей
        const limitsMedia = mediaHelpers.limitsMedia();

        let fileAndUrl = [];
        let nextMedia = mediaStore.mediaIdLoad;

        for (const element of filesNew) {

            //Индивидуальная обложка для IGTV
            if (element.coverIndividual) {

                loadFileAndBloobUrl(element.coverIndividual, 0, true, false).then((data) => {
                    getIconImage(data.url).then((icon) => {
                        mediaStore.IgtvImage = {file: data.blob, url:data.url, icon: icon};
                    }).catch((e) => {
                        alert(text[62]);
                    });
                }).catch((e) => {
                    alert(text[63]);
                });

            }

            //Если множественное создание постов то создаем доп таблицу и ID для загрузок файлов
            if (mediaStore.type === 'multyPost' || mediaStore.type === 'multyStory') {

                const getKeyNew = await APIfile.getKey();

                if (getKeyNew.status) {

                    let listAddTime = [];

                    for (let key in mediaStore.multyPost.slice()) {
                        listAddTime.push(mediaStore.multyPost[key].time);
                    }

                    let timeList = Time.dateTimetableGenerator(accountOneMultyPost, mediaStore.type === 'multyPost' ? 'post':'story', listAddTime);

                    mediaStore.actionListPostAdd(nextMedia, getKeyNew.id, timeList[0]);
                } else {
                    alert('Error setIdLoad');
                    return false;
                }
            }

            let loadFileObtabotkaURL = false;

            //была ли обработка видео через сервер
            let dopObrabotka = false;

            //100 мб для мобилок
            /*if (initialStore.templates !== 'desktop' && element.file.size > 1105381888) {
                mediaStore.actionMediaStart(mediaStore.mediaIdLoad, 'obrabotka', false, false, false, 0, {icons: [], iconsCover: []});
                notificationStore.add(text[194], text[193]);
                const resObr = await file.loadFileToServerObrabotka(mediaStore.mediaIdLoad, element);

                if (!resObr) {
                    notificationStore.add(text[69], text[196]);
                    continue;
                }

                loadFileObtabotkaURL = resObr;

                mediaStore.deliteMedia(mediaStore.mediaIdLoad);

            } else {
                //url = await file.createURL(element);
            }*/

            //если фото, создаем блуб файл, для избежания не корректных цветов в фото
            if (element.type === 'image') {

                if (socSetEnter.fb && mediaEnter.video) {
                    notificationStore.add(text[68], text[5]);
                    continue;
                }
                if (socSetEnter.twitter && mediaEnter.video) {
                    notificationStore.add(text[68], text[191]);
                    continue;
                }
                if (socSetEnter.twitter && mediaEnter.image>=4) {
                    notificationStore.add(text[68], text[191]);
                    continue;
                }
                if (socSetEnter.fb_instagram) {
                    if (mediaEnter.image || mediaEnter.video) {
                        notificationStore.add(text[68], text[218]);
                        continue;
                    }
                }
                if (mediaStore.type === 'igtv' || mediaStore.type === 'reels') {
                    continue;
                }

                mediaEnter.image++;

                //конвертируем фото для оптимизации цветов
                let blobAndProportion = false;
                if (!typeURL) {
                    let url = await file.createURL(element);
                    blobAndProportion = await file.createBlobPre(url);
                }


                mediaStore.actionMediaStart(nextMedia, 'image', false, false, false, 0, {icons: [], iconsCover: []});

                let infoFile = false;
                if (!typeURL) {
                    infoFile = await file.loadFileToServer(nextMedia, blobAndProportion.blob, false);
                } else {
                    infoFile = await file.loadFileToServer(nextMedia, false, element.url);
                    blobAndProportion = await file.createBlobPre(infoFile.file);
                }


                element.file = null;
                blobAndProportion.blob = null;

                if (!infoFile) {
                    mediaStore.deliteMedia(nextMedia);
                    notificationStore.add(text[69], 'Ошибка передачи медиа файла на сервер.');
                    continue;
                }

                if (infoFile.status === false) {
                    mediaStore.deliteMedia(nextMedia);
                    notificationStore.add(text[69], text[infoFile.code]);
                    continue;
                }

                let addFile = {...element, file: blobAndProportion.blob, url: '', duration: false, proportion: blobAndProportion.proportion, id: nextMedia};

                fileAndUrl[fileAndUrl.length] = addFile;

                nextMedia++;

            } else { //если видео, надо сделать тест на длину, и поддерживаемость браузера

                if (socSetEnter.fb && mediaEnter.image) {
                    notificationStore.add(text[69], text[5]);
                    continue;
                }

                if (socSetEnter.fb && mediaEnter.video) {
                    notificationStore.add(text[69], text[5]);
                    continue;
                }

                if (socSetEnter.twitter && mediaEnter.image) {
                    notificationStore.add(text[69], text[191]);
                    continue;
                }

                if (socSetEnter.twitter && mediaEnter.video) {
                    notificationStore.add(text[69], text[191]);
                    continue;
                }

                if (socSetEnter.fb_instagram) {
                    if (mediaEnter.image || mediaEnter.video) {
                        notificationStore.add(text[69], text[218]);
                        continue;
                    }
                }

                mediaEnter.video++;

                mediaStore.actionMediaStart(nextMedia, 'video', false, false, false, 0, {icons: [], iconsCover: []});

                let infoFile = false;
                if (!typeURL) {
                    infoFile = await file.loadFileToServer(nextMedia, element.file, false);
                } else {

                    let urlCover = false;
                    let coverTime = 0;
                    if (element.duration) {
                        if (element.duration.cover) {
                            coverTime = element.duration.cover;
                        }
                    }

                    if (element.urlCover) {
                        urlCover = element.urlCover;
                        coverTime = false;
                    }

                    infoFile = await file.loadFileToServer(nextMedia, false, element.url, urlCover, coverTime);
                }


                if (!infoFile) {
                    mediaStore.deliteMedia(nextMedia);
                    notificationStore.add(text[69], 'Ошибка передачи медиа файла на сервер.');
                    continue;
                }

                if (infoFile.status === false) {
                    mediaStore.deliteMedia(nextMedia);
                    let infoErrorFileLoad = 'Не известная ошибка';
                    if (infoFile.code) {
                        if (text[infoFile.code]) {
                            infoErrorFileLoad = text[infoFile.code];
                        }
                    }
                    notificationStore.add(text[69], infoErrorFileLoad);
                    continue;
                }



                let videoTest = {};
                element.file = null;
                //проводим тестирование видео
                if (infoFile.infoError === 'hdr') {
                    videoTest = {
                        status: false,
                        info: 'format',
                        type: 'hdr',
                    }
                } else {
                    videoTest = await file.videoTest(infoFile.file);
                }


                //если видео не поддерживается, конвертим!
                if (!videoTest.status) {
                    if (videoTest.info === 'format') {

                        if (videoTest.type) {
                            notificationStore.add(text[194], 'Видео в формате HDR, мы его обрабатываем, подождите не много.');
                        } else {
                            notificationStore.add(text[194], text[195]);
                        }

                        mediaStore.actionMediaUpdate(nextMedia, {
                            type: 'obrabotka',
                        });

                        const resObr = await file.loadFileToServerObrabotka(nextMedia, false, infoFile.file);

                        if (!resObr) {
                            mediaStore.deliteMedia(nextMedia);
                            notificationStore.add(text[69], text[196]);
                            continue;
                        }

                        mediaStore.actionMediaUpdate(nextMedia, {
                            type: 'video',
                            url: resObr,
                            loadPercent: true,
                        });

                        loadFileObtabotkaURL = resObr;

                        videoTest = await file.videoTest(resObr);
                    }
                }

                if (!videoTest.status) {
                    if (videoTest.info === 'max') {
                        mediaStore.deliteMedia(nextMedia);
                        notificationStore.add(text[69], text[72]);
                        continue;
                    } else {
                        videoTest = {status:true, duration: infoFile.duration, proportions: infoFile.proportions};
                    }
                }

                if (videoTest.status) {

                    if (videoTest.duration < 3) {
                        notificationStore.add(text[69], text[70]);
                        mediaStore.deliteMedia(nextMedia);
                        continue;
                    }

                    if (mediaStore.type === 'igtv') {
                        if (videoTest.duration < 3) {
                            mediaStore.deliteMedia(nextMedia);
                            notificationStore.add(text[69], text[71]);
                            continue;
                        }
                    }

                    const finish = videoTest.duration < limitsMedia.duration ? videoTest.duration : limitsMedia.duration;
                    //длина видео и все что с этим связано

                    let duration = {};
                    if (element.duration) {

                        duration = {
                            full: videoTest.duration,
                            start: element.duration.start ? element.duration.start : 0,
                            finish: element.duration.finish ? element.duration.finish : finish,
                            cover: element.duration.cover ? element.duration.cover : 0,
                            icons: [],
                            iconsCover: [],
                        };

                        if (duration.finish - duration.start > finish) {
                            duration.finish = duration.start + finish > duration.full ? duration.full: duration.start + finish;
                        }

                    } else {
                        duration = {
                            full: videoTest.duration,
                            start: 0,
                            finish: finish,
                            cover: 0,
                            icons: [],
                            iconsCover: [],
                        };
                    }

                    mediaStore.actionMediaUpdate(nextMedia, {
                        duration: duration,
                        proportion: videoTest.proportions,
                    });

                    if (element.crop) {
                        if (!element.crop.proportions) {
                            element.crop.proportions = videoTest.proportions;
                        }
                    }

                    let addFile = {...element, url: loadFileObtabotkaURL, duration: duration, proportion: videoTest.proportions, id: nextMedia};

                    fileAndUrl[fileAndUrl.length] = addFile;

                    nextMedia++;
                } else {
                    mediaStore.deliteMedia(nextMedia);
                    notificationStore.add(text[69], text[73]);
                    continue;
                }
            }
            numFile++;
            mediaStore.obrabotkaMediaFiles = '('+numFile+'/'+files.length+')';
        }

        //увеличиваем id следующего медиа
        mediaStore.actionMediaIdLoad(nextMedia);

        //обновляем у всех файлов пропорции фона и диспачим
        for (const key in fileAndUrl) {

            const element =  fileAndUrl[key];

            let proportions = fileAndUrl[key].proportion;

            let proportionsBackground = proportions;
            if (fileAndUrl[key].proportionsBackground) {
                proportionsBackground = fileAndUrl[key].proportionsBackground;
            }


            if (mediaStore.type === 'igtv') {
                if (proportionsBackground <= 1) {
                    proportionsBackground = limitsMedia.min;
                } else {
                    proportionsBackground = limitsMedia.max;
                }
            } else if (mediaStore.type === 'reels') {
                proportionsBackground = limitsMedia.min;
            } else {

                proportionsBackground = proportionsBackground < limitsMedia.min ? limitsMedia.min:proportionsBackground;
                proportionsBackground = proportionsBackground > limitsMedia.max ? limitsMedia.max:proportionsBackground;

            }

            if (limitsMedia.proportions && mediaStore.media.length) {
                if (mediaStore.media[0].proportionsBackground) {
                    proportionsBackground = mediaStore.media[0].proportionsBackground;
                }
            }

            proportionsBackground = Math.ceil((proportionsBackground)*1000)/1000;

            const mediaUpdate = {
                proportions: proportions,
                proportionsBackground: proportionsBackground,
                crop: {
                    unit: '%',
                    x: 0,
                    y: 0,
                    width: 100,
                    height: 100,
                    proportions: proportions,
                },
                duration: element.duration,
            };

            if (element.accounts) mediaUpdate.accounts = element.accounts;
            if (element.color) mediaUpdate.color = element.color;
            if (element.coverIndividual) mediaUpdate.coverIndividual = element.coverIndividual;
            if (element.crop) mediaUpdate.crop = element.crop;
            if (element.draggable) mediaUpdate.draggable = element.draggable;
            if (element.elements) mediaUpdate.elements = element.elements;
            if (element.soundDelite) mediaUpdate.soundDelite = element.soundDelite;
            if (element.zoom) mediaUpdate.zoom = element.zoom;

            await mediaStore.actionMediaUpdate(element.id, mediaUpdate);

            let realIDMedia = 0;
            for (const elementID in mediaStore.media.slice()) {
                if (mediaStore.media[elementID].id === element.id) {
                    realIDMedia = elementID;
                    break;
                }
            }

            if (mediaStore.media[realIDMedia].draggable.x === 0 && mediaStore.media[realIDMedia].draggable.y === 0) {

                const editorMedia = new editorMediaClass({...mediaStore.media[realIDMedia]});
                editorMedia.initial();
                const importState = editorMedia.importState();

                const mediaUpdate2 = {
                    ...importState,
                    processingPercent: 100,
                };

                mediaStore.actionMediaUpdate(element.id, mediaUpdate2);
            }


            fileAndUrl[key] = {...element, proportionsBackground:proportionsBackground, proportions: proportions};

        }
        mediaStore.obrabotkaMediaFiles = false;
    } catch (e) {
        mediaStore.obrabotkaMediaFiles = false;
        alert('Ошибка обработки медиа файлов.');
    }



}
