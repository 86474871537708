import mainStore from "../stor/mainStore";

function Language() {
    let language = window.navigator ? (window.navigator.language ||
        window.navigator.systemLanguage ||
        window.navigator.userLanguage) : "ru";
    language = language.substr(0, 2).toLowerCase();

    if (language !== 'en') {
        language = 'ru';
    }

    mainStore.initialStore.updateLanguage(language);
}

Language();