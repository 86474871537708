import React from 'react';
import {inject, observer} from "mobx-react";

import Input from "../../elements/input";
import ReactDOM from "react-dom";
import Select from "../../elements/select";

export default
@inject('initialStore')
@observer
class PostTemplateMediaRedQuizTexts extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        if (initialStore.templates === 'desktop') {
            return (
                <>
                    <div className="add-publication__text-box-red">
                        <span className="input-label">{text[176]}</span>
                        <Select nonTopValueElement classNameTwu={'event-select form-control view-2'} options={this.props.options} action={this.props.actionSelect}>{this.props.good}</Select>
                    </div>
                    <div className="item-form">
                        <div className="group-form">
                            <div className="column-form w-50 w-50-l">
                                <span className="input-label">{text[162]}</span>
                                <Input emoje className={this.props.classesA} placeholder={text[162]} onChange={(value => this.props.action('textA', value))}>{this.props.textAValue}</Input>
                            </div>
                            <div className="column-form w-50 w-50-r">
                                <span className="input-label">{text[163]}</span>
                                <Input emoje className={this.props.classesB} placeholder={text[163]} onChange={(value => this.props.action('textB', value))}>{this.props.textBValue}</Input>
                            </div>
                        </div>
                        <div className="group-form" style={{margin: '10px 0 0 0'}}>
                            <div className="column-form w-50 w-50-l">
                                <span className="input-label">{text[174]}</span>
                                <Input emoje className={this.props.classesC} placeholder={text[174]} onChange={(value => this.props.action('textC', value))}>{this.props.textCValue}</Input>
                            </div>
                            <div className="column-form w-50 w-50-r">
                                <span className="input-label">{text[175]}</span>
                                <Input emoje className={this.props.classesD} placeholder={text[175]} onChange={(value => this.props.action('textD', value))}>{this.props.textDValue}</Input>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {

            return (
                <>
                    <div className="add-publication__text-box-red">
                        <span className="input-label">{text[176]}</span>
                        <Select nonTopValueElement classNameTwu={'event-select form-control view-2'} options={this.props.options} action={this.props.actionSelect}>{this.props.good}</Select>
                    </div>
                    <div className="add-publication__text-box-red">
                        <span className="input-label">{text[162]}</span>
                        <Input emoje className={this.props.classesA} placeholder={text[162]} onChange={(value => this.props.action('textA', value))}>{this.props.textAValue}</Input>
                    </div>
                    <div className="add-publication__text-box-red">
                        <span className="input-label">{text[163]}</span>
                        <Input emoje className={this.props.classesB} placeholder={text[163]} onChange={(value => this.props.action('textB', value))}>{this.props.textBValue}</Input>
                    </div>
                    <div className="add-publication__text-box-red">
                        <span className="input-label">{text[174]}</span>
                        <Input emoje className={this.props.classesC} placeholder={text[174]} onChange={(value => this.props.action('textC', value))}>{this.props.textCValue}</Input>
                    </div>
                    <div className="add-publication__text-box-red">
                        <span className="input-label">{text[175]}</span>
                        <Input emoje className={this.props.classesD} placeholder={text[175]} onChange={(value => this.props.action('textD', value))}>{this.props.textDValue}</Input>
                    </div>
                </>
            );
        }
    }
}