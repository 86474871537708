import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import style from "../../../component/menu/index.module.css";

export default
@inject('initialStore')
@observer
class MenuElementButtonUrlPosts extends React.Component {



    render() {
        const initialStore = this.props.initialStore;

        const text = initialStore.text.menuCreatePost;

        if (initialStore.templates === 'desktop') {

            let searhValueElement = null;

            let classButton = 'sidebar-control__add add-drop sidebar-control__copy is-show';

            if (this.props.openMenu) {

                const classElement = this.props.load ? 'input-copy btn-primary-animate-content':'input-copy';

                searhValueElement = (
                    <div ref={this.props.ref} className="link-copy">
                        <input placeholder={text.insertLink} value="" className={classElement} autoFocus onChange={(e) => {this.props.onChangeValue(e.target.value)}} value={this.props.searhValue}/>
                        <span className="btn-copy" onClick={this.props.openModal}>
                        <span className="help-copy">?</span>
                        <span className="yes-copy"><i className="icon icon-control-yes"></i></span>
                    </span>
                    </div>
                );

                classButton = 'sidebar-control__add add-drop sidebar-control__copy is-show sidebar-control__add-open-url';
            }


            return <div  className={classButton} onClick={this.props.open}>
                <span className="icon-item"><i className="icon icon-control-plus-link"></i></span>
                <span className="title-item">{text.copyOfThePostOnTheLink}</span>

                {searhValueElement}

            </div>;


        } else {
            let searhValueElement = null;

            let classButton = 'sidebar-control__add add-drop sidebar-control__copy is-show';

            if (this.props.openMenu) {

                const classElement = this.props.load ? 'input-copy btn-primary-animate-content':'input-copy';

                searhValueElement = (
                    <div ref={this.props.ref} className="link-copy">
                        <input placeholder={text.insertLink} value="" className={classElement} autoFocus onChange={(e) => {this.props.onChangeValue(e.target.value)}} value={this.props.searhValue}/>
                        <span className="btn-copy" onClick={this.openModal}>
                        <span className="help-copy">?</span>
                        <span className="yes-copy"><i className="icon icon-control-yes"></i></span>
                    </span>
                    </div>
                );

                classButton = 'sidebar-control__add add-drop sidebar-control__copy is-show sidebar-control__add-open-url';
            }


            return <div  className={classButton} onClick={this.open}>
                <span className="icon-item"><i className="icon icon-control-plus-link"></i></span>
                <span className="title-item">{text.copyOfThePostOnTheLink}</span>

                {searhValueElement}

            </div>;
        }
    }

    openModal = () => {
        this.props.openModal();
    }

    open = () => {
        this.props.open();
    }
}

