import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ReactDOM from "react-dom";

export default
@inject('initialStore')
@observer
class HeaderBox extends React.Component {

    @observable typeOpen = false;

    constructor(props) {
        super(props);

        this.window = React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.openWin = false;
    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <nav className="top-menu">
                <ul className="top-menu__ul">
                    {this.props.children}
                </ul>
            </nav>;
        } else {

            if (!this.typeOpen) {
                if (this.openWin) {
                    this.openWin=false;
                    this.updateStatusCloseFon();
                }
            } else {
                if (!this.openWin) {
                    this.openWin=true;
                    this.updateStatusCloseFon();
                }
            }

            let contentType = null;
            if (this.typeOpen) {
                contentType = <div className="event-menu g-center v-bottom" ref={this.window}>
                    <ul className="top-menu__ul" >
                        {this.props.children}
                    </ul>
                </div>;
            }

            return <nav className="top-menu"  onClick={this.onFocus}>
                <div className="top-menu__label">
                    <span className="value-label">{this.props.activeElementName}</span>
                </div>
                {contentType}
            </nav>;
        }

    }

    onFocus = () => {
        this.typeOpen = !this.typeOpen;
    }

    updateStatusCloseFon = () => {
        if (this.openWin) {
            document.addEventListener('touchend', this.handleClickOutside, false);
        } else {
            document.removeEventListener('touchend', this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент

                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов

                    if (this.typeOpen) {
                        this.typeOpen = false;
                        this.searhValue = '';
                    }

        } catch(error) {
            return null;
        }
    }
}

