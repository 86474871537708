import React from 'react';
import {inject, observer} from "mobx-react";

import {Spring} from 'react-spring/renderprops';

import Editor from "./Editor";

import 'react-day-picker/lib/style.css';

import 'moment/locale/ru';

import APIposts from "../../api/posts";

import './emoji-mart.css';
import fileClass  from "../../model/file";
import mainStore from "../../stor/mainStore";
import Time from "../../model/time";

import file from "../../model/file";

import PostTemplate from "../../templates/post/post";
import TextModel from "../../model/text";
import loadFileObrabotchik from "../../model/loadFileObrabotchik";
import Fabric from "../../model/fabric";
import iconInst from "../../media/img/socialNetworks/inst.svg";
import iconVK from "../../media/img/socialNetworks/vk.svg";
import iconFB from "../../media/img/socialNetworks/fb.svg";
import iconOK from "../../media/img/socialNetworks/ok.svg";
import iconTG from "../../media/img/socialNetworks/tg.svg";
import iconTwitter from "../../media/img/socialNetworks/twitter.svg";
import iconInstFB from "../../media/img/socialNetworks/inst.fb.svg";

import initialStore from "../../stor/initialStore";



@inject('editorStore', 'libraryStore', 'initialStore', 'mediaStore', 'accountsStore', 'notificationStore', 'initialStore')
@observer
class Post extends React.Component{

    constructor (props) {
        super(props);

        this.closePost = this.closePost.bind(this);
        this.closePostDel = this.closePostDel.bind(this);
        this.loadPost = this.loadPost.bind(this);
        this.editPost = this.editPost.bind(this);
        this.timeProClick = this.timeProClick.bind(this);
        this.textProClick = this.textProClick.bind(this);
        this.onClickCommentOff = this.onClickCommentOff.bind(this);
        this.onClickComment = this.onClickComment.bind(this);
        this.onClickGeo = this.onClickGeo.bind(this);
    }

    actionNameValue = (value) => {
        const mediaStore = this.props.mediaStore;
        mediaStore.name = value;
    }

    render() {

        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        const idAccount = accountsStore.active;

        const accountsOK = mediaStore.accounts.slice();
        const accounts = accountsStore.list.slice();

        if (!mediaStore.open || mediaStore.type === 'multyPost' || mediaStore.type === 'multyStory') {
            return null;
        }


        let EditorModal = null;
        if (mediaStore.editorOpen !== false) {
            EditorModal = <Editor/>;
        }
        let stileBox = {
            width: "800px",
            height: "1000px",
            position: "absolution",
        };

        let nameBox = null;
        if (this.props.initialStore.templates === 'desktop') {
            nameBox = mediaStore.accounts.length === 0 ? text[6]: text[7];

            if (mediaStore.accounts.length > 1) {
                nameBox = text[8][0] + mediaStore.accounts.length + ' ' + this.num2str(mediaStore.accounts.length, [text[8][1], text[8][2], text[8][3]]);
            }
        } else {
            if (mediaStore.accounts.length === 0) {
                nameBox = <nav className="top-menu top-menu-right">
                    <div className="top-menu__label top-menu__label_non_image top-menu__label_non_image_top"><span className="value-label">{text[9]}</span>
                    </div>
                </nav>;
            } else {
                nameBox = <nav className="top-menu">
                    <div className="top-menu__label top-menu__label_non_image  top-menu__label_non_image_top"><span className="value-label">{text[10]}</span>
                    </div>
                </nav>;
            }

            if (mediaStore.accounts.length > 1) {
                nameBox = nameBox = <nav className="top-menu">
                    <div className="top-menu__label top-menu__label_non_image  top-menu__label_non_image_top"><span className="value-label">{text[11]}</span>
                    </div>
                </nav>;
            }
        }

        if (this.props.initialStore.templates === 'desktop') {
            if (mediaStore.type === 'edit') {
                nameBox = text[12];
            } else if (mediaStore.type === 'story') {
                nameBox = text[13];
            } else if (mediaStore.type === 'igtv') {
                nameBox = text[14];
            } else if (mediaStore.type === 'reels') {
                nameBox = text[199];
            } else if (mediaStore.type === 'editStory') {
                nameBox = text[15];
            } else if (mediaStore.type === 'editIgtv') {
                nameBox = text[16];
            } else if (mediaStore.type === 'editReels') {
                nameBox = text[200];
            }
        } else {
            if (mediaStore.type === 'edit') {
                nameBox = <nav className="top-menu1 top-menu-right">
                    <div className="top-menu__label top-menu__label_non_image"><span className="value-label">{text[12]}</span>
                    </div>
                </nav>;
            } else if (mediaStore.type === 'story') {
                nameBox = <nav className="top-menu top-menu-right">
                    <div className="top-menu__label top-menu__label_non_image  top-menu__label_non_image_top"><span className="value-label">{text[13]}</span>
                    </div>
                </nav>;
            } else if (mediaStore.type === 'igtv') {
                nameBox = <nav className="top-menu top-menu-right">
                    <div className="top-menu__label top-menu__label_non_image  top-menu__label_non_image_top"><span className="value-label">{text[14]}</span>
                    </div>
                </nav>;
            } else if (mediaStore.type === 'reels') {
                nameBox = <nav className="top-menu top-menu-right">
                    <div className="top-menu__label top-menu__label_non_image  top-menu__label_non_image_top"><span className="value-label">{text[199]}</span>
                    </div>
                </nav>;
            } else if (mediaStore.type === 'editStory') {
                nameBox = <nav className="top-menu top-menu-right">
                    <div className="top-menu__label top-menu__label_non_image  top-menu__label_non_image_top"><span className="value-label">{text[15]}</span>
                    </div>
                </nav>;
            } else if (mediaStore.type === 'editIgtv') {
                nameBox = <nav className="top-menu top-menu-right">
                    <div className="top-menu__label top-menu__label_non_image  top-menu__label_non_image_top"><span className="value-label">{text[200]}</span>
                    </div>
                </nav>;
            } else if (mediaStore.type === 'editReels') {
                nameBox = <nav className="top-menu top-menu-right">
                    <div className="top-menu__label top-menu__label_non_image  top-menu__label_non_image_top"><span className="value-label">{text[16]}</span>
                    </div>
                </nav>;
            }
        }

        let buttonOkName = mediaStore.accounts.length ? 'createPost':'createPostLibrary';
        if (mediaStore.type === 'edit') {
            buttonOkName = 'savePost';
        } else if (mediaStore.type === 'story') {
            buttonOkName = 'createStory';
        } else if (mediaStore.type === 'igtv') {
            buttonOkName = 'createIGTV';
        } else if (mediaStore.type === 'reels') {
            buttonOkName = 'createReels';
        } else if (mediaStore.type === 'editStory') {
            buttonOkName = 'saveStory';
        } else if (mediaStore.type === 'editIgtv') {
            buttonOkName = 'saveIGTV';
        } else if (mediaStore.type === 'editReels') {
            buttonOkName = 'saveReels';
        }

        //надо показывать или нет коментарий и гео
        const socSetEnter = {
            instagram: 0,
            vk: 0,
            fb: 0,
            ok: 0,
            tg: 0,
            twitter: 0,
            fb_instagram: 0,
        };
        for (const accountsOKOne of accountsOK) {
            for (let keys in accounts) {
                if (accounts[keys].id === accountsOKOne.id) {
                    if (accounts[keys].tip === 'fb') {
                        socSetEnter.fb++;
                    } else if (accounts[keys].tip === 'tg') {
                        socSetEnter.tg++;
                    } else if (accounts[keys].tip === 'ok') {
                        socSetEnter.ok++;
                    } else if (accounts[keys].tip === 'vk') {
                        socSetEnter.vk++;
                    } else if (accounts[keys].tip === 'instagram') {
                        socSetEnter.instagram++;
                    } else if (accounts[keys].tip === 'twitter') {
                        socSetEnter.twitter++;
                    } else if (accounts[keys].tip === 'fb-instagram') {
                        socSetEnter.fb_instagram++;
                    }
                }
            }
        }
        let viewComentAndGeo = false;

        if (socSetEnter.instagram || socSetEnter.fb_instagram) {
            if (socSetEnter.vk || socSetEnter.fb || socSetEnter.ok || socSetEnter.tg || socSetEnter.twitter) {
                viewComentAndGeo = 2;
            } else {
                viewComentAndGeo = 1;
            }
        } else {
            if (!socSetEnter.instagram && !socSetEnter.vk && !socSetEnter.fb && !socSetEnter.ok && !socSetEnter.tg && !socSetEnter.twitter && !socSetEnter.fb_instagram) {
                viewComentAndGeo = 1;
            }
        }




        //информация о редактируемом аккаунте
        let infoRedAccount = false;

        if (mediaStore.type === 'edit' || mediaStore.type === 'editStory' || mediaStore.type === 'editIgtv' || mediaStore.type === 'editReels') {

            for (let item of accounts) {
                for (let keys in accountsOK) {
                    if (accountsOK[keys].id === item.id) {
                        //иконка
                        let icon = '';
                        if (item.tip === 'instagram') icon = iconInst;
                        else if (item.tip === 'vk') icon = iconVK;
                        else if (item.tip === 'fb') icon = iconFB;
                        else if (item.tip === 'tg') icon = iconTG;
                        else if (item.tip === 'ok') icon = iconOK;
                        else if (item.tip === 'twitter') icon = iconTwitter;
                        else if (item.tip === 'fb-instagram') icon = iconInstFB;

                        infoRedAccount = {
                            icon: icon,
                            img: item.img,
                            name: item.name,
                        };
                        break;
                    }
                }
                if (infoRedAccount) break;
            }
        }


        const props = {
            type: mediaStore.type,
            nameBox: nameBox,
            oneCommentOn: mediaStore.comment,
            oneCommentOnAction: this.onClickComment,
            commentOff: mediaStore.commentOff,
            commentOffAction: this.onClickCommentOff,
            textProAndTimeProStatus: mediaStore.accounts.length > 1,
            textPro: mediaStore.textPro,
            textProAction: this.textProClick,
            timePro: mediaStore.timePro,
            timeProAction: this.timeProClick,
            geo: mediaStore.geo,
            geoAction: this.onClickGeo,

            buttomCloseAction: this.closePostDel,

            buttomCollapseStatus: mediaStore.type !== 'edit',
            buttomCollapseAction: this.closePost,

            buttonOkName: buttonOkName,
            buttonOkAction: (promise) => {
                // ?  : ,
                if (mediaStore.type === 'edit' || mediaStore.type === 'editStory' || mediaStore.type === 'editIgtv' || mediaStore.type === 'editReels') {
                    this.editPost(promise);
                } else {
                    this.loadPost(promise);
                }
            },

            //файлы
            mediaLoadingStatus: mediaStore.mediaLoading,

            modalEditor: EditorModal,

            viewComentAndGeo: viewComentAndGeo,

            fileDrop: this.fileDrop,

            infoRedAccount: infoRedAccount,
        };

        let render = (
            <PostTemplate
                {...props}
            />
        );

        return(<Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}>
            {props => <div className='createMobilePost' style={props}>
                {render}
            </div>
            }
        </Spring>);
    }

    fileDrop = (files, event) => {
        const mediaStore = this.props.mediaStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        if (mediaStore.mediaLoading) {
            notificationStore.add(text[17], text[18]);
            return true;
        }
        loadFileObrabotchik(files);
    }

    num2str(n, text_forms) {
        n = Math.abs(n) % 100; var n1 = n % 10;
        if (n > 10 && n < 20) { return text_forms[2]; }
        if (n1 > 1 && n1 < 5) { return text_forms[1]; }
        if (n1 == 1) { return text_forms[0]; }
        return text_forms[2];
    }

    componentDidMount() {

        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        if (mediaStore.accounts.length) {
            const idAccountActive = mediaStore.accounts[0].id;

            for (const key in accountsStore.list) {

                if (idAccountActive === accountsStore.list[key].id) {
                    accountsStore.updateActive(key);
                    break;
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        const idAccount = accountsStore.active;

        if (!mediaStore.open) {
            return null;
        }

        if (idAccount === null && mediaStore.accounts.slice().length) {

            for (const accountID in accountsStore.list.slice()) {
                if (accountsStore.list[accountID].id === mediaStore.accounts[0].id) {
                    //accountsStore.active = accountID;
                    break;
                }
            }
        }
    }

    onClickGeo () {
        const mediaStore = this.props.mediaStore;

        if (mediaStore.geo) {
            mediaStore.actionGeo(false);
        } else {
            mediaStore.actionGeo(true);
        }
    }

    onClickCommentOff () {
        const mediaStore = this.props.mediaStore;

        if (mediaStore.commentOff) {
            mediaStore.actionCommentOff(false);
        } else {
            mediaStore.actionCommentText('');
            mediaStore.actionComment(false);
            mediaStore.actionCommentOff(true);
        }
    }

    onClickComment () {
        const mediaStore = this.props.mediaStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        if (mediaStore.commentOff) {
            notificationStore.add(text[17], text[19]);
            return false;
        }

        if (mediaStore.comment) {
            mediaStore.actionCommentText('');
            mediaStore.actionComment(false);
        } else {
            mediaStore.actionComment(true);
        }
    }

    textProClick () {
        const mediaStore = this.props.mediaStore;

        if (mediaStore.textPro) {

            const accountsOK = mediaStore.accounts.slice();
            if (accountsOK.length) {
                for (let key in accountsOK) {
                    this.props.mediaStore.actionAccountUpdate(accountsOK[key].id, {text: accountsOK[0].text});
                }
            }

            mediaStore.actionTextPro(false);
        } else {
            mediaStore.actionTextPro(true);
        }
    }

    timeProClick () {
        const mediaStore = this.props.mediaStore;
        const accountsStore = this.props.accountsStore;

        const accounts = accountsStore.list.slice();

        if (mediaStore.timePro) {

            const accountsOK = mediaStore.accounts.slice();
            if (accountsOK.length) {

                if (accountsOK[0].del.status === 2) {
                    const socSetEnter = {
                        dr: 0,
                    };
                    for (const accountsOKOne of accountsOK) {
                        for (let keys in accounts) {
                            if (accounts[keys].id === accountsOKOne.id) {
                                if (accounts[keys].tip === 'fb') {
                                    socSetEnter.dr++;
                                } else if (accounts[keys].tip === 'tg') {
                                    socSetEnter.dr++;
                                } else if (accounts[keys].tip === 'ok') {
                                    socSetEnter.dr++;
                                } else if (accounts[keys].tip === 'vk') {
                                    socSetEnter.dr++;
                                } else if (accounts[keys].tip === 'twitter') {
                                    socSetEnter.dr++;
                                } else if (accounts[keys].tip === 'fb-instagram') {
                                    socSetEnter.dr++;
                                }
                            }
                        }
                    }

                    if (socSetEnter.dr) {
                        accountsOK[0].del.status = 1;
                    }
                }

                for (let key in accountsOK) {
                    this.props.mediaStore.actionAccountUpdate(accountsOK[key].id, {time: accountsOK[0].time, del: accountsOK[0].del});
                }
            }

            mediaStore.actionTimePro(false);
        } else {

            const accountsOK = mediaStore.accounts.slice();
            if (accountsOK.length) {
                for (let key in accountsOK) {
                    let typeTimetable = 'post';

                    if (mediaStore.type === 'story' || mediaStore.type === 'editStory') {
                        typeTimetable = 'story';
                    }

                    if (Time.TimetableStatus(accountsOK[key].id, typeTimetable)) {
                        const time = Time.dateTimetableGenerator(accountsOK[key].id, 'post')[0];
                        this.props.mediaStore.actionAccountUpdate(accountsOK[key].id, {time: time, del: accountsOK[0].del});
                    } else {
                        this.props.mediaStore.actionAccountUpdate(accountsOK[key].id, {time: accountsOK[0].time, del: accountsOK[0].del});
                    }

                }
            }

            mediaStore.actionTimePro(true);
        }
    }

    loadPost =  async (resolve) => {

        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        const type = mediaStore.type;

        const accountsOK = mediaStore.accounts.slice();
        const accounts = accountsStore.list.slice();

        if (mediaStore.obrabotkaMediaFiles) {
            notificationStore.add(text[230], text[231]);
            resolve(true);
            return;
        }

        //если нет файлов
        if (mediaStore.media.length === 0) {


            if (type === 'story' || type === 'igtv' || type === 'reels') {
                notificationStore.add(text[17], text[20]);
                resolve(true);
                return;
            }

            const socSetEnter = {
                instagram: 0,
                vk: 0,
                fb: 0,
                ok: 0,
                tg: 0,
                twitter: 0,
                fb_instagram: 0,
            };
            for (const accountsOKOne of accountsOK) {
                for (let keys in accounts) {
                    if (accounts[keys].id === accountsOKOne.id) {
                        if (accounts[keys].tip === 'fb') {
                            socSetEnter.fb++;
                        } else if (accounts[keys].tip === 'tg') {
                            socSetEnter.tg++;
                        } else if (accounts[keys].tip === 'ok') {
                            socSetEnter.ok++;
                        } else if (accounts[keys].tip === 'vk') {
                            socSetEnter.vk++;
                        } else if (accounts[keys].tip === 'instagram') {
                            socSetEnter.instagram++;
                        } else if (accounts[keys].tip === 'twitter') {
                            socSetEnter.twitter++;
                        } else if (accounts[keys].tip === 'fb-instagram') {
                            socSetEnter.fb_instagram++;
                        }
                    }
                }
            }

            if (socSetEnter.instagram || socSetEnter.fb_instagram) {
                notificationStore.add(text[17], text[21]);
                resolve(true);
                return;
            } else {
                if (mediaStore.accounts.slice().length) {
                    for (const account of mediaStore.accounts.slice()) {
                        if (account.text === '') {
                            notificationStore.add(text[17], text[22]);
                            resolve(true);
                            return;
                        }
                    }
                } else {
                    if (mediaStore.textSavePost === '') {
                        notificationStore.add(text[17], text[22]);
                        resolve(true);
                        return;
                    }
                }

            }
        }

        if (type === 'igtv' || type === 'igtvEdit') {
            /*if (mediaStore.name === '') {
                notificationStore.add(text[17], text[23]);
                resolve(true);
                return;
            }*/
        }

        if (type === 'story') {
            if (!mediaStore.accounts.length) {
                notificationStore.add(text[17], text[24]);
                resolve(true);
                return;
            }
        }

        if (type === 'igtv') {
            if (!mediaStore.accounts.length) {
                notificationStore.add(text[17], text[25]);
                resolve(true);
                return;
            }
        }

        if (type === 'reels') {
            if (!mediaStore.accounts.length) {
                notificationStore.add(text[17], text[201]);
                resolve(true);
                return;
            }
        }

        //Если это сохраненный пост
        if (mediaStore.accounts.slice().length === 0) {
            if (mediaStore.name === '') {
                notificationStore.add(text[17], text[23]);
                resolve(true);
                return;
            }
        }




        const minTime = (new Date() / 1000) - 50;
        for (const account of mediaStore.accounts.slice()) {
            if (account.time < minTime) {
                notificationStore.add(text[17], text[26]);
                resolve(true);
                return;
            }


            //Узнаем какие соц сети есть и показываем лимиты на текст
            let InstagramTrue = false;
            let VKTrue = false;
            let FBTrue = false;
            let OKTrue = false;
            let TGTrue = false;
            let TwitterTrue = false;
            let FbInstagramTrue = false;

            for (let keys in accounts) {
                if (accounts[keys].id === account.id) {
                    if (accounts[keys].tip === 'fb')                    FBTrue = true;
                    else if (accounts[keys].tip === 'tg')               TGTrue = true;
                    else if (accounts[keys].tip === 'ok')               OKTrue = true;
                    else if (accounts[keys].tip === 'vk')               VKTrue = true;
                    else if (accounts[keys].tip === 'instagram')        InstagramTrue = true;
                    else if (accounts[keys].tip === 'twitter')          TwitterTrue = true;
                    else if (accounts[keys].tip === 'fb-instagram')     FbInstagramTrue = true;

                    //проверяем время
                    if (account.time > accounts[keys].rights.rate) {
                        notificationStore.add(text[17], text[27](accounts[keys].name));
                        resolve(true);
                        return;
                    }
                }
            }

            const error = TextModel.limitedText(account.text, account.text+mediaStore.commentText, InstagramTrue, VKTrue, FBTrue, OKTrue, TGTrue, TwitterTrue, FbInstagramTrue).status;

            if (!error) {
                notificationStore.add(text[17], text[28]);
                resolve(true);
                return;
            }
        }

        if (mediaStore.name !== '') {
            const error = TextModel.limitedName(mediaStore.name).status;

            if (!error) {
                notificationStore.add(text[17], text[29]);
                resolve(true);
                return;
            }
        }

        let mediaLoad = false;
        let file = false;

        if (mediaStore.media.length > 0) {
            mediaLoad = [];
            file = {
                coverIndividual: [],
                elementsImage: [],
                elementsVideo: [],
            };

            for (const element of mediaStore.media) {

                if (element.loadPercent !== true) {
                    notificationStore.add(text[17], text[30]);
                    resolve(true);
                    return;
                }

                let duration = {...element.duration};

                delete(duration.icons);
                delete(duration.iconsCover);


                let elementMedia = {...element.elements};

                let icon = false;

                if (mediaStore.accounts.slice().length === 1) {
                    for (let element of accounts) {
                        if (element.id === mediaStore.accounts[0].id) {
                            icon = element.img;
                            break;
                        }
                    }
                }

                if (elementMedia.image.media) {
                    const newCanvas = document.createElement("canvas");
                    await Fabric.start(newCanvas, 400, Math.ceil(400 / element.proportionsBackground), elementMedia.image, () => {}, () => {}, () => {}, icon, false);
                    const image = await Fabric.createImg();
                    const blob = await fileClass.createBlobPre(image);

                    file.elementsImage[element.id] = blob.blob;
                }

                if (elementMedia.video.media) {
                    const newCanvas = document.createElement("canvas");
                    await Fabric.start(newCanvas, 400, Math.ceil(400 / element.proportionsBackground), elementMedia.video, () => {}, () => {}, () => {}, icon, false);
                    const image = await Fabric.createImg();
                    const blob = await fileClass.createBlobPre(image);

                    file.elementsVideo[element.id] = blob.blob;
                } else {
                    if (elementMedia.image.gif) {
                        if (element.type === 'image' && elementMedia.image.gif.length) {

                            const newCanvas = document.createElement("canvas");
                            await Fabric.start(newCanvas, 400, Math.ceil(400 / element.proportionsBackground), elementMedia.image, () => {}, () => {}, () => {}, icon, false);
                            const image = await Fabric.createImg();
                            const blob = await fileClass.createBlobPre(image);

                            file.elementsVideo[element.id] = blob.blob;
                        }
                    }
                }





                mediaLoad[mediaLoad.length] = {
                    id: element.id,
                    type: element.type,
                    proportions: element.proportions,
                    proportionsBackground: element.proportionsBackground,
                    zoom: element.zoom,
                    crop: element.crop,
                    draggable: element.draggable,
                    duration: duration,
                    color: element.color,
                    accounts: element.accounts,
                    elements: elementMedia,
                    coverIndividual: mediaStore.IgtvImage ? true:false,
                    soundDelite: element.soundDelite,
                }



                //подготавливаем обложку для видео
                if (element.type === 'video') {
                    //const blob = await fileClass.createBlobPre(element.cover);
                    //file.cover[element.id] = blob;
                }

                if (mediaStore.type === 'igtv' || mediaStore.type === 'editIgtv' || mediaStore.type === 'reels' || mediaStore.type === 'editReels') {
                    if (mediaStore.IgtvImage) {
                        const blob = await fileClass.createBlobPre(mediaStore.IgtvImage.url);
                        file.coverIndividual[element.id] = blob.blob;
                    }
                }
            }
        }


        let geo = false;

        if (mediaStore.geoObject.id!== false && mediaStore.geo) {
            geo = mediaStore.geoObject.array[mediaStore.geoObject.id];
        }

        let geoPro = false;

        if (mediaStore.geoFull) {
            geoPro = [];
            for (let geoObjectFullElement of mediaStore.geoObjectFull) {
                geoPro.push({
                    id: geoObjectFullElement.idAccount,
                    geo: geoObjectFullElement.array[geoObjectFullElement.id],
                })
            }
        }


        const json = {
            media: mediaLoad,
            idLoad: mediaStore.idLoad,
            accounts: mediaStore.accounts,
            text: mediaStore.textSavePost,
            name: mediaStore.name,
            commentOff: mediaStore.commentOff,
            commentText: mediaStore.commentText,
            geo: geo,
            geoPro: geoPro,
            category: mediaStore.category,
            type: type,
            urlStory: mediaStore.urlStory,
            previewPost: mediaStore.PrivievPost,
            previewStory: mediaStore.ReStory,
        };

        if(geo || geoPro) {
            const accountsOK = mediaStore.accounts.slice();
            const accounts = accountsStore.list.slice();

            for (let key in accountsOK) {
                for (let keys in accounts) {
                    if (accountsOK[key].id === accounts[keys].id) {
                        let newListHeshGeo = [];

                        if(geo) {
                            newListHeshGeo.push({...geo});
                        } else {
                            for (let geoProElement of geoPro) {
                                if (geoProElement.id === accountsOK[key].id) {
                                    newListHeshGeo.push({...geoProElement.geo});
                                }
                            }
                        }

                        let max = 0;
                        for (let geoElement of accounts[keys].hash_geo.slice()) {
                            max++;
                            if (max >= 9) {
                                break;
                            }
                            if (geoElement.name !== geo.name) {
                                newListHeshGeo.push({...geoElement});
                            }
                        }
                        accountsStore.list[keys].hash_geo = newListHeshGeo;

                    }
                }
            }
        }

        APIposts.setPost2(json, file).then((data) => {
            if (!data.status) {
                notificationStore.add(text[17], initialStore.text.code[data.code]);
                resolve(true);
                return;
            }

            const idAccount = accountsStore.active;

            if (idAccount) {
                const idAccountReal = accountsStore.list[idAccount].id;
                const idAccountRealHashPost = accountsStore.list[idAccount].post.hash;
                const idAccountRealHashStory = accountsStore.list[idAccount].story.hash;

                accountsStore.getPostLoad(idAccount);
                accountsStore.getStoryLoad(idAccount);

                APIposts.getPosts(idAccountReal, idAccountRealHashPost, idAccountRealHashStory).then((data) => {

                    accountsStore.getPost(idAccount, data.hashPosts, data.posts, data.rate);
                    accountsStore.getStory(idAccount, data.hashStory, data.story);

                    this.closePostDel();
                    //
                    notificationStore.add('', text[31]);
                    resolve(true);

                }).catch((error) => {
                    this.closePostDel();
                    notificationStore.add(text[17], text[32]);
                    resolve(true);
                });
            } else {
                const libraryStore = this.props.libraryStore;

                APIposts.getLibrary(libraryStore.hash).then((dataLibrary) => {
                    //сохраняем посты
                    mainStore.libraryStore.getPost(dataLibrary.hashPosts, dataLibrary.posts);

                    mainStore.libraryStore.getCategories(dataLibrary.categories);

                    this.closePostDel();
                    notificationStore.add('', text[33]);
                    resolve(true);
                }).catch((error) => {
                    this.closePostDel();
                    notificationStore.add(text[17], text[32]);
                    resolve(true);
                });
            }
        });
    }

    editPost =  async (resolve) => {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;



        let mediaLoad = false;

        let type = 'post';

        const accountsOK = mediaStore.accounts.slice();
        const accounts = accountsStore.list.slice();

        if (mediaStore.obrabotkaMediaFiles) {
            notificationStore.add(text[230], text[231]);
            resolve(true);
            return;
        }

        const minTime = (new Date() / 1000) - 50;
        for (const account of mediaStore.accounts.slice()) {
            if (account.time < minTime) {
                notificationStore.add(text[17], text[26]);
                resolve(true);
                return;
            }


            //Узнаем какие соц сети есть и показываем лимиты на текст
            let InstagramTrue = false;
            let VKTrue = false;
            let FBTrue = false;
            let OKTrue = false;
            let TGTrue = false;
            let TwitterTrue = false;

            for (let keys in accounts) {
                if (accounts[keys].id === account.id) {
                    if (accounts[keys].tip === 'fb')                FBTrue = true;
                    else if (accounts[keys].tip === 'tg')           TGTrue = true;
                    else if (accounts[keys].tip === 'ok')           OKTrue = true;
                    else if (accounts[keys].tip === 'vk')           VKTrue = true;
                    else if (accounts[keys].tip === 'instagram')    InstagramTrue = true;
                    else if (accounts[keys].tip === 'twitter')      TwitterTrue = true;

                    //проверяем время
                    if (account.time > accounts[keys].rights.rate) {
                        notificationStore.add(text[17], text[27](accounts[keys].name));
                        resolve(true);
                        return;
                    }
                }
            }

            const error = TextModel.limitedText(account.text, account.text+mediaStore.commentText, InstagramTrue, VKTrue, FBTrue, OKTrue, TGTrue, TwitterTrue).status;

            if (!error) {
                notificationStore.add(text[17], text[28]);
                resolve(true);
                return;
            }
        }

        if (mediaStore.name !== '') {
            const error = TextModel.limitedName(mediaStore.name).status;

            if (!error) {
                notificationStore.add(text[17], text[29]);
                resolve(true);
                return;
            }
        }

        if (mediaStore.media.length === 0 && mediaStore.mediaLoading === false) {

            if (type === 'story' || type === 'storyEdit' || type === 'igtv' || type === 'igtvEdit' || type === 'reels' || type === 'reelsEdit') {
                notificationStore.add(text[17], text[20]);
                resolve(true);
                return;
            }

            const socSetEnter = {
                instagram: 0,
                vk: 0,
                fb: 0,
                ok: 0,
                tg: 0,
                twitter: 0,
            };
            for (const accountsOKOne of accountsOK) {
                for (let keys in accounts) {
                    if (accounts[keys].id === accountsOKOne.id) {
                        if (accounts[keys].tip === 'fb') {
                            socSetEnter.fb++;
                        } else if (accounts[keys].tip === 'tg') {
                            socSetEnter.tg++;
                        } else if (accounts[keys].tip === 'ok') {
                            socSetEnter.ok++;
                        } else if (accounts[keys].tip === 'vk') {
                            socSetEnter.vk++;
                        } else if (accounts[keys].tip === 'instagram') {
                            socSetEnter.instagram++;
                        } else if (accounts[keys].tip === 'twitter') {
                            socSetEnter.twitter++;
                        } else if (accounts[keys].tip === 'fb-instagram') {
                            socSetEnter.fb_instagram++;
                        }
                    }
                }
            }

            if (socSetEnter.instagram || socSetEnter.fb_instagram) {
                notificationStore.add(text[17], text[21]);
                resolve(true);
                return;
            } else {
                if (mediaStore.accounts.slice().length) {
                    for (const account of mediaStore.accounts.slice()) {
                        if (account.text === '') {
                            notificationStore.add(text[17], text[22]);
                            resolve(true);
                            return;
                        }
                    }
                } else {
                    if (mediaStore.textSavePost === '') {
                        notificationStore.add(text[17], text[22]);
                        resolve(true);
                        return;
                    }
                }

            }
        }

        if (mediaStore.type === 'igtv' || mediaStore.type === 'editIgtv') {
            if (mediaStore.name === '') {
                notificationStore.add(text[17], text[23]);
                resolve(true);
                return;
            }
        }

        //Если это сохраненный пост
        if (mediaStore.accounts.slice().length === 0) {
            if (mediaStore.name === '') {
                notificationStore.add(text[17], text[23]);
                resolve(true);
                return;
            }

            if (mediaStore.textSavePost === '') {
                notificationStore.add(text[17], text[22]);
                resolve(true);
                return;
            }

        }

        if (mediaStore.type === 'editStory') {
            type = 'story';
        } else if (mediaStore.type === 'editIgtv') {
            type = 'igtv';
        } else if (mediaStore.type === 'editReels') {
            type = 'reels';
        }

        let file = {
            coverIndividual: [],
            elementsImage: [],
            elementsVideo: [],
        };

        if (mediaStore.mediaLoading !== true && mediaStore.media.length === 0) {
            if (mediaStore.type === 'edit' && mediaStore.accounts.slice().length === 0) {
                mediaStore.mediaLoading = true;
            }
        }

        if (mediaStore.mediaLoading !== true) {
            if (mediaStore.media.length === 0) {
                notificationStore.add(text[17], text[21]);
                resolve(true);
                return;
            }

            mediaLoad = [];

            for (const element of mediaStore.media) {

                if (element.loadPercent !== true) {
                    notificationStore.add(text[17], text[30]);
                    resolve(true);
                    return;
                }

                let duration = {...element.duration};

                delete(duration.icons);
                delete(duration.iconsCover);

                let elementMedia = {...element.elements};

                let icon = false;

                if (mediaStore.accounts.slice().length === 1) {
                    for (let element of accounts) {
                        if (element.id === mediaStore.accounts[0].id) {
                            icon = element.img;
                            break;
                        }
                    }
                }


                if (elementMedia.image.media) {
                    const newCanvas = document.createElement("canvas");
                    await Fabric.start(newCanvas, 400, Math.ceil(400 / element.proportionsBackground), elementMedia.image, () => {}, () => {}, () => {}, icon, false);
                    const image = await Fabric.createImg();
                    const blob = await fileClass.createBlobPre(image);
                    file.elementsImage[element.id] = blob.blob;
                }

                if (elementMedia.video.media) {
                    const newCanvas = document.createElement("canvas");
                    await Fabric.start(newCanvas, 400, Math.ceil(400 / element.proportionsBackground), elementMedia.video, () => {}, () => {}, () => {}, icon, false);
                    const image = await Fabric.createImg();
                    const blob = await fileClass.createBlobPre(image);

                    file.elementsVideo[element.id] = blob.blob;
                } else {
                    if (elementMedia.image.gif) {
                        if (element.type === 'image' && elementMedia.image.gif.length) {

                            const newCanvas = document.createElement("canvas");
                            await Fabric.start(newCanvas, 400, Math.ceil(400 / element.proportionsBackground), elementMedia.image, () => {}, () => {}, () => {}, icon, false);
                            const image = await Fabric.createImg();
                            const blob = await fileClass.createBlobPre(image);

                            file.elementsVideo[element.id] = blob.blob;
                        }
                    }
                }

                mediaLoad[mediaLoad.length] = {
                    id: element.id,
                    type: element.type,
                    proportions: element.proportions,
                    proportionsBackground: element.proportionsBackground,
                    zoom: element.zoom,
                    crop: element.crop,
                    draggable: element.draggable,
                    duration: duration,
                    color: element.color,
                    accounts: element.accounts,
                    elements: element.elements,
                    coverIndividual: mediaStore.IgtvImage ? true:false,
                    soundDelite: element.soundDelite,
                }



                //подготавливаем обложку для видео
                /*if (element.type === 'video') {
                    const blob = await fileClass.createBlobPre(element.cover);
                    file.cover[element.id] = blob;
                }*/

                if (mediaStore.type === 'igtv' || mediaStore.type === 'editIgtv' || mediaStore.type === 'reels' || mediaStore.type === 'editReels') {
                    if (mediaStore.IgtvImage) {
                        const blob = await fileClass.createBlobPre(mediaStore.IgtvImage.url);
                        file.coverIndividual[element.id] = blob.blob;
                    }
                }

            }
        }

        let geo = false;
        if (mediaStore.geoObject.id!== false && mediaStore.geo) {
            geo = mediaStore.geoObject.array[mediaStore.geoObject.id];
        }

        const json = {
            media: mediaLoad,
            idLoad: mediaStore.idLoad,
            accounts: mediaStore.accounts,
            name: mediaStore.name,
            text: mediaStore.textSavePost,
            commentOff: mediaStore.commentOff,
            commentText: mediaStore.commentText,
            geo: geo,
            category: mediaStore.category,
            id: mediaStore.id,
            code: mediaStore.code,
            type: type,
            urlStory: mediaStore.urlStory,
            previewPost: mediaStore.PrivievPost,
            previewStory: mediaStore.ReStory,
        };

        APIposts.setEditor(json, file).then((data) => {
            if (!data.status) {
                notificationStore.add(text[17], text[34](data.code));
                resolve(true);
                return;
            }

            const idAccount = accountsStore.active;

            if (idAccount) {
                const idAccountReal = accountsStore.list[idAccount].id;
                const idAccountRealHashPost = accountsStore.list[idAccount].post.hash;
                const idAccountRealHashStory = accountsStore.list[idAccount].story.hash;

                accountsStore.getPostLoad(idAccount);
                accountsStore.getStoryLoad(idAccount);

                APIposts.getPosts(idAccountReal, idAccountRealHashPost, idAccountRealHashStory).then((data) => {

                    accountsStore.getPost(idAccount, data.hashPosts, data.posts, data.rate);
                    accountsStore.getStory(idAccount, data.hashStory, data.story);

                    this.closePostDel();
                    //
                    notificationStore.add('', text[35]);
                    resolve(true);
                }).catch((error) => {
                    this.closePostDel();
                    notificationStore.add(text[17], text[36]);
                    resolve(true);
                });
            } else {
                const libraryStore = this.props.libraryStore;

                APIposts.getLibrary(libraryStore.hash).then((dataLibrary) => {
                    //сохраняем посты
                    mainStore.libraryStore.getPost(dataLibrary.hashPosts, dataLibrary.posts);

                    mainStore.libraryStore.getCategories(dataLibrary.categories);

                    this.closePostDel();
                    notificationStore.add('', text[35]);
                    resolve(true);
                }).catch((error) => {
                    this.closePostDel();
                    notificationStore.add(text[17], text[36]);
                    resolve(true);
                });
            }
        }).catch(() => {
            notificationStore.add(text[17], text[37]);
            resolve(true);
        });
    }

    closePost () {
        this.props.mediaStore.actionOpen(false, true);
    }

    closePostDel (props = false) {

        for (let element of this.props.mediaStore.media.slice()) {
            file.deliteURLFile(element.url);
        }

        this.props.mediaStore.actionDefoult();

        if (props) {
            props(true);
        }

    }

}

export default Post;
