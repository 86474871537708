import qs from "qs";
import React from "react";

import instance from "./base";

const APIaccountsTg = {

    getAdd (token, chanel) {
        return instance.post('/accountstg', qs.stringify({
            token: token,
            chanel: chanel,
        })).then(data => {
            return data;
        });
    },
}

export default APIaccountsTg;