import React from 'react';
import {inject, observer} from "mobx-react";
import Accounts from "../../component/post/component/Accounts";
import Text from "../../component/post/component/Text";
import Comment from "../../component/post/component/Comment";
import Checkbox from "../elements/checkbox";
import ButtonDel from "../elements/buttonDel";
import Button from "../elements/button";
import AccountsData from "../../component/post/component/AccountsData";
import FilesLoading from "../../component/post/component/FilesLoading";
import LoadFile from "../../component/post/component/LoadFile";
import MediaIcons from "../../component/post/component/MediaIcons";
import Name from "../../component/post/component/Name";
import Category from "../../component/post/component/Category";
import ButtonLoad from "../elements/buttonLoad";
import ButtonMobileOpenAccounts from "../../component/post/ButtonMobileOpenAccounts";
import IgtvImage from "../../component/post/component/IgtvImage";
import GeoSearhFull from "../../component/post/component/GeoSearhFull";

//Загрузка файлов перетаскиванием
import { FileDrop } from 'react-file-drop';
import PrivievPost from "../../component/post/component/PrivievPost";
import ReStory from "../../component/post/component/ReStory";


export default
@inject('initialStore', 'mediaStore')
@observer
class PostTemplate extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        if (initialStore.templates === 'desktop') {

            return(
                <>
                    <FileDrop onDrop={this.props.fileDrop}>
                        <div className="file-drop-dragging-over-frame-center-box">{text[38]}</div>
                    </FileDrop>
                    <div className="main">

                        <div className="main-wrapper">

                            <section className="lk view-2">

                                <Accounts/>

                                <div className="content">
                                    <div className="content-inner">

                                        {this.props.infoRedAccount ?
                                        <div className="chief__typical h1 info-type-account">
                                            <h1><span className="color">{text[39]}</span>
                                                <div className="img-user">
                                                    <span className="social-user"><img src={this.props.infoRedAccount.icon}/></span>
                                                    <span className="inner-img"><img src={this.props.infoRedAccount.img}/></span>
                                                </div>
                                                <div className="info-type-account-name">{this.props.infoRedAccount.name}</div>
                                            </h1>
                                        </div>
                                        :null}
                                        <div className="chief__typical h1 size-2">
                                            <h1>{this.props.nameBox}</h1>
                                        </div>

                                        <div className="add-publication">
                                            <div className="groups-publication">
                                                <div className="group-publication">
                                                    <div className="add-publication__text">
                                                        <div className="form-group">
                                                            <Name/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="group-publication">
                                                    <div className="add-publication__text">
                                                        <div className="form-group">
                                                            <Category/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="group-publication">
                                                    <div className="add-publication__text">
                                                        <div className="form-group">
                                                            <Text/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="group-publication">
                                                    <div className="add-publication__photo">
                                                        <div className="more-btn">
                                                            {this.props.mediaLoadingStatus ?
                                                                <FilesLoading/>
                                                                :
                                                                <LoadFile/>
                                                            }

                                                        </div>
                                                        {this.props.mediaLoadingStatus ? null :
                                                            <>
                                                                <MediaIcons/>
                                                            </>
                                                        }
                                                    </div>
                                                </div>



                                                <IgtvImage/>

                                                {this.props.viewComentAndGeo && (this.props.type === 'post' || this.props.type === 'edit' || this.props.type === 'igtv' || this.props.type === 'editIgtv' || this.props.type === 'reels' || this.props.type === 'editReels') ?
                                                    <>
                                                        <div className="group-publication">
                                                            <div className="add-publication__comments">
                                                                <div className="form-group">
                                                                    <div className="form-check-wrap">
                                                                        <div className="form-check">
                                                                            <div className="column-check">
                                                                                <span className="title-check">{text[41]}{this.props.viewComentAndGeo === 2 ? <span className="info-dos-soc-set"> {text[40]}</span>:null}</span>
                                                                                <p className="desc-check">{text[42]}</p>
                                                                            </div>
                                                                            <Checkbox onChange={this.props.oneCommentOnAction}>{this.props.oneCommentOn}</Checkbox>
                                                                        </div>
                                                                        <Comment/>
                                                                        <div className="form-check">
                                                                            <div className="column-check">
                                                                                <span className="title-check">{text[43]}{this.props.viewComentAndGeo === 2 ? <span className="info-dos-soc-set"> {text[40]}</span>:null}</span>
                                                                                <p className="desc-check">{text[44]}</p>
                                                                            </div>
                                                                            <Checkbox onChange={this.props.commentOffAction}>{this.props.commentOff}</Checkbox>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </>
                                                :null}

                                                {this.props.viewComentAndGeo && (this.props.type === 'post' || this.props.type === 'edit') ?
                                                <div className="group-publication">
                                                    <div className="add-publication__geo">
                                                        <div className="form-group">
                                                            <div className="form-check-wrap">
                                                                <div className="form-check">
                                                                    <div className="column-check">
                                                                        <span className="title-check">{text[45]}{this.props.viewComentAndGeo === 2 ? <span className="info-dos-soc-set"> {text[40]}</span>:null}</span>
                                                                        <p className="desc-check">{text[46]}</p>
                                                                    </div>
                                                                    <Checkbox onChange={this.props.geoAction}>{this.props.geo}</Checkbox>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <GeoSearhFull/>
                                                    </div>
                                                </div>
                                                :null}

                                                <PrivievPost/>

                                                <ReStory/>

                                                <div className="group-publication">
                                                    <div className="add-publication__settings">
                                                        <div className="form-group">
                                                            <div className="form-check-wrap">
                                                                { this.props.textProAndTimeProStatus ?
                                                                    <>
                                                                        {this.props.type === 'post' || this.props.type === 'igtv' || this.props.type === 'reels' ?
                                                                            <div className="form-check">
                                                                                <div className="column-check">
                                                                                    <span className="title-check">{text[47]}</span>
                                                                                    <p className="desc-check">{text[48]}</p>
                                                                                </div>
                                                                                <Checkbox onChange={this.props.textProAction}>{this.props.textPro}</Checkbox>
                                                                            </div>
                                                                        :null}

                                                                        <div className="form-check">
                                                                            <div className="column-check">
                                                                                <span className="title-check">{text[49]}</span>
                                                                                <p className="desc-check">{text[50]}</p>
                                                                            </div>
                                                                            <Checkbox onChange={this.props.timeProAction}>{this.props.timePro}</Checkbox>
                                                                        </div>
                                                                    </>
                                                                :null}
                                                            </div>
                                                        </div>

                                                        <AccountsData/>

                                                    </div>
                                                </div>


                                                <div className="group-publication">
                                                    <div className="add-publication__button">
                                                        <div className="group-settings">
                                                            <div className="column-group w-25" style={{padding: '0 4px 0 8px'}}>
                                                                <ButtonDel style={{width: '100%'}} action={this.props.buttomCloseAction}>postClose</ButtonDel>
                                                            </div>
                                                            <div className="column-group w-25" style={{padding: '0 4px'}}>
                                                                <Button style={{width: '100%'}} action={this.props.buttomCollapseAction}>collapse</Button>
                                                            </div>
                                                            <div className="column-group w-50" style={{padding: '0 8px 0 4px'}}>
                                                                <ButtonLoad style={{width: '100%'}} color action={(promise) => {this.props.buttonOkAction(promise)}}>{this.props.buttonOkName}</ButtonLoad>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>

                    </div>
                    {this.props.modalEditor}
                </>
            );
        } else {

            const mediaStore = this.props.mediaStore;

            return(
                <>
                    <div className={mediaStore.openMenuMobile ? "main content-open-menu" : "main"}>

                        <div className="main-wrapper">

                            <Accounts/>
                            <div className="mobile-block-lk"></div>
                            <section className="lk view-1">

                                <aside className="sidebar">
                                    <div className="sidebar-control">
                                        <div className="sidebar-control__nav1">

                                            <ButtonMobileOpenAccounts/>

                                            {this.props.nameBox}
                                        </div>
                                    </div>
                                </aside>

                                <div className="content">

                                    <div className="content-inner">

                                        {this.props.infoRedAccount ?
                                        <div className="chief__typical h1 info-type-account">
                                            <h1>
                                                <div className="img-user">
                                                    <span className="social-user"><img src={this.props.infoRedAccount.icon}/></span>
                                                    <span className="inner-img"><img src={this.props.infoRedAccount.img}/></span>
                                                </div>
                                                <div className="info-type-account-name">{this.props.infoRedAccount.name}</div>
                                            </h1>

                                        </div>
                                        :null}

                                        <div className="add-publication">
                                            <div className="groups-publication">
                                                <div className="group-publication">
                                                    <div className="add-publication__text">
                                                        <div className="form-group">
                                                            <Name/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="group-publication">
                                                    <div className="add-publication__text">
                                                        <div className="form-group">
                                                            <Category/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="group-publication">
                                                    <div className="add-publication__text">
                                                        <div className="form-group">
                                                            <Text/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="group-publication">
                                                    <div className="add-publication__photo">
                                                        <div className="more-btn">
                                                            {this.props.mediaLoadingStatus ?
                                                                <FilesLoading/>
                                                                :
                                                                <LoadFile/>
                                                            }

                                                        </div>
                                                        {this.props.mediaLoadingStatus ? null :
                                                            <>
                                                                <MediaIcons/>
                                                            </>
                                                        }
                                                    </div>
                                                </div>

                                                <IgtvImage/>

                                                {this.props.viewComentAndGeo && (this.props.type === 'post' || this.props.type === 'edit' || this.props.type === 'igtv' || this.props.type === 'editIgtv' || this.props.type === 'reels' || this.props.type === 'editReels') ?
                                                    <>
                                                        <div className="group-publication">
                                                            <div className="add-publication__comments">
                                                                <div className="form-group">
                                                                    <div className="form-check-wrap">
                                                                        <div className="form-check">
                                                                            <div className="column-check">
                                                                                <span className="title-check">{text[41]}{this.props.viewComentAndGeo === 2 ? <span className="info-dos-soc-set"> {text[40]}</span>:null}</span>
                                                                                <p className="desc-check">{text[42]}</p>
                                                                            </div>
                                                                            <Checkbox onChange={this.props.oneCommentOnAction}>{this.props.oneCommentOn}</Checkbox>
                                                                        </div>
                                                                        <Comment/>
                                                                        <div className="form-check">
                                                                            <div className="column-check">
                                                                                <span className="title-check">{text[43]}{this.props.viewComentAndGeo === 2 ? <span className="info-dos-soc-set"> {text[40]}</span>:null}</span>
                                                                                <p className="desc-check">{text[44]}</p>
                                                                            </div>
                                                                            <Checkbox onChange={this.props.commentOffAction}>{this.props.commentOff}</Checkbox>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.props.viewComentAndGeo && (this.props.type === 'post' || this.props.type === 'edit') ?
                                                        <div className="group-publication">
                                                            <div className="add-publication__geo">
                                                                <div className="form-group">
                                                                    <div className="form-check-wrap">
                                                                        <div className="form-check">
                                                                            <div className="column-check">
                                                                                <span className="title-check">{text[45]}{this.props.viewComentAndGeo === 2 ? <span className="info-dos-soc-set"> {text[40]}</span>:null}</span>
                                                                                <p className="desc-check">{text[46]}</p>
                                                                            </div>
                                                                            <Checkbox onChange={this.props.geoAction}>{this.props.geo}</Checkbox>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <GeoSearhFull/>
                                                            </div>
                                                        </div>
                                                        :null}
                                                    </>
                                                    :null
                                                }

                                                <PrivievPost/>

                                                <ReStory viewComentAndGeo={this.props.viewComentAndGeo}/>

                                                <div className="group-publication">
                                                    <div className="add-publication__settings">
                                                        <div className="form-group">
                                                            <div className="form-check-wrap">

                                                                { this.props.textProAndTimeProStatus ?
                                                                    <>
                                                                        {this.props.type === 'post' || this.props.type === 'igtv' || this.props.type === 'reels' ?
                                                                            <div className="form-check">
                                                                                <div className="column-check">
                                                                                    <span className="title-check">{text[47]}</span>
                                                                                    <p className="desc-check">{text[48]}</p>
                                                                                </div>
                                                                                <Checkbox onChange={this.props.textProAction}>{this.props.textPro}</Checkbox>
                                                                            </div>
                                                                            :null}

                                                                        <div className="form-check">
                                                                            <div className="column-check">
                                                                                <span className="title-check">{text[49]}</span>
                                                                                <p className="desc-check">{text[50]}</p>
                                                                            </div>
                                                                            <Checkbox onChange={this.props.timeProAction}>{this.props.timePro}</Checkbox>
                                                                        </div>
                                                                    </>
                                                                    :null}
                                                            </div>
                                                        </div>

                                                        <AccountsData/>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="more-btn bottom">

                                                <ButtonLoad color action={(promise) => {this.props.buttonOkAction(promise)}}>{this.props.buttonOkName}</ButtonLoad>

                                                {this.props.buttomCollapseStatus ?
                                                    <>
                                                        <div className="w-100" style={{display: 'flex'}}>
                                                            <div className="column-group w-50" style={{paddingRight: '5px'}}>
                                                                <Button action={this.props.buttomCollapseAction}>collapse</Button>
                                                            </div>
                                                            <div className="column-group w-50" style={{paddingLeft: '5px'}}>
                                                                <ButtonDel action={this.props.buttomCloseAction}>postClose</ButtonDel>
                                                            </div>
                                                        </div>
                                                    </>
                                                :
                                                    <ButtonDel action={this.props.buttomCloseAction}>postClose</ButtonDel>
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>

                    </div>
                    {this.props.modalEditor}
                </>
            );
        }

    }
}
