import React from 'react';
import {inject, observer} from "mobx-react";

export default
@inject('initialStore')
@observer
class ElementMenuCreatePostButtonMobile extends React.Component {

    render() {

        return (
            <div id="button_click_add_post" onClick={this.props.open}>
                <span className="icon-item"></span>
                {/*<span className="title-item">Создать пост</span>*/}
            </div>
        );

    }
}

