import React from 'react';
import {Route, Switch} from "react-router";
import {inject, observer} from "mobx-react";

import HeaderWelcome from "./HeaderWelcome";
import HeaderDownloadpost from "./HeaderDownloadpost";
import HeaderHelp from "./HeaderHelp";
import HeaderLibrary from "./HeaderLibrary";
import HeaderRate from "./HeaderRate";
import HeaderSettings from "./HeaderSettings";
import HeaderAccount from "./HeaderAccount";

import HeaderBox from "../../templates/header/box";
import {observable} from "mobx";

@inject("mediaStore")
@inject("accountsStore")
@observer
class Header extends React.Component {


    render() {
        return (
            <Switch>
                <Route exact path="/" component={HeaderWelcome}></Route>
                <Route exact path="/welcome" component={HeaderWelcome}></Route>
                <Route path="/downloadpost" component={HeaderDownloadpost}></Route>
                <Route path="/help" component={HeaderHelp}></Route>
                <Route path="/library" component={HeaderLibrary}></Route>
                <Route path="/rate" component={HeaderRate}></Route>
                <Route path="/settings" component={HeaderSettings}></Route>
                <Route path="/account/:idAccount" component={HeaderAccount}></Route>
            </Switch>
        );
    }
}
export default Header;