import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import insertTextAtCursor from "insert-text-at-cursor";

import ReactDOM from "react-dom";

export default
@inject('initialStore')
@observer
class Password extends React.Component {

    @observable look = true;

    constructor(props) {
        super(props);

        this.buttonOpenEmoje = React.createRef();
        this.PickerRef = React.createRef();

        this.textarea = React.createRef();

        const initialStore = this.props.initialStore;
        if (initialStore.platform === 'android' || initialStore.platform === 'ios') {
            this.look = false;
        }
    }
    render() {
        const initialStore = this.props.initialStore;

        let classInput = 'form-control';
        if (this.props.className) {
            classInput = this.props.className;
        }

        let disabled = false;
        if (this.props.load) {
            classInput+=" btn-primary-animate-content";
            disabled = true;
        }

        let input =
            <div className="inputDefoult">
                <input
                type={this.look ? "value":"PASSWORD"}
                autocomplete="OFF"
                style={this.props.style}
                placeholder={this.props.placeholder}
                className={classInput}
                onChange={(event => this.onChange(event.target.value))}
                value={this.props.children}
                ref={this.textarea}
                disabled={disabled}
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
                name={"WELCOME_bound_mainPanel_loginPanel_password"}
                handlenativeevents={"false"}
            />

            <div className={this.look ? "text-box-pass-box text-box-pass-box-true":"text-box-pass-box"} onMouseDown={this.onMouseDown} onTouchStart={this.onMouseDown} onTouchEnd={this.onMouseUp} onMouseUp={this.onMouseUp} onMouseOut={this.onMouseUp}></div>
            </div>;

        return input;

    }

    onChange = (value) => {
        if (this.props.children === '' && this.look) {
            this.look = false;
            //this.textarea.current.focus();
        }
        this.props.onChange(value);
    }

    onMouseDown = () => {
        this.look = true;
    }

    onMouseUp = () => {
        this.look = false;
    }
}