import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

export default
@inject('initialStore')
@observer
class PostBasic extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.post;


        let image = null;
        if (this.props.image) {
            image = (
                <div className="inner-img">
                    <div className="wrap-img">
                       <img src={'https://likepro.ru/cloud/'+this.props.image}/>
                    </div>
                </div>
            );

            if (this.props.statusPost) {

                if (this.props.statusPost === 'ojered') {
                    image = (
                        <div className="inner-img btn-primary-animate-content">
                            {/*<div className="wrap-img-Status">в очереди...</div>*/}
                        </div>
                    );

                } else if (this.props.statusPost === 'error') {
                    image = (
                        <div className="inner-img">
                            <div className="wrap-img-Status">{text[2]}</div>
                        </div>
                    );
                } else if (this.props.statusPost === 'load') {
                    image = (
                        <div className="inner-img btn-primary-animate-content">
                            {/*<div className="wrap-img-Status2">
                                <div>обработка...</div>
                            </div>
                            <div className="wrap-img-Status3">
                                <div>{this.props.statusInfo}</div>
                                <div>{this.props.statusProcent}</div>
                            </div>*/}
                        </div>
                    );

                }

            }
        }

        let tip = null;

        if (this.props.tip === 'video') {
            tip = <span className="type-post"><i className="icon icon-post-video"></i></span>;
        } else if (this.props.tip === 'gallery') {
            tip = <span className="type-post"><i className="icon icon-post-img"></i></span>;
        } else if (this.props.tip === 'igtv') {
            tip = <span className="type-post"><i className="icon icon-post-igtv"></i></span>;
        } else if (this.props.tip === 'reels') {
            tip = <span className="type-post"><i className="icon icon-post-reels"></i></span>;
        }

        let delite = null;

        if (this.props.delite) {
            delite = <div className="post-danger">
                <span className="icon-danger"><i className="icon icon-post-delite"></i></span>
                <span className="drop-danger">{this.props.delite}</span>
            </div>;
        }

        let time = this.props.time;

        if (this.props.status) {
            time = <span style={{color: '#ff7075'}}>{text[3]}</span>;
        }

        if (this.props.url) {
            time = text[5];
        }

        let moderation = null;
        if (this.props.moderation) {
            moderation = <div className="element-info-moderation">{text[4]}</div>;
        }

        if (initialStore.templates === 'desktop') {

            let textBox = this.props.text;
            if (this.props.text !== '' ) {
                textBox = window.emojiOne(textBox);
            }

            return (
                <div key={this.props.key} className="col">
                    <div className={!this.props.story ? "post post-base":"post post-history"} onClick={this.props.click}>
                        <div className="img-post">
                            {moderation}

                            {tip}

                            {image}

                            {this.props.user ?
                            <div className="user-post" style={{backgroundImage: `url(https://likepro.ru${this.props.user.img})`}}></div>
                            :null}

                            {this.props.url ?
                            <a href={this.props.url} target="_blank" className="share-post"><i className="icon icon-abs icon-share"></i></a>
                            :null}

                        </div>
                        <div className={this.props.statistics ? 'cnt-post':'cnt-post-non-stat'}>
                            {this.props.time !== null ?
                            <>

                                <time dateTime={this.props.time} className="date-post">{time}</time>
                                {delite}
                            </>
                            :null}
                            {this.props.text !== '' ?
                                <div className="desc-post">{textBox}</div>
                            :
                                <div className="desc-post" style={{minHeight: 0}}></div>
                            }

                            {this.props.statistics ?
                            <div className="post-info">
                                <div className="post-info__item like">
                                    <span className="icon-item"><i className="icon icon-abs icon-post-like"></i></span>
                                    {this.props.statistics.like}
                                </div>
                                <div className="post-info__item comment">
                                    <span className="icon-item"><i className="icon icon-abs icon-post-comment"></i></span>
                                    {this.props.statistics.comment}
                                </div>
                                {this.props.statistics.view ?
                                <div className="post-info__item eye">
                                    <span className="icon-item"><i className="icon icon-abs icon-post-eye"></i></span>
                                    {this.props.statistics.view}
                                </div>
                                :null}
                            </div>
                            :null}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="col">
                    <div className={!this.props.story ? "post post-base":"post post-history"} onClick={this.props.click}>
                        <div className="img-post">
                            {moderation}

                            {tip}

                            {image}

                            {this.props.user ?
                                <div className="user-post" style={{backgroundImage: `url(https://likepro.ru${this.props.user.img})`}}></div>
                                :null}

                            {this.props.url ?
                                <a href={this.props.url} target="_blank" className="share-post"><i className="icon icon-abs icon-share"></i></a>
                                :null}

                        </div>
                        <div className={this.props.statistics ? 'cnt-post':'cnt-post-non-stat'}>
                            {this.props.time !== null ?
                                <>

                                    <time dateTime={this.props.time} className="date-post">{time}</time>
                                    {delite}
                                </>
                                :null}
                            {this.props.text !== '' ?
                                <div className="desc-post">{this.props.text}</div>
                                :
                                <div className="desc-post" style={{minHeight: 0}}></div>
                            }

                            {this.props.statistics ?
                                <div className="post-info">
                                    <div className="post-info__item like">
                                        <span className="icon-item"><i className="icon icon-abs icon-post-like"></i></span>
                                        {this.props.statistics.like}
                                    </div>
                                    <div className="post-info__item comment">
                                        <span className="icon-item"><i className="icon icon-abs icon-post-comment"></i></span>
                                        {this.props.statistics.comment}
                                    </div>
                                </div>
                                :null}
                        </div>
                    </div>
                </div>
            );
        }
    }
}
