import React from 'react';
import {inject, observer} from "mobx-react";
import ReactDOM from "react-dom";
import {observable} from "mobx";

@inject('mediaStore', 'notificationStore', 'initialStore')
@observer
class ModalDelButton extends React.Component{

    @observable marginTopMobile = 0;
    @observable load = false;

    constructor (props) {
        super(props);

        this.close = this.close.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.window = React.createRef();
        this.refInfoBoxWrap = React.createRef();

        this.open = false;
    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.post;

        if (!initialStore.deliteButtonModalOpen) {
            return null;
        }

        if (!this.open) {
            this.open=true;
            this.updateStatusCloseFon();
        }

        let buttonClasses = 'btn btn-primary';
        if (this.load) {
            buttonClasses = 'btn btn-primary btn-primary-animate';
        }

        const content = <div className="delMediaFile">
            <div className="modal-inner">
                <span className="title-modal">{initialStore.deliteButtonModalName}</span>
                <p className="desc-modal">{initialStore.deliteButtonModalText}</p>
                <div className="more-btn">
                    <button type="button" className={buttonClasses} onClick={this.delite}>{text[34]}</button>
                    <button type="button" className="btn border btn-primary" onClick={this.close}>{text[35]}</button>
                </div>
            </div>
        </div>;

        let styleInfoBoxWrap = null;
        if (initialStore.templates !== 'desktop' && this.marginTopMobile > 0) {
            styleInfoBoxWrap = {marginTop: this.marginTopMobile + 'px'};
        }

        let render = <>
                <div className="info-wrap">
                    <div className="info-scroll">
                        <div className="info-box-wrap" ref={this.refInfoBoxWrap} style={styleInfoBoxWrap}>
                            <div className="info-box chief width-4" ref={this.window}>
                                <span onClick={this.close} className="close-box"><i className="icon icon-close-info"></i></span>
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-overlay"></div>
            </>;

        return render;
    }

    delite = () => {
        if (this.load) return true;

        const initialStore = this.props.initialStore;
        this.load = true;

        new Promise((resolve) => {
            initialStore.deliteButtonModalFun(resolve);
        }).then(() => {
            this.load = false;
            this.close();
        }).catch(() => {
            this.load = false;
            this.close();
        });
    }

    updateStatusCloseFon = () => {
        const initialStore = this.props.initialStore;
        let type = 'click';
        if (initialStore.templates !== 'desktop') {
            type = 'touchend';
        }

        if (this.open) {
            document.addEventListener(type, this.handleClickOutside, false);
        } else {
            document.removeEventListener(type, this.handleClickOutside, false);
        }
    }




    close () {
        if (this.load) return true;

        const initialStore = this.props.initialStore;
        initialStore.deliteButtonModalOpen = false;
        this.open = false;
        this.updateStatusCloseFon();
    }


    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов

                this.close();
            }
        } catch(error) {
            return null;
        }
    }

    componentDidUpdate() {
        const initialStore = this.props.initialStore;
        if (!this.marginTopMobile) {

            if (this.refInfoBoxWrap.current) {
                this.marginTopMobile = Math.round((initialStore.height/2) - (this.refInfoBoxWrap.current.clientHeight/2));
            }


        }
    }

}

export default ModalDelButton;