import React from 'react';
import {inject, observer} from "mobx-react"

import {getPosts} from '../actions/account';

@inject('accountsStore')
@observer
class Accountloop extends React.Component{

    constructor (props) {
        super(props);

        this.idAccount = null;
        this.loopAccounts();
        this.loopAccountsTimer = this.loopAccountsTimer.bind(this);
        this.loopAccountsActiveTimer = this.loopAccountsActiveTimer.bind(this);

        //автоматическое обновление постов у не активных аккаунтов
        setInterval(this.loopAccountsTimer, 60000);

        setInterval(this.loopAccountsActiveTimer, 15000);
    }

    loopAccountsActiveTimer() {

        const accountsStore = this.props.accountsStore;

        this.idAccount = accountsStore.active;

        if (this.idAccount) {

            const idAccount = this.idAccount;
            const idAccountReal = accountsStore.list[idAccount].id;
            const idAccountRealHashPost = accountsStore.list[idAccount].post.hash;
            const idAccountRealHashStory = accountsStore.list[idAccount].story.hash;

            getPosts(idAccountReal, idAccountRealHashPost, idAccountRealHashStory, idAccount);
        }
    }

    loopAccountsTimer() {

        const accountsStore = this.props.accountsStore;

        let accountsListOk = [];


        let timer = setInterval(() => {

            const accounts = accountsStore.list.slice();

            let block = false;
            let idAccountLoad = false;

            for (let key in accounts) {

                if (accounts[key].post.load) {
                    block = true;
                    break;
                }

                if (accountsListOk[key] === undefined && !accounts[key].post.load && !idAccountLoad && accountsStore.active !== key) {
                    idAccountLoad = key;
                }
            }

            if (!block) {

                if (!idAccountLoad) {
                    clearTimeout(timer);
                } else {
                    accountsListOk[idAccountLoad] = true;
                    getPosts(accounts[idAccountLoad].id, accounts[idAccountLoad].post.hash, accounts[idAccountLoad].story.hash, idAccountLoad);
                }

            }

        }, 100);
    }

    loopAccounts() {

        const accountsStore = this.props.accountsStore;

        let timer = setInterval(() => {

            const accounts = accountsStore.list.slice();

            let block = false;
            let idAccountLoad = false;

            for (let key in accounts) {
                if (accounts[key].post.load) {
                    block = true;
                    break;
                }

                if (accounts[key].post.list === null && !accounts[key].post.load && !idAccountLoad) {
                    idAccountLoad = key;
                }
            }

            if (!block) {

                if (!idAccountLoad) {
                    clearTimeout(timer);
                } else {
                    getPosts(accounts[idAccountLoad].id, accounts[idAccountLoad].post.hash, accounts[idAccountLoad].story.hash, idAccountLoad);
                }

            }

        }, 100);
    }

    render() {

        const accountsStore = this.props.accountsStore;

        this.idAccount = accountsStore.active;

        if (this.idAccount && accountsStore.list[this.idAccount]) {

            const idAccount = this.idAccount;
            const idAccountReal = accountsStore.list[idAccount].id;
            const idAccountRealHashPost = accountsStore.list[idAccount].post.hash;
            const idAccountRealHashStory = accountsStore.list[idAccount].story.hash;

            getPosts(idAccountReal, idAccountRealHashPost, idAccountRealHashStory, idAccount);
        }

        return null;
    }

}

export default Accountloop;