import React from 'react';
import {inject, observer} from "mobx-react";
import ReactDOM from "react-dom";
import TemplateModalAddAccountInstagram from "../../templates/modal/addAccount/templateModalAddAccountInstagram";
import APIaccounts from "../../api/accounts";
import TemplateModalAddAccountInstagramTwoFactorCode from "../../templates/modal/addAccount/templateModalAddAccountInstagramTwoFactorCode";
import APIuser from "../../api/user";
import TemplateModalAddAccountInstagramVerificationTip
    from "../../templates/modal/addAccount/templateModalAddAccountInstagramVerificationTip";
import TemplateModalAddAccountInstagramCode
    from "../../templates/modal/addAccount/templateModalAddAccountInstagramCode";
import TemplateModalAddAccountInstagramRe from "../../templates/modal/addAccount/templateModalAddAccountInstagramRe";
import TemplateModalAddAccountTg from "../../templates/modal/addAccount/templateModalAddAccountTg";
import APIaccountsTg from "../../api/accountstg";

@inject('modalAddAccount', 'notificationStore', 'initialStore', 'accountsStore')
@observer
class ModalAddAccountTg extends React.Component{

    constructor (props) {
        super(props);

        this.close = true;
    }

    render() {

        const modalAddAccount = this.props.modalAddAccount;

        let content = null;

        if (modalAddAccount.level === 0) {
            //ввод логина и пароля
            content = (
                <TemplateModalAddAccountTg
                    token={modalAddAccount.token}
                    actionToken={this.actionToken}
                    chanel={modalAddAccount.chanel}
                    actionChanel={this.actionChanel}
                    actionOK={this.actionOK}
                    close={this.actionClose}
                />
            );

        }

        return content;
    }

    actionOK = (resolve) => {
        const modalAddAccount = this.props.modalAddAccount;
        const accountsStore = this.props.accountsStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;

        this.close = false;

        if (modalAddAccount.token === '' || modalAddAccount.chanel === '') {
            notificationStore.add(text[4], initialStore.text.code[75]);
            this.close = true;
            resolve(true);
            return true;
        }

        APIaccountsTg.getAdd(
            modalAddAccount.token,
            modalAddAccount.chanel,
        ).then((data) => {

            if (!data.status) {
                if (data.code === false) {
                    alert(text[8] + data.response);
                } else {
                    notificationStore.add(text[4], initialStore.text.code[data.code]);
                }
                this.close = true;
                resolve(true);
            } else {
                //Аккаунт добавлен!
                APIuser.getLogin(true).then((datas) => {
                    accountsStore.updateInitialGroups(datas.groups);
                    accountsStore.updateInitial(datas.accounts);

                    this.close = true;
                    resolve(true);

                    //перенаправляем на новый аккаунт
                    notificationStore.add(text[10], '');

                    initialStore.redirect('/account/'+data.id);

                    modalAddAccount.closeActive();
                });

            }


        }).catch((e) => {
            notificationStore.add(text[4], text[12]);
            resolve(true);
        });
    }

    actionToken = (value) => {
        const modalAddAccount = this.props.modalAddAccount;
        modalAddAccount.token = value;
    }

    actionChanel = (value) => {
        const modalAddAccount = this.props.modalAddAccount;
        modalAddAccount.chanel = value;
    }

    actionClose = () => {
        const modalAddAccount = this.props.modalAddAccount;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;

        if (!this.close) {
            notificationStore.add(text[4], text[5]);
        } else {
            modalAddAccount.closeActive();
        }
    }
}

export default ModalAddAccountTg;