import React from 'react';
import {inject, observer} from "mobx-react";
import TitleH1 from "../elements/text/titleH1";
import Input from "../elements/input";
import ButtonLoad from "../elements/buttonLoad";

export default
@inject('initialStore')
@observer
class SettingsSecurity extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.settings;

        if (initialStore.templates === 'desktop') {

            return (
                <div className="settings">
                    <TitleH1>{text.safety}</TitleH1>

                    <div className="settings-security">
                        <div className="form-group">
                            <span className="input-label">{text.enterYourOldPassword}</span>
                            <Input onChange={this.props.passwordChange} type={'password'}>{this.props.password}</Input>
                        </div>
                        <div className="form-group">
                            <span className="input-label">{text.enterNewPassword}</span>
                            <Input onChange={this.props.passwordNewChange} type={'password'}>{this.props.passwordNew}</Input>
                        </div>
                        <div className="form-group">
                            <span className="input-label">{text.enterNewPasswordAgain}</span>
                            <Input onChange={this.props.passwordNew2Change} type={'password'}>{this.props.passwordNew2}</Input>
                        </div>
                    </div>

                    <div className="more-btn bottom">
                        <ButtonLoad color={true} action={this.props.saveClick}>saveNewPassword</ButtonLoad>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="settings" style={{marginTop: '10px'}}>
                    <TitleH1>{text.safetyMobile}</TitleH1>

                    <div className="settings-security">
                        <div className="form-group">
                            <span className="input-label">{text.enterYourOldPassword}</span>
                            <Input onChange={this.props.passwordChange} type={'password'}>{this.props.password}</Input>
                        </div>
                        <div className="form-group">
                            <span className="input-label">{text.enterNewPassword}</span>
                            <Input onChange={this.props.passwordNewChange} type={'password'}>{this.props.passwordNew}</Input>
                        </div>
                        <div className="form-group">
                            <span className="input-label">{text.enterNewPasswordAgain}</span>
                            <Input onChange={this.props.passwordNew2Change} type={'password'}>{this.props.passwordNew2}</Input>
                        </div>
                    </div>

                    <div className="more-btn bottom">
                        <ButtonLoad color={true} action={this.props.saveClick}>saveNewPassword</ButtonLoad>
                    </div>
                </div>
            );
        }

    }
}

