import React from 'react';
import {inject, observer} from "mobx-react";
import PostsBox from "../../../../templates/posts/postsBox";
import PostBox from "../../../../component/PostBox/PostBox";
import InfoContainer from "../../../../templates/infoConteiner";
import Time from "../../../../model/time";
import LoaderComponent from "../../../../templates/loaderComponent";
import PreviewBox from "../../../../templates/preview/previewBox";


@inject('accountsStore', 'menuStore', 'mediaStore', 'initialStore')
@observer
class PostsInstagram extends React.Component {

    render() {
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;
        const posts = accountsStore.list[accountsStore.active].post.list;

        let maxElement = initialStore.limitVisualPosts;

        const text = initialStore.text.account;

        let content = <LoaderComponent/>;

        let tip = '';


        if (posts !== null) {

            if (posts.length) {
                let postsArray = [];
                const postsMas = posts.slice();
                for (let i=postsMas.length-1; i>=0;i--) {

                    if (postsMas[i].url) {
                        continue;
                    }

                    if (postsMas[i].status) {
                        continue;
                    }

                    if (!maxElement) break;
                    maxElement--;

                    postsArray.push(<PostBox key={postsMas[i].id} {...postsMas[i]} preview={true}/>);
                }

                content = <PreviewBox>
                        {postsArray}
                </PreviewBox>;
            } else {

                const mediaStore = this.props.mediaStore;

                if (!mediaStore.collapsed) {
                    content = <InfoContainer
                        title={text[110]}
                        text={text[109](accountsStore.list[accountsStore.active].name)}
                        buttonName={text[111]}
                        buttonClick={this.openPost}
                    />;
                } else {
                    content = <InfoContainer
                        title={text[110]}
                        text={text[109](accountsStore.list[accountsStore.active].name)}
                    />;
                }

            }
        }

        return content;
    }

    componentDidMount() {
        const initialStore = this.props.initialStore;
        const accountsStore = this.props.accountsStore;

        initialStore.limitVisualPosts = initialStore.limitVisualPostsPlasDefoult;

        if (accountsStore.list[accountsStore.active].post.list) {
            const posts = accountsStore.list[accountsStore.active].post.list;
            initialStore.postInLentaMax = posts.length;
        } else {
            initialStore.postInLentaMax = 0;
        }

        this.activeAccount = accountsStore.active;

        this.props.menuStore.updateActiveComponentAccount('post');
    }

    componentWillUpdate() {
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;

        if (accountsStore.active !== this.activeAccount) {

            initialStore.limitVisualPosts = initialStore.limitVisualPostsPlasDefoult;

            if (accountsStore.list[accountsStore.active].post.list) {
                const posts = accountsStore.list[accountsStore.active].post.list;

                initialStore.postInLentaMax = posts.length;
            }

            this.activeAccount = accountsStore.active;

        } else {

            if (!initialStore.postInLentaMax && accountsStore.list[accountsStore.active].post.list) {
                const posts = accountsStore.list[accountsStore.active].post.list;
                initialStore.postInLentaMax = posts.length;
            }

        }
    }

    openPost = () => {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        //выставляем аккаунт который выбран
        if (mediaStore.accounts.length === 0 && accountsStore.active !== null) {

            const time = Time.dateTimetableGenerator(accountsStore.list[accountsStore.active].id, 'post');

            mediaStore.actionAccount(accountsStore.list[accountsStore.active].id, time[0]);
        }

        this.props.mediaStore.actionOpen(true);
    }
}

export default PostsInstagram;