import React from 'react';
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";

export default
@inject('initialStore')
@observer
class HelpElementTextBox extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <div className="group-content">
                        <span className="title-content">{this.props.name}</span>
                        <p>{this.props.text}</p>
                    </div>;
        } else {
            return <div className="group-content">
                <span className="title-content">{this.props.name}</span>
                <p>{this.props.text}</p>
            </div>;
        }

    }
}

