const ru = {
    0: 'Произошла ошибка соединения с сервером, повторите попытку позже.',
    1: 'Не корректно заполнены поля Имя или Email',
    2: 'Пользователь с таким Email не зарегистрирован на сервисе.',
    3: 'Пользователь с таким Email не подтвердил почту на сервисе.',
    4: 'Пользователь уже добавлен',
    5: 'Аккаунта не существует или он Вам не принадлежит',
    6: 'Вы забыли заполнить поле Имя',
    7: 'Вы забыли заполнить поле Email',
    8: 'Аккаунт успешно добавлен, но лист аккаунтов не удалось обновить, перезагрузите страницу',
    9: 'Требуется повторная авторизация',
    10: 'Не все значения были переданы для обработки запроса',
    11: 'Не корректно заполнено поле Имя',
    12: 'Менеджера не существует',
    14: 'Нет прав',
    15: 'Не корректно заполнены поля.',
    16: 'Прокси указан не верно или не работает.',
    17: 'Ваш браузер не поддерживает данный формат видео.',
    18: 'Видео в таком формате не поддерживается',
    19: 'Видео слишком короткое, меньше 3 секунд, замените это видео другим.',
    20: 'Видео слишком длинное, более 1 минуты, замените это видео другим.',
    21: 'Изображение слишком маленькое, замените его другим.',
    22: 'Пожалуйста, перекодируйте или замените это фото, сервис не сможет его обработать. Вероятно файл поврежден.',
    23: 'Не удалось сохранить файл на сервере',
    24: 'У данного поста идет обработка медиа файлов, повторите через пару минут',
    25: 'Данного поста не существует',
    26: 'Данной публикации не существует',
    27: 'Неверный формат данных',
    28: 'Ошибка',
    29: 'Вы указали неверную почту',
    30: 'Код недействителен, повторите попытку восстановления пароля',
    31: 'Пароль не должен быть короче 6 символов',
    32: 'У поста указано время публикации которое уже прошло, отредактируйте пожалуйста пост и повторите попытку',
    33: 'Ваш тарифный план не позволяет добавить больше аккаунтов',
    34: 'Тарифный план закончился, продлите его чтобы добавить аккаунт',
    35: 'Некорректные данные для логина и пароля',
    36: 'Некорректные данные для прокси',
    37: 'Прокси не работает',
    38: 'Временно добавление аккаунтов недоступно, повторите попытку позже или обратитесь в поддержку',
    39: 'Ошибка соединения с Instagram, нет ответа от социальной сети',
    40: 'Введен неверный пароль. Попробуйте ещё раз',
    41: 'Данного аккаунта не существует',
    42: 'Вы не являетесь владельцем данного аккаунта',
    43: 'Неизвестная ошибка, Instagram не принял код',
    44: 'Вы ввели неверный код слишком много раз. Подождите несколько минут и повторите попытку',
    45: 'Instagram не принял Ваш код по неизвестной причине',
    46: 'Неверный код авторизации. Если проблема не проходит, пожалуйста отключите двухфакторную защиту на время добавления аккаунта. Как добавите аккаунт включите ее снова.',
    47: 'Не удача в получении методов верификации. Закройте окно и повторите через 5-10 минут',
    48: 'Неизвестный метод верификации, пожалуйста, напишите службе поддержки',
    49: 'Instagram просит подтвердить ваш аккаунт, зайдите в приложение Instagram на своем телефоне и проследуйте инструкиям',
    50: 'Instagram выдал не известную ошибку во время получения типов подтверждения авторизации. Повторите через 5-10 минут или обратитесь в службу поддержки',
    51: 'Instagram показал не известную ошибку во время получения типов подтверждения авторизации. Повторите через 5-10 минут или обратитесь в службу поддержки',
    52: 'Instagram деактивировал ваш аккаунт, скорее всего по причине несоблюдения правил',
    53: 'Слишком частые запросы, подождите 5-10 минут и повторите попытку',
    54: 'На аккаунте сменился пароль, авторизуйтесь повторно',
    55: 'Откройте приложение Instagram на своем телефоне и подтвердите что это Вы пытаетесь авторизоваться.',
    56: 'Instagram просит принять изменения в "Пользовательском соглашении и Политике использования данных", мы приняли их за Вас, повторите попытку добавления аккаунта. Если не поможет, обратитесь в службу поддержки. Мы заметили, такое часто бывает на некачественных, купленных аккаунтах. Мы не рекомендуем добавлять такие аккаунты, если на них мало публикаций и недостаточное количество времени работают с обычного Instagram приложения',
    57: 'Ваш аккаунт имеет ограничение на авторизацию. Обратитесь в службу поддержки для помощи с авторизацией',
    58: 'Откройте приложение Instagram, обновите ленту и подтвердите что это Вы авторизовываетесь. После этого закройте окно добавления аккаунта и повторите попытку снова.',
    59: 'Ошибка подтверждения авторизации, закройте окно и повторите добавление аккаунта',
    60: 'Ошибка подтверждения кода, закройте окно и повторите добавление аккаунта',
    61: 'Неверный код, повторите снова, если не помогает, закройте окно и повторите добавление аккаунта',
    62: 'Вы включили личный прокси, заполните все поля или отключите его.',
    63: 'Вы забыли заполнить логин или пароль',
    64: 'Аккаунт уже добавлен в сервис другим пользователем. Вы можете попросить владельца дать Вам доступ к данному аккаунту через функцию "Сотрудники"',
    65: 'Аккаунт уже удален',
    66: 'Неверное имя пользователя. Похоже, вы ввели имя пользователя, которое не принадлежит аккаунту. Проверьте свое имя пользователя и попробуйте еще раз.',
    67: 'У одного из выбранных аккаунтов закончился тариф, создание поста было преравано.',
    68: 'Недопустимый формат, можно добавить только jpg, png и gif',
    69: 'Не удалось сохранить файл, повторите попытку через пару минут',
    70: 'Вы указали неправильный токен бота, или бот не работает',
    71: 'Вы ввели не правильно Логин/ID или ссылку на Канал или Группу',
    72: 'Бот не является администратором данного канала или группы',
    73: 'Вы забыли предоставить боту возможность "Публикация сообщений"',
    74: 'Не удалось скачать иконку Канала или Группы, повторите попытку через 1-2 минуты.',
    75: 'Вы забыли заполнить поля',
    76: 'Вы забыли предоставить боту возможность "Удаление сообщений"',
    77: 'Чтобы защитить ваш аккаунт, Instagram сбросил ваш пароль. Зайдите в приложение на мобильном устройстве, обновите ленту и следуйте инструкции по смене пароля. После смены пароля повторите добавление аккаунта уже с новым паролем.',
    78: 'Ссылка не действительна или это закрытый аккаунт',
    79: 'Ошибка получения данных 3',
    80: 'Ошибка соединения при обработке Telegram',
    81: 'Ошибка сохранения аватарки, повторите попытку позже.',
    82: 'Вы ввели не правильно номер телефона, телефон должен быть минимум из 9 цифр',
    83: 'Вы ввели не правильно Имя, поле Имя должно быть минимум из 2х букв',
    84: 'Ошибка сохранения, повторите попытку',
    85: 'Вы ввели неверный старый пароль',
    86: 'Поля заполнены некорректно, пароль должен быть от 6 до 100 символов.',
    87: 'Произошла ошибка при сохранении пароля, повторите попытку позже.',
    88: 'Произошла ошибка при сохранении статусов уведомлений',
    89: 'Произошла ошибка при получении данных для уведомлений',
    90: 'Вы забыли указать имя группы',
    91: 'Ошибка сохранения изменения имени группы',
    92: 'Группа удалена',
    93: 'Ошибка при удалении группы, повторите попытку позже',
    94: 'Ошибка при сохранении порядка аккаунтов при удалении группы, повторите попытку позже',
    95: 'Произошла ошибка при создании группы, повторите попытку позже.',
    96: 'Ошибка при сохранении порядка групп, повторите попытку позже',
    97: 'Произошла ошибка при получении данных для реферальной программы',
    98: 'Email адрес указан не корректно',
    99: 'Пароль некорректный',
    100: 'Пожалуйста, подтвердите Вашу почту, на нее было отправлено письмо с сылкой для подтверждения',
    101: 'Вы успешно авторизовались',
    102: 'Вы ошиблись с Электронной почтой или Паролем',
    103: 'Ошибка передачи данных',
    104: 'Ошибка запроса. Сервер не работает, повторите попытку позже',
    105: 'Неизвестная ошибка при регистрации или авторизации',
    106: 'На данный электронный адрес уже зарегистрирован пользователь. Если это Вы, восстановите пароль',
    107: 'Вы забыли заполнить поле',
    108: (mail) => 'На почту "'+mail+'" отправлено письмо с инструкцией по восстановлению пароля.',
    109: 'не удалось обновить ленту постов',
    110: 'Ошибка соединения',
    111: 'Внимание',
    112: 'Вы действительно хотите удалить группу? Посты которые находятся в ней, будут перенесены в группу Все. Если да, нажмите второй раз',
    113: 'Произошла ошибка, повторите через 2 минуты.',
    114: 'Шаблон удален',
    115: 'Произошла ошибка при удалении шаблона, повторите попытку',
    116: 'Произошла ошибка при сохранении расписания, повторите попытку',
    117: 'Произошла ошибка при удалении сотрудника',
    118: 'Сотрудник добавлен',
    119: 'Сотрудник отредактирован',
    120: 'Прокси сохранен',
    121: 'Прокси удален',
    122: 'Вы забыли заполнить название или текст шаблона',
    123: 'Ошибка при создании шаблона, повторите попытку',
    124: 'Ошибка при редактировании шаблона, повторите попытку',
    125: 'Шаблон создан',
    126: 'Шаблон изменен',
    127: 'Выход',
    128: 'Вы уверены, что хотите выйти?',
    129: 'Выйти',
    130: 'Отмена',
    131: 'Нет аккаунта',
    132: 'ошибка получения запроса',
    133: 'Ошибка получения статистики',
    134: 'Не удалось получить фото из VK, вероятно закрытый аккааунт или не действительная ссылка',
    135: 'Не удалось получить видео из VK, вероятно видео загружено на YouTube или другой сторонний сервис. Мы поддерживааем только видео загруженные в ВК',
    136: 'Не удалось получить пост из VK, вероятно закрытый аккааунт или не действительная ссылка',
    137: 'Не удалось получить пост из VK, мы не смогли обработать данный тип ссылки. Ссылка должна вести на пост, видео или фото на сайте VK',
    138: 'Не удалось получить пост из OK, вероятно закрытый аккаунт или не действительная ссылка',
    139: 'Не удалось получить фото из OK, вероятно закрытый аккаунт или не действительная ссылка',
    140: 'Не удалось найти в посте из социальной сети OK текст, фото или видео.',
    141: 'Не удалось найти в фото из социальной сети OK текст или фото.',
    142: 'Ссылки на видео в социальной сети OK не поддерживаются. Вы моджете вставить сылку на топик в котором есть данное видео для его загрузки.',
    143: 'Нам не удалось разобрать ссылку на пост из социальной сети OK.',
    144: 'Данный тариф временно не доступен, напишите в чат.',
    145: 'Вы не можете выбрать данный тариф. На сервисе у Вас аккаунтов больше, чем позволенно на выбраном тарифе.Удалите не нужные аккаунты или выберите другой тариф.',
    146: 'У Вас тестовый тариф, нельзя его редактировать.',
    147: 'Успешно',
    148: 'Тариф изменен',
    149: 'После повышения тарифа его нельзя сделать ниже в течении 7 дней после повышения. Вы можете обратится за помощью к оператору.',

}

const en = {
    0: 'An error occurred connecting to the server, please try again later.',
    1: 'The fields Name or Email are not filled in correctly',
    2: 'The user with this Email is not registered with the service.',
    3: 'The user with this Email did not confirm the mail on the service.',
    4: 'User already added',
    5: 'The account does not exist or it does not belong to you',
    6: 'You forgot to fill in the Name field',
    7: 'You forgot to fill in the Email field',
    8: 'Account added successfully, but the list of accounts could not be updated, reload the page',
    9: 'Re-authorization required',
    10: 'Not all values were passed to process the request',
    11: 'The Name field is not filled in correctly',
    12: 'Manager does not exist',
    14: 'No rights',
    15: 'Fields are not filled in correctly.',
    16: 'The proxy is incorrect or does not work.',
    17: 'Your browser does not support this video format.',
    18: 'Video in this format is not supported',
    19: 'The video is too short, less than 3 seconds, please replace this video with another one.',
    20: 'The video is too long, more than 1 minute, please replace this video with another one.',
    21: 'Image is too small, replace it with another one.',
    22: 'Please recode or replace this photo, the service will not be able to process it. The file is probably damaged.',
    23: 'Failed to save file to server',
    24: 'This post is processing media files, repeat in a couple of minutes',
    25: 'This post does not exist',
    26: 'This publication does not exist',
    27: 'Invalid data format',
    28: 'Error',
    29: 'You entered incorrect mail',
    30: 'The code is invalid, please try to recover your password again',
    31: 'Password must not be shorter than 6 characters',
    32: 'The post has a posting time that has already passed, please edit the post and try again',
    33: 'Your subscription plan does not allow you to add more accounts',
    34: 'The plan has ended, renew it to add an account',
    35: 'Invalid login and password data',
    36: 'Invalid proxy data',
    37: "Proxy doesn't work",
    38: 'Temporarily adding accounts is not available, please try again later or contact support',
    39: 'Instagram connection error, no response from social network',
    40: 'Incorrect password entered. Try again',
    41: 'This account does not exist',
    42: 'You are not the owner of this account',
    43: 'Unknown error, Instagram did not accept the code',
    44: 'You have entered the wrong code too many times. Wait a few minutes and try again',
    45: 'Instagram did not accept your code for an unknown reason',
    46: 'Invalid code, check the code and try again',
    47: 'No luck in getting verification methods. Close the window and repeat after 5-10 minutes',
    48: 'Unknown verification method, please email support',
    49: 'Instagram asks to verify your account, go to the Instagram app on your phone and follow the instructions',
    50: 'Instagram threw an unknown error while getting authorization confirmation types. Try again after 5-10 minutes or contact support',
    51: 'Instagram showed an unknown error while getting authorization confirmation types. Try again after 5-10 minutes or contact support',
    52: 'Instagram has deactivated your account, most likely due to non-compliance with the rules',
    53: 'Too frequent requests, wait 5-10 minutes and try again',
    54: 'The password on the account has been changed, log in again',
    55: 'Open the Instagram app on your phone and confirm that you are trying to log in.',
    56: 'Instagram asks to accept the changes to the "User Agreement and Data Use Policy", we took them for you, please try to add your account again. If it doesn\'t help, contact support. We noticed this often happens on low-quality, purchased accounts. We do not recommend adding such accounts if they have few publications and an insufficient amount of time working with a regular Instagram application.',
    57: 'Your account has an authorization restriction. Contact support for help with authorization',
    58: 'Open the Instagram app, refresh the feed and confirm that you are logging in. After that, close the window for adding an account and try to add an account again.',
    59: 'Authorization confirmation error, close the window and add the account again',
    60: 'Code confirmation error, close the window and add your account again',
    61: 'Invalid code, try again, if it does not help, close the window and add the account again',
    62: 'You have enabled personal proxy, fill in all the fields, or disable it.',
    63: 'You forgot to fill in your username or password',
    64: 'The account has already been added to the service by another user. You can ask the owner to give you access to this account through the "Employees" function',
    65: 'Account already deleted',
    66: 'Invalid username. It looks like you entered a username that does not belong to the account. Check your username and try again.',
    67: 'One of the selected accounts ran out of tariff and post creation was aborted.',
    68: 'Invalid format, you can add only jpg, png and gif',
    69: 'Failed to save the file, please try again in a couple of minutes',
    70: 'You specified the wrong bot token, or the bot does not work',
    71: 'You entered incorrect Login/ID or link to the Channel or Group',
    72: 'The bot is not the administrator of this channel or group',
    73: 'You forgot to give the bot the "Post messages" option',
    74: 'Failed to download the Channel or Group icon, please try again in 1-2 minutes.',
    75: 'You forgot to fill in the fields',
    76: 'You forgot to give the bot the "Delete messages" option',
    77: 'To protect your account, Instagram has reset your password. Go to the app on your mobile device, update the feed and follow the instructions to reset your password. After changing the password, add the account again with the new password.',
    78: 'The link is not valid or this is a closed account',
    79: 'Data retrieval error',
    80: 'Connection error while processing Telegram',
    81: 'Error saving avatar, please try again later.',
    82: 'You entered the wrong phone number, the phone must be at least 9 digits',
    83: 'You entered the wrong name, the Name field must be at least 2 letters',
    84: 'Save error, try again',
    85: 'You entered an invalid old password',
    86: 'The fields are filled in incorrectly, the password must be from 6 to 100 characters.',
    87: 'An error occurred while saving the password, please try again later.',
    88: 'An error occurred while saving notification statuses',
    89: 'An error occured while fetching data for notifications',
    90: 'You forgot to enter a group name',
    91: 'Error saving change of group name',
    92: 'Group deleted',
    93: 'Error while deleting group, please try again later',
    94: 'Error saving account order when deleting group, please try again later',
    95: 'There was an error creating the group, please try again later.',
    96: 'Error saving group order, please try again later',
    97: 'An error occurred while getting data for the referral program',
    98: 'Email address is not correct',
    99: 'Invalid password',
    100: 'Please confirm your email, an email with a confirmation link was sent to it',
    101: 'You have successfully logged in',
    102: 'You are wrong with your Email or Password',
    103: 'Data transmission error',
    104: 'The request failed. Server is down, please try again later',
    105: 'Unknown error during registration or authorization',
    106: "A user is already registered to this email address. If it's you, recover your password",
    107: 'You forgot to fill in the field',
    108: (mail) => 'An email with instructions on how to recover your password has been sent to "'+mail+'"',
    109: 'failed to update the feed of posts',
    110: 'Connection error',
    111: 'Attention',
    112: 'Are you sure you want to delete the group? Posts that are in it will be moved to the Everyone group. If yes, press a second time',
    113: 'An error has occurred, try again in 2 minutes.',
    114: 'Template deleted',
    115: 'An error occurred while deleting the template, please try again',
    116: 'An error occurred while saving the schedule, please try again',
    117: 'An error occurred while deleting an employee',
    118: 'Employee added',
    119: 'Employee edited',
    120: 'Proxy saved',
    121: 'Proxy removed',
    122: 'You forgot to fill in the Name or Text of the template',
    123: 'Error creating template, please try again',
    124: 'Error while editing template, please try again',
    125: 'Template created',
    126: 'Template changed',
    127: 'Exit',
    128: 'Are you sure you want to log out?',
    129: 'Exit',
    130: 'Cancellation',
    131: 'No account',
    132: 'error receiving request',
    133: 'Error getting statistics',
    134: 'Failed to get a photo from VK, probably a closed account or not a valid link',
    135: 'Failed to get video from VK, the video is probably uploaded to YouTube or another third-party service. We only support videos uploaded to VK',
    136: 'Failed to get post from VK, probably closed account or invalid link',
    137: 'Failed to get post from VK, we could not process this type of link. The link should lead to a post, video or photo on the VK website',
    138: 'Failed to get post from OK, probably closed account or invalid link',
    139: 'Failed to get photo from OK, probably closed account or invalid link',
    140: 'Couldn\'t find text, photo or video in OK post.',
    141: 'Could not find text or photo in OK photo.',
    142: 'Video links on OK social network are not supported. You can insert a link to the topic that contains this video to download it.',
    143: 'We were unable to parse the link to the post from the OK social network.',
    144: 'This tariff is temporarily unavailable, write to the chat.',
    145: 'You cannot select this tariff. You have more accounts on the service than allowed for the selected tariff. Delete unnecessary accounts or choose another tariff.',
    146: 'You have a test tariff, you cannot edit it.',
    147: 'Successfully',
    148: 'Tariff changed',
    149: 'After the tariff has been increased, it cannot be made lower within 7 days after the increase. You can ask the operator for help.',
}

const code = {
    ru: ru,
    en: en,
}

export default code;
