import React from 'react';
import Draggable from "react-draggable";

import MediaWindow from "./MediaWindow";

import {observable} from "mobx";
import {inject, observer} from "mobx-react";

import MediaUsersForm from "./MediaUsersForm";

@inject('editorStore')
@observer
class MediaUsers extends React.Component {


    @observable widthRedactor = 600;
    @observable max = this.widthRedactor-60;
    @observable durationDisplay = 'none';

    constructor (props) {
        super(props);

        this.drag = this.drag.bind(this);


        this.duration = 0;
        this.timerId = false;

    }

    render() {
        const editorStore = this.props.editorStore;

        const MediaWindowProps = {
            backgroud: this.props.backgroud,
            stateObertka: this.props.stateObertka,
            content: this.props.content,
            draggable: this.props.draggable,
            getDraggableBounds: this.props.getDraggableBounds,
            handleDrag: false,
            type: this.props.type,
            url: this.props.url,
            onMouseDownBox: this.props.onMouseDownBox,
            boxRef: this.props.boxRef,
            disabled: true,
            currentTime: editorStore.file.duration.cover,
        };

        const elementsUsers = [];

        const accounts = editorStore.file.accounts.slice();

        for (const user of accounts) {

            const DraggableProps = {
                axis: 'both',
                position: {
                    x: Math.round(this.props.backgroud.width / 100 * user.x),
                    y: Math.round(this.props.backgroud.height / 100 * user.y),
                },

                bounds: {
                    left: 0,
                    top: 0,
                    right: this.props.backgroud.width,
                    bottom: this.props.backgroud.height,
                },
                onDrag: (event, ui) =>{this.drag(event, ui, user.id)},
            };

            let pos = 'label-mark pos-b';
            if (user.y > 50) {
                pos = 'label-mark pos-t';
            }
            if (user.x < 20) {
                pos = 'label-mark pos-r';
            }
            if (user.x < 20 && user.y < 10) {
                pos = 'label-mark pos-rt';
            }
            if (user.x < 20 && user.y > 90) {
                pos = 'label-mark pos-rb';
            }
            if (user.x > 80) {
                pos = 'label-mark pos-l';
            }
            if (user.x > 80 && user.y < 10) {
                pos = 'label-mark pos-lt';
            }
            if (user.x > 80 && user.y > 90) {
                pos = 'label-mark pos-lb';
            }


            elementsUsers.push(
                <Draggable {...DraggableProps} key={user.id}>
                    <div className="mark-redactor">

                        <div className={pos}><div className="account-photo-delite" onClick={() => {this.deliteAccount(user.id)}}></div>{user.name}</div>
                    </div>
                </Draggable>
            );
        }



        const buttonElement = (
            <div style={{
                width: `100%`,
                height: `${this.props.backgroud.height}px`,
                position: 'absolute',

            }}>
                <div className="editor-img__redactor" style={{width: `${this.props.backgroud.width}px`, height: `${this.props.backgroud.height}px`, position: 'relative', margin: '0 auto'}}>
                    {elementsUsers}
                </div>
            </div>
        );







        const component = <>
            <div className="editor-top">

                {buttonElement}

                <MediaWindow {...MediaWindowProps}/>
            </div>

            <div className="editor-cnt">
                <div className="editor-redactor">
                    <div className="editor-redactor__form">
                        <div className="item-form item-form-users-add">
                            <MediaUsersForm />
                        </div>
                    </div>
                </div>
            </div>


        </>;

        return component;
    }

    componentDidMount() {
        const editorStore = this.props.editorStore;

        const duration = editorStore.file.duration.cover;

        this.duration = duration;
    }

    drag(event, ui, id) {
        const editorStore = this.props.editorStore;

        const x = ui.x;
        const y = ui.y;

        const xNew = Math.ceil((x / (this.props.backgroud.width/100))*1000)/1000;
        const yNew = Math.ceil((y / (this.props.backgroud.height/100))*1000)/1000;

        const accountNewList = [];

        for (const user of editorStore.file.accounts.slice()) {

            const array = {
                x: user.x,
                y: user.y,
                name: user.name,
                id: user.id,
            };

            if (array.id === id) {
                array.x = xNew;
                array.y = yNew;
            }

            accountNewList.push(array);
        }

        editorStore.file.accounts = accountNewList;
    }

    deliteAccount = (id) => {
        const editorStore = this.props.editorStore;

        const accountNewList = [];

        for (const user of editorStore.file.accounts.slice()) {

            const array = {
                x: user.x,
                y: user.y,
                name: user.name,
                id: user.id,
            };

            if (array.id !== id) {
                accountNewList.push(array);
            }
        }

        editorStore.file.accounts = accountNewList;
    }
}

export default MediaUsers;