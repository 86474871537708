import React from 'react';
import {inject, observer} from "mobx-react";
import RateIndexCharging from "../../templates/rate/charging";

@inject('initialStore')
@inject('menuStore')
@observer
class RateCharging extends React.Component {

    constructor (props) {
        super(props);
    }

    render() {

        return <RateIndexCharging/>;

    }

}

export default RateCharging;