import React from 'react';
import Draggable from "react-draggable";
import {inject, observer} from "mobx-react";

import stile from './mediaSize.module.css';


@inject('editorStore')
@observer
class MediaWindow extends React.Component {

    constructor (props) {
        super(props);


        this.video = false;
        if (!this.props.video) {
            this.video = React.createRef();
        } else {

            this.video = this.props.video;
        }

        this.play = false;
        this.currentTime = 0;
    }

    render() {

        const editorStore = this.props.editorStore;

        const cover = editorStore.file.duration.cover;

        const stileBacground = {
            width: `${this.props.backgroud.width}px`,
            height: `${this.props.backgroud.height}px`,
            backgroundColor: editorStore.file.color,
            overflow: 'hidden',
            position: "relative",
            margin: '0 auto',
            borderRadius: '4px',
            border: '1px solid #e2deeb',
        };

        const stileContentObertka = {
            position: `absolute`,
            overflow: `hidden`,
            width: `${this.props.stateObertka.width}px`,
            height: `${this.props.stateObertka.height}px`,
        };

        const stileContent = {
            width: `${this.props.content.width}px`,
            height: `${this.props.content.height}px`,
            position: "absolute",
            left: `-${this.props.content.left}px`,
            top: `-${this.props.content.top}px`,
        };

        const DraggableProps = {
            position: {
                x: this.props.draggable.x,
                y: this.props.draggable.y
            },
            bounds: this.props.getDraggableBounds,
            onDrag: (event, ui) =>{this.props.handleDrag(event, ui)},
            disabled:this.props.disabled,
        };

        let content = '';

        if (this.props.type === 'image') {
            content = <img draggable={false} onMouseDown={(e)=> {e.preventDefault()}} style={stileContent} src={this.props.url}/>;
        } else {
            content = <video ref={this.video} crossorigin="anonymous" draggable={false} onMouseDown={(e)=> {e.preventDefault()}} style={stileContent} /*src={this.props.url}*/ playsinline="true" /*autoplay*/ controls={false} loop muted/>;
        }

        const component = <div style={stileBacground}>
                            <Draggable {...DraggableProps}>
                                <div style={stileContentObertka} onMouseDown={this.props.onMouseDownBox} ref={this.props.boxRef}>
                                    {content}
                                </div>
                            </Draggable>
                        </div>;

        return component;
    }

    componentDidUpdate() {
        const editorStore = this.props.editorStore;

        if (editorStore.file.type === 'video') {

            let currentTime = 0;
            if (this.props.currentTime) {
                currentTime = this.props.currentTime;
            }


            const videoElement = this.video.current;

            if (this.currentTime !== currentTime) {

                /*let funOne = (e) => {
                    videoElement.removeEventListener('loadeddata', funOne, false);
                    videoElement.currentTime = currentTime;
                }
                videoElement.addEventListener('loadeddata', funOne);*/

                videoElement.currentTime = currentTime;
            }

            if (this.props.play && this.play === false) {
                videoElement.play();
                this.play = true;
            } else if(!this.props.play && this.play === true) {
                //videoElement.pause();
                this.play = false;
            }



        }
    }

    componentDidMount() {
        const editorStore = this.props.editorStore;

        if (editorStore.file.type === 'video') {

            let currentTime = 0;
            if (this.props.currentTime) {
                currentTime = this.props.currentTime;

            }

            const videoElement = this.video.current;

            if (this.currentTime !== currentTime) {
                //videoElement.currentTime = currentTime;
                //this.currentTime = currentTime;
            }

            let funOne = (e) => {
                videoElement.removeEventListener('canplaythrough', funOne, false);
                videoElement.currentTime = currentTime;

                if (this.props.play) {
                    videoElement.play();
                    this.play = true;
                } else if(!this.props.play) {
                    //videoElement.play();
                    videoElement.pause();
                    this.play = false;
                }
            }

            videoElement.addEventListener('canplaythrough', funOne);
            videoElement.src = this.props.url;
            //videoElement.crossOrigin="anonymous";
            videoElement.load();
        }
    }
}
export default MediaWindow;
