import React from 'react';

import {inject, observer} from "mobx-react";

import ButtonLoad from "../../templates/elements/buttonLoad";
import APImoderation from "../../api/moderation";

@inject('postStore', 'notificationStore', 'initialStore')
@observer
class ButtonModeration extends React.Component {

    constructor (props) {
        super(props);
    }

    render() {
        const postStore = this.props.postStore;

        if (!postStore.date.moderation) {
            return null;
        }

        if (!postStore.autorisation) {
            return null;
        }

        return (
            <div className="add-post__events">
                <ButtonLoad style={{width: "100%"}} color action={this.action}>confirm</ButtonLoad>
            </div>
        );
    }

    action = (resolve) => {
        const postStore = this.props.postStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.post;

        APImoderation.setTrue(postStore.id, postStore.code).then((date) => {
            if (date.status) {
                notificationStore.add(text[26]);
                postStore.date.moderation = false;
            } else {
                notificationStore.add(text[17], initialStore.text.code[date.code]);
            }
        }).catch((e) => {
            notificationStore.add(text[17], text[27]);
        }).finally((e) => {
            resolve(true);
        });
    }



}

export default ButtonModeration;
