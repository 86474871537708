import React from 'react';
import {inject, observer} from "mobx-react";

import arrayMove from 'array-move';

import File from "../../../model/file";

import iconDrop from "../../../media/svg/media-draggable.svg";
import PostTemplateMediaIcons from "../../../templates/post/mediaIcons";
import PostTemplateMediaIconsDraggable from "../../../templates/post/mediaIconsDraggable";
import PostTemplateMediaIconsConteiner from "../../../templates/post/mediaIconsConteiner";
import editorMediaClass from "../../../model/eritorMedia";
import PostTemplateMediaIconsMulty from "../../../templates/post/mediaIconsMulty";
import TextModel from "../../../model/text";
import Time from "../../../model/time";
import PostTimeBox from "../../../templates/post/timeBox";


@inject('mediaStore', 'initialStore', 'accountsStore', 'notificationStore')
@observer
class MediaIconsMulty extends React.Component {

    constructor (props) {
        super(props);

        this.editorOpen = this.editorOpen.bind(this);
        this.DelElement = this.DelElement.bind(this);
    }

    render() {
        const mediaStore = this.props.mediaStore;
        const accountsStore = this.props.accountsStore;

        const elemen = [];

        let  durationIcons = true;

        let InstagramTrue = false;
        let VKTrue = false;
        let FBTrue = false;
        let OKTrue = false;
        let TGTrue = false;
        let TwitterTrue = false;
        let FbInstagramTrue = false;

        let minTime = false;

        const accounts = accountsStore.list.slice();
        for (const account of mediaStore.accounts.slice()) {
            for (let keys in accounts) {
                if (accounts[keys].id === account.id) {
                    if (accounts[keys].tip === 'fb')                FBTrue = true;
                    else if (accounts[keys].tip === 'tg')           TGTrue = true;
                    else if (accounts[keys].tip === 'ok')           OKTrue = true;
                    else if (accounts[keys].tip === 'vk')           VKTrue = true;
                    else if (accounts[keys].tip === 'instagram')    InstagramTrue = true;
                    else if (accounts[keys].tip === 'twitter')      TwitterTrue = true;
                    else if (accounts[keys].tip === 'fb-instagram')      FbInstagramTrue = true;

                    if (accounts[keys].rights.rate) {
                        if (!minTime) {
                            minTime = accounts[keys].rights.rate;
                        } else {
                            if (accounts[keys].rights.rate < minTime) {
                                minTime = accounts[keys].rights.rate;
                            }
                        }
                    }
                }
            }
        }





        const elements = mediaStore.media.map((item, key) => {

            let type = 'video';
            if (item.type === 'image') {
                type = 'image';
            }

            let icon = false;
            let percent = false;

            if (item.processingPercent === 100 && item.icon) {
                icon = item.icon;
            } else {
                percent = item.processingPercent;
            }

            let content = false;
            let errorLoad = false;
            if (item.loadPercent !== true) {
                if (item.loadPercent === false) {
                    content = <span className="reLoadError" onClick={() => {
                        File.loadFileToServer(item.id)
                    }}>Ошибка загрузки<br/><span>Повторить</span></span>;
                    errorLoad = () => {
                        File.loadFileToServer(item.id)
                    };
                } else {
                    percent = item.loadPercent;
                }
            }

            if (errorLoad === false && icon !== false && percent === false) {
                content = (
                    <PostTemplateMediaIcons
                        editorOpen={() => {
                            this.editorOpen(key)
                        }}
                        DelElement={() => {
                            this.DelElement(item.id)
                        }}
                    />
                );
                content = true;
            }

            let durationIcon = true;

            if (item.proportions === 0 || item.proportionsBackground === 0) {
                durationIcon = false;
            }



            const num = key + 1;

            let nameTitle = mediaStore.type === 'multyPost' ? 'Пост ' : 'История ';

            const title = nameTitle + num + '/' + mediaStore.media.length;

            let text = '';
            let time = 0;
            let del = false;
            for (let key in mediaStore.multyPost.slice()) {
                if (mediaStore.multyPost[key].id === item.id) {
                    text = mediaStore.multyPost[key].text;
                    time = mediaStore.multyPost[key].time;
                    del = mediaStore.multyPost[key].del;
                    break;
                }
            }

            const errorText = TextModel.limitedText(text, '', InstagramTrue, VKTrue, FBTrue, OKTrue, TGTrue, TwitterTrue).str;
            const date = Time.convertUnixToDate(time);

            let arhiveStatus = true;
            let deliteStatus = true;
            if (VKTrue || FBTrue || OKTrue || TGTrue || FbInstagramTrue) {
                arhiveStatus = false;
            }

            if (FbInstagramTrue) {
                deliteStatus = false;
            }

            if (mediaStore.type === 'multyStory') {
                arhiveStatus = false;
                deliteStatus = false;
            }



            const vievTime = (
                <PostTimeBox
                    selectedDay={time}
                    ref={this.ref}
                    onDayChange={(dey) => {this.handleDayChange(dey, time, item.id)}}
                    date={date.date}
                    hAction={(e) => {this.onChangeHear(time, e, item.id)}}
                    hOptions={this.generatorOptionJSON(23)}
                    hValue={Number(date.h)}
                    mAction={(e) => {this.onChangeMin(time, e, item.id)}}
                    mOptions={this.generatorOptionJSON(59)}
                    mValue={Number(date.m)}
                    arhiveStatus={arhiveStatus}
                    deliteStatus={deliteStatus}
                    delStatus={del.status}
                    delAction={() => {this.activateDelite(item.id, 2)}}
                    arhiveAction={() => {this.activateDelite(item.id, 1)}}
                    delInputAction={(value) => {this.activateDeliteInt(item.id, value)}}
                    delInputValue={del.int}
                    delSelectAction={(value) => {this.activateDeliteTip(item.id, value)}}
                    delSelectValue={Number(del.tip)}
                    minTime={minTime}
                />
            );



            elemen.push(
                <PostTemplateMediaIconsMulty
                    typePost={mediaStore.type}
                    iconVideo={type === 'video'}
                    durationIcon={durationIcon}
                    icon={icon}
                    errorLoad={errorLoad}
                    percent={percent}
                    content={content}
                    editorOpen={() => {this.editorOpen(key)}}
                    DelElement={() => {this.DelElement(item.id)}}
                    title={title}
                    id={item.id}
                    text={text}
                    infoText={errorText}
                    //time
                    vievTime={vievTime}
                />
            );


        });

        return elemen;
    }

    activateDeliteTip = (id, value) => {

        const mediaStore = this.props.mediaStore;

        const newTip = value;

        let idAccountBD = 0;
        for (let key in mediaStore.multyPost.slice()) {
            if (mediaStore.multyPost[key].id === id) {
                idAccountBD = key;
                break;
            }
        }

        const status = mediaStore.multyPost[idAccountBD].del.status;
        let int = mediaStore.multyPost[idAccountBD].del.int;
        let tip = mediaStore.multyPost[idAccountBD].del.tip;

        tip = /\d+/.test(Number(newTip)) ? newTip : tip;

        if (tip < 0 || tip > 3) {
            tip = 2;
        }

        for (let key in mediaStore.multyPost.slice()) {
            if (mediaStore.multyPost[key].id === id) {
                mediaStore.multyPost[key].del.status = status;
                mediaStore.multyPost[key].del.int = int;
                mediaStore.multyPost[key].del.tip = tip;
                break;
            }
        }


    }

    activateDeliteInt = (id, value) => {

        const mediaStore = this.props.mediaStore;

        const newInt = value;

        let idAccountBD = 0;
        for (let key in mediaStore.multyPost.slice()) {
            if (mediaStore.multyPost[key].id === id) {
                idAccountBD = key;
                break;
            }
        }

        const status = mediaStore.multyPost[idAccountBD].del.status;
        let int = mediaStore.multyPost[idAccountBD].del.int;
        let tip = mediaStore.multyPost[idAccountBD].del.tip;

        int = /\d+/.test(Number(newInt)) ? newInt : int;

        for (let key in mediaStore.multyPost.slice()) {
            if (mediaStore.multyPost[key].id === id) {
                mediaStore.multyPost[key].del.status = status;
                mediaStore.multyPost[key].del.int = int;
                mediaStore.multyPost[key].del.tip = tip;
                break;
            }
        }

    }

    activateDelite = (id, statusNew) => {

        const mediaStore = this.props.mediaStore;

        let idAccountBD = 0;
        for (let key in mediaStore.multyPost.slice()) {
            if (mediaStore.multyPost[key].id === id) {
                idAccountBD = key;
                break;
            }
        }

        const status = mediaStore.multyPost[idAccountBD].del.status;
        let int = mediaStore.multyPost[idAccountBD].del.int;
        let tip = mediaStore.multyPost[idAccountBD].del.tip;

        if (statusNew === status) {
            statusNew = false;
            int = 1;
            tip = 2;
        }

        for (let key in mediaStore.multyPost.slice()) {
            if (mediaStore.multyPost[key].id === id) {
                mediaStore.multyPost[key].del.status = statusNew;
                mediaStore.multyPost[key].del.int = int;
                mediaStore.multyPost[key].del.tip = tip;
                break;
            }
        }
    }

    onChangeMin = (unix, e, id) => {
        const mediaStore = this.props.mediaStore;

        const min = e;

        const time = Time.convertUnixAndHearMinToUnix(unix, false, min);

        for (let key in mediaStore.multyPost.slice()) {
            if (mediaStore.multyPost[key].id === id) {
                mediaStore.multyPost[key].time = time;
                break;
            }
        }
    }

    onChangeHear = (unix, e, id) => {

        const mediaStore = this.props.mediaStore;

        const hear = e;

        const time = Time.convertUnixAndHearMinToUnix(unix, hear, false);

        for (let key in mediaStore.multyPost.slice()) {
            if (mediaStore.multyPost[key].id === id) {
                mediaStore.multyPost[key].time = time;
                break;
            }
        }
    }

    handleDayChange = (day, unix, id) => {
        const mediaStore = this.props.mediaStore;

        const time = Time.convertDateToUnix(day, unix);

        for (let key in mediaStore.multyPost.slice()) {
            if (mediaStore.multyPost[key].id === id) {
                mediaStore.multyPost[key].time = time;
                break;
            }
        }
    }

    generatorOptionJSON (num) {
        let jsx = [];
        for (let i = 0; i <= num; i++) {
            jsx.push({key: i, value: i.toString().padStart(2,0)});
        }
        return jsx;
    }

    DelElement(id) {
        this.props.mediaStore.delMediaID = id;
    }

    editorOpen(id) {
        this.props.mediaStore.actionEditorOpen(id);
    }
}

export default MediaIconsMulty;
