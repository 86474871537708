import qs from "qs";
import React from "react";
import instance from "./base";
import axios from "axios";
import loadFileAndBloobUrl from "../model/loadFileAndBloobUrl";

const APIfile = {

    getKey () {
        return instance.post('file/get_key', qs.stringify( {
            key: 'Qe9e1jh43I84fRdfj23!',
        })).then(data => {
            return data;
        });
    },

    getProcessing (id) {
        return instance.post('file/processing_info', qs.stringify( {
            id: id,
        })).then(data => {
            return data;
        });
    },

    getImageVideo (key, id, time, original = false) {
        return instance.post('file/get_image_video', qs.stringify( {
            key: key,
            id: id,
            time: time,
            original: original,
        })).then(data => {
            return data;
        });
    },

    getImagesVideo (key, id, start, finish, icons, iconsCover, iconsNumber) {
        return instance.post('file/get_images_video', qs.stringify( {
            key: key,
            id: id,
            start: start,
            finish: finish,
            icons: icons,
            iconsCover: iconsCover,
            iconsNumber: iconsNumber,
        })).then(data => {
            return data;
        });
    },


    loadFileToServerObrabotka(file, url, coolBack=false) {

        let formData = new FormData();
        if (file) {
            formData.append('file', file.file);
            formData.append('url', false);
        } else {
            formData.append('file', false);
            formData.append('url', url);
        }

        instance.post( 'file/processing',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: function( progressEvent ) {
                    const percent = parseInt(Math.round(( progressEvent.loaded * 100 ) / progressEvent.total));
                    if (coolBack) {
                        coolBack('load', percent);
                    }
                }.bind(this)
            }
        ).then(function(date) {
            if (coolBack) {
                if (date.status) {
                    coolBack('ok', date.id);
                } else {
                    coolBack(false, date);
                }
            }
        }).catch(function(error){
            if (coolBack) {
                coolBack(false, false);
            }
        });


    },
    createBlobVideo (blob) {

        return new Promise((resolve, reject) => {

            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                resolve(reader.result);
            }

        });
    },
    loadFileToServerApi3(id, file, key, coolBack=false, url, urlCover = false, coverTime = 0) {
        if (url) {
            this.loadFileToServer(id, file, key, coolBack, url, urlCover, coverTime);
        } else {
            if (true/*file.size < 103809024*/) {
                this.loadFileToServer(id, file, key, coolBack, url, urlCover, coverTime);
            } else {
                const loadFileToServer = this.loadFileToServer;
                let formData = new FormData();
                formData.append('file', file);

                axios.post( 'https://api3.likepro.ru/load.php',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: function( progressEvent ) {
                            const percent = parseInt(Math.round(( progressEvent.loaded * 100 ) / progressEvent.total));
                            if (coolBack) {
                                coolBack('load', id, percent);
                            }
                        }.bind(this)
                    }
                ).then(function(date){
                    if (date.data.status) {
                        loadFileToServer(id, false, key, coolBack, date.data.url, urlCover, coverTime);
                    } else {
                        if (coolBack) {
                            coolBack('error', id, false, false);
                        }
                    }
                }).catch(function(error){
                    if (coolBack) {
                        coolBack('error', id, false, false);
                    }
                    alert('Ошибка загрузки blob файла, ошибка будет показана в следующем окне. Сообщете о данной проблеме в службу поддержки и пришлите скриншот следующего окна.');
                    alert(error);
                });

            }
        }


    },
    loadFileToServer(id, file, key, coolBack=false, url, urlCover = false, coverTime = 0) {
        let formData = new FormData();
        formData.append('id', id);
        formData.append('key', key);
        formData.append('v', 2);
        formData.append('urlCover', urlCover);
        formData.append('coverTime', coverTime);

        if (url) {
            formData.append('file', false);
            formData.append('url', url);
        } else {
            formData.append('file', file);
            formData.append('url', false);
        }

        instance.post( 'file/load',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: function( progressEvent ) {
                    const percent = parseInt(Math.round(( progressEvent.loaded * 100 ) / progressEvent.total));
                    if (coolBack) {
                        coolBack('load', id, percent);
                    }
                }.bind(this)
            }
        ).then(function(date){
            if (coolBack) {
                coolBack('finish', id, date.status, date);
            }
        }).catch(function(error){
            if (coolBack) {
                coolBack('error', id, false, false);
            }
            alert('Ошибка загрузки blob файла, ошибка будет показана в следующем окне. Сообщете о данной проблеме в службу поддержки и пришлите скриншот следующего окна.');
            alert(error);
        });
    },

    loadCoverToServer(id, cover, time, key, ) {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append('id', id);
            formData.append('key', key);
            formData.append('v', 2);
            formData.append('time', time);
            formData.append('cover', cover);

            instance.post( 'file/load_cover',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                }
            ).then(function(date){
                resolve({date});
            }).catch(function(error){
                resolve(false);
                alert('Ошибка загрузки обложки видео, ошибка будет показана в следующем окне. Сообщете о данной проблеме в службу поддержки и пришлите скриншот следующего окна.');
                alert(error);
            });
        });

    },

    loadFileRed(file, coolBack = false) {
        let formData = new FormData();
        formData.append('file', file);

        instance.post( 'files',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }
        ).then(function(date) {
            if (coolBack) {
                coolBack(date);
            }
        }).catch(function(error){
            if (coolBack) {
                coolBack(false);
            }
        });
    }
}

export default APIfile;
