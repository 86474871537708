import qs from "qs";
import React from "react";
import instance from "./base";




let hesh = () => {

    //let timeUnix = new Date() / 1000;

    return '187dd8bbe0be666bfb31d3c7f36f3a61';
}

const APIuser = {

    getAuthorization (mail, password) {
        return instance.post('login/authorization', qs.stringify({
            mail: mail,
            password: password,
            hesh: hesh(),
        })).then(data => {
            return data;
        });
    },

    getRestorepassword (mail, language) {
        return instance.post('login/restorepassword', qs.stringify({
            mail: mail,
            language: language,
            hesh: hesh(),
        })).then(data => {
            return data;
        });
    },

    getRestorepasswordCode (code, password, language) {
        return instance.post('login/restorepassword_code', qs.stringify({
            code: code,
            password: password,
            language: language,
            hesh: hesh(),
        })).then(data => {
            return data;
        });
    },

    getLogin (result = false) {
        return instance.get('login').then((data) => {
            if (result) {
                return data;
            } else {
                if (data.login) {
                    return true;
                } else {
                    return false;
                }
            }
        }).catch(error => {
            return Promise.reject(error);
        });
    },
    getExit () {
        return instance.get('/login/output').then((data) => {
            return data;
        });
    },

    getRegistration (mail, password, language, platform) {
        return instance.post('login/registration', qs.stringify({
            mail: mail,
            password: password,
            language: language,
            platform: platform,
            hesh: hesh(),
        })).then(data => {
            return data;
        });
    }

}

export default APIuser;