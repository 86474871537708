import React from 'react';
import {inject, observer} from "mobx-react";

import Input from "../elements/input";
import ReactDOM from "react-dom";
import iconEdit from "../../media/svg/icons-edit-2.svg";
import iconDel from "../../media/svg/icons-del.svg";
import iconDrop from "../../media/svg/media-draggable.svg";
import {observable} from "mobx";

export default
@inject('initialStore', 'mediaStore')
@observer
class PostTemplateMediaIcons extends React.Component {

    @observable open = false;

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return(
                <div className="events-download">
                    <span className="event-download edit" onClick={this.props.editorOpen}>
                        <img src={iconEdit}/>
                    </span>
                    <span className="event-download remove" onClick={this.props.DelElement}>
                        <img src={iconDel}/>
                    </span>
                    <span className="event-drop">
                        <img src={iconDrop}/>
                    </span>
                </div>
            );
        } else {
            return(
                <div className="events-download" onClick={this.openElementsEvent}>
                    {this.open ?
                        <>
                            <span className="event-download edit" onClick={this.props.editorOpen}>
                                <img src={iconEdit}/>
                            </span>
                            <span className="event-download remove" onClick={this.props.DelElement}>
                                <img src={iconDel}/>
                            </span>
                            <span className="event-drop">
                                <img src={iconDrop}/>
                            </span>
                        </>
                    :null}
                </div>
            );
        }

    }

    openElementsEvent = () => {
        if (!this.open) {
            this.open = true;

            setTimeout(() => {
                this.open = false;
            }, 5000);
        }
    }


}

