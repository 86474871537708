import React from 'react';
import {inject, observer} from "mobx-react";
import ReactDOM from "react-dom";
import ModalAddAccountIndex from "./ModalAddAccountIndex";
import ModalAddAccountInstagram from "./ModalAddAccountInstagram";
import TemplateModalAddAccountBox from "../../templates/modal/addAccount/templateModalAddAccountBox";
import ModalAddAccountVk from "./ModalAddAccountVk";
import ModalAddAccountOk from "./ModalAddAccountOk";
import ModalAddAccountFb from "./ModalAddAccountFb";
import ModalAddAccountTg from "./ModalAddAccountTg";
import ModalAddAccountTwitter from "./ModalAddAccountTwitter";
import ModalAddAccountFbInstagram from "./ModalAddAccountFbInstagram";

@inject('modalAddAccount', 'notificationStore', 'initialStore')
@observer
class ModalAddAccount extends React.Component{

    constructor (props) {
        super(props);
    }

    render() {
        const modalAddAccount = this.props.modalAddAccount;

        if (!modalAddAccount.open) {
            return null;
        }


        let content = null

        if (modalAddAccount.type === false) {
            content = <ModalAddAccountIndex/>;
        } else if (modalAddAccount.type === 'instagram') {
            content = <ModalAddAccountInstagram/>;
        } else if (modalAddAccount.type === 'fb-instagram') {
            content = <ModalAddAccountFbInstagram/>;
        } else if (modalAddAccount.type === 'vk') {
            content = <ModalAddAccountVk/>;
        } else if (modalAddAccount.type === 'ok') {
            content = <ModalAddAccountOk/>;
        } else if (modalAddAccount.type === 'fb') {
            content = <ModalAddAccountFb/>;
        } else if (modalAddAccount.type === 'tg') {
            content = <ModalAddAccountTg/>;
        } else if (modalAddAccount.type === 'twitter') {
            content = <ModalAddAccountTwitter/>;
        }

        return (
            <TemplateModalAddAccountBox
                content={content}
                close={this.close}
            />
        );
    }
}
export default ModalAddAccount;
