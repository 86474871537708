import APIuser from "../../api/user";

import mainStore from "../../stor/mainStore";
import Time from "../../model/time";
import APIposts from "../../api/posts";

export function autorisation() {

        APIuser.getLogin(true).then((data) => {

            if (data.login) {

                //запрашиваем массив сохраненных постов
                APIposts.getLibrary(false).then((dataLibrary) => {

                    mainStore.accountsStore.updateInitialGroups(data.groups);
                    mainStore.accountsStore.updateInitial(data.accounts);
                    mainStore.accountsStore.updateInitialPatterns(data.patterns.list, data.patterns.hesh);

                    mainStore.initialStore.updateLanguage(data.localization);

                    Time.initTimeZone(data.timezone);

                    mainStore.initialStore.updateAutorisation(
                        data.login,
                        data.rate,
                        data.rateTime,
                        data.rateAccount,
                        data.clientId,
                        data.status,
                        data.timezone,
                        data.email,
                        data.name,

                        data.phone,
                        data.phone_code,

                        data.telegram,
                        data.photo,
                        data.id,
                        data.notificationsOnline,
                        data.clientId_tarif,
                    );

                    //библиотека постов
                    mainStore.libraryStore.getPost(dataLibrary.hashPosts, dataLibrary.posts);

                    mainStore.libraryStore.getCategories(dataLibrary.categories);


                }).catch((error) => {
                    mainStore.initialStore.updateServer(false, error);
                });

            } else {
                mainStore.initialStore.updateAutorisation(
                    data.login,
                    0,
                    data.status,
                    '',
                );
            }







        }).catch((error) => {
            mainStore.initialStore.updateServer(false, error);

        });
}