import React from "react";

const ru = {
    0: 'Добро пожаловать',
    1: <>
        Вам бесплатно предоставлен <b>тариф на 7 дней</b>.<br/>
        Можете добавить <b>3 аккаунта</b> из любой социальной сети.<br/>
        Что бы добавить первый аккаунт - нажмите кнопку ниже<br/>
        или выберите пункт в меню слева "Добавить аккаунт".
    </>,
    2: 'Добавить аккаунт',
}

const en = {
    0: 'Welcome',
    1: <>
        You are provided with a free plan for <b>7 days</b>.<br/>
        You can add <b>3 accounts</b> from any social network.<br/>
        To add your first account - click the button below<br/>
        or select "Add account" from the menu on the left.
    </>,
    2: 'Add account',
}

const welcome = {
    ru: ru,
    en: en,
}

export default welcome;