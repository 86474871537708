import React from 'react';
import {inject, observer} from "mobx-react";
import ReactDOM from "react-dom";
import TemplateModalAddAccountIndex from "../../templates/modal/addAccount/templateModalAddAccountIndex";
import APIaccountsAdd from "../../api/accountsadd";
import APIaccountsOk from "../../api/accountsok";
import APIaccountsFb from "../../api/accountsfb";
import APIaccountsTwitter from "../../api/accountstwitter";

@inject('modalAddAccount', 'notificationStore', 'initialStore')
@observer
class ModalAddAccountIndex extends React.Component{

    render() {
        const modalAddAccount = this.props.modalAddAccount;

        return (
            <TemplateModalAddAccountIndex
                instagram={this.openAddAccountInstagram}
                fbInstagram={this.openAddAccountFBInstagram}
                vk={this.openAddAccountVK}
                fb={this.openAddAccountFB}
                ok={this.openAddAccountOK}
                tg={this.openAddAccountTG}
                viber={()=>{this.skoro('Viber')}}
                twitter={this.openAddAccountTwitter}
                pinterest={()=>{this.skoro('Pinterest')}}
                close={modalAddAccount.closeActive}
            />
        );
    }

    openAddAccountFBInstagram = (resolve) => {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;
        APIaccountsFb.getFBInstagramUrl().then((data) => {
            if (data.status) {
                const modalAddAccount = this.props.modalAddAccount;
                modalAddAccount.openFbInstagram(data.url);
            } else {
                notificationStore.add(text[28], initialStore.text.code[data.code]);
            }
            resolve(true);
        }).catch(() => {
            notificationStore.add(text[28], text[113]);
            resolve(true);
        });
    }

    openAddAccountFB = (resolve) => {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;
        APIaccountsFb.getFBUrl().then((data) => {
            if (data.status) {
                const modalAddAccount = this.props.modalAddAccount;
                modalAddAccount.openFb(data.url);
            } else {
                notificationStore.add(text[28], initialStore.text.code[data.code]);
            }
            resolve(true);
        }).catch(() => {
            notificationStore.add(text[28], text[113]);
            resolve(true);
        });
    }

    openAddAccountTwitter = (resolve) => {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        APIaccountsTwitter.getUrl().then((data) => {
            if (data.status) {
                const modalAddAccount = this.props.modalAddAccount;
                modalAddAccount.openTwitter(data.url);
            } else {
                notificationStore.add(text[28], initialStore.text.code[data.code]);
            }
            resolve(true);
        }).catch(() => {
            notificationStore.add(text[28], text[113]);
            resolve(true);
        });
    }

    openAddAccountTG = (resolve) => {
        const modalAddAccount = this.props.modalAddAccount;
        modalAddAccount.openTg();
    }

    openAddAccountOK = (resolve) => {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        APIaccountsOk.getOKUrl().then((data) => {
            if (data.status) {
                const modalAddAccount = this.props.modalAddAccount;
                modalAddAccount.openOk(data.url);
            } else {
                notificationStore.add(text[28], initialStore.text.code[data.code]);
            }
            resolve(true);
        }).catch(() => {
            notificationStore.add(text[28], text[113]);
            resolve(true);
        });
    }

    openAddAccountInstagram = () => {
        const modalAddAccount = this.props.modalAddAccount;
        modalAddAccount.openInstagram();
    }

    openAddAccountVK = (resolve) => {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        APIaccountsAdd.getVkUrl().then((data) => {
            if (data.status) {
                const modalAddAccount = this.props.modalAddAccount;
                modalAddAccount.openVk(data.url);
            } else {
                notificationStore.add(text[28], initialStore.text.code[data.code]);
            }
            resolve(true);
        }).catch(() => {
            notificationStore.add(text[28], text[113]);
            resolve(true);
        });
    }

    skoro = (name) => {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;
        notificationStore.add('Скоро', 'Социальная сеть ' + name + ' еще недоступна, но скоро мы ее добавим!')
    }
}

export default ModalAddAccountIndex;
