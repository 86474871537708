import qs from "qs";
import React from "react";
import instance from "./base";


const APIstatistics = {

    set (account, tip) {
        return instance.post('statistics', qs.stringify({
            account: account,
            tip: tip,
        })).then(data => {
            return data;
        });
    },



}

export default APIstatistics;