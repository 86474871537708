import React from 'react';
import {inject, observer} from "mobx-react";

import style from "./style.module.css";
import Textbox from "../post/component/Textbox";
import APIpatterns from "../../api/patterns";
import {getPatterns} from "../../actions/account/patterns";
import {observable} from "mobx";
import ButtonLoad from "../../templates/elements/buttonLoad";
import Button from "../../templates/elements/button";
import Select from "../../templates/elements/select";
import Checkbox from "../../templates/elements/checkbox";
import ReactDOM from "react-dom";


@inject('helpStore', 'initialStore')
@observer
class ModalHelp extends React.Component{

    constructor (props) {
        super(props);

        this.close = this.close.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.window = React.createRef();

        this.openWin = false;
    }

    render() {

        const helpStore = this.props.helpStore;

        if (!helpStore.idCategory || !helpStore.idTeam) {
            if (this.openWin) {
                this.openWin=false;
                this.updateStatusCloseFon();
            }
        } else {
            if (!this.openWin) {
                this.openWin=true;
                this.updateStatusCloseFon();
            }
        }

        if (!helpStore.idCategory || !helpStore.idTeam) {
            return null;
        }

        let name = 'Ошибка';
        let text = 'Такой документации нет или она была удалена.';

        if (helpStore.params[helpStore.idCategory]) {
            if (helpStore.params[helpStore.idCategory].element[helpStore.idTeam]) {
                name = helpStore.params[helpStore.idCategory].element[helpStore.idTeam].name;
                text = helpStore.params[helpStore.idCategory].element[helpStore.idTeam].text;
            }
        }

        let content = <>
                <span className="title-box">{name}</span>

                <div style={{whiteSpace: 'pre-line'}}>
                    {text}
                </div>


                <div className="more-btn btns-box">
                    <Button action={this.close} style={{width: '100%'}} type="button" className="btn border btn-primary">close</Button>
                </div>
            </>;


        const classElement = this.props.initialStore.width < 500 ? "info-wrap editor-wrap" : "info-wrap";

        let render = <>
                <div className={classElement}>
                    <div className="info-scroll">
                        <div className="info-box-wrap">
                            <div className="info-box chief width-5" ref={this.window}>
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-overlay"></div>
            </>;

        return render;
    }

    close () {
        const initialStore = this.props.initialStore;

        initialStore.redirect('/help');
    }

    updateStatusCloseFon = () => {
        if (this.openWin) {
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {
        console.log(22);
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов

                const initialStore = this.props.initialStore;

                initialStore.redirect('/help/faq');
            }
        } catch(error) {
            return null;
        }
    }

}

export default ModalHelp;