import React from 'react';
import {inject, observer} from "mobx-react";
import directLoad from "../../../../media/gif/directLoad.gif";


export default
@inject('initialStore')
@observer
class AccountDirectMessageFelixShare extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {

            let boxMedia = '';
            let width = 300;
            let height = 300;
            if (this.props.width > this.props.height) {
                height = Math.round(width / (this.props.width/this.props.height));
            } else {
                width = Math.round(height * (this.props.width/this.props.height));
            }


            return (
                <div className="chat-message__history">
                    <div className="wrap-history">
                        <div className="inner-history" style={{width: width, height: height}}>
                            <img src={this.props.image}  width={width} height={height}/>
                            <time dateTime={this.props.time} className="chat-message__date">{this.props.time}</time>
                        </div>
                    </div>
                    <div className="user-post" style={{margin: '5px 0 0 10px'}}>
                        <div className="img" style={{width: 32, borderRadius: '50%', overflow: 'hidden', float: 'left'}}><img src={this.props.avatar} width="32" height="32"/></div>
                        <div className="cnt" style={{position: 'absolute', margin: '7px 0 0 35px', color: '#969696', fontSize: 14}}><span className="name">{this.props.username}</span></div>
                    </div>
                </div>
            );
        } else {
            let boxMedia = '';
            let width = 300;
            let height = 300;
            if (this.props.width > this.props.height) {
                height = Math.round(width / (this.props.width/this.props.height));
            } else {
                width = Math.round(height * (this.props.width/this.props.height));
            }


            return (
                <div className="chat-message__history">
                    <div className="wrap-history">
                        <div className="inner-history" style={{width: width, height: height}}>
                            <img src={this.props.image}  width={width} height={height}/>
                            <time dateTime={this.props.time} className="chat-message__date">{this.props.time}</time>
                        </div>
                    </div>
                    <div className="user-post" style={{margin: '5px 0 0 10px'}}>
                        <div className="img" style={{width: 32, borderRadius: '50%', overflow: 'hidden', float: 'left'}}><img src={this.props.avatar} width="32" height="32"/></div>
                        <div className="cnt" style={{position: 'absolute', margin: '7px 0 0 35px', color: '#969696', fontSize: 14}}><span className="name">{this.props.username}</span></div>
                    </div>
                </div>
            );
        }
    }

}