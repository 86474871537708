import React from 'react';
import {inject, observer} from "mobx-react";

export default
@inject('initialStore')
@observer
class LibraryIndex extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.library;

        if (initialStore.templates === 'desktop') {
            return <>
                <div className="filters top">
                    <div className="filter-group">
                        <div className="column-type">
                            {this.props.categories}
                        </div>
                        {this.props.categoriesAdd}
                    </div>
                </div>

                {this.props.posts}
                </>;
        } else {
            return <>

                <div className="filters top filters-library-top">
                    <div className="filter-group">
                        <div className="column-type">
                            {this.props.categories}
                        </div>
                        {this.props.categoriesAdd}
                    </div>
                </div>

                {this.props.posts}
            </>;
        }

    }
}

