import React from 'react';

import {inject, observer} from "mobx-react";
import Textbox from "../../../component/Textbox";
import {observable} from "mobx";
import store from "../../../../../stor/mainStore";
import APIinstagram from "../../../../../api/instagram";

@inject('editorStore', 'notificationStore', 'initialStore')
@observer
class MediaRedBGeoSearh extends React.Component {

    @observable focusInput = false;
    @observable geoObject = {
        id: false,
        load: false,
        search: '',
        array: [],
        z: 0,
    };

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;

        let infoGeo = this.fabric.elementInfoGeo();

        if (infoGeo.name) {
            this.geoObject = {
                id: 0,
                load: false,
                search: infoGeo.name,
                array: [
                    {
                        name: infoGeo.name,
                        NAME: infoGeo.name,
                        lat: infoGeo.name,
                        lng: infoGeo.name,
                    },
                ],
                z: 0,
            };
        }
    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        let render = null;

        let poleName = false;
        let poleNameActive = false
        let openSearh = false;
        if (this.focusInput) {
            render = (
                <div className="event-search">
                    <div className={this.geoObject.load ? "chat-search is-active btn-primary-animate-content" : "chat-search is-active"}>
                        <span className="chat-search__icon">
                            <i className="icon form-control-geo-icon-input"></i>
                        </span>
                        <div className="chat-search__input">
                            <input placeholder="Введите название геолокации" autoFocus value={this.geoObject.search} onChange={this.inputOnChange} onBlur={this.onBlur}/>
                        </div>
                        <span className="chat-search__remove" onMouseDown={this.actionDeliteGeo}>
                            <i className="icon icon-calendar-clear"></i>
                        </span>
                    </div>

                    {this.geoObject.array.length ?
                        <div className="event-search__result">
                            <div className="inner-result">
                                {this.arrayObject()}
                            </div>
                        </div>
                        :null}

                </div>

            );

            openSearh = true;
        } else {
            if (this.geoObject.id === false) {

                render = null;
                poleName = text[150];

            } else {
                render = null;
                poleName = this.geoObject.array[this.geoObject.id].name;
                poleNameActive = true;
            }
        }
        return (
            <div className={openSearh ? "event-drop dropdown is-open" : "event-drop dropdown"}>
                <div className={poleNameActive ? "form-control form-control-geo-icon-active-geo" : "form-control form-control-geo-icon"} onClick={this.onFocus}>{poleName}</div>
                {render}
            </div>
        );
    }

    arrayObject = () => {
        let array = this.geoObject.array.map((value, key) => {
            return (
                <span className="item-result" onMouseDown={() => {this.onClick(key)}}>{value.name}<br/><span className="item-result-adress">{value.address}</span></span>
            );
        });
        return array;
    }

    actionDeliteGeo = () => {
        this.geoObject.id = false;
        this.geoObject.search = '';
        this.geoObject.array = [];

        this.fabric.elementTemplate('', false, false);
    }

    onClick = (id) => {
        this.geoObject.id = id;
        this.geoObject.search = this.geoObject.array[id].name;

        this.fabric.elementTemplate(this.geoObject.array[id].NAME, false, {tip: 'geo', name: this.geoObject.array[id].name, lat: this.geoObject.array[id].lat, lng:this.geoObject.array[id].lng})

    }
    onFocus = () => {
        this.focusInput = true;
    }
    onBlur = () => {
        this.focusInput = false;
    }
    inputOnChange = (e) => {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        const notificationStore = this.props.notificationStore;
        const value = e.target.value;

        this.geoObject.search = value;

        if (this.geoObject.id !== false) {
            if (value != this.geoObject.array[this.geoObject.id].name) {
                this.geoObject.id = false;
                this.fabric.elementTemplate('', false, false);
            }
        }

        let z = this.geoObject.z;
        z = z+1;
        this.geoObject.z = z;

        this.geoObject.array = [];

        if (value === '') {
            return false;
        }

        this.geoObject.load = true;

        APIinstagram.getGeo(value).then((data) => {

            if (z === this.geoObject.z) {

                this.geoObject.load = false;

                if (!data.status) {
                    notificationStore.add(text[151], data.info);
                    return false;
                }

                if (data.return.length === 0) {
                    notificationStore.add(text[152], text[153](value));
                }

                this.geoObject.array = data.return;

            }


        }).catch((error) => {

        });
    }
}

export default MediaRedBGeoSearh;