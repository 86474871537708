import React from 'react';
import {inject, observer} from "mobx-react";

import Time from "../../model/time";
import PostBasic from "../../templates/posts/postBasic";
import PreviewBasic from "../../templates/preview/previewBasic";


@inject('postStore', 'initialStore')
@observer
class PostBox extends React.Component {

    constructor(props) {
        super(props);

        this.openPose = this.openPose.bind(this);
    }

    render() {
        const initialStore = this.props.initialStore;
        const texts = initialStore.text.post;

        const time = this.props.id ? Time.convertUnixToDatePostBox(this.props.time) : null;

        const text = this.props.id ? this.props.post.text : this.props.post.name;

        const post = this.props.post;

        let delite = false;

        if (this.props.delite) {
            delite = <>{texts[0]}<br/><b>{Time.convertUnixToDatePostBox(this.props.delite)}</b></>;
        } else if (this.props.arhive) {
            delite = <>{texts[1]}<br/><b>{Time.convertUnixToDatePostBox(this.props.arhive)}</b></>;
        }



        if (!this.props.preview) {

            return (
                <PostBasic
                    time={time}
                    delite={delite}
                    statusPost={post.status.status}

                    statusInfo={post.status.info}
                    statusProcent={post.status.procent}

                    tip={post.tip}
                    text={text}
                    image={post.icon}
                    click={this.openPose}
                    statistics={this.props.statistics}
                    url={this.props.url}
                    user={this.props.user}
                    story={this.props.story}
                    status={this.props.status}
                    moderation={this.props.moderation}
                />
            );

        } else {
            return (
                <PostBasic
                    time={time}
                    delite={delite}
                    statusPost={post.status.status}

                    statusInfo={post.status.info}
                    statusProcent={post.status.procent}

                    tip={post.tip}
                    text={text}
                    image={post.icon}
                    click={this.openPose}
                    statistics={this.props.statistics}
                    url={this.props.url}
                    user={this.props.user}
                    story={this.props.story}
                    status={this.props.status}
                    moderation={this.props.moderation}
                />
            );
        }

    }

    openPose () {
        const postStore = this.props.postStore;
        postStore.openActive(this.props.id, this.props.post.code);
    }
}
export default PostBox;