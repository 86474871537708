import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import style from "../../../component/menu/index.module.css";

export default
@inject('initialStore')
@observer
class MenuGroup extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <div className="sidebar-users__group">
                <span className="title-group">{this.props.name}</span>
                <ul className="sidebar-users__list">
                    {this.props.children}
                </ul>
            </div>;
        } else {
            return <div className="sidebar-users__group">
                <span className="title-group">{this.props.name}</span>
                <ul className="sidebar-users__list">
                    {this.props.children}
                </ul>
            </div>;
        }

    }
}

