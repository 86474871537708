import React from 'react';
import HeaderBoxLiElement from "../../templates/header/boxLIElement";
import {Link} from "react-router-dom";
import HeaderBox from "../../templates/header/box";
import HeaderMobileName from "../../templates/header/mobileName";
import {inject, observer} from "mobx-react";

@inject('initialStore')
@observer
class HeaderRate extends React.Component {



    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.rate;

        let activeElementName = 'Тариф';

        if (this.props.location.pathname === '/rate/charging') {
            activeElementName = 'Изменить тарификацию';
        }

        return <HeaderBox activeElementName={activeElementName}>
                <HeaderBoxLiElement
                    url={'/rate'}
                    active={this.props.location.pathname === '/rate' ? true: false}
                    name={text[68]}/>
                {/*<HeaderBoxLiElement
                    url={'/rate/charging'}
                    active={this.props.location.pathname === '/rate/charging' ? true: false}
                    name={'Изменить тарификацию'}/>*/}
            </HeaderBox>;
    }

}

export default HeaderRate;