import React from 'react';
import {Redirect} from "react-router";

import APIuser from "../../api/user";
import FormCode from './formCode';
import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import RestorepasswordCodeOk from "./ok";

@inject('initialStore')
@observer
class RestorepasswordCode extends React.Component {

    @observable password = '';
    @observable passwordCopy = '';
    @observable inform = '';

    @observable load = false;

    render() {

        if (this.inform === 'ok') {
            return <RestorepasswordCodeOk/>;
        } else {
            return (
            <FormCode
                password={this.password}
                passwordOnChange={this.passwordOnChange}
                passwordCopy={this.passwordCopy}
                passwordCopyOnChange={this.passwordCopyOnChange}
                inform={this.inform}
                okOnClick={this.okOnClick}
                load={this.load}
            />
            );
        }
    }

    passwordOnChange = (value) => {
        this.password = value;
    }

    passwordCopyOnChange = (value) => {
        this.passwordCopy = value;
    }

    okOnClick = () => {
        this.load = true;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.login;

        this.inform = '';

        if (this.password === '' || this.passwordCopy === '') {
            this.inform = text[17];
            this.load = false;
            return false;
        }

        if (this.password !== this.passwordCopy) {
            this.inform = text[18];
            this.load = false;
            return false;
        }



        APIuser.getRestorepasswordCode(this.props.match.params.code, this.password, initialStore.language).then(data => {

            this.load = false;

            if (!data.status) {
                this.inform = initialStore.text.code[data.code];
            } else {
                this.inform = 'ok';
                this.password='';
                this.passwordCopy='';
            }
        });
    }

    componentDidMount() {

        APIuser.getLogin().then((data) => {
            if (data) {
                this.setState({inform: <Redirect to="/" />, loginTest: true});
            }
        }).catch((error) => {

        });

    }

}

export default RestorepasswordCode;