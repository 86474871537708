import React from "react";

const ru = {
    0: 'Будет удален',
    1: 'Будет архивирован',
    2: 'ошибка обработки.',
    3: 'Ошибка публикации',
    4: 'Требуется проверка',
    5: 'Опубликовано',
    6: 'Пост',
    7: 'История',
    8: 'IGTV',
    9: 'Удаление',
    10: 'Архивирование',
    11: 'Публикуется',
    12: 'Опубликовано',
    13: 'Ошибка публикации.',
    14: 'Внимание',
    15: 'У Вас свернуто окно создание поста, закройте его перед созданием нового поста',
    16: 'Ошибка получения данных, проверьте интернет соединение и повторите попытку',
    17: 'Ошибка',
    18: 'Пост успешно удален, но не удалось обновить ленту, перезагрузите приложение',
    19: 'Описание',
    20: 'Описание скопировано',
    21: 'Показать описание полностью...',
    22: 'Первый комментарий',
    23: 'Комментарий скопирован',
    24: 'Ссылка скопирована',
    25: 'По данной ссылке любой пользователь может посмотреть данную публикацию',
    26: 'Подтверждено',
    27: 'Не удалось подтвердить модерацию, проверьте соединение с интернетом и повторите попытку',
    28: 'Загрузка...',
    29: 'Описание',
    30: 'Описание скопировано',
    31: 'Внимание',
    32: 'Создание поста по ссылке',
    33: <>
        <p>Данная функция позволяет Вам создавать посты по ссылке.</p>
        <p>Поддерживаемые форматы ссылок:</p>
        <p> - ссылка на пост из Instagram</p>
        <p> - ссылка на IGTV из Instagram</p>
        <p> - ссылка на пост из VKontakte</p>
        <p> - ссылка на пост из OK</p>
        <p> - ссылка на пост с сервиса</p>
    </>,
    34: 'Подтверждаю',
    35: 'Отмена',
    36: 'Гео',
    37: 'Публикация',
    38: 'Создал',
    39: 'Ссылка',
    40: ['Скопировано', 'Имя/Почта создателя поста скопировано'],
    41: ['Скопировано', 'Время публикации поста скопировано'],
    42: ['Скопировано', 'Геолокация скопирована'],
    43: ['Скопировано', 'Время удаления/архивирования поста скопировано'],
    44: 'Reels',
}

const en = {
    0: 'Will be deleted',
    1: 'Will be archived',
    2: 'processing error.',
    3: 'Post error',
    4: 'Verification required',
    5: 'Published by',
    6: 'Post',
    7: 'Story',
    8: 'IGTV',
    9: 'Deleting',
    10: 'Archiving',
    11: 'Published',
    12: 'Published by',
    13: 'Publishing error.',
    14: 'Attention',
    15: 'You have minimized the created post window, close it before creating a new post',
    16: 'Data retrieval error, check your internet connection and try again',
    17: 'Error',
    18: 'Post successfully deleted but failed to update the feed, restart the app',
    19: 'Description',
    20: 'Description copied',
    21: 'Show full description ...',
    22: 'First comment',
    23: 'Comment copied',
    24: 'Link copied',
    25: 'Any user can view this publication using this link.',
    26: 'Confirmed',
    27: 'Failed to confirm moderation, check your internet connection and try again',
    28: 'Loading...',
    29: 'Description',
    30: 'Description copied',
    31: 'Attention',
    32: 'Creating a post by link',
    33: <>
        <p>This feature allows you to create posts by reference.</p>
        <p>Supported link formats:</p>
        <p> - link to post from Instagram</p>
        <p> - IGTV link from Instagram</p>
        <p> - link to post from VKontakte</p>
        <p> - link to post from OK</p>
        <p> - link to the post from the service</p>
    </>,
    34: 'Confirm',
    35: 'Cancel',
    36: 'Geo',
    37: 'Publication',
    38: 'Created',
    39: 'Link',
    40: ['Copied', 'Post Creator Name/Mail Copied '],
    41: ['Copied', 'Post publication time copied'],
    42: ['Copied', 'Geolocation copied'],
    43: ['Copied', 'Post deletion/archiving time copied'],
    44: 'Reels',
}

const post = {
    ru: ru,
    en: en,
}

export default post;
