import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {config, Spring} from "react-spring/renderprops";
import ReactDOM from "react-dom";
import ButtonModeration from "../../../component/modalVievPost/ButtonModeration";
import ButtonDubl from "../../../component/modalVievPost/ButtonDubl";
import ButtonCloseMobile from "../../../component/modalVievPost/ButtonCloseMobile";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import ButtonUrl from "../../../component/modalVievPost/ButtonUrl";

export default
@inject('initialStore', 'notificationStore')
@observer
class TemplateModalViewPost extends React.Component {

    @observable infoBoxTop = null;
    @observable status = 'load';
    @observable textClouce = null;

    constructor(props) {
        super(props);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.infoWrap = React.createRef();
        this.window = React.createRef();
        this.textBox = React.createRef();

        this.openWin = false;


    }

    render() {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.post;

        if (initialStore.templates === 'desktop') {

            let textPost = this.props.text;
            if (textPost) {
                textPost = window.emojiOne(textPost);
            }

            let content = <div ref={this.infoWrap} className="info-wrap editor-wrap">
                <div className="info-scroll">
                    <div className="info-box-wrap other add-post__box-wrap">
                        <div style={{opacity: 0}} className="info-box add-post__box" ref={this.window}>
                            Load
                        </div>
                    </div>
                </div>
            </div>;


            if (this.infoBoxTop !== null) {

                const boxStartTop = this.infoBoxTop;
                let boxFinishTop = this.infoBoxTop === null ? boxStartTop : this.infoBoxTop - 130;

                if (this.status === 'close') {
                    boxFinishTop = Math.round((this.infoWrap.current.clientHeight/2) + (this.window.current.clientHeight/2));
                }

                let box = null;


                if (!this.props.open) {
                    //box = <div className="element-load-post" style={{fontSize: '18px', margin: '-5px', textAlign: 'center'}}>Загрузка...</div>;
                } else {

                    box = <>
                        <span className="close-box" onClick={this.close}><i className="icon icon-close-info"></i></span>
                        <span className="title-box">{this.props.title}</span>

                        <div className="add-post">
                            <div className="add-post__picture">
                                <div className="add-post__gallery">
                                    {this.props.contentMedia}
                                </div>
                            </div>
                            <div className="add-post__cnt">
                                {this.props.text ?
                                    <div className="add-post__desc">
                                        <div className="add-post__desc-add">
                                            <div className="top-add">
                                                <span className="title-add">{text[19]}</span>
                                                <CopyToClipboard text={this.props.text} onCopy={() => {notificationStore.add(text[20])}}>
                                                    <span className="copy-add"><i className="icon icon-add-post-desc"></i></span>
                                                </CopyToClipboard>


                                            </div>
                                            <p className="element-text-box element-text-box2" style={this.textClouce ? {whiteSpace: 'pre-line', overflow: 'hidden', height: 109}:{whiteSpace: 'pre-line', overflow: 'hidden'}} ref={(e) => {this.textBox=e}}>
                                                {textPost}
                                            </p>

                                            {this.textClouce ?
                                                <div className="add-post__desc_open" onClick={() => {this.textClouce = false;}}>{text[21]}</div>
                                                :<div className="add-post__desc_open" style={{display: 'none'}}></div>}
                                        </div>
                                    </div>
                                    :null}

                                {this.props.coment ?
                                    <div className="add-post__desc">
                                        <div className="add-post__desc-add">
                                            <div className="top-add">
                                                <span className="title-add">{text[22]}</span>
                                                <CopyToClipboard text={this.props.coment} onCopy={() => {notificationStore.add(text[23])}}>
                                                    <span className="copy-add"><i className="icon icon-add-post-desc"></i></span>
                                                </CopyToClipboard>

                                            </div>
                                            <p rows="4" placeholder="">{this.props.coment}</p>
                                        </div>
                                    </div>
                                    :null}

                                <div className="add-post__info">
                                    <div className="post-info other post-info-static-info">
                                        {this.props.likes !== false ?
                                            <div className="post-info__item like">
                                                <span className="icon-item"><i className="icon icon-abs icon-post-like"></i></span>
                                                {this.props.likes}
                                            </div>
                                            :null}
                                        {this.props.comment !== false ?
                                            <div className="post-info__item comment">
                                                <span className="icon-item"><i className="icon icon-abs icon-post-comment"></i></span>
                                                {this.props.comment}
                                            </div>
                                            :null}
                                        {this.props.view !== false ?
                                            <div className="post-info__item eye">
                                                <span className="icon-item"><i className="icon icon-abs icon-post-eye"></i></span>
                                                {this.props.view}
                                            </div>
                                            :null}
                                    </div>

                                    {this.props.geo ?
                                        <CopyToClipboard text={this.props.geo} onCopy={() => {notificationStore.add(this.props.textInfo.geoCopy[0], this.props.textInfo.geoCopy[1])}}>
                                        <div className="post-info other post-info-copy">
                                            <div className="post-info__item time">
                                                <span className="icon-item"><i className="icon icon-abs icon-post-geoPublication"></i></span>
                                                <div className="info-box-post">{this.props.textInfo.geo}: <span>{this.props.geo}</span></div>
                                            </div>
                                        </div>
                                        </CopyToClipboard>
                                        :null}

                                    {this.props.time ?
                                        <CopyToClipboard text={this.props.time} onCopy={() => {notificationStore.add(this.props.textInfo.publicationCopy[0], this.props.textInfo.publicationCopy[1])}}>
                                        <div className="post-info other post-info-copy">
                                            <div className="post-info__item time">
                                                <span className="icon-item"><i className="icon icon-abs icon-post-timePublication"></i></span>
                                                <div className="info-box-post">{this.props.textInfo.publication}: <span style={!this.props.timeColor ? {color:this.props.timeColor}:null}>{this.props.time} {this.props.publicationStatusDate ? <>({this.props.publicationStatus})</> :null}</span></div>
                                            </div>
                                        </div>
                                        </CopyToClipboard>
                                        :null}

                                    {this.props.deliteTime ?
                                        <CopyToClipboard text={this.props.deliteTime} onCopy={() => {notificationStore.add(this.props.textInfo.delCopy[0], this.props.textInfo.delCopy[1])}}>
                                        <div className="post-info other post-info-copy">
                                            <div className="post-info__item remove">
                                                <span className="icon-item"><i className="icon icon-abs icon-post-timeDelite"></i></span>
                                                <div className="info-box-post">{this.props.deliteTimeInfo}: <span>{this.props.deliteTime}</span></div>
                                            </div>
                                        </div>
                                        </CopyToClipboard>
                                        :null}

                                    {this.props.userCreator ?
                                        <CopyToClipboard text={this.props.userCreator} onCopy={() => {notificationStore.add(this.props.textInfo.createdCopy[0], this.props.textInfo.createdCopy[1])}}>
                                        <div className="post-info other post-info-copy">
                                            <div className="post-info__item remove">
                                                <span className="icon-item"><i className="icon icon-abs icon-post-creatorUser"></i></span>
                                                <div className="info-box-post">{this.props.textInfo.created}: <span>{this.props.userCreator}</span></div>
                                            </div>
                                        </div>
                                        </CopyToClipboard>
                                    :null}
                                </div>

                                <ButtonModeration/>

                                {this.props.editButton !== null ?
                                    <div className="add-post__events">
                                        <div className="add-post__events_box">
                                            <div className="add-post__events_box_element w-75" style={{padding: '0 0 0 0'}}>{this.props.editButton}</div>
                                            <div className="add-post__events_box_element w-25" style={{padding: '0 0 0 8px'}}>{this.props.deliteButton}</div>
                                        </div>
                                    </div>
                                :null}

                                <ButtonUrl url={this.props.url}/>

                                <ButtonDubl/>

                                <ButtonCloseMobile action={this.close}/>

                            </div>
                        </div>
                    </>;
                }


                if (this.status === 'open') {
                    boxFinishTop = 0;
                }


                /*content = <Spring
                    from={{ infoBoxTop: boxStartTop}}
                    to={{ infoBoxTop: boxFinishTop}}
                    config={config.stiff}>
                    {props => {

                        if (props.infoBoxTop < 2) {
                            props.infoBoxTop = 0;
                        }

                        const infoBoxStyle = {
                            top: props.infoBoxTop + 'px',
                            opacity:  this.infoBoxTop === null ? 0:1,
                        };

                        if (!this.props.open) {
                            infoBoxStyle.width = 200;
                        }

                        return <div ref={this.infoWrap} className="info-wrap editor-wrap">
                            <div className="info-scroll">
                                <div className="info-box-wrap other add-post__box-wrap">
                                    <div style={infoBoxStyle} className="info-box add-post__box" ref={this.window}>
                                        {box}
                                    </div>
                                </div>
                            </div>
                        </div>;

                    }}
                </Spring>;*/


                const infoBoxStyle = {
                    /*top: boxFinishTop + 'px',*/
                    transform: 'translateY('+boxFinishTop+'px)',
                    //opacity:  this.infoBoxTop === null ? 0:1,
                };

                if (!this.props.open) {
                    //infoBoxStyle.width = 200;
                }

                let classNameEle = this.infoBoxTop === null ? "info-box add-post__box add-post__box_animation" : "info-box add-post__box add-post__box_animation add-post__box_animation-finish";

                if (!this.props.open) {
                    classNameEle = "info-box add-post__box add-post__box_animation";
                }
                if (this.status === 'close') {
                    classNameEle = "info-box add-post__box add-post__box_animation add-post__box_animation-end";
                }



                content = <div ref={this.infoWrap} className="info-wrap editor-wrap">
                    <div className="info-scroll">
                        <div className="info-box-wrap other add-post__box-wrap">
                            <div className={classNameEle} ref={this.window}>
                                {box}
                            </div>
                        </div>
                    </div>
                </div>
            }

            let infoOverlayStyleOpacity = 1;
            if (this.status === 'close') {
                infoOverlayStyleOpacity = 0;
            }

            let render = <>
                {content}

                <Spring
                    from={{ opacity: 0}}
                    to={{ opacity: infoOverlayStyleOpacity}}
                    config={config.stiff}>
                    {props => {

                        let newClass = "info-overlay info-overlay-animation";

                        if (props.opacity > 0) {
                            newClass = "info-overlay info-overlay-animation info-overlay-animation-finish";
                            if (infoOverlayStyleOpacity === 0) {
                                newClass = "info-overlay info-overlay-animation";
                            }
                        }

                        if (!this.props.open) {
                            newClass+=" btn-primary-animate-content";
                        }

                        return <div className={newClass}></div>;
                    }}
                </Spring>

            </>;

            return render;


        } else {

            let content = <div ref={this.infoWrap} className="info-wrap">
                <div className="info-scroll">
                    <div className="info-box-wrap other add-post__box-wrap">
                        <div style={{opacity: 0}} className="info-box add-post__box" ref={this.window}>
                            Load
                        </div>
                    </div>
                </div>
            </div>;


            if (this.infoBoxTop !== null) {

                const boxStartTop = this.infoBoxTop;
                let boxFinishTop = this.infoBoxTop === null ? boxStartTop : this.infoBoxTop - 130;

                if (this.status === 'close') {
                    boxFinishTop = Math.round((this.infoWrap.current.clientHeight/2) + (this.window.current.clientHeight/2));
                }

                let box = null;


                if (!this.props.open) {
                    //box = <div className="element-load-post" style={{fontSize: '18px', margin: '-5px', textAlign: 'center'}}>Загрузка...</div>;
                } else {

                    box = <>

                        <div className="add-post">
                            <div className="add-post__picture">
                                <div className="add-post__gallery">
                                    {this.props.contentMedia}
                                </div>
                            </div>
                            <div className="add-post__cnt">
                                {this.props.text ?
                                    <div className="add-post__desc">
                                        <div className="add-post__desc-add">
                                            <div className="top-add">
                                                <span className="title-add">{text[19]}</span>
                                                <CopyToClipboard text={this.props.text} onCopy={() => {notificationStore.add(text[20])}}>
                                                    <span className="copy-add"><i className="icon icon-add-post-desc"></i></span>
                                                </CopyToClipboard>


                                            </div>
                                            <p className="element-text-box element-text-box2" style={this.textClouce ? {whiteSpace: 'pre-line', overflow: 'hidden', height: 109}:{whiteSpace: 'pre-line', overflow: 'hidden'}} ref={(e) => {this.textBox=e}}>
                                                {this.props.text}
                                            </p>

                                            {this.textClouce ?
                                                <div className="add-post__desc_open" onClick={() => {this.textClouce = false;}}>{text[21]}</div>
                                                :<div className="add-post__desc_open" style={{display: 'none'}}></div>}
                                        </div>
                                    </div>
                                    :null}

                                {this.props.coment ?
                                    <div className="add-post__desc">
                                        <div className="add-post__desc-add">
                                            <div className="top-add">
                                                <span className="title-add">{text[22]}</span>
                                                <CopyToClipboard text={this.props.coment} onCopy={() => {notificationStore.add(text[23])}}>
                                                    <span className="copy-add"><i className="icon icon-add-post-desc"></i></span>
                                                </CopyToClipboard>

                                            </div>
                                            <p rows="4" placeholder="">{this.props.coment}</p>
                                        </div>
                                    </div>
                                    :null}

                                <div className="add-post__info">
                                    <div className="post-info other">
                                        {this.props.likes !== false ?
                                            <div className="post-info__item like">
                                                <span className="icon-item"><i className="icon icon-abs icon-post-like"></i></span>
                                                {this.props.likes}
                                            </div>
                                            :null}
                                        {this.props.comment !== false ?
                                            <div className="post-info__item comment">
                                                <span className="icon-item"><i className="icon icon-abs icon-post-comment"></i></span>
                                                {this.props.comment}
                                            </div>
                                            :null}
                                        {this.props.view !== false ?
                                            <div className="post-info__item eye">
                                                <span className="icon-item"><i className="icon icon-abs icon-post-eye"></i></span>
                                                {this.props.view}
                                            </div>
                                            :null}
                                    </div>

                                    {this.props.geo ?
                                        <CopyToClipboard text={this.props.geo} onCopy={() => {notificationStore.add(this.props.textInfo.geoCopy[0], this.props.textInfo.geoCopy[1])}}>
                                            <div className="post-info other post-info-copy">
                                                <div className="post-info__item time">
                                                    <span className="icon-item"><i className="icon icon-abs icon-post-geoPublication"></i></span>
                                                    <div className="info-box-post">{this.props.textInfo.geo}: <span>{this.props.geo}</span></div>
                                                </div>
                                            </div>
                                        </CopyToClipboard>
                                        :null}

                                    {this.props.time ?
                                        <CopyToClipboard text={this.props.time} onCopy={() => {notificationStore.add(this.props.textInfo.publicationCopy[0], this.props.textInfo.publicationCopy[1])}}>
                                            <div className="post-info other post-info-copy">
                                                <div className="post-info__item time">
                                                    <span className="icon-item"><i className="icon icon-abs icon-post-timePublication"></i></span>
                                                    <div className="info-box-post">{this.props.textInfo.publication}: <span style={!this.props.timeColor ? {color:this.props.timeColor}:null}>{this.props.time} <br/>{this.props.publicationStatusDate ? <>({this.props.publicationStatus})</> :null}</span></div>
                                                </div>
                                            </div>
                                        </CopyToClipboard>
                                        :null}

                                    {this.props.deliteTime ?
                                        <CopyToClipboard text={this.props.deliteTime} onCopy={() => {notificationStore.add(this.props.textInfo.delCopy[0], this.props.textInfo.delCopy[1])}}>
                                            <div className="post-info other post-info-copy">
                                                <div className="post-info__item remove">
                                                    <span className="icon-item"><i className="icon icon-abs icon-post-timeDelite"></i></span>
                                                    <div className="info-box-post">{this.props.deliteTimeInfo}: <span>{this.props.deliteTime}</span></div>
                                                </div>
                                            </div>
                                        </CopyToClipboard>
                                        :null}

                                    {this.props.userCreator ?
                                        <CopyToClipboard text={this.props.userCreator} onCopy={() => {notificationStore.add(this.props.textInfo.createdCopy[0], this.props.textInfo.createdCopy[1])}}>
                                            <div className="post-info other post-info-copy">
                                                <div className="post-info__item remove">
                                                    <span className="icon-item"><i className="icon icon-abs icon-post-creatorUser"></i></span>
                                                    <div className="info-box-post">{this.props.textInfo.created}: <span>{this.props.userCreator}</span></div>
                                                </div>
                                            </div>
                                        </CopyToClipboard>
                                        :null}
                                </div>

                                <ButtonModeration/>

                                {this.props.editButton !== null ?
                                    <div className="add-post__events">
                                        <div className="add-post__events_box">
                                            <div className="add-post__events_box_element w-75" style={{padding: '0 0 0 0'}}>{this.props.editButton}</div>
                                            <div className="add-post__events_box_element w-25" style={{padding: '0 0 0 8px'}}>{this.props.deliteButton}</div>
                                        </div>
                                    </div>
                                    :null}
                                <ButtonUrl url={this.props.url}/>

                                <ButtonDubl/>

                                <ButtonCloseMobile action={this.close}/>
                            </div>
                        </div>
                    </>;
                }


                if (this.status === 'open') {
                    boxFinishTop = 0;
                }


                /*content = <Spring
                    from={{ infoBoxTop: boxStartTop}}
                    to={{ infoBoxTop: boxFinishTop}}
                    config={config.stiff}>
                    {props => {

                        if (props.infoBoxTop < 2) {
                            props.infoBoxTop = 0;
                        }

                        const infoBoxStyle = {
                            top: props.infoBoxTop + 'px',
                            opacity:  this.infoBoxTop === null ? 0:1,
                        };

                        if (!this.props.open) {
                            infoBoxStyle.width = 200;
                        }

                        return <div ref={this.infoWrap} className="info-wrap editor-wrap">
                            <div className="info-scroll">
                                <div className="info-box-wrap other add-post__box-wrap">
                                    <div style={infoBoxStyle} className="info-box add-post__box" ref={this.window}>
                                        {box}
                                    </div>
                                </div>
                            </div>
                        </div>;

                    }}
                </Spring>;*/


                const infoBoxStyle = {
                    /*top: boxFinishTop + 'px',*/
                    transform: 'translateY('+boxFinishTop+'px)',
                    //opacity:  this.infoBoxTop === null ? 0:1,
                };

                if (!this.props.open) {
                    //infoBoxStyle.width = 200;
                }

                let classNameEle = this.infoBoxTop === null ? "info-box add-post__box  add-post__box_animation info-scroll" : "info-box add-post__box add-post__box_content add-post__box_animation add-post__box_animation-finish info-scroll";

                if (!this.props.open) {
                    classNameEle = "info-box add-post__box add-post__box_content add-post__box_animation info-scroll";
                }
                if (this.status === 'close') {
                    classNameEle = "info-box add-post__box add-post__box_content add-post__box_animation add-post__box_animation-end info-scroll";
                }

                content = <div ref={this.infoWrap} className="info-wrap editor-wrap">

                        <div className="info-box-wrap other add-post__box-wrap add-post__box-wrap-fix">
                            <div className="viev-box-top">
                                <div className="viev-box-top-close" onClick={this.close}><i className="icon icon-nav-exit2"></i></div>
                                <div className="viev-box-top-name">{this.props.title}</div>
                            </div>
                            <div className={classNameEle} ref={this.window}>

                                <div className="add-post__box_content_box">
                                    {box}
                                </div>
                            </div>
                        </div>

                </div>
            }

            let infoOverlayStyleOpacity = 1;
            if (this.status === 'close') {
                infoOverlayStyleOpacity = 0;
            }

            let render = <>
                {content}

                <Spring
                    from={{ opacity: 0}}
                    to={{ opacity: infoOverlayStyleOpacity}}
                    config={config.stiff}>
                    {props => {

                        let newClass = "info-overlay info-overlay-animation";

                        if (props.opacity > 0) {
                            newClass = "info-overlay info-overlay-animation info-overlay-animation-finish";
                            if (infoOverlayStyleOpacity === 0) {
                                newClass = "info-overlay info-overlay-animation";
                            }
                        }

                        if (!this.props.open) {
                            newClass+=" btn-primary-animate-content";
                        }

                        return <div className={newClass}></div>;
                    }}
                </Spring>

            </>;

            return render;

        }

    }


    componentDidMount() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            this.infoBoxTop = Math.round((this.infoWrap.current.clientHeight/2) + (this.window.current.clientHeight/2));
        } else {
            this.infoBoxTop = Math.round((this.infoWrap.current.clientHeight) - (this.window.current.clientHeight));
        }


        if (!this.openWin && this.status === 'load') {
            this.openWin = true;
            this.updateStatusCloseFon();
        }

        if (this.props.open) {
            this.status = 'open';
        }


    }

    componentDidUpdate() {

        if (this.status === 'close') {
            setTimeout(() => {
                this.props.close();
            }, 250);
        }

        if (this.props.open && this.status === 'load') {
            this.status = 'open';
        }

        const windowText = this.textBox;
        if (windowText && this.textClouce === null) {
            if (windowText.offsetHeight) {
                if (windowText.offsetHeight > 110) {
                    this.textClouce = true;
                } else {
                    this.textClouce = false;
                }
            }
        }

    }

    updateStatusCloseFon = () => {

        const initialStore = this.props.initialStore;

        let type = 'click';

        if (initialStore.templates !== 'desktop') {
            type = 'touchend';
        }

        if (this.openWin) {
            document.addEventListener(type, this.handleClickOutside, false);
        } else {
            document.removeEventListener(type, this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов

                const postStore = this.props.postStore;

                //this.props.close();
                this.close();
            }
        } catch(error) {
            return null;
        }
    }

    close = () => {
        this.status = 'close';
        this.openWin = false;
        this.updateStatusCloseFon();
    }
};
