import React from 'react';
import {inject, observer} from "mobx-react";
import ReactDOM from "react-dom";

@inject('mediaStore', 'notificationStore', 'initialStore')
@observer
class ModalDelMedia extends React.Component{

    constructor (props) {
        super(props);

        this.close = this.close.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.window = React.createRef();

        this.open = false;
    }

    render() {
        const mediaStore = this.props.mediaStore;


        if (mediaStore.delMediaID === false) {

            if (this.open) {
                this.open=false;
                this.updateStatusCloseFon();
            }

            return null;
        }

        if (mediaStore.delMediaID !== false) {
            this.open=true;
            this.updateStatusCloseFon();
        }

        const content = <div className="delMediaFile">
            <div className="modal-inner">
                <span className="title-modal">Удаление файла</span>
                <p className="desc-modal">Вы уверены, что хотите удалить файл из поста?</p>
                <div className="more-btn">
                    <button type="button" className="btn btn-primary" onClick={this.deliteFile}>Удалить</button>
                    <button type="button" className="btn border btn-primary" onClick={this.close}>Отмена</button>
                </div>
            </div>
        </div>;



        let render = <>
                <div className="info-wrap">
                    <div className="info-scroll">
                        <div className="info-box-wrap">
                            <div className="info-box chief width-4" ref={this.window}>
                                <span onClick={this.close} className="close-box"><i className="icon icon-close-info"></i></span>
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-overlay"></div>
            </>;

        return render;
    }

    deliteFile = () => {
        const mediaStore = this.props.mediaStore;
        const id = mediaStore.delMediaID;

        mediaStore.deliteMedia(id);

        mediaStore.delMediaID=false;

    }

    updateStatusCloseFon = () => {

        const initialStore = this.props.initialStore;
        let type = 'click';
        if (initialStore.templates !== 'desktop') {
            type = 'touchend';
        }

        if (this.open) {
            document.addEventListener(type, this.handleClickOutside, false);
        } else {
            document.removeEventListener(type, this.handleClickOutside, false);
        }
    }

    close () {
        const mediaStore = this.props.mediaStore;
        mediaStore.delMediaID = false;
    }


    handleClickOutside(e) {
        console.log(15);
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов

                const mediaStore = this.props.mediaStore;

                if (mediaStore.delMediaID !== false) {
                    mediaStore.delMediaID = false;
                }
            }
        } catch(error) {
            return null;
        }
    }

}

export default ModalDelMedia;