import React from 'react';
import {inject, observer} from "mobx-react";

import {getPatterns} from "../../../actions/account/patterns";
import {observable} from "mobx";
import APIpatterns from "../../../api/patterns";
import AccountPatternsIndex from "../../../templates/account/patterns";
import AccountPatternsElement from "../../../templates/account/patterns/element";
import LoaderComponent from "../../../templates/loaderComponent";

@inject('accountsStore', 'menuStore', 'modalPatternsStore', 'notificationStore', 'initialStore')
@observer
class Patterns extends React.Component {

    @observable deliteButton = false;

    constructor(props) {
        super(props);

        this.openModal = this.openModal.bind(this);
        this.delite = this.delite.bind(this);
    }

    render() {

        const accountsStore = this.props.accountsStore;

        const account = accountsStore.active;
        const patterns = accountsStore.patterns.list;


        let content = null;

        let patternsList = '';
        if (patterns === null) {
            patternsList = <LoaderComponent/>;
        } else {

            let listCommon = [];
            let list = [];
            for (const pattern of patterns) {

                if (accountsStore.list[account].id !== pattern.account && pattern.account!=='0') continue;

                const element = <AccountPatternsElement
                    name={pattern.name}
                    text={pattern.text}
                    redAction={() => {this.openModal(pattern.id, 'red')}}
                    delAction={(resolve) => {this.delite(pattern.id, resolve)}}
                    />;

                if (pattern.account === '0') {
                    listCommon.push(element);
                } else {
                    list.push(element);
                }

            }

            patternsList = <>
                {listCommon}
                {list}
            </>;
        }

        return <AccountPatternsIndex
                add={() => {this.openModal(false, 'create')}}
                account={accountsStore.list[accountsStore.active].name}
                typeAccount={accountsStore.list[accountsStore.active].tip}
                imgAccount={accountsStore.list[accountsStore.active].img}
                >
            {content}
            {patternsList}
        </AccountPatternsIndex>;
    }

    delite (id, resolve) {
        const accountsStore = this.props.accountsStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        const patterns = accountsStore.patterns.list;

        const listCesh = patterns;

        let list = [];
        for (const pattern of patterns) {
            if (id === pattern.id) continue;

            list.push({...pattern});
        }
        accountsStore.patterns.list = list;

        APIpatterns.getPatternsDel(id).then((data) => {
            if (data.status) {
                notificationStore.add(text[114]);
            } else {
                notificationStore.add(text[28], text[115]);
                accountsStore.patterns.list = listCesh;
            }
        }).catch((e) => {
            notificationStore.add(text[28], text[115]);
            accountsStore.patterns.list = listCesh;
        }).finally(() => {
            resolve(true);
        });
    }

    openModal (idPattern, type) {

        const accountsStore = this.props.accountsStore;
        const modalPatternsStore = this.props.modalPatternsStore;
        const account = accountsStore.active;
        const patterns = accountsStore.patterns.list;

        if (type === 'create') {
            modalPatternsStore.openActive(accountsStore.list[account].id, type);
        } else {

            let name = '';
            let text = '';
            let common = false;
            let accountID = accountsStore.list[account].id;
            for (const pattern of patterns) {
                if (pattern.id === idPattern) {
                    name = pattern.name;
                    text = pattern.text;
                    if (pattern.account ==='0') {
                        common = true;
                    } else {
                        accountID = pattern.account;
                    }
                    break;
                }
            }

            modalPatternsStore.openActive(accountID, type, name, text, common, idPattern);
        }



    }


    componentDidMount() {
        this.props.menuStore.updateActiveComponentAccount('patterns');
        this.update();
    }

    componentDidUpdate() {
        this.update();
    }

    update() {

        const accountsStore = this.props.accountsStore;
        const accounts = accountsStore.active;

        const hesh = accountsStore.patterns.hesh;
        const load = accountsStore.patterns.load;
        if (!load) {
            getPatterns();
        }
    }
}

export default Patterns;