import {action, observable} from "mobx";

class mediaStore {

    @observable type = 'post';
    @observable open = false;
    @observable collapsed = false; //свернут
    @observable editorOpen = false;
    @observable editorMedia = false;
    @observable accounts = [];
    @observable media = [];
    @observable mediaIdLoad = 0;
    @observable maxMedia = 10;
    @observable idLoad = false;

    //под каждый аккаунт свое время
    @observable timePro = false;

    //под каждый аккаунт свой текст
    @observable textPro = false;

    //
    @observable commentOff = false;

    //
    @observable comment = false;
    @observable commentText = '';


    @observable textSavePost = '';

    @observable geo = false;
    @observable geoObject = {
        search: '',
        z: 0,
        load: false,
        array: [],
        id: false,
    };

    @observable geoFull= false;
    @observable geoObjectFull= [];

    @observable name = '';
    @observable category = 0;


    //данные редактируемого поста
    @observable id = false;
    @observable code = false;

    @observable mediaLoading = false;
    @observable mediaLoadingPercent = 0;

    @observable mediaLoadingPercentData = [];

    @observable delMediaID = false;

    @observable urlStory = '';

    @observable multyPost = [];

    @observable IgtvImage = false;

    @observable PrivievPost = true;

    @observable ReStory = false;

    @observable obrabotkaMediaFiles = false;

    @action actionMediaLoadingPercentData = (type) => {
        this.mediaLoadingPercentData = type;

        let key = 0;
        let percents = 0;
        for (const percent of this.mediaLoadingPercentData) {
            key++;
            percents+= percent;
        }
        if (percents > 0) {
            this.mediaLoadingPercent = Math.ceil(percents/key);
        }

    }

    @action actionMediaLoading = (type) => {
        this.mediaLoading = type;
    }

    @action actionIdAndCode = (id, code) => {
        this.id = id;
        this.code = code;
    }

    @action actionGeoObjectId = (value, accoint) => {
        if (!accoint) {
            this.geoObject.id = value;
        } else {
            this.updateObjectFullElement(accoint, {id: value});
        }

    }

    @action actionGeoObjectIdTrue = (value, accoint) => {
        if (!accoint) {
            this.geoObject.id = value;
            this.geoObject.search = this.geoObject.array[value].name;
        } else {
            let search= '';
            for (let geoObjectFullElement of this.geoObjectFull.slice()) {
                if (geoObjectFullElement.idAccount === accoint) {
                    search = geoObjectFullElement.array[value].name;
                }
            }

            this.updateObjectFullElement(accoint, {id: value, search: search});
        }

    }

    @action actionGeoObjectSearch = (value, accoint) => {
        if (!accoint) {
            this.geoObject.search = value;
        } else {
            this.updateObjectFullElement(accoint, {search: value});
        }
    }

    updateObjectFullElement = (account, array) => {
        const newGeoObjectFull = [];
        for (let geoObjectFullElement of this.geoObjectFull.slice()) {
            if (geoObjectFullElement.idAccount === account) {
                newGeoObjectFull.push({
                    ...geoObjectFullElement,
                    ...array,
                });

            } else {
                newGeoObjectFull.push({...geoObjectFullElement});
            }
        }
        this.geoObjectFull = newGeoObjectFull;
    }

    @action actionTextSavePost = (value) => {
        this.textSavePost = value;
    }

    @action actionOpen = (value, collapsed = false) => {
        this.open = value;
        this.collapsed = collapsed;
        this.maxMedia = 10;
    }

    @action actionOpenStory = (value, collapsed = false) => {
        this.type = 'story';
        this.open = value;
        this.collapsed = collapsed;
        this.maxMedia = 1;
    }

    @action actionMultyPost = (value, collapsed = false) => {
        this.type = 'multyPost';
        this.open = value;
        this.collapsed = collapsed;
        this.maxMedia = 50;
    }

    @action actionMultyStory = (value, collapsed = false) => {
        this.type = 'multyStory';
        this.open = value;
        this.collapsed = collapsed;
        this.maxMedia = 50;
    }

    @action actionOpenIgtv = (value, collapsed = false) => {
        this.type = 'igtv';
        this.open = value;
        this.collapsed = collapsed;
        this.maxMedia = 1;
    }
    @action actionOpenReels = (value, collapsed = false) => {
        this.type = 'reels';
        this.open = value;
        this.collapsed = collapsed;
        this.maxMedia = 1;
    }

    @action actionFileLoadUpdate = (mediaId) => {
        this.mediaIdLoad = mediaId;
    }

    @action actionMediaIdLoad = (mediaIdLoad) => {
        this.mediaIdLoad = mediaIdLoad;
    }

    @action actionIdLoad = (idLoad) => {
        this.idLoad = idLoad;
    }

    @action actionEditorMedia = (editorMedia) => {
        this.editorMedia = editorMedia;
    }

    @action actionEditorOpen = (id) => {
        this.editorOpen = id;
    }
    @action actionEditorType = (type) => {
        this.type = type;

        if (type === 'editStory' || type === 'editIgtv') {
            this.maxMedia = 1;
        }
    }

    @action deliteMedia = (id) => {
        let newMedia = [];

        for(const element of this.media.slice()) {

            if (id !== element.id) {
                newMedia.push({...element});
            }

        }
        this.media.replace(newMedia);

        //this.mediaIdLoad--;
        /*const media = this.media.slice();
        for(const elementID in media) {

            if (id === media[elementID].id) {
                this.media[elementID] = undefined;

            }

        }*/
    }


    @action actionListPostAdd = (id, loadFileId, time, urlStory = '') => {


        const object = {
            id: id,
            loadFileId: loadFileId,
            text: '',
            time: time,
            urlStory: urlStory,
            del: {
                status: false,
                int: 1,
                tip: 2,
            },
        };
        if (this.multyPost) {
            this.multyPost.push(object);
        } else {
            this.multyPost = [object];
        }

        return this.multyPost.slice().length - 1;
    }

    @action actionMediaStart = (id, type, file, url, icon, processingPercent, duration=false) => {


        const object = {
            type: type,
            processingPercent: processingPercent,
            loadPercent: 0,
            icon: icon,
            url: url,
            crop: {
                unit: '%',
                x: 0,
                y: 0,
                width: 100,
                height: 100,
                proportions: 1,
            },
            draggable: {
                x: 0,
                y: 0,
            },
            duration: duration,
            proportions: 0,
            proportionsBackground: 0,
            zoom: 1,
            color: '#ffffff',
            accounts: [],
            id: id,
            /*file: file,*/
            elements: {
                video: [],
                image: [],
            },
            soundDelite: false,
        };
        if (this.media) {
            this.media.push(object);
        } else {
            this.media = [object];
        }

        return this.media.slice().length - 1;
    }

    @action actionMediaStartEditor = (file) => {

        if (!file.color) {
            file.color  = '#ffffff';
        }

        const object = {
            type: file.type,
            processingPercent: 100,
            loadPercent: true,
            icon: file.icon,
            cover: file.cover,
            url: file.url,
            crop: {
                ...file.crop,
            },
            draggable: {
                ...file.draggable,
            },
            duration: {
                icons: [],
                iconsCover: [],
                ...file.duration,
            },
            proportions: file.proportions,
            proportionsBackground: file.proportionsBackground,
            zoom: file.zoom,
            color: file.color,
            id: file.id,
            file: file.file,
            accounts: file.accounts,
            elements: {
                video: file.elements.video,
                image: file.elements.image,
            },
            soundDelite: file.soundDelite,

        };
        if (this.media) {
            this.media.push(object);
        } else {
            this.media = [object];
        }
    }

    @action actionMediaUpdate = (id, update) => {

        for (const elementID in this.media.slice()) {

            if (this.media[elementID].id === id) {

                this.media[elementID] = {
                    ...this.media[elementID],
                    ...update,
                };
                return true;
            }
        }
    }

    //Дефолтное состояние формы создания поста
    @action actionDefoult = () => {
        this.type = 'post';
        this.open = false;
        this.editorOpen = false;
        this.editorMedia = false;
        this.mediaLoading = false;
        this.mediaLoadingPercent = 0;
        this.mediaLoadingPercentData = [];
        this.accounts = [];
        this.text = '';
        this.media = [];
        this.mediaIdLoad = 0;
        this.maxMedia = 10;
        this.idLoad = false;
        this.category = 0;
        this.collapsed = false;
        this.timePro = false;
        this.textPro = false;
        this.commentOff = false;
        this.comment = false;
        this.commentText = '';
        this.textSavePost = '';
        this.geo = false;
        this.geoObject = {
            search: '',
            z: 0,
            load: false,
            array: [],
            id: false,
        };
        this.geoFull= false;
        this.geoObjectFull= [];
        this.name = '';
        this.id = false;
        this.code = false;
        this.delMediaID = false;
        this.urlStory = '';

        this.multyPost = [];
        this.IgtvImage = false;
        this.PrivievPost = true;
        this.ReStory = false;

        this.obrabotkaMediaFiles = false;

    }

    @action actionText = (id, value) => {
        this.accounts[id].text = value;

    }

    @action actionAccount = (id, time, text='', delite = false) => {

        if (delite === false) {
            delite = {
                status: false,
                int: 1,
                tip: 2,
            };
        }

        //если запушить в пустой массив, то изменения будут не видны
        if (this.accounts.slice().length === 0) {

            this.accounts = [{
                id: id,
                time: time,
                text: text,
                del: {
                    status: delite.status,
                    int: delite.int,
                    tip: delite.tip,
                },
            }];

        } else {

            //при работе с создание массовых постов/историй, нельзя создавать посты в несколько аккаунтов
            /*if (this.type === 'multyPost' || this.type === 'multyStory') {

                let del = false;
                for (let key in this.accounts.slice()) {
                    if (this.accounts[key].id === id) {
                        del = true;
                        break;
                    }
                }

                if (!del) {
                    this.accounts = [{
                        id: id,
                        time: time,
                        text: text,
                        del: {
                            status: delite.status,
                            int: delite.int,
                            tip: delite.tip,
                        },
                    }];
                }

                return true;
            }*/


            let del = false;
            for (let key in this.accounts.slice()) {
                if (this.accounts[key].id === id) {
                    this.accounts.splice(key, 1);
                    del = true;
                    break;
                }
            }

            if (!del) {
                this.accounts.push({
                    id: id,
                    time: time,
                    text: text,
                    del: {
                        status: delite.status,
                        int: delite.int,
                        tip: delite.tip,
                    },
                });
            }

        }
    }

    @action actionAccountUpdate = (id, update) => {

        for (let key in this.accounts.slice()) {

            if (this.accounts[key].id === id) {
                this.accounts[key] = {
                    ...this.accounts[key],
                    ...update,
                };
                break;
            }
        }
    }

    @action actionTimePro = (value) => {
        this.timePro = value;
    }

    @action actionTextPro = (value) => {
        this.textPro = value;
    }

    @action actionCommentOff = (value) => {
        this.commentOff = value;
    }

    @action actionComment = (value) => {
        this.comment = value;
    }

    @action actionCommentText = (value) => {
        this.commentText = value;
    }

    @action actionGeo = (valie) => {
        this.geo = valie;
    }

    @observable openMenuMobile = false;

    @action actionOpenMenuMobile = (value) => {
        this.openMenuMobile = value;
    }

}

export default mediaStore;
