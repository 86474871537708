import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import GeoSearh from "./GeoSearh";
import Checkbox from "../../../templates/elements/checkbox";


@inject('mediaStore', 'initialStore', 'accountsStore')
@observer
class GeoSearhFull extends React.Component {

    @observable focusInput = false;

    constructor (props) {
        super(props);
    }
    render() {
        const mediaStore = this.props.mediaStore;
        const initialStore = this.props.initialStore;
        const accountsStore = this.props.accountsStore;
        const accountsOK = mediaStore.accounts.slice();
        const accounts = accountsStore.list.slice();
        const text = initialStore.text.addPost;

        if (!mediaStore.geo) {
            return null;
        }

        let geoFull = false;
        //надо показывать или нет коментарий и гео
        const socSetEnter = {
            instagram: 0,
            vk: 0,
            fb: 0,
            ok: 0,
            tg: 0,
            twitter: 0,
            fb_instagram: 0,
        };

        let listAccoint = [];

        for (const accountsOKOne of accountsOK) {
            for (let keys in accounts) {
                if (accounts[keys].id === accountsOKOne.id) {
                    if (accounts[keys].tip === 'fb') {
                        socSetEnter.fb++;
                    } else if (accounts[keys].tip === 'tg') {
                        socSetEnter.tg++;
                    } else if (accounts[keys].tip === 'ok') {
                        socSetEnter.ok++;
                    } else if (accounts[keys].tip === 'vk') {
                        socSetEnter.vk++;
                    } else if (accounts[keys].tip === 'instagram') {

                        socSetEnter.instagram++;
                        listAccoint.push({
                            id: accounts[keys].id,
                            img: accounts[keys].img,
                            name: accounts[keys].name,
                        });

                    } else if (accounts[keys].tip === 'twitter') {
                        socSetEnter.twitter++;
                    } else if (accounts[keys].tip === 'fb-instagram') {
                        socSetEnter.fb_instagram++;
                    }
                }
            }
        }

        if (socSetEnter.instagram > 1) {
            geoFull = true;
        }

        if (!geoFull) {
            return <GeoSearh/>;
        }

        let elements = [];

        //проверяем на появление новых аккааунтов


        if (mediaStore.geoFull) {
            for (let listAccointElement of listAccoint) {
                elements.push(<>
                    <div className="column-group w-100 postProTextNameBox"><img width="33px" height="33px" src={listAccointElement.img}/> {listAccointElement.name}</div>
                    <GeoSearh accoint={listAccointElement.id}/>
                </>);
            }
        } else {
            elements = <GeoSearh/>;
        }

        return <>
            {elements}

            <div className="form-group mt-30">
                <div className="form-check-wrap">
                    <div className="form-check">
                        <div className="column-check">
                            <span className="title-check">{text[197]}</span>
                            <p className="desc-check">{text[198]}</p>
                        </div>
                        <Checkbox onChange={this.actionGeoFull}>{mediaStore.geoFull}</Checkbox>
                    </div>
                </div>
            </div>
        </>;


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const mediaStore = this.props.mediaStore;
        const initialStore = this.props.initialStore;
        const accountsStore = this.props.accountsStore;
        const accountsOK = mediaStore.accounts.slice();
        const accounts = accountsStore.list.slice();
        const text = initialStore.text.addPost;

        let geoFull = false;
        //надо показывать или нет коментарий и гео
        const socSetEnter = {
            instagram: 0,
            vk: 0,
            fb: 0,
            ok: 0,
            tg: 0,
            twitter: 0,
            fb_instagram: 0,
        };

        let listAccoint = [];

        for (const accountsOKOne of accountsOK) {
            for (let keys in accounts) {
                if (accounts[keys].id === accountsOKOne.id) {
                    if (accounts[keys].tip === 'fb') {
                        socSetEnter.fb++;
                    } else if (accounts[keys].tip === 'tg') {
                        socSetEnter.tg++;
                    } else if (accounts[keys].tip === 'ok') {
                        socSetEnter.ok++;
                    } else if (accounts[keys].tip === 'vk') {
                        socSetEnter.vk++;
                    } else if (accounts[keys].tip === 'instagram') {

                        socSetEnter.instagram++;
                        listAccoint.push({
                            id: accounts[keys].id,
                            img: accounts[keys].img,
                            name: accounts[keys].name,
                        });

                    } else if (accounts[keys].tip === 'twitter') {
                        socSetEnter.twitter++;
                    } else if (accounts[keys].tip === 'fb-instagram') {
                        socSetEnter.fb_instagram++;
                    }
                }
            }
        }

        if (socSetEnter.instagram > 1) {
            geoFull = true;
        }


        if (mediaStore.geoFull) {
            const geoObjectFull = mediaStore.geoObjectFull.slice();
            const geoObjectFullNew = [...mediaStore.geoObjectFull.slice()];

            //проверяем на появление новых аккааунтов
            let exitElement = false;
            for (let listAccointElement of listAccoint) {
                let searhAccount = false;

                for (let geoObjectFullElement of geoObjectFull) {
                    if (listAccointElement.id === geoObjectFullElement.idAccount) {
                        searhAccount = true;
                        break;
                    }
                    exitElement = {...geoObjectFullElement};
                }

                if (!searhAccount) {
                    geoObjectFullNew.push({
                        ...exitElement,
                        idAccount: listAccointElement.id,
                    })
                }

                if (!searhAccount) {
                    mediaStore.geoObjectFull = [...geoObjectFullNew];
                }
            }

            const geoObjectFullDel = mediaStore.geoObjectFull.slice();
            const geoObjectFullNewDel = [];

            //проверяем на удаленные аккаунты
            for (let geoObjectFullElement of geoObjectFullDel) {
                let searh = false;
                for (let listAccointElement of listAccoint) {

                    if (listAccointElement.id === geoObjectFullElement.idAccount) {
                        searh = true;
                        geoObjectFullNewDel.push({...geoObjectFullElement});
                        break;
                    }
                }

                if (searh === false) {
                    mediaStore.geoObjectFull = [...geoObjectFullNewDel];
                }
            }
        }



    }

    actionGeoFull = (result) => {
        const mediaStore = this.props.mediaStore;
        const accountsStore = this.props.accountsStore;
        const accountsOK = mediaStore.accounts.slice();
        const accounts = accountsStore.list.slice();


        let geoFull = false;
        //надо показывать или нет коментарий и гео
        const socSetEnter = {
            instagram: 0,
            vk: 0,
            fb: 0,
            ok: 0,
            tg: 0,
            twitter: 0,
            fb_instagram: 0,
        };

        let listAccoint = [];

        for (const accountsOKOne of accountsOK) {
            for (let keys in accounts) {
                if (accounts[keys].id === accountsOKOne.id) {
                    if (accounts[keys].tip === 'fb') {
                        socSetEnter.fb++;
                    } else if (accounts[keys].tip === 'tg') {
                        socSetEnter.tg++;
                    } else if (accounts[keys].tip === 'ok') {
                        socSetEnter.ok++;
                    } else if (accounts[keys].tip === 'vk') {
                        socSetEnter.vk++;
                    } else if (accounts[keys].tip === 'instagram') {

                        socSetEnter.instagram++;
                        listAccoint.push({
                            id: accounts[keys].id,
                            img: accounts[keys].img,
                            name: accounts[keys].name,
                        });

                    } else if (accounts[keys].tip === 'twitter') {
                        socSetEnter.twitter++;
                    } else if (accounts[keys].tip === 'fb-instagram') {
                        socSetEnter.fb_instagram++;
                    }
                }
            }
        }

        let newGeoObjectFull = [];
        if (result) {
            for (let listAccointElement of listAccoint) {
                newGeoObjectFull.push({
                    search: mediaStore.geoObject.search,
                    z: mediaStore.geoObject.z,
                    load: mediaStore.geoObject.load,
                    array: [...mediaStore.geoObject.array],
                    id: mediaStore.geoObject.id,
                    idAccount: listAccointElement.id,
                });
            }
        } else {
            mediaStore.geoObject = {
                search: mediaStore.geoObjectFull[0].search,
                z: mediaStore.geoObjectFull[0].z,
                load: mediaStore.geoObjectFull[0].load,
                array: [...mediaStore.geoObjectFull[0].array],
                id: mediaStore.geoObjectFull[0].id,
            }
        }

        mediaStore.geoObjectFull = newGeoObjectFull;
        mediaStore.geoFull = result;
    }

}
export default GeoSearhFull;
