import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ButtonLoad from "../../elements/buttonLoad";
import Input from "../../elements/input";
import Button from "../../elements/button";

export default
@inject('initialStore')
@observer
class TemplateModalAddAccountInstagramCode extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;

        let render = <>
            <span className="title-box center">{text[17]}</span>
            <div className="login">

                <p className="login-help">
                    {text[26]}
                </p>

                <div className="form-box">
                    <div className="form-group">
                        <span className="input-label">{text[27]}</span>
                        <Input load={this.props.load} onChange={this.props.actionCode}>{this.props.code}</Input>
                    </div>
                </div>
            </div>

            <div className="more-btn btns-box">
                <ButtonLoad color action={this.props.actionOK}>add</ButtonLoad>
                <Button load={this.props.load} action={this.props.close}>cancellation</Button>
            </div>
        </>;

        return render;
    }
}

