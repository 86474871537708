import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import style from "../../../component/menu/index.module.css";

export default
@inject('initialStore')
@observer
class MenuAccountMenuBox extends React.Component {

    constructor(props) {
        super(props);
        this.funs = this.funs.bind(this);
    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <div className="settings-user" ref={this.props.boxRef}>
                <span className="icon-settings" onClick={this.props.buttomSetupClick}><i className="icon static icon-user-settings"></i></span>
                {this.props.openSetup ?
                    <div className="event-menu g-right v-bottom show">
                        {this.props.elements}
                    </div>
                    :
                    null}
            </div>;
        } else {
            return <div className="settings-user" ref={this.props.boxRef}>
                <span className="icon-settings" onClick={this.props.buttomSetupClick}><i className="icon static icon-user-settings"></i></span>
                {this.props.openSetup ?
                    <div className="event-menu g-right v-bottom show">
                        {this.props.elements}
                    </div>
                    :
                    null}
            </div>;
        }

    }



    funs () {
        this.props.fun(this.props.url);
    }
}

