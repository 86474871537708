import React from 'react';
import {inject, observer} from "mobx-react";

@inject("menuStore")
@observer
class ButtonOpenMenuMobile extends React.Component {

    constructor (props) {
        super(props);
    }

    render() {

        const menuStore = this.props.menuStore;

        return (
            <div
                onClick={this.open}
                className="sidebar-burger"
            >
            </div>
        );
    }

    open = () => {

        const menuStore = this.props.menuStore;

        if (menuStore.openMenuMobile) {
            menuStore.actionOpenMenuMobile(false);
        } else {
            menuStore.actionOpenMenuMobile(true);
        }
    }

}

export default ButtonOpenMenuMobile;