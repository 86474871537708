import React from 'react';

import {inject, observer} from "mobx-react";

@inject('editorStore', 'notificationStore', 'initialStore')
@observer
class MediaRedBDelite extends React.Component {

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;
    }

    render() {
        return (
            <>
                <div className="item-type">
                    <button type="button" className="btn red-button-delite" onClick={this.del}></button>
                </div>
            </>
        );
    }

    del = () => {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        this.fabric.elementDelite();

        notificationStore.add(text[172], '', 3);
    }
}

export default MediaRedBDelite;