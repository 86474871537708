import React from 'react';
import {inject, observer} from "mobx-react";

import {Link} from "react-router-dom";

import {getTimetable} from "../../../actions/account/timetable";
import TimeBox from "../../../component/timetable/TimeBox";
import APItimetable from "../../../api/timetable";
import LoaderComponent from "../../../templates/loaderComponent";
import AccountTimetableIndex from "../../../templates/account/timetable";
import AccountTimeTableFilterElement from "../../../templates/account/timetable/filterElement";
import AccountTimeTableFilter from "../../../templates/account/timetable/filter";
import AccountTimeTableScheduleCol from "../../../templates/account/timetable/scheduleCol";
import AccountPatternsIndex from "../../../templates/account/patterns";

@inject('accountsStore', 'menuStore', 'initialStore')
@observer
class Timetable extends React.Component {


    constructor(props) {
        super(props);
        this.clickTypeRed = this.clickTypeRed.bind(this);
    }

    render() {

        const accountsStore = this.props.accountsStore;
        const accounts = accountsStore.active;
        const timetable = accountsStore.list[accounts].timetable.list;
        const timetable_story = accountsStore.list[accounts].timetable.list_story;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        let timetableName = '';
        let timetableStore = timetable;
        if (!this.props.match.params.type) {
        } else {
            timetableStore = timetable_story;
        }

        let timetableJSX = '';
        if (timetableStore === null) {
            timetableJSX = <LoaderComponent/>;
        } else {

            let box1 = [];
            let box2 = [];
            let box3 = [];
            let box4 = [];

            for (const element of timetableStore) {

                const box = <TimeBox
                        time={element.id}
                        type={element.type}
                        typeRed={this.clickTypeRed}
                    />;

                if (element.id >=0 && element.id <=5) {
                    box1.push(box);
                } else if (element.id >=6 && element.id <=11) {
                    box2.push(box);
                } else if (element.id >=12   && element.id <=17) {
                    box3.push(box);
                } else if (element.id >=18   && element.id <=23) {
                    box4.push(box);
                }
            }

            timetableJSX = <>
                <AccountTimeTableScheduleCol name={text[10]}>{box1}</AccountTimeTableScheduleCol>
                <AccountTimeTableScheduleCol name={text[11]}>{box2}</AccountTimeTableScheduleCol>
                <AccountTimeTableScheduleCol name={text[12]}>{box3}</AccountTimeTableScheduleCol>
                <AccountTimeTableScheduleCol name={text[13]}>{box4}</AccountTimeTableScheduleCol>
            </>;
        }

        let filterElements = [
            <AccountTimeTableFilterElement url={'/account/' + accountsStore.list[accounts].id + '/timetable'} active={!this.props.match.params.type} name={text[14]}/>,
            <AccountTimeTableFilterElement url={'/account/' + accountsStore.list[accounts].id + '/timetable/story'} active={this.props.match.params.type} name={text[15]}/>,
        ];

        let filter = <AccountTimeTableFilter>{filterElements}</AccountTimeTableFilter>;


        let content = <AccountTimetableIndex
            account={accountsStore.list[accountsStore.active].name}
            typeAccount={accountsStore.list[accountsStore.active].tip}
            imgAccount={accountsStore.list[accountsStore.active].img}
            filter={filter}
            >
            {timetableJSX}
        </AccountTimetableIndex>;

        return content;
    }

    clickTypeRed(id, type) {

        const accountsStore = this.props.accountsStore;
        const accounts = accountsStore.active;
        const timetable = accountsStore.list[accounts].timetable.list.slice();
        const timetable_story = accountsStore.list[accounts].timetable.list_story.slice();

        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        let timetableName = '';

        if (!this.props.match.params.type) {
            for (const key in timetable) {
                if (timetable[key].id === id) {
                    accountsStore.list[accounts].timetable.list[key].type = type;
                    APItimetable.setPatterns(accountsStore.list[accounts].id, 'post', id, type).then((data) => {
                        if (data.status) {

                        } else {
                            alert(text[116]);
                        }
                    }).catch((e)=> {
                        alert(text[116]);
                    });
                    return;
                }
            }
        } else {
            for (const key in timetable_story) {
                if (timetable_story[key].id === id) {
                    accountsStore.list[accounts].timetable.list_story[key].type = type;
                    APItimetable.setPatterns(accountsStore.list[accounts].id, 'story', id, type).then((data) => {
                        if (data.status) {

                        } else {
                            alert(text[116]);
                        }
                    }).catch((e)=> {
                        alert(text[116]);
                    });
                    return;
                }
            }
        }
    }

    componentDidMount() {
        this.props.menuStore.updateActiveComponentAccount('timetable');
        this.update();
    }

    componentDidUpdate() {
        this.update();
    }

    update() {

        const accountsStore = this.props.accountsStore;
        const accounts = accountsStore.active;

        const idAccount = accountsStore.list[accounts].id;
        const hesh = accountsStore.list[accounts].timetable.hesh;
        const load = accountsStore.list[accounts].timetable.load;

        if (!load) {
            getTimetable(idAccount, accounts, hesh);

        }
    }
}

export default Timetable;