import React from 'react';
import {inject, observer} from "mobx-react";

import file from "../../../model/file";
import editorMediaClass from "../../../model/eritorMedia";
import APIfile from "../../../api/file";
import mediaHelpers from "../../../model/mediaHelpers";
import loadFileObrabotchik from "../../../model/loadFileObrabotchik";


@inject('mediaStore', 'accountsStore', 'notificationStore', 'initialStore')
@observer
class LoadFile extends React.Component{

    constructor (props) {
        super(props);

        this.fileInput = React.createRef();
        this.clickFile = this.clickFile.bind(this);
        this.filOnChange = this.filOnChange.bind(this);
    }

    render() {

        const mediaStore = this.props.mediaStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        if (mediaStore.media.slice().length >= mediaStore.maxMedia) {
            return null;
        }

        let viev = null;

        //Идет обработка файлов или нет
        let animationNonLoad = false;

        let infoLoadFile = null;
        if (mediaStore.obrabotkaMediaFiles) {
            animationNonLoad = true;
            infoLoadFile = ' '+mediaStore.obrabotkaMediaFiles;
        }

        if (initialStore.templates === 'desktop') {
            viev = <>
                <button type="button" style={{width: '100%'}} className={!animationNonLoad ? "btn border btn-primary":"btn border btn-primary btn-primary-animate"} onClick={this.clickFile}>{text[66]}{infoLoadFile}</button>

                <input style={{display: 'none'}} type="file" multiple accept="image/*, video/*" ref={this.fileInput} onChange={this.filOnChange} />
            </>;

            if (mediaStore.type === 'igtv' || mediaStore.type === 'reels') {
                viev = <>
                    <button type="button" className={!animationNonLoad ? "btn border btn-primary btn-block":"btn border btn-primary btn-block btn-primary-animate"} onClick={this.clickFile}>{text[67]}{infoLoadFile}</button>

                    <input style={{display: 'none'}} type="file" accept="video/*" ref={this.fileInput} onChange={this.filOnChange} />
                </>;
            }
        } else {
            viev = <>
                <button type="button" style={{width: '100%'}} className={!animationNonLoad ? "btn border btn-primary":"btn border btn-primary btn-primary-animate"} onClick={this.clickFile}>{text[66]}{infoLoadFile}</button>
                {/*<button type="button" className="btn border btn-primary" onClick={() => {alert('Функция еще не доступна. При помощи данной функции Вы сможете создать простое изображения и накладывать на них разные элементы через редактор, а так же выбирать цвет фона')}}>Создать изображение</button>*/}

                <input className="no-fastclick" style={{display: 'none'}} type="file" multiple ref={this.fileInput} onChange={this.filOnChange} />
            </>;

            if (mediaStore.type === 'igtv' || mediaStore.type === 'reels') {
                viev = <>
                    <button type="button" className={!animationNonLoad ? "btn border btn-primary btn-block":"btn border btn-primary btn-block btn-primary-animate"} onClick={this.clickFile}>{text[67]}{infoLoadFile}</button>

                    <input className="no-fastclick" style={{display: 'none'}} type="file" accept="video/*" ref={this.fileInput} onChange={this.filOnChange} />
                </>;
            }
        }

        let render = <>
            {viev}
        </>;

        return render;
    }

    clickFile() {
        const mediaStore = this.props.mediaStore;
        const notificationStore = this.props.notificationStore;

        //Идет обработка файлов или нет
        let animationNonLoad = false;

        if (mediaStore.obrabotkaMediaFiles) {
            animationNonLoad = true;
        }

        if (animationNonLoad) {
            notificationStore.add('Ошибка', 'Не все файлы обработались, подождите пожалуйста.');
            return false;
        }
        //Удаляем прошлые файлы из формы
        this.fileInput.current.value = null;
        //открываем форму
        this.fileInput.current.click();
    }

    //передаем файлы в обработчик после выбора
    filOnChange() {
        loadFileObrabotchik(this.fileInput.current.files)
    }
}
export default LoadFile;
