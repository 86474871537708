import React from 'react';
import {observer, inject} from "mobx-react";
import HelpIndex from "../../templates/help";
import HelpElementCategory from "../../templates/help/elementCategory";
import HelpElementTextBox from "../../templates/help/elementTextBox";
import HelpElementText from "../../templates/help/elementText";
import HelpElementTem from "../../templates/help/elementTem";
import {Route, Switch} from "react-router-dom";
import Security from "../settings/security";
import Notifications from "../settings/notifications";
import Groups from "../settings/groups";
import Referrals from "../settings/referrals";
import Main from "../settings/main";

import Faq from "./faq";
import Chat from "./chat";

@inject("helpStore")
@observer
class Help extends React.Component {

    constructor (props) {
        super(props);
    }

    render() {

        return <Switch>
            <Route path="/help/faq/:id/:id2" component={Faq}></Route>
            <Route path="/help/faq" component={Faq}></Route>
            <Route path="/help" component={Chat}></Route>

        </Switch>;
    }

}

export default Help;