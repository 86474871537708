import qs from "qs";
import React from "react";

import instance from "./base";

const APIsettings = {

    setTimezone (timezone) {
        return instance.post('settings/time', qs.stringify({
            time: timezone,
        })).then(data => {
            return data;
        });
    },
    setLanguage (language) {
        return instance.post('settings/language', qs.stringify({
            language: language,
        })).then(data => {
            return data;
        });
    },

    setSave (time, language, phone, phoneCode, name) {
        return instance.post('settings/save', qs.stringify({
            time: time,
            language: language,
            phone: phone,
            phoneCode: phoneCode,
            name: name,
        })).then(data => {
            return data;
        });
    },

    setTelegram () {
        return instance.post('settings/telegram', qs.stringify({

        })).then(data => {
            return data;
        });
    },

    setTelegramDelite () {
        return instance.post('settings/telegram_delite', qs.stringify({

        })).then(data => {
            return data;
        });
    },

    setSecurity (password, newPassword, newPassword2) {
        return instance.post('settings/security', qs.stringify({
            password: password,
            newPassword: newPassword,
            newPassword2: newPassword2,
        })).then(data => {
            return data;
        });
    },
    groupsAdd (name) {
        return instance.post('settings/groups_add', qs.stringify({
            name: name,
        })).then(data => {
            return data;
        });
    },
    groupsDel (id) {
        return instance.post('settings/groups_del', qs.stringify({
            id: id,
        })).then(data => {
            return data;
        });
    },
    groupsRed (id, name) {
        return instance.post('settings/groups_red', qs.stringify({
            id: id,
            name: name,
        })).then(data => {
            return data;
        });
    },
    groupsOrder (groups) {

        var jsons = JSON.stringify(groups);

        let formData = new FormData();
        formData.append('groups', jsons);

        return instance.post('settings/groups_order',
            formData
        ).then((data) => {
            return data;
        });
    },
    groupsAccountsOrder (accounts) {

        var jsons = JSON.stringify(accounts);

        let formData = new FormData();
        formData.append('accounts', jsons);

        return instance.post('settings/groups_accounts_order',
            formData
        ).then((data) => {
            return data;
        });
    },
    photoLoad(file) {
        let formData = new FormData();
        formData.append('photo', file);

        return instance.post( 'settings/photo',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }
        );
    },
    getNotifications () {
        return instance.post('settings/notifications', qs.stringify({

        })).then(data => {
            return data;
        });
    },
    setNotifications (data, dataOnline) {
        const jsons = JSON.stringify(data);
        const jsonsOnline = JSON.stringify(dataOnline);

        let formData = new FormData();
        formData.append('data', jsons);
        formData.append('dataOnline', jsonsOnline);

        return instance.post('settings/notifications_save',
            formData
        ).then((data) => {
            return data;
        });
    },
    getReferrals () {
        return instance.post('settings/referrals', qs.stringify({

        })).then(data => {
            return data;
        });
    },
    setTypeList (account, type) {
        return instance.post('settings/type_list', qs.stringify({
            account: account,
            type: type,
        })).then(data => {
            return data;
        });
    },
}

export default APIsettings;