import React from 'react';
import Draggable from "react-draggable";

import stile from './mediaSizeScroll.module.css';
import mediaHelpers from "../../../model/mediaHelpers";
import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import Checkbox from "../../../templates/elements/checkbox";

@inject('editorStore', 'initialStore')
@observer
class MediaStill extends React.Component {

    constructor (props) {
        super(props);


    }

    render() {

        const editorStore = this.props.editorStore;
        const initialStore = this.props.initialStore;

        const text = initialStore.text.addPost;
        //const color = editorStore.file.color;

        let soundDelite = editorStore.file.soundDelite;
        let component = <div className="group-publication group-publication-still">
            <div className="add-publication__comments">
                <div className="form-group">
                    <div className="form-check-wrap">
                        <div className="form-check">
                            <div className="column-check">
                                <span className="title-check">{text[212]}</span>
                                <p className="desc-check">{text[213]}</p>
                            </div>
                            <Checkbox onChange={this.OnAction}>{soundDelite}</Checkbox>
                        </div>

                    </div>
                </div>
            </div>
        </div>;

        return component;
    }


    OnAction = (status) => {
        const editorStore = this.props.editorStore;
        editorStore.file.soundDelite = status;
    }

}
export default MediaStill;
