import React from 'react';
import {inject, observer} from "mobx-react";

import {observable} from "mobx";
import Checkbox from "../../../templates/elements/checkbox";

import loadFileObrabotchikImageIgtv from "../../../model/loadFileObrabotchikImageIgtv";

@inject('mediaStore', 'accountsStore', 'notificationStore', 'initialStore')
@observer
class ReStory extends React.Component {

    @observable status = false;

    @observable style = null;

    constructor (props) {
        super(props);

        const mediaStore = this.props.mediaStore;

        this.status = mediaStore.ReStory;
    }

    render() {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        const accounts = accountsStore.list.slice();

        const accountsOK = mediaStore.accounts.slice();

        const socSetEnter = {
            instagram: 0,
            vk: 0,
            fb: 0,
            ok: 0,
            tg: 0,
            twitter: 0,
            fb_instagram: 0,
        };

        for (const accountsOKOne of accountsOK) {
            for (let keys in accounts) {
                if (accounts[keys].id === accountsOKOne.id) {
                    if (accounts[keys].tip === 'fb') {
                        socSetEnter.fb++;
                    } else if (accounts[keys].tip === 'tg') {
                        socSetEnter.tg++;
                    } else if (accounts[keys].tip === 'ok') {
                        socSetEnter.ok++;
                    } else if (accounts[keys].tip === 'vk') {
                        socSetEnter.vk++;
                    } else if (accounts[keys].tip === 'instagram') {
                        socSetEnter.instagram++;
                    } else if (accounts[keys].tip === 'twitter') {
                        socSetEnter.twitter++;
                    } else if (accounts[keys].tip === 'fb-instagram') {
                        socSetEnter.fb_instagram++;
                    }
                }
            }
        }
        let viewComentAndGeo = false;

        if (socSetEnter.instagram) {
            if (socSetEnter.vk || socSetEnter.fb || socSetEnter.ok || socSetEnter.tg || socSetEnter.twitter || socSetEnter.fb_instagram) {
                viewComentAndGeo = 2;
            } else {
                viewComentAndGeo = 1;
            }
        } else {
            if (!socSetEnter.instagram && !socSetEnter.vk && !socSetEnter.fb && !socSetEnter.ok && !socSetEnter.tg && !socSetEnter.twitter && !socSetEnter.fb_instagram) {
                viewComentAndGeo = 1;
            }
        }

        for (let key in accountsOK) {

            let idDB = 0;
            for (let keys in accounts) {
                if (accountsOK[key].id === accounts[keys].id) {
                    idDB = keys;
                }
            }
        }

        if (mediaStore.type !== 'post' && mediaStore.type !== 'edit') {
            return null;
        }

        if (!viewComentAndGeo) {
            return null;
        }

        let info = text[208];

        if (mediaStore.type === 'igtv' || mediaStore.type === 'editIgtv') {
            info = text[209];
        }
        if (mediaStore.type === 'reels' || mediaStore.type === 'editReels') {
            info = text[210];
        }

        let render = (
            <div className="group-publication">
                <div className="add-publication__comments">
                    <div className="form-group">
                        <div className="form-check-wrap">
                            <div className="form-check">
                                <div className="column-check">
                                    <span className="title-check">{text[207]}{viewComentAndGeo === 2 ? <span className="info-dos-soc-set"> {text[40]}</span>:null}</span>
                                    <p className="desc-check">{info}</p>
                                </div>
                                <Checkbox onChange={this.OnAction}>{this.status}</Checkbox>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );

        return render;
    }

    componentDidUpdate() {
        const mediaStore = this.props.mediaStore;

        this.status = mediaStore.ReStory;
    }

    OnAction = (value) => {
        const mediaStore = this.props.mediaStore;

        this.status = value;

        mediaStore.ReStory = value;
    }
}

export default ReStory;
