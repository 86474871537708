import React from 'react';

import MediaSizeScroll from "./MediaSizeScroll";
import MediaWindow from "./MediaWindow";
import {observable} from "mobx";
import {inject, observer} from "mobx-react";

import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import MediaColor from "./MediaColor";
import MediaStill from "./MediaStill";

@inject('editorStore', 'mediaStore', 'accountsStore', 'initialStore')
@observer
class MediaSize extends React.Component {

    @observable type = 'size';

    constructor (props) {
        super(props);

        this.redType = this.redType.bind(this);
        this.typeBil = '';

        this.video = React.createRef();
    }

    render() {

        const mediaStore = this.props.mediaStore;
        const editorStore = this.props.editorStore;
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;

        const text = initialStore.text.addPost;

        const type = this.type;

        let width = 400;
        if (initialStore.width < width) {
            width = initialStore.width;
        }

        if (editorStore.file.proportions < 1) {
            width = width * editorStore.file.proportions;
        }

        const MediaWindowProps = {
            backgroud: this.props.backgroud,
            stateObertka: this.props.stateObertka,
            content: this.props.content,
            draggable: this.props.draggable,
            getDraggableBounds: this.props.getDraggableBounds,
            handleDrag: this.props.handleDrag,
            type: this.props.type,
            url: this.props.url,
            onMouseDownBox: this.props.onMouseDownBox,
            boxRef: this.props.boxRef,
            width: width,
        };

        const MediaSizeScrollProps = {
            zoom:this.props.objectZoom,
            zoomInt: this.props.objectZoomInt,
            backgroundSize: this.props.objectBackgroundSize,
            backgroundSizeInt: this.props.objectBackgroundSizeInt,
            objectBackgroundSizeStatus: this.props.objectBackgroundSizeStatus,
            typePost: this.props.typePost,
            realProportionMedia: this.props.editorStore.file.crop.proportions,
            zoomFullSkreen: this.props.fullSkreen,
        };

        let boxCrop = '';
        if (type === 'crop') {
            if (this.props.type === 'image') {

                boxCrop = (
                    <div className="editor-top-crop" style={{width: width}}>
                        <ReactCrop
                            src={this.props.url}
                            keepSelection
                            crop={this.props.crop}
                            onChange={(newCrop, newCrop2) => this.props.setCrop(newCrop, newCrop2)}
                            ruleOfThirds/>
                    </div>
                );
            } else {
                const videoComponent = <video
                    style={{ display: 'block', maxWidth: '100%'}}
                    src={this.props.url}
                    ref={el=>this.video=el}
                    preload="auto"
                    autoPlay
                    playsinline="true"
                    loop
                    muted
                />;
                boxCrop = (
                    <div className="editor-top-crop" style={{width: width}}>
                        <ReactCrop
                            keepSelection
                            crop={this.props.crop}
                            onChange={(newCrop, newCrop2) => this.props.setCrop(newCrop, newCrop2)}
                            renderComponent={videoComponent}
                            ruleOfThirds/>
                    </div>
                );
            }
        }



        //доуступно редактирование соотношение
        let aspectStatus = false;
        if (this.props.typePost === 'post' || this.props.typePost === 'edit') {

            if (this.props.idFile) {
                const accounts = accountsStore.list.slice();
                const accountsOK = mediaStore.accounts.slice();

                let proportions = false;

                for (let key in accountsOK) {

                    for (let keys in accounts) {
                        if (accountsOK[key].id === accounts[keys].id) {
                            if (accounts[keys].tip === 'instagram') {
                                proportions = true;
                                break;
                            }
                        }
                    }
                }

                if (!proportions) {
                    aspectStatus = true;
                }

            } else {
                aspectStatus = true;
            }
        } else if (this.props.typePost === 'igtv' || this.props.typePost === 'igtvEdit') {
            aspectStatus = true;
        }

        if (this.props.typePost === 'multyPost') {
            aspectStatus = true;
        }



        const component = <>
            <div className="editor-top">
                {type !== 'crop' ?
                    <MediaWindow play {...MediaWindowProps} />
                    :
                    boxCrop
                }
            </div>

            <div className="editor-cnt">
                <div className="editor-media editor-media-new">
                    <div className="editor-media__type editor-media__type-new">
                        <button type="button" className={type === 'size' ? "btn btn-primary": "btn border btn-primary"} onClick={() => this.redType('size')}>{text[93]}</button>

                        {aspectStatus ?
                            <button type="button" className={type === 'aspect' ? "btn btn-primary": "btn border btn-primary"} onClick={() => this.redType('aspect')}>{text[94]}</button>
                            :
                            null
                        }

                        <button type="button" className={type === 'color' ? "btn btn-primary": "btn border btn-primary"} onClick={() => this.redType('color')}>{text[95]}</button>
                        <button type="button" className={type === 'crop' ? "btn btn-primary": "btn border btn-primary"} onClick={() => this.redType('crop')}>{text[96]}</button>
                        {editorStore.file.type === 'video' ?
                        <button type="button" className={type === 'still' ? "btn btn-primary btn-primary-still": "btn border btn-primary btn-primary-still"} onClick={() => this.redType('still')}>{text[211]}</button>
                            :null}
                    </div>

                    {type === 'size' || type === 'aspect' ?
                        <MediaSizeScroll {...MediaSizeScrollProps} type={type}/>
                        :null
                    }

                    {type === 'color' ?
                        <MediaColor />
                        :null
                    }

                    {type === 'still' ?
                        <MediaStill/>
                        :null
                    }

                </div>

            </div>
        </>;

        return component;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const type = this.type;

        if (type === 'crop' && this.typeBil !== 'crop') {

            //!!!лечим проблему с видео!!!
            if (this.props.type === 'video') {
                this.props.setCrop({}, {
                    ...this.props.crop,
                });
            }
            //!!!лечим проблему с видео!!!
        }

        this.typeBil = type;

        if (type === 'crop') {
            if (this.props.type !== 'image') {
                const videoElement = this.video;
                videoElement.play();
            }
        }

    }

    redType(type) {
        this.type = type;
    }

}

export default MediaSize;
