import React from 'react';

import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import MediaRedBOpasity from "./button/MediaRedBOpasity";
import MediaRedBColorFon from "./button/MediaRedBColorFon";
import MediaRedBDelite from "./button/MediaRedBDelite";
import MediaRedBNext from "./button/MediaRedBNext";
import MediaRedBText from "./button/MediaRedBText";
import MediaRedBColorTextBackground from "./button/MediaRedBColorTextBackground";
import MediaRedBAlign from "./button/MediaRedBAlign";
import MediaRedBTextBold from "./button/MediaRedBTextBold";
import MediaRedBTextItalic from "./button/MediaRedBTextItalic";
import MediaRedBTextFont from "./button/MediaRedBTextFont";
import MediaRedBElementTemplate from "./button/MediaRedBElementTemplate";
import MediaRedBGeoSearh from "./button/MediaRedBGeoSearh";
import MediaRedBUserSearh from "./button/MediaRedBUserSearh";
import MediaRedBHeshtagSearh from "./button/MediaRedBHeshtagSearh";
import MediaRedBColorQuestionBackground from "./button/MediaRedBColorQuestionBackground";
import MediaRedBQuestion from "./button/MediaRedBQuestion";
import MediaRedBColorSlayderBackground from "./button/MediaRedBColorSlayderBackground";
import MediaRedBSlayderEmoje from "./button/MediaRedBSlayderEmoje";
import MediaRedBSlider from "./button/MediaRedBSlider";
import MediaRedBPollText from "./button/MediaRedBPollText";
import MediaRedBPollTextLR from "./button/MediaRedBPollTextLR";
import MediaRedBQuizName from "./button/MediaRedBQuizName";
import MediaRedBQuizTexts from "./button/MediaRedBQuizTexts";
import MediaRedBQuizTemplate from "./button/MediaRedBQuizTemplate";
import MediaRedBUrlStr from "./button/MediaRedBUrlStr";

@inject('editorStore', 'initialStore')
@observer
class MediaRedButton extends React.Component {

    constructor (props) {
        super(props);
    }

    render() {

        const editorStore = this.props.editorStore;
        const initialStore = this.props.initialStore;

        const text = initialStore.text.addPost;

        let component = null;

        let typeElement = this.props.openElement ? this.props.openElement.type : false;

        if (!typeElement) {
            //стартовая кнопка для показа кнопки создания новых элементов
            component = (
                <div className="editor-redactor__add">
                    <div className="btn click-add-elements-red" onClick={this.props.openListElements}>
                        <i className="icon icon-editor-add icon-abs"></i> {text[136]}
                    </div>
                </div>
            );
        } else if (typeElement === 'rect') {
            //квадрат
            component = (
                <div className="editor-redactor__type">
                    <MediaRedBNext fabric={this.props.fabric} />

                    <MediaRedBOpasity fabric={this.props.fabric} />

                    <MediaRedBColorFon fabric={this.props.fabric} />

                    <MediaRedBDelite fabric={this.props.fabric} />
                </div>
            );
        } else if (typeElement === 'triangle') {
            //треугольник
            component = (
                <div className="editor-redactor__type">
                    <MediaRedBNext fabric={this.props.fabric} />

                    <MediaRedBOpasity fabric={this.props.fabric} />

                    <MediaRedBColorFon fabric={this.props.fabric} />

                    <MediaRedBDelite fabric={this.props.fabric} />
                </div>
            );
        } else if (typeElement === 'circle') {
            //круг
            component = (
                <div className="editor-redactor__type">
                    <MediaRedBNext fabric={this.props.fabric} />

                    <MediaRedBOpasity fabric={this.props.fabric} />

                    <MediaRedBColorFon fabric={this.props.fabric} />

                    <MediaRedBDelite fabric={this.props.fabric} />
                </div>
            );
        } else if (typeElement === 'iText') {
            //Текст
            component = (
                <>
                    <div className="editor-redactor__type">
                        <MediaRedBNext fabric={this.props.fabric} />

                        <MediaRedBAlign fabric={this.props.fabric} />

                        <MediaRedBTextBold fabric={this.props.fabric} />

                        <MediaRedBTextItalic fabric={this.props.fabric} />

                        <MediaRedBOpasity fabric={this.props.fabric} />

                        <MediaRedBColorFon fabric={this.props.fabric} text={true} />

                        <MediaRedBColorTextBackground fabric={this.props.fabric} />

                        <MediaRedBTextFont fabric={this.props.fabric} />

                        <MediaRedBDelite fabric={this.props.fabric} />
                    </div>
                    <div className="editor-redactor__text">
                        <MediaRedBText fabric={this.props.fabric}/>
                    </div>
                </>
            );
        } else if (typeElement === 'geo') {
            //ГЕО ТОЧКА
            component = (
                <>
                    <div className="editor-redactor__type">
                        <MediaRedBNext fabric={this.props.fabric} />

                        <MediaRedBOpasity fabric={this.props.fabric} />

                        <MediaRedBElementTemplate fabric={this.props.fabric} />

                        <MediaRedBDelite fabric={this.props.fabric} />
                    </div>

                    <div className="editor-redactor__geo_name">
                        <MediaRedBGeoSearh fabric={this.props.fabric} />
                    </div>
                </>
            );
        } else if (typeElement === 'hashtag') {
            //Хэштег
            component = (
                <>
                    <div className="editor-redactor__type">
                        <MediaRedBNext fabric={this.props.fabric} />

                        <MediaRedBOpasity fabric={this.props.fabric} />

                        <MediaRedBElementTemplate fabric={this.props.fabric} />

                        <MediaRedBDelite fabric={this.props.fabric} />
                    </div>

                    <div className="editor-redactor__geo_name">
                        <MediaRedBHeshtagSearh fabric={this.props.fabric} />
                    </div>
                </>
            );
        } else if (typeElement === 'user') {
            //Упоминание
            component = (
                <>
                    <div className="editor-redactor__type">
                        <MediaRedBNext fabric={this.props.fabric} />

                        <MediaRedBOpasity fabric={this.props.fabric} />

                        <MediaRedBElementTemplate fabric={this.props.fabric} />

                        <MediaRedBDelite fabric={this.props.fabric} />
                    </div>

                    <div className="editor-redactor__geo_name">
                        <MediaRedBUserSearh fabric={this.props.fabric} />
                    </div>
                </>
            );
        } else if (typeElement === 'questions') {
            //Вопросы
            component = (
                <>
                    <div className="editor-redactor__type">
                        <MediaRedBNext fabric={this.props.fabric} />

                        <MediaRedBColorQuestionBackground fabric={this.props.fabric} />

                        <MediaRedBDelite fabric={this.props.fabric} />
                    </div>

                    <div className="editor-redactor__value_name">
                        <MediaRedBQuestion fabric={this.props.fabric} />
                    </div>
                </>
            );
        } else if (typeElement === 'slayder') {
            //Слайдер
            component = (
                <>
                    <div className="editor-redactor__type">
                        <MediaRedBNext fabric={this.props.fabric} />

                        <MediaRedBColorSlayderBackground fabric={this.props.fabric} />

                        <MediaRedBSlayderEmoje fabric={this.props.fabric} />

                        <MediaRedBDelite fabric={this.props.fabric} />
                    </div>

                    <div className="editor-redactor__value_name">
                        <MediaRedBSlider fabric={this.props.fabric} />
                    </div>
                </>
            );
        } else if (typeElement === 'poll') {
            //Опрос
            component = (
                <>
                    <div className="editor-redactor__type">
                        <MediaRedBNext fabric={this.props.fabric} />
                        <MediaRedBDelite fabric={this.props.fabric} />
                    </div>

                    <div className="editor-redactor__value_name">
                        <MediaRedBPollText fabric={this.props.fabric} />
                        <MediaRedBPollTextLR fabric={this.props.fabric} />
                    </div>
                </>
            );
        } else if (typeElement === 'quiz') {
            component = (
                <>
                    <div className="editor-redactor__type">
                        <MediaRedBNext fabric={this.props.fabric} />
                        <MediaRedBQuizTemplate fabric={this.props.fabric} />
                        <MediaRedBDelite fabric={this.props.fabric} />
                    </div>

                    <div className="editor-redactor__value_name">
                        <MediaRedBQuizName fabric={this.props.fabric} />
                        <MediaRedBQuizTexts fabric={this.props.fabric} />
                    </div>
                </>
            );
        } else if (typeElement === 'image') {
            component = (
                <>
                    <div className="editor-redactor__type">
                        <MediaRedBNext fabric={this.props.fabric} />

                        <MediaRedBOpasity fabric={this.props.fabric} />

                        <MediaRedBDelite fabric={this.props.fabric} />
                    </div>
                </>
            );
        } else if (typeElement === 'gif') {
            component = (
                <>
                    <div className="editor-redactor__type">
                        <MediaRedBNext fabric={this.props.fabric} />

                        <MediaRedBOpasity fabric={this.props.fabric} />

                        <MediaRedBDelite fabric={this.props.fabric} />
                    </div>
                </>
            );
        } else if (typeElement === 'url') {
            //Хэштег
            component = (
                <>
                    <div className="editor-redactor__type">
                        <MediaRedBNext fabric={this.props.fabric} />

                        <MediaRedBOpasity fabric={this.props.fabric} />

                        <MediaRedBElementTemplate fabric={this.props.fabric} type={'url'} />

                        <MediaRedBDelite fabric={this.props.fabric} />
                    </div>

                    <MediaRedBUrlStr fabric={this.props.fabric} />
                </>
            );
        } else {
            alert('element - ' + typeElement);
        }


        return (
            <div className="editor-cnt">
                <div className="editor-redactor">
                    {component}
                </div>
            </div>
        );
    }

}

export default MediaRedButton;
