import React from 'react';
import {inject, observer} from "mobx-react";

import Input from "../elements/input";
import ReactDOM from "react-dom";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {formatDate, parseDate} from "react-day-picker/moment";
import Select from "../elements/select";
import Time from "../../model/time";
import DateMobile from "../elements/dateMobile";

export default
@inject('initialStore', 'mediaStore')
@observer
class PostTimeBox extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        if (initialStore.templates === 'desktop') {
            return(
                <div className="group-settings">
                    <div className="column-group w-40">
                        <span className="input-label date-and-time-fix-hight">{text[86]}</span>
                        <div className="filter-date">
                            <div className="filter-date__item is-active DayPickerInput-Overlay-top-box">
                                <DayPickerInput
                                    inputProps={{ readOnly: true, className: 'form-control start form-control-date form-control-date-44'}}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    format="D MMMM YYYY"
                                    placeholder={text[87]}
                                    tef={this.ref}
                                    dayPickerProps={{
                                        locale: initialStore.language,
                                        localeUtils: MomentLocaleUtils,
                                        disabledDays:(day) => {

                                            const result = Time.timeBoxMinTime(day);

                                            if (!result) {
                                                return true;
                                            }

                                            const resultFinish = Time.timeBoxMaxTime(day, this.props.minTime);

                                            if (!resultFinish) {
                                                return true;
                                            }
                                        },
                                        selectedDay: this.props.selectedDay,
                                        ref: this.props.ref,
                                    }}
                                    onDayChange={this.props.onDayChange}
                                    value={this.props.date}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="column-group w-60">
                        <div className="group-settings">
                            <div className={this.props.arhiveStatus || this.props.deliteStatus ? "column-group w-70":"column-group w-100"}>
                                <span className="input-label date-and-time-fix-hight">{text[88]}</span>
                                <div className="time-settings">

                                    <Select
                                        top
                                        nonTopValueElement
                                        viewElementsList={5}
                                        action={this.props.hAction}
                                        options={this.props.hOptions}>{this.props.hValue}</Select>


                                    <Select
                                        top
                                        nonTopValueElement
                                        viewElementsList={5}
                                        action={this.props.mAction}
                                        options={this.props.mOptions}>{this.props.mValue}</Select>

                                </div>
                            </div>
                            {this.props.arhiveStatus || this.props.deliteStatus ?
                            <div className="column-group w-30">
                                <div className="events-settings">
                                    {this.props.arhiveStatus ?
                                        <button style={this.props.deliteStatus ? null: {width: '100%'}} type="button" className={this.props.delStatus === 2 ? "btn is-active": "btn btn-style-border"} onClick={this.props.delAction}>
                                            <svg width="16px" height="15px" viewBox="0 0 16 15">
                                                <g id="Desctop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g id="2_0_2" transform="translate(-861.000000, -2029.000000)">
                                                        <g transform="translate(389.000000, 1921.000000)">
                                                            <g id="Group-3" transform="translate(456.000000, 91.000000)">
                                                                <g id="box" transform="translate(16.000000, 17.000000)" fill={this.props.delStatus === 2 ? "#FFFFFF": "#7B5C9B"} fill-rule="nonzero">
                                                                    <path d="M1.6,3.2 L14.4,3.2 L14.4,13.2 C14.4,13.8627417 13.8627417,14.4 13.2,14.4 L2.8,14.4 C2.1372583,14.4 1.6,13.8627417 1.6,13.2 L1.6,3.2 Z M6.4,5.6 C5.9581722,5.6 5.6,5.95817224 5.6,6.4 C5.6,6.84182776 5.9581722,7.2 6.4,7.2 L9.6,7.2 C10.0418278,7.2 10.4,6.84182776 10.4,6.4 C10.4,5.95817224 10.0418278,5.6 9.6,5.6 L6.4,5.6 Z" id="Combined-Shape"></path>
                                                                    <rect id="Rectangle-143" x="0" y="0" width="16" height="3.2" rx="1"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    :null}
                                    {this.props.deliteStatus ?
                                        <button style={this.props.arhiveStatus ? null: {width: '100%'}} type="button" className={this.props.delStatus === 1 ? "btn is-active": "btn btn-style-border"} onClick={this.props.arhiveAction}>
                                            <svg width="13px" height="16px" viewBox="0 0 13 16">
                                                <g id="Desctop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g id="2_0_2" transform="translate(-919.000000, -2028.000000)">
                                                        <g transform="translate(389.000000, 1921.000000)">
                                                            <g id="Group-3-Copy" transform="translate(512.000000, 91.000000)">
                                                                <rect id="Rectangle" x="0" y="0" width="48" height="48" rx="6"></rect>
                                                                <g id="trash" transform="translate(18.000000, 16.000000)" fill={this.props.delStatus === 1 ? "#FFFFFF": "#7B5C9B"} fill-rule="nonzero">
                                                                    <path d="M0.912280702,3.98463168 L0.912280702,14.6315789 C0.912280702,15.387337 1.52494368,16 2.28070175,16 L10.4912281,16 C11.2469861,16 11.8596491,15.387337 11.8596491,14.6315789 L11.8596491,3.98463168 L0.912280702,3.98463168 Z" id="round"></path>
                                                                    <path d="M8.21052632,1.36842105 L8.21052632,0.912280702 C8.21052632,0.408441982 7.80208429,0 7.29824561,0 L5.47368421,0 C4.96984554,0 4.56140351,0.408441982 4.56140351,0.912280702 L4.56140351,1.36842105 L0.456140351,1.36842105 C0.204220996,1.36842105 0,1.57264205 0,1.8245614 L0,2.28070175 C0,2.53262111 0.204220996,2.73684211 0.456140351,2.73684211 L12.3157895,2.73684211 C12.5677089,2.73684211 12.7719298,2.53262111 12.7719298,2.28070175 L12.7719298,1.8245614 C12.7719298,1.57264205 12.5677089,1.36842105 12.3157895,1.36842105 L8.21052632,1.36842105 Z" id="Shape"></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    :null}

                                </div>
                            </div>
                            :null}
                        </div>
                    </div>
                    {this.props.delStatus ?
                        <div className="column-group w-100">
                            <div className="remove-settings element-time-right">
                                <span className="input-label">{this.props.delStatus === 1 ? text[89] : text[90]}</span>

                                <Input style={{width: '100px'}} onChange={this.props.delInputAction}>{this.props.delInputValue}</Input>

                                <Select
                                    top
                                    nonTopValueElement
                                    action={this.props.delSelectAction}
                                    options={[
                                        {key: 0, value: text[91][0]},
                                        {key: 1, value: text[91][1]},
                                        {key: 2, value: text[91][2]},
                                        {key: 3, value: text[91][3]},
                                    ]}>{this.props.delSelectValue}</Select>

                            </div>
                        </div>
                        :null}
                </div>
            );
        } else {

            return(
                <div className="group-settings">
                    <div className="column-group w-100">
                        <span className="input-label date-and-time-fix-hight date-and-time-fix-hight-mobile">{text[86]}</span>
                        <div className="filter-date">
                            <div className="filter-date__item is-active DayPickerInput-Overlay-top-box">
                                <DateMobile
                                    placeholder={text[87]}
                                    className={'form-control start form-control-date'}
                                    onDayClick={this.props.onDayChange}
                                    selectedDays={this.props.selectedDay}
                                    disabledDays={(day) => {

                                        const result = Time.timeBoxMinTime(day);

                                        if (!result) {
                                            return true;
                                        }

                                        const resultFinish = Time.timeBoxMaxTime(day, this.props.minTime);

                                        if (!resultFinish) {
                                            return true;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="column-group w-100">
                        <div className="group-settings">
                            <div className={this.props.arhiveStatus || this.props.deliteStatus ? "column-group w-60":"column-group w-100"}>
                                <span className="input-label date-and-time-fix-hight">{text[88]}</span>
                                <div className="time-settings">

                                    <Select
                                        top
                                        nonTopValueElement
                                        viewElementsList={10}
                                        action={this.props.hAction}
                                        options={this.props.hOptions}>{this.props.hValue}</Select>


                                    <Select
                                        top
                                        nonTopValueElement
                                        viewElementsList={10}
                                        action={this.props.mAction}
                                        options={this.props.mOptions}>{this.props.mValue}</Select>

                                </div>
                            </div>
                            {this.props.arhiveStatus || this.props.deliteStatus ?
                            <div className="column-group w-40">
                                <div className="events-settings" style={{marginTop: '27px'}}>
                                    {this.props.arhiveStatus ?
                                        <button type="button" className={this.props.delStatus === 2 ? "btn is-active": "btn btn-style-border"} onClick={this.props.delAction}>
                                            <svg width="16px" height="15px" viewBox="0 0 16 15">
                                                <g id="Desctop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g id="2_0_2" transform="translate(-861.000000, -2029.000000)">
                                                        <g transform="translate(389.000000, 1921.000000)">
                                                            <g id="Group-3" transform="translate(456.000000, 91.000000)">
                                                                <g id="box" transform="translate(16.000000, 17.000000)" fill={this.props.delStatus === 2 ? "#FFFFFF": "#7B5C9B"} fill-rule="nonzero">
                                                                    <path d="M1.6,3.2 L14.4,3.2 L14.4,13.2 C14.4,13.8627417 13.8627417,14.4 13.2,14.4 L2.8,14.4 C2.1372583,14.4 1.6,13.8627417 1.6,13.2 L1.6,3.2 Z M6.4,5.6 C5.9581722,5.6 5.6,5.95817224 5.6,6.4 C5.6,6.84182776 5.9581722,7.2 6.4,7.2 L9.6,7.2 C10.0418278,7.2 10.4,6.84182776 10.4,6.4 C10.4,5.95817224 10.0418278,5.6 9.6,5.6 L6.4,5.6 Z" id="Combined-Shape"></path>
                                                                    <rect id="Rectangle-143" x="0" y="0" width="16" height="3.2" rx="1"></rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                        :null}
                                    {this.props.deliteStatus ?
                                        <button style={!this.props.arhiveStatus ? {width:'100%'}:null} type="button" className={this.props.delStatus === 1 ? "btn is-active": "btn btn-style-border"} onClick={this.props.arhiveAction}>
                                            <svg width="13px" height="16px" viewBox="0 0 13 16">
                                                <g id="Desctop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g id="2_0_2" transform="translate(-919.000000, -2028.000000)">
                                                        <g transform="translate(389.000000, 1921.000000)">
                                                            <g id="Group-3-Copy" transform="translate(512.000000, 91.000000)">
                                                                <rect id="Rectangle" x="0" y="0" width="48" height="48" rx="6"></rect>
                                                                <g id="trash" transform="translate(18.000000, 16.000000)" fill={this.props.delStatus === 1 ? "#FFFFFF": "#7B5C9B"} fill-rule="nonzero">
                                                                    <path d="M0.912280702,3.98463168 L0.912280702,14.6315789 C0.912280702,15.387337 1.52494368,16 2.28070175,16 L10.4912281,16 C11.2469861,16 11.8596491,15.387337 11.8596491,14.6315789 L11.8596491,3.98463168 L0.912280702,3.98463168 Z" id="round"></path>
                                                                    <path d="M8.21052632,1.36842105 L8.21052632,0.912280702 C8.21052632,0.408441982 7.80208429,0 7.29824561,0 L5.47368421,0 C4.96984554,0 4.56140351,0.408441982 4.56140351,0.912280702 L4.56140351,1.36842105 L0.456140351,1.36842105 C0.204220996,1.36842105 0,1.57264205 0,1.8245614 L0,2.28070175 C0,2.53262111 0.204220996,2.73684211 0.456140351,2.73684211 L12.3157895,2.73684211 C12.5677089,2.73684211 12.7719298,2.53262111 12.7719298,2.28070175 L12.7719298,1.8245614 C12.7719298,1.57264205 12.5677089,1.36842105 12.3157895,1.36842105 L8.21052632,1.36842105 Z" id="Shape"></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    :null}

                                </div>
                            </div>
                            :null}
                        </div>
                    </div>
                    {this.props.delStatus ?
                        <div className="column-group w-100">
                            <div className="remove-settings">
                                <span className="input-label">{this.props.delStatus === 1 ? text[89] : text[90]}</span>
                                <div className="column-group w-100">
                                    <div className="group-settings">
                                        <div className="column-group w-50">
                                            <Input onChange={this.props.delInputAction}>{this.props.delInputValue}</Input>
                                        </div>
                                        <div className="column-group w-50 dop-min-10">
                                            <Select
                                                top
                                                nonTopValueElement
                                                action={this.props.delSelectAction}
                                                options={[
                                                    {key: 0, value: text[91][0]},
                                                    {key: 1, value: text[91][1]},
                                                    {key: 2, value: text[91][2]},
                                                    {key: 3, value: text[91][3]},
                                                ]}>{this.props.delSelectValue}</Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :null}
                </div>
            );
        }

    }
}