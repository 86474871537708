import React from "react";
import store from "../stor/mainStore";
import stile from "../component/post/component/accounts.module.css";
const mediaHelpers = {

    reCheckMedia () {
        alert(1233333);

    },
    limitsMedia () {
        const mediaStore = store.mediaStore;
        const accountsStore = store.accountsStore;

        const accounts = accountsStore.list.slice();
        const accountsOK = mediaStore.accounts.slice();

        let InstagramTrue = false;
        let TwitterTrue = false;
        let FbInstagramTrue = false;

        let proportions = false;
        if (mediaStore.type !== 'multyPost') {
            for (let key in accountsOK) {

                for (let keys in accounts) {
                    if (accountsOK[key].id === accounts[keys].id) {
                        if (accounts[keys].tip === 'instagram') {
                            proportions = true;
                            InstagramTrue = true;
                            break;
                        }
                        if (accounts[keys].tip === 'twitter') {
                            TwitterTrue = true;
                            break;
                        }
                        if (accounts[keys].tip === 'fb-instagram') {
                            FbInstagramTrue = true;
                            break;
                        }
                    }
                }
            }
        }

        if (mediaStore.type === 'post' || mediaStore.type === 'edit' || mediaStore.type === 'multyPost') {

            let duration = 60*30;

            if (InstagramTrue) {
                duration = 60;
            }

            if (FbInstagramTrue) {
                duration = 60;
            }

            if (TwitterTrue) {
                duration = 30;
            }




            return {
                proportions: proportions,
                min: 0.8,
                max: 1.91,
                duration: duration,
            }
        } else if (mediaStore.type === 'story' || mediaStore.type === 'editStory' || mediaStore.type === 'multyStory') {
            return {
                proportions: proportions,
                min: 0.5625,
                max: 0.5625,
                duration: 60,
            }
        } else if (mediaStore.type === 'igtv' || mediaStore.type === 'editIgtv') {
            return {
                proportions: proportions,
                min: 0.5625,
                max: 1.77,
                duration: 60*15,
            }
        } else if (mediaStore.type === 'reels' || mediaStore.type === 'editReels') {
            return {
                proportions: proportions,
                min: 0.5625,
                max: 0.5625,
                duration: 60,
            }
        }


    }

}

export default mediaHelpers;
