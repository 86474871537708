import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import style from "../../../component/menu/index.module.css";

export default
@inject('initialStore')
@observer
class MenuElementsBox extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <ul className="sidebar-nav">
                    {this.props.children}
            </ul>;
        } else {
            return <ul className="sidebar-nav">
                {this.props.children}
            </ul>;
        }

    }
}

