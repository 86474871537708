import React from 'react';
import {inject, observer} from "mobx-react";
import ButtonConfirmation from "../../elements/buttonDel";
import Button from "../../elements/button";
import InfoContainer from "../../infoConteiner";
import AccountInfoTop from "../../elements/accountInfoTop";

export default
@inject('initialStore')
@observer
class AccountEmployeesIndex extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        if (initialStore.templates === 'desktop') {
            return <div className="settings">
                <div className="staff">
                    <span className="settings-notifications__user">{this.props.account}</span>
                    <div className="chief__typical h1 flex">
                        <h1>{text[2]}</h1>
                        <Button action={this.props.addAccount}>accountEmployeeAdd</Button>
                    </div>
                    {this.props.children.length ?
                        <div className="staff-scroll">
                            <table className="staff-table">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>{text[23]}</th>
                                    <th>{text[28]}</th>
                                    <th>{text[29]}</th>
                                    <th>{text[30]}</th>
                                    <th>{text[31]}</th>
                                    <th>{text[8]}</th>
                                    <th>{text[9]}</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.props.children}
                                </tbody>
                            </table>
                        </div>
                        :
                        <InfoContainer
                        title={text[32](this.props.account)}
                        text={text[33]}
                        buttonName={text[34]}
                        buttonClick={this.props.addAccount}
                        />
                    }

                </div>
            </div>;
        } else {
            return <div className="settings">
                <div className="staff">

                    <AccountInfoTop
                        type={this.props.typeAccount}
                        name={this.props.account}
                        img={this.props.imgAccount}
                    />

                    <div className="chief__typical h1 flex">
                        <Button action={this.props.addAccount}>accountEmployeeAdd</Button>
                    </div>
                    {this.props.children.length ?
                        <div className="staff-scroll">

                            {this.props.children}
                        </div>
                        :
                        <InfoContainer
                            title={text[32](this.props.account)}
                            text={text[33]}
                            buttonName={text[34]}
                            buttonClick={this.props.addAccount}
                        />
                    }

                </div>
            </div>;
        }
    }
}