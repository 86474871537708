import React from 'react';

import {inject, observer} from "mobx-react";
import Textbox from "../../../component/Textbox";
import {observable} from "mobx";
import Input from "../../../../../templates/elements/input";

@inject('editorStore', 'initialStore')
@observer
class MediaRedBQuestion extends React.Component {

    @observable text = '';

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;
    }

    render() {
        const initialStore = this.props.initialStore;
        const textI18 = initialStore.text.addPost;

        const text = this.fabric.getElementQuestionsText();
        let ff = this.text;
        return (
            <div className="add-publication__text-box-red">
                <Input emoje className={'form-control view-2'} placeholder={textI18[159]} onChange={this.action}>{text}</Input>
            </div>
        );
    }

    action = (value) => {
        this.fabric.elementRedQuestions(value);
        this.text = value;
    }
}

export default MediaRedBQuestion;