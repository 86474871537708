import React from 'react';
import ReactDOM from "react-dom";

import {inject, observer} from "mobx-react";

import {observable} from "mobx";

import FileSaver from "file-saver";

import PlayVideo from "../../media/svg/PlayVideo.svg";

import axios from "axios";
import DuwenloadMediaButton from "./DuwenloadMediaButton";

@inject('postStore')
@inject('accountsStore')
@observer
class VideoBox extends React.Component {

    @observable load = false;

    @observable status = 0;

    @observable openElement = 0;

    constructor (props) {
        super(props);

        this.videoRef = React.createRef();
    }

    render() {

        let width = this.props.widthMedia;
        const item = this.props.item;
        let height = Math.ceil(width / item.proportions);

        return (<div className='image-gallery-image'>

            <div className="videoBox"
                 style={!this.status ? {width: width, height: height, lineHeight: height+'px', backgroundImage: 'url("'+item.original+'")'}:{width: width, height: height, lineHeight: height+'px'}}
                 onClick={this.status ? this.pause:this.play}>

                    <span style={this.status ? {display:'none'}:null}>
                        <svg width="42px" height="42px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21,0 C9.4017,0 0,9.4017 0,21 C0,32.5983 9.4017,42 21,42 C32.5983,42 42,32.5983 42,21 C42,9.4017 32.5983,0 21,0 Z M16.8,30.45 L16.8,11.55 L29.4,21 L16.8,30.45 Z" id="Shape"></path>
                        </svg>
                    </span>

            </div>
            <div className='video-wrapper'>
                <a className='close-video' /*onClick={this._toggleShowVideo.bind(this, item.embedUrl)}*/></a>
                <video
                    width={width}
                    height={height}
                    src={item.embedUrl}
                    ref={this.videoRef}
                    playsinline="true"
                >
                </video>
            </div>
            <DuwenloadMediaButton url={item.embedUrl} type={'mp4'}/>

        </div>);

        /*<video
               src="https://likepro.ru/cloud/21.04.2020/oz6018313jm/0.mp4"
               poster="https://likepro.ru/cloud/21.04.2020/oz6018313jm/0-icon.jpg"></video>*/

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open) {
            if (this.openElement !== this.props.open) {
                this.openElement = this.props.open;
                if (this.status == 1) {
                    const video = this.videoRef.current;
                    this.status = 0;
                    video.pause();
                    video.currentTime = 0;
                    debugger;
                }
            }
        }

    }

    componentDidMount() {
        const video = this.videoRef.current;
        let status = this.status;
        video.addEventListener("ended", () => {
            this.status = 0;

            video.currentTime = 0;

        }, true);
    }

    play = () => {
        this.status = 1;
        this.videoRef.current.play();

    }

    pause = () => {
        this.videoRef.current.pause();
        this.status = 0;
    }

    componentWillUnmount () {
        const video = this.videoRef.current;
        this.status = 0;
        video.pause();
        video.currentTime = 0;
    }


}

export default VideoBox;