import React from 'react';
import {inject, observer} from "mobx-react";
import Textbox from "./Textbox";
import TextModel from "../../../model/text";

@inject('mediaStore', 'accountsStore', 'initialStore')
@observer
class Comment extends React.Component {

    constructor (props) {
        super(props);

        //this.actionTextFullAccount = this.actionTextFullAccount.bind(this);
    }




    render() {
        const mediaStore = this.props.mediaStore;
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        let render = null;

        if (mediaStore.comment && !mediaStore.commentOff) {

            let error = null;
            const accounts = accountsStore.list.slice();
            for (const account of mediaStore.accounts.slice()) {
                for (let keys in accounts) {
                    if (accounts[keys].id === account.id) {
                        if (accounts[keys].tip === 'instagram') {

                            const errorAccounts = TextModel.limitedText(mediaStore.commentText, account.text + mediaStore.commentText);

                            if (!errorAccounts.status) {
                                error = errorAccounts.str;
                            }

                            if (error === null) {
                                error = errorAccounts.str;
                            }
                        }
                    }
                }
            }



            render = <div className="addPostOneCommentBox">
                <Textbox title={text[65]} text={mediaStore.commentText} textOnChange={mediaStore.actionCommentText} patternsUser={true}/>
                <span className="count-input">{error}</span>
            </div>;
        }

        return render;
    }
}

export default Comment;