import {action, observable} from "mobx";

class libraryStore {

    @observable list = [];
    @observable load = false;
    @observable hash = false;

    @observable categories = [];

    @observable openRedactor = false;

    @action getCategories = (list) => {
        let newCategories = [];

        newCategories.push({
            id: "0",
            name: 'All',
        });

        for (const element of list) {
            newCategories.push(element);
        }

        this.categories = newCategories;
    }

    @action getPost = (hash, list) => {

        if (list) {
            let newList =list.map((item) => {
                return {
                    ...item,
                };
            });
            this.list = newList;
            this.hash = hash;
        }
        this.load = false;

    }
}

export default libraryStore;