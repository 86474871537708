import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

import editorMediaClass from '../../model/eritorMedia';
import mediaHelpers from "../../model/mediaHelpers";

import MediaSize from "./componentEditor/MediaSize";

import MediaCover from "./componentEditor/MediaCover";
import MediaDuration from "./componentEditor/MediaDuration";

import MediaUsers from "./componentEditor/MediaUsers";
import MediaRed from "./componentEditor/MediaRed/MediaRed";
import PostTemplateEditor from "../../templates/post/editor";
import APIfile from "../../api/file";

@inject('mediaStore', 'editorStore', 'accountsStore')
@observer
class Editor extends React.Component {

    @observable editor = {
        elementOpen: 'size',
    };

    constructor (props) {
        super(props);

        this.boxRef = React.createRef();

        this.closeEditor = this.closeEditor.bind(this);
        this.save = this.save.bind(this);

        this.setCrop = this.setCrop.bind(this);
        this.objectZoom = this.objectZoom.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.objectCenter = this.objectCenter.bind(this);
        this.objectBackgroundPlus = this.objectBackgroundPlus.bind(this);
        this.objectBackgroundMinus = this.objectBackgroundMinus.bind(this);
        this.objectBackgroundSize = this.objectBackgroundSize.bind(this);

        this.onSize = this.onSize.bind(this);
        this.onCover = this.onCover.bind(this);
        this.onDuration = this.onDuration.bind(this);

        this.videoCover = React.createRef();
    }

    render() {

        const mediaStore = this.props.mediaStore;


        const accountsStore = this.props.accountsStore;
        const editorStore = this.props.editorStore;

        const accounts = accountsStore.list.slice();
        const accountsOK = mediaStore.accounts.slice();

        const fileStore = editorStore.file;

        if (mediaStore.editorOpen === false) {
            return null;
        }

        if (!editorStore.editorMedia) {
            return null;
        }

        const contentPX = editorStore.editorMedia.contentSizePX();

        //Определеяем, можно ли редактировать соотношение
        let redBackgroundSize = true;
        if (mediaStore.editorOpen !== 0) {
            const limitsMedia = mediaHelpers.limitsMedia();
            if (limitsMedia.proportions) {
                redBackgroundSize = false;
            }

        }

        if (mediaStore.type === 'multyPost') {
            redBackgroundSize = true;
        }

        const propsMediaSize = {
            typePost: mediaStore.type,
            idFile: mediaStore.editorOpen,
            type: fileStore.type,
            url: fileStore.url,
            backgroud: editorStore.editorMedia.backgroundSizePX(),
            stateObertka: {
                width: editorStore.editorMedia.getStateObertka().width,
                height: editorStore.editorMedia.getStateObertka().height
            },
            content: {
                width: contentPX.width,
                height: contentPX.height,
                left: contentPX.left,
                top: contentPX.top,
            },
            draggable: {
                x: editorStore.editorMedia.setDraggable().x,
                y: editorStore.editorMedia.setDraggable().y,
            },
            getDraggableBounds: editorStore.editorMedia.getDraggableBounds(),
            handleDrag: this.handleDrag,
            onMouseDownBox: this.onMouseDownBox,
            boxRef: this.boxRef,
            objectZoom: this.objectZoom,
            objectZoomInt: fileStore.zoom,
            objectCenter: this.objectCenter,
            objectBackgroundPlus: this.objectBackgroundPlus,
            objectBackgroundMinus: this.objectBackgroundMinus,
            objectBackgroundSize: this.objectBackgroundSize,
            objectBackgroundSizeInt: fileStore.proportionsBackground,
            objectBackgroundSizeStatus: redBackgroundSize,

            crop: fileStore.crop,
            setCrop: this.setCrop,

            fullSkreen: editorStore.editorMedia.getFullSkreen(),
        };

        const MediaCoverProps = {
            type: fileStore.type,
            url: fileStore.url,
            backgroud: editorStore.editorMedia.backgroundSizePX(),
            stateObertka: {
                width: editorStore.editorMedia.getStateObertka().width,
                height: editorStore.editorMedia.getStateObertka().height
            },
            content: {
                width: contentPX.width,
                height: contentPX.height,
                left: contentPX.left,
                top: contentPX.top,
            },
            draggable: {
                x: editorStore.editorMedia.setDraggable().x,
                y: editorStore.editorMedia.setDraggable().y,
            },
            getDraggableBounds: editorStore.editorMedia.getDraggableBounds(),
            onMouseDownBox: this.onMouseDownBox,
            boxRef: this.boxRef,
            objectZoom: this.objectZoom,
            objectZoomInt: fileStore.zoom,
            objectCenter: this.objectCenter,
            objectBackgroundPlus: this.objectBackgroundPlus,
            objectBackgroundMinus: this.objectBackgroundMinus,
            objectBackgroundSize: this.objectBackgroundSize,
            objectBackgroundSizeInt: fileStore.proportionsBackground,
            objectBackgroundSizeStatus: redBackgroundSize,
            videoCover: this.videoCover,
            createIcon: this.createIcon,
        };




        const MediaDurationProps = {
            type: fileStore.type,
            url: fileStore.url,
            backgroud: editorStore.editorMedia.backgroundSizePX(),
            stateObertka: {
                width: editorStore.editorMedia.getStateObertka().width,
                height: editorStore.editorMedia.getStateObertka().height
            },
            content: {
                width: contentPX.width,
                height: contentPX.height,
                left: contentPX.left,
                top: contentPX.top,
            },
            draggable: {
                x: editorStore.editorMedia.setDraggable().x,
                y: editorStore.editorMedia.setDraggable().y,
            },
            getDraggableBounds: editorStore.editorMedia.getDraggableBounds(),
            onMouseDownBox: this.onMouseDownBox,
            boxRef: this.boxRef,
            objectZoom: this.objectZoom,
            objectZoomInt: fileStore.zoom,
            objectCenter: this.objectCenter,
            objectBackgroundPlus: this.objectBackgroundPlus,
            objectBackgroundMinus: this.objectBackgroundMinus,
            objectBackgroundSize: this.objectBackgroundSize,
            objectBackgroundSizeInt: fileStore.proportionsBackground,
            objectBackgroundSizeStatus: redBackgroundSize,
        };

        const MediaUsersProps = {
            type: fileStore.type,
            url: fileStore.url,
            backgroud: editorStore.editorMedia.backgroundSizePX(),
            stateObertka: {
                width: editorStore.editorMedia.getStateObertka().width,
                height: editorStore.editorMedia.getStateObertka().height
            },
            content: {
                width: contentPX.width,
                height: contentPX.height,
                left: contentPX.left,
                top: contentPX.top,
            },
            draggable: {
                x: editorStore.editorMedia.setDraggable().x,
                y: editorStore.editorMedia.setDraggable().y,
            },
            getDraggableBounds: editorStore.editorMedia.getDraggableBounds(),
            onMouseDownBox: this.onMouseDownBox,
            boxRef: this.boxRef,
            objectZoom: this.objectZoom,
            objectZoomInt: fileStore.zoom,
            objectCenter: this.objectCenter,
            objectBackgroundPlus: this.objectBackgroundPlus,
            objectBackgroundMinus: this.objectBackgroundMinus,
            objectBackgroundSize: this.objectBackgroundSize,
            objectBackgroundSizeInt: fileStore.proportionsBackground,
            objectBackgroundSizeStatus: redBackgroundSize,
        };

        const MediaRedProps = {
            type: fileStore.type,
            url: fileStore.url,
            backgroud: editorStore.editorMedia.backgroundSizePX(),
            stateObertka: {
                width: editorStore.editorMedia.getStateObertka().width,
                height: editorStore.editorMedia.getStateObertka().height
            },
            content: {
                width: contentPX.width,
                height: contentPX.height,
                left: contentPX.left,
                top: contentPX.top,
            },
            draggable: {
                x: editorStore.editorMedia.setDraggable().x,
                y: editorStore.editorMedia.setDraggable().y,
            },
            getDraggableBounds: editorStore.editorMedia.getDraggableBounds(),
            onMouseDownBox: this.onMouseDownBox,
            boxRef: this.boxRef,
            objectZoom: this.objectZoom,
            objectZoomInt: fileStore.zoom,
            objectCenter: this.objectCenter,
            objectBackgroundPlus: this.objectBackgroundPlus,
            objectBackgroundMinus: this.objectBackgroundMinus,
            objectBackgroundSize: this.objectBackgroundSize,
            objectBackgroundSizeInt: fileStore.proportionsBackground,
            objectBackgroundSizeStatus: redBackgroundSize,
        };

        let componentOpen = null;

        if (this.editor.elementOpen === 'size') {
            componentOpen = <MediaSize {...propsMediaSize}/>;
        } else if (this.editor.elementOpen === 'cover') {
            componentOpen = <MediaCover {...MediaCoverProps}/>;
        } else if (this.editor.elementOpen === 'duration') {
            componentOpen = <MediaDuration {...MediaDurationProps}/>;
        } else if (this.editor.elementOpen === 'users') {
            componentOpen = <MediaUsers {...MediaUsersProps}/>;
        } else if (this.editor.elementOpen === 'red') {
            componentOpen = <MediaRed {...MediaRedProps}/>;
        }

        //доступно или нет - Отметить пользователей
        let otmetitPolzovateley = false;
        if (!accountsOK.length) {
            otmetitPolzovateley = true;
        } else {
            let accountInstagram = 0;
            for (let key in accountsOK) {

                let idDB = 0;
                for (let keys in accounts) {
                    if (accountsOK[key].id === accounts[keys].id) {
                        idDB = keys;
                    }
                }
                if (accounts[idDB].tip === 'instagram') {
                    accountInstagram++;
                }

                if (accounts[idDB].tip === 'fb-instagram') {
                    if (fileStore.type === 'image') {
                        accountInstagram++;
                    }
                }
            }

            if (accountInstagram) {
                otmetitPolzovateley = true;
            }
        }

        if (otmetitPolzovateley) {
            if (mediaStore.type !== 'post' && mediaStore.type !== 'edit') {
                otmetitPolzovateley = false;

            }
        }






        return <PostTemplateEditor
            refWindow={this.window}
            componentOpen={componentOpen}
            elementOpen={this.editor.elementOpen}
            onSize={this.onSize}
            type={fileStore.type}
            otmetitPolzovateley={otmetitPolzovateley}
            onCover={this.onCover}
            onDuration={this.onDuration}
            onRed={this.onRed}
            onUsers={this.onUsers}
            closeEditor={this.closeEditor}
            save={this.save}
        />;
    }

    onSize () {
        this.editor.elementOpen = 'size';
    }

    onCover() {
        this.editor.elementOpen = 'cover';
    }

    onDuration () {
        this.editor.elementOpen = 'duration';
    }
    onUsers = () => {
        this.editor.elementOpen = 'users';
    }
    onRed = () => {
        this.editor.elementOpen = 'red';
    }

    objectZoom(zoom) {
        const editorStore = this.props.editorStore;

        editorStore.editorMedia.setZoom(zoom);

        const importState = editorStore.editorMedia.importState();

        editorStore.updateFile(importState);
    }

    objectBackgroundPlus() {
        const editorStore = this.props.editorStore;

        console.log('+++');
        editorStore.editorMedia.setBackgroundPlus();

        const importState = editorStore.editorMedia.importState();

        editorStore.updateFile(importState);
    }
    objectBackgroundMinus() {
        const editorStore = this.props.editorStore;

        console.log('---');
        editorStore.editorMedia.setBackgroundMinus();

        const importState = editorStore.editorMedia.importState();

        editorStore.updateFile(importState);
    }

    objectBackgroundSize(size) {
        const editorStore = this.props.editorStore;

        editorStore.editorMedia.setProportionsBackground(size);

        const importState = editorStore.editorMedia.importState();

        editorStore.updateFile(importState);

    }


    handleDrag(event, ui) {
        const editorStore = this.props.editorStore;
        editorStore.editorMedia.getDraggable(ui.x, ui.y);

        const importState = editorStore.editorMedia.importState();

        editorStore.updateFile(importState);
    }

    objectCenter() {
        const editorStore = this.props.editorStore;

        editorStore.editorMedia.contentFullSkreen();

        const importState = editorStore.editorMedia.importState();

        editorStore.updateFile(importState);
    }




    setCrop (newCrop, newCrop2) {
        const editorStore = this.props.editorStore;

        editorStore.editorMedia.setCrop(newCrop, newCrop2);

        const importState = editorStore.editorMedia.importState();

        editorStore.updateFile(importState);
    }

    componentDidMount() {
        const mediaStore = this.props.mediaStore;
        const editorStore = this.props.editorStore;

        if (mediaStore.editorOpen === false) {
            return null;
        }

        if (!mediaStore.editorMedia) {
            const editorMedia = mediaStore.media[mediaStore.editorOpen];

            let mediaCopy = {};
            if (editorMedia.type === 'video') {
                mediaCopy = {
                    ...editorMedia,
                    duration: {
                        ...editorMedia.duration,
                        icons: [...editorMedia.duration.icons],
                        iconsCover: [...editorMedia.duration.iconsCover],
                    },
                    elements: {
                        video: {...editorMedia.elements.video},
                        image: {...editorMedia.elements.image},
                    },
                };
            } else {
                mediaCopy = {
                    ...editorMedia,
                    duration: {
                        ...editorMedia.duration,
                    },
                    elements: {
                        video: {...editorMedia.elements.video},
                        image: {...editorMedia.elements.image},
                    },
                };
            }

            editorStore.editorMedia = new editorMediaClass({...mediaCopy});

            const importState = editorStore.editorMedia.importState();

            editorStore.file = {...importState};


            //создаем иконки если их нет
            if (editorMedia.type === 'video') {
                if (mediaCopy.duration.icons.length === 0 || mediaCopy.duration.icons.iconsCover) {

                    //Высчитываем количество иконок
                    let nadoIcons = 10;
                    const width=document.body.clientWidth;
                    if (width < 769) {
                        const widthMax = width - 78;//241
                        nadoIcons = Math.ceil(widthMax/60);
                    }

                    let idLoad = mediaStore.idLoad;
                    if (mediaStore.type === 'multyPost' || mediaStore.type === 'multyStory') {
                        for (let key in mediaStore.multyPost.slice()) {
                            if (mediaStore.multyPost[key].id === mediaCopy.id) {
                                idLoad = mediaStore.multyPost[key].loadFileId;
                                break;
                            }
                        }
                    }


                    let dataInfo = {
                        start: mediaCopy.duration.start,
                        finish: mediaCopy.duration.finish,
                        icons: mediaCopy.duration.icons.length ? false:true,
                        iconsCover: mediaCopy.duration.iconsCover.length ? false:true,
                        id: mediaCopy.id,
                        key: idLoad,
                        iconsNumber: nadoIcons,
                    };


                    let editorOpenVideo = mediaStore.editorOpen;

                    APIfile.getImagesVideo(dataInfo.key, dataInfo.id, dataInfo.start, dataInfo.finish, dataInfo.icons, dataInfo.iconsCover, dataInfo.iconsNumber).then((data) => {
                        if (data.status) {

                            if (data.icons.length && !mediaCopy.duration.icons.length) {
                                mediaCopy.duration.icons = [...data.icons];
                                mediaStore.media[editorOpenVideo].duration.icons = [...data.icons];
                                editorStore.file.duration.icons = [...data.icons];
                            }

                            if (data.iconsCover.length && !mediaCopy.duration.iconsCover.length) {
                                mediaCopy.duration.iconsCover = [...data.iconsCover];
                                mediaStore.media[editorOpenVideo].duration.iconsCover = [...data.iconsCover];
                                editorStore.file.duration.iconsCover = [...data.iconsCover];
                            }
                        }
                    }).catch((e) => {

                    });
                }
            }
        }
    }

    closeEditor () {
        const mediaStore = this.props.mediaStore;
        const editorStore = this.props.editorStore;

        //закрываем редактор
        mediaStore.actionEditorOpen(false);

        editorStore.editorMedia = false;
    }

    save = async () => {

        const mediaStore = this.props.mediaStore;

        const editorStore = this.props.editorStore;

        const id = mediaStore.editorOpen;

        //копируем стейт
        let reStore = {
            ...editorStore.file,
            duration: {
                ...editorStore.file.duration,
            },
            elements: {
                video: {...editorStore.file.elements.video},
                image: {...editorStore.file.elements.image},
            },
        };

        //удаляем элемент стейта со значениями от редактора
        delete reStore.editor;

        //удаляем значения процента загрузки файла и процент обработки медиа
        delete reStore.loadPercent;
        delete reStore.processingPercent;

        //создаем новую иконку, если надо
        const cover = mediaStore.media[id].duration.cover;
        if (cover !== reStore.duration.cover) {

            //editorStore.fileBlobCover = rer;
            //editorStore.fileBlobCoverTime

            let coverTime = reStore.duration.cover;
            let coverFile = false;

            if (editorStore.fileBlobCover !== false && editorStore.fileBlobCoverTime === reStore.duration.cover) {
                coverFile = editorStore.fileBlobCover;

            } else {
                let infoCreate = await this.createIcon(this.videoCover);
                if (infoCreate) {
                    coverFile = editorStore.fileBlobCover;
                }

            }

            let idLoad = mediaStore.idLoad
            if (mediaStore.type === 'multyPost' || mediaStore.type === 'multyStory') {
                for (let key in mediaStore.multyPost.slice()) {
                    if (mediaStore.multyPost[key].id === id) {
                        idLoad = mediaStore.multyPost[key].loadFileId;
                        break;
                    }
                }
            }

            const loadCoverToServer = await APIfile.loadCoverToServer(mediaStore.media[id].id, coverFile, coverTime, idLoad);
            if (loadCoverToServer) {
                if (loadCoverToServer.date) {
                    if (loadCoverToServer.date.status) {
                        reStore.icon = loadCoverToServer.date.icon;
                    }
                }
            }

            /*let result = await getIconVideo(reStore.url, reStore.duration.cover, true);
            reStore.icon = result.img;
            reStore.cover = result.original;

            result=null;*/
        }

        //сохраняем локальный стейт в глобальный
        mediaStore.actionMediaUpdate(mediaStore.media[id].id, {
            ...reStore,
        });

        //закрываем редактор
        mediaStore.actionEditorOpen(false);

        editorStore.editorMedia = false;
        editorStore.fileBlobCover = false;
        editorStore.fileBlobCoverTime = false;

        this.videoCover = null;
    }

    createIcon = (videoURL) => {
        return new Promise((resolve, reject) => {
            const editorStore = this.props.editorStore;
            const videoElement = videoURL.current;

            if (videoElement === null) {
                resolve(false);
            }

            const width = videoElement.videoWidth;
            const height = videoElement.videoHeight;

            //создаем скриншот с видео
            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;


            let canvasContext = canvas.getContext("2d");


            canvasContext.drawImage(videoElement, 0, 0, width, height);

            let original = canvas.toDataURL("image/png");

            fetch(original)
                .then(res => res.blob())
                .then(rer => {
                    editorStore.fileBlobCover = rer;
                    editorStore.fileBlobCoverTime = editorStore.file.duration.cover;

                    resolve(true);


                    /*const objectURL = URL.createObjectURL(rer);
                    console.log(objectURL);
                    debugger;*/
                })
        });
    }
}

export default Editor;
