import React from 'react';

import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import APIfile from "../../../../api/file";

import { Grid} from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import loadFileAndBloobUrl from "../../../../model/loadFileAndBloobUrl";


@inject('editorStore', 'notificationStore', 'initialStore')
@observer
class MediaRedGif extends React.Component {

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;
    }

    gf = new GiphyFetch('FqAbzojU6cZTW5XAm4drLAn1gLUTNqmL');

    @observable categiry = 0;
    @observable result = false;

    @observable searhValue = '';

    @observable loadGifFile = false;

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        let content = <div className="css-1nu97io loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>;

        if (this.loadGifFile) {
            return (
                <>
                    <div className="giphi-conteiner">
                    </div>
                    <div className="items-type">
                        {content}
                    </div>
                    <div className="close-type">
                    </div>
                </>
            );
        }
        if (this.result) {
            if (this.result !== 'load') {
                content = <Grid width={this.props.width} columns={this.categiry === 3 ? 4:2} fetchGifs={this.result} noLink={true} onGifClick={this.onGifClick} hideAttribution={true}/>;
            }
        }

        let closeButton = null;

        if (this.searhValue !== '') {
            closeButton = <div className="icon icon-close-notif delite-giphi-searh" onClick={this.searchClose}></div>;
        }

        return (
            <>
                <div className="giphi-conteiner">
                    <div>
                        <input className='searh-giphi' placeholder={text[144]} onChange={(event) => this.searchOnChange(event.target.value)} value={this.searhValue}/>
                        {closeButton}
                    </div>
                    <div className="giphi-conteiner-cat">
                        <div className={this.categiry === 0 ? "giphi-conteiner-cat-element giphi-conteiner-cat-element-active":"giphi-conteiner-cat-element"} onClick={() => this.redCategiry(0)}>{text[145]}</div>
                        <div className={this.categiry === 1 ? "giphi-conteiner-cat-element giphi-conteiner-cat-element-active":"giphi-conteiner-cat-element"} onClick={() => this.redCategiry(1)}>{text[146]}</div>
                        <div className={this.categiry === 2 ? "giphi-conteiner-cat-element giphi-conteiner-cat-element-active":"giphi-conteiner-cat-element"} onClick={() => this.redCategiry(2)}>{text[147]}</div>
                        <div className={this.categiry === 3 ? "giphi-conteiner-cat-element giphi-conteiner-cat-element-active":"giphi-conteiner-cat-element"} onClick={() => this.redCategiry(3)} style={this.searhValue === '' ? null: {opacity: 0.5}}>{text[148]}</div>
                    </div>
                </div>
                <div className="items-type">
                    {content}
                </div>
                <div className="close-type">
                    <button type="button" className="btn border btn-block" onClick={this.close}>{text[149]}</button>
                </div>
            </>
        );
    }

    onGifClick = (date) => {

        this.loadGifFile = true;
        const notificationStore = this.props.notificationStore;
        const gif = date.images.original.url;

        loadFileAndBloobUrl(gif, 0, true, false).then((data) => {

            const culback = (data) => {

                this.fabric.addGif('new', false, 'https://likepro.ru/cloud/files/' + data.file).then(() => {
                    this.loadGifFile = false;
                    this.props.closeFunction(false);
                }).catch(() => {
                    this.loadGifFile = false;
                    notificationStore.add('Ошибка', 'Произошла ошибка в момент обработки файла, повторите попытку.');
                    return;
                });
            }

            APIfile.loadFileRed(data.blob, culback);

        }).catch((e) => {
            this.loadGifFile = false;
            alert('Ошибка загрузки обложки для видео, проверьте соединение с интернетом.');
        });


    }

    searchClose = () => {
        this.result = false;
        this.searhValue = '';
    }

    searchOnChange = (value) => {

        this.result = false;

        if (this.categiry === 3) {
            this.categiry = 0;
        }

        this.searhValue = value;
    }

    redCategiry = (num) => {

        if (this.searhValue !== '' && num === 3) return;

        if (num === this.categiry) return;

        this.categiry = num;
        this.result = false;
    }

    componentDidMount() {
        this.loadCategory();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.loadCategory();
    }

    loadCategory = () => {
        if (this.result === false) {
            this.result = 'load';
            if (this.searhValue !== '') {

                let typeSearh = 'stickers';

                if (this.categiry === 1) typeSearh = 'gifs';
                if (this.categiry === 2) typeSearh = 'text';

                this.result = (offset: number) => this.gf.search(this.searhValue, { sort: 'relevant', lang: 'ru', limit: 24, type: typeSearh});
            } else if (this.categiry === 0) {
                this.result = (offset: number) => this.gf.trending({ offset, limit: 24, type: "stickers" });
            } else if (this.categiry === 1) {
                this.result = (offset: number) => this.gf.trending({ offset, limit: 24, type: "gifs"});
            } else if (this.categiry === 2) {
                this.result = (offset: number) => this.gf.trending({ offset, limit: 24, type: "text" });
            } else if (this.categiry === 3) {
                this.result = (offset: number) => this.gf.emoji({ offset, limit: 24 });
            }


        }
    }


    close = () => {
        this.props.closeFunction();
    }
}

export default MediaRedGif;