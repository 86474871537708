import React from 'react';

import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ReactDOM from "react-dom";


import MenuAccountMenuBox from "../../../templates/elements/menu/accountMenuBox";
import MenuAccountMenuBoxElement from "../../../templates/elements/menu/accountMenuBoxElement";

@inject("accountsStore", "menuStore", "initialStore")
@observer
class AccountSetup extends React.Component {

    @observable openSetup = false;

    constructor(props) {
        super(props);

        this.buttomSetupClick = this.buttomSetupClick.bind(this);

        this.open = false;

        this.boxRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.clickToElementAlertMenu = this.clickToElementAlertMenu.bind(this);
    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;
        const menuStore = this.props.menuStore;

        const account = this.props.account;

        const rights =account.rights;

        const elements = [];

        if (!this.openSetup) {
            if (this.open) {
                this.open=false;
                this.updateStatusCloseFon();
            }
        } else {
            if (!this.open) {
                this.open=true;
                this.updateStatusCloseFon();
            }
        }

        if (this.openSetup) {
            elements.push(<MenuAccountMenuBoxElement
                onClick={this.clickToElementAlertMenu}
                url={'/account/'+ account.id + '/patterns'}
                active={menuStore.activeComponentAccount === 'patterns' ? true:false}
                name={text[0]}
            />);
            elements.push(<MenuAccountMenuBoxElement
                onClick={this.clickToElementAlertMenu}
                url={'/account/'+ account.id + '/timetable'}
                active={menuStore.activeComponentAccount === 'timetable' ? true:false}
                name={text[1]}
            />);
            if (rights.root) {
                elements.push(<MenuAccountMenuBoxElement
                    onClick={this.clickToElementAlertMenu}
                    url={'/account/'+ account.id + '/employees'}
                    active={menuStore.activeComponentAccount === 'employees' ? true:false}
                    name={text[2]}
                />);
                if (account.tip === 'instagram') {
                    elements.push(<MenuAccountMenuBoxElement
                        onClick={this.clickToElementAlertMenu}
                        url={'/account/'+ account.id + '/proxy'}
                        active={menuStore.activeComponentAccount === 'proxy' ? true:false}
                        name={text[3]}
                    />);
                    elements.push(<MenuAccountMenuBoxElement
                        onClick={this.openReAutorisation}
                        url={null}
                        active={null}
                        name={text[4]}
                    />);
                }
            }
            elements.push(<MenuAccountMenuBoxElement
                onClick={this.openDeliteAccount}
                url={null}
                active={null}
                name={text[5]}
            />);
        }

        return <MenuAccountMenuBox
            boxRef={this.boxRef}
            buttomSetupClick={this.buttomSetupClick}
            openSetup={this.openSetup}
            elements={elements}
        />;
    }

    openReAutorisation = () => {

        this.openSetup = false;

        const accountsStore = this.props.accountsStore;
        accountsStore.openModalReAutorisation = true;

    }

    openDeliteAccount = () => {

        this.openSetup = false;

        const accountsStore = this.props.accountsStore;
        accountsStore.openModalDelAccount = true;
    }

    updateStatusCloseFon = () => {
        if (this.open) {
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {

        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;

            let node = ReactDOM.findDOMNode(this.boxRef.current);

            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {

                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов

                // Если клик не производился и на кнопку открытия окна смайлов, то скрываем блок.

                    if (this.openSetup) {
                        this.openSetup = false;
                    }
            }
        } catch(error) {
            return null;
        }
    }

    clickToElementAlertMenu () {
        this.openSetup = false;
    }

    buttomSetupClick () {
        this.openSetup = !this.openSetup;
    }
}
export default AccountSetup;