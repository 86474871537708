import React from 'react';
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import classes from "../../../component/header/HeaderAccount.module.css";
import {observable} from "mobx";
import Time from "../../../model/time";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {formatDate, parseDate} from "react-day-picker/moment";
import Searh from "../../../component/HistoryPosts/Searh";

export default
@inject('initialStore')
@inject('accountsStore')
@inject('menuStore')
@observer
class AccountStatisticsBox extends React.Component {

    constructor(props) {
        super(props);


    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return (
                <div className="statistics-item">
                    <span className="title-item">{this.props.name} <span style={{color: '#7b5c9b', fontSize: 18}}>{this.props.info > 0 ? '+':null}{this.props.info}</span></span>
                    <div className="chart-item" style={{height: 300}}>
                        {this.props.children}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="statistics-item">
                    <span className="title-item">{this.props.name} <span style={{color: '#7b5c9b', fontSize: 18}}>{this.props.info > 0 ? '+':null}{this.props.info}</span></span>
                    <div className="chart-item" style={{height: 300}}>
                        {this.props.children}
                    </div>
                </div>
            );
        }
    }
}

