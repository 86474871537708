import React from 'react';
import Draggable from "react-draggable";

import mediaHelpers from "../../../model/mediaHelpers";
import {action, observable} from "mobx";
import {inject, observer} from "mobx-react";

@inject('initialStore')
@observer
class MediaSizeScroll extends React.Component {

    @observable xScroll = 0;
    @observable activeDrag = false;

    constructor (props) {
        super(props);

        this.state = {
            type: 'size',

            xMax: 552,
        };

        const width=document.body.clientWidth;

        if (width < 769) {
            const initialStore = this.props.initialStore;
            if (initialStore.cordova) {
                this.state.xMax = width-40;
            } else {
                this.state.xMax = width-78;
            }

        }

        this.typeHistory = 'size';

        this.onClickType = this.onClickType.bind(this);
        this.drag = this.drag.bind(this);


    }

    render() {
        const initialStore = this.props.initialStore;

        const text = initialStore.text.addPost;

        const typePost = this.props.typePost;

        let positionX = Math.round(this.state.xMax / 100 * this.xScroll);
        if (positionX > this.state.xMax) {
            positionX = this.state.xMax;
        }


        const DraggableProps = {
            axis: 'x',
            position: {
                x: positionX,
                y: 0
            },
            bounds: {
                left: 0,
                right: this.state.xMax,
            },
            onDrag: (event, ui) =>{this.drag(event, ui); this.activeDrag = true;},
            onStart:() => {this.activeDrag = true;},
            onStop: () => {this.activeDrag = false;},
        };

        let buttomHelp = null;

        if (this.props.type === 'aspect') {

            let active = 0;

            if (this.props.backgroundSizeInt > 1.05) {
                active = 3;
            } else if (this.props.backgroundSizeInt >= 0.95 && this.props.backgroundSizeInt <= 1.05) {
                active = 2;
            } else if (this.props.backgroundSizeInt < 0.95) {
                active = 1;
            }

            let raznitca = this.props.realProportionMedia - this.props.backgroundSizeInt;
            if (raznitca <= 0.01 && raznitca >= -0.01) {
                active = 0;
            }

            const action = (size) => {

                const limitsMedia = mediaHelpers.limitsMedia();
                const sizeMax = limitsMedia.max;
                const sizeMin = limitsMedia.min;

                if (size > sizeMax) size = sizeMax;
                if (size < sizeMin) size = sizeMin;

                this.props.backgroundSize(size);
                /*const limitsMedia = mediaHelpers.limitsMedia();
                const sizeMax = limitsMedia.max;
                const sizeMin = limitsMedia.min;*/
                const sizeCents = sizeMax-sizeMin;
                const zoom = Math.ceil(((size - sizeMin) / (sizeCents/100))*1000)/1000;
                this.xScroll = zoom;
            }

            buttomHelp = (
                <div className="buttomHelpSlider">
                    <div className={active === 0 ? "buttomHelpSliderButton active buttomHelpSliderButton25" : "buttomHelpSliderButton buttomHelpSliderButton25"} onClick={() => action(this.props.realProportionMedia)}>{text[97]}</div>
                    <div className={active === 1 ? "buttomHelpSliderButton active buttomHelpSliderButton25" : "buttomHelpSliderButton buttomHelpSliderButton25"} onClick={() => action(0.8)}>{text[98]}</div>
                    <div className={active === 2 ? "buttomHelpSliderButton active buttomHelpSliderButton25" : "buttomHelpSliderButton buttomHelpSliderButton25"} onClick={() => action(1)}>{text[99]}</div>
                    <div className={active === 3 ? "buttomHelpSliderButton active buttomHelpSliderButton25" : "buttomHelpSliderButton buttomHelpSliderButton25"} onClick={() => action(1.91)}>{text[100]}</div>
                </div>
            );
        } else {

            //

            let active = 3;


            const FullSkreen = this.props.zoomFullSkreen;

            const zoomIntRaznica = FullSkreen - this.props.zoomInt;
            if (this.props.zoomInt === 1) {
                active = 1;
            } else if (zoomIntRaznica <= 0.01 && zoomIntRaznica >= -0.01) {
                active = 0;
            }

            const action = (zoom) => {
                this.props.zoom(zoom);
                const sizeMax = 3;
                const sizeMin = 1;
                const sizeCents = sizeMax-sizeMin;
                const zooms = Math.ceil(((zoom - sizeMin) / (sizeCents/100))*1000)/1000;
                this.xScroll = zooms;
            }

            buttomHelp = (
                <div className="buttomHelpSlider">
                    <div className={active === 0 ? "buttomHelpSliderButton active buttomHelpSliderButton50" : "buttomHelpSliderButton buttomHelpSliderButton50"} onClick={() => action(this.props.zoomFullSkreen)}>{text[101]}</div>
                    <div className={active === 1 ? "buttomHelpSliderButton active buttomHelpSliderButton50" : "buttomHelpSliderButton buttomHelpSliderButton50"} onClick={() => action(1)}>{text[102]}</div>
                </div>
            );
        }

        if (typePost === 'igtv' && this.props.type === 'aspect') {
            buttomHelp = null;
        }

        let component = (
            <div className="editor-media__progress">
                <div className="line-progress">
                    {/*<span className="v-progress v-25"></span>
                    <span className="v-progress v-75"></span>*/}
                </div>
                <Draggable {...DraggableProps}>
                    <div className="slider-progress"></div>
                </Draggable>
                {buttomHelp}
            </div>
        );

        if (typePost === 'igtv' && this.props.type === 'aspect') {

            const center = this.state.xMax/2;

            let style = {marginBottom: '-22px'};
            if (initialStore.templates !== 'desktop') {
                style = {};
            }

            component = <>
                <div className="editor-cnt">
                    <div className="editor-media">
                        <div className="editor-media__type" style={style}>
                <button type="button" className={DraggableProps.position.x < center ? "btn btn-primary": "btn border btn-primary"} onClick={() => this.drag(null, {x:0})}>{text[98]}</button>
                <button type="button" className={DraggableProps.position.x > center ? "btn btn-primary": "btn border btn-primary"} onClick={() => this.drag(null, {x: this.state.xMax})}>{text[100]}</button>
                        </div>
                    </div>
                </div>
            </>;

        }



        return component;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const type = this.props.type;

        if (this.typeHistory !== type) {
            this.xScrollRe();
            this.typeHistory = type;
        }
    }

    xScrollRe = () => {

        let zoom = 0;
        if (this.props.type === 'size') {
            const sizeMax = 3;
            const sizeMin = 1;
            const sizeCents = sizeMax-sizeMin;

            zoom = Math.ceil(((this.props.zoomInt - sizeMin) / (sizeCents/100))*1000)/1000;
        } else {
            const limitsMedia = mediaHelpers.limitsMedia();

            const sizeMax = limitsMedia.max;
            const sizeMin = limitsMedia.min;
            const sizeCents = sizeMax-sizeMin;

            zoom = Math.ceil(((this.props.backgroundSizeInt - sizeMin) / (sizeCents/100))*1000)/1000;
        }

        this.xScroll = zoom;
    }




    handlePreventTouchmoveWhenPanning = (event) => {
        console.log(event)
        if (this.activeDrag) {
            event.preventDefault();
        }
    };

    componentDidMount () {
        this.xScrollRe();

        window.document.body.addEventListener('touchmove', this.handlePreventTouchmoveWhenPanning, {
            passive: false
        });
    }

    componentWillUnmount () {
        window.document.body.removeEventListener('touchmove', this.handlePreventTouchmoveWhenPanning, {
            passive: false
        });
    }



    drag(event, ui) {



        const x = ui.x;

        const xScroll = Math.ceil((x / (this.state.xMax/100))*1000)/1000;

        if (this.props.type === 'size') {
            const zoom = Math.ceil((1+(2/100*xScroll))*1000)/1000;
            this.props.zoom(zoom);
        } else {

            const limitsMedia = mediaHelpers.limitsMedia();

            const sizeMax = limitsMedia.max;
            const sizeMin = limitsMedia.min;
            const sizeCents = sizeMax-sizeMin;

            const size = Math.ceil((sizeMin+(sizeCents/100*xScroll))*1000)/1000;

            this.props.backgroundSize(size);
        }

        this.xScroll = xScroll;

    }

    onClickType () {

        if (!this.props.objectBackgroundSizeStatus) {
            return false;
        }

        if (this.props.type === 'size') {

            const limitsMedia = mediaHelpers.limitsMedia();

            const sizeMax = limitsMedia.max;
            const sizeMin = limitsMedia.min;
            const sizeCents = sizeMax-sizeMin;

            const size = Math.ceil(((this.props.backgroundSizeInt - sizeMin) / (sizeCents/100))*1000)/1000;


            this.xScroll = size;

        } else {

            const sizeMax = 3;
            const sizeMin = 1;
            const sizeCents = sizeMax-sizeMin;

            const zoom = Math.ceil(((this.props.zoomInt - sizeMin) / (sizeCents/100))*1000)/1000;

            this.xScroll = zoom;
        }

    }
}

export default MediaSizeScroll;