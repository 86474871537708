import {action, observable} from "mobx";

class chatStore {

    hostId = 'rM9Y8a9hEekzEMZcx';

    @observable status = false;

    @observable clientId = false;

    @observable clientIdHash = false;

    @observable clientsData = false;

    @observable message = null;
    @observable messageNext = true;

    @observable newMessage = 0;

    @observable operation = [];

    @observable operatorPrints = false;

    opened = false;

    idResult = 10;


    getIdResult = () => {
        this.idResult++;

        return String(this.idResult);
    }

    //function
    openChat = false;
    closeChat = false;
    updateMessage = false;
    submitMessage = false;
    nextButtonChat = false;
    getHistoryMessages = false;
    markClientAsRead = false;
    chatStatusFalse = false;

    messageId = false;



    @observable activeURL = '/';

    @observable openMenuMobile = false;

    @action actionOpenMenuMobile = (value) => {
        this.openMenuMobile = value;
    }
}

export default chatStore;