import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

export default
@inject('initialStore')
@observer
class PreviewBasic extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        let image = '';
        if (this.props.image) {
            image = <div className="wrap-img"><img src={'https://likepro.ru/cloud/'+this.props.image}/></div>;
            if (this.props.status) {
                return null;
            }
        }

        let tip = null;

        if (this.props.tip === 'video') {
            tip = <span className="type-post"><i className="icon icon-post-video"></i></span>;
        } else if (this.props.tip === 'gallery') {
            tip = <span className="type-post"><i className="icon icon-post-img"></i></span>;
        } else if (this.props.tip === 'igtv') {
            tip = <span className="type-post"><i className="icon icon-post-igtv"></i></span>;
        } else if (this.props.tip === 'reels') {
            tip = <span className="type-post"><i className="icon icon-post-reels"></i></span>;
        }

        let delite = null;

        if (this.props.delite) {
            delite = <div className="post-danger">
                <span className="icon-danger"><i className="icon icon-post-delite"></i></span>
                <span className="drop-danger">{this.props.delite}</span>
            </div>;
        }

        let time = this.props.time;

        if (this.props.status) {
            time = <span style={{color: '#ff7075'}}>Ошибка публикации</span>;
        }

        if (this.props.url) {
            time = 'Опубликовано';
        }

        if (initialStore.templates === 'desktop') {



            return (
                <div className="col">
                    <div className={!this.props.story ? "post post-base":"post post-history"} onClick={this.props.click} style={{borderRadius: '0px'}}>
                        <div className="img-post">
                            {tip}
                            <div className="inner-img">
                                {image}
                            </div>


                            {this.props.url ?
                            <a href={this.props.url} target="_blank" className="share-post"><i className="icon icon-abs icon-share"></i></a>
                            :null}

                        </div>

                    </div>
                </div>
            );
        } else {
            return (
                <div className="col">
                    <div className={!this.props.story ? "post post-base":"post post-history"} onClick={this.props.click} style={{borderRadius: '0px'}}>
                        <div className="img-post">
                            {tip}
                            <div className="inner-img">
                                {image}
                            </div>


                            {this.props.url ?
                                <a href={this.props.url} target="_blank" className="share-post"><i className="icon icon-abs icon-share"></i></a>
                                :null}

                        </div>

                    </div>
                </div>
            );
        }

    }
}

