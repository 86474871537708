import React from 'react';


import {inject, observer} from "mobx-react";

import imgLink from "../../media/svg/menu/menu-create-post-link.svg";

import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {observable} from "mobx";

@inject('postStore', 'notificationStore', 'initialStore')
@observer
class ButtonUrl extends React.Component {

    @observable action = false;

    constructor (props) {
        super(props);
    }

    render() {
        const postStore = this.props.postStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.post;

        return (
            <div className="add-post__events">
                <CopyToClipboard text={this.props.url} onCopy={() => {notificationStore.add(text[24], text[25])}}>
                    <button type="button" className={this.action === true ? "btn btn-primary btn-primary-url-copy btn-primary-url-copy-action":"btn btn-primary btn-primary-url-copy"} onClick={this.action}>{this.action === true ? '':<><img src={imgLink} className="btn-primary-url-copy-img"/>Получить ссылку на пост</>}</button>
                </CopyToClipboard>
                <div className="add-post__events_box">
                    <div className="input-group" style={{width: '100%'}}>
                        <div className="form-control element-input-inffo-url text-v-and-co">{this.props.url}</div>
                        <CopyToClipboard text={this.props.url} onCopy={() => {notificationStore.add(text[24], text[25])}}>
                            <button type="button" className="btn btn-primary" style={{width: '26%'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <path fill="#ffffff" fill-rule="nonzero" d="M1.8 0A1.8 1.8 0 0 0 0 1.8v12.6h1.8V1.8h12.6V0H1.8zm3.6 3.6a1.8 1.8 0 0 0-1.8 1.8v10.8A1.8 1.8 0 0 0 5.4 18h10.8a1.8 1.8 0 0 0 1.8-1.8V5.4a1.8 1.8 0 0 0-1.8-1.8H5.4zm0 1.8h10.8v10.8H5.4V5.4z"></path>
                                </svg>
                            </button>
                        </CopyToClipboard>

                    </div>
                </div>
            </div>
        );


    }

    action = (resolve) => {
        this.action = true;
    }



}

export default ButtonUrl;
