import React from 'react';

import {observable} from "mobx";
import {inject, observer} from "mobx-react";

import APIinstagram from "../../../api/instagram";

@inject('editorStore', 'notificationStore', 'mediaStore', 'initialStore')
@observer
class MediaUsersForm extends React.Component {

    @observable valueSearh = '';
    @observable focusInput = false;
    @observable indexSearh = 0;
    @observable load = false;
    @observable array = [];

    constructor (props) {
        super(props);

        this.duration = 0;
        this.timerId = false;

    }

    render() {
        const editorStore = this.props.editorStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        let render = null;

        let poleName = false;
        let poleNameActive = false
        let openSearh = false;
        if (this.focusInput) {
            render = (
                <div className="event-search">
                    <div className={this.load ? "chat-search is-active btn-primary-animate-content" : "chat-search is-active"}>
                        <span className="chat-search__icon">
                            <i className="icon form-control-user-icon-input"></i>
                        </span>
                        <div className="chat-search__input">
                            <input placeholder={text[110]} autoFocus value={this.valueSearh} onChange={this.inputOnChange} onBlur={this.onBlur}/>
                        </div>
                    </div>

                    {this.array.length ?
                        <div className="event-search__result">
                            <div className="inner-result">
                                {this.arrayObject()}
                            </div>
                        </div>
                        :null}

                </div>

            );

            openSearh = true;
        } else {
            render = null;
            poleName = text[111];
        }

        const component = <>
            <div className={openSearh ? "event-drop dropdown is-open" : "event-drop dropdown"}>
                <div className={poleNameActive ? "form-control form-control-user-icon-active-user" : "form-control form-control-user-icon"} onClick={this.onFocus} style={{paddingLeft: '40px'}}>{poleName}</div>

                {render}

            </div>
        </>;

        return component;
    }

    arrayObject () {


        let array = this.array.map((value, key) => {
            return (
                <span className="item-result item-result-searh-users" onMouseDown={() => {this.onClick(value)}}>
                    <div className="item-result-user-image">
                        <img src={value.img}/>
                    </div>
                    <div className="item-result-user-info">
                        {value.username}<br/>
                        <span className="item-result-adress">{value.full_name}</span>
                    </div>
                </span>
            );
        });
        return array;
    }

    onClick (info) {
        const mediaStore = this.props.mediaStore;
        const editorStore = this.props.editorStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        const accountNewList = [];

        const arrayAccounts = [];
        for (const user of editorStore.file.accounts.slice()) {

            const array = {
                x: user.x,
                y: user.y,
                name: user.name,
                id: user.id,
            };

            if (array.id === info.pk) {
                notificationStore.add(text[17], text[112](info.username));
                return true;
            }

            arrayAccounts.push(user.id);

            accountNewList.push(array);
        }

        for (const media of mediaStore.media.slice()) {
            if (editorStore.file.id === media.id) {
                continue;
            }


            if (media.accounts === undefined) {
                continue;
            }

            for (const account of media.accounts.slice()) {

                let newAccount = true;
                for (const accountIDEst of arrayAccounts) {
                    if (accountIDEst === account.id) {
                        newAccount = false;
                    }
                }
                if (newAccount) {
                    arrayAccounts.push(account.id);
                }
            }
        }

        if (arrayAccounts.length >= 20) {
            notificationStore.add(text[17], text[113]);
            return true;
        }


        accountNewList.push({
            x: 50,
            y: 50,
            name: info.username,
            id: info.pk,
        });

        editorStore.file.accounts = accountNewList;
    }

    onFocus = () => {
        this.focusInput = true;
        this.valueSearh = '';
        this.array = '';
    }
    onBlur = () => {
        this.focusInput = false;
    }
    inputOnChange = (e) => {

        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        const value = e.target.value;

        this.valueSearh = value;

        this.indexSearh++;

        if (value === '') {
            this.array = [];
            this.load = false;
            return true;
        }

        this.load = true;

        APIinstagram.getUsers(value, this.indexSearh).then((data) => {

            if (this.indexSearh === data.id) {

                this.load = false;

                if (!data.status) {
                    notificationStore.add(text[114], data.info);
                    return false;
                }

                if (data.return.length === 0) {
                    notificationStore.add(text[115], text[116](value));
                }

                this.array = data.return;

            }

        }).catch((error) => {

        });
    }
}

export default MediaUsersForm;