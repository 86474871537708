import React from 'react';
import {inject, observer} from "mobx-react";

import icon from "../../../../media/svg/media-del-media.svg";


export default
@inject('initialStore')
@observer
class AccountDirectMessageRavenMedia extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {

            let content='';

            if (this.props.content === 'PhotoInstagram') {
                content=<>Изчезающее фото<br/><span className="chat-message__help">Посмотрите его через мобильное приложение Instagram.</span></>;
            } else if (this.props.content === 'PhotoBum') {
                content=<><img src={icon} width={18} height={17}/>  Фото</>;
            } if (this.props.content === 'PhotoPley') {
                content=<>ПЛЕЙ Фото</>;
            } if (this.props.content === 'VideoPley') {
                content=<>ПЛЕЙ  Видео</>;
            }

            return (
                <div className="chat-message__inner">
                    <div className="inner-content">
                        <div className="column-content">
                            <div className="chat-message__photo">{content}</div>
                        </div>
                        <div className="column-content date">
                            <time dateTime={this.props.time} className="chat-message__date static">{this.props.time}</time>
                        </div>
                    </div>
                </div>
            );
        } else {
            let content='';

            if (this.props.content === 'PhotoInstagram') {
                content=<>Изчезающее фото<br/><span className="chat-message__help">Посмотрите его через мобильное приложение Instagram.</span></>;
            } else if (this.props.content === 'PhotoBum') {
                content=<><img src={icon} width={18} height={17}/>  Фото</>;
            } if (this.props.content === 'PhotoPley') {
                content=<>ПЛЕЙ Фото</>;
            } if (this.props.content === 'VideoPley') {
                content=<>ПЛЕЙ  Видео</>;
            }

            return (
                <div className="chat-message__inner">
                    <div className="inner-content">
                        <div className="column-content">
                            <div className="chat-message__photo">{content}</div>
                        </div>
                        <div className="column-content date">
                            <time dateTime={this.props.time} className="chat-message__date static">{this.props.time}</time>
                        </div>
                    </div>
                </div>
            );
        }
    }

}