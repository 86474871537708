import APIpatterns from "../../api/patterns";
import store from "../../stor/mainStore";

export function getPatterns() {

    const accountsStore = store.accountsStore;

    const hesh = accountsStore.patterns.hesh;

    APIpatterns.getPatterns(hesh).then((data) => {

        accountsStore.getPatterns(data.hesh, data.patterns);

    }).catch((error) => {

    });
}
