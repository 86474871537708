import React from 'react';
import {inject, observer} from "mobx-react";

import {autorisation} from './actions/app/initial';

import {directLoop} from "./model/direct";

//Компоненты
import AccountLoop from './component/Accountloop';
import Post from "./component/post/Post";
import MediaLoop from "./component/MediaLoop";
import StatusPostLoop from "./component/StatusPostLoop";
import VievPost from "./component/modalVievPost/VievPost";
import ModalPatterns from "./component/modalPatterns/ModalPatterns";
import LoaderApp from "./templates/loaderApp";


//CSS
import './media/css/bootstrap-reboot.css';
import './media/css/icons.css';

import FrameApp from "./templates/FrameApp";
import Notification from "./component/Notification";
import ModalTarifPay from "./component/modalTarifPay/ModalTarifPay";
import ModalAddAccount from "./component/modalAddAccount/ModalAddAccount";
import ModalDelMedia from "./component/modalDelMedia/ModalDelMedia";
import ModalExit from "./component/modalExit/ModalExit";
import ModalSearhLibraryPost from "./component/modalSearhLibraryPost/ModalSearhLibraryPost";
import ModalSearhUrlPost from "./component/modalSearhUrlPost/ModalSearhUrlPost";
import {observable} from "mobx";
import ModalReAutorisation from "./component/modalReAutorisation/ModalReAutorisation";
import ModalDelAccount from "./component/modalDelAccount/ModalDelAccount";
import ModalRedPostTime from "./component/modalRedPostTime/ModalRedPostTime";
import PostMulty from "./component/post/PostMulty";
import ModalHelp from "./component/modalHelp/ModalHelp";
import ModalDateMobile from "./component/modalDateMobile/ModalDateMobile";
import ModalDelButton from "./component/modalDelButton/ModalDelButton";
import ChatSetup from "./component/ChatSetup";
import ChatraSetup from "./component/ChatraSetup";
import ModalTarifRed from "./component/modalTarifRed/ModalTarifRed";



@inject('initialStore', 'mediaStore')
@observer
class App extends React.Component {

    @observable loadCSS = false;

    constructor (props) {

        super(props);

        directLoop(this.props.history.push);

        this.props.initialStore.redirect = (url) => {this.props.history.push(url)};

        /*
        Меньтше 769 полная мобилка
        Меньше 993 мобилка но модалки уже дисктопные
        Все что больше 993 уже полноценный режим
         */
        const width=document.body.clientWidth;//992
        const height=window.innerHeight;
        this.props.initialStore.width = width;
        this.props.initialStore.height = height;



        if (width < 993) {
            this.props.initialStore.templates = 'mobile';

            this.props.initialStore.limitVisualPostsPlasDefoult = 9;

            (async () => {
                // import module for side effects
                await import ('./media/css-mobile/main.css');
                await import ('./media/css-mobile/root.css');
                await import ('./media/css-mobile/media.css');

                this.loadCSS = true;
            })();
        } else {
            this.props.initialStore.templates = 'desktop';

            (async () => {
                // import module for side effects
                await import ('./media/css/main.css');
                await import ('./media/css/root.css');
                await import ('./media/css/media.css');

                this.loadCSS = true;
            })();
        }
    }


    render() {

        if (!this.loadCSS) {
            return null;
        }
        const initialStore = this.props.initialStore;
        const mediaStore = this.props.mediaStore;

        let content = <LoaderApp/>;

        if (initialStore.autorisation === true) {

            let chat = null;
            if (initialStore.templates === 'desktop') {
                chat = <ChatraSetup/>;
            } else {
                chat = <ChatSetup/>;
            }

            if (mediaStore.open) {
                content = <>
                        <Post/>
                        <PostMulty/>

                        <ModalDelMedia/>
                        <ModalDateMobile/>
                        <ModalDelButton/>

                        <div className="notifications">
                            <Notification/>
                        </div>

                        <MediaLoop/>
                    </>;
            } else {

                content = <>
                        <AccountLoop/>

                        <StatusPostLoop/>

                        <FrameApp/>

                        <VievPost/>

                        <ModalDelButton/>
                        <ModalAddAccount/>
                        <ModalPatterns/>
                        <ModalTarifPay/>
                        <ModalTarifRed/>
                        <ModalSearhLibraryPost/>
                        <ModalSearhUrlPost/>
                        <ModalReAutorisation/>
                        <ModalDelAccount/>
                        <ModalRedPostTime/>
                        <ModalHelp/>
                        <ModalExit/>
                        <ModalDateMobile/>

                        <div className="notifications">
                            <Notification/>
                        </div>
                    </>;
            }

            content=<>{chat}{content}</>;

        } else if (initialStore.status === false) {
            if (initialStore.statusType === 0) {
                content = <div>Ошибка, проверьте соединение с интернетом.</div>;
            } else {
                content = <div>Ошибка, сервис временно не работает.</div>;
            }

        } else if (initialStore.autorisation === false) {
            this.props.history.push(`/login`);
        }
        return content;
    }

    componentDidMount() {
        autorisation(this.props.initialStore);
    }


}

export default App;
