import React from 'react';
import {inject, observer} from "mobx-react";

import PostBox from "../../../component/PostBox/PostBox";

import 'react-day-picker/lib/style.css';

import {autorun} from "mobx";
import APIposts from "../../../api/posts";
import PostsBox from "../../../templates/posts/postsBox";
import ButtonLoad from "../../../templates/elements/buttonLoad";
import InfoContainer from "../../../templates/infoConteiner";
import LoaderComponent from "../../../templates/loaderComponent";

@inject('menuStore', 'accountsStore', 'initialStore')
@observer
class History extends React.Component {

    bilScroll = 0;

    constructor(props) {
        super(props);

        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        autorun(() => {
            const accountsStore = this.props.accountsStore;

            const start = accountsStore.list[accountsStore.active].postStore.search.start;
            const end = accountsStore.list[accountsStore.active].postStore.search.end;
            const type = accountsStore.list[accountsStore.active].postStore.search.type;

            const Zstart = accountsStore.list[accountsStore.active].postStore.loadDate.start;
            const Zend = accountsStore.list[accountsStore.active].postStore.loadDate.end;
            const Ztype = accountsStore.list[accountsStore.active].postStore.loadDate.type;

            if (!start) return;
            if (!end) return;
            if (start === Zstart && end === Zend && type === Ztype) return;
            if (accountsStore.list[accountsStore.active].postStore.load) return;

            accountsStore.list[accountsStore.active].postStore.loadDate.start = start;
            accountsStore.list[accountsStore.active].postStore.loadDate.end = end;
            accountsStore.list[accountsStore.active].postStore.loadDate.type = type;

            accountsStore.list[accountsStore.active].postStore.load = true;
            accountsStore.list[accountsStore.active].postStore.list = null;
            accountsStore.list[accountsStore.active].postStore.listID = 0;

            APIposts.getPostsHistory(
                accountsStore.list[accountsStore.active].id,
                accountsStore.list[accountsStore.active].postStore.loadDate.start,
                accountsStore.list[accountsStore.active].postStore.loadDate.end,
                accountsStore.list[accountsStore.active].postStore.loadDate.type,
                accountsStore.list[accountsStore.active].postStore.listID,
            ).then((data) => {

                accountsStore.list[accountsStore.active].postStore.load = false;
                accountsStore.getPostStore(accountsStore.active, data.posts);
                accountsStore.list[accountsStore.active].postStore.listID = data.list;

            }).catch((error) => {
                accountsStore.list[accountsStore.active].postStore.load = false;
                accountsStore.list[accountsStore.active].postStore.list = null;
                alert(text[132]);
            });

        });
    }

    render() {

        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        let content;

        let addPosts = null;

        if (accountsStore.list[accountsStore.active].postStore.load) {

            content = <LoaderComponent/>;

        } else if (accountsStore.list[accountsStore.active].postStore.list) {
            const postsR = accountsStore.list[accountsStore.active].postStore.list;
            let posts = [];
            posts = postsR.map((item) => {
                return <PostBox key={item.id} {...item} />;
            });

            if (accountsStore.list[accountsStore.active].postStore.listID) {
                addPosts = <ButtonLoad style={{width: '100%'}} action={this.addPosts}>downloadMorePublications</ButtonLoad>;
            }
            if (!posts.length) {
                content = <InfoContainer
                    title={text[147][0]}
                    text={text[147][1]}
                />;
            } else {
                content = <PostsBox  button={addPosts}>
                    <div className="posts row view-1">
                        {posts}
                    </div>
                </PostsBox>;
            }

        } else {
            content = <InfoContainer
                title={text[112]}
                text={text[113]}
            />;
        }

        return content;
    }

    addPosts = (promise) => {
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        const start = accountsStore.list[accountsStore.active].postStore.search.start;
        const end = accountsStore.list[accountsStore.active].postStore.search.end;
        const type = accountsStore.list[accountsStore.active].postStore.search.type;

        if (accountsStore.list[accountsStore.active].postStore.load) return;

        //accountsStore.list[accountsStore.active].postStore.load = true;

        APIposts.getPostsHistory(
            accountsStore.list[accountsStore.active].id,
            accountsStore.list[accountsStore.active].postStore.loadDate.start,
            accountsStore.list[accountsStore.active].postStore.loadDate.end,
            accountsStore.list[accountsStore.active].postStore.loadDate.type,
            accountsStore.list[accountsStore.active].postStore.listID,
        ).then((data) => {

            this.bilScroll = initialStore.refContentInner.scrollTop;

            accountsStore.list[accountsStore.active].postStore.load = false;
            accountsStore.getPostStore(accountsStore.active, data.posts);
            accountsStore.list[accountsStore.active].postStore.listID = data.list;
            promise(true);
        }).catch((error) => {
            accountsStore.list[accountsStore.active].postStore.load = false;
            promise(true);
            alert(text[132]);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.bilScroll) {
            const initialStore = this.props.initialStore;
            initialStore.funScrollTopContentInner(0, this.bilScroll);
            this.bilScroll = 0;
        }
    }


    componentDidMount() {
        this.props.menuStore.updateActiveComponentAccount('history');
    }
}

export default History;