import React from 'react';
import {inject, observer} from "mobx-react";
import PostsBox from "../../../../templates/posts/postsBox";
import PostBox from "../../../../component/PostBox/PostBox";
import InfoContainer from "../../../../templates/infoConteiner";
import Time from "../../../../model/time";
import LoaderComponent from "../../../../templates/loaderComponent";
import {autorun, observable} from "mobx";
import initialStore from "../../../../stor/initialStore";
import {impureFinalPropsSelectorFactory} from "react-redux/lib/connect/selectorFactory";


@inject('accountsStore', 'menuStore', 'initialStore', 'mediaStore')
@observer
class PostsDate extends React.Component {

    activeAccount = false;

    render() {

        const accountsStore = this.props.accountsStore;
        const posts = accountsStore.list[accountsStore.active].post.list;
        const initialStore = this.props.initialStore;


        let maxElement = initialStore.limitVisualPosts;

        const text = initialStore.text.account;

        //return <LoaderComponent/>;

        let content = <LoaderComponent/>;

        let tip = 'date';


            if (posts !== null) {

                if (posts.length) {
                    let postsArray = [];
                    if (tip === '') {
                        postsArray = posts.map((item) => {

                            return <PostBox key={item.id} {...item} />;

                        });
                    } else {

                        let time = '';
                        let dateBox = {
                            'Ошибка':[],
                            'Удаление':[],
                        };

                        postsArray = posts.map((item) => {
                            let timeText = Time.convertUnixToDatePostBoxDelitel(item.time);

                            if (item.url) {
                                timeText = 'Удаление';
                            }

                            if (item.status) {
                                timeText = 'Ошибка';
                            }

                            let dateBoxEst = false;
                            for (const dateBoxName in dateBox) {
                                if (dateBoxName === timeText) {
                                    dateBoxEst = true;
                                    break;
                                }
                            }

                            if (!dateBoxEst) {
                                dateBox[timeText] = [];
                            }


                            dateBox[timeText][dateBox[timeText].length] = (<PostBox key={item.id} {...item} />);

                            /*if (time !== timeText) {
                                time = timeText;
                                return <>
                                    <div style={{width: '100%', fontSize: 20,margin: '30px 0 0 10px'}}>{timeText}</div>
                                    <PostBox key={item.id} {...item} />
                                </>;
                            } else {
                                return <PostBox key={item.id} {...item} />;
                            }*/

                        });




                        for (const dateBoxName in dateBox) {

                            if (dateBox[dateBoxName].length) {

                                if (dateBoxName === 'Ошибка') {
                                    if (!maxElement) break;
                                    const dateBoxElementDIV = [];
                                    for(const dateBoxElement of dateBox[dateBoxName]) {

                                        if (!maxElement) break;
                                        maxElement--;

                                        dateBoxElementDIV.push(dateBoxElement);
                                    }

                                    postsArray.push(<div className="posts row view-1" key={'Ошибка'}>
                                        <div className="element-info-post-box">{text[107]} <span className="count" style={{color: '#7b5c9b'}}>{dateBox[dateBoxName].length}</span></div>
                                        {dateBoxElementDIV}
                                    </div>);
                                }
                            }

                        }

                        for (const dateBoxName in dateBox) {

                            if (dateBox[dateBoxName].length) {

                                if ( dateBoxName === 'Удаление' || dateBoxName === 'Ошибка') {
                                    continue;
                                } else  {
                                    if (!maxElement) break;
                                    const dateBoxElementDIV = [];

                                    for(const dateBoxElement of dateBox[dateBoxName]) {

                                        if (!maxElement) break;
                                        maxElement--;
                                        dateBoxElementDIV.push(dateBoxElement);
                                    }

                                    postsArray.push(<div className="posts row view-1" key={dateBoxName}>
                                        <div className="element-info-post-box">{dateBoxName} <span className="count" style={{color: '#7b5c9b'}}>{dateBox[dateBoxName].length}</span></div>
                                        {dateBoxElementDIV}
                                    </div>);
                                }

                            }

                        }

                        for (const dateBoxName in dateBox) {

                            if (dateBox[dateBoxName].length) {

                                if ( dateBoxName === 'Удаление') {
                                    if (!maxElement) break;
                                    const dateBoxElementDIV = [];
                                    for(const dateBoxElement of dateBox[dateBoxName]) {

                                        if (!maxElement) break;
                                        maxElement--;

                                        dateBoxElementDIV.push(dateBoxElement);
                                    }

                                    postsArray.push(<div className="posts row view-1" key={'Удаление'}>
                                        <div className="element-info-post-box">{text[108]} <span className="count" style={{color: '#7b5c9b'}}>{dateBox[dateBoxName].length}</span></div>
                                        {dateBoxElementDIV}
                                    </div>);
                                }

                            }

                        }
                    }

                    content = <PostsBox>
                        {postsArray}
                    </PostsBox>;
                } else {

                    const mediaStore = this.props.mediaStore;

                    if (!mediaStore.collapsed) {
                        content = <InfoContainer
                            title={text[110]}
                            text={text[109](accountsStore.list[accountsStore.active].name)}
                            buttonName={text[111]}
                            buttonClick={this.openPost}
                        />;
                    } else {
                        content = <InfoContainer
                            title={text[110]}
                            text={text[109](accountsStore.list[accountsStore.active].name)}
                        />;
                    }
                }
            }

        return content;
    }


    componentDidMount() {
        const initialStore = this.props.initialStore;
        const accountsStore = this.props.accountsStore;

        initialStore.limitVisualPosts = initialStore.limitVisualPostsPlasDefoult;

        if (accountsStore.list[accountsStore.active].post.list) {
            const posts = accountsStore.list[accountsStore.active].post.list;
            initialStore.postInLentaMax = posts.length;
        } else {
            initialStore.postInLentaMax = 0;
        }


        this.activeAccount = accountsStore.active;

        this.props.menuStore.updateActiveComponentAccount('post');
    }

    componentWillUpdate() {
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;

        if (accountsStore.active !== this.activeAccount) {

            initialStore.limitVisualPosts = initialStore.limitVisualPostsPlasDefoult;

            if (accountsStore.list[accountsStore.active].post.list) {
                const posts = accountsStore.list[accountsStore.active].post.list;

                initialStore.postInLentaMax = posts.length;
            }


            this.activeAccount = accountsStore.active;

        } else {

            if (!initialStore.postInLentaMax && accountsStore.list[accountsStore.active].post.list) {
                const posts = accountsStore.list[accountsStore.active].post.list;
                initialStore.postInLentaMax = posts.length;
            }

        }
    }

    openPost = () => {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        //выставляем аккаунт который выбран
        if (mediaStore.accounts.length === 0 && accountsStore.active !== null) {

            const time = Time.dateTimetableGenerator(accountsStore.list[accountsStore.active].id, 'post');

            mediaStore.actionAccount(accountsStore.list[accountsStore.active].id, time[0]);
        }
        this.props.mediaStore.actionOpen(true);
    }
}
export default PostsDate;