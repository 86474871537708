import React from 'react';
import {inject, observer} from "mobx-react";

import classes from './PostBox.module.css';
import loafFile from '../../model/loadFile';
import loaderGif from './loader.gif';
import Time from "../../model/time";
import {observable} from "mobx";
import ReactDOM from "react-dom";


@inject('accountsStore', 'initialStore')
@observer
class Searh extends React.Component {

    @observable typeOpen = false;

    constructor(props) {
        super(props);

        this.window = React.createRef();

        this.onFocus = this.onFocus.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.newType = this.newType.bind(this);

        this.openWin = false;
    }

    render() {
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;
        const active = accountsStore.active;
        const type = accountsStore.list[active].postStore.search.type;
        const text = initialStore.text.account;

        let typeLanguage = text[133];
        if (type === 'like') {
            typeLanguage = text[134];
        } else if (type === 'comment') {
            typeLanguage = text[135];
        } else if (type === 'view') {
            typeLanguage = text[136];
        }

        if (!this.typeOpen) {
            if (this.openWin) {
                this.openWin=false;
                this.updateStatusCloseFon();
            }
        } else {
            if (!this.openWin) {
                this.openWin=true;
                this.updateStatusCloseFon();
            }
        }

        let contentType = null;
        if (this.typeOpen) {
            contentType = <div className="event-menu g-center v-bottom show">
                    <span className="event-item" onClick={() => {this.newType('time')}}>{text[137]}</span>
                    <span className="event-item" onClick={() => {this.newType('like')}}>{text[138]}</span>
                    <span className="event-item" onClick={() => {this.newType('comment')}}>{text[139]}</span>
                    <span className="event-item" onClick={() => {this.newType('view')}}>{text[140]}</span>
                </div>;
        }

        let content = <div className="filter-sort" ref={this.window}>
                {text[141]}&nbsp;
                <div className="filter-sort__nav">
                    <span className="label-nav" onClick={this.onFocus}>{typeLanguage}</span>
                    {contentType}
                </div>
            </div>;

        return content;
    }

    newType (type) {
        const accountsStore = this.props.accountsStore;
        const active = accountsStore.active;

        accountsStore.list[active].postStore.search.type = type;
        this.typeOpen = false;
    }

    onFocus () {
        this.typeOpen = !this.typeOpen;
    }

    updateStatusCloseFon = () => {
        if (this.openWin) {

            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов

                if (this.typeOpen) {
                    this.typeOpen = false;
                }
            }
        } catch(error) {
            return null;
        }
    }
}
export default Searh;