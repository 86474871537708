import {action, observable} from "mobx";

class editorStore {

    @observable file = {
        crop: false,
        draggable: false,
        duration: false,
        file: false,
        icon: false,
        id: false,
        loadPercent: false,
        processingPercent: false,
        proportions: false,
        proportionsBackground: false,
        type: false,
        update: false,
        url: false,
        zoom: false,
        sound: true,
    };

    @observable editorMedia = false;




    @action updateFile = (update) => {
        this.file = {
            ...this.file,
            ...update,
        };
    }

    fileBlobCover = false;
    fileBlobCoverTime = false;
}

export default editorStore;
