import React from 'react';

import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ReactDOM from "react-dom";
import {Picker} from "emoji-mart";

@inject('editorStore', 'initialStore')
@observer
class MediaRedBSlayderEmoje extends React.Component {

    @observable openMenu = false;

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.window = React.createRef();
        this.openHistory = false;
    }

    render() {

        const initialStore = this.props.initialStore;

        if (this.openMenu && !this.openHistory) {
            this.updateStatusCloseFon();
            this.openHistory = true;
        }

        if (!this.openMenu && this.openHistory) {
            this.updateStatusCloseFon();
            this.openHistory = false;
        }


            let EmojeBox = <Picker
                set='apple'
                color="#7b5c9b"
                showSkinTones={false}
                showPreview={false}
                i18n={initialStore.text.emoji}
                onSelect={this.pickerOnSelect}
                ref={this.PickerRef}
            />;


        let classes = 'emoje-slayder';

        if (this.openMenu) {
            classes = 'emoje-slayder open-emoje-slayder';
        }

        return (
            <>
                <div className="item-type">
                    <button type="button" className="btn red-button-emoje" onClick={this.open}></button>

                    <div className={classes} ref={this.window}>
                        {EmojeBox}
                    </div>

                </div>
            </>
        );
    }

    pickerOnSelect = (emoje) => {
        this.fabric.elementRedSlayder(false,false, emoje.native);
        this.openMenu = false;
        //emoje.native
    }

    open = () => {
        if (!this.openMenu) {
            this.openMenu = true;
        }
    }

    updateStatusCloseFon = () => {
        if (this.openMenu) {
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {
        console.log(1743);
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                if (this.openMenu !== false) {
                    this.openMenu = false;
                }
            }
        } catch(error) {
            return null;
        }
    }
}

export default MediaRedBSlayderEmoje;