import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

import Time from "../../../model/time";


@inject('initialStore')
@observer
class Timeonline extends React.Component {

    @observable time = 0;

    constructor (props) {
        super(props);

        this.time = Time.getTimeOnlint(this.props.timeZone);
    }

    render() {



        return this.time;
    }

    componentDidMount() {

        this.timerID = setInterval(() => {

            this.time = Time.getTimeOnlint(this.props.timeZone);

        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

}

export default Timeonline;