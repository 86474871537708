import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ButtonLoad from "../../elements/buttonLoad";
import Select from "../../elements/select";
import Button from "../../elements/button";

export default
@inject('initialStore')
@observer
class TemplateModalAddAccountInstagramVerificationTip extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;

        let render = <>
            <span className="title-box center">{text[17]}</span>
            <div className="login">

                <p className="login-help">{text[24]}</p>

                <div className="form-box">
                    <div className="form-group">
                        <span className="input-label">{text[25]}</span>
                        <Select load={this.props.load} options={this.props.options} action={this.props.action} nonTopValueElement>{this.props.value}</Select>
                    </div>
                </div>
            </div>

            <div className="more-btn btns-box">
                <ButtonLoad color action={this.props.actionOK}>send</ButtonLoad>
                <Button load={this.props.load} action={this.props.close}>cancellation</Button>
            </div>
        </>;

        return render;
    }
}