import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

import APIemployees from "../../../api/employees";
import EmployeesForm from "./EmployeesForm";

import {getEmployees} from "../../../actions/account/employees";
import AccountEmployeesElement from "../../../templates/account/employees/element";
import AccountEmployeesIndex from "../../../templates/account/employees";
import LoaderComponent from "../../../templates/loaderComponent";
import AccountPatternsIndex from "../../../templates/account/patterns";

@inject('accountsStore', 'menuStore', 'notificationStore', 'initialStore')
@observer
class Employees extends React.Component {

    @observable form = false;

    constructor(props) {
        super(props);

        this.delite = this.delite.bind(this);
        this.edit = this.edit.bind(this);
        this.add = this.add.bind(this);
        this.addBek = this.addBek.bind(this);
    }

    render() {

        const accountsStore = this.props.accountsStore;
        const accounts = accountsStore.active;
        const employees = accountsStore.list[accounts].employees.list;

        let employeesJSX = <LoaderComponent/>;

        if (this.form) {
            employeesJSX = <EmployeesForm
                actionBek={this.addBek}
                id={this.form}
            />;
        }else if (employees !== null) {
            let list = employees.map(item => {
                return <AccountEmployeesElement
                    {...item}
                    actionDelite={(resolve) => {this.delite(item.id, resolve)}}
                    actionEdit={() => {this.edit(item.id)}}
                />;
            });
            employeesJSX = <AccountEmployeesIndex
                account={accountsStore.list[accountsStore.active].name}
                typeAccount={accountsStore.list[accountsStore.active].tip}
                imgAccount={accountsStore.list[accountsStore.active].img}
                addAccount={this.add}
            >{list}</AccountEmployeesIndex>;
        }

        return <>
            {employeesJSX}
        </>;
    }

    delite ( id, resolve ) {
        const accountsStore = this.props.accountsStore;
        const notificationStore = this.props.notificationStore;
        const accounts = accountsStore.active;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        const employees = accountsStore.list[accounts].employees.list;
        const employeesBeckap = employees;
        let newList = [];
        for (const element of employees) {
            if (element.id !== id) {
                newList.push({...element});
            }
        }
        accountsStore.list[accounts].employees.list = newList;
        resolve(true);

        APIemployees.del(id).then((data) => {
            if(data.status) {
                this.update();
            } else {
                accountsStore.list[accounts].employees.list = employeesBeckap;
                notificationStore.add(text[28], text[117]);
            }
        }).catch((e) => {
            accountsStore.list[accounts].employees.list = employeesBeckap;
            notificationStore.add(text[28], text[117]);
        });
    }

    edit (id) {
        this.form = id;
    }
    add () {
        this.form = true;
    }
    addBek () {
        this.form = false;
    }

    componentDidMount() {
        this.props.menuStore.updateActiveComponentAccount('employees');
        this.update();
    }

    componentDidUpdate() {
        this.update();
    }

    update() {

        const accountsStore = this.props.accountsStore;
        const accounts = accountsStore.active;

        const idAccount = accountsStore.list[accounts].id;
        const hesh = accountsStore.list[accounts].employees.hesh;
        const load = accountsStore.list[accounts].employees.load;

        if (!load) {
            getEmployees(idAccount, accounts, hesh);
        }
    }
}



export default Employees;