import React from "react";

const ru = {
    0: 'Чат поддержки',
    1: 'FAQ',
    2: 'Загрузка...',
    3: 'Идет загрузка диалогов',
    4: 'Чат поддержки',
    5: 'Напишите, мы с радостью поможем Вам',
    6: (name) => <><b>Оператор {name}</b><br/>присоединился к чату</>,
    7: 'Оператор печатает...',
    8: 'Введите ваше сообщение…',
    9: 'Введите сообщение…',


}

const en = {
    0: 'Support Chat',
    1: 'FAQ',
    2: 'Loading...',
    3: 'Loading dialogs',
    4: 'Support Chat',
    5: 'Write, we will be happy to help you',
    6: (name) => <><b>Operator {name}</b><br/>joined the chat</>,
    7: 'The operator is typing ...',
    8: 'Enter your message…',
    9: 'Enter your message…',
}

const help = {
    ru: ru,
    en: en,
}

export default help;