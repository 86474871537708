import React from 'react';
import {inject, observer} from "mobx-react";

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
import 'moment/locale/ru';

import Time from "../../../model/time";
import Select from "../../../templates/elements/select";
import Input from "../../../templates/elements/input";
import PostTimeBox from "../../../templates/post/timeBox";
import PostTimeBoxPro from "../../../templates/post/timeBoxPro";

@inject('mediaStore')
@inject('accountsStore')
@observer
class AccountsData extends React.Component{

    constructor (props) {
        super(props);

        this.handleDayChange = this.handleDayChange.bind(this);
        this.timePro = this.timePro.bind(this);
        this.timeDefolt = this.timeDefolt.bind(this);
        this.activateDelite = this.activateDelite.bind(this);
        this.activateDeliteInt = this.activateDeliteInt.bind(this);
        this.activateDeliteTip = this.activateDeliteTip.bind(this);
    }

    render() {
        const mediaStore = this.props.mediaStore;
        const accountsStore = this.props.accountsStore;

        const accounts = accountsStore.list.slice();
        const accountsOK = mediaStore.accounts.slice();



        let viev;

        if (mediaStore.timePro) {
            viev = this.timePro();
        } else {
            viev = this.timeDefolt();
        }






        let render = <>
            {viev}
        </>;

        return render;
    }

    timeDefolt () {
        const mediaStore = this.props.mediaStore;
        const accountsStore = this.props.accountsStore;

        const accounts = accountsStore.list.slice();
        const accountsOK = mediaStore.accounts.slice();

        if (!accountsOK.length) {
            return null;
        }

        const key = 0;

        const date = Time.convertUnixToDate(accountsOK[key].time);

        const socSetEnter = {
            instagram: 0,
            vk: 0,
            fb: 0,
            ok: 0,
            tg: 0,
            twitter: 0,
            fbInstagram: 0,
        };

        let minTime = false;

        for (const accountsOKOne of accountsOK) {
            for (let keys in accounts) {
                if (accounts[keys].id === accountsOKOne.id) {
                    if (accounts[keys].tip === 'fb') {
                        socSetEnter.fb++;
                    } else if (accounts[keys].tip === 'tg') {
                        socSetEnter.tg++;
                    } else if (accounts[keys].tip === 'ok') {
                        socSetEnter.ok++;
                    } else if (accounts[keys].tip === 'vk') {
                        socSetEnter.vk++;
                    } else if (accounts[keys].tip === 'instagram') {
                        socSetEnter.instagram++;
                    } else if (accounts[keys].tip === 'twitter') {
                        socSetEnter.twitter++;
                    } else if (accounts[keys].tip === 'fb-instagram') {
                        socSetEnter.fbInstagram++;
                    }

                    if (accounts[keys].rights.rate) {
                        if (!minTime) {
                            minTime = accounts[keys].rights.rate;
                        } else {
                            if (accounts[keys].rights.rate < minTime) {
                                minTime = accounts[keys].rights.rate;
                            }
                        }
                    }

                }
            }
        }

        let arhiveStatus = true;
        let deliteStatus = true;
        if (socSetEnter.vk || socSetEnter.fb || socSetEnter.ok || socSetEnter.tg || socSetEnter.twitter || socSetEnter.fbInstagram) {
            arhiveStatus = false;

            ///accountsOK[key].del.status
        }
        if (socSetEnter.fbInstagram) {
            deliteStatus = false;
        }

        if (mediaStore.type === 'igtv' || mediaStore.type === 'editIgtv') {
            arhiveStatus = false;
        }

        if (mediaStore.type === 'story' || mediaStore.type === 'editStory') {
            arhiveStatus = false;
            deliteStatus = false;
        }

        if (mediaStore.type === 'reels' || mediaStore.type === 'editReels') {
            arhiveStatus = false;
            deliteStatus = true;
        }

        const viev = (
            <PostTimeBox
                selectedDay={accountsOK[key].time}
                ref={this.ref}
                onDayChange={(dey) => {this.handleDayChange(dey, accountsOK[key].time, accountsOK[key].id)}}
                date={date.date}
                hAction={(e) => {this.onChangeHear(accountsOK[key].time, e, accountsOK[key].id)}}
                hOptions={this.generatorOptionJSON(23)}
                hValue={Number(date.h)}
                mAction={(e) => {this.onChangeMin(accountsOK[key].time, e, accountsOK[key].id)}}
                mOptions={this.generatorOptionJSON(59)}
                mValue={Number(date.m)}
                arhiveStatus={arhiveStatus}
                deliteStatus={deliteStatus}
                delStatus={accountsOK[key].del.status}
                delAction={() => {this.activateDelite(key, 2)}}
                arhiveAction={() => {this.activateDelite(key, 1)}}
                delInputAction={(value) => {this.activateDeliteInt(key, value)}}
                delInputValue={accountsOK[key].del.int}
                delSelectAction={(value) => {this.activateDeliteTip(key, value)}}
                delSelectValue={Number(accountsOK[key].del.tip)}
                minTime={minTime}
            />
        );

        return viev;
    }

    timePro () {

        const mediaStore = this.props.mediaStore;
        const accountsStore = this.props.accountsStore;

        const accounts = accountsStore.list.slice();
        const accountsOK = mediaStore.accounts.slice();

        let accountsElements = [];

        for (let key in accountsOK) {

            let idDB = 0;

            let minTime = false;

            let arhiveStatus = false;
            let delStatus = accountsOK[key].del.status;
            for (let keys in accounts) {
                if (accountsOK[key].id === accounts[keys].id) {
                    idDB = keys;
                    if (accounts[keys].tip === 'instagram') {
                        arhiveStatus = true;
                    }
                    if (accounts[keys].tip === 'fb-instagram'){
                        delStatus = false;
                    }

                    if (accounts[keys].rights.rate) {
                        if (!minTime) {
                            minTime = accounts[keys].rights.rate;
                        } else {
                            if (accounts[keys].rights.rate < minTime) {
                                minTime = accounts[keys].rights.rate;
                            }
                        }
                    }

                }
            }





            const date = Time.convertUnixToDate(accountsOK[key].time);

            accountsElements.push(
                <PostTimeBoxPro
                    img={accounts[idDB].img}
                    name={accounts[idDB].name}
                    ref={this.ref}
                    selectedDay={accountsOK[key].time}
                    onDayChange={(dey) => {this.handleDayChange(dey, accountsOK[key].time, accountsOK[key].id)}}
                    value={date.date}
                    hAction={(e) => {this.onChangeHear(accountsOK[key].time, e, accountsOK[key].id)}}
                    hOptions={this.generatorOptionJSON(23)}
                    hValue={Number(date.h)}
                    mAction={(e) => {this.onChangeMin(accountsOK[key].time, e, accountsOK[key].id)}}
                    mOptions={this.generatorOptionJSON(59)}
                    mValue={Number(date.m)}
                    arhiveStatus={arhiveStatus}
                    delStatus={delStatus}
                    delAction={() => {this.activateDelite(key, 2)}}
                    arhiveAction={() => {this.activateDelite(key, 1)}}
                    inputAction={(value) => {this.activateDeliteInt(key, value)}}
                    inputValue={accountsOK[key].del.int}
                    selectAction={(value) => {this.activateDeliteTip(key, value)}}
                    selectValue={Number(accountsOK[key].del.tip)}
                    key={key}
                    minTime={minTime}
                />
            );

        }

        let viev = accountsElements.map((item) => {
            return item;
        });

        return viev;
    }

    activateDeliteTip(id, value) {

        const mediaStore = this.props.mediaStore;

        const newTip = value;

        const idAccount = mediaStore.accounts[id].id;
        const status = mediaStore.accounts[id].del.status;
        let tip = mediaStore.accounts[id].del.tip;
        let int = mediaStore.accounts[id].del.int;

        tip = /\d+/.test(Number(newTip)) ? newTip : tip;

        if (tip < 0 || tip > 3) {
            tip = 2;
        }

        const account = {
            del: {
                status: status,
                int: int,
                tip: tip
            }
        };
        if (mediaStore.timePro) {
            mediaStore.actionAccountUpdate(idAccount, account);
        } else {
            const accountsOK = mediaStore.accounts.slice();
            for (let key in accountsOK) {
                mediaStore.actionAccountUpdate(accountsOK[key].id, account);
            }
        }
    }

    activateDeliteInt(id, value) {

        const mediaStore = this.props.mediaStore;

        const newInt = value;

        const idAccount = mediaStore.accounts[id].id;
        const status = mediaStore.accounts[id].del.status;
        let tip = mediaStore.accounts[id].del.tip;
        let int = mediaStore.accounts[id].del.int;

        int = /\d+/.test(Number(newInt)) ? newInt : int;

        const account = {
            del: {
                status: status,
                int: int,
                tip: tip
            }
        };

        if (mediaStore.timePro) {
            mediaStore.actionAccountUpdate(idAccount, account);
        } else {
            const accountsOK = mediaStore.accounts.slice();
            for (let key in accountsOK) {
                mediaStore.actionAccountUpdate(accountsOK[key].id, account);
            }
        }

    }

    activateDelite(id, statusNew) {

        const mediaStore = this.props.mediaStore;

        const idAccount = mediaStore.accounts[id].id;
        const status = mediaStore.accounts[id].del.status;
        let int = mediaStore.accounts[id].del.int;
        let tip = mediaStore.accounts[id].del.tip;

        if (statusNew === status) {
            statusNew = false;
            int = 1;
            tip = 2;
        }

        const account = {
            del: {
                status: statusNew,
                int: int,
                tip: tip
            }
        };

        if (mediaStore.timePro) {
            mediaStore.actionAccountUpdate(idAccount, account);
        } else {
            const accountsOK = mediaStore.accounts.slice();
            for (let key in accountsOK) {
                mediaStore.actionAccountUpdate(accountsOK[key].id, account);
            }
        }

        if (document.activeElement) {
            if (document.activeElement.localName === 'textarea' || document.activeElement.localName === 'input') {
                document.activeElement.blur();
            }
        }

    }

    generatorOption (num) {
        let jsx = [];

        for (let i = 0; i <= num; i++) {
            jsx[i]= <option value={i}>{i.toString().padStart(2,0)}</option>;
        }

        return jsx;

    }

    generatorOptionJSON (num) {
        let jsx = [];

        for (let i = 0; i <= num; i++) {
            jsx.push({key: i, value: i.toString().padStart(2,0)});
        }

        return jsx;

    }

    handleDayChange(day, unix, id) {
        const mediaStore = this.props.mediaStore;

        const time = Time.convertDateToUnix(day, unix);

        if (mediaStore.timePro) {

            this.props.mediaStore.actionAccountUpdate(id, {time: time});
        } else {

            const accountsOK = mediaStore.accounts.slice();

            for (let key in accountsOK) {
                this.props.mediaStore.actionAccountUpdate(accountsOK[key].id, {time: time});
            }
        }

    }

    onChangeHear(unix, e, id) {

        const mediaStore = this.props.mediaStore;

        const hear = e;

        const time = Time.convertUnixAndHearMinToUnix(unix, hear, false);

        if (mediaStore.timePro) {
            this.props.mediaStore.actionAccountUpdate(id, {time: time});
        } else {
            const accountsOK = mediaStore.accounts.slice();
            for (let key in accountsOK) {
                this.props.mediaStore.actionAccountUpdate(accountsOK[key].id, {time: time});
            }
        }

    }

    onChangeMin(unix, e, id) {
        const mediaStore = this.props.mediaStore;

        const min = e;

        const time = Time.convertUnixAndHearMinToUnix(unix, false, min);


        if (mediaStore.timePro) {
            this.props.mediaStore.actionAccountUpdate(id, {time: time});
        } else {
            const accountsOK = mediaStore.accounts.slice();
            for (let key in accountsOK) {
                this.props.mediaStore.actionAccountUpdate(accountsOK[key].id, {time: time});
            }
        }
    }



}

export default AccountsData;
