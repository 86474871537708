import React from 'react';

import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ReactDOM from "react-dom";

@inject('editorStore', 'initialStore')
@observer
class MediaRedBElementTemplate extends React.Component {

    @observable openMenu = false;

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;


        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.window = React.createRef();

        this.openHistory = false;

    }

    render() {
        const initialStore = this.props.initialStore;
        const type = this.props.type;
        const text = initialStore.text.addPost;

        if (this.openMenu && !this.openHistory) {
            this.updateStatusCloseFon();
            this.openHistory = true;
        }

        if (!this.openMenu && this.openHistory) {
            this.updateStatusCloseFon();
            this.openHistory = false;
        }



        let classes = this.openMenu ? "event-menu g-center v-bottom show botton-element-color-box" : "event-menu g-center v-bottom show botton-element-color-box event-menu-none";

        let fonts = [
            {name: text[188], id: '0'},
            {name: text[189], id: '1'},
            {name: text[190], id: '2'},
        ];

        if (type === 'url') {
            fonts = [
                {name: text[188], id: '0'},
                {name: text[215], id: '1'},
                {name: text[190], id: '2'},
            ];
        }


        const elemensColors = [];
        for (let element of fonts) {
            elemensColors.push(<span className="event-item" onClick={() => this.template(element.id)}>{element.name}</span>);
        }

        let icon = 'btn red-button-color-fon';
        if (this.props.text) {
            icon = 'btn red-button-color-text';
        }

        return (
            <>
                <div className="item-type">
                    <button type="button" className={icon} onClick={this.open}></button>

                    <div className={classes}>
                        <div className="event-colors" ref={this.window}>
                            {elemensColors}
                        </div>
                    </div>

                </div>
            </>
        );
    }

    template = (temp) => {
        this.fabric.elementTemplate(false, temp);
        this.openMenu = false;
    }

    open = () => {
        if (!this.openMenu) {
            this.openMenu = true;
        }
    }



    updateStatusCloseFon = () => {
        if (this.openMenu) {
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {

        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                if (this.openMenu !== false) {
                    this.openMenu = false;
                }
            }
        } catch(error) {
            return null;
        }
    }
}

export default MediaRedBElementTemplate;
