import React from 'react';
import {inject, observer} from "mobx-react";
import TitleH1 from "../elements/text/titleH1";
import Input from "../elements/input";
import ButtonLoad from "../elements/buttonLoad";
import {CopyToClipboard} from "react-copy-to-clipboard";

export default
@inject('initialStore')
@observer
class SettingsNotifications extends React.Component {

    render() {

        const initialStore = this.props.initialStore;
        const text = initialStore.text.settings;

        if (initialStore.templates === 'desktop') {

            return (
                <div className="settings">
                    <div className="filters top">
                        <div className="filter-flex">
                            <div className="filter-check">
                                {this.props.buttonType}
                            </div>
                        </div>
                    </div>


                    <TitleH1>{this.props.type === 'offline' ? text.serviceNotifications : text.popupWindows}</TitleH1>

                    <div className="settings-notifications">
                        <table className="table-notifications">
                            <thead>
                            <tr>
                                <th>{text.notificationType}</th>
                                <th>{this.props.type === 'offline' ? text.post : text.window}</th>
                                <th>{this.props.type === 'offline' ? text.telegram2 : text.sound}</th>
                                {/*{this.props.type === 'offline' ? <th>{text.telephone}</th> : null}*/}
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.type === 'offline' ? this.props.contentOffline : this.props.contentOnline}
                            </tbody>
                        </table>
                    </div>

                    <div className="more-btn bottom">
                        <ButtonLoad action={this.props.save} color={true}>save</ButtonLoad>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="settings">
                    <div style={{margin:'10px 0 0 0'}} className="filters top">
                        <div className="filter-flex">
                            <div className="filter-check">
                                {this.props.buttonType}
                            </div>
                        </div>
                    </div>




                    <div className="settings-notifications">
                        <table className="table-notifications">
                            <thead>
                            <tr>
                                <th>{text.notificationType}</th>
                                <th>{this.props.type === 'offline' ? text.post : text.window}</th>
                                <th>{this.props.type === 'offline' ? text.telegram2 : text.sound}</th>
                                {/*{this.props.type === 'offline' ? <th>{text.telephone}</th> : null}*/}
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.type === 'offline' ? this.props.contentOffline : this.props.contentOnline}
                            </tbody>
                        </table>
                    </div>
                    <div className="more-btn bottom">
                        <ButtonLoad action={this.props.save} color={true}>save</ButtonLoad>
                    </div>
                </div>
            );
        }

    }
}
