import {action, observable} from "mobx";

class notificationStore {

    @observable elements = [];

    @action add (name = '', text = '', sek= 5, buttonName = false, buttonClick = false) {
        const elements = [...this.elements];
        elements.push({
            name: name,
            text: text,
            sekStart: 0,
            sekEnd: sek,
            buttonName: buttonName,
            buttonClick: buttonClick,
        });

        this.elements = elements;
    }

    @action del (id) {

        let elements = [...this.elements.slice()];
        let elementsNew = [];
        for (const elementKey in elements) {
            if (id !== elementKey) {
                elementsNew.push({...elements[elementKey]})
            }
        }
        this.elements = elementsNew;
    }
}

export default notificationStore;