import React from 'react';
import {inject, observer} from "mobx-react";

import APIsettings from "../../api/settings";
import {observable} from "mobx";
import LoaderComponent from "../../templates/loaderComponent";
import SettingsNotificationsElement from "../../templates/settings/notificationsElements";
import SettingsNotifications from "../../templates/settings/notifications";
import AccountElementsFilterElement from "../../templates/account/elements/filterElement";



@inject('initialStore', 'notificationStore')
@observer
class Notifications extends React.Component {

    @observable notifications = false;
    @observable notificationsOnline = false
    @observable button = true;

    constructor (props) {
        super(props);

        this.onClickElement = this.onClickElement.bind(this);
        this.save = this.save.bind(this);

    }

    render() {

        const initialStore = this.props.initialStore;
        const text = initialStore.text.settings;

        let content = '';
        let button = null;
        if (!this.notifications) {
            return <LoaderComponent>{text.loading}</LoaderComponent>;
        } else {
            let elementsOffline = [];
            let elementsOnline = [];

            for (const num in this.notifications) {

                let name = '';

                let actionPhone = () => {this.onClickElement(num, 'sms')};

                if (num === '1') {
                    name = text.postPublished;
                    actionPhone = false;
                } else if (num === '2') {
                    name = text.posFailedToPublish;
                } else if (num === '3') {
                    name = text.postDeletedSchedule;
                    actionPhone = null;
                } else if (num === '4') {
                    name = text.postCouldNotDeletedOnSchedule;
                } else if (num === '5') {
                    name = text.postArchivedSchedule;
                    actionPhone = null;
                } else if (num === '6') {
                    name = text.postCouldNotArchivedOnSchedule;
                } else if (num === '7') {
                    name = text.accountAuthorizationError;
                } else if (num === '8') {
                    name = text.postAddedByLink;
                    actionPhone = null;
                }

                elementsOffline.push(<SettingsNotificationsElement
                        name={name}
                        actionEmail={() => {this.onClickElement(num, 'mail')}}
                        email={this.notifications[num].mail}
                        actionTelegram={() => {this.onClickElement(num, 'telegram')}}
                        telegram={this.notifications[num].telegram}
                        actionPhone={actionPhone}
                        phone={this.notifications[num].sms}
                    />
                );
            }

            for (const num in this.notificationsOnline) {

                let name = '';

                if (num === '1') {
                    name = text.direct;
                }

                elementsOnline.push(<SettingsNotificationsElement
                        name={name}
                        actionEmail={() => {this.onClickElementOnline(num, 'win')}}
                        email={this.notificationsOnline[num].win}
                        actionTelegram={() => {this.onClickElementOnline(num, 'sound')}}
                        telegram={this.notificationsOnline[num].sound}
                        actionPhone={false}
                        phone={false}
                    />
                );
            }

            let bitton1 = <AccountElementsFilterElement
                name={text.notifications}
                active={this.props.match.params.type === 'offline' ? true:false}
                url={'/settings/notifications/offline'}
            />;

            let bitton2 = <AccountElementsFilterElement
                name={text.popupWindows}
                active={this.props.match.params.type === 'online' ? true:false}
                url={'/settings/notifications/online'}
            />;

            let buttonType = <>
                {bitton1}
                {bitton2}
            </>;

            return <SettingsNotifications
                type={this.props.match.params.type}
                buttonType={buttonType}
                save={(e) => this.save(e)}
                contentOffline={elementsOffline}
                contentOnline={elementsOnline}
            />;

        }
    }

    save(resolve) {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;

        const text = initialStore.text.settings;
        const textCode = initialStore.text.code;

        APIsettings.setNotifications(this.notifications, this.notificationsOnline).then((data) => {
            if (data.status) {
                notificationStore.add(text.notificationsSaved);

                initialStore.notification[1].win = this.notificationsOnline[1].win;
                initialStore.notification[1].sound = this.notificationsOnline[1].sound;
            } else {
                notificationStore.add(textCode[88]);
            }
        }).catch((e)=> {
            notificationStore.add(textCode[88]);
        }).finally(() => {
            resolve();
        });

    }

    onClickElement (id, type) {


        for (const num in this.notifications) {

            if (num === id) {
                if (type === 'mail') {
                    this.notifications[num].mail = !this.notifications[num].mail;
                } else if (type === 'telegram') {
                    this.notifications[num].telegram = !this.notifications[num].telegram;
                } else if (type === 'sms') {
                    this.notifications[num].sms = !this.notifications[num].sms;
                }
                break;
            }
        }

    }

    onClickElementOnline = (id, type) => {


        for (const num in this.notificationsOnline) {

            if (num === id) {
                if (type === 'win') {
                    this.notificationsOnline[num].win = !this.notificationsOnline[num].win;
                } else if (type === 'sound') {
                    this.notificationsOnline[num].sound = !this.notificationsOnline[num].sound;
                }
                break;
            }
        }

    }

    componentDidMount() {

        const initialStore = this.props.initialStore;

        const textCode = initialStore.text.code;

        APIsettings.getNotifications().then((data) => {
            if (data.status) {
                this.notifications = data.data;
                this.notificationsOnline = data.data_online;
            } else {
                alert(textCode[89]);
            }
        }).catch((e) => {
            alert(textCode[89]);
        })
    }

}

export default Notifications;