import React from 'react';
import {inject, observer} from "mobx-react";
import AccountDirectPosts from "../account/direct/posts";
import AccountDirectCreateMessage from "../account/direct/createMessage";
import {observable} from "mobx";
import Time from "../../model/time";
import APIdirect from "../../api/direct";

export default
@inject('initialStore')
@observer
class HelpChat extends React.Component {



    constructor(props) {
        super(props);


    }





    render() {
        const initialStore = this.props.initialStore;

        let activeID = 0;
        let nextLoad = this.props.nextLoad;
        let posts = this.props.message;

        if (initialStore.templates === 'desktop') {
            return <div className="chat">
                <div className="chat-dashboard">

                        <AccountDirectPosts
                            account={activeID}
                            messagesButton={this.props.messagesButton}
                            addMessagesButton={this.props.addMessagesButton}
                            scrollToMessagesButton={this.props.scrollToMessagesButton}
                            cursor={this.props.cursor}
                            cursorLoad={this.props.nextLoadItems}
                        >
                            {nextLoad}
                            {posts}
                        </AccountDirectPosts>

                        <AccountDirectCreateMessage
                            textOnChange={this.props.messageTextChange}
                            textValue={this.props.messageText}
                            sendingMessageText={this.props.sendingMessageText}
                            sendingMessageMedia={this.props.sendingMessageMedia}
                            scrollToMessagesButton={this.props.scrollToMessagesButton}
                            nonTest={true}
                        />

                </div>
            </div>;
        } else {
            return <div className="chat">
                <div className="chat-dashboard">

                    <AccountDirectPosts
                        account={activeID}
                        messagesButton={this.props.messagesButton}
                        addMessagesButton={this.props.addMessagesButton}
                        scrollToMessagesButton={this.props.scrollToMessagesButton}
                        cursor={this.props.cursor}
                        cursorLoad={this.props.nextLoadItems}
                    >
                        {nextLoad}
                        {posts}
                    </AccountDirectPosts>

                    <AccountDirectCreateMessage
                        textOnChange={this.props.messageTextChange}
                        textValue={this.props.messageText}
                        sendingMessageText={this.props.sendingMessageText}
                        sendingMessageMedia={this.props.sendingMessageMedia}
                        scrollToMessagesButton={this.props.scrollToMessagesButton}
                        nonTest={true}
                    />

                </div>
            </div>;
        }

    }
}

