import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ButtonLoad from "../../elements/buttonLoad";

//скришоты телеграм
import img1 from "../../../media/instruction/telegram/1.png";
import img2 from "../../../media/instruction/telegram/2.png";
import img3 from "../../../media/instruction/telegram/3.png";
import img4 from "../../../media/instruction/telegram/4.png";
import img5 from "../../../media/instruction/telegram/5.png";
import img6 from "../../../media/instruction/telegram/6.png";
import img7 from "../../../media/instruction/telegram/7.png";
import img8 from "../../../media/instruction/telegram/8.png";
import img9 from "../../../media/instruction/telegram/9.png";
import img10 from "../../../media/instruction/telegram/10.png";
import img11 from "../../../media/instruction/telegram/11.png";
import img12 from "../../../media/instruction/telegram/12.png";

export default
@inject('initialStore')
@observer
class TemplateModalAddAccountTg extends React.Component {

    @observable openInfo = false;

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;

        if (initialStore.templates === 'desktop') {

            let render = <>
                <span className="title-box center">{text[45]}</span>
                <div className="login">
                    <p className="login-help">{text[46]}</p>

                    <div className="form-box">
                        <div className="form-group">
                            <span className="input-label">{text[47]}</span>
                            <input autocomplete="off" onChange={(e) => {this.props.actionToken(e.target.value)}} className="form-control view-2" value={this.props.token}/>
                        </div>
                        <div className="form-group">
                            <span className="input-label">{text[48]}</span>
                            <input autocomplete="off" onChange={(e) => {this.props.actionChanel(e.target.value)}} className="form-control view-2" value={this.props.chanel}/>
                        </div>

                        <div className="login-proxies">
                            <span className="login-label label-proxies"
                                  onClick={() => this.openInfo = !this.openInfo}>{!this.openInfo ? text[49] : text[50]}</span>
                            {this.openInfo ?
                                <div className="cnt-proxies">
                                    {text[51]}
                                </div>
                                : null}
                        </div>
                    </div>
                </div>

                <div className="more-btn btns-box">
                    <ButtonLoad color action={this.props.actionOK}>add</ButtonLoad>
                    <button type="button" className="btn border btn-primary" onClick={this.props.close}>{text[32]}</button>
                </div>
            </>;

            return render;
        } else {
            let render = <>
                <span className="title-box center">{text[45]}</span>
                <div className="login">
                    <p className="login-help">{text[46]}</p>

                    <div className="form-box">
                        <div className="form-group">
                            <span className="input-label">{text[47]}</span>
                            <input autoComplete="off" onChange={(e) => {
                                this.props.actionToken(e.target.value)
                            }} className="form-control view-2" value={this.props.token}/>
                        </div>
                        <div className="form-group">
                            <span className="input-label">{text[48]}</span>
                            <input autoComplete="off" onChange={(e) => {
                                this.props.actionChanel(e.target.value)
                            }} className="form-control view-2" value={this.props.chanel}/>
                        </div>

                        <div className="login-proxies">
                            <span className="login-label label-proxies"
                                  onClick={() => this.openInfo = !this.openInfo}>{!this.openInfo ? text[49] : text[50]}</span>
                            {this.openInfo ?
                                <div className="cnt-proxies">
                                    {text[51]}
                                </div>
                                : null}
                        </div>
                    </div>
                </div>

                <div className="more-btn btns-box">
                    <ButtonLoad color action={this.props.actionOK}>add</ButtonLoad>
                    <button type="button" className="btn border btn-primary" onClick={this.props.close}>{text[32]}</button>
                </div>
            </>;


            return render;
        }
    }
}

