import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import style from "../../../component/menu/index.module.css";

export default
@inject('initialStore')
@observer
class MenuElement extends React.Component {

    constructor(props) {
        super(props);
        this.funs = this.funs.bind(this);
    }

    render() {
        const initialStore = this.props.initialStore;

        let notification = null;
        if (this.props.notification) {
            notification = <span className="title-item-count-element">{this.props.notification}</span>
        }

        if (initialStore.templates === 'desktop') {
            return <li>
                    <Link to={this.props.active? null:this.props.url} onClick={this.funs} className={this.props.active ? 'sidebar-nav__item is-h':'sidebar-nav__item'} style={this.props.active ? {cursor:'default'}:null}>
                        <span className="icon-item"><i className={'icon ' + this.props.iconClass}></i></span>
                        <span className="title-item">{this.props.name}</span>
                        {notification}
                    </Link>
                </li>;
        } else {
            return <li>
                <Link to={this.props.active? null:this.props.url} onClick={this.funs} className={this.props.active ? 'sidebar-nav__item is-h':'sidebar-nav__item'} style={this.props.active ? {cursor:'default'}:null}>
                    <span className="icon-item"><i className={'icon ' + this.props.iconClass}></i></span>
                    <span className="title-item">{this.props.name}</span>
                    {notification}
                </Link>
            </li>;
        }

    }

    funs () {
        this.props.fun(this.props.url);
    }
}

