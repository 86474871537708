import qs from "qs";
import React from "react";

import instance from "./base";


const APIaccountsTwitter = {

    getUrl () {
        return instance.post('/accountstwitter/url', qs.stringify({

        })).then(data => {
            return data;
        });
    },
}

export default APIaccountsTwitter;