import {action, observable} from "mobx";

class modalDateMobile {

    @observable open = false;

@observable onDayClick = false;
    @observable selectedDays = false;

    disabledDays = false;


    @action openActive = (type = true) => {
        this.open = type;
    }
}

export default modalDateMobile;