import React from 'react';
import {inject, observer} from "mobx-react";
import file, {getIconVideo} from "../../../model/file";
import APIsettings from "../../../api/settings";
import iconNonViev from "../../../media/svg/eye-slash-solid.svg";

export default
@inject('initialStore')
@observer
class AccountDirectAccount extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const initialStore = this.props.initialStore;

        let icon=[];

        if (this.props.img.length === 1) {
            icon.push(<img key={1} src={this.props.img[0]}/>);
        } else {
            icon=[];
            for (const element of this.props.img) {

                icon.push(<img key={element} src={element}/>);
            }
        }

        if (initialStore.templates === 'desktop') {



            return (
                <div className={this.props.active ? 'chat-user active':'chat-user'} onClick={this.props.click}>
                    <div className="inner-user">
                        <div className="img-user">
                            <span className="inner-img">
                                {icon}
                            </span>
                        </div>
                        <div className="top-user">
                            <span className="name-user">{this.props.name}</span>
                            <span className="time-user">{this.props.userNonViev ? <img src={iconNonViev} width={14} height={14} style={{margin: '-2px 3px 0px 0px'}} alt={this.props.userNonViev}/>:null}{this.props.time}</span>
                        </div>
                        <div className="bottom-user">
                            <p className="message-user">{this.props.text}</p>
                            {this.props.newMessage ? <span className="count-user">{this.props.newMessage}</span>:null}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div key={this.props.key} className={this.props.active ? 'chat-user active':'chat-user'} onClick={this.props.click}>
                    <div className="inner-user">
                        <div className="img-user">
                            <span className="inner-img">
                                {icon}
                            </span>
                        </div>
                        <div className="top-user">
                            <span className="name-user">{this.props.name}</span>
                            <span className="time-user">{this.props.userNonViev ? <img src={iconNonViev} width={14} height={14} style={{margin: '-2px 3px 0px 0px'}} alt={this.props.userNonViev}/>:null}{this.props.time}</span>
                        </div>
                        <div className="bottom-user">
                            <p className="message-user">{this.props.text}</p>
                            {this.props.newMessage ? <span className="count-user">{this.props.newMessage}</span>:null}
                        </div>
                    </div>
                </div>
            );
        }
    }

    onKeyUpEnter (event) {
        if (event.keyCode === 13 && event.shiftKey) {
            this.props.sendingMessageText();
        }
    }

    loafMedia () {
        //Удаляем прошлые файлы из формы
        this.fileInput.current.value = null;
        //открываем форму
        this.fileInput.current.click();
    }

    filOnChange() {
        this.handleFiles(this.fileInput.current.files);
    }

    handleFiles = async (files) => {
        const initialStore = this.props.initialStore;

        if (!files.length) {
            return false;
        }

        let filesNew = await file.FileMaxSizeAndTypeAndMaxFiles(files,1);


        const url = await file.createURL(filesNew[0]);
        let blob = null;
        let type = 'image';
        let proportions = 1;
        let cover = null;
        if (filesNew[0].type === 'image') {
            blob = await file.createBlobPre(url);
            proportions = await file.proportionsImage(url);
        } else {
            const videoTest = await file.videoTest(url);
            if (videoTest.status) {
                blob = filesNew[0].file;
                type = 'video';
                proportions = await file.proportionsVideo(url);
                const result = await getIconVideo(url, 0, true);
                cover = result.original;
            } else {
                alert(initialStore.text.code[17]);
            }
        }
        this.props.sendingMessageMedia(type, url, blob, proportions, cover);
    }
}