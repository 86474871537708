import React from 'react';
import {Redirect} from "react-router";

import Form from "./form";
import APIuser from "../../api/user";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

@inject('initialStore')
@observer
class Registration extends React.Component {

    @observable load = false;

    constructor (props) {
        super(props);

        this.state = {
            mail: '',
            password: '',
            inform:'',
        };

        this.mailOnChange = this.mailOnChange.bind(this);
        this.passwordOnChange = this.passwordOnChange.bind(this);
        this.okOnClick = this.okOnClick.bind(this);
    }

    mailOnChange (value) {
        this.setState({mail: value});
    }

    passwordOnChange (value) {
        this.setState({password: value});
    }

    okOnClick (e) {

        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        this.load = true;

        this.setState({inform: ''});
        APIuser.getRegistration(this.state.mail, this.state.password, initialStore.language, initialStore.platform).then(data => {

            this.load = false;

            if (!data.status) {
                this.setState({inform: <div>{text[104]}</div>});
            }else if (data.code) {
                this.setState({inform: <div>{text[28]}: {text[data.code]}</div>});
            }else if (!data.registration) {
                this.setState({inform: <div>{text[105]}</div>});
            } else {
                this.props.history.push(`/registration/${this.state.mail}`);
            }
        });
    }

    render() {
        return (<Form inform={this.state.inform}
                      okOnClick={this.okOnClick}
                      mail={this.state.mail}
                      mailOnChange={this.mailOnChange}
                      password={this.state.password}
                      passwordOnChange={this.passwordOnChange}
                      load={this.load}
                />);
    }

    componentDidMount() {

        APIuser.getLogin().then((result) => {
            if (result) {
                this.setState({inform: <Redirect to="/" />, loginTest: true});
            }
        }).catch((error) => {

        });
    }

}

export default Registration;