import React from 'react';
import {inject, observer} from "mobx-react";
import directLoad from "../../../../media/gif/directLoad.gif";


export default
@inject('initialStore')
@observer
class AccountDirectMessageReelShare extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {

            let boxMedia = '';
            if (this.props.mediaType) {
                if (this.props.mediaType === 'image') {
                    boxMedia = <img src={this.props.media} width={108} height={160}/>;
                } else {
                    boxMedia = <video src={this.props.media} width={108} height={160}/>;
                }
            }

            return (
                <div className="chat-message__history">
                    <p className="help-history">{this.props.username ? `Вы упомянули ${this.props.username} в своей истории`:`Упомянул(-а) вас в своей истории`}</p>
                    {this.props.mediaType ?
                    <>
                        <div className="wrap-history">
                            <div className="inner-history">
                                {this.props.mediaType ? boxMedia:null}
                                <time dateTime={this.props.time} className="chat-message__date">{this.props.time}</time>
                            </div>
                        </div>
                        {this.props.text ? <p className="help-history">{this.props.text}</p> : null}
                    </>
                    :<time style={{bottom: -6}} dateTime={this.props.time} className="chat-message__date">{this.props.time}</time>}
                </div>
            );
        } else {
            let boxMedia = '';
            if (this.props.mediaType) {
                if (this.props.mediaType === 'image') {
                    boxMedia = <img src={this.props.media} width={108} height={160}/>;
                } else {
                    boxMedia = <video src={this.props.media} width={108} height={160}/>;
                }
            }

            return (
                <div className="chat-message__history">
                    <p className="help-history">{this.props.username ? `Вы упомянули ${this.props.username} в своей истории`:`Упомянул(-а) вас в своей истории`}</p>
                    {this.props.mediaType ?
                        <>
                            <div className="wrap-history">
                                <div className="inner-history">
                                    {this.props.mediaType ? boxMedia:null}
                                    <time dateTime={this.props.time} className="chat-message__date">{this.props.time}</time>
                                </div>
                            </div>
                            {this.props.text ? <p className="help-history">{this.props.text}</p> : null}
                        </>
                        :<time style={{bottom: -6}} dateTime={this.props.time} className="chat-message__date">{this.props.time}</time>}
                </div>
            );
        }
    }

}