import React from 'react';
import HeaderBox from "../../templates/header/box";
import HeaderBoxLiElement from "../../templates/header/boxLIElement";
import {inject, observer} from "mobx-react";

@inject('initialStore', 'chatStore')
@observer
class HeaderHelp extends React.Component {



    render() {
        const initialStore = this.props.initialStore;
        const chatStore = this.props.chatStore;
        const text = initialStore.text.help;

        let activeElementName = text.basicSettings;

        if (this.props.location.pathname === '/help') {
            activeElementName = text[0];
        } else if (this.props.location.pathname === '/help/faq') {
            activeElementName = text[1];
        }

        let newMessage = '';
        let name = text[0];
        if (chatStore.newMessage) {
            name = text[0] + ' ' +chatStore.newMessage;
        }
        return <HeaderBox activeElementName={activeElementName}>
            <HeaderBoxLiElement
                url={'/help'}
                active={this.props.location.pathname === '/help' ? true: false}
                name={name}/>
            {/*<HeaderBoxLiElement
                url={'/help/faq'}
                active={this.props.location.pathname === '/help/faq' ? true: false}
                name={text[1]}/>*/}
        </HeaderBox>;
    }

}

export default HeaderHelp;