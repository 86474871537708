import React from 'react';
import {inject, observer} from "mobx-react";
import ReactDOM from "react-dom";
import {observable} from "mobx";

@inject('modalSearhUrlPost', 'initialStore')
@observer
class ModalSearhUrlPost extends React.Component{

    @observable category = 0;

    constructor (props) {
        super(props);

        this.close = this.close.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.window = React.createRef();


        this.open = false;
    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.post;

        const modalSearhUrlPost = this.props.modalSearhUrlPost;

        if (!modalSearhUrlPost.open) {

            if (this.open) {
                this.open=false;
                this.updateStatusCloseFon();
            }

            return null;
        }

        if (!this.open) {
            this.open=true;
            this.updateStatusCloseFon();
        }

        const content = (
            <div className="post-save-tabs">
                {text[33]}
            </div>
        );

        let render = <>
                <div className="info-wrap">
                    <div className="info-scroll">
                        <div className="info-box-wrap">
                            <div className="info-box chief width-2" ref={this.window}>
                                <span onClick={this.close} className="close-box"><i className="icon icon-close-info"></i></span>
                                <span className="title-box">{text[32]}</span>
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-overlay"></div>
            </>;

        return render;
    }

    updateStatusCloseFon = () => {
        if (this.open) {
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }


    close () {
        const modalSearhUrlPost = this.props.modalSearhUrlPost;
        modalSearhUrlPost.open = false;
    }


    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                const modalSearhUrlPost = this.props.modalSearhUrlPost;

                if (modalSearhUrlPost.open) {
                    modalSearhUrlPost.open = false;
                }
            }
        } catch(error) {
            return null;
        }
    }
}

export default ModalSearhUrlPost;