import React from 'react';
import {inject, observer} from "mobx-react";
import ReactDOM from "react-dom";
import APIuser from "../../api/user";
import {observable} from "mobx";

@inject('initialStore', 'notificationStore')
@observer
class ModalExit extends React.Component{

    @observable marginTopMobile = 0;

    constructor (props) {
        super(props);

        this.close = this.close.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.window = React.createRef();
        this.refInfoBoxWrap = React.createRef();

        this.open = false;
    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        if (initialStore.modalExitOpen === false) {

            if (this.open) {
                this.open=false;
                this.updateStatusCloseFon();
            }

            return null;
        }

        if (initialStore.modalExitOpen !== false) {
            this.open=true;
            this.updateStatusCloseFon();
        }

        const content = <div className="delMediaFile">
            <div className="modal-inner">
                <span className="title-modal">{text[127]}</span>
                <p className="desc-modal">{text[128]}</p>
                <div className="more-btn">
                    <button type="button" className="btn btn-primary" onClick={this.exit}>{text[129]}</button>
                    <button type="button" className="btn border btn-primary" onClick={this.close}>{text[130]}</button>
                </div>
            </div>
        </div>;

        let styleInfoBoxWrap = null;
        if (initialStore.templates !== 'desktop' && this.marginTopMobile > 0) {
            styleInfoBoxWrap = {marginTop: this.marginTopMobile + 'px'};
        }

        let render = <>
                <div className="info-wrap">
                    <div className="info-scroll">
                        <div className="info-box-wrap" ref={this.refInfoBoxWrap} style={styleInfoBoxWrap}>
                            <div className="info-box chief width-4" ref={this.window}>
                                <span onClick={this.close} className="close-box"><i className="icon icon-close-info"></i></span>
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-overlay"></div>
            </>;

        return render;
    }

    componentDidUpdate() {
        const initialStore = this.props.initialStore;
        if (!this.marginTopMobile) {

            if (this.refInfoBoxWrap.current) {
                this.marginTopMobile = Math.round((initialStore.height/2) - (this.refInfoBoxWrap.current.clientHeight/2));
            }


        }
    }

    exit = () => {
        const initialStore = this.props.initialStore;
        const id = initialStore.modalExitOpen;

        APIuser.getExit().then();

        initialStore.modalExitOpen=false;

    }

    updateStatusCloseFon = () => {

        const initialStore = this.props.initialStore;

        let type = 'click';
        if (initialStore.templates !== 'desktop') {
            type = 'touchend';
        }
        if (this.open) {
            document.addEventListener(type, this.handleClickOutside, false);
        } else {
            document.removeEventListener(type, this.handleClickOutside, false);
        }
    }


    close () {
        const initialStore = this.props.initialStore;
        initialStore.modalExitOpen = false;
    }


    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {

                const initialStore = this.props.initialStore;

                if (initialStore.modalExitOpen !== false) {
                    initialStore.modalExitOpen = false;
                }
            }
        } catch(error) {
            return null;
        }
    }

}

export default ModalExit;