import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import style from "../../../component/menu/index.module.css";

export default
@inject('initialStore', 'menuStore', 'accountsStore')
@observer
class MenuAccount extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <li>
                <Link to={this.props.url} onClick={this.linkOnClick} className={this.props.active ? 'sidebar-user is-h':'sidebar-user'} style={this.props.url ? null:{cursor: 'default'}}>
                    <div className="inner-user">
                        <div className="img-user">
                            <span className="social-user"><img src={this.props.imgSocSet}/></span>
                            <span className="inner-img"><img src={this.props.avatar}/></span>
                        </div>
                        <span className="name-user">{this.props.name}</span>

                        {this.props.error ?
                        <i className="icon icon-user-error" style={this.props.active ? {marginRight: 30}:null}></i>
                        :null}

                        {this.props.direct ? <Link to={this.props.urlDirect}><i className="icon icon-user-message" style={{position: 'absolute', left: '200px', top: '8px'}}></i></Link>:null}

                        <span style={this.props.post === 0 ? {display: 'none'}:null} className="count-user">{this.props.buttomSetup}{this.props.post}</span>
                    </div>
                </Link>
            </li>;
        } else {
            return <li>
                <Link to={this.props.url} onClick={this.linkOnClick} className={this.props.active ? 'sidebar-user is-h':'sidebar-user'} style={this.props.url ? null:{cursor: 'default'}}>
                    <div className="inner-user">
                        <div className="img-user">
                            <span className="social-user"><img src={this.props.imgSocSet}/></span>
                            <span className="inner-img"><img src={this.props.avatar}/></span>
                        </div>
                        <span className="name-user">{this.props.name}</span>

                        {this.props.error ?
                            <i className="icon icon-user-error" style={this.props.active ? {marginRight: 30}:null}></i>
                            :null}

                        {this.props.direct ? <Link to={this.props.urlDirect}><i className="icon icon-user-message" style={{position: 'absolute', left: '200px', top: '8px'}}></i></Link>:null}

                        <span style={this.props.post === 0 ? {display: 'none'}:null} className="count-user">{this.props.buttomSetup}{this.props.post}</span>
                    </div>
                </Link>
            </li>;
        }
    }

    linkOnClick = (e) => {
        const initialStore = this.props.initialStore;
        const menuStore = this.props.menuStore;

        if (initialStore.templates !== 'desktop') {
            if(!this.props.active) menuStore.openMenuMobile = false;
        }

        const accountsStore = this.props.accountsStore;

        accountsStore.active = this.props.number;

        initialStore.funScrollTopContentInner();

        /*if (initialStore.templates !== 'desktop') {
            e.preventDefault();
        }*/
    }
}

