import React from 'react';
import iconDel from "../media/svg/icons-del.svg";

const ru = {
    accountEmployeeDelite: {
        title: 'Удалить',
        text: 'Вы уверены что хотите удалить право на использование данного аккаунта у этого менеджера? Если да, нажмите "Подтверждаю"',
    },
    accountProxy: {
        title: 'Удалить',
        text: 'Вы уверены что хотите удалить прокси? Если да, нажмите "Подтверждаю"',
    },
    accountPatterns: {
        title: 'Удалить',
        text: 'Вы уверены что хотите удалить шаблон? Если да, нажмите "Подтверждаю"',
    },
    employeesDel: {
        title: 'Удалить',
        text: 'Вы уверены что хотите удалить Сотрудника? Если да, нажмите "Подтверждаю"',
    },
    postClose: {
        title: 'Закрыть',
        text: 'Вы уверены что хотите закрыть Создание поста? Если да, нажмите "Подтверждаю"',
    },
    postDel: {
        title: <img src={iconDel}/>,
        text: 'Вы уверены что хотите удалить Пост? Если да, нажмите "Подтверждаю"',
    },
}

const en = {
    accountEmployeeDelite: {
        title: 'Delete',
        text: 'Are you sure you want to remove the right to use this account from this manager? If yes, click "Confirm"',
    },
    accountProxy: {
        title: 'Delete',
        text: 'Are you sure you want to remove the proxy? If yes, click "Confirm"',
    },
    accountPatterns: {
        title: 'Delete',
        text: 'Are you sure you want to delete the template? If yes, click "Confirm"',
    },
    employeesDel: {
        title: 'Delete',
        text: 'Are you sure you want to delete an Employee? If yes, click "Confirm"',
    },
    postClose: {
        title: 'Close',
        text: 'Are you sure you want to close Posting? If yes, click "Confirm"',
    },
    postDel: {
        title: <img src={iconDel}/>,
        text: 'Are you sure you want to delete the Post? If yes, click "Confirm"',
    },
}

const buttonConfirmation = {
    ru: ru,
    en: en,
}

export default buttonConfirmation;
