import React from 'react';

import {inject, observer} from "mobx-react";


import ButtonLoad from "../../templates/elements/buttonLoad";
import APIposts from "../../api/posts";
import {createLoadPost} from "../../actions/posts/createLoadPost";



@inject('postStore', 'notificationStore', 'initialStore')
@observer
class ButtonDubl extends React.Component {

    constructor (props) {
        super(props);
    }

    render() {
        const postStore = this.props.postStore;

        if (!postStore.autorisation) {
            return null;
        }

        return (
            <div className="add-post__events">
                <ButtonLoad style={{width: "100%", textAlign: 'left'}} action={this.action} color>dubl</ButtonLoad>
            </div>
        );


    }

    action = (resolve) => {
        const postStore = this.props.postStore;
        const initialStore = this.props.initialStore;
        const notificationStore = this.props.notificationStore;
        const text = initialStore.text.post;

        APIposts.getInfoPost(postStore.id, postStore.code).then((data) => {
            if (!data.status) {
                notificationStore.add(text[17], text[16])
                return false;
            } else {
                postStore.open = false;
                createLoadPost(data.date, false);
            }
        }).catch((e) => {

        }).finally(() => {
            resolve(true);
        });
    }



}

export default ButtonDubl;
