import React from 'react';
import {inject, observer} from "mobx-react";
import Logo from "../media/img/sidebar/logo.svg";

import {Spring} from 'react-spring/renderprops';
import "./loaderApp.css";
export default
@inject('initialStore')
@observer
class LoaderApp extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return(
                <div className="main">
                    <div className="main-wrapper">
                        <div className="lk-load btn-primary-animate-content">
                            <div className="lk-load-name">Loading...</div>
                            <div className="inner-load">
                                <img src={Logo} className="brand-load"/>
                                {/*<span className="title-load">{this.props.name ? this.props.name:''}</span>*/}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return(
                <div className="main">
                    <div className="main-wrapper">
                        <div className="lk-load  btn-primary-animate-content">
                            {this.props.loadingOff ? null:<div className="lk-load-name">Loading...</div>}
                            <div className="inner-load">
                                <img src={Logo} className="brand-load"/>
                                {/*<span className="title-load">{this.props.name ? this.props.name:''}</span>*/}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    }
}

