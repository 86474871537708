import React from 'react';
import {inject, observer} from "mobx-react";

export default
@inject('initialStore')
@observer
class HelpIndex extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <>



                <div className="questions">

                    <div className="questions-wrap row">
                        <div className="questions-col col" style={{width: '100%'}}>
                            <ul className="questions-list">
                                {this.props.categorys}
                            </ul>
                        </div>
                        {this.props.list}
                    </div>
                </div>

                </>;
        } else {
            return null;
        }

    }
}

