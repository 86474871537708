import {action, observable} from "mobx";

class redPostMiniStore {

    @observable open = false;

    @observable id = false;
    @observable code = false;

    @observable status = 1;
    @observable tip = 1;
    @observable int = 1;

}

export default redPostMiniStore;