import React from 'react';
import {inject, observer} from "mobx-react";

import Input from "../elements/input";
import ReactDOM from "react-dom";
import iconEdit from "../../media/svg/icons-edit-2.svg";
import iconDel from "../../media/svg/icons-del.svg";
import iconDrop from "../../media/svg/media-draggable.svg";
import {observable} from "mobx";
import PostTemplateMediaIconsDraggable from "./mediaIconsDraggable";
import Textbox from "../../component/post/component/Textbox";
import PostTimeBox from "./timeBox";


export default
@inject('initialStore', 'mediaStore')
@observer
class PostTemplateMediaIconsMulty extends React.Component {

    @observable open = false;

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return(
                <div className="group-publication">
                    <div className="add-publication__several">
                        <div className={this.props.typePost === 'multyPost' ? "column-several column-img":"column-several column-img-store"}>
                            <span className="label-several"><b>{this.props.title}</b></span>
                            <div className="img-several">
                                <div className="add-publication__download">

                                    <div className={this.props.typePost === 'multyPost' ? "inner-img":"inner-img inner-img-story"}>
                                        {this.props.iconVideo ? <span className="type-post"><i className="icon icon-post-video"></i></span>:null}
                                        {!this.props.durationIcon ? <span className="type-post-icons-create"></span> : null}
                                        <div className="wrap-img">{this.props.icon ? <img src={this.props.icon}/> : null}</div>
                                    </div>

                                    {this.props.errorLoad ? <span className="reLoadError" onClick={this.props.errorLoad}>Ошибка загрузки<br/><span>Повторить</span></span>:null}

                                    {this.props.percent ? <span className="percent-download">{this.props.percent}%</span>:null}

                                    {this.props.content && !this.props.errorLoad && this.props.durationIcon ?

                                        <div className="events-download">
                                            <span className="event-download edit" id="event-download-edit" onClick={this.props.editorOpen}>
                                                <img src={iconEdit}/>
                                            </span>
                                            <span className="event-download remove" id="event-download-remove" onClick={this.props.DelElement}>
                                                <img src={iconDel}/>
                                            </span>

                                        </div>
                                        :null}

                                </div>
                            </div>
                        </div>
                        <div className={this.props.typePost === 'multyPost' ? "column-several column-info" : "column-several column-info column-info-story"}>
                            {this.props.typePost === 'multyPost' ?
                            <div className="group-publication">
                                <div className="add-publication__text">
                                    <div className="form-group">
                                        <Textbox text={this.props.text} textOnChange={this.actionText}  patternsUser={true} rows={9}/>
                                        <span className="count-input">{this.props.infoText}</span>
                                    </div>
                                </div>
                            </div>
                            : null}
                            <div className="group-publication">
                                <div className="add-publication__settings" style={{margin: '0px 0px 35px 0'}}>
                                    {this.props.vievTime}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    }

    actionText = (text) => {
        const mediaStore = this.props.mediaStore;
        for (let key in mediaStore.multyPost.slice()) {
            if (mediaStore.multyPost[key].id === this.props.id) {
                mediaStore.multyPost[key].text = text;
                break;
            }
        }
    }


    openElementsEvent = () => {
        if (!this.open) {
            this.open = true;

            setTimeout(() => {
                this.open = false;
            }, 5000);
        }
    }


}

