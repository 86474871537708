import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

export default
@inject('initialStore')
@observer
class Button extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        let classBox = this.props.color ? 'btn btn-primary':'btn border btn-primary';

        if (this.props.className) {
            classBox = this.props.className;
        }

        if (this.props.load) {
            classBox+=" btn-primary-animate-content type-button-cursor-default";
        }

        let name = '';

        if (this.props.name) {
            name = this.props.name;
        } else {
            name = initialStore.text.button[this.props.children];
        }

        if (this.props.div) {
            return <div style={this.props.style} onClick={this.action} className={classBox}>{name}</div>;
        }
        return <button style={this.props.style} onClick={this.action} type="button" className={classBox}>{name}</button>;
    }

    action = () => {
        if (!this.props.load) {
            this.props.action();
        }

        if (document.activeElement) {
            if (document.activeElement.localName === 'textarea' || document.activeElement.localName === 'input') {
                document.activeElement.blur();
            }
        }
    }
}

