import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import Time from "../../model/time";
import {impureFinalPropsSelectorFactory} from "react-redux/lib/connect/selectorFactory";

export default
@inject('initialStore', 'modalDateMobile')
@observer
class DateMobile extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const onDayClick = this.props.onDayClick;
        let selectedDays = this.props.selectedDays;

        let className = 'form-control start form-control-date form-control-date-mobile';
        if (selectedDays) {
            selectedDays = Time.convertUnixToDate(selectedDays).date;
            className = 'form-control start form-control-date form-control-date-mobile form-control-date-mobile-black';
        }

        if (this.props.className) {
            className = this.props.className;
        }

        return (
            <div
                className={className}
                onClick={this.openCalendar}
                >
                    {selectedDays ? selectedDays : this.props.placeholder}
            </div>
        );

    }

    openCalendar = (e) => {

        const modalDateMobile = this.props.modalDateMobile;
        modalDateMobile.onDayClick = this.props.onDayClick;

        let selectedDays = false;
        if (this.props.selectedDays) {
            selectedDays = new Date(this.props.selectedDays*1000);
        }

        if (this.props.disabledDays) {
            modalDateMobile.disabledDays = this.props.disabledDays;
        } else {
            modalDateMobile.disabledDays = false;
        }


        modalDateMobile.selectedDays = selectedDays;
        modalDateMobile.openActive();

        if (document.activeElement) {
            if (document.activeElement.localName === 'textarea' || document.activeElement.localName === 'input') {
                document.activeElement.blur();
            }
        }
    }
}

