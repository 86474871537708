import qs from "qs";
import React from "react";
import instance from "./base";


const APIpromocode = {

    getStatus (code, money) {
        return instance.post('promocode/status', qs.stringify({
            code: code,
            money: money,
        })).then(data => {
            return data;
        });
    },



}

export default APIpromocode;