import React from 'react';
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";

export default
@inject('initialStore')
@observer
class HelpElementText extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <div className="questions-col col">
                <div className="questions-content">
                    <div className="item-content">
                        {this.props.children}
                    </div>
                </div>
            </div>;
        } else {
            return <div className="questions-col col">
                <div className="questions-content">
                    <div className="item-content">
                        {this.props.children}
                    </div>
                </div>
            </div>;
        }

    }
}

