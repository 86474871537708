import {action, observable} from "mobx";

class modalTarifStore {

    @observable open = false;
    @observable accounts = 5;
    @observable money = 5;
    @observable month = 1;
    @observable openRed = false;
    @observable accountsBil = false;

    @action openActive = (accounts, money, month) => {
        this.open = true;
        if (accounts  < 5) {
            accounts = 5;
        }
        this.accounts = accounts;
        this.money = money;
        this.month = month;
    }

    @action openActiveRed = (accounts, accountsBil) => {
        this.openRed = true;
        this.accounts = accounts;
        this.accountsBil = accountsBil;
    }
}

export default modalTarifStore;