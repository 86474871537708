import React from 'react';
import {inject, observer} from "mobx-react";
import directLoad from "../../../../media/gif/directLoad.gif";


export default
@inject('initialStore')
@observer
class AccountDirectMessageGif extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {

            let width = 200;
            let height = 200;
            if (this.props.width > this.props.height) {
                height = Math.round(width / (this.props.width/this.props.height));
            } else {
                width = Math.round(height * (this.props.width/this.props.height));
            }

            return (
                <img src={this.props.media} width={width} height={height}/>
            );
        } else {
            let width = 200;
            let height = 200;
            if (this.props.width > this.props.height) {
                height = Math.round(width / (this.props.width/this.props.height));
            } else {
                width = Math.round(height * (this.props.width/this.props.height));
            }

            return (
                <img src={this.props.media} width={width} height={height}/>
            );
        }
    }

}