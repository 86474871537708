import React from 'react';
import {inject, observer} from "mobx-react";
import TextareaAutosize from 'react-autosize-textarea';
import file, {getIconVideo} from "../../../model/file";
import iconEmoje from "../../../media/img/icons/chat/icons-8-happy.svg";

export default
@inject('initialStore')
@observer
class AccountDirectAddAccount extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return (
                <div style={{margin: 10}}   >
                    <button type="button" className="btn border btn-primary" style={{marginRight: 30}} onClick={this.props.buttonOk}>Принять</button>
                    <button type="button" className="btn border btn-primary" onClick={this.props.buttonDel}>Удалить</button>
                </div>);

        } else {
            return (
                <div style={{margin: 10}}   >
                    <button type="button" className="btn border btn-primary" style={{marginRight: 30}} onClick={this.props.buttonOk}>Принять</button>
                    <button type="button" className="btn border btn-primary" onClick={this.props.buttonDel}>Удалить</button>
                </div>);
        }
    }


}