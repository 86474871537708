import {action, observable} from "mobx";

class postStore {

    @observable open = false;
    @observable id = false;
    @observable code = false;
    @observable load = false;
    @observable date = {};

    @observable deliteStatus = 0;
    @observable editStatus = 0;

    @observable autorisation = true;

    loadContent = false;



    @action openActive = (id, code, autorisation = true) => {
        this.open = true;
        this.id = id;
        this.code = code;
        this.load = true;
        this.deliteStatus = 0;
        this.editStatus = 0;

        this.autorisation = autorisation;
    }

    @action dateAction = (date) => {
        this.date = {
            ...date
        };
    }
}

export default postStore;
