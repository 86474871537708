import {action, observable} from "mobx";

class menuStore {

    @observable activeURL = '/';
    @observable activeComponentAccount = 'posts';

    @observable openSetup = false;

    @observable elementsMenu = [
            {url: '/library', key: 0},
            /*{url: '/downloadpost', key: 1},*/
            {url: '/rate', key: 2},
            {url: '/settings', key: 3},
            {url: '/help', key: 4},
        ];

    @observable activeUrlMenu = window.location.pathname;

    @action updateActiveUrlMenu = (value) => {
        this.activeUrlMenu = value;
    }

    @action updateActiveURL = (value) => {
        this.activeURL = value;
    }

    @action updateActiveComponentAccount = (value) => {
        this.activeComponentAccount = value;
    }

    @action actionOpenSetup = (value) => {
        this.openSetup = value;
    }

    @observable openMenuMobile = false;

    @action actionOpenMenuMobile = (value) => {
        this.openMenuMobile = value;
    }

    @observable openMenuCreatePost = false;
}

export default menuStore;