import React from 'react';

import {inject, observer} from "mobx-react";
import Textbox from "../../../component/Textbox";
import {observable} from "mobx";
import store from "../../../../../stor/mainStore";
import APIinstagram from "../../../../../api/instagram";

@inject('editorStore', 'notificationStore', 'initialStore')
@observer
class MediaRedBUserSearh extends React.Component {

    @observable focusInput = false;
    @observable array = {
        id: false,
        load: false,
        search: '',
        array: [],
        z: 0,
    };

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;

        let infoGeo = this.fabric.elementInfoUser();

        if (infoGeo.id) {
            this.array = {
                id: 0,
                load: false,
                search: infoGeo.username,
                array: [
                    {
                        pk: infoGeo.id,
                        username: infoGeo.username,
                        full_name: infoGeo.full_name,
                        img: infoGeo.img,
                    },
                ],
                z: 0,
            };
        }
    }

    render() {
        const initialStore = this.props.initialStore;

        const text = initialStore.text.addPost;

        let render = null;

        let poleName = false;
        let poleNameActive = false
        let openSearh = false;
        if (this.focusInput) {
            render = (
                <div className="event-search">
                    <div className={this.array.load ? "chat-search is-active btn-primary-animate-content" : "chat-search is-active"}>
                        <span className="chat-search__icon">
                            <i className="icon form-control-users-icon-input"></i>
                        </span>
                        <div className="chat-search__input">
                            <input placeholder={text[110]} autoFocus value={this.array.search} onChange={this.inputOnChange} onBlur={this.onBlur}/>
                        </div>
                        <span className="chat-search__remove" onMouseDown={this.actionDeliteGeo}>
                            <i className="icon icon-calendar-clear"></i>
                        </span>
                    </div>

                    {this.array.array.length ?
                        <div className="event-search__result">
                            <div className="inner-result">
                                {this.arrayObject()}
                            </div>
                        </div>
                        :null}

                </div>

            );

            openSearh = true;
        } else {
            if (this.array.id === false) {

                render = null;
                poleName = text[111];

            } else {
                render = null;
                poleName = this.array.array[this.array.id].username;
                poleNameActive = true;
            }
        }
        return (
            <div className={openSearh ? "event-drop dropdown is-open" : "event-drop dropdown"}>
                <div className={poleNameActive ? "form-control form-control-user-icon" : "form-control form-control-user-icon"} onClick={this.onFocus}>{poleName}</div>
                {render}
            </div>
        );
    }

    arrayObject = () => {
        let array = this.array.array.map((value, key) => {
            return (
                <span className="item-result item-result-searh-users" onMouseDown={() => {this.onClick(key)}}>
                    <div className="item-result-user-image">
                        <img src={value.img}/>
                    </div>
                    <div className="item-result-user-info">
                        {value.username}<br/>
                        <span className="item-result-adress">{value.full_name}</span>
                    </div>
                </span>
            );
        });
        return array;
    }

    actionDeliteGeo = () => {
        this.array.id = false;
        this.array.search = '';
        this.array.array = [];

        this.fabric.elementTemplate('', false, false);
    }

    onClick = (id) => {
        this.array.id = id;

        this.array.search = this.array.array[id].username;

        this.fabric.elementTemplate(this.array.array[id].username.toUpperCase(), false, {
            tip: 'user',
            id: this.array.array[id].pk,
            username: this.array.array[id].username,
            full_name: this.array.array[id].full_name,
            img: this.array.array[id].img,
        });

    }
    onFocus = () => {
        this.focusInput = true;
    }
    onBlur = () => {
        this.focusInput = false;
    }
    inputOnChange = (e) => {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;

        const text = initialStore.text.addPost;
        const value = e.target.value;

        this.array.search = value;

        if (this.array.id !== false) {
            if (value != this.array.array[this.array.id].name) {
                this.array.id = false;
                this.fabric.elementTemplate('', false, false);
            }
        }

        let z = this.array.z;
        z = z+1;
        this.array.z = z;

        this.array.array = [];

        if (value === '') {
            return false;
        }

        this.array.load = true;

        APIinstagram.getUsers(value, z).then((data) => {

            if (z === this.array.z) {

                this.array.load = false;

                if (!data.status) {
                    notificationStore.add(text[114], data.info);
                    return false;
                }

                if (data.return.length === 0) {
                    notificationStore.add(text[115], text[116](value));
                }

                this.array.array = data.return;
            }
        }).catch((error) => {

        });
    }
}

export default MediaRedBUserSearh;