import React from 'react';
import ReactDOM from 'react-dom'
import {inject, observer} from "mobx-react";
import {Picker} from "emoji-mart";
import {observable} from "mobx";
import insertTextAtCursor from "insert-text-at-cursor";
import TextareaAutosize from "react-autosize-textarea";

import imgEmoje from "../../../media/svg/emojeText.svg";

@inject('mediaStore', 'accountsStore', 'initialStore')
@observer
class Textbox extends React.Component {

    @observable emojeOpen = false;
    @observable boxPatternsOpen = false;

    constructor (props) {
        super(props);

        this.textarea = React.createRef();
        this.buttonOpenEmoje = React.createRef();
        this.PickerRef = React.createRef();

        this.boxPatternsRef = React.createRef();

        this.textChange = this.textChange.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.pickerOnSelect = this.pickerOnSelect.bind(this);

        this.boxPatternsOpenClick = this.boxPatternsOpenClick.bind(this);
        this.handleClickOutsidePatterns = this.handleClickOutsidePatterns.bind(this);

        this.openWinEmoje = false;
        this.openWinTemplate = false;
    }

    //вставляет текст в место где был ранее курсор
    pickerOnSelect (text, offset) {

        const el = this.textarea.current;

        insertTextAtCursor(el, text);
    }



    render() {
        const mediaStore = this.props.mediaStore;
        const accountsStore = this.props.accountsStore;
        const account = accountsStore.active;
        const patterns = accountsStore.patterns.list;
        const accountsOK = mediaStore.accounts.slice();
        const patternsUser = this.props.patternsUser;

        const initialStore = this.props.initialStore;

        const textI18 = initialStore.text.addPost;

        let text = this.props.text;

        let EmojeBox;

        if (!this.emojeOpen) {
            if (this.openWinEmoje) {
                this.openWinEmoje=false;

                this.updateStatusCloseFonEmoje();
            }
        } else {
            if (!this.openWinEmoje) {
                this.openWinEmoje=true;
                this.updateStatusCloseFonEmoje();
            }
        }

        if (this.emojeOpen && initialStore.templates === 'desktop') {
            EmojeBox = <Picker
                set='apple'
                color="#7b5c9b"
                showSkinTones={false}
                showPreview={false}
                i18n={initialStore.text.emoji}
                onSelect={(emoji) => {this.pickerOnSelect(emoji.native)}}
                ref={this.PickerRef}
            />;
        }

        let boxPatterns = null;

        if (!this.boxPatternsOpen) {
            if (this.openWinTemplate) {
                this.openWinTemplate=false;

                this.updateStatusCloseFonTemplate();
            }
        } else {
            if (!this.openWinTemplate) {
                this.openWinTemplate=true;
                this.updateStatusCloseFonTemplate();
            }
        }

        if (this.boxPatternsOpen) {

            let listCommon = [];
            let list = [];

            for (const pattern of patterns) {

                let nado = false;
                if (patternsUser === true) {

                    for (const userActive of accountsOK) {
                        if (userActive.id === pattern.account || pattern.account === '0') {
                            nado=true;
                        }
                    }
                    if (!accountsOK.length && pattern.account === '0') {
                        nado=true;
                    }
                } else {

                    if (patternsUser === pattern.account || pattern.account === '0') {
                        nado=true;
                    }
                }

                if (!nado) continue;

                const element = (
                    <div className="event-item" onClick={() => {this.pickerOnSelect(pattern.text)}}>
                        <span className="title-item">{pattern.name}</span>
                        <p className="desc-item">{pattern.text}</p>
                    </div>
                );

                if (pattern.account === 0) {
                    listCommon.push(element);
                } else {
                    list.push(element);
                }

            }

            if (listCommon.length === 0 && list.length === 0) {

                listCommon = <div className="non-templare">{textI18[54]}</div>;
            }

            boxPatterns = (
                <div className="event-menu g-center v-bottom show event-menu-default">
                    <div className="inner-menu">
                        {listCommon}
                        {list}
                    </div>
                </div>
            );
        }

        let maxRows = 20;
        if (initialStore.templates === 'mobile') {
            maxRows = 10;
        } else {
            //let text2 = window.emojiOne(text);
            //text2= text + '<div>1</div>';
            //console.log(text2);
            //text = text2;
        }

        let render = <div className="add-publication__text-box">
            {patternsUser !== undefined ?
            <div ref={this.boxPatternsRef}>
                <div className="input-label flex">
                    <span className="title">{this.props.title ? this.props.title : textI18[56]}</span>
                    <div className="add-publication__template">
                        <span className="label-template" onClick={this.boxPatternsOpenClick}>{textI18[55]}</span>
                        {boxPatterns}
                    </div>
                </div>


            </div>
                : null}
            <TextareaAutosize
                ref={this.textarea}
                onChange={this.textChange}
                value={text}
                rows={this.props.rows ? this.props.rows : 5}
                maxRows={maxRows}
                className="form-control view-2 no-fastclick"
            ></TextareaAutosize>
            {initialStore.templates === 'desktop' ?
            <div className="text-box-emoje">
                <div className="text-box-emoje-box">
            {EmojeBox}
                </div>
            <div onClick={() => {
                if (this.emojeOpen) {
                    this.emojeOpen = false;
                } else {
                    this.emojeOpen = true;
                }
            }
        } ref={el=>this.buttonOpenEmoje=el}><img src={imgEmoje}/></div>

            </div>
                :null}

        </div>;

        return render;
    }



    boxPatternsOpenClick() {
        this.boxPatternsOpen = !this.boxPatternsOpen;
    }

    handleKeyDown () {
        //console.log(this.textarea.current.selectionStart + ' / ' + this.textarea.current.selectionEnd);
    }

    textChange (event) {
        this.props.textOnChange(event.target.value);
    }

    updateStatusCloseFonEmoje = () => {
        if (this.openWinEmoje) {
            //смайлы
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            //смайлы
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    updateStatusCloseFonTemplate = () => {
        if (this.openWinTemplate) {
            //шаблоны
            document.addEventListener('click', this.handleClickOutsidePatterns, false);
        } else {
            //шаблоны
            document.removeEventListener('click', this.handleClickOutsidePatterns, false);
        }
    }

    // Вызывается после удаления компонента из DOM
    componentWillUnmount() {
        //смайлы
        document.removeEventListener('click', this.handleClickOutside, false);

        //шаблоны
        document.removeEventListener('click', this.handleClickOutsidePatterns, false);
    }

    // Отлавливаем клик на любую область
    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.PickerRef.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов
                const svgSmileBtn = this.buttonOpenEmoje;

                // Если клик не производился и на кнопку открытия окна смайлов, то скрываем блок.
                if (!e.path.includes(svgSmileBtn)) {
                    this.emojeOpen = false;
                }
            }
        } catch(error) {
            return null
        }
    }

    handleClickOutsidePatterns(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.boxPatternsRef.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов

                if (this.boxPatternsOpen) {
                    this.boxPatternsOpen = false;
                }
            }
        } catch(error) {
            return null;
        }
    }
}

export default Textbox;
