import React from 'react';
import {inject, observer} from "mobx-react";
import MenuAccount from "../../../templates/elements/menu/account";

import iconInst from "../../../media/img/socialNetworks/inst.svg";
import iconFB from "../../../media/img/socialNetworks/fb.svg";
import iconVK from "../../../media/img/socialNetworks/vk.svg";
import iconOK from "../../../media/img/socialNetworks/ok.svg";
import iconTG from "../../../media/img/socialNetworks/tg.svg";
import iconTwitter from "../../../media/img/socialNetworks/twitter.svg";
import iconInstFb from "../../../media/img/socialNetworks/inst.fb.svg";

import AccountSetup from "./accountSetup";


@inject("accountsStore")
@inject("menuStore")
@observer
class Account extends React.Component {

    render() {
        const menuStore = this.props.menuStore;
        const accountsStore = this.props.accountsStore;

        const account = this.props.account;
        const id = this.props.id;

        let active = false;
        if (accountsStore.active !== null && id === Number(accountsStore.active)) active = true;

        let post = '';
        if (account.post.list) {
            post = 0;

            for (const item of account.post.list.slice()){
                if (item.url) {
                    continue;
                }else if (item.status) {
                    continue;
                } else {
                    post++;
                }
            }

            if (!post) {
                post = '';
            }

        }

        let url = '/account/' + account.id;

        if (menuStore.activeComponentAccount === 'history') {
            url+= '/history';
            if (active) url = false;
        } else if (menuStore.activeComponentAccount === 'preview' && account.tip === 'instagram') {
            url+= '/preview';
            if (active) url = false;
        } else if (menuStore.activeComponentAccount === 'story' && account.tip === 'instagram') {
            url+= '/story';
            if (active) url = false;
        } else if (menuStore.activeComponentAccount === 'statistics' && account.tip === 'instagram' && account.rights.statistics) {
            url+= '/statistics';
            if (active) url = false;
        } else if (menuStore.activeComponentAccount === 'direct' && account.tip === 'instagram' && account.rights.direct) {
            url+= '/direct';
            if (active) url = false;
        } else if (menuStore.activeComponentAccount === 'patterns') {
            //url+= '/patterns';
        } else if (menuStore.activeComponentAccount === 'timetable' && account.rights.root) {
            //url+= '/timetable';
        } else if (menuStore.activeComponentAccount === 'employees' && account.rights.root) {
            //url+= '/employees';
        } else if (menuStore.activeComponentAccount === 'proxy' && account.tip === 'instagram' && account.rights.root) {
            //url+= '/proxy';
        } else if (menuStore.activeComponentAccount === 'post') {
            if (active) url = false;
        } else {

        }

        let buttomSetup = null;

        if (active) {
            post = null;
            buttomSetup = <AccountSetup
                account={this.props.account}
                menuStore={this.props.menuStore}
            />;
        }

        post = post < 99 ? post:'+99';

        //иконка
        let icon = '';
        if (account.tip === 'instagram') {
            icon = iconInst;
        } else if (account.tip === 'vk') {
            icon = iconVK;
        } else if (account.tip === 'fb') {
            icon = iconFB;
        } else if (account.tip === 'ok') {
            icon = iconOK;
        } else if (account.tip === 'tg') {
            icon = iconTG;
        } else if (account.tip === 'twitter') {
            icon = iconTwitter;
        } else if (account.tip === 'fb-instagram') {
            icon = iconInstFb;
        }



        return <MenuAccount
                url={url}
                active={active}
                avatar={account.img}
                imgSocSet={icon}
                name={account.name}
                buttomSetup={buttomSetup}
                post={post}
                error={account.error}
                direct={this.props.direct}
                urlDirect={'/account/' + account.id + '/direct'}
                number={this.props.number}

        />;

    }
}

export default Account;
