import APItimetable from "../../api/timetable";
import store from "../../stor/mainStore";

export function getTimetable(id, idAccountRedax, hash) {

    const accountsStore = store.accountsStore;

    accountsStore.getTimetableLoad(idAccountRedax);

    APItimetable.getPatterns(id, hash).then((data) => {

        accountsStore.getTimetable(idAccountRedax, data.timetable, data.timetable_story, data.hesh);

    }).catch((error) => {

    });
}
