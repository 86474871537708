import React from 'react';
import {inject, observer} from "mobx-react";
import TitleH1 from "../elements/text/titleH1";
import Input from "../elements/input";
import ButtonLoad from "../elements/buttonLoad";
import {CopyToClipboard} from "react-copy-to-clipboard";

export default
@inject('initialStore')
@observer
class SettingsReferrals extends React.Component {

    render() {

        const initialStore = this.props.initialStore;
        const text = initialStore.text.settings;

        if (initialStore.templates === 'desktop') {

            return (
                <div className="settings">
                    <TitleH1>{text.affiliateProgram}</TitleH1>

                    <p className="settings__help">{text.textInformReferrals}</p>

                    <div className="settings-partners">
                        <div className="settings-partners__add">
                            <span className="input-label">{text.referralLink}</span>
                            <div className="input-group">
                                <input value={this.props.url} placeholder="" className="form-control"/>
                                <CopyToClipboard text={this.props.url} onCopy={() => {this.props.notification(text.copy[1])}}>
                                    <button type="button" className="btn btn-primary">{text.copy[0]}</button>
                                </CopyToClipboard>

                            </div>
                        </div>

                        <ul className="settings-partners__list">
                            <li>
                                <span>{text.numberOfClicksOnLink}</span>
                                <b>{this.props.transitions}</b>
                            </li>
                            <li>
                                <span>{text.numberOfRegistrationsReference}</span>
                                <b>{this.props.registration}</b>
                            </li>
                            <li>
                                <span>{text.totalEarned}</span>
                                <b>{this.props.earned} {text.rub}</b>
                            </li>
                            <li>
                                <span>{text.totalPaid}</span>
                                <b>{this.props.paidOut} {text.rub}</b>
                            </li>
                            <li>
                                <span>{text.currentBalance}</span>
                                <b>{this.props.balance} {text.rub}</b>
                            </li>
                        </ul>

                        <p className="settings__help">{text.referalInffo_1}</p>
                        <p className="settings__help">{text.referalInffo_2}</p>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="settings">

                    <p className="settings__help">{text.textInformReferrals}</p>

                    <div className="settings-partners">
                        <div className="settings-partners__add">
                            <span className="input-label">{text.referralLink}</span>
                            <div className="input-group">
                                <input value={this.props.url} placeholder="" className="form-control"/>
                                <CopyToClipboard text={this.props.url} onCopy={() => {this.props.notification(text.copy[1])}}>
                                    <button type="button" className="btn btn-primary">{text.copy[0]}</button>
                                </CopyToClipboard>

                            </div>
                        </div>

                        <ul className="settings-partners__list">
                            <li>
                                <span>{text.numberOfClicksOnLink}</span>
                                <b>{this.props.transitions}</b>
                            </li>
                            <li>
                                <span>{text.numberOfRegistrationsReference}</span>
                                <b>{this.props.registration}</b>
                            </li>
                            <li>
                                <span>{text.totalEarned}</span>
                                <b>{this.props.earned} {text.rub}</b>
                            </li>
                            <li>
                                <span>{text.totalPaid}</span>
                                <b>{this.props.paidOut} {text.rub}</b>
                            </li>
                            <li>
                                <span>{text.currentBalance}</span>
                                <b>{this.props.balance} {text.rub}</b>
                            </li>
                        </ul>

                        <p className="settings__help">{text.referalInffo_1}</p>
                        <p className="settings__help">{text.referalInffo_2}</p>
                    </div>
                </div>
            );
        }

    }
}

