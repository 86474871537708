import {action, observable} from "mobx";

class modalPatternsStore {

    @observable open = false;
    @observable type = 'create';
    @observable id = false;
    @observable account = 0;
    @observable common = false; //общий
    @observable name = '';
    @observable text = '';


    @action openActive = (account, type, name = '', text = '', common = false, id = false) => {
        this.open = true;
        this.account = account;
        this.type = type;
        this.name = name;
        this.text = text;
        this.common = common;
        this.id = id;
    }

    @action dateAction = (date) => {
        this.date = {
            ...date
        };
    }
}

export default modalPatternsStore;