import React from 'react';

import {inject, observer} from "mobx-react";
import Textbox from "../../../component/Textbox";
import {observable} from "mobx";

@inject('editorStore')
@observer
class MediaRedBText extends React.Component {

    @observable text = '';

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;
    }

    render() {

        const text = this.fabric.elementGetText();
        let ff = this.text;

        return (
            <div className="add-publication__text-box-red">
                <Textbox text={text} textOnChange={(value) => {this.action(value)}} rows={2}/>
            </div>
        );
    }

    action = (value) => {
        this.fabric.elementSetText(value);

        this.text = value;
    }
}

export default MediaRedBText;