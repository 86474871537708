import React from 'react';
import {observer, inject} from "mobx-react";
import HelpIndex from "../../templates/help";
import HelpElementCategory from "../../templates/help/elementCategory";
import HelpElementTextBox from "../../templates/help/elementTextBox";
import HelpElementText from "../../templates/help/elementText";
import HelpElementTem from "../../templates/help/elementTem";

@inject("helpStore")
@observer
class Faq extends React.Component {

    constructor (props) {
        super(props);
    }

    render() {

        const helpStore = this.props.helpStore;
        const params = helpStore.params;

        const idCategory = this.props.match.params.id;
        const idTeam = this.props.match.params.id2;

        const categorys = [];

        let list = null;

        const info = params.map((item , key) => {

            const active = Number(idCategory) === key ? true:false;

            categorys.push(<HelpElementCategory name={item.name}/>);

            //разбор под категорий
            item.element.map((item2, key2) => {
                categorys.push(<HelpElementTem name={item2.name} id={key} id2={key2}/>);
            });

        });



        return <HelpIndex categorys={categorys}/>
    }

    componentDidMount() {
        const helpStore = this.props.helpStore;

        const idCategory = this.props.match.params.id;
        const idTeam = this.props.match.params.id2;

        if (idCategory && idTeam) {
            helpStore.idCategory = idCategory;
            helpStore.idTeam = idTeam;
        } else {
            if (helpStore.idCategory || helpStore.idTeam) {
                helpStore.idCategory = false;
                helpStore.idTeam = false;
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const helpStore = this.props.helpStore;

        const idCategory = this.props.match.params.id;
        const idTeam = this.props.match.params.id2;

        if (idCategory && idTeam) {
            helpStore.idCategory = idCategory;
            helpStore.idTeam = idTeam;
        } else {
            if (helpStore.idCategory || helpStore.idTeam) {
                helpStore.idCategory = false;
                helpStore.idTeam = false;
            }
        }
    }

}

export default Faq;