import React from 'react';
import {inject, observer} from "mobx-react";

import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';

import arrayMove from 'array-move';

import File from "../../../model/file";

import iconDrop from "../../../media/svg/media-draggable.svg";
import PostTemplateMediaIcons from "../../../templates/post/mediaIcons";
import PostTemplateMediaIconsDraggable from "../../../templates/post/mediaIconsDraggable";
import PostTemplateMediaIconsConteiner from "../../../templates/post/mediaIconsConteiner";
import editorMediaClass from "../../../model/eritorMedia";

const DragHandle = SortableHandle(() => {
    return <span className="event-drop">
            <img src={iconDrop}/>
        </span>;
});

const SortableItem = SortableElement(({value}) => {

    return (
        <PostTemplateMediaIconsConteiner
            typePost={value.typePost}
            iconVideo={value.iconVideo}
            durationIcon={value.durationIcon}
            icon={value.icon}
            errorLoad={value.errorLoad}
            percent={value.percent}
            content={value.content}
            durationIcons={value.durationIcons}
            editorOpen={value.editorOpen}
            DelElement={value.DelElement}
            type={value.type}
            DragHandle={<DragHandle/>}
        />
    );

});

const SortableList = SortableContainer(({items}) => {

    return (
        <div className="result-photo">
            {items.map((value, index) => (
                <SortableItem key={`item-${value.id}`} index={index} value={value} />
            ))}
        </div>
    );
});

@inject('mediaStore', 'initialStore', 'accountsStore', 'notificationStore')
@observer
class MediaIconsNew extends React.Component{

    constructor (props) {
        super(props);

        this.editorOpen = this.editorOpen.bind(this);
        this.DelElement = this.DelElement.bind(this);

    }

    render() {
        const mediaStore = this.props.mediaStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        const elemen = [];




        const elements = mediaStore.media.map((item, key) => {

            let type = 'video';
            if (item.type === 'image') {
                type = 'image';
            } else if (item.type === 'obrabotka') {

                type = 'obrabotka';
            }

            let icon = false;
            let percent = false;

            if (item.processingPercent === 100 && item.icon) {
                icon = item.icon;

                if (mediaStore.IgtvImage) {
                    icon = mediaStore.IgtvImage.icon;
                }
            } else {
                percent = item.processingPercent;
            }

            let content = false;
            let errorLoad = false;
            if (item.loadPercent !== true) {
                if (item.loadPercent === false) {
                    content = <span className="reLoadError" onClick={() => {File.loadFileToServer(item.id)}}>{text[74]}<br/><span>{text[75]}</span></span>;
                    errorLoad = () => {File.loadFileToServer(item.id)};
                } else {
                    percent = item.loadPercent;
                }
            }



            if (errorLoad === false && icon !== false && percent === false) {
                content = (
                    <PostTemplateMediaIcons
                        editorOpen={() => {this.editorOpen(key)}}
                        DelElement={() => {this.DelElement(item.id)}}
                    />
                );
                content = true;
            }

            let  durationIcon = true;
            if (item.proportions === 0 || item.proportionsBackground === 0) {
                durationIcon = false;
            }


            elemen.push({
                id: item.id,
                icon: icon,
                iconVideo: type === 'video',
                percent: percent,
                content: content,
                errorLoad: errorLoad,
                editorOpen: () => {this.editorOpen(key)},
                DelElement: () => {this.DelElement(item.id)},
                typePost: mediaStore.type,
                durationIcon: durationIcon,
                type: type,
            });




        });


        return <SortableList axis="xy" items={elemen} onSortEnd={this.onSortEnd} helperClass="SortableHelper" useDragHandle/>;
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex === newIndex) {
            return;
        }

        const mediaStore = this.props.mediaStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        mediaStore.media = arrayMove(mediaStore.media.slice(), oldIndex, newIndex);

        //корректируем соотношение
        if (newIndex !== 0) {
            return true;
        }

        const accountsStore = this.props.accountsStore;
        const media = mediaStore.media.slice();
        let redBackgroundSize = false;
        for (const value of mediaStore.accounts.slice()) {

            for (const valueState of accountsStore.list.slice()) {
                if (value.id === valueState.id && valueState.tip === 'instagram') {
                    redBackgroundSize = true;
                }
            }
        }

        if (mediaStore.type === 'multyPost' || mediaStore.type === 'multyStory') {
            redBackgroundSize = false;
        }

        //если надо что бы все медиа были одного соотношения
        if (redBackgroundSize) {


            let proportionsBackgroundOne = media[0].proportions;

            if (proportionsBackgroundOne < 0.8) {
                proportionsBackgroundOne = 0.8;
            }
            if (proportionsBackgroundOne > 1.91) {
                proportionsBackgroundOne = 1.91;
            }

            const editorMedia = new editorMediaClass({...media[0], proportionsBackground:proportionsBackgroundOne});
            editorMedia.initial();
            const importState = editorMedia.importState();

            mediaStore.actionMediaUpdate(media[0].id, {...importState});

            const notificationStore = this.props.notificationStore;
            notificationStore.add('', text[76]);
        }
    };

    DelElement(id) {
        this.props.mediaStore.delMediaID = id;
    }

    editorOpen(id) {
        this.props.mediaStore.actionEditorOpen(id);
    }
}

export default MediaIconsNew;
