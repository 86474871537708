import React from 'react';
import {inject, observer} from "mobx-react";

import Menu from "../component/menu";
import MenuCreatePost from "../component/menu/menuCreatePost";
import Header from "../component/header/Header";
import Content from "../component/Content";
import Notification from "../component/Notification";
import VievPost from "../component/modalVievPost/VievPost";
import ButtonOpenMenuMobile from "../component/menu/ButtonOpenMenuMobile";
import {observable} from "mobx";
import MenuCreatePostButtonMobile from "../component/menu/menuCreatePostButtonMobile";
import {impureFinalPropsSelectorFactory} from "react-redux/lib/connect/selectorFactory";
import MenuMobileTop from "./elements/menu/mobileTop";

export default
@inject('initialStore', 'menuStore')
@observer
class FrameApp extends React.Component {



    constructor(props) {
        super(props);

        this.windows = React.createRef();

    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <div className="main">

                <div className="main-wrapper">

                    <section className="lk view-1">
                        <aside className="sidebar">

                            <MenuCreatePost/>

                            <Menu/>
                        </aside>

                        <div className="content">

                            <Header/>

                            <div className="content-inner" onScroll={this.onScroll} ref={(el) => { this.addWindows(el); }}>

                                <Content />
                            </div>
                        </div>
                    </section>

                </div>

            </div>;
        } else {

            const menuStore = this.props.menuStore;

            return <div className={menuStore.openMenuMobile || menuStore.openMenuCreatePost ? "main content-open-menu" : "main"}>

                <div className="main-wrapper">
                    <Menu/>
                    <MenuCreatePost/>
                    <div className="mobile-block-lk"></div>
                    <section className="lk view-1">
                        <MenuMobileTop/>

                        <div className="content">

                            <div className="content-inner" onScroll={this.onScroll} ref={(el) => { this.addWindows(el); }}>

                                <Content />

                            </div>
                        </div>
                    </section>

                </div>

            </div>;
        }
    }

    addWindows = (e) => {
        const initialStore = this.props.initialStore;
        initialStore.refContentInner = e;
    }

    onScroll = () => {
        const initialStore = this.props.initialStore;
        const scrollTop = initialStore.refContentInner.scrollTop;
        const scrollHeight = initialStore.refContentInner.scrollHeight;
        const height = initialStore.refContentInner.clientHeight;
        const maxScrollTop = scrollHeight - height;
        const ostatok = maxScrollTop - scrollTop;
        if (initialStore.templates !== 'desktop') {

            if (scrollTop>initialStore.refContentInnerScrollTop && initialStore.mobileOpacityTop > 0) {

                if (scrollTop <=0) {
                    initialStore.mobileOpacityTop=1;
                } else {
                        /*const spusk = scrollTop - initialStore.refContentInnerScrollTop;
                        const itog = initialStore.mobileOpacityTop - spusk/120 > 0 ? spusk/120 : initialStore.mobileOpacityTop;
                        initialStore.mobileOpacityTop-=itog;*/
                    initialStore.mobileOpacityTop = 0;
                }
            } else if (scrollTop < initialStore.refContentInnerScrollTop && initialStore.mobileOpacityTop < 1) {
                if (ostatok <= 0 && scrollTop > 60) {
                    initialStore.mobileOpacityTop = 0;
                } else {
                    initialStore.mobileOpacityTop=1;
                }

            }


        }

        initialStore.refContentInnerScrollTop = scrollTop;


        if (ostatok < 480) {

            if (initialStore.limitVisualPosts < initialStore.postInLentaMax) {

                initialStore.limitVisualPosts = initialStore.limitVisualPosts + initialStore.limitVisualPostsPlasDefoult;

            }
        }


    }
}