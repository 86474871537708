import React from 'react';
import {inject, observer} from "mobx-react";
import RateCharging from "./charging";
import RateActive from "./active";

@inject('initialStore')
@inject('menuStore')
@observer
class Rate extends React.Component {

    constructor (props) {
        super(props);
    }

    render() {

        const category = this.props.match.params.category;

        if (category === 'charging') {
            return <RateCharging/>;
        } else {
            return <RateActive/>;
        }

    }
    componentDidMount() {
        this.props.menuStore.updateActiveUrlMenu('/rate');
    }

}

export default Rate;