import React from 'react';
import {inject, observer} from "mobx-react";
import TextareaAutosize from 'react-autosize-textarea';
import file, {getIconVideo} from "../../../model/file";
import iconEmoje from "../../../media/img/icons/chat/icons-8-happy.svg";
import {Picker} from "emoji-mart";
import {observable} from "mobx";
import insertTextAtCursor from 'insert-text-at-cursor';
import ReactDOM from "react-dom";

export default
@inject('initialStore')
@observer
class AccountDirectCreateMessage extends React.Component {

    @observable emojeOpen = false;

    constructor(props) {
        super(props);

        this.fileInput = React.createRef();
        this.textarea = React.createRef();
        this.PickerRef = React.createRef();
        this.buttonOpenEmoje = React.createRef();

        this.onKeyUpEnter = this.onKeyUpEnter.bind(this);
        this.loafMedia = this.loafMedia.bind(this);
        this.filOnChange = this.filOnChange.bind(this);
        this.pickerOnSelect = this.pickerOnSelect.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.openWin = false;
    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.help;
        let EmojeBox = null;




        if (initialStore.templates === 'desktop') {

            if (!this.emojeOpen) {
                if (this.openWin) {
                    this.openWin=false;
                    this.updateStatusCloseFon();
                }
            } else {
                if (!this.openWin) {
                    this.openWin=true;
                    this.updateStatusCloseFon();
                }
            }

            if (this.emojeOpen) {
                EmojeBox = <div style={{position: 'absolute', margin: '-340px 0 0 -301px'}}><Picker
                    set='apple'
                    showSkinTones={false}
                    showPreview={false}
                    i18n={initialStore.text.emoji}
                    onSelect={(emoji) => {this.pickerOnSelect(emoji.native)}}
                    ref={this.PickerRef}
                /></div>;
            }

            return (
                <div className="chat-send">
                    <div className="chat-send__attach">
                        <span className="icon-attach" onClick={this.loafMedia}>
                            <i className="icon icon-chat-attach"></i>
                            <input className="no-fastclick" style={{display: 'none'}} type="file" accept="image/*, video/*" ref={this.fileInput} onChange={this.filOnChange} />
                        </span>
                    </div>
                    <div className="chat-send__input">
                        <TextareaAutosize
                            ref={this.textarea}
                            onKeyDown={this.onKeyUpEnter}
                            placeholder={text[8]}
                            onChange={(event) => {this.props.textOnChange(event.target.value)}}
                            value={this.props.textValue}
                            rows={1}
                            maxRows={5}
                            onHeightChange={(size, element) => {
                                console.log(size, element);
                                debugger;
                            }}
                            ></TextareaAutosize>
                    </div>
                    <div className="chat-send__stickers">
                        {EmojeBox}
                        <span className="icon-stickers" ref={el=>this.buttonOpenEmoje=el} onClick={this.openAndEndEmoje}>
                            <img src={iconEmoje}/>
                        </span>
                    </div>
                    <span className="chat-send__btn" onClick={this.props.sendingMessageText}>
                        <i className="icon icon-chat-send"></i>
                    </span>
                </div>);

        } else {



            return (
                <div className="chat-send">
                    <div className="chat-send__attach">
                        <button type="button" className="icon-attach" onClick={this.loafMedia}>
                            <i className="icon icon-chat-attach"></i>
                        </button>
                        <input className="no-fastclick" style={{display: 'none'}} type="file" accept="image/*, video/*" ref={this.fileInput} onChange={this.filOnChange} />
                    </div>
                    <div className="chat-send__input">
                        <TextareaAutosize
                            ref={this.textarea}
                            onKeyDown={this.onKeyUpEnter}
                            placeholder={text[9]}
                            onChange={(event) => {this.props.textOnChange(event.target.value)}}
                            value={this.props.textValue}
                            rows={1}
                            maxRows={5}
                            onHeightChange={(size, element) => {
                                console.log(size, element);
                                debugger;
                            }}
                        ></TextareaAutosize>
                    </div>
                    <span className="chat-send__btn" onClick={this.props.sendingMessageText}>
                        <i className="icon icon-chat-send"></i>
                    </span>
                </div>);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.textarea.current.value === "\n") {
            this.props.textOnChange('');
            this.props.scrollToMessagesButton(false);
        }
    }

    openAndEndEmoje = () => {
        this.emojeOpen=!this.emojeOpen;
    }

    //вставляет текст в место где был ранее курсор
    pickerOnSelect (text, offset) {

        const el = this.textarea.current;
        insertTextAtCursor(el, text);
    }

    onKeyUpEnter (event) {
        const initialStore = this.props.initialStore;


        if (initialStore.templates === 'desktop') {
            if (event.keyCode === 13 && !event.shiftKey && event.currentTarget.value !== '') {
                this.props.sendingMessageText();
            }
        } else {
            if (event.keyCode === 13 && event.shiftKey) {
                this.props.sendingMessageText();
            }
        }
    }

    loafMedia () {
        //Удаляем прошлые файлы из формы
        this.fileInput.current.value = null;
        //открываем форму
        this.fileInput.current.click();
    }

    filOnChange() {
        this.handleFiles(this.fileInput.current.files);
    }

    handleFiles = async (files) => {
        const initialStore = this.props.initialStore;

        if (!files.length) {
            return false;
        }

        if (!this.props.nonTest) {
            let filesNew = await file.FileMaxSizeAndTypeAndMaxFiles(files,1);

            const url = await file.createURL(filesNew[0]);
            let blob = null;
            let type = 'image';
            let proportions = 1;
            let cover = null;
            if (filesNew[0].type === 'image') {
                blob = await file.createBlobPre(url);
                proportions = await file.proportionsImage(url);
            } else {
                const videoTest = await file.videoTest(url);
                if (videoTest.status) {
                    blob = filesNew[0].file;
                    type = 'video';
                    proportions = await file.proportionsVideo(url);
                    const result = await getIconVideo(url, 0, true);
                    cover = result.original;
                } else {
                    alert(initialStore.text.code[17]);
                }
            }
            this.props.sendingMessageMedia(type, url, blob, proportions, cover);
        } else {
            if (files[0] > 210763776) {
                alert('Max size file = 200MB');
                return false;
            }
            this.props.sendingMessageMedia(files[0]);
        }

    }

    /*componentWillUnmount() {
        //смайлы
        document.removeEventListener('click', this.handleClickOutside, false);
    }

    // Вызывается до рендера
    componentWillMount() {
        //смайлы
        document.addEventListener('click', this.handleClickOutside, false);
    }*/


    updateStatusCloseFon = () => {
        if (this.openWin) {
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    // Отлавливаем клик на любую область
    handleClickOutside(e) {
        console.log(23);
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.PickerRef.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов
                const svgSmileBtn = this.buttonOpenEmoje;

                // Если клик не производился и на кнопку открытия окна смайлов, то скрываем блок.
                if (!e.path.includes(svgSmileBtn)) {
                    if (this.emojeOpen) {
                        this.emojeOpen = false;
                    }

                }
            }
        } catch(error) {
            return null
        }
    }
}