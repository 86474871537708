import React from 'react';
import {inject, observer} from "mobx-react";

import {autorun} from "mobx";

import editorMediaClass from "../model/eritorMedia";

@inject('accountsStore', 'notificationStore', 'mediaStore')
@observer
class MediaLoop extends React.Component{

    constructor (props) {
        super(props);

        autorun(() => {

            const mediaStore = this.props.mediaStore;
            const accountsStore = this.props.accountsStore;

            const media = mediaStore.media.slice();

            if (!media.length) {
                return true;
            }

            if (mediaStore.obrabotkaMediaFiles) {
                return true;
            }

            for (const value of media) {
                if (value.processingPercent < 100) {
                    return true;
                }
            }


            //Определеяем, можно ли редактировать соотношение
            let redBackgroundSize = false;

            for (const value of mediaStore.accounts.slice()) {

                for (const valueState of accountsStore.list.slice()) {
                    if (value.id === valueState.id && valueState.tip === 'instagram') {
                        redBackgroundSize = true;
                    }
                }
            }

            //если есть ааккаунты Instagram, значит надо урезать видео до 1 минуты
            if (redBackgroundSize && mediaStore.type!== 'igtv' && mediaStore.type !== 'editIgtv') {
                for (const key in media) {

                    let mediaID = Number(key);
                    mediaID++;

                    if (media[key].duration.finish - media[key].duration.start > 60) {
                        mediaStore.media[key].duration.finish = media[key].duration.start+60;
                        const notificationStore = this.props.notificationStore;
                        notificationStore.add('', 'Продолжительность видео была изменена до 1 минуты у медиа №' + mediaID);
                    }
                }
            }

            if (mediaStore.type === 'multyPost' || mediaStore.type === 'multyStory' || mediaStore.type === 'story' || mediaStore.type === 'editStory' || mediaStore.type === 'igtv' || mediaStore.type === 'editIgtv' || mediaStore.type === 'reels' || mediaStore.type === 'editReels') {
                redBackgroundSize = false;
            }


            //если надо что бы все медиа были одного соотношения
            if (redBackgroundSize) {

                let proportionsBackgroundOne = false;
                for (const key in media) {

                    if (key === '0') {
                        proportionsBackgroundOne = media[key].proportionsBackground;

                        if (proportionsBackgroundOne === 0) {
                            break;
                        }

                        proportionsBackgroundOne = proportionsBackgroundOne < 0.8 ? 0.8 : proportionsBackgroundOne;
                        proportionsBackgroundOne = proportionsBackgroundOne > 1.91 ? 1.91 : proportionsBackgroundOne;

                        if (media[key].proportionsBackground === proportionsBackgroundOne) {
                            continue;
                        }
                    }

                    if (!proportionsBackgroundOne) {
                        return false;
                    }

                    if (media[key].proportionsBackground !== proportionsBackgroundOne) {

                        const editorMedia = new editorMediaClass({...media[key], proportionsBackground:proportionsBackgroundOne});
                        editorMedia.initial();
                        const importState = editorMedia.importState();

                        mediaStore.actionMediaUpdate(media[key].id, {...importState});

                        let mediaID = Number(key);
                        mediaID++;
                        //const notificationStore = this.props.notificationStore;
                        //notificationStore.add('', 'Соотношение сторон было изменено у медиа №' + mediaID);

                    }

                }
            }
        });
    }

    render() {
        return null;
    }

}

export default MediaLoop;
