import React from 'react';
import {inject, observer} from "mobx-react";

import APIsettings from "../../api/settings";
import {observable} from "mobx";
import LoaderComponent from "../../templates/loaderComponent";
import SettingsReferrals from "../../templates/settings/referrals";



@inject('initialStore', 'notificationStore')
@observer
class Referrals extends React.Component {

    @observable referrals = false;

    constructor (props) {
        super(props);

    }

    render() {

        const initialStore = this.props.initialStore;

        let content = '';
        if (!this.referrals) {
            return <LoaderComponent>Loading</LoaderComponent>;
        } else {

            const props = {
                url: this.referrals.url,
                transitions: this.referrals.transitions,
                registration: this.referrals.registration,
                earned: this.referrals.earned,
                paidOut: this.referrals.paidOut,
                balance: this.referrals.balance,
                notification: (name) => {this.props.notificationStore.add(name)}
            }

            return <SettingsReferrals {...props}/>;
        }
    }


    componentDidMount() {

        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;
        APIsettings.getReferrals().then((data) => {
            if (data.status) {
                this.referrals = data.data;
            } else {
                alert(text[97]);
            }
        }).catch((e) => {
            alert(text[97]);
        });
    }

}

export default Referrals;