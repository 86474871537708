import qs from "qs";
import React from "react";

import instance from "./base";


const APIaccountsFb = {

    getFBUrl () {
        return instance.post('/accountsfb/fb_url', qs.stringify({

        })).then(data => {
            return data;
        });
    },

    getFBInstagramUrl () {
        return instance.post('/accountsfb/fb_url_instagram', qs.stringify({

        })).then(data => {
            return data;
        });
    },
}

export default APIaccountsFb;
