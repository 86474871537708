import React from 'react';
import {inject, observer} from "mobx-react";

import {observable} from "mobx";
import Checkbox from "../../../templates/elements/checkbox";

import loadFileObrabotchikImageIgtv from "../../../model/loadFileObrabotchikImageIgtv";

@inject('mediaStore', 'accountsStore', 'notificationStore', 'initialStore')
@observer
class IgtvImage extends React.Component {

    @observable open = false;

    @observable style = null;
    constructor (props) {
        super(props);

        const mediaStore = this.props.mediaStore;

        if (mediaStore.IgtvImage) {
            this.open = true;
        }

        this.fileInput = React.createRef();
    }

    render() {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        const accounts = accountsStore.list.slice();
        const accountsOK = mediaStore.accounts.slice();

        let f = 0;
        for (let key in mediaStore.media.slice()) {
            f++;
        }

        for (let key in accountsOK) {

            let idDB = 0;
            for (let keys in accounts) {
                if (accountsOK[key].id === accounts[keys].id) {
                    idDB = keys;
                }
            }
        }

        if (mediaStore.type !== 'igtv' && mediaStore.type !== 'editIgtv' && mediaStore.type !== 'reels' && mediaStore.type !== 'editReels') {
            return null;
        }

        if (mediaStore.mediaLoading) {
            return null;
        }

        let content = null;

        if (this.open) {
            if (!mediaStore.IgtvImage) {
                content = (
                    <>
                        <button type="button" className="btn border btn-primary btn-primary-load-obl" onClick={this.clickFile}>{text[80]}</button>

                        <input className="no-fastclick" style={{display: 'none'}} type="file" accept="image/*" ref={this.fileInput} onChange={this.filOnChange} />
                    </>
                );
            } else {
                content = (
                    <>
                        <button type="button" className="btn border btn-primary btn-primary-load-obl" onClick={this.clickFileDel}>{text[81]}</button>
                    </>
                );
            }

        }

        let render = (
            <div className="group-publication">
                <div className="add-publication__comments">
                    <div className="form-group">
                        <div className="form-check-wrap">
                            <div className="form-check">
                                <div className="column-check">
                                    <span className="title-check">{text[82]}</span>
                                    <p className="desc-check">{text[83]}</p>
                                </div>
                                <Checkbox onChange={this.OnAction}>{this.open}</Checkbox>
                            </div>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        );

        return render;
    }

    componentDidUpdate() {
        const mediaStore = this.props.mediaStore;

        if (mediaStore.IgtvImage) {
            this.open = true;
        }
    }

    clickFile = () => {
        //Удаляем прошлые файлы из формы
        this.fileInput.current.value = null;
        //открываем форму
        this.fileInput.current.click();
    }

    //передаем файлы в обработчик после выбора
    filOnChange = () => {
        //this.handleFiles(this.fileInput.current.files);
        loadFileObrabotchikImageIgtv(this.fileInput.current.files);
    }

    clickFileDel = () => {
        const mediaStore = this.props.mediaStore;
        mediaStore.IgtvImage = false;
    }

    OnAction = (value) => {
        const mediaStore = this.props.mediaStore;

        this.open = value;

        if (!value) {
            mediaStore.IgtvImage = false;
        }
    }
}

export default IgtvImage;
