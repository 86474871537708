import qs from "qs";
import React from "react";
import instance from "./base";


const APImoderation = {

    setTrue (id, code) {
        return instance.post('moderation/true', qs.stringify({
            id: id,
            code: code,
        })).then(data => {
            return data;
        });
    },



}

export default APImoderation;