import React from 'react';

import {inject, observer} from "mobx-react";

@inject('editorStore')
@observer
class MediaRedBNext extends React.Component {

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;
    }

    render() {
        return (
            <div className="item-type">
                <button type="button" className="btn red-button-next" onClick={this.beck}></button>
            </div>
        );
    }

    beck = () => {
        this.fabric.beckIndex();
    }
}

export default MediaRedBNext;