import React from 'react';
import {inject, observer} from "mobx-react";
import directLoad from "../../../../media/gif/directLoad.gif";
import iconNonViev from "../../../../media/svg/eye-slash-solid.svg";

export default
@inject('initialStore')
@observer
class AccountDirectMessageText extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {


            return (
                <div className="chat-message__inner">
                    <div className="inner-content">
                        <div className="column-content">
                            {/*{!this.props.myItem ? <span className="chat-message__name">Adam Denisov</span> : null}*/}
                            <p className="chat-message__text v-text" style={{whiteSpace: 'pre-line'}}>{this.props.text}</p>
                        </div>
                        <div className="column-content date">
                            {!this.props.time ?
                            <img src={directLoad} style={{width:12, height: 12}} />
                            :
                            <time dateTime={this.props.time} className="chat-message__date static">{this.props.time}</time>
                            }

                        </div>
                        {this.props.userNonViev ? <img src={iconNonViev} width={14} height={14} style={{margin: '0 0 0 -5px'}} alt={this.props.userNonViev}/>:null}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="chat-message__inner">
                    <div className="inner-content">
                        <div className="column-content">
                            {/*{!this.props.myItem ? <span className="chat-message__name">Adam Denisov</span> : null}*/}
                            <p className="chat-message__text" style={{whiteSpace: 'pre-line'}}>{this.props.text}</p>
                        </div>
                        <div className="column-content date">
                            {!this.props.time ?
                                <img src={directLoad} style={{width:12, height: 12}} />
                                :
                                <time dateTime={this.props.time} className="chat-message__date static">{this.props.time}</time>
                            }

                        </div>
                        {this.props.userNonViev ? <img src={iconNonViev} width={14} height={14} style={{margin: '0 0 0 -5px'}} alt={this.props.userNonViev}/>:null}
                    </div>
                </div>
            );
        }
    }

}