import React from 'react';
import {inject, observer} from "mobx-react";
import TitleH1 from "../elements/text/titleH1";
import Input from "../elements/input";
import ButtonLoad from "../elements/buttonLoad";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CheckBoxGreen from "../elements/checkBoxGreen";

export default
@inject('initialStore')
@observer
class SettingsNotificationsElement extends React.Component {

    render() {

        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return (
                <tr>
                    <td>{this.props.name}</td>
                    <td>
                        <CheckBoxGreen action={this.props.actionEmail}>{this.props.email}</CheckBoxGreen>
                    </td>
                    <td>
                        <CheckBoxGreen action={this.props.actionTelegram}>{this.props.telegram}</CheckBoxGreen>
                    </td>
                    {/*<td>
                        {this.props.actionPhone ? <CheckBoxGreen action={this.props.actionPhone}>{this.props.phone}</CheckBoxGreen>:null}
                    </td>*/}
                </tr>
            );
        } else {
            return (
                <tr>
                    <td>{this.props.name}</td>
                    <td>
                        <CheckBoxGreen action={this.props.actionEmail}>{this.props.email}</CheckBoxGreen>
                    </td>
                    <td>
                        <CheckBoxGreen action={this.props.actionTelegram}>{this.props.telegram}</CheckBoxGreen>
                    </td>
                    {/*<td>
                        {this.props.actionPhone ? <CheckBoxGreen action={this.props.actionPhone}>{this.props.phone}</CheckBoxGreen>:null}
                    </td>*/}
                </tr>
            );
        }

    }
}

