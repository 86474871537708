import React from 'react';

import {inject, observer} from "mobx-react";

@inject('editorStore', 'notificationStore')
@observer
class MediaRedBTextItalic extends React.Component {

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;
    }

    render() {
        return (
            <>
                <div className="item-type">
                    <button type="button" className="btn red-button-text-italic" onClick={this.italic}></button>
                </div>
            </>
        );
    }

    italic = () => {
        this.fabric.elementTextItalic();
    }
}

export default MediaRedBTextItalic;