import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

export default
@inject('initialStore')
@observer
class PostsBox extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <div className="posts-list">

                    {this.props.children}

                <div className="more-btn bottom">
                    {this.props.button}
                </div>
            </div>;
        } else {
            return <div className="posts-list">
                <div className="posts row view-1">
                    {this.props.children}
                </div>
                <div className="more-btn bottom">
                    {this.props.button}
                </div>
            </div>;
        }

    }

}

