import buttonConfirmation from "./buttonConfirmation";
import button from "./button";
import code from "./code";
import menuCreatePost from "./menuCreatePost";
import settings from "./settings";
import rate from "./rate";
import time from "./time";
import login from "./login";
import library from "./library";
import post from "./post"
import addAccount from "./addAccount";
import account from "./account";
import addPost from "./addPost";
import welcome from "./welcome";
import help from "./help";

const ru = {
    buttonConfirmation: buttonConfirmation.ru,
    button: button.ru,
    code: code.ru,
    menu: {
        savedPosts: 'Библиотека постов',
        downloadFromInstagram: 'Скачать из Instagram',
        rates: 'Тариф',
        settings: 'Настройки',
        help: 'Чат поддержки',
        exit: 'Выход',
        addAccount: 'Добавить аккаунт',
        beck: 'Старая версия',
    },
    menuCreatePost: menuCreatePost.ru,
    emoji: {
        search: 'Поиск',
        clear: 'Закрыть', // Accessible label on "clear" button
        notfound: 'Эмодзи не найдено',
        skintext: 'Выберите свой оттенок кожи по умолчанию',
        categories: {
            search: 'Результат поиска',
            recent: 'Часто используемые',
            people: 'Смайлики и люди',
            nature: 'Животные и природа',
            foods: 'Еда и напитки',
            activity: 'Активность',
            places: 'Путешествия и местности',
            objects: 'Предметы',
            symbols: 'Символы',
            flags: 'Флаги',
            custom: 'Кастомные',
        },
        categorieslabel: 'Эмодзи категории', // Accessible title for the list of categories
        skintones: {
            1: 'Тон кожи по умолчанию',
            2: 'Светлый тон кожи',
            3: 'Средне-светлый оттенок кожи',
            4: 'Средний тон кожи',
            5: 'Средне-темный оттенок кожи',
            6: 'Темный оттенок кожи',
        },
    },
    settings: settings.ru,
    rate: rate.ru,
    time: time.ru,
    login: login.ru,
    library: library.ru,
    post: post.ru,
    addAccount: addAccount.ru,
    account: account.ru,
    addPost: addPost.ru,
    welcome: welcome.ru,
    help: help.ru,

}

const en = {
    buttonConfirmation: buttonConfirmation.en,
    button: button.en,
    code: code.ru,
    menu: {
        savedPosts: 'Library Posts',
        downloadFromInstagram: 'Download from Instagram',
        rates: 'Rate',
        settings: 'Settings',
        help: 'Help',
        exit: 'Exit',
        addAccount: 'Add account',
        beck: 'Old version',
    },
    menuCreatePost: menuCreatePost.en,
    emoji: {
        search: 'Search',
        clear: 'Clear', // Accessible label on "clear" button
        notfound: 'No Emoji Found',
        skintext: 'Choose your default skin tone',
        categories: {
            search: 'Search Results',
            recent: 'Frequently Used',
            people: 'Smileys & People',
            nature: 'Animals & Nature',
            foods: 'Food & Drink',
            activity: 'Activity',
            places: 'Travel & Places',
            objects: 'Objects',
            symbols: 'Symbols',
            flags: 'Flags',
            custom: 'Custom',
        },
        categorieslabel: 'Emoji categories', // Accessible title for the list of categories
        skintones: {
            1: 'Default Skin Tone',
            2: 'Light Skin Tone',
            3: 'Medium-Light Skin Tone',
            4: 'Medium Skin Tone',
            5: 'Medium-Dark Skin Tone',
            6: 'Dark Skin Tone',
        },
    },
    settings: settings.en,
    rate: rate.en,
    time: time.en,
    login: login.en,
    library: library.en,
    post: post.en,
    addAccount: addAccount.en,
    account: account.en,
    addPost: addPost.en,
    welcome: welcome.en,
    help: help.en,
}

const index = {
    ru: ru,
    en: en,
}

export default index;
