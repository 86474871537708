import React from 'react';
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";

export default
@inject('initialStore')
@observer
class HeaderBoxLiElement extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <li className={this.props.active ? 'is-active':null}>
                <Link to={this.props.active ? null:this.props.url}>{this.props.name} {this.props.info ? <span className="count">{this.props.info}</span>:null}</Link>
            </li>;
        } else {
            return <li className={this.props.active ? 'is-active':null}>
                    <Link to={this.props.url}>{this.props.name}<span className="count"> {this.props.info}</span></Link>
                </li>;
        }

    }
}

