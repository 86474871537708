import React from 'react';
import {inject, observer} from "mobx-react";

export default
@inject('initialStore')
@observer
class TemplateModalAddAccountBox extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {

            let render = <>
                <div className="info-wrap">
                    <div className="info-scroll">
                        <div className="info-box-wrap">
                            <div className="info-box chief width-4">
                                {this.props.content}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-overlay"></div>
            </>;

            return render;
        } else {
            let classElement = "info-wrap";
            let classElement2 = "info-box chief width-4";
            let classElement3 = "info-overlay";
            if (this.props.initialStore.width < 993) {
                /*classElement = "info-wrap editor-wrap";
                classElement2 = "info-box info-boxW chief width-4";
                classElement3 = "info-overlay info-overlayW";*/
            }

            let render = <>
                <div className={classElement}>
                    <div className="info-scroll">
                        <div className="info-box-wrap">
                            <div className={classElement2} ref={this.props.window}>
                                {this.props.content}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classElement3}></div>
            </>;

            return render;
        }

    }
}

