import React from 'react';
import {inject, observer} from "mobx-react";

import {getProxy} from "../../../actions/account/proxy";
import LoaderComponent from "../../../templates/loaderComponent";
import AccountProxyIndex from "../../../templates/account/proxy";
import {observable} from "mobx";
import APIproxy from "../../../api/proxy";

@inject('accountsStore', 'menuStore', 'initialStore', 'notificationStore')
@observer
class Proxy extends React.Component {

    @observable ip = false;
    @observable port = false;
    @observable login = false;
    @observable password = false;

    constructor(props) {
        super(props);

        this.ipChange = this.ipChange.bind(this);
        this.portChange = this.portChange.bind(this);
        this.loginChange = this.loginChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        this.save = this.save.bind(this);
        this.delite = this.delite.bind(this);
    }

    render() {

        const accountsStore = this.props.accountsStore;
        const accounts = accountsStore.active;
        const proxy = accountsStore.list[accounts].proxy.list;

        let content = <LoaderComponent/>;
        if (this.ip !== false) {

            let statusRed = true;
            if (proxy) {
                if (proxy.status) {

                    if (this.ip === proxy.ip && this.port === proxy.port && this.login === proxy.login && this.password === proxy.password) {
                        statusRed = false;
                    }
                }
            }


            const dataTemplate = {
                ip: this.ip,
                ipChange: this.ipChange,
                port: this.port,
                portChange: this.portChange,
                login: this.login,
                loginChange: this.loginChange,
                password: this.password,
                passwordChange: this.passwordChange,
                statusJob: proxy.statusJob,
                status: proxy.status,
                statusRed: statusRed,
                save: this.save,
                delite: this.delite,
            }

            content = <AccountProxyIndex {...dataTemplate}
                                         account={accountsStore.list[accountsStore.active].name}
                                         typeAccount={accountsStore.list[accountsStore.active].tip}
                                         imgAccount={accountsStore.list[accountsStore.active].img}
            />;
        }

        return content;
    }

    save (promise) {
        const initialStore = this.props.initialStore;
        const accountsStore = this.props.accountsStore;
        const notificationStore = this.props.notificationStore;

        const accounts = accountsStore.active;
        const account = accountsStore.list[accounts].id;
        const proxy = accountsStore.list[accounts].proxy.list;
        const text = initialStore.text.code;

        APIproxy.save(account, this.ip, this.port, this.login, this.password).then((data) => {
            if (data.status) {
                if (!proxy.status) {
                    accountsStore.list[accounts].proxy.list = {
                        status: true,
                        ip: this.ip,
                        port: this.port,
                        login: this.login,
                        password: this.password,
                        statusJob: {
                            status: true,
                            listError: [],
                        },
                    };

                    accountsStore.list[accounts].proxyInstall = true;
                    notificationStore.add(text[120]);
                }
            } else {
                notificationStore.add(text[28], initialStore.text.code[data.code]);
            }
            promise();
        }).catch((e)=> {
            notificationStore.add(text[28], initialStore.text.code[0]);
            promise();
        });
    }

    delite (promise) {
        const initialStore = this.props.initialStore;
        const accountsStore = this.props.accountsStore;
        const notificationStore = this.props.notificationStore;

        const accounts = accountsStore.active;
        const account = accountsStore.list[accounts].id;
        const proxy = accountsStore.list[accounts].proxy.list;
        const text = initialStore.text.code;

        APIproxy.del(account).then((data) => {
            if (data.status) {
                accountsStore.list[accounts].proxy.list = {
                    status: false,
                };

                this.ip = '';
                this.port = '';
                this.login = '';
                this.password = '';

                accountsStore.list[accounts].proxyInstall = false;
                notificationStore.add(text[121]);
            } else {
                notificationStore.add(text[28], initialStore.text.code[data.code]);
            }
            promise();
        }).catch((e)=> {
            notificationStore.add(text[28], initialStore.text.code[0]);
            promise();
        });
    }

    ipChange (value) {
        this.ip = value;
    }

    portChange (value) {
        this.port = value;
    }

    loginChange (value) {
        this.login = value;
    }

    passwordChange (value) {
        this.password = value;
    }


    componentDidMount() {
        this.props.menuStore.updateActiveComponentAccount('proxy');
        this.update();
    }

    componentDidUpdate() {
        const accountsStore = this.props.accountsStore;
        const accounts = accountsStore.active;
        const proxy = accountsStore.list[accounts].proxy.list;

        if (proxy !== null && this.ip === false) {
            if (proxy.status) {
                this.ip = proxy.ip;
                this.port = proxy.port;
                this.login = proxy.login;
                this.password = proxy.password;
            } else {
                this.ip = '';
                this.port = '';
                this.login = '';
                this.password = '';
            }

        }


        this.update();
    }

    update() {

        const accountsStore = this.props.accountsStore;
        const accounts = accountsStore.active;

        const idAccount = accountsStore.list[accounts].id;
        const hesh = accountsStore.list[accounts].proxy.hesh;
        const load = accountsStore.list[accounts].proxy.load;

        if (!load) {
            getProxy(idAccount, accounts, hesh);
        }
    }
}

export default Proxy;