import React from 'react';
import {inject, observer} from "mobx-react";

import HeaderBoxLiElement from "../../templates/header/boxLIElement";
import HeaderBox from "../../templates/header/box";
import HeaderMobileName from "../../templates/header/mobileName";

@inject('menuStore', 'accountsStore', 'initialStore')
@observer
class HeaderAccount extends React.Component {

    render() {
        const accountsStore = this.props.accountsStore;
        const menuStore = this.props.menuStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        if (menuStore.activeComponentAccount === 'patterns') {
            return <HeaderMobileName name={text[0]} />;
        } else if (menuStore.activeComponentAccount === 'timetable') {
            return <HeaderMobileName name={text[1]} />;
        } else if (menuStore.activeComponentAccount === 'employees') {
            return <HeaderMobileName name={text[2]} />;
        } else if (menuStore.activeComponentAccount === 'proxy') {
            return <HeaderMobileName name={text[3]} />;
        }

        let id = false;
        let name = false;
        let tip = false;
        let tipStory = false;
        let rights = {root: false, basic: false, moderation: false, viewPost: false, editingPost: false, addUrl: false, history: false, statistics: false, direct: false};

        let posts = 0;
        let storys = 0;


        if (accountsStore.active !== null) {
                id = accountsStore.list[accountsStore.active].id;
                name = accountsStore.list[accountsStore.active].name;
                tip = accountsStore.list[accountsStore.active].tip;
                tipStory = accountsStore.list[accountsStore.active].storyTrue;
                rights = accountsStore.list[accountsStore.active].rights;

            if (accountsStore.list[accountsStore.active].post.list) {
                posts = 0;
                for (const item of accountsStore.list[accountsStore.active].post.list.slice()){
                    if (item.url) {
                        continue;
                    }else if (item.status) {
                        continue;
                    } else {
                        posts++;
                    }
                }

                if (!posts) {
                    posts = '';
                }
            }

            if (accountsStore.list[accountsStore.active].story.list) {
                storys = accountsStore.list[accountsStore.active].story.list.slice().length;
            }


        }

        const newMessageDirect = accountsStore.newMessage[accountsStore.active];

        let story;
        let statistics;
        let direct;




        let activeElementName = '';

        let postActive = false;

        if (menuStore.activeComponentAccount === 'post' || menuStore.activeComponentAccount === 'history' || menuStore.activeComponentAccount === 'preview') {
            postActive = true;
            activeElementName = text[6];
        }



        const post = <HeaderBoxLiElement
                        url={'/account/'+id}
                        active={postActive}
                        name={text[6]}
                        info={posts}/>;

        if (tip === 'instagram' || tip === 'vk' && tipStory) {
            let activeStory = menuStore.activeComponentAccount === 'story' ? true:false;

            if (activeStory) {
                activeElementName = text[7];
            }

            story = <HeaderBoxLiElement
                url={'/account/'+ id + '/story'}
                active={activeStory}
                name={text[7]}
                info={storys}/>;
        }

        if (tip === 'instagram') {
            if (rights.viewPost) {

            }


            if (rights.statistics) {
                const active = menuStore.activeComponentAccount === 'statistics' ? true:false;

                if (active) {
                    activeElementName = text[8];
                }

                statistics = <HeaderBoxLiElement
                    url={'/account/'+ id + '/statistics'}
                    active={active}
                    name={text[8]}/>;
            }

            if (rights.direct) {
                const active = menuStore.activeComponentAccount === 'direct' ? true:false;

                if (active) {
                    activeElementName = text[9];
                }

                direct = <HeaderBoxLiElement
                        url={'/account/'+ id + '/direct'}
                        active={active}
                        name={text[9]}
                        info={newMessageDirect}/>;
            }

        }



        return <HeaderBox activeElementName={activeElementName}>
                {post}
                {story}
                {statistics}
                {direct}
            </HeaderBox>;
    }
}

export default HeaderAccount;
