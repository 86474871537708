import React from 'react';
import {inject, observer} from "mobx-react";
import ButtonLoad from "../../elements/buttonLoad";

export default
@inject('initialStore')
@observer
class TemplateModalAddAccountIndex extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;

        if (initialStore.templates === 'desktop') {

            let render = <>
                <span className="title-box center">{text[0]}</span>
                <div className="login">
                    <div className="login-type">

                        <button type="button" onClick={this.props.instagram} className="btn btn-block instagram">Instagram</button>
                        <div className="info-add-account">Подключение с логином и паролем, используя Android Emulator. Поддержка всех аккаунтов.</div>


                        <ButtonLoad className={'btn btn-block instagram'} action={this.props.fbInstagram}>fb_inst</ButtonLoad>
                        <div className="info-add-account">Подключение через официальный Instagram API. Работает только с бизнес-аккаунтами. Ограниченные возможности.</div>

                        <ButtonLoad className={'btn btn-block vk'} action={this.props.vk}>vk</ButtonLoad>

                        <ButtonLoad className={'btn btn-block fb'} action={this.props.fb}>fb</ButtonLoad>

                        <ButtonLoad className={'btn btn-block odnoklassniki'} action={this.props.ok}>ok</ButtonLoad>

                        <button onClick={this.props.tg} type="button" className="btn btn-block telegram">Telegram</button>

                        <ButtonLoad className={'btn btn-block twitter'} action={this.props.twitter} type="button">twitter</ButtonLoad>

                        {/*<div onClick={this.props.viber} className="login-type-info-box">скоро</div>
                        <button onClick={this.props.viber} type="button" className="btn btn-block viber">Viber</button>

                        <div onClick={this.props.pinterest} className="login-type-info-box">скоро</div>
                        <button onClick={this.props.pinterest} type="button" className="btn btn-block pinterest">Pinterest</button>*/}

                        {/*<button type="button" className="btn btn-block mail">mail.ru</button>*/}

                    </div>
                    <div className="more-btn btns-box">
                        <button type="button" className="btn border btn-primary" style={{width: '100%'}} onClick={this.props.close}>{text[1]}</button>
                    </div>
                </div>

            </>;

            return render;
        } else {
            let render = <>
                <span className="title-box center">{text[0]}</span>
                <div className="login">
                    <div className="login-type">

                                <button type="button" onClick={this.props.instagram} className="btn btn-block instagram">Instagram</button>
                                <div className="info-add-account">Подключение с логином и паролем, используя Android Emulator. Поддержка всех аккаунтов.</div>

                            <ButtonLoad className={'btn btn-block instagram'} action={this.props.fbInstagram}>fb_inst</ButtonLoad>
                        <div className="info-add-account">Подключение через официальный Instagram API. Работает только с бизнес-аккаунтами. Ограниченные возможности.</div>

                        <ButtonLoad className={'btn btn-block vk'} action={this.props.vk}>vk</ButtonLoad>

                        <ButtonLoad className={'btn btn-block fb'} action={this.props.fb}>fb</ButtonLoad>

                        <ButtonLoad className={'btn btn-block odnoklassniki'} action={this.props.ok}>ok</ButtonLoad>

                        <button onClick={this.props.tg} type="button" className="btn btn-block telegram">Telegram</button>

                        <ButtonLoad className={'btn btn-block twitter'} action={this.props.twitter} type="button">twitter</ButtonLoad>

                        {/*<div onClick={this.props.viber} className="login-type-info-box">скоро</div>
                        <button onClick={this.props.viber} type="button" className="btn btn-block viber">Viber</button>

                        <div onClick={this.props.pinterest} className="login-type-info-box">скоро</div>
                        <button onClick={this.props.pinterest} type="button" className="btn btn-block pinterest">Pinterest</button>*/}

                        {/*<button type="button" className="btn btn-block mail">mail.ru</button>*/}
                    </div>
                    <div className="more-btn btns-box">
                        <button type="button" className="btn border btn-primary" style={{width: '100%'}} onClick={this.props.close}>{text[1]}</button>
                    </div>
                </div>

            </>;

            return render;
        }
    }
}
