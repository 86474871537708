import React from 'react';

import {inject, observer} from "mobx-react";

@inject('editorStore')
@observer
class MediaRedBOpasity extends React.Component {

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;
    }

    render() {
        return (
            <>
                <div className="item-type">
                    <button type="button" className="btn red-button-opasity-min" onClick={this.min}></button>
                </div>
                <div className="item-type">
                    <button type="button" className="btn red-button-opasity-max" onClick={this.max}></button>
                </div>
            </>
        );
    }

    min = () => {
        this.fabric.elementOpacity('min');
    }

    max = () => {
        this.fabric.elementOpacity('max');
    }
}

export default MediaRedBOpasity;