import React from 'react';
import {inject, observer} from "mobx-react"
import Time from "../model/time";
import souds from "../media/sound/notifikation.mp3";
import mainStore from "../stor/mainStore";

@inject('initialStore', 'menuStore', 'mediaStore', 'chatStore', 'notificationStore')
@observer
class ChatSetup extends React.Component {



    socket = false;
    socketStatus = false;
    clouse = false;
    idColback = [];

    constructor(props) {
        super(props);
    }

    startChat = () => {
        this.socket = new WebSocket('wss://chat.chatra.io/sockjs/011/nrkfinbu/websocket');

        this.webSocket();
    }

    str_rand = (num) => {
        var result       = '';
        var words        = '0123456789qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM';
        var max_position = words.length - 1;
        for( let i = 0; i < num; ++i ) {
            const position = Math.floor ( Math.random() * max_position );
            result = result + words.substring(position, position + 1);
        }
        return result;
    }


    render() {

        const initialStore = this.props.initialStore;
        const chatStore = this.props.chatStore;

        if (initialStore.idUser && !this.socket) {
            //генератор clientId
            chatStore.clientId = initialStore.clientId;

            //генератор opened
            const opened = this.str_rand(17);
            chatStore.opened = opened;

            chatStore.openChat = this.openChat;
            chatStore.closeChat = this.closeChat;
            chatStore.updateMessage = this.updateMessage;
            chatStore.submitMessage = this.submitMessage;
            chatStore.getHistoryMessages = this.getHistoryMessages;
            chatStore.markClientAsRead = this.markClientAsRead;

            chatStore.messageId = this.str_rand(17);

            this.startChat();

        }

        return null;
    }

    componentDidMount() {
        const chatStore = this.props.chatStore;
    }

    componentDidUpdate() {
        const chatStore = this.props.chatStore;

    }

    componentWillUnmount() {
        if (this.socket) {
            this.clouse = true;
            this.socket.close();
        }

    }


    socketSend = (array) => {

        const converter = (array) => {
            let array2 = JSON.stringify(array)
            return JSON.stringify([array2]);
        };
        if (this.socket) {
            this.socket.send(converter(array));
        }

    }



    webSocket = () => {
        const chatStore = this.props.chatStore;
        const initialStore = this.props.initialStore;
        const notificationStore = this.props.notificationStore;
        const hostId = chatStore.hostId;
        const clientId = chatStore.clientId;
        const mediaStore = this.props.mediaStore;

        let idColback = this.idColback;

        const socketSend = this.socketSend;
        const connectDataNext = this.connectDataNext;

        const str_rand = this.str_rand;
        // Connection opened
        const connectData = this.connectData;

        const addEventListenerOpen = () => {
            this.socketStatus = true;
            connectData();
        }
        this.socket.addEventListener('open', function (event) {
            addEventListenerOpen();
        });

        const startChat = this.startChat;
        const addEventListenerClose = () => {
            if (!this.clouse) {
                chatStore.message = null;
                this.socket = false;
                this.socketStatus = false;
                if (chatStore.chatStatusFalse) {
                    chatStore.chatStatusFalse();
                }
                startChat();
            }


        }
        this.socket.addEventListener('close', function (event) {
            addEventListenerClose();
        });

        this.socket.addEventListener('error', function (event) {
            addEventListenerClose();
        });

        // Listen for messages

        const addEventListenerMessage = (event) => {
            const converter = (str) => {
                if (str === 'c[1000,"Normal closure"]') {
                    return false;
                }
                if (str === 'o') {
                    return false;
                }

                let se = JSON.parse(str.slice(1));
                return JSON.parse(se);
            };

            let result = converter(event.data);

            if (result.msg) {
                if (result.msg === "ping") {

                    socketSend({"msg":"pong"});

                } else if (result.msg === "result") {
                    if (result.id === '5') { //получение id клиента
                        chatStore.clientIdHash = result.result;

                        connectDataNext();

                        const idResult = chatStore.getIdResult;
                        const createdAt = new Date().getTime();
                    } else {

                        if (result.result) {
                            for (let elementID in idColback) {
                                if (idColback[elementID].id === result.id) {

                                    idColback[elementID].function(result);

                                    idColback[elementID].id = 0;
                                    idColback[elementID].function = null;
                                }

                            }
                        }


                    }
                } else if (result.msg === "added") {
                    if (result.collection === "UsersPubSub") { //добавляются операторы
                        const newOperation = [];
                        for (let operation of chatStore.operation.slice()) {
                            if (operation.id !== result.id) {
                                newOperation.push(operation);
                            }
                        }
                        newOperation.push(result);
                        chatStore.operation = newOperation;
                    } else if (result.collection === "Messages") { //добавляются сообщения

                        if (chatStore.message) {
                            const newMessage = [];
                            for (let message of chatStore.message.slice()) {
                                if (message._id !== result.id) {
                                    newMessage.push(message);
                                }
                            }
                            newMessage.push({
                                _id: result.id,
                                ...result.fields,
                            });
                            chatStore.message = newMessage;

                            if (chatStore.nextButtonChat) {
                                chatStore.nextButtonChat();
                            }

                            //Информирование о новом сообщении
                            if (result.fields.userId) {
                                if (result.fields.message) {

                                    //Воспроизводим звук
                                    const audio = new Audio();
                                    audio.preload = 'auto';
                                    audio.src = souds;
                                    audio.play();

                                    const openDialog = () => {
                                        //перенаправляем пользователя на страницу директа
                                        initialStore.redirect('/help');
                                    }

                                    if (!chatStore.nextButtonChat) {
                                        notificationStore.add('Чат поддержки', result.fields.message, 15, 'Открыть чат', openDialog);
                                    }
                                }



                            }

                        }
                    } else if (result.collection === "Clients") { //Добавляются данные клиента (важжное Дата до какого сообщения прочитаны)
                        chatStore.clientsData = result.fields;

                        this.newMessage();
                    } else if (result.collection === "Typings") {
                        if (result.fields) {
                            if (!result.fields[result.id]) {
                                chatStore.operatorPrints = true;
                            }
                        } else if (result.cleared) {
                            if (!result.cleared[result.id]) {
                                chatStore.operatorPrints = false;
                            }
                        }
                    }

                } else if (result.msg === "changed") { //обновление данных
                    if (result.collection === "Clients") {
                        let clientsData = {
                            ...chatStore.clientsData,
                            ...result.fields,
                        }
                        chatStore.clientsData = clientsData;

                        this.newMessage();
                    } else if (result.collection === "Typings") {

                        if (result.fields) {
                            if (!result.fields[result.id]) {
                                chatStore.operatorPrints = true;
                            }

                        } else if (result.cleared) {
                            if (!result.cleared[result.id]) {
                                chatStore.operatorPrints = false;
                            }

                        }

                    } else if (result.collection === "Messages") {

                        if (chatStore.message) {
                            const newMessage = [];
                            for (let message of chatStore.message.slice()) {
                                if (message._id !== result.id) {
                                    newMessage.push(message);
                                } else {
                                    newMessage.push({
                                        ...message,
                                        ...result.fields,
                                    });
                                }
                            }

                            chatStore.message = newMessage;
                        }
                    }
                }
            }

            console.log(result);
        }
        this.socket.addEventListener('message', function (event) {
            addEventListenerMessage(event);
        });
    }


    //получение сообщений
    getHistoryMessagesOne = true;

    getHistoryMessages = (coolback = false) => {
        const chatStore = this.props.chatStore;
        const hostId = chatStore.hostId;
        const clientId = chatStore.clientId;
        const clientIdHash = chatStore.clientIdHash;
        const idResult = chatStore.getIdResult;
        const messageId = chatStore.messageId;

        const createdAt = new Date().getTime();

        const id = idResult();

        let start = 0;

        if (chatStore.message) {
            start = chatStore.message.slice().length;
        }

        this.socketSend({
            "msg":"method",
            "method":"getHistoryMessages",
            "params":[clientIdHash,createdAt,25,start],
            "id":id,
        });

        const getHistoryMessagesColback = (data) => {

            const chatStore = this.props.chatStore;

            if (data.result) {
                if (data.result.length) {
                    if (!chatStore.message) {

                        let array = data.result;
                        array.reverse();

                        chatStore.message = array;
                    } else {
                        let array = data.result;
                        array.reverse();

                        let newMessage = array;
                        for (let mes of chatStore.message.slice()) {
                            newMessage.push(mes);
                        }

                        chatStore.message = newMessage;
                    }

                    chatStore.messageNext = true;
                } else {
                    if (!chatStore.message) {
                        chatStore.message = [];
                    }
                    chatStore.messageNext = false;
                }

            } else {
                chatStore.messageNext = false;
            }

            this.newMessage(this.getHistoryMessagesOne);
            this.getHistoryMessagesOne = false;
            if (coolback) {
                coolback();
            }
        }

        this.idColback.push({
            'id': id,
            'function': getHistoryMessagesColback,
        });

    }

    //отправка сообщения
    submitMessage = (text) => {
        const chatStore = this.props.chatStore;
        const hostId = chatStore.hostId;
        const clientId = chatStore.clientId;
        const clientIdHash = chatStore.clientIdHash;
        const idResult = chatStore.getIdResult;
        const messageId = chatStore.messageId;

        const createdAt = new Date().getTime();

        this.socketSend({
            "msg":"method",
            "method":"submitMessage",
            "params":[{
                "messageId": messageId, //случайные цифры
                "message":{
                    "message": text,
                    "clientId": clientIdHash,
                    "createdAt": createdAt},
                "externalClientId":clientId}],
            "id":idResult(),
            "randomSeed":this.str_rand(20) //случайные цифры
        });

        chatStore.messageId = this.str_rand(17);
    }

    //отправка ввода сообщения
    updateMessage = (text) => {
        const chatStore = this.props.chatStore;
        const hostId = chatStore.hostId;
        const clientId = chatStore.clientId;
        const clientIdHash = chatStore.clientIdHash;
        const idResult = chatStore.getIdResult;
        const messageId = chatStore.messageId;
        this.socketSend({
            "msg":"method",
            "method":"updateMessage",
            "params":[{
                "clientId": clientIdHash,
                "externalClientId":clientId,
                "messageId": messageId,
                "text": text}],
            "id": idResult(),
        });

        this.socketSend({
            "msg":"method",
            "method":"setTyping",
            "params":[clientIdHash],
            "id":idResult(),
        });
    }

    //прочитывание сообщений
    markClientAsRead = () => {
        const chatStore = this.props.chatStore;
        const clientIdHash = chatStore.clientIdHash;
        const idResult = chatStore.getIdResult;

        const createdAt = new Date().getTime();

        this.socketSend({
            "msg":"method",
            "method":"markClientAsRead",
            "params":[{
                "clientId":clientIdHash,
                "readAt":createdAt,
            }],
            "id":idResult(),
        });
    }

    connectData = () => {
        const chatStore = this.props.chatStore;
        const hostId = chatStore.hostId;
        const clientId = chatStore.clientId;

        this.socketSend({"msg":"connect","version":"1","support":["1","pre2","pre1"]});
        this.socketSend({"msg":"method","method":"getTime","params":[],"id":"1"});
        this.socketSend({"msg":"method","method":"getConnectionGeo","params":[{"hostId":hostId}],"id":"2"});

        this.socketSend({"msg":"sub","id":"7ygaEXQmxTtSa48eR","name":"host","params":[{"hostId":hostId,"host":"app.likepro.ru","hostName":"app.likepro.ru"}]});
        this.socketSend({"msg":"sub","id":"xNH4QDwsGAukNryvC","name":"agents","params":[{"hostId":hostId}]});

        this.socketSend({"msg":"method","method":"Localizer.getLocale","params":["ru",false],"id":"3"});

        this.socketSend({"msg":"method","method":"getClientId","params":[{"create":false,"subscribe":false,"hostId":hostId,"id":clientId}],"id":"4"});
        this.socketSend({"msg":"method","method":"getClientId","params":[{"create":true,"subscribe":false,"hostId":hostId,"id":clientId}],"id":"5"});
    }

    connectDataNext = () => {
        const chatStore = this.props.chatStore;
        const initialStore = this.props.initialStore
        const hostId = chatStore.hostId;
        const clientId = chatStore.clientId;
        const clientIdHash = chatStore.clientIdHash;
        const createdAt = new Date().getTime();
        const idResult = chatStore.getIdResult;

        let userAgent = "non";

        if (navigator) {
            if (navigator.userAgent) {
                userAgent = navigator.userAgent;
            }
        }


        let phoneCode = initialStore.phoneCode;
        let phone = initialStore.phone;
        let phoneEnter = phone !== '' ? phoneCode + ' ' + phone : 'non';

        const timeRate = Time.convertUnixToDate(initialStore.rateTime);

        //this.socketSend({"msg":"sub","id":this.str_rand(17),"name":"client","params":[clientIdHash,{"timeDiff":11286,"hostname":"likepro.ru","integrationData":{},"hostId":hostId,"id":clientId}]});
        this.socketSend({
            "msg":"sub",
            "id":this.str_rand(17),
            "name":"client",
            "params":[
                clientIdHash,
                {
                    "timeDiff":11286,
                    "hostname":"app.likepro.ru",
                    "integrationData":{
                        "name": initialStore.name,
                        "email":initialStore.mail,
                        "phone": phoneEnter,
                        "notes":"",
                        "Тариф":"Аккаунтов: " + initialStore.rateAccount,
                        "тарифа заканчивается": timeRate.date + ' в ' + timeRate.h + ':' + timeRate.m,
                        "Аккаунты":"vse",
                        "Платформа": initialStore.platform,
                    },
                    "hostId":hostId,
                    "id":clientId}
                    ]
        });

        this.socketSend({"msg":"sub","id":this.str_rand(17),"name":"actualMessages","params":[clientIdHash,createdAt]});

        this.ping();


        this.socketSend({"msg":"method","method":"updateClientSession","params":[clientIdHash,{"userAgent":userAgent,"browserLanguage":"ru-RU","currentPage":"https://likepro.ru/","currentPageTitle":"Отложенный постинг и автопостинг в Инстаграм ВК Фейсбук","displayedLanguage":"ru","referrer":""},{"timeDiff":10191}],"id":"7"});
        this.socketSend({"msg":"method","method":"setGroup","params":[clientIdHash,null],"id":"8"});

        this.socketSend({
            "msg":"method",
            "method":"setClientIntegration",
            "params":[{
                "clientId": clientIdHash,
                "externalClientId": clientId,
                "integrationData":{
                    "name": initialStore.name,
                    "email":initialStore.mail,
                    "phone": phoneEnter,
                    "notes":"",
                    "Тариф":"Аккаунтов: " + initialStore.rateAccount,
                    "тарифа заканчивается": timeRate.date + ' в ' + timeRate.h + ':' + timeRate.m,
                    "Аккаунты":"vse",
                    "Платформа": initialStore.platform,
                },
                "isUpdate":false
            }],
            "id":"9"});


        chatStore.status = true;

        this.getHistoryMessages();
    }

    openChat = () => {
        const chatStore = this.props.chatStore;
        const clientIdHash = chatStore.clientIdHash;
        const opened = chatStore.opened;
        const idResult = chatStore.getIdResult;

        if (chatStore.status) {
            this.socketSend({"msg":"method","method":"updateClient","params":[clientIdHash,{"opened":opened}],"id":idResult()})
        } else {

            let timerId = setInterval(() => {
                if (chatStore.status) {
                    const clientIdHash = chatStore.clientIdHash;
                    const opened = chatStore.opened;
                    this.socketSend({"msg":"method","method":"updateClient","params":[clientIdHash,{"opened":opened}],"id":idResult()});
                    clearInterval(timerId);
                }
            }, 1000);
        }
    }

    closeChat = () => {
        const chatStore = this.props.chatStore;
        const clientIdHash = chatStore.clientIdHash;
        const idResult = chatStore.getIdResult;


        if (chatStore.status) {
            this.socketSend({"msg":"method","method":"updateClient","params":[clientIdHash,{"opened":false}],"id":idResult()});
        } else {
            let timerId = setInterval(() => {
                if (chatStore.status) {
                    const clientIdHash = chatStore.clientIdHash;
                    this.socketSend({"msg":"method","method":"updateClient","params":[clientIdHash,{"opened":false}],"id":idResult()});
                    clearInterval(timerId);
                }
            }, 10000);
        }
    }

    ping = () => {
        const chatStore = this.props.chatStore;

        const idResult = chatStore.getIdResult();
        if (this.socketStatus) {
            this.socketSend({"msg":"method","method":"ping","params":[180000],"id":idResult});
        }


        setInterval(() => {
            if (this.socketStatus) {
                this.socketSend({"msg":"ping"});
            }

        }, 60*1000);

        setInterval(() => {
            if (this.socketStatus) {
                const idResult = chatStore.getIdResult();
                this.socketSend({"msg":"method","method":"ping","params":[180000],"id":idResult});
            }
        }, 180*1000);
    }

    newMessage = (getHistoryMessagesOne) => {
        const chatStore = this.props.chatStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        //время до какого просмотрены сообщения операторами
        let timeVievUser = false;

        if (chatStore.clientsData) {
            if (chatStore.clientsData.readAt) {

                for (let users in chatStore.clientsData.readAt) {

                    if (chatStore.clientIdHash === users) {
                        timeVievUser = chatStore.clientsData.readAt[users];
                    }
                }
            }
        }

        let newMessage = 0;
        if (timeVievUser) {
            if (chatStore.message) {
                for (const mes of chatStore.message.slice()) {
                    if (mes.userId) {
                        if (mes.createdAt > timeVievUser) {
                            newMessage++;
                        }
                    }
                }

                if (newMessage !== chatStore.newMessage) {
                    chatStore.newMessage = newMessage;
                    if (getHistoryMessagesOne && newMessage) {
                        const openDialog = () => {
                            //перенаправляем пользователя на страницу директа
                            initialStore.redirect('/help');
                        }

                        if (!chatStore.nextButtonChat) {
                            notificationStore.add('Чат поддержки', 'У вас есть ' + newMessage + ' новое сообщений', 15, 'Открыть чат', openDialog);
                        }
                    }

                }
            }
        }
    }

}

export default ChatSetup;
