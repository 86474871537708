import React from 'react';
import {inject, observer} from "mobx-react";

import file from "../../../model/file";
import editorMediaClass from "../../../model/eritorMedia";
import APIfile from "../../../api/file";
import mediaHelpers from "../../../model/mediaHelpers";
import loadFileObrabotchik from "../../../model/loadFileObrabotchik";


@inject('mediaStore', 'accountsStore', 'notificationStore')
@observer
class SortTime extends React.Component {

    constructor (props) {
        super(props);
    }

    render() {
        return <button type="button" style={{width: '100%'}} className="btn border btn-primary" onClick={this.click}>Отсортировать по времени</button>;
    }

    click = () => {
        const notificationStore = this.props.notificationStore;
        const mediaStore = this.props.mediaStore;

        let listMulty =  [...mediaStore.multyPost.slice()];

        listMulty.sort((a, b) => a.time > b.time ? 1 : -1);

        let newListMedia = [];

        for (const elementList of listMulty) {

            let id = false;
            for (const elementKey in mediaStore.media.slice()) {
                if (elementList.id === mediaStore.media[elementKey].id) {
                    id = elementKey;
                    break;
                }
            }

            if (id !== false) {
                newListMedia.push({...mediaStore.media[id]});
            }
        }

        mediaStore.media = [...newListMedia];

        notificationStore.add('Выполнено', 'Посты отсортированы по времени');

    }
}




export default SortTime;
