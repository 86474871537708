import React from "react";
import store from "../stor/mainStore";

import {fabric} from 'fabric';

import FontFaceObserver from "fontfaceobserver";

import gifFrames from "gif-frames";

import storyUrlBlack from '../media/svg/storyUrlBlack.png';
import storyUrlWite from '../media/svg/storyUrlWite.png';
import storyUrlBlue from '../media/svg/storyUrlBlue.png';



//модернизация фабрики
fabric.Object.prototype.getAbsolute = function(key) {
    if (this.group) {
        if (key === 'top') {
            return this.calcTransformMatrix()[5];
        } else if (key === 'left') {
            return this.calcTransformMatrix()[4];
        } else if (key === 'angle') {
            return this.angle + this.group.angle;
        } else if (key === 'scaleX') {
            return this.scaleX * this.group.scaleX;
        } else if (key === 'scaleY') {
            return this.scaleY * this.group.scaleY;
        }
    }
    return this[key];
};

fabric.Object.prototype.cornerStyle="circle";
fabric.Object.prototype.borderColor="#7b5c9b";
fabric.Object.prototype.transparentCorners=false;
fabric.Object.prototype.cornerColor="#7b5c9b";
fabric.Object.prototype.cornerSize=15;
fabric.Object.prototype.rotatingPointOffset=29;



//загрузка шрифтов
let loadFont = (name) => {
    var font = new FontFaceObserver(name);
    font.load('ЯЖГОВОРИЛBETA').then(function () {
        //console.log('YES Font is  available ' + name);
    }, function () {
        console.log('Font is not available ' + name);
    });
}
loadFont('fabric-mode');
loadFont('fabric-neon');
loadFont('fabric-alaRuss');
loadFont('AvenyT');
loadFont('HelveticaNeueCyrBold');
loadFont('HelveticaNeueCyrMedium');

loadFont('TimesNewRoman');
loadFont('YanoneKaffeesatz');

loadFont('Lobster');
loadFont('Comfortaa');
loadFont('Exo2');
loadFont('Pacifico');
loadFont('Caveat');











let fabricWidth = 0;
let fabricHeight = 0;

let canvas = false;
let elements = [];
let saveFunction = false;

//какой элемент выбран
let canvasEnter = false;
let canvasEnterFunction = false;

let funOpenInfoDelite = () => {};

//иконка основного аккаунта
let iconAccount = false;

//иконки для ссылки на истории
let iconUrlBlack = false;
let iconUrlWite = false;
let iconUrlBlue = false;

const Fabric = {

    start (id, width, height, data, saveElements = () => {}, canvasEnterFunctions = () => {}, funOpenInfoDel = () => {}, icon, GIF = true) {

        return new Promise(async (resolve, reject) => {

            fabricWidth = width;
            fabricHeight = height;

            saveFunction = saveElements;
            canvasEnterFunction=canvasEnterFunctions;

            funOpenInfoDelite = funOpenInfoDel;

            canvas = new fabric.Canvas(id, {width: width, height:height});

            //запрет выделения нескольких элементов
            canvas.selection = false;

            //модификация объекта
            canvas.on('object:modified', function (options) {
                saveFunction();
                if (options.by === undefined) {
                    let canvasElementMoving = options.target;
                    let bound = canvasElementMoving.getBoundingRect(true, true);
                    if (fabricWidth < bound.left || fabricHeight < bound.top || bound.width * -1 > bound.left || bound.height * -1 > bound.top) {
                        canvas.remove(canvas.getActiveObject());
                        funOpenInfoDelite(false);
                        saveFunction();
                    }
                }
            });
            //перемещение объекта
            canvas.on('object:moving', function(options) {
                let canvasElementMoving = options.target;
                let bound = canvasElementMoving.getBoundingRect(true, true);
                if (fabricWidth < bound.left || fabricHeight < bound.top || bound.width * -1 > bound.left || bound.height * -1 > bound.top) {
                    funOpenInfoDelite(true);
                } else {
                    funOpenInfoDelite(false);
                }
            });
            //увеличение объекта
            canvas.on('object:scaling', function(options) {
                let canvasElementMoving = options.target;
                let bound = canvasElementMoving.getBoundingRect(true, true);
                if (fabricWidth < bound.left || fabricHeight < bound.top || bound.width * -1 > bound.left || bound.height * -1 > bound.top) {
                    funOpenInfoDelite(true);
                } else {
                    funOpenInfoDelite(false);
                }
            });
            //вращение элемента
            canvas.on('object:rotating', function(options) {
                let canvasElementMoving = options.target;
                let bound = canvasElementMoving.getBoundingRect(true, true);
                if (fabricWidth < bound.left || fabricHeight < bound.top || bound.width * -1 > bound.left || bound.height * -1 > bound.top) {
                    funOpenInfoDelite(true);
                } else {
                    funOpenInfoDelite(false);
                }
            });
            //окончание увеличение объекта
            canvas.on('object:scaled', function(options) {
                let canvasElementMoving = options.target;
                let bound = canvasElementMoving.getBoundingRect(true, true);
                if (fabricWidth < bound.left || fabricHeight < bound.top || bound.width * -1 > bound.left || bound.height * -1 > bound.top) {
                    canvas.remove(canvas.getActiveObject());
                    funOpenInfoDelite(false);
                    saveFunction();
                }
            });
            //смена выбранного элемента
            canvas.on('selection:updated', function (options) {
                canvasEnter = options.target ? options.target:false;
                canvasEnterFunction(canvasEnter);
                funEditElementFinish();
                saveFunction();
            });
            //выбор элемента
            canvas.on('object:selected', function(options) {
                canvasEnter = options.target ? options.target:false;
                canvasEnterFunction(canvasEnter);
                funEditElementFinish();
                saveFunction();
            });
            //отмена выбора элемента
            canvas.on('before:selection:cleared', function(options) {
                canvasEnter = false;
                canvasEnterFunction(canvasEnter);
                funEditElementFinish();
                saveFunction();
            });


            let funEditElementFinish = () => {
                //проверяем элементы, что они не имеют пустую информацию, если имеют то удаляем или редачим
                let texBoxItems = canvas.getObjects();

                for(var i=0; i<texBoxItems.length; i++ ){

                    if (texBoxItems[i].get('type') === "hashtag") {
                        if (canvasEnter) {
                            if (canvasEnter.type === 'hashtag') {
                                continue;
                            }
                        }
                        if (texBoxItems[i].get('info') === '') {
                            canvas.remove(texBoxItems[i]);
                        }
                    } else if (texBoxItems[i].get('type') === "url") {
                        if (canvasEnter) {
                            if (canvasEnter.type === 'url') {
                                continue;
                            }
                        }
                        if (texBoxItems[i].get('url') === '') {
                            canvas.remove(texBoxItems[i]);
                        }
                    } else if(texBoxItems[i].get('type') === "user") {
                        if (canvasEnter) {
                            if (canvasEnter.type === 'user') {
                                continue;
                            }
                        }
                        if (texBoxItems[i].get('info') === '') {
                            canvas.remove(texBoxItems[i]);
                        }
                        if (texBoxItems[i].get('idUser') === '') {
                            canvas.remove(texBoxItems[i]);
                        }
                    } else if(texBoxItems[i].get('type') === "geo") {
                        if (canvasEnter) {
                            if (canvasEnter.type === 'geo') {
                                continue;
                            }
                        }
                        if (texBoxItems[i].get('info') === '') {
                            canvas.remove(texBoxItems[i]);
                        }
                        if (texBoxItems[i].get('nameGeo') === '') {
                            canvas.remove(texBoxItems[i]);
                        }
                    } else if (texBoxItems[i].get('type') === "quiz") {
                        if (canvasEnter) {
                            if (canvasEnter.type === 'quiz') {
                                continue;
                            }
                        }

                        if (texBoxItems[i].get('textA') === '' || texBoxItems[i].get('textB') === '') {
                            canvas.remove(texBoxItems[i]);
                        }

                        if (texBoxItems[i].get('textD') !== '' && texBoxItems[i].get('textC') === '') {
                            canvas.remove(texBoxItems[i]);
                        }

                    }
                }
            }

            //создаем элементы которые есть
            let addElements = async () => {
                if (data) {

                    //квадрат
                    if (data.square) {
                        for (let key in data.square.slice()) {
                            this.addSquare('add', data.square[key]);
                        }
                    }
                    //треугольник
                    if (data.triangle) {
                        for (let key in data.triangle.slice()) {
                            this.addTriangle('add', data.triangle[key]);
                        }
                    }
                    //круг
                    if (data.circle) {
                        for (let key in data.circle.slice()) {
                            this.addCircle('add', data.circle[key]);
                        }
                    }
                    //текст
                    if (data.text) {
                        for (let key in data.text.slice()) {
                            this.addText('add', data.text[key]);
                        }
                    }

                    //гео
                    if (data.geo) {
                        for (let key in data.geo.slice()) {
                            let top =  fabricHeight * data.geo[key].y;
                            let left =  fabricWidth * data.geo[key].x;
                            let text = data.geo[key].text;
                            let rotation = data.geo[key].rotation;

                            let zoom = fabricWidth*data.geo[key].width.percent/data.geo[key].width.px;

                            let stile = data.geo[key].stile;
                            let zIndex = data.geo[key].z;

                            let name = data.geo[key].name;
                            let lat = data.geo[key].lat;
                            let lng = data.geo[key].lng;
                            let opacity = data.geo[key].opacity;

                            let dop = {tip: 'geo', name: name, lat: lat, lng:lng, opacity: opacity};

                            this.addElementsUserHashGeo('add', 'geo', text, top, left, rotation, zoom, stile, zIndex, dop);
                        }
                    }

                    if (data.user) {
                        for (let key in data.user.slice()) {
                            let top =  fabricHeight * data.user[key].y;
                            let left =  fabricWidth * data.user[key].x;
                            let text = data.user[key].text;
                            let rotation = data.user[key].rotation;

                            let zoom = fabricWidth*data.user[key].width.percent/data.user[key].width.px;

                            let stile = data.user[key].stile;
                            let zIndex = data.user[key].z;

                            let id = data.user[key].id;
                            let username = data.user[key].username;
                            let full_name = data.user[key].full_name;
                            let img = data.user[key].img;
                            let opacity = data.user[key].opacity;

                            let dop = {tip: 'user', id:id, username:username, full_name:full_name, img:img, opacity:opacity};

                            this.addElementsUserHashGeo('add', 'user', text, top, left, rotation, zoom, stile, zIndex, dop);
                        }
                    }

                    if (data.hashtag) {
                        for (let key in data.hashtag.slice()) {

                            let top =  fabricHeight * data.hashtag[key].y;
                            let left =  fabricWidth * data.hashtag[key].x;
                            let text = data.hashtag[key].text;
                            let rotation = data.hashtag[key].rotation;

                            let zoom = fabricWidth*data.hashtag[key].width.percent/data.hashtag[key].width.px;

                            let stile = data.hashtag[key].stile;
                            let zIndex = data.hashtag[key].z;

                            let name = data.hashtag[key].name;
                            let subtitle = data.hashtag[key].subtitle;
                            let img = data.hashtag[key].img;
                            let opacity = data.hashtag[key].opacity;

                            let dop = {tip: 'hashtag', name: name, subtitle: subtitle, img: img, opacity:opacity};

                            this.addElementsUserHashGeo('add', 'hashtag', text, top, left, rotation, zoom, stile, zIndex, dop);
                        }
                    }

                    if (data.url) {
                        for (let key in data.url.slice()) {

                            let top =  fabricHeight * data.url[key].y;
                            let left =  fabricWidth * data.url[key].x;
                            let text = data.url[key].text;
                            let rotation = data.url[key].rotation;

                            let zoom = fabricWidth*data.url[key].width.percent/data.url[key].width.px;

                            let stile = data.url[key].stile;
                            let zIndex = data.url[key].z;

                            let url = data.url[key].url;

                            let opacity = data.url[key].opacity;

                            let dop = {tip: 'url', url: url, opacity:opacity};

                            this.addElementsUserHashGeo('add', 'url', text, top, left, rotation, zoom, stile, zIndex, dop);

                        }
                    }

                    //Вопросы
                    if (data.questions) {
                        for (let key in data.questions.slice()) {
                            this.addQuestions('add', data.questions[key]);
                        }
                    }
                    //слайдер
                    if (data.slayder) {
                        for (let key in data.slayder.slice()) {
                            this.addSlayder('add', data.slayder[key])
                        }
                    }
                    //опрос
                    if (data.poll) {
                        for (let key in data.poll.slice()) {
                            this.addPoll('add', data.poll[key]);
                        }
                    }
                    //тест
                    if (data.quiz) {
                        for (let key in data.quiz.slice()) {
                            this.addQuiz('add', data.quiz[key]);
                        }
                    }
                    //Фото
                    if (data.image) {
                        for (let element of data.image.slice()) {
                             await this.addImage('add', element);
                        }
                    }

                    //GIF
                    if (data.gif && GIF) {
                        for (let element of data.gif.slice()) {
                            await this.addGif('add', element);
                        }
                    }


                    return true;

                }
            }



            //обрабатываем иконку
            let funCalback = (img) => {
                iconAccount = img;

                addElements().then(() => {
                    resolve(true);
                }).catch(() => {
                    alert('error compiling image');
                });


            }
            new fabric.Image.fromURL(icon, funCalback, { crossOrigin: 'anonymous' });


            //обрабатываем иконку
            let funCalbackUrlIcon = (img, type) => {
                if (type === 'black') {
                    iconUrlBlack = img;
                } else if (type === 'wite') {
                    iconUrlWite = img;
                } else if (type === 'blue') {
                    iconUrlBlue = img;
                }

            }
            new fabric.Image.fromURL(storyUrlBlack, (img) => funCalbackUrlIcon(img, 'black'), { crossOrigin: 'anonymous' });
            new fabric.Image.fromURL(storyUrlWite, (img) => funCalbackUrlIcon(img, 'wite'), { crossOrigin: 'anonymous' });
            new fabric.Image.fromURL(storyUrlBlue, (img) => funCalbackUrlIcon(img, 'blue'), { crossOrigin: 'anonymous' });



        });

    },
    //создание квадрата
    addSquare (type='new', obj=false) {
        let square = new fabric.Rect({
            fill: '#5395e9',
            width: 100,
            height: 100,
            strokeWidth: 2,
            originX: 'center',
            originY: 'center',
        });

        //если надо создать элемент
        if (obj) {
            square.height = obj.height.px;
            square.width = obj.width.px;

            square.scaleX = fabricWidth*obj.width.percent/obj.width.px;
            square.scaleY = fabricHeight*obj.height.percent/obj.height.px;

            square.opacity = obj.opacity;
            square.angle = obj.rotation;
            square.left = fabricWidth * obj.x;
            square.top = fabricHeight * obj.y;
            square.fill = obj.color;
        }

        canvas.add(square);

        if (type === 'new') {
            square.center();
            canvas.setActiveObject(square);
        } else {
            //поднимаем его на самый верх
            square.moveTo(obj.z);
        }

        //сохраняем изменения в бд
        saveFunction();
    },
    addCircle (tip='new', obj=false) {

        var circle = new fabric.Circle({
            radius: 50,
            fill: '#5395e9',
            originX: 'center',
            originY: 'center',
        });

        //если надо создать элемент
        if (obj) {
            circle.height = obj.height.px;
            circle.width = obj.width.px;

            /*circle.scaleX = obj.width.scale;
            circle.scaleY = obj.height.scale;*/

            circle.scaleX = fabricWidth*obj.width.percent/obj.width.px;
            circle.scaleY = fabricHeight*obj.height.percent/obj.height.px;

            circle.opacity = obj.opacity;
            circle.angle = obj.rotation;
            circle.left = fabricWidth * obj.x;
            circle.top = fabricHeight * obj.y;
            circle.fill = obj.color;
        }

        canvas.add(circle);

        if (tip == 'new') {
            circle.center();
            canvas.setActiveObject(circle);
        } else {
            circle.moveTo(obj.z);
        }

        //сохраняем изменения в бд
        saveFunction();
    },
    addTriangle (tip='new', obj=false) {

        let triangle = new fabric.Triangle({
            width: 100,
            height: 100,
            fill: '#5395e9',
            strokeWidth: 2,
            originX: 'center',
            originY: 'center',
        });

        //если надо создать элемент
        if (obj) {
            triangle.height = obj.height.px;
            triangle.width = obj.width.px;

            /*triangle.scaleX = obj.width.scale;
            triangle.scaleY = obj.height.scale;*/

            triangle.scaleX = fabricWidth*obj.width.percent/obj.width.px;
            triangle.scaleY = fabricHeight*obj.height.percent/obj.height.px;

            triangle.opacity = obj.opacity;
            triangle.angle = obj.rotation;
            triangle.left = fabricWidth * obj.x;
            triangle.top = fabricHeight * obj.y;
            triangle.fill = obj.color;
        }

        canvas.add(triangle);

        if (tip == 'new') {
            triangle.center();
            canvas.setActiveObject(triangle);
        } else {
            triangle.moveTo(obj.z);
        }

        //сохраняем изменения в бд
        saveFunction();
    },
    addText (tip='new', obj = false, text = store.initialStore.text.addPost[138]) {

        let textbox = new fabric.Textbox(text, {
            fontFamily:"AvenyT",
            fontSize: 34,
            fill: '#000',
            textBackgroundColor: "",
            lineHeight: 0.97,
            textAlign: "center",
            padding: 5,
            type: 'iText',
            opacity: 1,
            originX: 'center',
            originY: 'center',
            editable: false,
        });

        textbox.setControlsVisibility({
            mt: false,
            mb: false,
        });

        //если надо создать элемент
        if (obj) {
            textbox.text = obj.text;
            textbox.height = obj.height.px;
            textbox.width = obj.width.px;

            /*textbox.scaleX = obj.width.scale;
            textbox.scaleY = obj.height.scale;*/

            textbox.scaleX = fabricWidth*obj.width.percent/obj.width.px;
            textbox.scaleY = fabricHeight*obj.height.percent/obj.height.px;

            textbox.fill = obj.color;
            textbox.fontFamily = obj.fontFamily;
            textbox.fontStyle = obj.fontStyle;
            textbox.fontWeight = obj.fontWeight;
            textbox.opacity = obj.opacity;
            textbox.angle = obj.rotation;
            textbox.shadow = obj.shadow;
            textbox.textAlign = obj.textAlign;
            textbox.textBackgroundColor = obj.textBackgroundColor;
            textbox.left = fabricWidth * obj.x;
            textbox.top = fabricHeight * obj.y;
        }

        canvas.add(textbox);

        if (tip == 'new') {
            textbox.center();
            canvas.setActiveObject(textbox);
            //textbox.enterEditing();
        } else {
            textbox.moveTo(obj.z);
        }

        //сохраняем изменения в бд
        saveFunction();
    },
    addElementsUserHashGeo (tipAdd='new', tip='hashtag', val='', top=0, left=0, angle=0, zoom=0, stile='0', zIndex=false, dop=false) {

        let opacity = 0;
        let colorBacground = '#fff';
        let textHash = val;
        if (val === '') {
            textHash = store.initialStore.text.addPost[168];
            opacity = 0.7;
        }

        let path = null;

        let boxXleft = 30;
        let icon = '#';
        if (tip === 'user') {
            icon = '@';
            boxXleft = 38;
            textHash = store.initialStore.text.addPost[164];
        } else if (tip === 'geo') {
            textHash = store.initialStore.text.addPost[165];
            icon = '    ';
            var paths = new fabric.Path('M13.5 7.383a5.084 5.084 0 0 0-5.084 5.084c0 1.214.37 2.175 1.135 3.204.697.935.827 1.074 1.3 1.577-.055-.056.047.049 0 0 1.037 1.046 2.454 2.37 2.649 2.37.195 0 1.74-1.416 2.645-2.37.517-.544.585-.61 1.304-1.578.764-1.028 1.135-1.989 1.135-3.203A5.084 5.084 0 0 0 13.5 7.383zm0 6.696a1.612 1.612 0 1 0 0-3.224 1.612 1.612 0 0 0 0 3.224z');

            let circulIcon = '#fff';
            if (stile === '2') {
                circulIcon = '#CCCCCC';
            }
            let pathCircle = new fabric.Circle({
                radius: 2,
                fill: circulIcon,
                left: 11, top: 10
            });

            let scale = 1.4;
            paths.set({ });

            if (stile === '0') {
                paths.set({fill: "#941cb9"});
            } else if (stile === '1') {
                paths.set({fill: "rgb(254, 100, 64)"});
            } else {
                paths.set({fill: "#fff"});
            }

            path = new fabric.Group([paths, pathCircle], {
                left: 10, top: 12, scaleY:scale, scaleX:scale
            });
        } else if (tip === 'url') {
            icon = '    ';
            boxXleft = 35;
            textHash = store.initialStore.text.addPost[214];

            let scale = 0.04;

            let iconUrl = false;
            if (stile === '0') {
                iconUrl = iconUrlBlack;
            } else if (stile === '1') {
                iconUrl = iconUrlBlue;
            } else if (stile === '2') {
                iconUrl = iconUrlWite;
            }

            path = new fabric.Group([iconUrl], {
                left: 10, top: 12, scaleY:scale, scaleX:scale
            });
        }

        if (val !== '') {
            textHash = val;
        }

        let text = new fabric.IText(icon + textHash, {
            left: 14,
            top: 5,
            fontFamily:"AvenyT",
            textAlign: "center",
            fontSize:30,
        });

        if (stile === '0') {

            if (tip === 'geo') {
                text.setGradient('fill', {
                    x1: 0,
                    y1: 0,
                    x2: text.width,
                    y2: 0,
                    colorStops: {
                        0: "#7f32c0",
                        1: "#2b29d2",
                    }
                });
            } else if (tip === 'user') {
                text.setGradient('fill', {
                    x1: 0,
                    y1: 0,
                    x2: text.width,
                    y2: 0,
                    colorStops: {
                        0: "#e3784e",
                        1: "#e4d95d",
                    }
                });
            } else if (tip === 'url') {
                text.setGradient('fill', {
                    x1: 0,
                    y1: 0,
                    x2: text.width,
                    y2: 0,
                    colorStops: {
                        0: "#000000",
                        1: "#000000",
                    }
                });
            } else {
                text.setGradient('fill', {
                    x1: 0,
                    y1: 0,
                    x2: text.width,
                    y2: 0,
                    colorStops: {
                        0: "rgb(245, 140, 92)",
                        1: "rgb(255, 79, 153)",
                    }
                });
            }


        } else if (stile === '1') {

            if (tip === 'url') {
                text.setGradient('fill', {
                    x1: 0,
                    y1: 0,
                    x2: text.width,
                    y2: 0,
                    colorStops: {
                        0: "#0195f6",
                        1: "#0195f6",
                    }
                });
            } else {
                text.setGradient('fill', {
                    x1: 0,
                    y1: 0,
                    x2: text.width,
                    y2: 0,
                    colorStops: {
                        0: "rgb(254, 100, 64)",
                        0.25: "rgb(255, 191, 91)",
                        0.5: "rgb(133, 194, 92)",
                        0.75: "rgb(45, 162, 222)",
                        1: "rgb(146, 32, 181)",
                    }
                });
            }

        } else {

            let colorTu = '#fff';

            if (val === '') {
                colorTu = 'rgb(231,231,231)';
            }

            text.setGradient('fill', {
                x1: 0,
                y1: 0,
                x2: text.width,
                y2: 0,
                colorStops: {
                    0: "#fff",
                    0.099: "#fff",
                    0.1: colorTu,
                    1: colorTu,
                }
            });

            colorBacground = 'rgba(204, 204, 204, 0.8)';
            opacity = 0;
        }

        let box = new fabric.Rect({
            fill: colorBacground,
            width: text.width + 28,
            height: text.height + 9,
            strokeWidth: 2,
            rx:5,
            ry:5,
        });

        let boxW = new fabric.Rect({
            fill: colorBacground,
            width: text.width + 28-35,
            height: text.height + 10,
            strokeWidth: 2,
            opacity:opacity,
            rx:5,ry:5,
            left: boxXleft,
        });
        let groupArray = [ box, text, boxW];
        if (tip === 'geo') {
            groupArray = [ box, text, boxW, path];
        }
        if (tip === 'url') {
            groupArray = [ box, text, boxW, path];
        }

        let idUser = false;
        let usernameUser = false;
        let full_nameUser = false;
        let imgUser = false;


        let nameGeo = false;
        let latGeo = false;
        let lngGeo = false;

        let nameHashtag = false;
        let subtitleHashtag = false;
        let imgHashtag = false;

        let url = '';

        if (dop !== false) {

            if (dop.tip === 'user') {
                idUser = dop.id;
                usernameUser = dop.username;
                full_nameUser = dop.full_name;
                imgUser = dop.img;

            } else if (dop.tip === 'geo') {
                nameGeo = dop.name;
                latGeo = dop.lat;
                lngGeo = dop.lng;
            } else if (dop.tip === 'url') {
                url = dop.url;
            } else {

                nameHashtag = dop.name;
                subtitleHashtag = dop.subtitle;
                imgHashtag = dop.img;
            }
        }

        let opacityGroup = 1;
        if (dop.opacity) {
            opacityGroup = dop.opacity;
        }

        let group = new fabric.Group(groupArray, {
            type: tip,
            info: val,
            styl: stile,
            idUser: idUser,
            usernameUser: usernameUser,
            full_nameUser: full_nameUser,
            imgUser: imgUser,
            nameGeo: nameGeo,
            latGeo: latGeo,
            lngGeo: lngGeo,

            nameHashtag: nameHashtag,
            subtitleHashtag: subtitleHashtag,
            imgHashtag: imgHashtag,
            url: url,

            lockScalingFlip: true,
            originX: 'center',
            originY: 'center',
            minScaleLimit: 0.1,
            opacity: opacityGroup,
        });

        if (left !== 0 && top !== 0) {
            group.top =  top;
            group.left =  left;
            group.angle = angle;
            group.scaleX = zoom;
            group.scaleY = zoom;
        }

        group.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
        });

        canvas.add(group);


        if (tipAdd == 'new') {
            canvas.setActiveObject(group);

            if (left == 0 && top == 0) {
                group.center();
            }
        }

        if (zIndex) {
            group.moveTo(zIndex);
        }

        //сохраняем изменения в бд
        saveFunction();
    },
    addHashtag () {
        let add = true;
        let texBoxItems = canvas.getObjects();
        for(let i=0; i < texBoxItems.length; i++ ) {
            if(texBoxItems[i].get('type') === "hashtag"){
                add = false;
                canvas.setActiveObject(texBoxItems[i]);
                canvas.requestRenderAll();
            }
        }

        if (add) {
            this.addElementsUserHashGeo('new', 'hashtag', '');
        }
    },
    addUrl () {
        let add = true;
        let texBoxItems = canvas.getObjects();
        for(let i=0; i < texBoxItems.length; i++ ) {
            if(texBoxItems[i].get('type') === "url"){
                add = false;
                canvas.setActiveObject(texBoxItems[i]);
                canvas.requestRenderAll();
            }
        }

        if (add) {
            this.addElementsUserHashGeo('new', 'url', '');
        }
    },
    addUser () {
        let add = true;
        let texBoxItems = canvas.getObjects();
        for(let i=0; i < texBoxItems.length; i++ ) {
            if(texBoxItems[i].get('type') === "user"){
                add = false;
                canvas.setActiveObject(texBoxItems[i]);
                canvas.requestRenderAll();
            }
        }

        if (add) {
            this.addElementsUserHashGeo('new', 'user', '');
        }
    },
    //добавление гео точки
    addGEO () {
        let add = true;
        let texBoxItems = canvas.getObjects();
        for(let i=0; i < texBoxItems.length; i++ ) {
            if(texBoxItems[i].get('type') === "geo"){
                add = false;
                canvas.setActiveObject(texBoxItems[i]);
                canvas.requestRenderAll();
            }
        }

        if (add) {
            this.addElementsUserHashGeo('new', 'geo', '');
        }
    },
    //Создаем Вопросы
    addQuestions (tip='new', obj=false) {

        const nameTitle = store.initialStore.text.addPost[159];
        let name = nameTitle;
        let color = '#ffffff';
        let colorText = '#ffffff';

        let opasityText = 0.7;

        if (obj) {
            //if (obj.name !== '') {
                name=obj.name;
            //}
            color=obj.color;
        }


        if (color === '#ffffff' || color === '#fefefe' || color === '#f7d3d3' || color === '#efefef' || color === '#dbdbdb' || color === '#dbdbdb' || color === '#f9dcb9') {
            colorText = '#000000';
        }

        let nameText = name === '' ? nameTitle : name;

        let text = new fabric.Textbox(nameText, {
            fontFamily:"HelveticaNeueCyrMedium",
            fontSize: 20,
            fill: colorText,
            textBackgroundColor: "",
            lineHeight: 0.97,
            textAlign: "center",
            padding: 5,
            type: 'iText',
            opacity: 1,
            width: 287,
            left: 15,
            top: 75,
        });

        if (text.height >= 70 || text.width > 287) {

            for (let i = 400; i > 0; i--) {

                name = name.substring(0, name.length - 1);

                text = new fabric.Textbox(name, {
                    fontFamily:"HelveticaNeueCyrMedium",
                    fontSize: 20,
                    fill: colorText,
                    textBackgroundColor: "",
                    lineHeight: 0.97,
                    textAlign: "center",
                    padding: 5,
                    type: 'iText',
                    opacity: 1,
                    width: 287,
                    left: 15,
                    top: 75,
                });

                if (text.height < 70 && text.width <= 287 ) {
                    break;
                }
            }
        }

        let textOt = new fabric.Textbox(store.initialStore.text.addPost[160], {
            fontFamily:"AvenyT",
            fontSize: 17,
            fill: '#bdbdbd',
            textBackgroundColor: "",
            lineHeight: 0.97,
            textAlign: "center",
            padding: 5,
            type: 'iText',
            opacity: opasityText,
            width: 287,
            left: 15,
            top: 103 + text.height,
        });

        let boxFontPhoto = new fabric.Rect({
            fill: color,
            width: 56,
            height: 56,
            left: 130,
            rx:50,
            ry:50,
        });

        let boxFon = new fabric.Rect({
            fill: '#000',
            width: 290,
            height: 55,
            opacity: 0.05,
            top: 85 + text.height,
            left: 13,
            rx:10,
            ry:10,
        });

        let box = new fabric.Rect({
            fill: color,
            width: 317,
            height: 128 + text.height,
            top: 27,
            rx:10,
            ry:10,
        });

        //создаем картинку
        let oImg = iconAccount;

        let texBoxItems = canvas.getObjects();
        for(var i=0; i<texBoxItems.length; i++ ) {
            if (texBoxItems[i].get('type') == "questions") {
                canvas.remove(texBoxItems[i]);
            }
        }

        oImg.set({
            left: 132.5,
            top: 2.5,
            clipTo: function (ctx) {
                ctx.arc(0, 0, 75, 0, Math.PI * 2, true);
            }
        });

        oImg.scaleToWidth(52);
        oImg.scaleToHeight(52);

        let groupArray = [box, boxFontPhoto, boxFon, textOt, text, oImg];

        var group = new fabric.Group(groupArray, {
            type: 'questions',
            name: name,
            color: color,
            colorText: colorText,
            lockScalingFlip: true,
            scaleX: 0.8129496402877697,
            scaleY: 0.8129496402877697,
            originX: 'center',
            originY: 'center',
            minScaleLimit: 0.1,
        });

        if (obj) {
            group.angle = obj.rotation;

            /*group.scaleX = obj.width.scale;
            group.scaleY = obj.height.scale;*/

            group.scaleX = fabricWidth*obj.width.percent/obj.width.px;
            group.scaleY = fabricHeight*obj.height.percent/obj.height.px;

            group.name = obj.name;
            group.left = fabricWidth * obj.x;
            group.top = fabricHeight * obj.y;
        }

        group.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
        });


        canvas.add(group);

        if (tip == 'new') {
            canvas.setActiveObject(group);
        }

        if (!obj) {
            group.center();
        }

        //сохраняем изменения в бд
        saveFunction();
    },
    //Создаем слайдер
    addSlayder (tip='new', obj= false) {

        let name = '';
        let color = '#ffffff';
        let colorText = '#ffffff';
        let emagi = '😍';
        if (obj) {
            name=obj.text;
            color = obj.color;
            emagi = obj.emagi;
        }
        //console.log(color);
        if (color === '#ffffff' || color === '#fefefe' || color === '#f7d3d3' || color === '#efefef' || color === '#dbdbdb' || color === '#dbdbdb' || color === '#f9dcb9') {
            colorText = '#000000';
        }

        let text = new fabric.Textbox(name, {
            fontFamily:"HelveticaNeueCyrMedium",
            fontSize: 20,
            fill: colorText,
            textBackgroundColor: "",
            lineHeight: 0.97,
            textAlign: "center",
            padding: 5,
            type: 'iText',
            opacity: 1,
            width: 249,
            left: 33,
            top: 25,
        });

        if (text.width > 249) {

            for (let i = 400; i > 0; i--) {

                name = name.substring(0, name.length - 1);

                text = new fabric.Textbox(name, {
                    fontFamily:"HelveticaNeueCyrMedium",
                    fontSize: 20,
                    fill: colorText,
                    textBackgroundColor: "",
                    lineHeight: 0.97,
                    textAlign: "center",
                    padding: 5,
                    type: 'iText',
                    opacity: 1,
                    width: 249,
                    left: 33,
                    top: 25,
                });

                if (text.width <= 249 ) {
                    break;
                }
            }
        }

        /*$('.fab-element-slayder-value').val(name);*/

        if (tip == 'skrin') {
            emagi = '';
        }

        let emoji = new fabric.Textbox(emagi, {
            fontFamily:"HelveticaNeueCyrBold",
            fontSize: 42,
            textAlign: "center",
            left: 27,
            top: 29,
        });

        let box = new fabric.Rect({
            fill: color,
            width: 315,
            height: 100,
            rx:15,
            ry:15,
        });

        if (obj) {
            box.fill = obj.color;
        }

        let polosa = new fabric.Rect({
            fill: '#000',
            width: 249,
            height: 9,
            left: 33,
            top: 45.5,
            rx:5,
            ry:5,
            opacity:0.1
        });

        if (obj) {
            if (obj.text != '') {
                emoji.top = text.height+emoji.top+15;
                polosa.top = text.height+polosa.top+15;
                box.height = text.height + 90+15;
            }
        }

        let groupArray = [box, polosa, emoji, text];

        var group = new fabric.Group(groupArray, {
            type: 'slayder',
            text: name,
            color: color,
            colorText: colorText,
            emagi: emagi,
            lockScalingFlip: true,
            scaleX: 0.8129496402877697,
            scaleY: 0.8129496402877697,
            originX: 'center',
            originY: 'center',
            minScaleLimit: 0.1,
        });

        if (obj) {
            group.angle = obj.rotation;
            /*group.scaleX = obj.width.scale;
            group.scaleY = obj.height.scale;*/

            group.scaleX = fabricWidth*obj.width.percent/obj.width.px;
            group.scaleY = fabricHeight*obj.height.percent/obj.height.px;

            group.left = fabricWidth * obj.x;
            group.top = fabricHeight * obj.y;
        }

        group.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
        });

        canvas.add(group);

        if (tip == 'new') {
            canvas.setActiveObject(group);
        }

        if (!obj) {
            group.center();
        }

        //сохраняем изменения в бд
        saveFunction();
    },
    //создание опроса
    addPoll (tip='new', obj=false) {

        let textT = '';
        let textL = store.initialStore.text.addPost[166];
        let textR = store.initialStore.text.addPost[167];
        let yText = 0;

        if (obj) {

            /*if (obj.textL === '') {
                obj.textL = 'ДА';
            }

            if (obj.textR === '') {
                obj.textR = 'НЕТ';
            }*/

            textT = obj.textT;
            textL = obj.textL;
            textR = obj.textR;
        }

        let fontL = 35.0;
        let fontR = 35.0;

        let text = new fabric.Textbox(textT, {
            fontFamily:"HelveticaNeueCyrBold",
            fontSize: 23,
            fill: '#fff',
            textBackgroundColor: "",
            lineHeight: 0.97,
            textAlign: "center",
            padding: 5,
            type: 'iText',
            opacity: 1,
            width: 270,
        });

        if (text.width > 270) {

            for (let i = 400; i > 0; i--) {

                textT = textT.substring(0, textT.length - 1);

                text = new fabric.Textbox(textT, {
                    fontFamily:"HelveticaNeueCyrBold",
                    fontSize: 23,
                    fill: '#fff',
                    textBackgroundColor: "",
                    lineHeight: 0.97,
                    textAlign: "center",
                    padding: 5,
                    type: 'iText',
                    opacity: 1,
                    width: 270,
                });

                if (text.width <= 270 ) {
                    break;
                }
            }
        }

       /*$('.fab-element-poll-value').val(textT);*/

        let box = new fabric.Rect({
            fill: '#fff',
            width: 270,
            height: 84,
            rx:14,
            ry:14,
            left:135.5,
            top: 42.5,
            lockScalingFlip: true,
            originX: 'center',
            originY: 'center',
        });

        let polosa = new fabric.Rect({
            fill: '#dcdcdc',
            width: 2,
            height: 84,
            left: 134,
        });

        let textWidth = 120;
        let textOtstup = 8;
        let textOtstupR = 142;
        let textMinFontSize = 20.5;
        let minHight = 25.43;

        let letDataText = {
            fontFamily:"AvenyT",
            fill: '#000',
            textBackgroundColor: "",
            textAlign: "center",
            padding: 0,
            type: 'iText',
            opacity: 1,
            width: textWidth,
            height: 56.14,
            globalCompositeOperation: 'source-over',
            lineHeight: 1.16,
            minWidth: 20,
            stroke: null,
            strokeWidth: 1,
            strokeDashArray: null,
            strokeLineCap: 'butt',
            strokeDashOffset: 0,
            strokeLineJoin: 'miter',
            strokeMiterLimit: 4,
            clipTo: null,
            fillRule: 'nonzero',
            paintFirst: 'fill',
            charSpacing: 0,
        }

        let textLeft;

        let textLVstavka = textL === '' ? store.initialStore.text.addPost[166] : textL;

        for (let i=39; i >= textMinFontSize; i=i-0.5) {
            textLeft = new fabric.Textbox(textLVstavka,
                Object.assign(letDataText, {
                    fontSize: i,
                    left: textOtstup,
                    splitByGrapheme: false,
                }));
            fontL = i;
            if (textLeft.height <= 60 && textLeft.width <= textWidth) {
                break;
            }
        }

        if (textLeft.width > textWidth) {
            for (let i=39; i >= textMinFontSize; i=i-0.5) {
                textLeft = new fabric.Textbox(textL,
                    Object.assign(letDataText, {
                        fontSize: i,
                        left: textOtstup,
                        splitByGrapheme: ' ',
                    }));
                fontL = i;
                if (textLeft.height <= 60 && textLeft.width <= textWidth) {
                    break;
                }
            }
        }


        //если больше по высоте, удаляем символы с конца
        if (textLeft.height > 60) {
            let splitByGrapheme = textLeft.splitByGrapheme;
            for (let i=200; i > 0; i--) {
                textL = textL.substring(0, textL.length - 1);
                textLeft = new fabric.Textbox(textL,
                    Object.assign(letDataText, {
                        fontSize: textMinFontSize,
                        left: textOtstup,
                        splitByGrapheme: splitByGrapheme,
                    }));

                if (textLeft.height <= 60 && textLeft.width<=textWidth) {
                    break;
                }
            }
        }


        let textRight;

        let textRVstavka = textR === '' ? store.initialStore.text.addPost[167] : textR;

        for (let i=39; i >= textMinFontSize; i=i-0.5) {
            textRight = new fabric.Textbox(textRVstavka,
                Object.assign(letDataText, {
                    fontSize: i,
                    left: textOtstupR,
                    splitByGrapheme: false,
                }));
            fontR = i;
            if (textRight.height <= 60 && textRight.width <= textWidth) {
                break;
            }
        }

        if (textRight.width > textWidth) {
            for (let i=35; i >= textMinFontSize; i=i-0.5) {
                textRight = new fabric.Textbox(textR,
                    Object.assign(letDataText, {
                        fontSize: i,
                        left: textOtstupR,
                        splitByGrapheme: ' ',
                    }));
                fontR = i;
                if (textRight.height <= 60 && textRight.width <= textWidth) {
                    break;
                }
            }
        }


        //если больше по высоте, удаляем символы с конца
        if (textRight.height > 60) {
            let splitByGrapheme = textRight.splitByGrapheme;
            for (let i=200; i > 0; i--) {
                textR = textR.substring(0, textR.length - 1);
                textRight = new fabric.Textbox(textR,
                    Object.assign(letDataText, {
                        fontSize: textMinFontSize,
                        left: textOtstupR,
                        splitByGrapheme: splitByGrapheme,
                    }));
                if (textRight.height <= 60 && textRight.width<=textWidth) {
                    break;
                }
            }
            /*$('.fab-element-poll-valueR').val(textR);*/
        }

        textLeft.setGradient('fill', {
            type: 'linear',
            x1: 0,
            y1: 0,
            x2: 120,
            y2: 0,
            colorStops: {
                0: "rgb(38,170,243)",
                1: "rgb(50,216,55)",
            }
        });

        textRight.setGradient('fill', {
            x1: 0,
            y1: 0,
            x2: 120,
            y2: 0,
            colorStops: {
                0: "rgb(245,140,92)",
                1: "rgb(255,79,153)",
            }
        });

        textLeft.top = (85 - textLeft.height) / 2;
        textRight.top = (85 - textRight.height) / 2;

        if (textT !== '') {
            box.top = text.height+10+43;
            polosa.top = text.height+10;
            textLeft.top = text.height+textLeft.top+10;
            textRight.top = text.height+textRight.top+10;
        }

        let groupArray = [text, box, polosa, textLeft, textRight];

        let group = new fabric.Group(groupArray, {
            type: 'poll',
            textT: textT,
            textL: textL,
            textR: textR,
            yText: 0,
            xText: 0,
            fontL: fontL,
            fontR: fontR,
            BoxWidth: 0,
            BoxHeight: 0,
            scaleX: 0.8129496402877697,
            scaleY: 0.8129496402877697,
            lockScalingFlip: true,
            originX: 'center',
            originY: 'center',
            minScaleLimit: 0.1,
        });


        if (textT !== '') {
            yText = (group.height / 2) - (box.height/2) + 40;
        }



        if (obj) {
            group.angle = obj.rotation;

            /*group.scaleX = obj.width.scale;
            group.scaleY = obj.height.scale;*/

            group.scaleX = fabricWidth*obj.width.percent/obj.width.px;
            group.scaleY = fabricHeight*obj.height.percent/obj.height.px;

            group.text = obj.text;
            group.left = fabricWidth * obj.x;
            group.top = fabricHeight * obj.y;
        }

        group.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
        });

        canvas.add(group);

        if (tip === 'new') {
            canvas.setActiveObject(group);
        }

        if (!obj) {
            group.center();
        }

        let tops = box.getAbsolute('top');
        let topsl = box.getAbsolute('left');
        //console.log(tops + ' / ' + topsl);

        group.yText = box.getAbsolute('top');
        group.xText = box.getAbsolute('left');
        group.BoxWidth = box.width;
        group.BoxHeight = box.height;

        //размер
        group.on('modified', function(options) {
            //console.log('modified poll');
            this.yText = this._objects[1].getAbsolute('top');
            this.xText = this._objects[1].getAbsolute('left');
            this.BoxWidth = this._objects[1].width;
            this.BoxHeight = this._objects[1].height;

            //console.log(this.yText);

            saveFunction();
        });

        //сохраняем изменения в бд
        saveFunction();
    },
    //создание Теста
    addQuiz (tip='new', obj=false) {

        let colorFon = '#252626';
        let name = store.initialStore.text.addPost[161];
        let textA = store.initialStore.text.addPost[162];
        let textB = store.initialStore.text.addPost[163];
        let textC = '';
        let textD = '';
        let good = 0;

        let color = '#ffffff';
        let colorText = '#ffffff';

        let template = 0;
        if (obj) {
            name=obj.name;
            textA = obj.textA;
            textB = obj.textB;
            textC = obj.textC;
            textD = obj.textD;
            template = obj.template;
            good = obj.good;
        }

        let elements = 2;
        if (textC !== '') elements = 3;
        if (textD !== '') elements = 4;

        let gradient = false;

        /*
        0 - черный
        1 - фиолетово-синий
        2 - зелено-голубой
        3 - оранжево-желтый
        4 - розовый
        5 - фиолетовый
        6 - светло красный
        7 - оранжевый
        8 - желтый
        9 - зеленый
        10  - синий
        */
        if (template === 1) {
            gradient = {
                0: "#7f32c0",
                1: "#64abf8",
            };
        } else if (template === 2) {
            gradient = {
                0: "#72c049",
                1: "#5fc0e9",
            };
        } else if (template === 3) {
            gradient = {
                0: "#db5b5b",
                1: "#f4c96e",
            };
        } else if (template === 4) {
            gradient = {
                0: "#bc3ed6",
                1: "#eb67a9",
            };
        } else if (template === 5) {
            gradient = {
                0: "#9520b3",
                1: "#9520b3",
            };
        } else if (template === 6) {
            gradient = {
                0: "#db565a",
                1: "#db565a",
            };
        } else if (template === 7) {
            gradient = {
                0: "#ee9248",
                1: "#ee9248",
            };
        } else if (template === 8) {
            gradient = {
                0: "#f5cc6e",
                1: "#f5cc6e",
            };
        } else if (template === 9) {
            gradient = {
                0: "#74c042",
                1: "#74c042",
            };
        } else if (template === 10) {
            gradient = {
                0: "#4192ee",
                1: "#4192ee",
            };
        }

        let nameText = name === '' ? store.initialStore.text.addPost[161] : name;

        let nameTitileFontSize = 26;
        let nameTitileTop =  - 41;

        let text = new fabric.Textbox(nameText, {
            fontFamily:"AvenyT",
            fontSize: nameTitileFontSize,
            fill: colorText,
            lineHeight: 1,
            textAlign: "center",
            padding: 5,
            type: 'iText',
            opacity: 1,
            width: 285,
            left: 15,
            top: nameTitileTop,
        });

        if (text.height >= 70 || text.width > 285) {

            for (let i = 400; i > 0; i--) {

                name = name.substring(0, name.length - 1);

                text = new fabric.Textbox(name, {
                    fontFamily:"AvenyT",
                    fontSize: nameTitileFontSize,
                    fill: colorText,
                    lineHeight: 0.97,
                    textAlign: "center",
                    padding: 5,
                    type: 'iText',
                    opacity: 1,
                    width: 285,
                    left: 15,
                    top: nameTitileTop,
                });
                if (text.height < 70 && text.width <= 287 ) {
                    break;
                }
            }
        }



        if (text.height > 35) {
            text.top = -68;
        }

        //шапка
        let topBox = new fabric.Rect({
            fill: colorFon,
            width: 317,
            height: 92, // 67
            top: text.height > 35 ? -82 : -57, //-57 = 1 строка -82 = 2 строки
            rx:10,
            ry:10,
        });

        if (gradient) {
            topBox.setGradient('fill', {
                x1: 0,
                y1: 0,
                x2: topBox.width,
                y2: 82,
                colorStops: {
                    ...gradient,
                }
            });
        }

        //центральный бокс
        let boxHight = 127;
        if (elements === 3) boxHight+=72;
        if (elements === 4) boxHight+=72*2;

        let box = new fabric.Rect({
            fill: color,
            width: 317,
            height: boxHight,
        });

        let boxbotton = new fabric.Rect({
            fill: color,
            width: 317,
            height: 50,
            top: boxHight-10,
            rx:10,
            ry:10,
        });

        let textOtvetGeneration = (text, simbol, top = 0) => {

            let box1 = new fabric.Rect({
                fill: '#ffffff',
                strokeWidth: 1,
                stroke: "#C7C6C7",
                width: 284,
                height: 56,
                opacity: 1,
                top: 19 + top,
                left: 17,
                rx:28,
                ry:28,
            });

            let box1Circle = new fabric.Rect({
                fill: '#ffffff',
                strokeWidth: 2,
                stroke: colorFon,
                width: 27,
                height: 27,
                opacity: 1,
                top: 33 + top,
                left: 30,
                rx:28,
                ry:28,
            });

            if (gradient) {
                box1Circle.setGradient('stroke', {
                    x1: 0,
                    y1: 0,
                    x2: 27,
                    y2: 0,
                    colorStops: {
                        ...gradient,
                    }
                });
            }

            let box1CircleText = new fabric.Textbox(simbol, {
                fontFamily:"HelveticaNeueCyrMedium",
                fontSize: 16,
                fill: colorFon,
                lineHeight: 1,
                textAlign: "center",
                padding: 5,
                type: 'iText',
                opacity: 1,
                width: 27,
                top: 39 + top,
                left: 30.5,
            });

            if (gradient) {
                box1CircleText.setGradient('fill', {
                    x1: 0,
                    y1: 0,
                    x2: 27,
                    y2: 0,
                    colorStops: {
                        ...gradient,
                    }
                });
            }

            let box = new fabric.Textbox(text, {
                fontFamily:"AvenyT",
                fontSize: 17,
                fill: "#272727",
                lineHeight: 1,
                padding: 5,
                type: 'iText',
                opacity: 1,
                width: 220,
                top: 38 + top,
                left: 67,
            });

            if (box.height > 20) {
                box.top = 28 + top;
            }

            if (box.height > 40 || box.width > 220) {
                for (let i = 400; i > 0; i--) {
                    text = text.substring(0, text.length - 1);
                    box = new fabric.Textbox(text, {
                        fontFamily:"AvenyT",
                        fontSize: 17,
                        fill: "#272727",
                        lineHeight: 1,
                        padding: 5,
                        type: 'iText',
                        opacity: 1,
                        width: 220,
                        top: 28 + top,
                        left: 67,
                    });
                    if (box.height < 40 && box.width <= 220) {
                        break;
                    }
                }
            }

            return {
                component: [box1, box1Circle, box1CircleText, box],
                text: text,
            };
        }

        let box1Text = textOtvetGeneration(textA, 'A', 0);
        let box2Text = textOtvetGeneration(textB, 'B', 72);
        let box3Text = elements >= 3 ? textOtvetGeneration(textC, 'C', 145) : false;
        let box4Text = elements >= 4 ? textOtvetGeneration(textD, 'D', 217) : false;

        textA = box1Text.text;
        textB = box2Text.text;
        textC = box3Text ? box3Text.text : textC;
        textD = box4Text ? box4Text.text : textD;

        let groupArray = [
            topBox,
            boxbotton,
            box,
            text,
            ...box1Text.component,
            ...box2Text.component,
        ];

        if (elements >= 3) groupArray.push(...box3Text.component);
        if (elements >= 4) groupArray.push(...box4Text.component);

        let texBoxItems = canvas.getObjects();
        for(var i=0; i<texBoxItems.length; i++ ) {
            if (texBoxItems[i].get('type') == "questions") {
                canvas.remove(texBoxItems[i]);
            }
        }

        var group = new fabric.Group(groupArray, {
            type: 'quiz',
            name: name,
            textA: textA,
            textB: textB,
            textC: textC,
            textD: textD,
            good: good,
            template: template,
            lockScalingFlip: true,
            scaleX: 0.8129496402877697,
            scaleY: 0.8129496402877697,
            originX: 'center',
            originY: 'center',
            minScaleLimit: 0.1,
        });

        if (obj) {
            group.angle = obj.rotation;

            group.scaleX = fabricWidth*obj.width.percent/obj.width.px;
            group.scaleY = fabricHeight*obj.height.percent/obj.height.px;

            group.left = fabricWidth * obj.x;
            group.top = fabricHeight * obj.y;
        }

        group.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
        });


        canvas.add(group);

        if (tip == 'new') {
            canvas.setActiveObject(group);
        }

        if (!obj) {
            group.center();
        }

        //сохраняем изменения в бд
        saveFunction();
    },

    addImage (type='new', obj=false, imageBase=false) {
        return new Promise(async (resolve, reject) => {
            let imageURL = imageBase;

            if (!imageBase) {
                imageURL = obj.image;
            }

            const funCallBack = (oImg) => {

                if (obj) {
                    oImg.height = obj.height.px;
                    oImg.width = obj.width.px;

                    oImg.scaleX = fabricWidth*obj.width.percent/obj.width.px;
                    oImg.scaleY = fabricHeight*obj.height.percent/obj.height.px;

                    oImg.opacity = obj.opacity;
                    oImg.angle = obj.rotation;
                    oImg.left = fabricWidth * obj.x;
                    oImg.top = fabricHeight * obj.y;
                    oImg.image = imageURL;

                    oImg.type = 'image';
                    oImg.originX = 'center';
                    oImg.originY = 'center';

                } else {
                    oImg.type = 'image';
                    oImg.scaleToWidth(100);
                    oImg.originX = 'center';
                    oImg.originY = 'center';
                    oImg.image = imageURL;
                }

                canvas.add(oImg);

                if (type === 'new') {
                    oImg.center();
                    canvas.setActiveObject(oImg);
                } else {
                    //поднимаем его на самый верх
                    oImg.moveTo(obj.z);
                }

                //сохраняем изменения в бд
                saveFunction();

                resolve(true);
            }

            fabric.Image.fromURL(imageURL, funCallBack, { crossOrigin: 'anonymous' });
        });

    },

    addGif (type='new', obj=false, imageBase=false) {
        return new Promise(async (resolve, reject) => {

            let imageURL = imageBase;

            if (!imageBase) {
                imageURL = obj.image;
            }

            const funCallBack = (oImg) => {

                if (obj) {
                    oImg.height = obj.height.px;
                    oImg.width = obj.width.px;

                    oImg.scaleX = fabricWidth*obj.width.percent/obj.width.px;
                    oImg.scaleY = fabricHeight*obj.height.percent/obj.height.px;

                    oImg.opacity = obj.opacity;
                    oImg.angle = obj.rotation;
                    oImg.left = fabricWidth * obj.x;
                    oImg.top = fabricHeight * obj.y;
                    oImg.image = imageURL;

                    oImg.type = 'gif';
                    oImg.originX = 'center';
                    oImg.originY = 'center';

                } else {
                    oImg.type = 'gif';
                    oImg.scaleToWidth(150);
                    oImg.originX = 'center';
                    oImg.originY = 'center';
                    oImg.image = imageURL;
                }

                canvas.add(oImg);

                if (type === 'new') {
                    oImg.center();
                    canvas.setActiveObject(oImg);
                } else {
                    //поднимаем его на самый верх
                    oImg.moveTo(obj.z);
                }

                gif(imageURL, function(frames) {

                    var framesIndex = 0,
                        animInterval;
                    oImg.dirty = true;
                    oImg._render = function(ctx) {
                        ctx.drawImage(frames[framesIndex].img, -this.width / 2, -this.height / 2, this.width, this.height);
                    }
                    oImg.play = function() {
                        if (typeof(animInterval) === 'undefined') {


                            let timers = 0;
                            animInterval = setInterval(function() {
                                timers++;
                                if (timers > frames[framesIndex].delay) {
                                    timers = 0;
                                    framesIndex++;
                                    if (framesIndex === frames.length) {
                                        framesIndex = 0;
                                    }
                                }
                            }, 10);
                        }
                    }
                    oImg.stop = function() {
                        clearInterval(animInterval);
                        animInterval = undefined;
                    }
                    oImg.play();
                    //canvas.add(oImg);
                });



                //сохраняем изменения в бд
                saveFunction();

                resolve(true);
            }

            fabric.Image.fromURL(imageURL, funCallBack, { crossOrigin: 'anonymous' });
        });

    },
    getElementRedUrlText () {
        if (!canvasEnter) return false;

        return canvasEnter.get('url');
    },
    getElementRedUrlTextAlt () {
        if (!canvasEnter) return false;

        return canvasEnter.get('info');
    },


    getElementRedQuizText (type = 'name') {
        if (!canvasEnter) return false;

        if (type === 'name') {
            return canvasEnter.get('name');
        } else if (type === 'text') {

            return {
                textA: canvasEnter.get('textA'),
                textB: canvasEnter.get('textB'),
                textC: canvasEnter.get('textC'),
                textD: canvasEnter.get('textD'),
                good: canvasEnter.get('good'),
            };
        }
    },
    elementRedQuiz (nameNew=false, textANew=false, textBNew = false, textCNew = false, textDNew = false, goodNew = false, templateNew = false) {
        if (!canvasEnter) return false;

        const name = nameNew === false ? canvasEnter.name : nameNew;
        const textA = textANew === false ? canvasEnter.textA : textANew;
        const textB = textBNew === false ? canvasEnter.textB : textBNew;
        const textC = textCNew === false ? canvasEnter.textC : textCNew;
        const textD = textDNew === false ? canvasEnter.textD : textDNew;
        const good = goodNew === false ? canvasEnter.good : goodNew;
        const template = templateNew === false ? canvasEnter.template : templateNew;


        const inform = {
            name: name,
            textA: textA,
            textB: textB,
            textC: textC,
            textD: textD,
            good: good,
            template: template,
            rotation: canvasEnter.angle,
            width: {
                scale: canvasEnter.scaleX,
                percent: canvasEnter.width * canvasEnter.scaleX / fabricWidth,
                px: canvasEnter.width,
            },
            height: {
                scale: canvasEnter.scaleX,
                percent: canvasEnter.height * canvasEnter.scaleY / fabricHeight,
                px: canvasEnter.height,
            },
            x: canvasEnter.left / fabricWidth,
            y: canvasEnter.top / fabricHeight,
        };

        canvas.remove(canvas.getActiveObject());

        this.addQuiz('new', inform);
    },
    addQuizOne () {
        let add = true;
        let texBoxItems = canvas.getObjects();
        for(var i=0; i<texBoxItems.length; i++ ){
            if(texBoxItems[i].get('type') === "quiz"){
                add = false;
                canvas.setActiveObject(texBoxItems[i]);
                canvas.requestRenderAll();
            }
        }
        if (add) {
            this.addQuiz('new');
        }
    },
    getElementRedPollText (type = 'text') {
        if (!canvasEnter) return false;

        if (type === 'text') {
            return canvasEnter.get('textT');
        } else if (type === 'left') {
            return canvasEnter.get('textL');
        } else if (type === 'right') {
            return canvasEnter.get('textR');
        }
    },
    elementRedPoll (val=false, valL=false, valR = false) {
        if (!canvasEnter) return false;

        const textT = val === false ? canvasEnter.textT : val;
        const textL = valL === false ? canvasEnter.textL : valL;
        const textR = valR === false ? canvasEnter.textR : valR;

        const inform = {
            textT: textT,
            textL: textL,
            textR: textR,
            rotation: canvasEnter.angle,
            width: {
                scale: canvasEnter.scaleX,
                percent: canvasEnter.width * canvasEnter.scaleX / fabricWidth,
                px: canvasEnter.width,
            },
            height: {
                scale: canvasEnter.scaleX,
                percent: canvasEnter.height * canvasEnter.scaleY / fabricHeight,
                px: canvasEnter.height,
            },
            x: canvasEnter.left / fabricWidth,
            y: canvasEnter.top / fabricHeight,
        };

        canvas.remove(canvas.getActiveObject());

        this.addPoll('new', inform);
    },
    addPollOne () {
        let add = true;
        let texBoxItems = canvas.getObjects();
        for(var i=0; i<texBoxItems.length; i++ ){
            if(texBoxItems[i].get('type') === "poll"){
                add = false;
                canvas.setActiveObject(texBoxItems[i]);
                canvas.requestRenderAll();
            }
        }
        if (add) {
            this.addPoll('new');
        }
    },
    addSlayderOne () {
        let add = true;
        let texBoxItems = canvas.getObjects();
        for(var i=0; i<texBoxItems.length; i++ ){
            if(texBoxItems[i].get('type') === "slayder"){
                add = false;
                canvas.setActiveObject(texBoxItems[i]);
                canvas.requestRenderAll();
            }
        }
        if (add) {
            this.addSlayder('new');
        }
    },
    addQuestionsOne () {
        let add = true;
        let texBoxItems = canvas.getObjects();
        for(var i=0; i<texBoxItems.length; i++ ){
            if(texBoxItems[i].get('type') === "questions"){
                add = false;
                canvas.setActiveObject(texBoxItems[i]);
                canvas.requestRenderAll();
            }
        }
        if (add) {
            this.addQuestions('new');
        }
    },

    //Редактированние слайдера
    elementRedSlayder (val=false, color=false, emagi = false) {
        if (!canvasEnter) return false;

        if (val === false) {
            val = canvasEnter.text;
        }

        if (!color) {
            color = canvasEnter.color;
        }

        if (!emagi) {
            emagi = canvasEnter.emagi;
        }

        const inform = {
            text: val,
            color: color,
            emagi: emagi,
            rotation: canvasEnter.angle,
            width: {
                scale: canvasEnter.scaleX,
                percent: canvasEnter.width * canvasEnter.scaleX / fabricWidth,
                px: canvasEnter.width,
            },
            height: {
                scale: canvasEnter.scaleX,
                percent: canvasEnter.height * canvasEnter.scaleY / fabricHeight,
                px: canvasEnter.height,
            },
            x: canvasEnter.left / fabricWidth,
            y: canvasEnter.top / fabricHeight,
        };

        canvas.remove(canvas.getActiveObject());

        this.addSlayder('new', inform);
    },
    getElementSliderText () {
        if (!canvasEnter) return false;
        return canvasEnter.get('text');
    },
    //Возвращает текст из стикера Вопросы
    getElementQuestionsText () {
        if (!canvasEnter) return false;
        return canvasEnter.get('name');
    },
    //Редактируем Вопросы
    elementRedQuestions (val=false, hex=false, element = false) {

        if (!canvasEnter && !element) {
            return false;
        }
        const elem = element ? element : canvasEnter;

        const name = val !== false ? val : elem.name;
        const color = hex ? hex : elem.color;

        const inform = {
            name: name,
            color: color,
            rotation: elem.angle,
            width: {
                scale: elem.scaleX,
                percent: elem.width * elem.scaleX / fabricWidth,
                px: elem.width,
            },
            height: {
                scale: elem.scaleY,
                percent: elem.height * elem.scaleY / fabricHeight,
                px: elem.height,
            },
            x: elem.left / fabricWidth,
            y: elem.top / fabricHeight,
        };

        this.addQuestions('new', inform);
    },


    //получаем информацию их хэштега
    elementInfoHashtag () {
        if (!canvasEnter) return false;

        return {
            name: canvasEnter.get('nameHashtag'),
            subtitle: canvasEnter.get('subtitleHashtag'),
            img: canvasEnter.get('imgHashtag'),
        };
    },
    //получаем информацию по активному выбранному аккаунту в Упоминании
    elementInfoUser () {
        if (!canvasEnter) return false;

        return {
            id: canvasEnter.get('idUser'),
            username: canvasEnter.get('usernameUser'),
            full_name: canvasEnter.get('full_nameUser'),
            img: canvasEnter.get('imgUser'),
        };
    },
    //получаем информацию по активной ГЕО точке
    elementInfoGeo () {
        if (!canvasEnter) return false;

        return {
            NAME: canvasEnter.get('info'),
            name: canvasEnter.get('nameGeo'),
            lat: canvasEnter.get('latGeo'),
            lng: canvasEnter.get('lngGeo'),
        };

    },
    elementInfoUrl () {
        if (!canvasEnter) return false;

        return {
            tip: canvasEnter.get('tip'),
            url: canvasEnter.get('url'),
            opacity: canvasEnter.get('opacity'),
        };

    },
    //Изменения шаблона оформления у элементов хэштег/гео/упоминание а еще изминение выбранного юзера и гео
    elementTemplate (val=false, style=false, dop=false) {
        if (!canvasEnter) return false;

        let top = canvasEnter.top;
        let left = canvasEnter.left;
        let angle = canvasEnter.angle;
        let zoom = canvasEnter.scaleX;
        let styl = canvasEnter.styl;
        let info = canvasEnter.info;
        let tip = canvasEnter.type;
        let opacity = canvasEnter.opacity;

        if (val !== false) {
            info = val;
        }

        if (style !== false) {
            styl = style;
        }

        if (dop === false) {
            if (tip === 'user') {
                dop = {
                    tip: 'user',
                    id: canvasEnter.idUser,
                    username: canvasEnter.usernameUser,
                    full_name: canvasEnter.full_nameUser,
                    img: canvasEnter.imgUser,
                    opacity: opacity
                };
            } else if (tip === 'geo') {
                dop = {
                    tip: 'geo',
                    name: canvasEnter.nameGeo,
                    lat: canvasEnter.latGeo,
                    lng: canvasEnter.lngGeo,
                    opacity: opacity
                };
            } else if (tip === 'url') {

                dop = {
                    tip: 'url',
                    url: canvasEnter.url,
                    opacity: opacity
                };


                /*if (dop2) {
                    info = dop2;
                }*/


            } else {
                dop = {
                    tip: 'hashtag',
                    name: canvasEnter.name,
                    subtitle: canvasEnter.subtitle,
                    img: canvasEnter.img,
                    opacity: opacity
                };
            }
        }



        canvas.remove(canvas.getActiveObject());

        this.addElementsUserHashGeo('new', tip, info, top, left, angle, zoom, styl, false, dop);

        //сохраняем изменения в бд
        saveFunction();
    },
    //Изменяем шрифта текста
    elementTextFont (font) {
        if (!canvasEnter) return false;

        if (canvasEnter.fontFamily == 'fabric-neon' && font != 'fabric-neon') {
            canvasEnter.set('shadow', '');
            canvasEnter.set('textBackgroundColor', '');
        }

        canvasEnter.set('fontFamily', font);

        if (font == 'fabric-neon') {
            canvasEnter.set('shadow', '#9520b3 0 0 5px');
            canvasEnter.set('textBackgroundColor', '');
        }

        canvas.renderAll();

        //сохраняем изменения в бд
        saveFunction();
    },
    //Изменяем наклона текста
    elementTextItalic () {
        if (!canvasEnter) return false;

        if (canvasEnter.fontStyle == 'normal') {
            canvasEnter.fontStyle = 'italic';
        } else{
            canvasEnter.fontStyle = 'normal';
        }
        canvas.renderAll();

        //сохраняем изменения в бд
        saveFunction();
    },
    //Изменяем толщины текста
    elementTextBold () {
        if (!canvasEnter) return false;

        if (canvasEnter.fontWeight == 'normal') {
            canvasEnter.fontWeight = 600;
        } else{
            canvasEnter.fontWeight = 'normal';
        }
        canvas.renderAll();

        //сохраняем изменения в бд
        saveFunction();
    },
    //Изменяем выравнивание текста
    elementSetAligenText () {
        if (!canvasEnter) return false;

        if (canvasEnter.textAlign === 'center') {
            canvasEnter.textAlign = 'left';
        } else if (canvasEnter.textAlign === 'left') {
            canvasEnter.textAlign = 'right';
        } else{
            canvasEnter.textAlign = 'center';
        }

        canvas.renderAll();

        //сохраняем изменения в бд
        saveFunction();
    },
    //Возвращает тип выравнивания текста
    elementGetAligenText () {
        if (!canvasEnter) return false;
        return canvasEnter.textAlign;
    },
    //изменяем цвет подложки
    elementTextBackground (color) {
        if (!canvasEnter) return false;

        canvasEnter.set('shadow', '');
        canvasEnter.set('textBackgroundColor', '');

        if (canvasEnter.fontFamily == 'fabric-neon') {
            canvasEnter.set('shadow', color + ' 0 0 5px');
        } else {
            canvasEnter.set('textBackgroundColor', color);
        }

        canvas.renderAll();

        //сохраняем изменения в бд
        saveFunction();
    },
    //возвращает текст
    elementGetText () {
        if (!canvasEnter) return false;
        return canvasEnter.text;
    },
    //редактирует текст
    elementSetText (text) {
        if (!canvasEnter) return false;
        canvasEnter.text = text;
        canvas.renderAll();

        saveFunction();
    },
    //прозрачность элемента
    elementOpacity (type) {
        if (!canvasEnter) return false;

        const typeElement = canvasEnter.type;
        //разрешаем для некоторых элементов
        if (typeElement === 'geo' || typeElement === 'user' || typeElement === 'hashtag') {
            if (type === 'min' && canvasEnter.opacity > 0.1) {
                canvasEnter.opacity-=0.1;
            }
        } else {
            if (type === 'min' && canvasEnter.opacity > 0.3) {
                canvasEnter.opacity-=0.1;
            }
        }

        if (type === 'max' && canvasEnter.opacity < 1) {
            canvasEnter.opacity+=0.1;
        }

        canvas.renderAll();

        saveFunction();
    },
    //цвет фона элемента
    elementBackgroudColor (color) {
        if (!canvasEnter) return false;
        canvasEnter.setColor(color);
        canvas.renderAll();

        //сохраняем изменения в бд
        saveFunction();
    },
    //Удаление элемента
    elementDelite () {
        canvas.remove(canvas.getActiveObject());
    },
    //Сброс выделения элементов
    beckIndex () {
        canvas.discardActiveObject();
        canvas.requestRenderAll();
    },
    save () {

        return new Promise((resolve, reject) => {

            //убираем выделение каких либо элементов
            //canvas.discardActiveObject();
            //canvas.requestRenderAll();

            let json = {
                text: [],
                hashtag: [],
                user: [],
                geo: [],
                square: [],
                triangle: [],
                circle: [],
                slayder: [],
                questions: [],
                poll: [],
                quiz: [],
                url: [],
                image: [],
                gif: [],
                media: {

                }
            };

            let texBoxItems = canvas.getObjects();

            for(let i=0; i<texBoxItems.length; i++ ){

                if (texBoxItems[i].get('type') === "iText") {
                    json.text.push({
                        text: texBoxItems[i].get('text'),
                        x: texBoxItems[i].left / fabricWidth,
                        y: texBoxItems[i].get('top') / fabricHeight,
                        width: {
                            px: texBoxItems[i].get('width'),
                            percent: texBoxItems[i].get('width')*texBoxItems[i].get('scaleX') / fabricWidth,
                            scale: texBoxItems[i].get('scaleX'),
                        },
                        height: {
                            px: texBoxItems[i].get('height'),
                            percent: texBoxItems[i].get('height')*texBoxItems[i].get('scaleY') / fabricHeight,
                            scale: texBoxItems[i].get('scaleY'),
                        },
                        rotation: texBoxItems[i].get('angle'),
                        textAlign: texBoxItems[i].get('textAlign'),
                        fontWeight: texBoxItems[i].get('fontWeight'),
                        fontStyle: texBoxItems[i].get('fontStyle'),
                        opacity: texBoxItems[i].get('opacity'),
                        color: texBoxItems[i].get('fill'),
                        shadow: texBoxItems[i].get('shadow'),
                        textBackgroundColor: texBoxItems[i].get('textBackgroundColor'),
                        fontFamily: texBoxItems[i].get('fontFamily'),
                        z: i,
                    });
                } else if(texBoxItems[i].get('type') == "hashtag") {
                    json.hashtag.push({
                        text: texBoxItems[i].get('info'),

                        name: texBoxItems[i].get('nameHashtag'),
                        subtitle: texBoxItems[i].get('subtitleHashtag'),
                        img: texBoxItems[i].get('imgHashtag'),

                        x: texBoxItems[i].get('left') / fabricWidth,
                        y: texBoxItems[i].get('top') / fabricHeight,
                        width: {
                            px: texBoxItems[i].get('width'),
                            percent: texBoxItems[i].get('width')*texBoxItems[i].get('scaleX') / fabricWidth,
                            scale: texBoxItems[i].get('scaleX'),
                        },
                        height: {
                            px: texBoxItems[i].get('height'),
                            percent: texBoxItems[i].get('height')*texBoxItems[i].get('scaleY') / fabricHeight,
                            scale: texBoxItems[i].get('scaleY'),
                        },
                        rotation: texBoxItems[i].get('angle'),
                        stile: texBoxItems[i].get('styl'),
                        opacity: texBoxItems[i].get('opacity'),
                        z: i,
                    });
                } else if(texBoxItems[i].get('type') == "user") {
                    json.user.push({
                        text: texBoxItems[i].get('info'),
                        id: texBoxItems[i].get('idUser'),
                        username: texBoxItems[i].get('usernameUser'),
                        full_name: texBoxItems[i].get('full_nameUser'),
                        img: texBoxItems[i].get('imgUser'),
                        x: texBoxItems[i].get('left') / fabricWidth,
                        y: texBoxItems[i].get('top') / fabricHeight,
                        width: {
                            px: texBoxItems[i].get('width'),
                            percent: texBoxItems[i].get('width')*texBoxItems[i].get('scaleX') / fabricWidth,
                            scale: texBoxItems[i].get('scaleX'),
                        },
                        height: {
                            px: texBoxItems[i].get('height'),
                            percent: texBoxItems[i].get('height')*texBoxItems[i].get('scaleY') / fabricHeight,
                            scale: texBoxItems[i].get('scaleY'),
                        },
                        rotation: texBoxItems[i].get('angle'),
                        stile: texBoxItems[i].get('styl'),
                        opacity: texBoxItems[i].get('opacity'),
                        z: i,
                    });
                } else if(texBoxItems[i].get('type') == "url") {
                    json.url.push({
                        text: texBoxItems[i].get('info'),
                        url: texBoxItems[i].get('url'),
                        x: texBoxItems[i].get('left') / fabricWidth,
                        y: texBoxItems[i].get('top') / fabricHeight,
                        width: {
                            px: texBoxItems[i].get('width'),
                            percent: texBoxItems[i].get('width')*texBoxItems[i].get('scaleX') / fabricWidth,
                            scale: texBoxItems[i].get('scaleX'),
                        },
                        height: {
                            px: texBoxItems[i].get('height'),
                            percent: texBoxItems[i].get('height')*texBoxItems[i].get('scaleY') / fabricHeight,
                            scale: texBoxItems[i].get('scaleY'),
                        },
                        rotation: texBoxItems[i].get('angle'),
                        stile: texBoxItems[i].get('styl'),
                        opacity: texBoxItems[i].get('opacity'),
                        z: i,
                    });
                } else if(texBoxItems[i].get('type') == "geo") {
                    json.geo.push({
                        text: texBoxItems[i].get('info'),
                        name: texBoxItems[i].get('nameGeo'),
                        lat: texBoxItems[i].get('latGeo'),
                        lng: texBoxItems[i].get('lngGeo'),
                        x: texBoxItems[i].get('left') / fabricWidth,
                        y: texBoxItems[i].get('top') / fabricHeight,
                        width: {
                            px: texBoxItems[i].get('width'),
                            percent: texBoxItems[i].get('width')*texBoxItems[i].get('scaleX') / fabricWidth,
                            scale: texBoxItems[i].get('scaleX'),
                        },
                        height: {
                            px: texBoxItems[i].get('height'),
                            percent: texBoxItems[i].get('height')*texBoxItems[i].get('scaleY') / fabricHeight,
                            scale: texBoxItems[i].get('scaleY'),
                        },
                        rotation: texBoxItems[i].get('angle'),
                        stile: texBoxItems[i].get('styl'),
                        opacity: texBoxItems[i].get('opacity'),
                        z: i,
                    });
                } else if(texBoxItems[i].get('type') == "rect") {
                    json.square.push({
                        x: texBoxItems[i].get('left') / fabricWidth,
                        y: texBoxItems[i].get('top') / fabricHeight,
                        width: {
                            px: texBoxItems[i].get('width'),
                            percent: texBoxItems[i].get('width')*texBoxItems[i].get('scaleX') / fabricWidth,
                            scale: texBoxItems[i].get('scaleX'),
                        },
                        height: {
                            px: texBoxItems[i].get('height'),
                            percent: texBoxItems[i].get('height')*texBoxItems[i].get('scaleY') / fabricHeight,
                            scale: texBoxItems[i].get('scaleY'),
                        },
                        rotation: texBoxItems[i].get('angle'),
                        opacity: texBoxItems[i].get('opacity'),
                        color: texBoxItems[i].get('fill'),
                        z: i,
                    });
                } else if(texBoxItems[i].get('type') == "triangle") {
                    json.triangle.push({
                        x: texBoxItems[i].get('left') / fabricWidth,
                        y: texBoxItems[i].get('top') / fabricHeight,
                        width: {
                            px: texBoxItems[i].get('width'),
                            percent: texBoxItems[i].get('width')*texBoxItems[i].get('scaleX') / fabricWidth,
                            scale: texBoxItems[i].get('scaleX'),
                        },
                        height: {
                            px: texBoxItems[i].get('height'),
                            percent: texBoxItems[i].get('height')*texBoxItems[i].get('scaleY') / fabricHeight,
                            scale: texBoxItems[i].get('scaleY'),
                        },
                        rotation: texBoxItems[i].get('angle'),
                        opacity: texBoxItems[i].get('opacity'),
                        color: texBoxItems[i].get('fill'),
                        z: i,
                    });
                } else if(texBoxItems[i].get('type') == "circle") {
                    json.circle.push({
                        x: texBoxItems[i].get('left') / fabricWidth,
                        y: texBoxItems[i].get('top') / fabricHeight,
                        width: {
                            px: texBoxItems[i].get('width'),
                            percent: texBoxItems[i].get('width')*texBoxItems[i].get('scaleX') / fabricWidth,
                            scale: texBoxItems[i].get('scaleX'),
                        },
                        height: {
                            px: texBoxItems[i].get('height'),
                            percent: texBoxItems[i].get('height')*texBoxItems[i].get('scaleY') / fabricHeight,
                            scale: texBoxItems[i].get('scaleY'),
                        },
                        rotation: texBoxItems[i].get('angle'),
                        opacity: texBoxItems[i].get('opacity'),
                        color: texBoxItems[i].get('fill'),
                        z: i,
                    });
                } else if(texBoxItems[i].get('type') == "slayder") {
                    json.slayder.push({
                        text: texBoxItems[i].get('text'),
                        color: texBoxItems[i].get('color'),
                        colorText: texBoxItems[i].get('colorText'),
                        emagi: texBoxItems[i].get('emagi'),
                        x: texBoxItems[i].get('left') / fabricWidth,
                        y: texBoxItems[i].get('top') / fabricHeight,
                        width: {
                            px: texBoxItems[i].get('width'),
                            percent: texBoxItems[i].get('width')*texBoxItems[i].get('scaleX') / fabricWidth,
                            scale: texBoxItems[i].get('scaleX'),
                        },
                        height: {
                            px: texBoxItems[i].get('height'),
                            percent: texBoxItems[i].get('height')*texBoxItems[i].get('scaleY') / fabricHeight,
                            scale: texBoxItems[i].get('scaleY'),
                        },
                        rotation: texBoxItems[i].get('angle'),

                        z: i,
                    });
                } else if(texBoxItems[i].get('type') == "questions") {
                    json.questions.push({
                        name: texBoxItems[i].get('name'),
                        color: texBoxItems[i].get('color'),
                        colorText: texBoxItems[i].get('colorText'),
                        x: texBoxItems[i].get('left') / fabricWidth,
                        y: texBoxItems[i].get('top') / fabricHeight,
                        width: {
                            px: texBoxItems[i].get('width'),
                            percent: texBoxItems[i].get('width')*texBoxItems[i].get('scaleX') / fabricWidth,
                            scale: texBoxItems[i].get('scaleX'),
                        },
                        height: {
                            px: texBoxItems[i].get('height'),
                            percent: texBoxItems[i].get('height')*texBoxItems[i].get('scaleY') / fabricHeight,
                            scale: texBoxItems[i].get('scaleY'),
                        },
                        rotation: texBoxItems[i].get('angle'),
                        z: i,
                    });
                } else if(texBoxItems[i].get('type') == "poll") {
                    json.poll.push({
                        textT: texBoxItems[i].get('textT'),
                        textL: texBoxItems[i].get('textL'),
                        textR: texBoxItems[i].get('textR'),
                        fontL: texBoxItems[i].get('fontL'),
                        fontR: texBoxItems[i].get('fontR'),
                        xText: texBoxItems[i].get('xText') / fabricWidth,
                        BoxWidth: texBoxItems[i].get('BoxWidth')*texBoxItems[i].get('scaleX') / fabricWidth,
                        BoxHeight: texBoxItems[i].get('BoxHeight')*texBoxItems[i].get('scaleY') / fabricHeight,
                        yText: texBoxItems[i].get('yText') / fabricHeight,
                        x: texBoxItems[i].get('left') / fabricWidth,
                        y: texBoxItems[i].get('top') / fabricHeight,
                        width: {
                            px: texBoxItems[i].get('width'),
                            percent: texBoxItems[i].get('width')*texBoxItems[i].get('scaleX') / fabricWidth,
                            scale: texBoxItems[i].get('scaleX'),
                        },
                        height: {
                            px: texBoxItems[i].get('height'),
                            percent: texBoxItems[i].get('height')*texBoxItems[i].get('scaleY') / fabricHeight,
                            scale: texBoxItems[i].get('scaleY'),
                        },
                        rotation: texBoxItems[i].get('angle'),
                        z: i,
                    });

                    console.log(texBoxItems[i].get('yText') + ' / ' + fabricHeight + '  = ' + texBoxItems[i].get('yText') / fabricHeight);

                } else if(texBoxItems[i].get('type') == "quiz") {

                    json.quiz.push({
                        name: texBoxItems[i].get('name'),
                        textA: texBoxItems[i].get('textA'),
                        textB: texBoxItems[i].get('textB'),
                        textC: texBoxItems[i].get('textC'),
                        textD: texBoxItems[i].get('textD'),
                        good: texBoxItems[i].get('good'),
                        template: texBoxItems[i].get('template'),
                        x: texBoxItems[i].get('left') / fabricWidth,
                        y: texBoxItems[i].get('top') / fabricHeight,
                        width: {
                            px: texBoxItems[i].get('width'),
                            percent: texBoxItems[i].get('width')*texBoxItems[i].get('scaleX') / fabricWidth,
                            scale: texBoxItems[i].get('scaleX'),
                        },
                        height: {
                            px: texBoxItems[i].get('height'),
                            percent: texBoxItems[i].get('height')*texBoxItems[i].get('scaleY') / fabricHeight,
                            scale: texBoxItems[i].get('scaleY'),
                        },
                        rotation: texBoxItems[i].get('angle'),
                        z: i,
                    });
                } else if(texBoxItems[i].get('type') == "image") {
                    json.image.push({
                        x: texBoxItems[i].get('left') / fabricWidth,
                        y: texBoxItems[i].get('top') / fabricHeight,
                        width: {
                            px: texBoxItems[i].get('width'),
                            percent: texBoxItems[i].get('width')*texBoxItems[i].get('scaleX') / fabricWidth,
                            scale: texBoxItems[i].get('scaleX'),
                        },
                        height: {
                            px: texBoxItems[i].get('height'),
                            percent: texBoxItems[i].get('height')*texBoxItems[i].get('scaleY') / fabricHeight,
                            scale: texBoxItems[i].get('scaleY'),
                        },
                        rotation: texBoxItems[i].get('angle'),
                        opacity: texBoxItems[i].get('opacity'),
                        image: texBoxItems[i].get('image'),
                        z: i,
                    });
                } else if(texBoxItems[i].get('type') == "gif") {

                    json.gif.push({
                        x: texBoxItems[i].get('left') / fabricWidth,
                        y: texBoxItems[i].get('top') / fabricHeight,
                        width: {
                            px: texBoxItems[i].get('width'),
                            percent: texBoxItems[i].get('width')*texBoxItems[i].get('scaleX') / fabricWidth,
                            scale: texBoxItems[i].get('scaleX'),
                        },
                        height: {
                            px: texBoxItems[i].get('height'),
                            percent: texBoxItems[i].get('height')*texBoxItems[i].get('scaleY') / fabricHeight,
                            scale: texBoxItems[i].get('scaleY'),
                        },
                        rotation: texBoxItems[i].get('angle'),
                        opacity: texBoxItems[i].get('opacity'),
                        image: texBoxItems[i].get('image'),
                        z: i,
                    });
                }

            }

            if (!json.text.length &&
                !json.hashtag.length &&
                !json.user.length &&
                !json.geo.length &&
                !json.square.length &&
                !json.triangle.length &&
                !json.circle.length &&
                !json.slayder.length &&
                !json.poll.length &&
                !json.questions.length &&
                !json.quiz.length &&
                !json.url.length &&
                !json.image.length &&
                !json.gif.length
            ) {
                resolve([]);
            } else {
                resolve(json);
            }
        });

    },
    createImg () {

        return new Promise((resolve, reject) => {

            const multiplier = 1400/fabricWidth;
            const image = canvas.toDataURL({format: 'png', multiplier: multiplier});
            resolve(image);


        });
    }
}

function gif(url, callback) {
    var imgs = [];
    gifFrames({ url: url, frames: 'all', outputType: "canvas", cumulative: false,}).then((frameData) => {

        const frames = renderCumulativeFrames(frameData);

        for (var i = 0; i < frames.length; i++) {
            createFrame(frames[i]);
        }

        callback(imgs);

    });

    function createFrame(frame) {

        const dataURL = frame.getImage().toDataURL('image/png');
        var tempImg = fabric.util.createImage();
        tempImg.src = dataURL;

        let delay = frame.frameInfo.delay;

        if (delay === 0) {
            delay = 5;
        }

        imgs.push({img: tempImg, delay: delay});
    }
}

render()

function render() {
    if (canvas) {
        canvas.renderAll();
    }

    fabric.util.requestAnimFrame(render);
}

const renderCumulativeFrames = (frameData) => {
    if (frameData.length === 0) {
        return frameData;
    }
    const previous = document.createElement("canvas");
    const previousContext = previous.getContext("2d");
    const current = document.createElement("canvas");
    const currentContext = current.getContext("2d");

    // Setting the canvas width will clear the canvas, so we only want to do it once.
    const firstFrameCanvas = frameData[0].getImage();

    // It also apperas that 'gif-frames' always returns a consistent sized canvas for all frames.
    previous.width = firstFrameCanvas.width;
    previous.height = firstFrameCanvas.height;
    current.width = firstFrameCanvas.width;
    current.height = firstFrameCanvas.height;

    for (const frame of frameData) {
        // Copy the current to the previous.
        previousContext.clearRect(0, 0, previous.width, previous.height);
        previousContext.drawImage(current, 0, 0);

        // Draw the current frame to the cumulative buffer.
        const canvas = frame.getImage();
        const context = canvas.getContext("2d");
        currentContext.drawImage(canvas, 0, 0);
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(current, 0, 0);

        const {frameInfo} = frame;
        const {disposal} = frameInfo;
        // If the disposal method is clear to the background color, then clear the canvas.
        if (disposal === 2) {
            currentContext.clearRect(frameInfo.x, frameInfo.y, frameInfo.width, frameInfo.height);
            // If the disposal method is reset to the previous, then copy the previous over the current.
        } else if (disposal === 3) {
            currentContext.clearRect(0, 0, current.width, current.height);
            currentContext.drawImage(previous, 0, 0);
        }
        frame.getImage = () => canvas;
    }
    return frameData;
};

export default Fabric;
