import React from 'react';
import {inject, observer} from "mobx-react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {observable} from "mobx";
import APIsettings from "../../api/settings";
import SettingsGroups from "../../templates/settings/groups/groups";


const getItemStyleGroup = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 2,
    margin: `0 0 1px 0`,
    borderRadius: 4,

    // change background colour if dragging
    background: isDragging ? '#f5f6fa': '#f5f6fa',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 19,
    margin: `0 0 1px 0`,

    // change background colour if dragging
    background: isDragging ? '#f5f6fa': '#f5f6fa',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'rgb(234, 236, 243)': '#f5f6fa',//'#f5f6fa',
    padding: 2,
    borderRadius: 4,
});

const getListStyleGroupsElements = isDraggingOver => ({
    background: isDraggingOver ? 'rgb(234, 236, 243)': '#f5f6fa',//'#f5f6fa',
    padding: 2,
    margin:'0px 8px 0px 8px',
    borderRadius: 4,

});

@inject('initialStore', 'accountsStore', 'notificationStore')
@observer
class Groups extends React.Component {

    @observable addGroupButton = true;
    @observable GroupRed = {
        id: false,
        name: '',
    };

    constructor (props) {
        super(props);

        //this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.addGroup = this.addGroup.bind(this);

        this.deliteGroop = this.deliteGroop.bind(this);
        this.redGroop = this.redGroop.bind(this);
        this.redGroopChenge = this.redGroopChenge.bind(this);
        this.redGroopSave = this.redGroopSave.bind(this);
    }

    render() {

        const accountsStore = this.props.accountsStore;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.settings;

        const accounts = accountsStore.list;
        const groups = accountsStore.groups;

        const GroupRed = this.GroupRed;

        const content = (
            <>
                <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>

                    <Droppable droppableId={'Groups'} type="Group">
                        {(provided, snapshot) => (
                            <>
                            <div ref={provided.innerRef} className="settings-groups" style={getListStyle(snapshot.isDraggingOver)}>
                                {groups.map((itemGroup, indexGroup) => {

                                    if (itemGroup.id === 0) return;

                                    return <Draggable key={'group-' + itemGroup.id} draggableId={'group-' + itemGroup.id} index={indexGroup}>
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef}
                                                 {...provided.draggableProps}
                                                 className={'settings-groups__group'}
                                                 style={getItemStyleGroup(
                                                     snapshot.isDragging,
                                                     provided.draggableProps.style
                                                 )}>

                                                {GroupRed.id === itemGroup.id ?
                                                    <div className="settings-groups__add settings-groups__add_ded">
                                                        <span className="title-add">{text.groupName}:</span>
                                                        <div className="input-group">
                                                            <input autoFocus onChange={this.redGroopChenge} value={GroupRed.name} placeholder="" className="form-control"/>
                                                            <button type="button" className="btn btn-primary" onClick={this.redGroopSave}>
                                                                <i className="icon icon-settings-add"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="settings-groups__item chief">
                                                        <span className="title-item">{itemGroup.name}</span>
                                                        <span className="control-item">
                                                            <i onClick={() => {this.redGroop(itemGroup.id)}} className="icon icon-settings-edit"></i>
                                                            <i onClick={() => {this.deliteGroop(itemGroup.id)}} className="icon icon-settings-remove"></i>
                                                        </span>
                                                        <span className="move-item" {...provided.dragHandleProps}><i className="icon icon-settings-move"></i></span>
                                                    </div>
                                                }


                                                    <Droppable droppableId={String(itemGroup.id)}>
                                                        {(provided, snapshot) => (
                                                            <div ref={provided.innerRef} style={getListStyleGroupsElements(snapshot.isDraggingOver)}>

                                                                {accounts.map((item, index) => {

                                                                    if (item.group !== itemGroup.id) {
                                                                        return null;
                                                                    }

                                                                    let type = item.tip;
                                                                    if (type === 'fb') type = 'FB';
                                                                    else if (type === 'tg') type = 'TG';
                                                                    else if (type === 'vk') type = 'VK';
                                                                    else if (type === 'ok') type = 'OK';
                                                                    else if (type === 'instagram') type = 'Insta';


                                                                    return <Draggable key={'item-' + item.id} draggableId={'item-' + item.id} index={index}>
                                                                        {(provided, snapshot) => (
                                                                            <div className={'settings-groups__item settings-groups__item__dop'} ref={provided.innerRef}
                                                                                 {...provided.draggableProps}
                                                                                 style={getItemStyle(
                                                                                         snapshot.isDragging,
                                                                                         provided.draggableProps.style
                                                                                     )}>
                                                                                <span className="title-item">{type}: {item.name}</span>
                                                                                <span className="move-item" {...provided.dragHandleProps}>
                                                                                    <i className="icon icon-settings-move"></i>
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                })}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                            </div>
                                        )}
                                    </Draggable>
                                })}
                                {provided.placeholder}
                            </div>
                            </>
                        )}
                    </Droppable>

                    <Droppable droppableId={'0'}>
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} className="settings-groups settings-groups_non_group" style={getListStyle(snapshot.isDraggingOver)}>

                                {accounts.map((item, index) => {

                                    if (item.group !== 0) {
                                        return null;
                                    }
                                    let type = item.tip;
                                    if (type === 'fb') type = 'FB';
                                    else if (type === 'tg') type = 'TG';
                                    else if (type === 'vk') type = 'VK';
                                    else if (type === 'ok') type = 'OK';
                                    else if (type === 'instagram') type = 'Insta';

                                    return <Draggable key={'item-' + item.id} draggableId={'item-' + item.id}
                                                      index={index}>
                                        {(provided, snapshot) => (
                                            <div className={'settings-groups__item settings-groups__item_non_group'} ref={provided.innerRef}
                                                 {...provided.draggableProps}
                                                 style={getItemStyle(
                                                     snapshot.isDragging,
                                                     provided.draggableProps.style
                                                 )}>
                                                <span className="title-item">{type}: {item.name}</span>
                                                <span className="move-item" {...provided.dragHandleProps}>
                                                    <i className="icon icon-settings-move"></i>
                                                </span>
                                            </div>
                                        )}
                                    </Draggable>
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </>
        );

        return <SettingsGroups add={this.addGroup}>
            {content}
        </SettingsGroups>
    }

    redGroop (id) {
        const accountsStore = this.props.accountsStore;

        let name = '';
        for (const element of accountsStore.groups) {
            if (element.id === id) {
                name = element.name;
                break;
            }
        }

        this.GroupRed = {
            id: id,
            name: name,
        }
    }

    redGroopChenge (event) {
        let name = event.target.value;
        this.GroupRed = {
            id: this.GroupRed.id,
            name: name,
        }
    }

    redGroopSave () {
        const accountsStore = this.props.accountsStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        let name = this.GroupRed.name.trim();
        let id = this.GroupRed.id;

        if (name === '') {
            notificationStore.add(text[90]);
        }

        const BackupGroups = [...accountsStore.groups];
        let newGroups = [];
        for (let element of accountsStore.groups) {
            if (element.id === id) {
                element.name = name;
            }
            newGroups.push({...element});
        }

        accountsStore.groups = newGroups;

        APIsettings.groupsRed(id, name).then((data)=> {
            if (data.status) {

            } else {
                accountsStore.groups = BackupGroups;
                notificationStore.add(text[91]);
            }
        }).catch((e)=> {
            accountsStore.groups = BackupGroups;
            notificationStore.add(text[91]);
        });

        this.GroupRed = {
            id: false,
            name: '',
        }
    }

    deliteGroop (id) {

        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;
        this.GroupRed = {id: false, name: ''};

        const accountsStore = this.props.accountsStore;

        const BackupAccounts = [...accountsStore.list];
        const BackupGroups = [...accountsStore.groups];

        let newList = accountsStore.list.map((item) => {
            let element = {...item};
            if (element.group === id) {
                element.group = 0;
            }
            return element;
        });

        let newListGroup = [];
        for (const element of accountsStore.groups) {
            if (element.id !== id) {
                newListGroup.push({...element});
            }
        }

        accountsStore.groups = newListGroup;
        accountsStore.list = newList;
        accountsStore.sortByGroup();

        //отправляем данные на сервер
        let list = [];
        for (const element of accountsStore.list) {
            list.push({
                id: element.id,
                group: element.group,
            });
        }

        APIsettings.groupsAccountsOrder(list).then((data)=> {
            if (data.status) {
                APIsettings.groupsDel(id).then((data)=> {
                    if (data.status) {
                        notificationStore.add(text[92]);
                    } else {
                        accountsStore.groups = BackupGroups;
                        notificationStore.add(text[93]);
                    }
                }).catch((e)=> {
                    accountsStore.groups = BackupGroups;
                    notificationStore.add(text[93]);
                });
            } else {
                accountsStore.list = BackupAccounts;
                accountsStore.groups = BackupGroups;
                notificationStore.add(text[94]);
            }
        }).catch((e)=> {
            accountsStore.list = BackupAccounts;
            accountsStore.groups = BackupGroups;
            notificationStore.add(text[94]);
        });
    }

    addGroup () {
        const accountsStore = this.props.accountsStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;
        const textSettings = initialStore.text.settings;

        this.GroupRed = {id: false, name: ''};

        this.addGroupButton = false;

        APIsettings.groupsAdd(textSettings.newGroup).then((data) => {
            if (data.status) {

                accountsStore.groups = [...accountsStore.groups, {
                    id: data.group.id,
                    name: data.group.name,
                }];

            } else {
                notificationStore.add(text[95]);
            }
            this.addGroupButton = true;
        }).catch((e) => {
            notificationStore.add(text[95]);
            this.addGroupButton = true;
        });

    }

    onDragStart () {
        this.GroupRed = {id: false, name: ''};
    }

    onDragEnd = (result) => {
        const notificationStore = this.props.notificationStore;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        const accountsStore = this.props.accountsStore;

        const { source, destination } = result;

        if (destination === null) return;
        if (source.droppableId === null) return;
        if (destination.droppableId === null) return;

        if (source.droppableId === 'Groups' && destination.droppableId === 'Groups') {

            const result = Array.from(accountsStore.groups);
            const resultCopy = Array.from(accountsStore.groups);
            const [removed] = result.splice(source.index, 1);
            result.splice(destination.index, 0, removed);

            accountsStore.groups = result;

            accountsStore.sortByGroup();

            //отправляем данные на сервер
            let list = [];
            for (const element of accountsStore.groups) {
                if (element.id === 0) continue;
                list.push(element.id);
            }

            APIsettings.groupsOrder(list).then((data)=> {
                if (data.status) {

                } else {
                    accountsStore.groups = resultCopy;
                    notificationStore.add(text[96]);
                }
            }).catch((e)=> {
                accountsStore.groups = resultCopy;
                notificationStore.add(text[96]);
            });


        } else {

            if (source.index < destination.index && source.droppableId !== destination.droppableId ) {
                destination.index--;
            }

            const result = Array.from(accountsStore.list);
            const resultCopy = Array.from(accountsStore.list);
            const [removed] = result.splice(source.index, 1);
            result.splice(destination.index, 0, removed);

            removed.group = Number(destination.droppableId);

            accountsStore.list = result;

            accountsStore.sortByGroup();

            //отправляем данные на сервер
            let list = [];
            for (const element of accountsStore.list) {
                list.push({
                    id: element.id,
                    group: element.group,
                });
            }

            APIsettings.groupsAccountsOrder(list).then((data)=> {
                if (data.status) {

                } else {
                    accountsStore.list = resultCopy;
                    notificationStore.add(text[96]);
                }
            }).catch((e)=> {
                accountsStore.list = resultCopy;
                notificationStore.add(text[96]);
            });
        }
    };
}

export default Groups;