import React from 'react';
import {inject, observer} from "mobx-react";
import TitleH1 from "../../elements/text/titleH1";
import Input from "../../elements/input";
import ButtonLoad from "../../elements/buttonLoad";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Button from "../../elements/button";

export default
@inject('initialStore')
@observer
class SettingsGroups extends React.Component {

    render() {

        const initialStore = this.props.initialStore;
        const text = initialStore.text.settings;

        if (initialStore.templates === 'desktop') {

            return (
                <div className="settings">
                    <TitleH1>{text.accountGroups}</TitleH1>

                    <p className="settings__help">{text.textInformGroups}</p>
                    <div className={'settings-groups'} style={{marginLeft: -12}}>
                        {this.props.children}
                    </div>

                    <div className="more-btn bottom">
                        <Button action={this.props.add}>addGroup</Button>
                    </div>

                </div>
            );
        } else {
            return (
                <div className="settings">

                    <p className="settings__help">{text.textInformGroups}</p>

                    <div className={'settings-groups'} style={{marginLeft: -12}}>
                        {this.props.children}
                    </div>

                    <div className="more-btn bottom">
                        <Button action={this.props.add}>addGroup</Button>
                    </div>

                </div>
            );
        }

    }
}