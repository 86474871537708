import React from 'react';
import {inject, observer} from "mobx-react";

import Input from "../elements/input";
import Select from "../elements/select";
import LoaderComponent from "../loaderComponent";

export default
@inject('initialStore')
@observer
class RateIndexActiveIOS extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.rate;

        const percent = Math.round(100 / this.props.account * this.props.accountActive);

        if (initialStore.templates === 'desktop') {
            return null;
        } else {
            return <div className="tariffs">

                <div className="tariff">

                    <div className="tariff-tasks__item">
                        <div className="top-item">
                            <span className="title-top">{this.props.account > 3 ? <>{text[53]} {this.props.account} {text[54]}</>:<>{text[55]}</>}</span>
                        </div>



                        <div className="cnt-item">
                            <div className="tariff-posts__progress">
                                <div className="top-progress">
                                    <span>{text[56]}</span>
                                    <b>{this.props.accountActive} {text[57]} {this.props.account}</b>
                                </div>
                                <div className="line-progress">
                                    <div className="inner-line" style={{width: percent+'%'}}></div>
                                </div>
                            </div>
                            <p className="help-posts">{this.props.time ? <><b>{text[13]}</b> {this.props.time}</>:<b>{text[14]}</b>}</p>
                            <p className="help-posts">{text[15](this.props.timeOstatokInDey)}</p>
                        </div>
                        {this.props.account > 3 && this.props.time ?
                            <div className="form-item">

                                <button onClick={this.props.openRateRed} type="button" className="btn btn-primary btn-primary-button-m" style={{width: '100%'}}>{this.props.openRate ? text[66]:text[67]}</button>
                                {this.props.openRate ?
                                    <>
                                        <div className="tariff-form__inputs row red-rate">
                                            <div className="col">
                                                <div className="form-group">
                                                    <div className="input-count">
                                                        <Select options={this.props.optionsTarifAccountiOS} action={(accounts) => this.props.formChange(accounts, true)}>{this.props.formAccount}</Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <div className="input-count">
                                                        <button onClick={this.props.buttonClickRed} type="button" className="btn btn-primary" style={{width: '100%'}}>Сохранить</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {this.props.errorRedRate === 0 ? <>
                                            <div style={{marginBottom: '10px'}}>
                                                {this.props.account != this.props.formAccount ?
                                                    <>
                                                        <p className="finish-form">{text[59]} <b>{this.props.tarifTime}</b></p>
                                                        <p className="finish-form">{text[60]} <b>{this.props.tarifMoney} {text[61]}</b></p>
                                                    </>
                                                    :null}
                                            </div>
                                            <span className="input-label">{text[62]}</span>
                                        </>:<>
                                            <span className="input-label" style={{color: '#ea1818'}}>{text[63]} {this.props.errorRedRate === 1 ? text[64]:text[65]}</span>
                                        </>}

                                    </>
                                    :null}
                            </div>
                            :null}
                    </div>


                    <div className="tariff-form">
                        <span className="title-form">{this.props.time && this.props.account > 5 ? text[17] : text[52] }</span>
                        <div className="tariff-form__inputs row">
                            {this.props.tarifMoney !== 0 ?
                                <>
                                    {this.props.account < 5 || !this.props.time ?
                                        <div className="col" style={{width: '100%'}}>
                                            <div className="form-group">
                                                <span className="input-label">{text[18]}</span>
                                                <div className="input-count">
                                                    <Select options={this.props.optionsTarifAccountiOS} action={(accounts) => this.props.formChange(accounts, true)}>{this.props.formAccount}</Select>
                                                </div>
                                            </div>
                                        </div>
                                        :null}
                                    <div className="col" style={{width: this.props.account < 5 || !this.props.time ? '100%' : '100%'}}>
                                        <div className="form-group">
                                            <span className="input-label">{text[19]}</span>
                                            <Select options={this.props.optionsTarifIOS} action={this.props.selectTarifAction}>{this.props.selectTarifValue}</Select>
                                        </div>
                                    </div>
                                </>
                                :null}
                        </div>
                        {!this.props.error ?
                            <>

                                <p className="finish-form">{text[21]}: <b>{this.props.tarifMoneyReal}</b></p>
                                <p className="finish-form">{text[22]}: <b>{this.props.tarifTimeReal}</b></p>
                                <div className="more-btn bottom">
                                    <button onClick={this.props.payIOS} type="button" style={{width: '100%'}} className={!this.props.payStart ? "btn btn-primary": "btn btn-primary btn-primary-animate"}>{this.props.buttonName}</button>
                                    {/*<button onClick={this.props.payIOS} type="button" className={!this.props.payStart ? "btn btn-primary": "btn btn-primary btn-primary-animate"}>{text[42]}</button>*/}
                                </div>
                            </>
                            :
                            <p className="finish-form">{this.props.error}</p>
                        }

                    </div>
                </div>
            </div>;
        }

    }
}

