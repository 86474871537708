import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import Input from "../../../templates/elements/input";
import TextModel from "../../../model/text";

@inject('initialStore', 'accountsStore', 'mediaStore')
@observer
class Name extends React.Component {

    @observable emojeOpen = false;

    constructor (props) {
        super(props);

        this.textChange = this.textChange.bind(this);
    }

    render() {
        const mediaStore = this.props.mediaStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        const name = mediaStore.name;

        if (mediaStore.type === 'post' || mediaStore.type === 'edit') {

            if (mediaStore.accounts.length > 0) {
                return  null;
            }
        } else if (mediaStore.type === 'igtv' || mediaStore.type === "editIgtv") {

        } else {
            return null;
        }

        const error = TextModel.limitedName(name).str;

        let render = <>
            <div className="input-label flex">
                <span className="title">{text[51]}</span>
                <div className="add-publication__template">
                </div>
            </div>
            <Input onChange={this.textChange} placeholder={text[51]} emoje>{name}</Input>
            <span className="count-input">{error}</span>
        </>;

        return render;
    }

    textChange = (value) => {
        this.props.mediaStore.name = value;
    }
}
export default Name;