import React from 'react';
import {inject, observer} from "mobx-react";
import APIdirect from "../../../../api/direct";
import AccountDirectCreateMessage from "../../../../templates/account/direct/createMessage";
import {observable} from "mobx";
import Time from "../../../../model/time";
import directLoad from "../../../../media/gif/directLoad.gif";

import AccountDirectPosts from "../../../../templates/account/direct/posts";

import AccountDirectAddAccount from "../../../../templates/account/direct/addAccount";
import LoaderComponent from "../../../../templates/loaderComponent";
import InfoContainer from "../../../../templates/infoConteiner";
import MessageContent from "./MessageContent";
import Dialogs from "./Dialogs";
import initialStore from "../../../../stor/initialStore";

@inject('notificationStore', 'initialStore', 'accountsStore', 'menuStore')
@observer
class Direct extends React.Component {

    @observable messageText = '';

    @observable buttonPendingStatus = 0;

    constructor(props) {
        super(props);

        this.messageTextChange = this.messageTextChange.bind(this);
        this.sendingMessageText = this.sendingMessageText.bind(this);
        this.sendingMessageMedia = this.sendingMessageMedia.bind(this);
        this.searhDialogs = this.searhDialogs.bind(this);

        this.messagesButton = React.createRef();

        this.newMessageYesAndSkrollButton = false;
    }

    searhDialogs () {
        this.props.notificationStore.add('В разработке', 'Функция поиска по диалогам находится в разработке.', 3);
    }

    addMessagesButton = (e) => {
        this.messagesButton = e;
    }



    scrollToMessagesButton = (animated = false) => {
        this.messagesButton.scrollIntoView({ behavior: animated ? "smooth":"auto" });
    }

    render() {
        const initialStore = this.props.initialStore;
        const accountsStore = this.props.accountsStore;
        const active = accountsStore.active;
        const direct = accountsStore.list[active].direct;
        const text = initialStore.text.account;

        let content = null;

        if (!accountsStore.list[active].proxyInstall) {
            content = <InfoContainer
                title={text[9]}
                text={text[125]}
            />;
        } else {

            if (direct.info) {
                if (direct.info === 'launch') {
                    content = <LoaderComponent title={text[127]}/>;
                } else if (direct.info === 'load') {
                    content = <LoaderComponent title={text[128]}/>;
                } else {
                    content = <LoaderComponent title={direct.info}/>;
                }
            } else {

                if (!direct.status) {
                    content = <InfoContainer
                        title={text[9]}
                        text={text[126]}
                    />;
                } else {

                    const active = direct.account.active;
                    let activeID = false;

                    let titleDialog = '';



                    //диалоги
                    for (const dialogKey in direct.account.list.slice()) {
                        if (active === direct.account.list[dialogKey].thread_id) {
                            activeID = dialogKey;

                            for (const user of direct.account.list[dialogKey].user) {
                                titleDialog = titleDialog === '' ? user.username: titleDialog + ', ' + user.username;
                            }
                            break;
                        }
                    }

                    if (titleDialog === '') {
                        titleDialog = direct.viewer.username;
                    }

                    //сообщения
                    let posts = [];
                    let nextLoad = null;
                    if (activeID!== false) {

                        let timeVievPost = 0;
                        if (direct.account.list[activeID].last_seen_at[direct.viewer.user_id] !== undefined) {
                            timeVievPost = direct.account.list[activeID].last_seen_at[direct.viewer.user_id].timestamp;
                        }

                        let newMessageInt = 0;
                        let newTimeUser = 0;
                        let itemInfoIDAccountBeck = false;
                        posts = direct.account.list[activeID].posts.list.map((dataItem) => {
                            console.log(dataItem);
                            if (dataItem.user_id !== direct.viewer.user_id && dataItem.timestamp > timeVievPost) {
                                newMessageInt++;
                                newTimeUser = dataItem.timestamp;
                            }

                            const item = MessageContent(dataItem, direct.account.list[activeID].user, direct.viewer, direct.account.list[activeID].last_seen_at, itemInfoIDAccountBeck);
                            itemInfoIDAccountBeck = dataItem.user_id;
                            return item.box;
                        });

                        if (newMessageInt) {
                            //direct.account.list[activeID].last_seen_at[direct.viewer.user_id].timestamp = newTimeUser;
                        }

                        if (direct.account.list[activeID].posts.cursor) {
                            nextLoad = <div style={{textAlign: 'center', color: '#7b5c9b', textDecoration: 'underline', padding: 20, cursor: 'pointer'}} onClick={() => {this.nextLoadItems(activeID)}}>{text[129]}</div>;

                            if (direct.account.list[activeID].posts.cursor === 'load') {
                                nextLoad = <div style={{textAlign: 'center', color: '#7b5c9b', textDecoration: 'underline', padding: 20}}><img width={16} height={16} src={directLoad}/></div>;
                            }
                        }
                    }

                    let dopStyle = null;
                    let buttonBeck = null;
                    if (initialStore.templates !== 'desktop') {
                        dopStyle = {
                            style: activeID ? null: {display: 'none'},
                        };
                        buttonBeck = <span className="directMobileBeckButton" onClick={()=> {direct.account.active=false;}}>{text[130]}</span>;
                    }

                    content = <div className="chat">
                        <Dialogs
                            buttonPendingStatus={this.buttonPendingStatus}
                            buttonPendingStatusRed={(e)=>{this.buttonPendingStatus = e;}}
                            activeID={activeID}
                        />
                        <div className="chat-dashboard" {...dopStyle}>

                            {activeID !== false ?
                            <>
                                <div className="chat-company">
                                    <span className="name-company" style={{whiteSpace: 'pre-line'}}>{buttonBeck}{titleDialog}</span>
                                </div>
                                <AccountDirectPosts
                                    account={activeID}
                                    messagesButton={this.messagesButton}
                                    addMessagesButton={this.addMessagesButton}
                                    scrollToMessagesButton={this.scrollToMessagesButton}
                                    cursor={activeID === false ? false:direct.account.list[activeID].posts.cursor}
                                    cursorLoad={() => {this.nextLoadItems(activeID)}}
                                >
                                    {nextLoad}
                                    {posts}
                                </AccountDirectPosts>

                                {direct.account.list[activeID].pending === 0 ?
                                    <AccountDirectCreateMessage
                                        textOnChange={this.messageTextChange}
                                        textValue={this.messageText}
                                        sendingMessageText={this.sendingMessageText}
                                        sendingMessageMedia={this.sendingMessageMedia}
                                        scrollToMessagesButton={this.scrollToMessagesButton}
                                    />
                                :
                                    <AccountDirectAddAccount
                                        buttonOk={()=>{this.buttonAccountOk(activeID)}}
                                        buttonDel={()=>{this.buttonAccountDel(activeID)}}
                                    />
                                }
                            </>
                            :
                            <>
                              <InfoContainer
                                  title={text[131]}
                                  text={text[132]}
                              />
                            </>}

                        </div>
                    </div>;
                }

            }
        }

        return content;
    }


    buttonAccountOk = (activeID) => {
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;

        const active = accountsStore.active;
        const direct = accountsStore.list[active].direct;

        const account = accountsStore.list[active].id;
        const dialog = direct.account.active;

        direct.account.list[activeID].pending = 0;
        this.buttonPendingStatus = 0;
        APIdirect.sending_approvependingthreads(account, dialog).then((data) => {
            if (data.status) {

            } else {
                alert(initialStore.text.code[data.code]);
            }
        }).catch((e) => {
            alert(initialStore.text.code[0]);
        });
    }

    buttonAccountDel = (activeID) => {
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;

        const active = accountsStore.active;
        const direct = accountsStore.list[active].direct;

        const account = accountsStore.list[active].id;
        const dialog = direct.account.active;

        direct.account.active = false;

        let List = [...direct.account.list.slice()];
        let newList = [];
        let YesPending = false;
        for (const key in List) {
            if (Number(key) !== Number(activeID)) {
                newList.push({...List[key]});
                if (List[key].pending === 1) {
                    YesPending = true;
                }
            }
        }
        direct.account.list = newList;

        if (!YesPending) {
            this.buttonPendingStatus = 0;
        }

        APIdirect.sending_declinependingthreads(account, dialog).then((data) => {
            if (data.status) {

            } else {
                alert(initialStore.text.code[data.code]);
            }
        }).catch((e) => {
            alert(initialStore.text.code[0]);
        });

    }

    sendingMessageMedia (type, url, file, proportions, cover) {
        this.newMessageYesAndSkrollButton = true;
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;

        const active = accountsStore.active;
        const direct = accountsStore.list[active].direct;

        const account = accountsStore.list[active].id;
        const dialog = direct.account.active;

        const accountList = direct.account.list.slice();
        let dialogID = false;
        for (const dialogKey in accountList) {
            if (accountList[dialogKey].thread_id === dialog) {
                dialogID = dialogKey;
                break;
            }
        }

        let listBeckape = false;

        if (dialogID) {
            listBeckape = accountList[dialogID].posts.list;
            let listCopy = accountList[dialogID].posts.list.slice();

            let video_versions = {};

            if (type === 'video') {
                video_versions = {
                    video_versions: [
                        {
                            type: 101,
                            width: 800,
                            height: Math.round(800/proportions),
                            url: url,
                            id: Time.getUnix(),
                        }
                    ]
                };
            }

            listCopy.push({
                item_id: Time.getUnix(),
                user_id: direct.viewer.user_id,
                timestamp: 0,
                item_type: 'media',
                item_info: {
                    media: {
                        id: Time.getUnix(),
                        image_versions2: {
                            candidates: [
                                {
                                    width: 800,
                                    height: Math.round(800/proportions),
                                    url: type === 'image' ? url:cover,
                                },
                            ],
                        },
                        original_width: 800,
                        original_height: Math.round(800/proportions),
                        media_type: type === 'image' ? 1:2,
                        ...video_versions,
                    }
                },
            });

            accountsStore.list[active].direct.account.list[dialogID].posts.list = [...listCopy];
        }

        APIdirect.sending_media(account, dialog, type, file).then((data) => {
            if (data.status) {

            } else {
                alert(initialStore.text.code[data.code]);
                if (listBeckape) {
                    accountsStore.list[active].direct.account.list[dialogID].posts.list = listBeckape;
                }
            }
        }).catch((e) => {
            alert(initialStore.text.code[0]);
            if (listBeckape) {
                accountsStore.list[active].direct.account.list[dialogID].posts.list = listBeckape;
            }
        });


    }

    sendingMessageText () {

        const text = this.messageText.trim();

        if (text === '') {
            return;
        }

        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;

        const active = accountsStore.active;
        const direct = accountsStore.list[active].direct;

        const account = accountsStore.list[active].id;
        const dialog = direct.account.active;

        const accountList = direct.account.list.slice();
        let dialogID = false;
        for (const dialogKey in accountList) {
            if (accountList[dialogKey].thread_id === dialog) {
                dialogID = dialogKey;
                break;
            }
        }

        let listBeckape = false;

        if (dialogID) {
            listBeckape = accountList[dialogID].posts.list;
            let listCopy = accountList[dialogID].posts.list.slice();

            listCopy.push({
                item_id: Time.getUnix(),
                user_id: direct.viewer.user_id,
                timestamp: 0,
                item_type: 'text',
                item_info: {
                    text: text,
                },
            });

            accountsStore.list[active].direct.account.list[dialogID].posts.list = [...listCopy];

            //перемещаем диалог на первое место
            if (dialogID > 0) {
                let list = accountsStore.list[active].direct.account.list.slice();
                list.unshift(...list.splice(dialogID,1));
                accountsStore.list[active].direct.account.list = [...list];
            }

        }

        APIdirect.sending_message(account, dialog, text).then((data) => {
            if (data.status) {

            } else {
                alert(initialStore.text.code[data.code]);
                if (listBeckape) {
                    accountsStore.list[active].direct.account.list[dialogID].posts.list = listBeckape;
                }
            }
        }).catch((e) => {
            alert(initialStore.text.code[0]);
            if (listBeckape) {
                accountsStore.list[active].direct.account.list[dialogID].posts.list = listBeckape;
            }
        });

        this.messageText = '';

        this.newMessageYesAndSkrollButton = true;
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.newMessageYesAndSkrollButton) {
            this.newMessageYesAndSkrollButton = false;
            this.scrollToMessagesButton(true);
        }

        //прочитывание новых сообщений
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;

        const active = accountsStore.active;
        const direct = accountsStore.list[active].direct;

        const activeDirect = direct.account.active;

        if (activeDirect !== false) {

            let activeID = false;

            for (const key in direct.account.list.slice()) {
                if (direct.account.list[key].thread_id === activeDirect) {
                    activeID = key;
                    break;
                }
            }

            let timeVievPost = 0;
            if (direct.account.list[activeID].last_seen_at[direct.viewer.user_id] !== undefined) {
                timeVievPost = direct.account.list[activeID].last_seen_at[direct.viewer.user_id].timestamp;
            }

            let newMessageInt = 0;
            let newTimeUser = 0;
            let itemList = '';
            for (const elementMessage of direct.account.list[activeID].posts.list) {

                if (elementMessage.user_id !== direct.viewer.user_id && elementMessage.timestamp > timeVievPost) {
                    newMessageInt++;
                    newTimeUser = elementMessage.timestamp;

                    itemList = elementMessage.item_id;
                }
            }

            if (newMessageInt) {

                this.scrollToMessagesButton(true);

                if (direct.account.list[activeID].last_seen_at[direct.viewer.user_id] !== undefined) {
                    setTimeout(() => {
                        direct.account.list[activeID].last_seen_at[direct.viewer.user_id].timestamp = newTimeUser;
                    }, 500);
                }


                const account = accountsStore.list[active].id;
                const dialog = direct.account.active;

                APIdirect.sending_view(account, dialog, itemList).then((data) => {
                    if (data.status) {

                    } else {
                        alert(initialStore.text.code[data.code]);
                    }
                }).catch((e) => {
                    alert(initialStore.text.code[0]);
                });
            }
        }
    }

    messageTextChange (value) {
        this.messageText = value;
    }

    nextLoadItems = (activeID) => {
        const accountsStore = this.props.accountsStore;
        const active = accountsStore.active;
        const account = accountsStore.list[active].id;
        const direct = accountsStore.list[active].direct;

        const cursor = direct.account.list[activeID].posts.cursor;
        const thread_id = direct.account.list[activeID].thread_id;

        if (cursor !== true) {
            return false;
        }

        direct.account.list[activeID].posts.cursor = 'load';

        APIdirect.nextdialog(account, thread_id).then((data) => {
            if (!data.status) {
                direct.account.list[activeID].posts.cursor = true;
            }
        }).catch((error) => {
            direct.account.list[activeID].posts.cursor = true;
        });
    }

    componentDidMount() {
        this.props.menuStore.updateActiveComponentAccount('direct');
    }
}

export default Direct;