import qs from "qs";
import React from "react";
import instance from "./base";


const APIlibrary = {

    categoryAdd (name) {
        return instance.post('library/caregory_add', qs.stringify({
            name: name,
        })).then(data => {
            return data;
        });
    },

    categoryRed (id, name) {
        return instance.post('library/caregory_red', qs.stringify({
            id: id,
            name: name,
        })).then(data => {
            return data;
        });
    },

    categoryDel (id) {
        return instance.post('library/caregory_del', qs.stringify({
            id: id,
        })).then(data => {
            return data;
        });
    },





}

export default APIlibrary;