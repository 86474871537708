import React from 'react';
import {inject, observer} from "mobx-react";

import Input from "../elements/input";
import Select from "../elements/select";

export default
@inject('initialStore')
@observer
class RateIndexActiveStandart extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        const text = initialStore.text.rate;

        const percent = Math.round(100 / this.props.account * this.props.accountActive);

        let accountsPay = this.props.account;
        if (this.props.account < 5 || !this.props.time) {
            accountsPay = this.props.formAccount;
        }

        let url= 'https://likepro.ru/api2/rate/tinkoff?accounts=' + accountsPay + '&month=' + this.props.selectTarifValue + '&uID='+initialStore.idUser;

        let buttonPay = <a href={url} className={'btn border btn-block '} target="_blank">{this.props.buttonName}</a>;
        if (initialStore.templates !== 'desktop') {

            if (initialStore.platform === 'ios' || initialStore.platform === 'android') {
                buttonPay = <div onClick={() => {window.openUrlModal(url)}} className={'btn btn-primary btn-primary-button-m no-fastclick no-fastclick-click-css '}>{this.props.buttonName}</div>;
            } else {
                buttonPay = <a href={url} className={'btn btn-primary btn-primary-button-m no-fastclick '} target="_blank">{this.props.buttonName}</a>;
            }


        }

        if (initialStore.templates === 'desktop') {
            return <div className="tariffs">

                <div className="tariff">

                    <div className="tariff-tasks__item">
                        <div className="top-item">
                            <span className="title-top">{this.props.account > 3 ? <>{text[53]} {this.props.account} {text[54]}</>:<>{text[55]}</>}</span>
                        </div>



                        <div className="cnt-item">
                            <div className="tariff-posts__progress">
                                <div className="top-progress">
                                    <span>{text[56]}</span>
                                    <b>{this.props.accountActive} {text[57]} {this.props.account}</b>
                                </div>
                                <div className="line-progress">
                                    <div className="inner-line" style={{width: percent+'%'}}></div>
                                </div>
                            </div>
                            <p className="help-posts">{this.props.time ? <><b>{text[13]}</b> {this.props.time}</>:<b>{text[14]}</b>}</p>
                            <p className="help-posts">{text[15](this.props.timeOstatokInDey)}</p>
                        </div>
                        {this.props.account > 3 && this.props.time ?
                        <div className="form-item">

                                <button onClick={this.props.openRateRed} type="button" className="btn btn-primary" style={{width: '100%'}}>{this.props.openRate ? text[66]:text[67]}</button>
                                {this.props.openRate ?
                                    <>
                                    <div className="tariff-form__inputs row red-rate">
                                        <div className="col">
                                            <div className="form-group">
                                                <div className="input-count">
                                                    <span onClick={this.props.formAccountMinus} style={{lineHeight: '30px'}} className="btn-count minus unselectable">-</span>
                                                    <Input style={this.props.formAccountRed ? {border: '1px solid #ea1818', height: 44}:{height: 44}} onChange={this.props.formChange}>{this.props.formAccount}</Input>
                                                    <span onClick={this.props.formAccountPlas} style={{lineHeight: '30px'}} className="btn-count plus unselectable">+</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <div className="input-count">
                                                    <button onClick={this.props.buttonClickRed} type="button" className="btn btn-primary" style={{width: '100%'}}>{text[58]}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                        {this.props.errorRedRate === 0 ? <>
                                            <div style={{marginBottom: '10px'}}>
                                                {this.props.account != this.props.formAccount ?
                                                    <>
                                                        <p className="finish-form">{text[59]} <b>{this.props.tarifTime}</b></p>
                                                        <p className="finish-form">{text[60]} <b>{this.props.tarifMoney} {text[61]}</b></p>
                                                    </>
                                                    :null}
                                            </div>
                                            <span className="input-label">{text[62]}</span>
                                        </>:<>
                                            <span className="input-label" style={{color: '#ea1818'}}>{text[63]} {this.props.errorRedRate === 1 ? text[64]:text[65]}</span>
                                        </>}

                                    </>
                                :null}
                        </div>
                        :null}
                    </div>


                    <div className="tariff-form">
                        <span className="title-form">{this.props.time && this.props.account > 5 ? text[17] : text[52] }</span>
                        <div className="tariff-form__inputs row">
                            {this.props.tarifMoney !== 0 ?
                                <>
                            {this.props.account < 5 || !this.props.time ?
                            <div className="col" style={{width: '50%'}}>
                                <div className="form-group">
                                    <span className="input-label">{text[18]}</span>
                                    <div className="input-count">
                                        <span onClick={this.props.formAccountMinus} style={{lineHeight: '30px'}} className="btn-count minus unselectable">-</span>
                                        <Input style={this.props.formAccountRed ? {border: '1px solid #ea1818', height: 44}:{height: 44}} onChange={this.props.formChange}>{this.props.formAccount}</Input>
                                        <span onClick={this.props.formAccountPlas} style={{lineHeight: '30px'}} className="btn-count plus unselectable">+</span>
                                    </div>
                                </div>
                            </div>
                            :null}
                            <div className="col" style={{width: this.props.account < 5 || !this.props.time ? '50%' : '100%'}}>
                                <div className="form-group">
                                    <span className="input-label">{text[19]}</span>
                                    <Select options={this.props.selectTarifOptions} action={this.props.selectTarifAction}>{this.props.selectTarifValue}</Select>
                                </div>
                            </div>
                                </>
                            :null}
                        </div>
                        {!this.props.error ?
                            <>

                                <p className="finish-form">{text[21]}: <b>{this.props.tarifMoneyReal}</b></p>
                                <p className="finish-form">{text[22]}: <b>{this.props.tarifTimeReal}</b></p>
                                <div className="more-btn bottom">
                                    <button onClick={this.props.buttonClick} type="button" style={{width: '100%'}} className="btn btn-primary">{this.props.buttonName}</button>
                                </div>
                            </>
                            :
                            <p className="finish-form">{this.props.error}</p>
                        }

                    </div>
                </div>
            </div>;
        } else {





            return <div className="tariffs">

                <div className="tariff">

                    <div className="tariff-tasks__item">
                        <div className="top-item">
                            <span className="title-top">{this.props.account > 3 ? <>{text[53]} {this.props.account} {text[54]}</>:<>{text[55]}</>}</span>
                        </div>



                        <div className="cnt-item">
                            <div className="tariff-posts__progress">
                                <div className="top-progress">
                                    <span>{text[56]}</span>
                                    <b>{this.props.accountActive} {text[57]} {this.props.account}</b>
                                </div>
                                <div className="line-progress">
                                    <div className="inner-line" style={{width: percent+'%'}}></div>
                                </div>
                            </div>
                            <p className="help-posts">{this.props.time ? <><b>{text[13]}</b> {this.props.time}</>:<b>{text[14]}</b>}</p>
                            <p className="help-posts">{text[15](this.props.timeOstatokInDey)}</p>
                        </div>
                        {this.props.account > 3 && this.props.time ?
                            <div className="form-item">

                                <button onClick={this.props.openRateRed} type="button" className="btn btn-primary btn-primary-button-m" style={{width: '100%'}}>{this.props.openRate ? text[66]:text[67]}</button>
                                {this.props.openRate ?
                                    <>
                                        <div className="tariff-form__inputs row red-rate">
                                            <div className="col">
                                                <div className="form-group">
                                                    <div className="input-count">
                                                        <span onClick={this.props.formAccountMinus} style={{lineHeight: '30px'}} className="btn-count minus unselectable">-</span>
                                                        <Input style={this.props.formAccountRed ? {border: '1px solid #ea1818', height: 44}:{height: 44}} onChange={this.props.formChange}>{this.props.formAccount}</Input>
                                                        <span onClick={this.props.formAccountPlas} style={{lineHeight: '30px'}} className="btn-count plus unselectable">+</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <div className="input-count">
                                                        <button onClick={this.props.buttonClickRed} type="button" className="btn btn-primary" style={{width: '100%'}}>{text[58]}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {this.props.errorRedRate === 0 ? <>
                                            <div style={{marginBottom: '10px'}}>
                                                {this.props.account != this.props.formAccount ?
                                                    <>
                                                        <p className="finish-form">{text[59]} <b>{this.props.tarifTime}</b></p>
                                                        <p className="finish-form">{text[60]} <b>{this.props.tarifMoney} {text[61]}</b></p>
                                                    </>
                                                    :null}
                                            </div>
                                            <span className="input-label">{text[62]}</span>
                                        </>:<>
                                            <span className="input-label" style={{color: '#ea1818'}}>{text[63]} {this.props.errorRedRate === 1 ? text[64]:text[65]}</span>
                                        </>}

                                    </>
                                    :null}
                            </div>
                            :null}
                    </div>


                    <div className="tariff-form">
                        <span className="title-form">{this.props.time && this.props.account > 5 ? text[17] : text[52] }</span>
                        <div className="tariff-form__inputs row">
                            {this.props.tarifMoney !== 0 ?
                                <>
                                    {this.props.account < 5 || !this.props.time ?
                                        <div className="col" style={{width: '100%'}}>
                                            <div className="form-group">
                                                <span className="input-label">{text[18]}</span>
                                                <div className="input-count">
                                                    <span onClick={this.props.formAccountMinus} style={{lineHeight: '30px'}} className="btn-count minus unselectable">-</span>
                                                    <Input style={this.props.formAccountRed ? {border: '1px solid #ea1818', height: 44}:{height: 44}} onChange={this.props.formChange}>{this.props.formAccount}</Input>
                                                    <span onClick={this.props.formAccountPlas} style={{lineHeight: '30px'}} className="btn-count plus unselectable">+</span>
                                                </div>
                                            </div>
                                        </div>
                                        :null}
                                    <div className="col" style={{width: this.props.account < 5 || !this.props.time ? '100%' : '100%'}}>
                                        <div className="form-group">
                                            <span className="input-label">{text[19]}</span>
                                            <Select options={this.props.selectTarifOptions} action={this.props.selectTarifAction}>{this.props.selectTarifValue}</Select>
                                        </div>
                                    </div>
                                </>
                                :null}
                        </div>
                        {!this.props.error ?
                            <>

                                <p className="finish-form">{text[21]}: <b>{this.props.tarifMoneyReal}</b></p>
                                <p className="finish-form">{text[22]}: <b>{this.props.tarifTimeReal}</b></p>
                                <div className="more-btn bottom">
                                    {/*<button onClick={this.props.buttonClick} type="button" style={{width: '100%'}} className="btn btn-primary">{this.props.buttonName}</button>*/}
                                    {buttonPay}
                                </div>
                            </>
                            :
                            <p className="finish-form">{this.props.error}</p>
                        }

                    </div>
                </div>
            </div>;
        }

    }

    retRateFun = () => {
        this.retRate = !this.retRate;
    }
}

