import qs from "qs";
import React from "react";
import instance from "./base";


const APIchat = {

    sending_media (file) {
        let formData = new FormData();
        formData.append('file', file);

        return instance.post( 'chat/sending_media',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }
        );
    },
}

export default APIchat;