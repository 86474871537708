import APIproxy from "../../api/proxy";
import store from "../../stor/mainStore";

export function getProxy(id, idAccountRedax, hash) {

    const accountsStore = store.accountsStore;

    accountsStore.getProxyLoad(idAccountRedax);

    APIproxy.getProxy(id, hash).then((data) => {

        accountsStore.getProxy(idAccountRedax, data.hesh, data.proxy);

    }).catch((error) => {

    });
}
