import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import insertTextAtCursor from "insert-text-at-cursor";
import {Picker} from "emoji-mart";
import imgEmoje from "../../media/svg/emojeText.svg";
import ReactDOM from "react-dom";

export default
@inject('initialStore')
@observer
class Input extends React.Component {

    @observable emojeOpen = false;

    constructor(props) {
        super(props);

        this.buttonOpenEmoje = React.createRef();
        this.PickerRef = React.createRef();

        this.textarea = React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.openWin = false;
    }
    render() {
        const initialStore = this.props.initialStore;

        let EmojeBox;

        if (this.props.emoje) {

            if (!this.emojeOpen) {
                if (this.openWin) {
                    this.openWin=false;

                    this.updateStatusCloseFon();
                }
            } else {
                if (!this.openWin) {
                    this.openWin=true;
                    this.updateStatusCloseFon();
                }
            }
        }

        let disabled = false;


        if (initialStore.templates === 'desktop') {

            if (this.emojeOpen) {
                EmojeBox = <Picker
                    set='apple'
                    color="#7b5c9b"
                    showSkinTones={false}
                    showPreview={false}
                    i18n={initialStore.text.emoji}
                    onSelect={(emoji) => {this.pickerOnSelect(emoji.native)}}
                    ref={this.PickerRef}
                />;
            }


            let classInput = 'form-control';
            if (this.props.className) {
                classInput = this.props.className;
            }
            if (this.props.emoje) {
                classInput+=' form-control-emoje';
            }

            if (this.props.load) {
                classInput+=" btn-primary-animate-content";
                disabled = true;
            }

            let input = <input
                    type={this.props.type ? this.props.type:'value'}
                    style={this.props.style}
                    placeholder={this.props.placeholder}
                    className={classInput}
                    onChange={(event => this.props.onChange(event.target.value))}
                    value={this.props.children}
                    ref={this.textarea}
                    disabled={disabled}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                />;

            if (this.props.emoje) {
                input = <div className="inputDefoult">
                            {input}
                            {this.props.emoje ?
                                <div className="text-box-emoje-input">
                                    <div className="text-box-emoje-box">
                                        {EmojeBox}
                                    </div>
                                    <div onClick={() => {
                                        if (this.emojeOpen) {
                                            this.emojeOpen = false;
                                        } else {
                                            this.emojeOpen = true;
                                        }
                                    }
                                    } ref={el=>this.buttonOpenEmoje=el}><img src={imgEmoje}/></div>
                                </div>
                                :null}
                        </div>;
            }

            return input;
        } else {

            let classInput = 'form-control';
            if (this.props.className) {
                classInput = this.props.className;
            }

            if (this.props.load) {
                classInput+=" btn-primary-animate-content";
                disabled = true;
            }

            return <input
                type={this.props.type ? this.props.type:'value'}
                placeholder={this.props.placeholder}
                className={classInput}
                onChange={(event => this.props.onChange(event.target.value))}
                value={this.props.children}
                disabled={disabled}
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
            />;
        }

    }

    updateStatusCloseFon = () => {
        if (this.openWin) {

            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    //вставляет текст в место где был ранее курсор
    pickerOnSelect (text, offset) {

        const el = this.textarea.current;

        insertTextAtCursor(el, text);
    }

    // Вызывается после удаления компонента из DOM
    componentWillUnmount() {
        //смайлы
        document.removeEventListener('click', this.handleClickOutside, false);
    }

    // Отлавливаем клик на любую область
    handleClickOutside(e) {
        console.log(24);
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.PickerRef.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов
                this.emojeOpen = false;
            }
        } catch(error) {
            return null
        }
    }

}

