import React from 'react';
import {inject, observer} from "mobx-react";

import {Spring} from 'react-spring/renderprops';

import Editor from "./Editor";

import 'react-day-picker/lib/style.css';

import 'moment/locale/ru';

import APIposts from "../../api/posts";

import './emoji-mart.css';
import fileClass  from "../../model/file";
import mainStore from "../../stor/mainStore";
import Time from "../../model/time";

import file from "../../model/file";

import PostTemplate from "../../templates/post/post";
import TextModel from "../../model/text";
import loadFileObrabotchik from "../../model/loadFileObrabotchik";
import Fabric from "../../model/fabric";
import iconInst from "../../media/img/socialNetworks/inst.svg";
import iconVK from "../../media/img/socialNetworks/vk.svg";
import iconFB from "../../media/img/socialNetworks/fb.svg";
import iconOK from "../../media/img/socialNetworks/ok.svg";
import iconTG from "../../media/img/socialNetworks/tg.svg";
import iconTwitter from "../../media/img/socialNetworks/twitter.svg";
import iconInstFB from "../../media/img/socialNetworks/inst.fb.svg";


import initialStore from "../../stor/initialStore";
import PostTemplateMulty from "../../templates/post/postMulty";



@inject('editorStore', 'libraryStore', 'initialStore', 'mediaStore', 'accountsStore', 'notificationStore', 'initialStore')
@observer
class PostMulty extends React.Component {

    constructor (props) {
        super(props);

        this.closePost = this.closePost.bind(this);
        this.closePostDel = this.closePostDel.bind(this);
        this.loadPost = this.loadPost.bind(this);

        this.timeProClick = this.timeProClick.bind(this);
        this.textProClick = this.textProClick.bind(this);
        this.onClickCommentOff = this.onClickCommentOff.bind(this);
        this.onClickComment = this.onClickComment.bind(this);
        this.onClickGeo = this.onClickGeo.bind(this);
    }



    actionNameValue = (value) => {
        const mediaStore = this.props.mediaStore;
        mediaStore.name = value;
    }

    render() {

        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;
        const initialStore = this.props.initialStore;

        const idAccount = accountsStore.active;

        const accountsOK = mediaStore.accounts.slice();
        const accounts = accountsStore.list.slice();

        if (!mediaStore.open) {

        }

        if (mediaStore.type !== 'multyPost' && mediaStore.type !== 'multyStory') {
            return null;
        }


        let EditorModal = null;
        if (mediaStore.editorOpen !== false) {
            EditorModal = <Editor/>;
        }
        let stileBox = {
            width: "800px",
            height: "1000px",
            position: "absolution",
        };

        let nameBox = 'Создание нескольких постов';
        let buttonOkName = 'createPosts';

        if (mediaStore.type === 'multyStory') {
            nameBox = 'Создание нескольких историй';
            buttonOkName = 'createStorys';
        }




        //надо показывать или нет коментарий и гео
        const socSetEnter = {
            instagram: 0,
            vk: 0,
            fb: 0,
            ok: 0,
            tg: 0,
            twitter: 0,
            fb_instagram: 0,
        };
        for (const accountsOKOne of accountsOK) {
            for (let keys in accounts) {
                if (accounts[keys].id === accountsOKOne.id) {
                    if (accounts[keys].tip === 'fb') {
                        socSetEnter.fb++;
                    } else if (accounts[keys].tip === 'tg') {
                        socSetEnter.tg++;
                    } else if (accounts[keys].tip === 'ok') {
                        socSetEnter.ok++;
                    } else if (accounts[keys].tip === 'vk') {
                        socSetEnter.vk++;
                    } else if (accounts[keys].tip === 'instagram') {
                        socSetEnter.instagram++;
                    } else if (accounts[keys].tip === 'twitter') {
                        socSetEnter.twitter++;
                    } else if (accounts[keys].tip === 'fb-instagram') {
                        socSetEnter.fb_instagram++;
                    }
                }
            }
        }
        let viewComentAndGeo = false;

        if (socSetEnter.instagram) {
            if (socSetEnter.vk || socSetEnter.fb || socSetEnter.ok || socSetEnter.tg || socSetEnter.twitter || socSetEnter.fb_instagram) {
                viewComentAndGeo = 2;
            } else {
                viewComentAndGeo = 1;
            }
        } else {
            if (!socSetEnter.instagram && !socSetEnter.vk && !socSetEnter.fb && !socSetEnter.ok && !socSetEnter.tg && !socSetEnter.twitter && !socSetEnter.fb_instagram) {
                viewComentAndGeo = 1;
            }
        }


        //информация о редактируемом аккаунте
        let infoRedAccount = false;

        if (mediaStore.type === 'edit' || mediaStore.type === 'editStory' || mediaStore.type === 'editIgtv') {

            for (let item of accounts) {
                for (let keys in accountsOK) {
                    if (accountsOK[keys].id === item.id) {
                        //иконка
                        let icon = '';
                        if (item.tip === 'instagram') icon = iconInst;
                        else if (item.tip === 'vk') icon = iconVK;
                        else if (item.tip === 'fb') icon = iconFB;
                        else if (item.tip === 'tg') icon = iconTG;
                        else if (item.tip === 'ok') icon = iconOK;
                        else if (item.tip === 'twitter') icon = iconTwitter;
                        else if (item.tip === 'fb-instagram') icon = iconInstFB;



                        infoRedAccount = {
                            icon: icon,
                            img: item.img,
                            name: item.name,
                        };
                        break;
                    }
                }
                if (infoRedAccount) break;
            }
        }

        let sortTime = false;
        if (mediaStore.multyPost.slice().length) {
            sortTime = true;
        }

        const props = {
            type: mediaStore.type,
            nameBox: nameBox,
            oneCommentOn: mediaStore.comment,
            oneCommentOnAction: this.onClickComment,
            commentOff: mediaStore.commentOff,
            commentOffAction: this.onClickCommentOff,
            textProAndTimeProStatus: mediaStore.accounts.length > 1 ? true : false,
            textPro: mediaStore.textPro,
            textProAction: this.textProClick,
            timePro: mediaStore.timePro,
            timeProAction: this.timeProClick,
            geo: mediaStore.geo,
            geoAction: this.onClickGeo,

            buttomCloseAction: this.closePostDel,

            buttomCollapseStatus: mediaStore.type === 'edit' ? false : true,
            buttomCollapseAction: this.closePost,

            buttonOkName: buttonOkName,
            buttonOkAction: (promise) => {
                // ?  : ,
                if (mediaStore.type === 'edit' || mediaStore.type === 'editStory' || mediaStore.type === 'editIgtv') {
                    this.editPost(promise);
                } else {
                    this.loadPost(promise);
                }
            },

            //файлы
            mediaLoadingStatus: mediaStore.mediaLoading,

            modalEditor: EditorModal,

            viewComentAndGeo: viewComentAndGeo,

            fileDrop: this.fileDrop,

            infoRedAccount: infoRedAccount,

            sortTime: sortTime,
        };

        let render = (
            <PostTemplateMulty
                {...props}
            />
        );

        return(<Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}>
            {props => <div className='createMobilePost' style={props}>
                {render}
            </div>
            }
        </Spring>);
    }

    fileDrop = (files, event) => {
        const mediaStore = this.props.mediaStore;
        const notificationStore = this.props.notificationStore;

        if (mediaStore.mediaLoading) {
            notificationStore.add('Ошибка', 'Для добавления файлов, загрузите файлы для редактирования');
            return true;
        }
        loadFileObrabotchik(files);
    }

    num2str(n, text_forms) {
        n = Math.abs(n) % 100; var n1 = n % 10;
        if (n > 10 && n < 20) { return text_forms[2]; }
        if (n1 > 1 && n1 < 5) { return text_forms[1]; }
        if (n1 == 1) { return text_forms[0]; }
        return text_forms[2];
    }

    componentDidMount() {

        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        if (mediaStore.accounts.length) {
            const idAccountActive = mediaStore.accounts[0].id;

            for (const key in accountsStore.list) {

                if (idAccountActive === accountsStore.list[key].id) {
                    accountsStore.updateActive(key);
                    break;
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        const idAccount = accountsStore.active;

        if (!mediaStore.open) {
            return null;
        }

        if (idAccount === null && mediaStore.accounts.slice().length) {

            for (const accountID in accountsStore.list.slice()) {
                if (accountsStore.list[accountID].id === mediaStore.accounts[0].id) {
                    //accountsStore.active = accountID;
                    break;
                }
            }
        }
    }

    onClickGeo () {
        const mediaStore = this.props.mediaStore;

        if (mediaStore.geo) {
            mediaStore.actionGeo(false);
        } else {
            mediaStore.actionGeo(true);
        }
    }

    onClickCommentOff () {
        const mediaStore = this.props.mediaStore;

        if (mediaStore.commentOff) {
            mediaStore.actionCommentOff(false);
        } else {
            mediaStore.actionCommentText('');
            mediaStore.actionComment(false);
            mediaStore.actionCommentOff(true);
        }
    }

    onClickComment () {
        const mediaStore = this.props.mediaStore;
        const notificationStore = this.props.notificationStore;

        if (mediaStore.commentOff) {
            notificationStore.add('Ошибка', 'Для создания первого комментария, требуется включить комментарии');
            return false;
        }

        if (mediaStore.comment) {
            mediaStore.actionCommentText('');
            mediaStore.actionComment(false);
        } else {
            mediaStore.actionComment(true);
        }
    }

    textProClick () {
        const mediaStore = this.props.mediaStore;

        if (mediaStore.textPro) {

            const accountsOK = mediaStore.accounts.slice();
            if (accountsOK.length) {
                for (let key in accountsOK) {
                    this.props.mediaStore.actionAccountUpdate(accountsOK[key].id, {text: accountsOK[0].text});
                }
            }

            mediaStore.actionTextPro(false);
        } else {
            mediaStore.actionTextPro(true);
        }
    }

    timeProClick () {
        const mediaStore = this.props.mediaStore;
        const accountsStore = this.props.accountsStore;

        const accounts = accountsStore.list.slice();

        if (mediaStore.timePro) {

            const accountsOK = mediaStore.accounts.slice();
            if (accountsOK.length) {

                if (accountsOK[0].del.status === 2) {
                    const socSetEnter = {
                        dr: 0,
                    };
                    for (const accountsOKOne of accountsOK) {
                        for (let keys in accounts) {
                            if (accounts[keys].id === accountsOKOne.id) {
                                if (accounts[keys].tip === 'fb') {
                                    socSetEnter.dr++;
                                } else if (accounts[keys].tip === 'tg') {
                                    socSetEnter.dr++;
                                } else if (accounts[keys].tip === 'ok') {
                                    socSetEnter.dr++;
                                } else if (accounts[keys].tip === 'vk') {
                                    socSetEnter.dr++;
                                } else if (accounts[keys].tip === 'twitter') {
                                    socSetEnter.dr++;
                                } else if (accounts[keys].tip === 'fb-instagram') {
                                    socSetEnter.dr++;
                                }
                            }
                        }
                    }

                    if (socSetEnter.dr) {
                        accountsOK[0].del.status = 1;
                    }
                }

                for (let key in accountsOK) {
                    this.props.mediaStore.actionAccountUpdate(accountsOK[key].id, {time: accountsOK[0].time, del: accountsOK[0].del});
                }
            }

            mediaStore.actionTimePro(false);
        } else {

            const accountsOK = mediaStore.accounts.slice();
            if (accountsOK.length) {
                for (let key in accountsOK) {
                    let typeTimetable = 'post';

                    if (mediaStore.type === 'story' || mediaStore.type === 'editStory') {
                        typeTimetable = 'story';
                    }

                    if (Time.TimetableStatus(accountsOK[key].id, typeTimetable)) {
                        const time = Time.dateTimetableGenerator(accountsOK[key].id, 'post')[0];
                        this.props.mediaStore.actionAccountUpdate(accountsOK[key].id, {time: time, del: accountsOK[0].del});
                    } else {
                        this.props.mediaStore.actionAccountUpdate(accountsOK[key].id, {time: accountsOK[0].time, del: accountsOK[0].del});
                    }

                }
            }

            mediaStore.actionTimePro(true);
        }
    }

    loadPost =  async (resolve) => {

        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;

        const type = mediaStore.type;

        const accountsOK = mediaStore.accounts.slice();
        const accounts = accountsStore.list.slice();

        if (mediaStore.media.length === 0) {
            notificationStore.add('Ошибка', 'Добавьте минимум 1 медиа файл.');
            resolve(true);
            return;
        }

        initialStore.infoButtonLoaf = ' (0/' + mediaStore.multyPost.length + ')';


        const minTime = (new Date() / 1000) - 50;
        for (const account of mediaStore.multyPost.slice()) {

            let yesPost = false;
            for (const medias of mediaStore.media.slice()) {
                if (medias.id === account.id) {
                    yesPost = true;
                }
            }

            if (!yesPost) {
                continue;
            }

            if (account.time < minTime) {
                notificationStore.add('Ошибка', 'Измените время публикации, данное время уже прошло.');
                initialStore.infoButtonLoaf = null;
                resolve(true);
                return;
            }


            //Узнаем какие соц сети есть и показываем лимиты на текст
            let InstagramTrue = false;
            let VKTrue = false;
            let FBTrue = false;
            let OKTrue = false;
            let TGTrue = false;
            let TwitterTrue = false;
            let FbInstagramTrue = false;

            for (let keys in accounts) {
                if (accounts[keys].id === account.id) {
                    if (accounts[keys].tip === 'fb')                FBTrue = true;
                    else if (accounts[keys].tip === 'tg')           TGTrue = true;
                    else if (accounts[keys].tip === 'ok')           OKTrue = true;
                    else if (accounts[keys].tip === 'vk')           VKTrue = true;
                    else if (accounts[keys].tip === 'instagram')    InstagramTrue = true;
                    else if (accounts[keys].tip === 'twitter')    TwitterTrue = true;
                    else if (accounts[keys].tip === 'fb-instagram')    FbInstagramTrue = true;

                    //проверяем время
                    if (account.time > accounts[keys].rights.rate) {
                        notificationStore.add('Ошибка', 'У аккаунта ' + accounts[keys].name + ' в выбранное время будет не активен тариф. Продлите тариф или измените время публикации.');
                        initialStore.infoButtonLoaf = null;
                        resolve(true);
                        return;
                    }
                }
            }

            const error = TextModel.limitedText(account.text, account.text, InstagramTrue, VKTrue, FBTrue, OKTrue, TGTrue, TwitterTrue, FbInstagramTrue).status;

            if (!error) {
                notificationStore.add('Ошибка', 'Привышены лимиты в описании по символам, хэштегам или упоминаниям');
                initialStore.infoButtonLoaf = null;
                resolve(true);
                return;
            }
        }

        let mediaLoad = false;
        let file = false;

        if (mediaStore.media.length > 0) {
            mediaLoad = [];
            file = {
                elementsImage: [],
                elementsVideo: [],
            };



            for (const element of mediaStore.media) {

                if (element.loadPercent !== true) {
                    notificationStore.add('Ошибка', 'Не все файлы еще загружены на сервер');
                    initialStore.infoButtonLoaf = null;
                    resolve(true);
                    return;
                }

                let duration = {...element.duration};

                delete(duration.icons);
                delete(duration.iconsCover);


                let elementMedia = {...element.elements};

                let icon = false;

                if (mediaStore.accounts.slice().length === 1) {
                    for (let element of accounts) {
                        if (element.id === mediaStore.accounts[0].id) {
                            icon = element.img;
                            break;
                        }
                    }
                }

                if (elementMedia.image.media) {
                    const newCanvas = document.createElement("canvas");
                    await Fabric.start(newCanvas, 400, Math.ceil(400 / element.proportionsBackground), elementMedia.image, () => {}, () => {}, () => {}, icon, false);
                    const image = await Fabric.createImg();
                    const blob = await fileClass.createBlobPre(image);

                    file.elementsImage[element.id] = blob.blob;
                }

                if (elementMedia.video.media) {
                    const newCanvas = document.createElement("canvas");
                    await Fabric.start(newCanvas, 400, Math.ceil(400 / element.proportionsBackground), elementMedia.video, () => {}, () => {}, () => {}, icon, false);
                    const image = await Fabric.createImg();
                    const blob = await fileClass.createBlobPre(image);

                    file.elementsVideo[element.id] = blob.blob;
                } else {
                    if (elementMedia.image.gif) {
                        if (element.type === 'image' && elementMedia.image.gif.length) {

                            const newCanvas = document.createElement("canvas");
                            await Fabric.start(newCanvas, 400, Math.ceil(400 / element.proportionsBackground), elementMedia.image, () => {}, () => {}, () => {}, icon, false);
                            const image = await Fabric.createImg();
                            const blob = await fileClass.createBlobPre(image);

                            file.elementsVideo[element.id] = blob.blob;
                        }
                    }
                }





                mediaLoad[mediaLoad.length] = {
                    id: element.id,
                    type: element.type,
                    proportions: element.proportions,
                    proportionsBackground: element.proportionsBackground,
                    zoom: element.zoom,
                    crop: element.crop,
                    draggable: element.draggable,
                    duration: duration,
                    color: element.color,
                    accounts: element.accounts,
                    elements: elementMedia,
                    soundDelite: element.soundDelite,
                }
            }

        }


        let geo = false;

        if (mediaStore.geoObject.id!== false && mediaStore.geo) {
            geo = mediaStore.geoObject.array[mediaStore.geoObject.id];
        }

        let numPost = 1;

        initialStore.infoButtonLoaf = ' (0/'+mediaStore.multyPost.length+')';

        for (const element of mediaLoad) {

            let infoPost = false;
            for (const medias of mediaStore.multyPost.slice()) {
                if (medias.id === element.id) {
                    infoPost = medias;
                }
            }

            let fileMulty = {
                elementsImage: [],
                elementsVideo: [],
            };

            for (const elementsImageID in file.elementsImage) {
                if (Number(elementsImageID) === element.id) {
                    fileMulty.elementsImage[element.id] = file.elementsImage[element.id];
                    break;
                }
            }

            for (const elementsVideoID in file.elementsVideo) {
                if (Number(elementsVideoID) === element.id) {
                    fileMulty.elementsVideo[element.id] = file.elementsVideo[element.id];
                    break;
                }
            }

            let accountMulty = [];

            for (let accountsElement of mediaStore.accounts.slice()) {
                accountMulty.push({
                    del: infoPost.del,
                    id: accountsElement.id,
                    text: infoPost.text,
                    time: infoPost.time,
                });
            }

            const json = {
                media: [element],
                idLoad: infoPost.loadFileId,
                accounts: accountMulty,
                text: infoPost.text,
                name: mediaStore.name,
                commentOff: mediaStore.commentOff,
                commentText: mediaStore.commentText,
                geo: geo,
                category: mediaStore.category,
                type: mediaStore.type === 'multyPost' ? 'post':'story',
                urlStory: infoPost.urlStory,
            };

            let infoPostJson = await APIposts.setPost2(json, fileMulty);

            if (!infoPostJson.status) {
                if (infoPostJson.code) {
                    notificationStore.add('Ошибка Пост №' + numPost, initialStore.text.code[infoPostJson.code]);
                } else {
                    notificationStore.add('Ошибка Пост №' + numPost, 'Ошибка соединения с интернетом, повторите попытку как остальные посты загрузятся');
                }

            } else {
                //notificationStore.add('Пост №' + numPost + ' создан', '', 2);
            }

            initialStore.infoButtonLoaf = ' (' + numPost + '/'+mediaStore.multyPost.length+')';

            numPost++;
        }

        initialStore.infoButtonLoaf = null;

        const idAccount = accountsStore.active;

        if (idAccount) {
            const idAccountReal = accountsStore.list[idAccount].id;
            const idAccountRealHashPost = accountsStore.list[idAccount].post.hash;
            const idAccountRealHashStory = accountsStore.list[idAccount].story.hash;

            accountsStore.getPostLoad(idAccount);
            accountsStore.getStoryLoad(idAccount);

            APIposts.getPosts(idAccountReal, idAccountRealHashPost, idAccountRealHashStory).then((data) => {

                accountsStore.getPost(idAccount, data.hashPosts, data.posts, data.rate);
                accountsStore.getStory(idAccount, data.hashStory, data.story);

                this.closePostDel();
                //
                if (mediaStore.type === 'multyPost') {
                    notificationStore.add('', 'Посты созданы');
                } else {
                    notificationStore.add('', 'Истории созданы');
                }
                initialStore.infoButtonLoaf = null;
                resolve(true);

            }).catch((error) => {
                this.closePostDel();
                if (mediaStore.type === 'multyPost') {
                    notificationStore.add('Ошибка', 'Посты успешно созданы, но не удалось обновить ленту, перезагрузите страницу.');
                } else {
                    notificationStore.add('Ошибка', 'Истории успешно созданы, но не удалось обновить ленту, перезагрузите страницу.');
                }
                initialStore.infoButtonLoaf = null;
                resolve(true);
            });
        } else {
            const libraryStore = this.props.libraryStore;

            APIposts.getLibrary(libraryStore.hash).then((dataLibrary) => {
                //сохраняем посты
                mainStore.libraryStore.getPost(dataLibrary.hashPosts, dataLibrary.posts);

                mainStore.libraryStore.getCategories(dataLibrary.categories);

                this.closePostDel();
                if (mediaStore.type === 'multyPost') {
                    notificationStore.add('', 'Посты созданы');
                } else {
                    notificationStore.add('', 'Истории созданы');
                }
                initialStore.infoButtonLoaf = null;
                resolve(true);
            }).catch((error) => {
                this.closePostDel();

                if (mediaStore.type === 'multyPost') {
                    notificationStore.add('Ошибка', 'Посты успешно созданы, но не удалось обновить ленту, перезагрузите страницу.');
                } else {
                    notificationStore.add('Ошибка', 'Истории успешно созданы, но не удалось обновить ленту, перезагрузите страницу.');
                }
                initialStore.infoButtonLoaf = null;
                resolve(true);
            });
        }
    }

    closePost () {
        this.props.mediaStore.actionOpen(false, true);
    }

    closePostDel (props = false) {

        for (let element of this.props.mediaStore.media.slice()) {
            file.deliteURLFile(element.url);
        }

        this.props.mediaStore.actionDefoult();

        if (props) {
            props(true);
        }

    }

}

export default PostMulty;
