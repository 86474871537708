import qs from "qs";
import React from "react";

import instance from "./base";


const APIemployees = {

    getEmployees (account, hash) {
        return instance.post('employees', qs.stringify({
            account: account,
            hesh:hash,
        })).then(data => {
            return data;
        });
    },
    create (account, name, email, moderation, view_post, editing_post, history, statistics, url, direct) {
        return instance.post('employees/create', qs.stringify({
            account: account,
            name: name,
            email:email,
            moderation:moderation,
            view_post:view_post,
            editing_post:editing_post,
            history:history,
            statistics:statistics,
            url:url,
            direct:direct,
        })).then(data => {
            return data;
        });
    },
    red (id, name, moderation, view_post, editing_post, history, statistics, url, direct) {
        return instance.post('employees/red', qs.stringify({
            id: id,
            name: name,
            moderation:moderation,
            view_post:view_post,
            editing_post:editing_post,
            history:history,
            statistics:statistics,
            url:url,
            direct:direct,
        })).then(data => {
            return data;
        });
    },
    del (id) {
        return instance.post('employees/del', qs.stringify({
            id: id,
        })).then(data => {
            return data;
        });
    },



}

export default APIemployees;