import APIemployees from "../../api/employees";
import store from "../../stor/mainStore";

export function getEmployees(id, idAccountRedax, hash) {

    const accountsStore = store.accountsStore;

    accountsStore.getEmployeesLoad(idAccountRedax)

    APIemployees.getEmployees(id, hash).then((data) => {

        accountsStore.getEmployees(idAccountRedax, data.hesh, data.employees);

    }).catch((error) => {

    });
}
