import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

import MaskedInput from 'react-text-mask'

import Timeonline from "./components/timepnline";
import Time from "../../model/time";
import APIsettings from "../../api/settings";

import file from "../../model/file";
import SettingsMain from "../../templates/settings/main";

@inject('initialStore', 'notificationStore')
@observer
class Main extends React.Component {

    @observable timeValue = this.props.initialStore.timezone;
    @observable languageValue = this.props.initialStore.language;

    @observable phone = this.props.initialStore.phone;
    @observable phoneCode = this.props.initialStore.phoneCode;
    @observable name = this.props.initialStore.name;
    @observable saveStatusButton = true;

    @observable telegramCode = false;
    @observable telegramButton = false;
    @observable telegramDeliteButton = false;

    constructor (props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.languageChange = this.languageChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.phoneCodeChange = this.phoneCodeChange.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.saveClick = this.saveClick.bind(this);

        this.redImage = this.redImage.bind(this);
        this.filOnChange = this.filOnChange.bind(this);
        this.telegramClick = this.telegramClick.bind(this);
        this.telegramDeliteClick = this.telegramDeliteClick.bind(this);


        this.fileInput = React.createRef();
    }



    render() {

        const initialStore = this.props.initialStore;
        const text = initialStore.text.settings;

        const timeZoneArray = this.props.initialStore.timeZoneArray;


        let timeElements = [
            {key: '', value:text.automatic}
        ];
        for (const element of timeZoneArray) {
            timeElements.push({key: element, value:element});
        }

        let save = (e) => {this.saveClick(e)};

        if (this.timeValue !== this.props.initialStore.timezone ||
            this.languageValue !== this.props.initialStore.language ||
            this.phone !== this.props.initialStore.phone ||
            this.phoneCode !== this.props.initialStore.phoneCode ||
            this.name !== this.props.initialStore.name
        ) {
            save = (e) => {this.saveClick(e)};
        }

        let telegramButtonStatus = true;
        let telegramButtonAction = this.telegramClick;

        if (this.props.initialStore.telegram) {
            if (this.telegramDeliteButton) {
                telegramButtonStatus = false;
                telegramButtonAction = null;
            } else {
                telegramButtonAction = this.telegramDeliteClick;
            }

        } else if (this.telegramCode) {

        } else if (this.telegramButton) {
            telegramButtonStatus = false;
            telegramButtonAction = null;
        }



        const languageOption = [
            {key: 'en', value: '🇺🇸 English'},
            {key: 'ru', value: '🇷🇺 Русский'},
        ];

        const telegramStatus = this.props.initialStore.telegram;

        return(
            <SettingsMain
                avatarRed={this.redImage}
                avatarUrl={this.props.initialStore.photo ? 'https://likepro.ru' + this.props.initialStore.photo:'https://likepro.ru/user/1.png'}
                avatarInputRef={this.fileInput}
                avatarInputChange={this.filOnChange}

                nameValue={this.name}
                nameChange={this.nameChange}

                timeValue={this.timeValue}
                timeOption={timeElements}
                timeChange={this.handleChange}

                languageValue={this.languageValue}
                languageChange={this.languageChange}
                languageOption={languageOption}

                telegramNameAccount={telegramStatus}
                telegramCode={this.telegramCode}
                telegramButtonStatus={telegramButtonStatus}
                telegramButtonAction={telegramButtonAction}

                email={this.props.initialStore.mail}

                phoneValue={this.phone}
                phoneChange={this.phoneChange}


                save={save}
            />
        );
    }

    telegramClick() {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        this.telegramButton = true;
        APIsettings.setTelegram().then((data) => {
            if (!data.status) {

                notificationStore.add(text[80]);
            } else {
                if (data.telegram) {
                    this.props.initialStore.telegram =  data.telegram;
                } else {
                    this.telegramCode = data.telegram_code;
                }
            }
            this.telegramButton = false;
        }).catch((e) => {
            notificationStore.add(text[80]);
            this.telegramButton = false;
        });
    }

    telegramDeliteClick() {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        this.telegramDeliteButton = true;
        APIsettings.setTelegramDelite().then((data) => {
            if (!data.status) {
                notificationStore.add(text[80]);
            } else {
                if (data.telegram) {
                    this.props.initialStore.telegram =  data.telegram;
                } else {
                    this.props.initialStore.telegram =  data.telegram;
                    this.telegramCode = data.telegram_code;
                }

            }
            this.telegramDeliteButton = false;
        }).catch((e) => {
            notificationStore.add(text[80]);
            this.telegramDeliteButton = false;
        });
    }

    filOnChange() {
        this.handleFiles(this.fileInput.current.files);
    }

    redImage () {
        //Удаляем прошлые файлы из формы
        this.fileInput.current.value = null;
        //открываем форму
        this.fileInput.current.click();
    }

    handleFiles = async (files) => {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        if (!files.length) return false;

        let filesNew = await file.FileMaxSizeAndTypeAndMaxFiles(files,1);

        const url = await file.createURL(filesNew[0]);
        const blob = await file.createBlobPre(url);

        APIsettings.photoLoad(blob).then((data)=> {
            if (data.status) {
                this.props.initialStore.photo = data.photo;
            } else {
                alert(text[81]);
            }
        }).catch((e)=> {
            alert(text[81]);
        });
    }

    saveClick (resolve) {

        const notificationStore = this.props.notificationStore;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;
        const textSettings = initialStore.text.settings;

        if (String(this.phone).length < 9 && String(this.phone).length > 0 && String(this.phone) !== '0') {
            notificationStore.add(text[82]);
            resolve();
            return false;
        }

        if (this.name.length < 2 && this.name.length > 0) {
            notificationStore.add(text[83]);
            resolve();
            return false;
        }

        APIsettings.setSave(this.timeValue, this.languageValue, this.phone, this.phoneCode, this.name).then((data) => {

            if (data.status) {
                 this.props.initialStore.timezone = this.timeValue;
                this.props.initialStore.language = this.languageValue;
                this.props.initialStore.phone = this.phone;
                this.props.initialStore.phoneCode = this.phoneCode;
                this.props.initialStore.name = this.name;

                this.props.initialStore.updateLanguage(this.languageValue);

                Time.initTimeZone(this.timeValue);
                notificationStore.add(textSettings.changesSaved);
            } else {
                notificationStore.add(text[84]);
            }
        }).catch((e) => {
            notificationStore.add(text[84]);
        }).finally(() => {
            resolve();
        });

    }

    nameChange (name) {
        this.name = name;
    }

    phoneCodeChange (phoneCode) {

        if (this.phoneCode !== phoneCode) {
            this.phone = '';
        }

        this.phoneCode = phoneCode;
    }

    phoneChange (event) {
        let phone = event.target.value;

        if (phone !== '') {
            phone = parseInt(phone.replace(/\D+/g,""));
        }

        if (isNaN(phone)) {
            phone = '';
        }

        this.phone = phone;
    }
    handleChange (timeZone) {
        this.timeValue = timeZone;
    }

    languageChange (value) {
        this.languageValue = value;
    }

}

export default Main;