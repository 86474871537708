import qs from "qs";
import React from "react";
import instance from "./base";


const APIposts = {

    getPosts (id, hashPost, hashStory) {

        return instance.post('posts', qs.stringify({
            id: id,
            hashPosts:hashPost,
            hashStory:hashStory,
        })).then((data) => {
            return data;
        });
    },

    getLibrary (hashPost) {

        return instance.post('posts/library', qs.stringify({
            hashPosts:hashPost,
        })).then((data) => {
            return data;
        });
    },

    getPostsHistory (id, start, end, type, list) {

        return instance.post('posts/history', qs.stringify({
            id: id,
            start: start,
            end: end,
            type: type,
            list: list,
        })).then((data) => {
            return data;
        });
    },

    setPost (json) {
        return instance.post('add/one', qs.stringify({
                data: JSON.stringify(json),
        })).then((data) => {
            return data;
        });

    },
    setPost2 (json, file) {

        var jsons = JSON.stringify(json);

        let formData = new FormData();
        formData.append('data', jsons);

        /*for (const key in file.cover) {
            formData.append('cover_' + key, file.cover[key]);
        }*/

        for (const key in file.coverIndividual) {
            formData.append('coverIndividual_' + key, file.coverIndividual[key]);
        }

        for (const key in file.elementsImage) {
            formData.append('elementsImage_' + key, file.elementsImage[key]);
        }

        for (const key in file.elementsVideo) {
            formData.append('elementsVideo_' + key, file.elementsVideo[key]);
        }

        return instance.post('add/one',
            formData
            ).then((data) => {
            return data;
        });
    },
    setEditor (json, file) {

        var jsons = JSON.stringify(json);

        let formData = new FormData();
        formData.append('data', jsons);

        for (const key in file.coverIndividual) {
            formData.append('coverIndividual_' + key, file.coverIndividual[key]);
        }

        for (const key in file.elementsImage) {
            formData.append('elementsImage_' + key, file.elementsImage[key]);
        }

        for (const key in file.elementsVideo) {
            formData.append('elementsVideo_' + key, file.elementsVideo[key]);
        }

        return instance.post('editor',
            formData
        ).then((data) => {
            return data;
        });
    },
    getPost (id, code) {
        return instance.post('posts/getpost', qs.stringify({
            id: id,
            code:code,
        })).then((data) => {
            return data;
        });
    },
    delite (id, code) {
        return instance.post('posts/delite', qs.stringify({
            id: id,
            code:code,
        })).then((data) => {
            return data;
        });
    },
    edit (id, code) {
        return instance.post('posts/edit', qs.stringify({
            id: id,
            code:code,
        })).then((data) => {
            return data;
        });
    },
    editLoadFile (id, code) {
        return instance.post('posts/loading_files', qs.stringify({
            id: id,
            code:code,
        })).then((data) => {
            return data;
        });
    },
    getInfoPost (id, code) {
        return instance.post('posts/getinfopost', qs.stringify({
            id: id,
            code:code,
        })).then((data) => {
            return data;
        });
    },
    getEditorMini (id, code, status, tip, int) {
        return instance.post('editor/mini', qs.stringify({
            id: id,
            code:code,
            status: status,
            tip: tip,
            int: int,
        })).then((data) => {
            return data;
        });
    },
}

export default APIposts;
