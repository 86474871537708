const ru = {
    proceed: ['Продолжить', 'Продолжить.', 'Продолжить..', 'Продолжить...', 'Продолжить..', 'Продолжить.'],
    createPost: ['Создать пост', 'оздать пост', 'здать пост', 'дать пост', 'ать пост', 'ть пост', 'ь пост', ' Пост', 'Пост'],
    story: 'Историю',
    igtv: 'IGTV',
    reels: 'Reels',
    multiplePosts: 'Несколько постов',
    multipleStory: 'Несколько историй',
    copyOfThePostOnTheLink: 'Копию поста по ссылке',
    insertLink: 'Вставьте ссылку',
    fromPostsLibrary: 'Из библиотеки постов',
    librarySearch: 'Поиск по библиотеке',
    showAll: 'Показать все',
}

const en = {
    proceed: ['Proceed', 'Proceed.', 'Proceed..', 'Proceed...', 'Proceed..', 'Proceed.'],
    createPost: ['Create post', 'reate post', 'eate post', 'ate post', 'ate post', 'te post', 'e post', ' Post', 'Post'],
    story: 'Story',
    igtv: 'IGTV',
    reels: 'Reels',
    multiplePosts: 'Multiple posts',
    multipleStory: 'Multiple stories',
    copyOfThePostOnTheLink: 'Copy post on link',
    insertLink: 'Insert link',
    fromPostsLibrary: 'From posts to the library',
    librarySearch: 'Library search',
    showAll: 'Show all',
}

const menuCreatePost = {
    ru: ru,
    en: en,
}

export default menuCreatePost;
