import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ReactDOM from "react-dom";

export default
@inject('initialStore')
@observer
class HeaderMobileName extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return null;
        } else {
            return <nav className="top-menu">
                <div className="top-menu__label top-menu__label_non_image event-menu-mobile-center"><span className="value-label">{this.props.name}</span></div>
            </nav>;
        }
    }
}

