import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import iconInst from "../../media/img/socialNetworks/inst.svg";
import iconVK from "../../media/img/socialNetworks/vk.svg";
import iconFB from "../../media/img/socialNetworks/fb.svg";
import iconOK from "../../media/img/socialNetworks/ok.svg";
import iconTG from "../../media/img/socialNetworks/tg.svg";
import iconTwitter from "../../media/img/socialNetworks/twitter.svg";
import iconFbInstagram from "../../media/img/socialNetworks/inst.fb.svg";


export default
@inject('initialStore')
@observer
class AccountInfoTop extends React.Component {

    render() {
        //const initialStore = this.props.initialStore;

        //иконка
        let icon = '';
        if (this.props.type === 'instagram') {
            icon = iconInst;
        } else if (this.props.type === 'vk') {
            icon = iconVK;
        } else if (this.props.type === 'fb') {
            icon = iconFB;
        } else if (this.props.type === 'ok') {
            icon = iconOK;
        } else if (this.props.type === 'tg') {
            icon = iconTG;
        } else if (this.props.type === 'twitter') {
            icon = iconTwitter;
        } else if (this.props.type === 'fb-instagram') {
            icon = iconFbInstagram;
        }


        return (
            <div className="chief__typical h1 info-type-account">
                <h1>
                    <div className="img-user">
                            <span className="social-user">
                                <img src={icon}/>
                            </span>
                        <span className="inner-img">
                                <img src={this.props.img}/>
                            </span>
                    </div>
                    <div className="info-type-account-name" style={{fontSize: '20px', color: '#3e3e42'}}>{this.props.name}</div>
                </h1>
            </div>
        );
    }
}

