import React from 'react';
import {inject, observer} from "mobx-react";

import style from "./style.module.css";
import Textbox from "../post/component/Textbox";
import APIpatterns from "../../api/patterns";
import {getPatterns} from "../../actions/account/patterns";
import {observable} from "mobx";
import ButtonLoad from "../../templates/elements/buttonLoad";
import Button from "../../templates/elements/button";
import Select from "../../templates/elements/select";
import Checkbox from "../../templates/elements/checkbox";


@inject('modalPatternsStore', 'accountsStore', 'notificationStore', 'initialStore')
@observer
class ModalPatterns extends React.Component{

    constructor (props) {
        super(props);

        this.close = this.close.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.textChange = this.textChange.bind(this);
        this.commonClick = this.commonClick.bind(this);
        this.create = this.create.bind(this);

        this.accountChange = this.accountChange.bind(this);
    }

    render() {

        const modalPatternsStore = this.props.modalPatternsStore;
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        if (!modalPatternsStore.open) {
            return null;
        }

        let name = text[74];
        if (modalPatternsStore.type !== 'create') {
            name = text[75];
        }

        let accountActive = modalPatternsStore.account;

        let listAccounts = [];
        for (const account of accountsStore.list) {
            listAccounts.push({key:account.id, value: account.name});
        }



        let content = <>
                <span className="title-box">{name}</span>

                <div className="form-box">
                    <div className="form-group">
                    <span className="input-label">{text[76]}</span>
                    <input placeholder="" className="form-control view-2" onChange={(e) =>{this.nameChange(e)}} value={modalPatternsStore.name}/>
                    </div>
                    <div className="form-group">
                        <span className="input-label">{text[77]}</span>
                        <Textbox text={modalPatternsStore.text} textOnChange={this.textChange}/>
                    </div>

                    {!modalPatternsStore.common ?
                    <div className="form-group">
                        <span className="input-label">{text[78]}</span>
                        <Select search options={listAccounts} action={this.accountChange}>{accountActive}</Select>
                    </div>
                    :null}

                    <div className="form-group">
                        <div className="form-check">
                            <span className="title-check">{text[79]}</span>
                            <Checkbox onChange={this.commonClick}>{modalPatternsStore.common}</Checkbox>
                        </div>
                    </div>

                </div>

                <div className="more-btn btns-box">
                    <ButtonLoad color action={(props) => this.create(props)}>{modalPatternsStore.type === 'create' ? 'createPatterns':'saveChanges'}</ButtonLoad>
                    <Button action={this.close} type="button" className="btn border btn-primary">cancellation</Button>
                </div>
            </>;


        const classElement = this.props.initialStore.width < 500 ? "info-wrap" : "info-wrap";

        let render = <>
                <div className={classElement}>
                    <div className="info-scroll">
                        <div className="info-box-wrap">
                            <div className="info-box chief width-2">
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-overlay"></div>
            </>;

        return render;
    }

    create (props) {
        const modalPatternsStore = this.props.modalPatternsStore;
        const notificationStore = this.props.notificationStore;

        const accountsStore = this.props.accountsStore;

        const initialStore = this.props.initialStore;
        const textI18 = initialStore.text.code;

        const name = modalPatternsStore.name.trim();
        const text = modalPatternsStore.text.trim();
        let account = modalPatternsStore.account;
        const id = modalPatternsStore.id;

        if (name === '' || text === '') {

            notificationStore.add(textI18[28], textI18[122]);
            props(true);
            return;
        }

        if (modalPatternsStore.common) {
            account = 0;
        }



        if (modalPatternsStore.type === 'create') {
            APIpatterns.getPatternsAdd(account, name, text).then((data) => {
                if (data.status) {

                    const load = accountsStore.patterns.load;
                    if (!load) {
                        getPatterns();
                    }
                    this.close();
                    notificationStore.add(textI18[125]);
                } else {
                    notificationStore.add(textI18[28], textI18[123]);
                }

            }).catch((e) => {
                notificationStore.add(textI18[28], textI18[123]);
            }).finally(() => {
                props(true);
            });
        } else {

            APIpatterns.getPatternsRed(id ,account, name, text).then((data) => {
                if (data.status) {

                    const load = accountsStore.patterns.load;
                    if (!load) {
                        getPatterns();
                    }
                    this.close();
                    notificationStore.add(textI18[126]);

                } else {
                    notificationStore.add(textI18[28], textI18[124]);
                }
                this.button = true;
            }).catch((e) => {
                notificationStore.add(textI18[28], textI18[124]);
                this.button = true;
            }).finally(() => {
                props(true);
            });
        }
    }

    commonClick (status) {
        const modalPatternsStore = this.props.modalPatternsStore;
        modalPatternsStore.common = status;
    }

    accountChange (event) {
        const modalPatternsStore = this.props.modalPatternsStore;
        modalPatternsStore.account = event;
    }

    nameChange (event) {
        const modalPatternsStore = this.props.modalPatternsStore;
        modalPatternsStore.name = event.target.value;
    }

    textChange (event) {
        const modalPatternsStore = this.props.modalPatternsStore;
        modalPatternsStore.text = event;
    }

    close () {
        const modalPatternsStore = this.props.modalPatternsStore;
        modalPatternsStore.open = false;
        modalPatternsStore.name = '';
    }

}

export default ModalPatterns;