import React from 'react';
import {inject, observer} from "mobx-react";

import LibraryButtonCategoriesAdd from "../../templates/library/buttonCategoriesAdd";
import {observable} from "mobx";
import APIlibrary from "../../api/library";
import APIposts from "../../api/posts";
import mainStore from "../../stor/mainStore";

@inject('libraryStore', 'initialStore')
@observer
class LibraryButtonAdd extends React.Component {

    @observable value = '';

    @observable addLoad = false;

    constructor (props) {
        super(props);
    }

    render() {
        const libraryStore = this.props.libraryStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.library;

        const openRed = libraryStore.openRedactor === 0;

        const content = <LibraryButtonCategoriesAdd
            open={openRed}
            openAction={this.openAdd}
            closeAction={this.closeAdd}
            addAction={this.addAction}
            addActionStatusLoad={this.addLoad}

            inputValue={this.value}
            inputAction={this.inputAction}
        />;

        return content;
    }

    addAction = () => {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        const libraryStore = this.props.libraryStore;

        if (this.value.length === 0) {
            return false;
        }

        if (this.addLoad) return false;

        this.addLoad=true;

        APIlibrary.categoryAdd(this.value).then((data) => {
            if (!data.status) {
                alert(text[28]);
            } else {
                APIposts.getLibrary(libraryStore.hash).then((dataLibrary) => {
                    //сохраняем посты
                    libraryStore.getPost(dataLibrary.hashPosts, dataLibrary.posts);

                    libraryStore.getCategories(dataLibrary.categories);

                    libraryStore.openRedactor=false;

                    //редиректим
                    initialStore.redirect(`/library/${data.id}`);


                }).catch((error) => {
                    alert(text[109]);
                    libraryStore.openRedactor=false;
                });

            }
            this.addLoad=false;
        }).catch(() => {
            libraryStore.openRedactor=false;
            alert(text[110]);
            this.addLoad=false;
        });
    };


    inputAction = (value) => {

        if (this.addLoad) return false;

        if (String(value).length > 20) {
            return false;
        }

        this.value = String(value);
    }

    openAdd = () => {
        const libraryStore = this.props.libraryStore;

        this.value='';

        libraryStore.openRedactor=0;

    }

    closeAdd = () => {

        if (this.addLoad) return false;

        const libraryStore = this.props.libraryStore;

        libraryStore.openRedactor=false;
    }
}

export default LibraryButtonAdd;