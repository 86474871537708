import React from 'react';
import {inject, observer} from "mobx-react";

import {getPost} from "../../actions/posts/getPost";

//Галлерея
import ImageGallery from 'react-image-gallery';
import "../../media/css/image-gallery.css";

import Time from "../../model/time";
import APIposts from "../../api/posts";
import mainStore from "../../stor/mainStore";
import {editLoad} from "../../actions/posts/editLoad";
import {observable} from "mobx";
import ReactDOM from "react-dom";

import DuwenloadMediaButton from "./DuwenloadMediaButton";
import VideoBox from "./VideoBox";
import {CopyToClipboard} from "react-copy-to-clipboard";
import ButtonModeration from "./ButtonModeration";
import ButtonDubl from "./ButtonDubl";
import Button from "../../templates/elements/button";
import ButtonCloseMobile from "./ButtonCloseMobile";
import TemplateModalViewPost from "../../templates/modal/viewPost/viewPost";
import ButtonDel from "../../templates/elements/buttonDel";

@inject('postStore', 'accountsStore', 'notificationStore', 'mediaStore', 'libraryStore', 'initialStore')
@observer
class VievPost extends React.Component{

    @observable widthMedia = 500;
    @observable textClouce = null;

    @observable editButtonLoad = false;
    @observable deliteButtonLoad = false;

    constructor (props) {
        super(props);

        this.close = this.close.bind(this);
        this.delite = this.delite.bind(this);
        this.edit = this.edit.bind(this);

        this.textBox = React.createRef();


        this.window = React.createRef();

        this.openElementGallery = 0;

        this.openWin = false;

        if (this.props.initialStore.width < 450) {

            this.widthMedia = this.props.initialStore.width;
        }
    }

    render() {
        const postStore = this.props.postStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.post;

        if (!postStore.open) {
            return null;
        }

        let open = false;
        let contentMedia = null;
        let geo = false;
        let time = null;
        let timeColor = false;
        let publicationStatus = false;
        let deliteTime = false;
        let deliteTimeInfo = false;
        let deliteButton = null;
        let editButton = null;
        let userCreator = null;
        const date = postStore.date;

        if (!postStore.load) {
            open = true;

            const images = [];

            if (date.media.slice().length === 1) {
                if (date.media[0].tip === 'video') {

                    const item = {
                        embedUrl: date.media[0].urlVideo,
                        original: date.media[0].urlImage,
                        proportions: date.media[0].proportions,
                    };
                    contentMedia = <VideoBox item={item} widthMedia={this.widthMedia}/>
                } else {
                    contentMedia = this._renderImage({
                        original: date.media[0].urlImage,
                        proportions: date.media[0].proportions,
                        accounts: date.media[0].accounts,
                    });
                }
            } else {
                for (const key in date.media.slice()) {
                    const item = date.media[key];
                    console.log(item);
                    if (item.tip === 'video') {
                        images.push({
                            thumbnail: item.urlImage,
                            original: item.urlImage,
                            embedUrl: item.urlVideo,
                            proportions: item.proportions,
                            renderItem: (e) => {return <VideoBox item={e} open={this.openElementGallery} widthMedia={this.widthMedia}/>},
                        });
                    } else {
                        images.push({
                            original: item.urlImage,
                            thumbnail: item.urlImage,
                            proportions: item.proportions,
                            accounts: item.accounts,
                            renderItem: this._renderImage.bind(this),
                        });
                    }
                }

                contentMedia = <ImageGallery
                    items={images}
                    infinite={true}
                    thumbnailPosition={'bottom'}
                    showPlayButton={false}
                    showThumbnails={false}
                    showBullets={true}
                    showFullscreenButton={false}
                    onSlide={(e) => {this.openElementGallery = e}}
                />
            }


            //время публикации
            if (date.time) {
                time = Time.convertUnixToDatePostBox(date.time);
            }

            //geo публикации
            if (date.geo) {
                geo = date.geo;
            }

            if (date.publicationStatus) {
                if (date.publicationStatus === 1) {
                    publicationStatus = text[11];
                } else if (date.publicationStatus === 2) {
                    publicationStatus = text[12];
                    if (date.publicationUrl) {
                        publicationStatus = <a href={date.publicationUrl} target="_blank">{text[12]}</a>;
                    }
                    timeColor = false;
                } else if (date.publicationStatus === 3) {
                    publicationStatus = text[13];
                    timeColor = '#F44336';
                }
            }


            if (date.timeDelite) {
                deliteTime = Time.convertUnixToDatePostBox(date.timeDelite);
                deliteTimeInfo = text[9];
            } else if (date.timeArhive) {
                deliteTime = Time.convertUnixToDatePostBox(date.timeArhive);
                deliteTimeInfo = text[10];
            }

            //кнопка удаления
            if (date.edit && postStore.autorisation) {
                //deliteButton = <Button color load={this.deliteButtonLoad} action={this.delite} style={{width: '100%'}}>divButtonDel</Button>;
                deliteButton = <ButtonDel style={{width: '100%', height: '47px'}} action={this.delite}>postDel</ButtonDel>;

                editButton = <Button load={this.editButtonLoad} color action={this.edit} style={{width: '100%', textAlign: 'left', padding: '13px 30px 13px', height: '47px'}}>accountEmployeeEdit</Button>;
            }
        }

        //Title
        let title = date.name ? date.name : text[6];
        if (date.type === 'story') {
            title = text[7];
        } else if (date.type === 'igtv') {
            title = text[8];
        } else if (date.type === 'reels') {
            title = text[44];
        }

        userCreator = date.user;

        return <TemplateModalViewPost
            close={this.close}
            open={open}
            title={title}
            contentMedia={contentMedia}
            text={date.text}
            coment={date.coment}
            likes={date.likes}
            comment={date.comment}
            view={date.view}
            geo={geo}
            time={time}
            timeColor={timeColor}
            publicationStatusDate={date.publicationStatus}
            publicationStatus={publicationStatus}
            deliteTime={deliteTime}
            deliteTimeInfo={deliteTimeInfo}
            editButton={editButton}
            deliteButton={deliteButton}
            url={date.url}
            userCreator={userCreator}
            textInfo={{
                geo: text[36],
                publication: text[37],
                created: text[38],
                link: text[39],
                createdCopy: text[40],
                publicationCopy: text[41],
                geoCopy: text[42],
                delCopy: text[43],

            }}
        />;
    }


    componentDidUpdate() {
        const postStore = this.props.postStore;

        if (postStore.open && postStore.load === true && postStore.loadContent === false) {
            postStore.loadContent = true;
            getPost(postStore.id, postStore.code);
            this.textClouce = null;

        }

        if (postStore.load === false) {
            postStore.loadContent = false;
        }
    }

    edit () {
        const postStore = this.props.postStore;
        const mediaStore = this.props.mediaStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.post;

        if (mediaStore.collapsed) {
            notificationStore.add(text[14], text[15], 10);
            return false;
        }

        this.editButtonLoad = true;

        if (postStore.editStatus === 0) {
            postStore.editStatus = 1;

            const functionBeck = () => {this.editButtonLoad = false}
            editLoad(postStore.id, postStore.code, functionBeck);
        }
    }

    delite (resolve) {
        const postStore = this.props.postStore;
        const accountsStore = this.props.accountsStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.post;
        this.deliteButtonLoad = true;

        APIposts.delite(postStore.id, postStore.code).then((data) => {

            if (!data.status) {
                this.deliteButtonLoad = false;
                notificationStore.add(text[17],text[16]);
                resolve(true);
                return;
            }

            const idAccount = accountsStore.active;

            if (accountsStore.active === null) {

                const libraryStore = this.props.libraryStore;

                libraryStore.openRedactor = false;

                APIposts.getLibrary(libraryStore.hash).then((dataLibrary) => {
                    //сохраняем посты
                    mainStore.libraryStore.getPost(dataLibrary.hashPosts, dataLibrary.posts);

                    mainStore.libraryStore.getCategories(dataLibrary.categories);
                    this.deliteButtonLoad = false;
                    mainStore.postStore.open = false;
                    resolve(true);

                }).catch((error) => {
                    this.closePostDel();
                    this.deliteButtonLoad = false;
                    notificationStore.add(text[17],text[18]);
                    resolve(true);
                });
            } else {

                const idAccountReal = accountsStore.list[idAccount].id;
                const idAccountRealHashPost = accountsStore.list[idAccount].post.hash;
                const idAccountRealHashStory = accountsStore.list[idAccount].story.hash;

                accountsStore.getPostLoad(idAccount);
                accountsStore.getStoryLoad(idAccount);

                APIposts.getPosts(idAccountReal, idAccountRealHashPost, idAccountRealHashStory).then((data) => {

                    accountsStore.getPost(idAccount, data.hashPosts, data.posts, data.rate);
                    accountsStore.getStory(idAccount, data.hashStory, data.story);

                    this.deliteButtonLoad = false;
                    mainStore.postStore.open = false;
                    resolve(true);
                }).catch((error) => {
                    this.closePostDel();
                    this.deliteButtonLoad = false;
                    notificationStore.add(text[17],text[18]);
                    resolve(true);
                });
            }
        }).catch((error) => {
            //console.log(error);
            this.deliteButtonLoad = false;
            notificationStore.add(text[17],text[16]);
            resolve(true);
        });
    }

    close () {
        const postStore = this.props.postStore;
        postStore.open = false;
        postStore.loadContent = false;
    }

    _renderImage(item) {
        let width = this.widthMedia;
        let height = Math.ceil(this.widthMedia / item.proportions);

         let accounts = [];

         if (item.accounts) {
             if (item.accounts.length) {
                 for (let elementsAccount of item.accounts) {

                     accounts.push(<div class="elementsAccountDiv" style={{left: elementsAccount.left+'%', top: elementsAccount.top+'%'}}>
                         <div>{elementsAccount.account}</div>
                     </div>);
                 }
             }
         }


        return (
            <div className='image-gallery-image'>

                <div className='video-wrapper'>
                    {accounts}
                    <img
                        width={width}
                        height={height}
                        src={item.original}
                    />

                </div>
                <DuwenloadMediaButton url={item.original} type={'jpg'}/>
            </div>
        );
    }

}

export default VievPost;
