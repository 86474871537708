import React from 'react';
import {inject, observer} from "mobx-react";
import PostBox from "../../component/PostBox/PostBox";

import APIposts from "../../api/posts";
import mainStore from "../../stor/mainStore";
import PostsBox from "../../templates/posts/postsBox";
import LoaderComponent from "../../templates/loaderComponent";
import LibraryIndex from "../../templates/library";
import LibraryButtonCategories from "../../templates/library/buttonCategories";
import LibraryButtonAdd from "./buttonAdd";

import InfoContainer from "../../templates/infoConteiner";


@inject('libraryStore', 'accountsStore', 'mediaStore', 'initialStore')
@observer
class Library extends React.Component {

    constructor (props) {
        super(props);
    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.library;

        const libraryStore = this.props.libraryStore;
        const posts = libraryStore.list.slice();

        let category = this.props.match.params.category === undefined ? 0 : this.props.match.params.category;

        if (category === '0') category = 0;

        const categoriesList = libraryStore.categories.slice();

        let content = <LoaderComponent/>;

        let categories = [];
        let categoriesName='';

        for (const categoryElement of categoriesList) {
            categories.push(<LibraryButtonCategories key={categoryElement.id}
                active={category === categoryElement.id}
                name={categoryElement.id === 0 ? text[8] : categoryElement.name}
                id={categoryElement.id}

                setupAction={() => libraryStore.openRedactor=categoryElement.id}
                setupStatus={libraryStore.openRedactor === categoryElement.id}
            />);

            if (category === categoryElement.id) {
                categoriesName = categoryElement.name;
            }

        }


        if (posts !== null) {
            let postsBox = null;


                let postI = 0;
                let postsArray = posts.map((item) => {

                    if (category) {
                        if (item.category !== category) {
                            return null;
                        }
                    }
                    postI++;
                    return <PostBox key={item.post.code} {...item} />;
                });

                if (postI) {
                    postsBox = <PostsBox>
                        <div className="posts row view-1">
                            {postsArray}
                        </div>
                    </PostsBox>;
                } else {
                    if (category) {
                        postsBox = <InfoContainer

                            title={text[1](categoriesName)}
                            text={text[2](categoriesName)}
                        />;
                    } else {

                        const mediaStore = this.props.mediaStore;

                        if (!mediaStore.collapsed) {
                            postsBox = <InfoContainer
                                title={text[3]}
                                text={text[4]}
                                buttonName={text[5]}
                                buttonClick={() => {this.openPost()}}
                            />;
                        } else {
                            postsBox = <InfoContainer
                                title={text[3]}
                                text={text[4]}
                            />;
                        }

                    }

                }



            const categoriesAdd=<LibraryButtonAdd/>;

            content = <LibraryIndex
                posts={postsBox}
                categories={categories}
                categoriesAdd={categoriesAdd}
            />
        }
        return content;
    }

    openPost = () => {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        this.props.mediaStore.actionOpen(true);
    }

    componentDidMount() {
        const libraryStore = this.props.libraryStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        libraryStore.openRedactor = false;

        APIposts.getLibrary(libraryStore.hash).then((dataLibrary) => {
            //сохраняем посты
            mainStore.libraryStore.getPost(dataLibrary.hashPosts, dataLibrary.posts);

            mainStore.libraryStore.getCategories(dataLibrary.categories);
        }).catch((error) => {
            alert(text[109]);
        });
    }

}

export default Library;
