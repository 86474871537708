class EritorMedia {

    constructor (object) {

        this.zoomEdenica = 0.1;

        this.state = object;

        this.maxWidth = 400;

        const width=document.body.clientWidth;//992
        if (width < 400) {
            this.maxWidth = width;
        }

        this.historyZoom = 1;
    }

    importState () {
        return this.state;
    }

    initial () {

        //отвечает за размер во всю ширину или высоту фона
        this.contentFullSkreen();

        //если надо что бы добавлялись белые края, первую функцию надо закоментить
        //this.setZoom(1);
        //this.draggablePositionCenter();
    }

    getColor (color) {
        this.state.color = color;
    }

    setBackgroundPlus () {
        let proportions = this.state.proportionsBackground + this.zoomEdenica;
        this.setProportionsBackground(proportions);
    }

    setBackgroundMinus () {
        let proportions = this.state.proportionsBackground - this.zoomEdenica;
        this.setProportionsBackground(proportions);
    }

    setProportionsBackground (proportions) {
        proportions = proportions > 1.91 ? 1.91 : proportions;
        proportions = proportions < 0.5 ? 0.5 : proportions;

        this.state.proportionsBackground = proportions;
        this.setZoom(1);
    }

    getDraggable(x, y) {

        //конвертируем данные в %
        const xPercent = x === 0 ? 0 : Math.ceil((x/(this.backgroundSizePX().width/100))*1000)/1000;
        const yPercent = y === 0 ? 0 : Math.ceil((y/(this.backgroundSizePX().height/100))*1000)/1000;

        this.state.draggable = {
            x: xPercent,
            y: yPercent,
        }
    }

    setDraggable () {
        const onPercentWidth = this.backgroundSizePX().width/100;
        const onPercentHeight = this.backgroundSizePX().height/100;

        return {
            x: Math.round(this.state.draggable.x * onPercentWidth),
            y: Math.round(this.state.draggable.y * onPercentHeight),
        };
    }

    backgroundSizePX () {
        //размер подложки
        let width = this.maxWidth;

        if (this.state.proportionsBackground < 1) {
            width = this.maxWidth * this.state.proportionsBackground;
        }

        if (this.state.proportionsBackground < 0.8) {
            width = Math.round(this.maxWidth /100 * 70);
        }

        let height = Math.round(width/this.state.proportionsBackground);

        return {width: width, height: height};
    }

    contentSizePX (sdf = false) {
        const width = this.getStateObertka().width/this.state.crop.width*100;
        const height = this.getStateObertka().height/this.state.crop.height*100;
        const contentWidthUanPercent = this.getStateObertka().width/this.state.crop.width;
        const contentHeightUanPercent = this.getStateObertka().height/this.state.crop.height;
        const left = contentWidthUanPercent*this.state.crop.x;
        const top  = contentHeightUanPercent*this.state.crop.y;

        return {
            width: Math.round(width),
            height: Math.round(height),
            left: Math.round(left),
            top: Math.round(top),
        };

    }

    setZoomPlus () {
        let zoom = this.state.zoom + this.zoomEdenica;
        this.setZoom(zoom);
    }

    setZoomMinus () {
        let zoom = this.state.zoom - this.zoomEdenica;
        this.setZoom(zoom);
    }

    setZoom (zoom) {
        zoom = zoom < 1 ? 1: zoom;
        zoom = zoom > 10 ? 10: zoom;

        this.historyZoom = this.state.zoom;

        this.state.zoom = Math.ceil((zoom)*1000)/1000;
        this.zoom();
    }

    getZoom () {
        return this.state.zoom;
    }

    getStateObertka (zoom = false) {

        if (!zoom) {
            zoom = this.state.zoom;
        }

        //размер окантовки фото
        const backgroundSizePX = this.backgroundSizePX();
        let contentObertkaWidth = backgroundSizePX.width;
        let contentObertkaHeight = backgroundSizePX.height;

        if (this.state.proportionsBackground < this.state.crop.proportions) {
            contentObertkaHeight = contentObertkaWidth / this.state.crop.proportions;
        } else {
            contentObertkaWidth = contentObertkaHeight * this.state.crop.proportions;
        }

        return  {
            width: Math.round(contentObertkaWidth*zoom),
            height: Math.round(contentObertkaHeight*zoom),
        };
    }

    zoom () {
        //размер окантовки фото
        let contentObertkaWidth = this.backgroundSizePX().width;
        let contentObertkaHeight = this.backgroundSizePX().height;

        //debugger;
        if (this.state.proportionsBackground < this.state.crop.proportions) {
            contentObertkaHeight = contentObertkaWidth / this.state.crop.proportions;
        } else {
            contentObertkaWidth = contentObertkaHeight * this.state.crop.proportions;
        }

        //берем старые данные размера что бы позиционировать после изменения размер равномерно.

        const widthHistory = this.getStateObertka(this.historyZoom).width;
        const heightHistory = this.getStateObertka(this.historyZoom).height;
        //console.log(widthHistory);

        let plusDraggableX = -(((contentObertkaWidth*this.state.zoom) - widthHistory)/2);
        let plusDraggableY = -(((contentObertkaHeight*this.state.zoom) - heightHistory)/2);

        let newDraggableX = this.setDraggable().x+plusDraggableX;
        let newDraggableY = this.setDraggable().y+plusDraggableY;

        const getDraggableBounds = this.getDraggableBounds();

        newDraggableX = newDraggableX  < getDraggableBounds.left ? getDraggableBounds.left : newDraggableX;
        newDraggableX = newDraggableX  > getDraggableBounds.right ? getDraggableBounds.right : newDraggableX;

        newDraggableY = newDraggableY  < getDraggableBounds.top ? getDraggableBounds.top : newDraggableY;
        newDraggableY = newDraggableY  > getDraggableBounds.bottom ? getDraggableBounds.bottom : newDraggableY;

        if (this.state.zoom === 1) {
            this.draggablePositionCenter();
        } else {
            this.getDraggable(newDraggableX, newDraggableY);
        }

    }

    getDraggableBounds () {

        let right = this.backgroundSizePX().width-this.getStateObertka().width;
        let left = 0;
        if ( right < 0 ) {
            left = right;
            right = 0;
        }

        let bottom = this.backgroundSizePX().height-this.getStateObertka().height;
        let top = 0;
        if ( bottom < 0 ) {
            top = bottom;
            bottom = 0;
        }

        return {
            left: left,
            top:top,
            right:right ,
            bottom: bottom,
        }
    }

    setCrop(newCropPx, newCropPercent) {

        if (newCropPercent.width===0 &&newCropPercent.height === 0) {
            return false;
        }

        newCropPercent.width = Math.ceil((newCropPercent.width)*1000)/1000;
        newCropPercent.height = Math.ceil((newCropPercent.height)*1000)/1000;
        newCropPercent.x = Math.ceil((newCropPercent.x)*1000)/1000;
        newCropPercent.y = Math.ceil((newCropPercent.y)*1000)/1000;

        let p1 = 100;//высота
        let p2 = p1*this.state.proportions;//ширина
        let p3 = p2/100*newCropPercent.width;//новая ширина
        let p4 = p1/100*newCropPercent.height;//новая высота
        let proportionsCrop = p3/p4;

        proportionsCrop = Math.ceil((proportionsCrop)*1000)/1000;

        const newCropPercentDop = {...newCropPercent, proportions: proportionsCrop};

        let reZoom = false;
        if (proportionsCrop !== this.state.crop.proportions) {
            reZoom = true;
        }

        this.state.crop = {
            ...this.state.crop,
            ...newCropPercentDop,
        };

        if (reZoom) {
            this.setZoom(1);
            this.draggablePositionCenter();
        } else {
        }
    }

    //  координаты для центровки обертки
    draggablePositionCenter () {

        const polovinaBackgroundX = this.backgroundSizePX().width / 2;
        const polovinaObertkaX = this.getStateObertka().width / 2;
        const x = polovinaBackgroundX-polovinaObertkaX;

        const polovinaBackgroundY = this.backgroundSizePX().height / 2;
        const polovinaObertkaY = this.getStateObertka().height / 2;
        const y = polovinaBackgroundY-polovinaObertkaY;

        this.getDraggable(x, y);
    }

    contentFullSkreen () {
        this.setZoom(this.getFullSkreen());
        this.draggablePositionCenter();
    }

    getFullSkreen () {
        let zoomFullSkreen = 1;

        if (this.state.proportionsBackground >= this.state.crop.proportions ) {
            zoomFullSkreen = this.state.proportionsBackground / this.state.crop.proportions;
        } else {
            zoomFullSkreen = this.state.crop.proportions / this.state.proportionsBackground;
        }
        return zoomFullSkreen;
    }


}

export default EritorMedia;
