import qs from "qs";
import React from "react";

import instance from "./base";


const APItimetable = {

    getPatterns (account, hash) {
        return instance.post('timetable', qs.stringify({
            account: account,
            hesh:hash,
        })).then(data => {
            return data;
        });
    },

    setPatterns (account, tip, id, type) {
        return instance.post('timetable/red', qs.stringify({
            account: account,
            tip:tip,
            id:id,
            type:type,
        })).then(data => {
            return data;
        });
    },



}

export default APItimetable;