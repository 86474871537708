import React from 'react';
import {Redirect} from "react-router";

import {Link} from "react-router-dom";
import APIuser from "../../api/user";
import {inject, observer} from "mobx-react";

export default
@inject('initialStore')
@observer
class RegistrationOk extends React.Component {

    render() {

        const initialStore = this.props.initialStore;
        const text = initialStore.text.login;

        return (
            <div className="floatScreen">
                <div className="auth">

                    <div className="form">

                        <div className="logo_white"></div>

                        <div className="title1">{text[7]}</div>


                        <div className="form_group">

                            <div className="input-box">
                                <span className="input-label1">{text[12](this.props.match.params.email)}</span>

                            </div>

                            <div className="input-box">
                                <Link to="/login" className="btn1 border1 btn-primary1" style={{width: '100%'}}>{text[13]}</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        APIuser.getLogin().then((data) => {
            if (data) {
                this.setState({inform: <Redirect to="/" />, loginTest: true});
            }
        }).catch((error) => {

        });
    }

}