import React from 'react';
import {inject, observer} from "mobx-react";

import Account from "./elements/account";

import MenuGroup from "../../templates/elements/menu/group";
import MenuAccountBox from "../../templates/elements/menu/accountBox";
import MenuElement from "../../templates/elements/menu/element";
import MenuElementsBox from "../../templates/elements/menu/elementsBox";
import MenuElementExit from "../../templates/elements/menu/elementExit";
import MenuElementAddAccount from "../../templates/elements/menu/elementAddAccount";
import MenuConteiner from "../../templates/elements/menu/conteiner";

@inject("menuStore", "initialStore", "accountsStore", "modalAddAccount", "chatStore")
@observer
class Menu extends React.Component {

    constructor (props) {
        super(props);
        this.elementOnClick = this.elementOnClick.bind(this);
        this.exitOnClick = this.exitOnClick.bind(this);

    }

    exitOnClick (e) {
        const initialStore = this.props.initialStore;
        const menuStore = this.props.menuStore;
        menuStore.openMenuMobile = false;
        initialStore.modalExitOpen = true;
    }

    elementOnClick (e) {
        const menuStore = this.props.menuStore;
        menuStore.openMenuMobile = false;
        this.props.menuStore.updateActiveUrlMenu(e);
    }

    addAccount = () => {
        const modalAddAccount = this.props.modalAddAccount;
        const menuStore = this.props.menuStore;
        menuStore.openMenuMobile = false;
        modalAddAccount.openActive();
    }

    render() {
        const accountsStore = this.props.accountsStore;
        const menuStore = this.props.menuStore;
        const chatStore = this.props.chatStore;

        const initialStore = this.props.initialStore;

        const text = initialStore.text.menu;

        let groups = [];

        const newMessageDirect = accountsStore.newMessage;


        for (const element of accountsStore.groups) {

            let AccountsI = 0;
            let accountList = accountsStore.list.map((item, key) => {
                if (item.group !== element.id) return;

                AccountsI++;
                return <Account key={key} account={item} id={key} direct={newMessageDirect[key]} number={String(key)} />;
            });

            if (!AccountsI) {
                continue;
            }

            groups.push(
                <MenuGroup key={element.id} name={element.name}>
                    {accountList}
                </MenuGroup>
            );
        }



        const elementsMenu = menuStore.elementsMenu.map((item) => {

            let active = false;
            if (accountsStore.active === null && item.url === menuStore.activeUrlMenu) active = true;

            let name = '';
            let icon = '';
            switch (item.key) {
                case 0:
                    name=text.savedPosts;
                    icon='icon-nav-save';
                    break;
                case 1:
                    name=text.downloadFromInstagram;
                    icon='icon-nav-download';
                    break;
                case 2:
                    name=text.rates;
                    icon='icon-nav-tarrifs';
                    break;
                case 3:
                    name=text.settings;
                    icon='icon-nav-settings';
                    break;
                case 4:
                    name=text.help;
                    icon='icon-nav-about';
                    break;
            }

            /*if (initialStore.cordova && item.key === 2 && initialStore.clientId_tarif === 0) {
                return null;
            }*/

            if (initialStore.templates === 'desktop' && item.key === 4) {
                return null;
            }

            let notification = false;
            if (item.key === 4) {
                if (chatStore.newMessage) {
                    notification = chatStore.newMessage > 9 ? 9:chatStore.newMessage;
                }
            }

            return <MenuElement
                    key={item.key}
                    name={name}
                    active={active}
                    iconClass={icon}
                    url={item.url}
                    fun={this.elementOnClick}
                    id={item.key}
                    notification={notification}
                />;
        });

        return <MenuConteiner>
                <MenuAccountBox>
                    {groups}
                </MenuAccountBox>
                <MenuElementsBox>
                    <MenuElementAddAccount
                        name={text.addAccount}
                        fun={this.addAccount}
                        iconClass={'icon-nav-add'}
                    />
                    {elementsMenu}

                    <MenuElementExit
                        name={text.exit}
                        fun={this.exitOnClick}
                        iconClass={'icon-nav-exit'}
                    />

                </MenuElementsBox>
            </MenuConteiner>;

    }
}

export default Menu;
