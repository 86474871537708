import React from 'react';
import mainStore from "../stor/mainStore";
import APImedia from "../api/media";

class StatusPostLoop extends React.Component{

    constructor (props) {
        super(props);
        this.timerId = false;
        this.loopStatus();
        this.obr = false;
    }

    loopStatus() {

        this.timerId = setInterval(() => {

            if (this.obr) {
                return false;
            }

            const list = mainStore.accountsStore.list.slice();

            const libraryStore = mainStore.libraryStore;
            const postsLibrary = libraryStore.list.slice();

            let array = [];

            for (const account of list) {

                if (account.post.list) {

                    for (const post of account.post.list.slice()) {

                        if (post.post.status.status) {
                            array.push(post.post.code + '_' + post.id);
                        }
                    }
                }
            }

            for (const post of postsLibrary) {
                if (post.post.status.status) {
                    array.push(post.post.code);
                }
            }

            if (array.length) {
                this.obr = true;
                APImedia.getInfoStatus(array).then((data) => {
                    this.obr = false;


                    if (!data.status) {
                        return false;
                    }

                    //цикл по аккаунтам
                    for (const accountKey in list) {

                        //если постов нет то пропускаем итерацию
                        if (list[accountKey].post.list === null) {
                            continue;
                        }

                        //если постов нет то пропускаем итерацию
                        if (!list[accountKey].post.list.slice()) {
                            continue;
                        }

                        //цикл по постам аккаунта
                        for (const postKey in list[accountKey].post.list.slice()) {


                            if (!list[accountKey].post.list[postKey].post.status.status) {
                                continue;
                            }

                            //код поста
                            const code = list[accountKey].post.list[postKey].post.code;

                            if (!data.list[code]) {
                                continue;
                            }

                            if (!data.list[code].status) {
                                continue;
                            }


                            const statusNew = data.list[code].status;

                            if (statusNew === 'ok') {

                                mainStore.accountsStore.list[accountKey].post.list[postKey].post.status.status = {
                                    status: false,
                                    info: false,
                                    procent: false,
                                };
                                /*mainStore.accountsStore.list[accountKey].post.list[postKey].post.statusInfo = false;
                                mainStore.accountsStore.list[accountKey].post.list[postKey].post.statusProcent = false;*/

                            } else if (statusNew === 'ojered') {

                                mainStore.accountsStore.list[accountKey].post.list[postKey].post.status = {
                                    status: 'ojered',
                                    info: false,
                                    procent: false,
                                };

                            } else if (statusNew === 'error') {

                                mainStore.accountsStore.list[accountKey].post.list[postKey].post.status = {
                                    status: 'error',
                                    info: false,
                                    procent: false,
                                };

                            } else if (statusNew === 'load') {

                                console.log('load', {status: 'load',
                                    info: data.list[code].info,
                                    procent: data.list[code].procent});

                                mainStore.accountsStore.list[accountKey].post.list[postKey].post.status = {
                                    status: 'load',
                                    info: data.list[code].info,
                                    procent: data.list[code].procent,
                                };
                            } else {

                            }

                        }

                    }

                    //цикл по постам аккаунта
                    for (const postKey in libraryStore.list.slice()) {

                        if (!libraryStore.list[postKey].post.status.status) continue;

                        //код поста
                        const code = libraryStore.list[postKey].post.code;

                        if (!data.list[code]) continue;
                        if (!data.list[code].status) continue;


                        const statusNew = data.list[code].status;

                        if (statusNew === 'ok') {
                            mainStore.libraryStore.list[postKey].post.status = {
                                status: false,
                                info: false,
                                procent: false,
                            };
                        } else if (statusNew === 'ojered') {
                            mainStore.libraryStore.list[postKey].post.status = {
                                status: 'ojered',
                                info: false,
                                procent: false,
                            };
                        } else if (statusNew === 'error') {
                            mainStore.libraryStore.list[postKey].post.status = {
                                status: 'error',
                                info: false,
                                procent: false,
                            };
                        } else if (statusNew === 'load') {
                            mainStore.libraryStore.list[postKey].post.status = {
                                status: 'load',
                                info: data.list[code].info,
                                procent: data.list[code].procent,
                            };
                        }
                    }

                });
            }

        }, 3000);
    }


    render() {



        return null;
    }

}

export default StatusPostLoop;