import React from 'react';

import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import loadFileObrabotchik from "../../../../model/loadFileObrabotchik";
import {getBase64Strings} from "exif-rotate-js/lib";
import APIfile from "../../../../api/file";

@inject('editorStore', 'notificationStore', 'initialStore')
@observer
class MediaRedImage extends React.Component {

    @observable type = 0;
    @observable load = false;
    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;
        this.fileInput = React.createRef();
    }

    render() {
        return (
            <>
                <div className={this.props.className}><button type="button" className={this.load ? "btn border btn-block btn-primary-animate-content" : "btn border btn-block"} onClick={this.action}>Фото</button></div>
                <input className="no-fastclick" style={{display: 'none'}} type="file" accept="image/*" ref={this.fileInput} onChange={this.filOnChange} />
            </>
        );
    }

    action = () => {
        const notificationStore = this.props.notificationStore;

        if (this.load) {
            notificationStore.add('Ошибка', 'Дождитесь добавление Изображения в редактор');
            return true;
        }
        //Удаляем прошлые файлы из формы
        this.fileInput.current.value = null;
        //открываем форму
        this.fileInput.current.click();
    }

    //передаем файлы в обработчик после выбора
    filOnChange = () => {
        this.obrabotka(this.fileInput.current.files)
    }

    obrabotka = (files) => {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;

        if (!files.length) {
            return false;
        }

        const file = files[0];

        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/webp') {
            if (file.size > 10000000) {
                notificationStore.add('Ошибка', 'Изображение '+file.name + ' больше 10мб, уменьшите размер или загрузите другой файл');
                return false;
            }
        } else {
            notificationStore.add('Ошибка', 'Данный тип файла не поддерживается, загрузите другое изображение');
            return false;
        }

        this.load = true;

        let culback = (date) => {

            if (!date.status) {
                notificationStore.add('Ошибка', initialStore.text.code[date.code]);
                this.load = false;
                return;
            }

            this.fabric.addImage('new', false, 'http://likepro.ru/cloud/files/' + date.file).then(() => {
                this.load = false;
                this.props.clouse();
            }).catch(() => {
                notificationStore.add('Ошибка', 'Произошла ошибка в момент обработки файла, повторите попытку.');
                return;
            });
        }

        APIfile.loadFileRed(files[0], culback);



    }

}

export default MediaRedImage;
