import React from 'react';
import {Redirect} from "react-router";

import APIuser from "../../api/user";
import Form from './form';
import {observable} from "mobx";
import {inject, observer} from "mobx-react";

@inject('initialStore')
@observer
class Restorepassword extends React.Component {

    @observable mail = '';
    @observable inform = '';

    @observable load = false;

    constructor (props) {
        super(props);

        this.state = {
            mail: '',
            inform:'',
        };

        this.mailOnChange = this.mailOnChange.bind(this);
        this.okOnClick = this.okOnClick.bind(this);
    }

    mailOnChange (value) {
        this.mail = value;
    }

    okOnClick () {

        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        this.load = true;

        this.inform = '';

        if (this.mail === '') {
            this.inform = text[107];
            this.load = false;
            return false;
        }

        APIuser.getRestorepassword(this.mail, initialStore.language).then(data => {
            this.load = false;
            if (!data.status) {

                this.inform = initialStore.text.code[data.code];

            } else {
                this.inform = text[108](this.mail);
                this.mail = '';
            }

        });
    }

    render() {
        return (<Form mail={this.mail}
                      mailOnChange={this.mailOnChange}
                      inform={this.inform}
                      okOnClick={this.okOnClick}
                      load={this.load}
                />);
    }

    componentDidMount() {

        APIuser.getLogin().then((data) => {
            if (data) {
                this.setState({inform: <Redirect to="/" />, loginTest: true});
            }
        }).catch((error) => {

        });
    }
}
export default Restorepassword;