import React from 'react';
import {inject, observer} from "mobx-react";

import PostBox from "../../../component/PostBox/PostBox";
import PostsBox from "../../../templates/posts/postsBox";
import LoaderComponent from "../../../templates/loaderComponent";
import InfoContainer from "../../../templates/infoConteiner";
import Time from "../../../model/time";


@inject('accountsStore', 'menuStore', 'initialStore', 'mediaStore')
@observer
class Story extends React.Component {



    render() {
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;
        const story = accountsStore.list[accountsStore.active].story.list;
        const text = initialStore.text.account;

        let content = <LoaderComponent/>;

        if (story !== null) {

            if (story.length) {
                let postsArray = story.map((item) => {
                    return <PostBox key={item.id} {...item} story={true} />;
                });

                content = <PostsBox>
                    <div className="posts row view-1">
                        {postsArray}
                    </div>
                </PostsBox>;
            } else {

                const mediaStore = this.props.mediaStore;


                if (!mediaStore.collapsed) {
                    content = <InfoContainer
                        title={text[114]}
                        text={text[115](accountsStore.list[accountsStore.active].name)}
                        buttonName={text[116]}
                        buttonClick={this.openStory}
                    />;
                } else {
                    content = <InfoContainer
                        title={text[114]}
                        text={text[115](accountsStore.list[accountsStore.active].name)}
                    />;
                }
            }
        }
        return content;
    }



    componentDidMount() {
        const initialStore = this.props.initialStore;

        this.props.menuStore.updateActiveComponentAccount('story');

        //Скролл на верх
        if (initialStore.refContentInner !== null) {
            if (initialStore.refContentInner.scrollTop) {
                initialStore.refContentInner.scrollTo(0, 0);
            }
        }
    }

    openStory = () => {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        let activeAccount = false;
        /*for (const accountID in accountsStore.list.slice()) {
            if (accountsStore.list[accountID].tip === 'instagram') {
                activeAccount = accountID;
                break;
            }
            if (accountsStore.list[accountID].tip === 'vk' && accountsStore.list[accountID].storyTrue === true) {
                activeAccount = accountID;
                break;
            }
        }*/

        //выставляем аккаунт который выбран
        if (mediaStore.accounts.length === 0 && accountsStore.active !== null) {

            if (accountsStore.list[accountsStore.active].tip === 'instagram') {
                activeAccount = accountsStore.active;
            }
            if (accountsStore.list[accountsStore.active].tip === 'vk' && accountsStore.list[accountsStore.active].storyTrue === true) {
                activeAccount = accountsStore.active;
            }

            if (activeAccount) {
                const time = Time.dateTimetableGenerator(accountsStore.list[activeAccount].id, 'story');

                mediaStore.actionAccount(accountsStore.list[activeAccount].id, time[0]);
            }


        }

        this.props.mediaStore.actionOpenStory(true);
    }
}

export default Story;
