import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import style from "../../../component/menu/index.module.css";

export default
@inject('initialStore')
@observer
class MenuAccountBox extends React.Component {

    render() {
        const initialStore = this.props.initialStore;


        if (!this.props.children.length) {
            return null;
        }

        if (initialStore.templates === 'desktop') {
            return <div className="sidebar-users">
                    {this.props.children}
            </div>;
        } else {
            return <div className="sidebar-users">
                {this.props.children}
            </div>;
        }

    }
}

