import React from 'react';
import {inject, observer} from "mobx-react";

import APIposts from "../../../api/posts";
import mediaStore from "../../../stor/mediaStore";
import APIfile from "../../../api/file";
import loadFileObrabotchik from "../../../model/loadFileObrabotchik";

@inject('accountsStore', 'initialStore', 'mediaStore')
@observer
class FilesLoading extends React.Component {

    constructor (props) {
        super(props);

        this.clickFile = this.clickFile.bind(this);
    }

    render() {
        const mediaStore = this.props.mediaStore;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        let viev = null;

        if (mediaStore.mediaLoading === true) {

            viev = <button className="btn btn-primary" style={{width: '100%'}} onClick={this.clickFile}>{text[57]}</button>;

        } else if (mediaStore.mediaLoading === 1) {

            viev = <button className="btn btn-primary btn-primary-animate" style={{width: '100%'}}>{text[58]}</button>;

        } else if (mediaStore.mediaLoading === 2) {

            viev = <button className="btn btn-primary btn-primary-animate" style={{width: '100%'}}>{text[59](Math.ceil(mediaStore.mediaLoadingPercent) + '%')}</button>;

        } else if (mediaStore.mediaLoading === 3) {

            viev = <button className="btn btn-primary btn-primary-animate" style={{width: '100%'}}>{text[60](Math.ceil(mediaStore.mediaLoadingPercent) + '%')}</button>;

        }

        let render = <>
            {viev}
        </>;

        return render;
    }

    clickFile() {

        const mediaStore = this.props.mediaStore;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        mediaStore.mediaLoading = 1;
        APIposts.editLoadFile(mediaStore.id, mediaStore.code).then((date) => {

            APIfile.getKey().then((data) => {
                mediaStore.mediaLoading = false;

                mediaStore.idLoad = data.id;

                loadFileObrabotchik(date.file, true);

            }).catch(() => {
                mediaStore.mediaLoading = true;
                alert('Ошибка загрузки данных по медиа файлам, повторите через пару минут.');
            });
        }).catch((error) => {
            console.log(error);
            alert(text[61]);
        });
    }
}
export default FilesLoading;
