import React from 'react';
import {inject, observer} from "mobx-react";

export default
@inject('initialStore')
@observer
class TemplateModalAddAccountOk extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;


        if (initialStore.templates === 'desktop') {

            let render = <>
                <span className="title-box center">{text[35]}</span>
                <div className="login">
                    <div className="login-type">

                        <p style={{margin: '20px 0', lineHeight: '21px'}}>{text[36]}</p>

                        <a href={this.props.url} target="_blank" onClick={this.props.activation} className="btn btn-block odnoklassniki">{text[37]}</a>
                    </div>
                    <div className="more-btn btns-box">
                        <button type="button" className="btn border btn-primary" style={{width: '100%'}} onClick={this.props.close}>{text[32]}</button>
                    </div>
                </div>

            </>;

            return render;
        } else {

            let url = <a href={this.props.url} target="_blank" onClick={this.props.activation} className="btn btn-block odnoklassniki no-fastclick">{text[37]}</a>;
            if (initialStore.platform === 'ios' || initialStore.platform === 'android') {
                url = <div onClick={() => {this.props.activation(); window.openUrl(this.props.url)}} className="btn btn-block odnoklassniki no-fastclick no-fastclick-click-css">{text[37]}</div>;
            }

            let render = <>
                <span className="title-box center">{text[35]}</span>
                <div className="login">
                    <div className="login-type">

                        <p style={{margin: '20px 0', lineHeight: '21px'}}>{text[36]}</p>

                        {url}
                    </div>
                    <div className="more-btn btns-box">
                        <button type="button" className="btn border btn-primary" style={{width: '100%'}} onClick={this.props.close}>{text[32]}</button>
                    </div>
                </div>

            </>;


            return render;
        }

    }
}

