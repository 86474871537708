import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import style from "../../../component/menu/index.module.css";
import {animated, config, Spring} from "react-spring/renderprops";
import ButtonUrlPosts from "../../../component/menu/ButtonUrlPosts";
import ButtonLibraryPosts from "../../../component/menu/ButtonLibraryPosts";
import ElementMenuCreatePostProseed from "./proceed";

export default
@inject('initialStore')
@observer
class ElementMenuCreatePost extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.menuCreatePost;

        if (initialStore.templates === 'desktop') {

            if (this.props.collapsed) {
                return <ElementMenuCreatePostProseed openPost={this.props.openPost}/>;
            }
            return <div className="sidebar-control">

                <div className="sidebar-control__nav unselectable">
                    <div className="sidebar-control__add chief sidebar-control__add_viev" onClick={this.props.openPost}>
                <span className="icon-item"><i className="icon icon-control-plus"></i></span>
                <span className="title-item">

                    <Spring
                        from={{ n: 8 }}
                        to={{ n: this.props.openMenuCreatePost ? 0:8}}>
                      {props =>  {

                          if(props.n > 7) {
                              return <>{text.createPost[0]}</>;
                          } else if(props.n >= 7) {
                              return <>{text.createPost[1]}</>;
                          } else if(props.n >= 6) {
                              return <>{text.createPost[2]}</>;
                          } else if(props.n >= 5) {
                              return <>{text.createPost[3]}</>;
                          } else if(props.n >= 4) {
                              return <>{text.createPost[4]}</>;
                          } else if(props.n >= 3) {
                              return <>{text.createPost[5]}</>;
                          } else if(props.n >= 2) {
                              return <>{text.createPost[6]}</>;
                          } else if(props.n >= 1) {
                              return <>{text.createPost[7]}</>;
                          } else {
                              return <>{text.createPost[8]}</>;
                          }

                      }}
                    </Spring>
                </span>
                    </div>
                    <div className={this.props.openMenuCreatePost ? "sidebar-control__arrow is-open" : "sidebar-control__arrow"} onClick={this.props.openAllMenu}>
                        <span className={'sidebar-control__arrow-deli'}></span>
                        <span className="arrow-item">
                            <i className="icon icon-control-arrow"></i>
                        </span>
                    </div>
                </div>

                <Spring native config={config.default}
                        from={{ top: -360/*-380*/ }}
                        to={{ top: this.props.openMenuCreatePost ? 0: -360/*-380*/ }}>
                    {props => <animated.div style={props} className="sidebar-control__drop is-show" ref={this.props.window}>
                        <div className="sidebar-control__add add-drop" onClick={this.props.openStory}>
                            <span className="icon-item"><i className="icon icon-control-plus-story"></i></span>
                            <span className="title-item">{text.story}</span>
                        </div>
                        <div className="sidebar-control__add add-drop" onClick={this.props.openIgtv}>
                            <span className="icon-item"><i className="icon icon-control-plus-igtv"></i></span>
                            <span className="title-item">{text.igtv}</span>
                        </div>
                        <div className="sidebar-control__add add-drop" onClick={this.props.openReels}>
                            <span className="icon-item"><i className="icon icon-control-plus-reels"></i></span>
                            <span className="title-item">{text.reels}</span>
                        </div>

                        <div className="sidebar-control__add add-drop" onClick={this.props.openMultyPost}>
                            <span className="icon-item"><i className="icon icon-control-plus-posts"></i></span>
                            <span className="title-item">{text.multiplePosts}</span>
                        </div>
                        <div className="sidebar-control__add add-drop" onClick={this.props.openMultyStory}>
                            <span className="icon-item"><i className="icon icon-control-plus-storys"></i></span>
                            <span className="title-item">{text.multipleStory}</span>
                        </div>


                        {/*<div className="sidebar-control__add add-drop">
                        <span className="icon-item"><i className="icon icon-control-plus"></i></span>
                        <span className="title-item">Ссылку</span>
                    </div>*/}

                        <ButtonUrlPosts/>

                        <ButtonLibraryPosts/>

                    </animated.div>}
                </Spring>
            </div>;

        } else {


            return (

                <div className={this.props.openMenuCreatePost ? "sidebar-control__drop is-show sidebar-control__drop-open" : "sidebar-control__drop is-show"} ref={this.props.window}>

                    <div
                        className="sidebar-control__add add-drop"
                        onClick={this.props.openPost}
                    >
                        <span className="icon-item"><i className="icon icon-control-plus-mobile"></i></span>
                        <span className="title-item">{text.createPost[8]}</span>
                    </div>

                    <div
                        className="sidebar-control__add add-drop"
                        onClick={this.props.openStory}
                    >
                        <span className="icon-item"><i className="icon icon-control-plus-story"></i></span>
                        <span className="title-item">{text.story}</span>
                    </div>
                    <div
                        className="sidebar-control__add add-drop"
                        onClick={this.props.openIgtv}
                    >
                        <span className="icon-item"><i className="icon icon-control-plus-igtv"></i></span>
                        <span className="title-item">{text.igtv}</span>
                    </div>

                    <div
                        className="sidebar-control__add add-drop"
                        onClick={this.props.openReels}
                    >
                        <span className="icon-item"><i className="icon icon-control-plus-reels"></i></span>
                        <span className="title-item">{text.reels}</span>
                    </div>

                    {/*<div className="sidebar-control__add add-drop">
                        <span className="icon-item"><i className="icon icon-control-plus"></i></span>
                        <span className="title-item">Ссылку</span>
                    </div>*/}

                    <ButtonUrlPosts/>

                    <ButtonLibraryPosts/>

                </div>
            );
        }

    }
}

