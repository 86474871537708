import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

export default
@inject('initialStore')
@observer
class NotificationItem extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        const clickFon = () => {
            if (!this.props.buttonName) {
                this.props.delFunction();
            }
            else {
                return true;
            }
        }

        if (initialStore.templates === 'desktop') {

            return <div key={this.props.key} className="notifications-item" onClick={clickFon}>
                <span className="close-item" onClick={this.props.delFunction}><i className="icon icon-close-notif"></i></span>
                <span className="title-item">{this.props.name}</span>
                {this.props.text ? <p className="desc-item">{this.props.text}</p> : null}
                {this.props.buttonName ? <span className="cancel-item" onClick={this.props.buttonClick}>{this.props.buttonName}</span> :null}
            </div>;

        } else {
            return <div key={this.props.key} style={{cursor: 'pointer'}} className="notifications-item" onClick={clickFon}>
                {this.props.buttonName ? <span className="close-item" onClick={this.props.delFunction}><i className="icon icon-close-notif"></i></span>:null}
                <span className="title-item">{this.props.name}</span>
                {this.props.text ? <p className="desc-item">{this.props.text}</p> : null}
                {this.props.buttonName ? <span className="cancel-item" onClick={this.props.buttonClick}>{this.props.buttonName}</span> :null}
            </div>;
        }

    }
}

