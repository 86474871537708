import React from 'react';
import {inject, observer} from "mobx-react";
import Textbox from "./Textbox";
import TextModel from "../../../model/text";

@inject('accountsStore', 'initialStore', 'mediaStore')
@observer
class Text extends React.Component {

    constructor (props) {
        super(props);

        this.actionTextFullAccount = this.actionTextFullAccount.bind(this);
    }




    render() {
        const mediaStore = this.props.mediaStore;
        const accountsStore = this.props.accountsStore;

        if (mediaStore.type === 'story') {
            return null;
        }
        if (mediaStore.type === 'editStory') {
            return null;
        }

        const accountsOK = mediaStore.accounts.slice();
        let render;

        if (mediaStore.textPro && accountsOK.length) {

            render = [];
            const accounts = accountsStore.list.slice();


            for (let key in accountsOK) {

                let idDB = 0;

                let InstagramTrue = false;
                let VKTrue = false;
                let FBTrue = false;
                let OKTrue = false;
                let TGTrue = false;
                let TwitterTrue = false;
                let FbInstagramTrue = false;

                for (let keys in accounts) {
                    if (accountsOK[key].id === accounts[keys].id) {
                        idDB = keys;

                        if (accounts[keys].tip === 'fb')                FBTrue = true;
                        else if (accounts[keys].tip === 'tg')           TGTrue = true;
                        else if (accounts[keys].tip === 'ok')           OKTrue = true;
                        else if (accounts[keys].tip === 'vk')           VKTrue = true;
                        else if (accounts[keys].tip === 'instagram')    InstagramTrue = true;
                        else if (accounts[keys].tip === 'twitter')      TwitterTrue = true;
                        else if (accounts[keys].tip === 'fb-instagram')      FbInstagramTrue = true;
                    }
                }

                const error = TextModel.limitedText(accountsOK[key].text, accountsOK[key].text+mediaStore.commentText, InstagramTrue, VKTrue, FBTrue, OKTrue, TGTrue, TwitterTrue, FbInstagramTrue).str;

                render.push(
                    <div key={key}>
                        <div className="column-group w-100 postProTextNameBox">
                            <img width='33px' height='33px' src={accounts[idDB].img}/> {accounts[idDB].name}
                        </div>
                        <Textbox text={accountsOK[key].text} textOnChange={(value) => {
                            mediaStore.actionText(key, value);
                            //если 0, сохраняем еще текст в сохраненную публикацию
                            if (key === '0') {
                                mediaStore.actionTextSavePost(value);
                            }
                        }} patternsUser={accountsOK[key].id}/>
                        <span className="count-input">{error}</span>
                    </div>
                );
            }

        } else {

            if (accountsOK.length) {

                //Узнаем какие соц сети есть и показываем лимиты на текст
                let InstagramTrue = false;
                let VKTrue = false;
                let FBTrue = false;
                let OKTrue = false;
                let TGTrue = false;
                let TwitterTrue = false;
                let FbInstagramTrue = false;

                const accounts = accountsStore.list.slice();
                for (const account of mediaStore.accounts.slice()) {
                    for (let keys in accounts) {
                        if (accounts[keys].id === account.id) {
                            if (accounts[keys].tip === 'fb')                FBTrue = true;
                            else if (accounts[keys].tip === 'tg')           TGTrue = true;
                            else if (accounts[keys].tip === 'ok')           OKTrue = true;
                            else if (accounts[keys].tip === 'vk')           VKTrue = true;
                            else if (accounts[keys].tip === 'instagram')    InstagramTrue = true;
                            else if (accounts[keys].tip === 'twitter')      TwitterTrue = true;
                            else if (accounts[keys].tip === 'fb-instagram')      FbInstagramTrue = true;
                        }
                    }
                }

                const error = TextModel.limitedText(accountsOK[0].text, accountsOK[0].text+mediaStore.commentText, InstagramTrue, VKTrue, FBTrue, OKTrue, TGTrue, TwitterTrue, FbInstagramTrue).str;

                render = (
                    <>
                        <Textbox text={accountsOK[0].text} textOnChange={this.actionTextFullAccount}  patternsUser={true}/>
                        <span className="count-input">{error}</span>
                    </>
                    );
            } else {

                const error = TextModel.limitedText(mediaStore.textSavePost, mediaStore.textSavePost+mediaStore.commentText).str;


                render = (
                    <>
                        <Textbox text={mediaStore.textSavePost} textOnChange={(value) => {mediaStore.actionTextSavePost(value)}}  patternsUser={true}/>
                        <span className="count-input">{error}</span>
                    </>
                );

            }

        }

        return render;
    }

    actionTextFullAccount (value) {
        const mediaStore = this.props.mediaStore;

        const accountsOK = mediaStore.accounts.slice();

        for (let key in accountsOK) {
            mediaStore.actionText(key, value);
        }

        mediaStore.actionTextSavePost(value);
    }
}

export default Text;
