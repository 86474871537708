import React from 'react';
import DownloadpostIndex from "../../templates/downloadpost";
import APIinstagram from "../../api/instagram";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

@inject('postStore', 'notificationStore')
@observer
class Downloadpost extends React.Component {

    @observable value = '';

    constructor (props) {
        super(props);

        this.loadPost = this.loadPost.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    render() {
        return <DownloadpostIndex
            action={this.loadPost}
            value={this.value}
            onChange={this.onChange}
        />;
    }

    onChange (value) {
        this.value = value;
    }

    loadPost (resolve) {
        const postStore = this.props.postStore;
        const notificationStore = this.props.notificationStore;

        const url = this.value.split('?')[0];

        APIinstagram.getPost(url).then((data) => {
            console.log('e', data);
            if (!data.status) {
                notificationStore.add(' Ошибка', 'Ссылка не действительна или пост не доступен');
            } else {
                postStore.dateAction({
                    ...data,
                    title: 'Публикация на ' + data.account,
                    url: url,
                    likes: false,
                    comment: false,
                    view: false,
                    time: 0,
                });

                postStore.load = false;
                postStore.open = true;

                this.value = '';
            }




        }).catch((e) => {
            console.log('error', e);
        }).finally(() => {
            resolve(true);
        });
    }

}

export default Downloadpost;