import React from 'react';
import {inject, observer} from "mobx-react";

import {Spring, animated, config} from 'react-spring/renderprops';

import Time from "../../model/time";
import {observable} from "mobx";
import ReactDOM from "react-dom";
import Select from "../../templates/elements/select";
import APIposts from "../../api/posts";
import {createLoadPost} from "../../actions/posts/createLoadPost";

@inject("libraryStore", "modalSearhLibraryPost", "initialStore")
@observer
class ButtonLibraryPosts extends React.Component {

    @observable openMenu = false;
    @observable searhValue = '';
    @observable loadPost = false;

    constructor (props) {
        super(props);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.window = React.createRef();

        this.openWin = false;
    }

    render() {

        const libraryStore = this.props.libraryStore;
        const modalSearhLibraryPost = this.props.modalSearhLibraryPost;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.menuCreatePost;

        let searhElements = null;

        if (!this.openMenu) {
            if (this.openWin) {
                this.openWin=false;
                this.updateStatusCloseFon();
            }
        } else {
            if (!this.openWin) {
                this.openWin=true;
                this.updateStatusCloseFon();
            }
        }

        if (this.openMenu) {

            let listPosts = [];

            for (const element of libraryStore.list.slice()) {
                if (element.post.name === '') {
                    continue;
                }

                if (this.searhValue !== '') {
                    if(element.post.name.toUpperCase().indexOf(this.searhValue.toUpperCase()) + 1) {
                        listPosts.push(<span className="item-result" onClick={() => {this.getInfoPost(element.post.code)}}>{element.post.name}</span>);
                    }
                } else {
                    listPosts.push(<span className="item-result" onClick={() => {this.getInfoPost(element.post.code)}}>{element.post.name}</span>)
                }


            }


            searhElements = (
                <div ref={this.window} className="search-save">
                    <div className="event-search">
                        <div className="chat-search">
                            <span className="chat-search__icon"><i className="icon icon-chat-search"></i></span>
                            <div className="chat-search__input"><input autoFocus placeholder={text.librarySearch} onChange={(e) => {this.onChangeValue(e.target.value)}} value={this.searhValue}/></div>
                        </div>
                        <div className="event-search__result">
                            <div className="inner-result" style={{maxHeight: '145px'}}>
                                {listPosts}
                            </div>
                            <span className="more-result" style={{marginLeft: '10px'}} onClick={this.openModal}>{text.showAll}</span>
                        </div>
                    </div>
                </div>
            );
        }

        let classButton = 'sidebar-control__add add-drop sidebar-control__save is-show';
        if (this.loadPost) {
            classButton = 'sidebar-control__add add-drop sidebar-control__save is-show btn-primary-animate-content loadPostTextRedakt';
        }

        if (this.openMenu) {
            classButton+= ' sidebar-control__add-open-url';
        }

        return  <div className={classButton} onClick={this.open}>
                    <span className="icon-item"><i className="icon icon-control-plus-liberty"></i></span>
                    <span className="title-item">{text.fromPostsLibrary}</span>
                    {searhElements}
                </div>;
    }

    updateStatusCloseFon = () => {
        if (this.openWin) {

            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    getInfoPost = (code) => {

        setTimeout(() => {
            this.openMenu = false;
        }, 0);

        this.loadPost = true;

        APIposts.getInfoPost(false, code).then((data) => {
            if (!data.status) {

                const initialStore = this.props.initialStore;
                const text = initialStore.text.code;

                alert(text[79]);
                return false;
            } else {
                createLoadPost(data.date);
            }
        }).catch((e) => {

        }).finally(() => {
            this.loadPost = false;
        });
    }

    openModal = () => {

        setTimeout(() => {
            this.openMenu = false;
        }, 0);

        const modalSearhLibraryPost = this.props.modalSearhLibraryPost;
        modalSearhLibraryPost.openActive();
    }

    onChangeValue = (value) => {
        console.log(value);
        this.searhValue = value;
    }

    open = () => {
        if (!this.openMenu) {
            this.openMenu = true;
            this.searhValue = '';
        }
    }

    handleClickOutside(e) {

        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов
                //const svgSmileBtn = this.buttonOpenEmoje;

                // Если клик не производился и на кнопку открытия окна смайлов, то скрываем блок.
                //if (!e.path.includes(svgSmileBtn)) {


                        this.openMenu = false;


                //}
            }
        } catch(error) {
            return null;
        }
    }
}

export default ButtonLibraryPosts;
