import React from 'react';
import {inject, observer} from "mobx-react";
import LoadingIcon from "../media/img/content/icons-8-loading.svg";
export default
@inject('initialStore')
@observer
class LoaderComponent extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <div className="loading">
                <div className="loader-animation">
                    <img src={LoadingIcon}/>
                </div>
                {this.props.title ? <div style={{textAlign: 'center', fontSize: '15px', marginTop: '10px', color: '#7b7a7d'}}>{this.props.title}</div>:null}
            </div>;
        } else {
            return <div className="loading">
                <div className="loader-animation">
                    <img src={LoadingIcon}/>
                </div>
                {this.props.title ? <div style={{textAlign: 'center', fontSize: '15px', marginTop: '10px', color: '#7b7a7d'}}>{this.props.title}</div>:null}
            </div>;
        }

    }
}

