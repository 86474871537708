import React from 'react';
import {inject, observer} from "mobx-react";
import ButtonConfirmation from "../../elements/buttonDel";
import Button from "../../elements/button";
import Input from "../../elements/input";
import Checkbox from "../../elements/checkbox";
import ButtonLoad from "../../elements/buttonLoad";

export default
@inject('initialStore')
@observer
class AccountEmployeesAddAndRed extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        if (initialStore.templates === 'desktop') {
            return <div className="settings">
                <div className="back-link"><span className="link" onClick={this.props.actionBek}>Вернуться назад</span></div>
                <div className="chief__typical h1">
                    <h1>{this.props.type === 'add' ? text[35]:text[36]}</h1>
                </div>

                <div className="settings-staff">
                    <div className="settings-staff__form">
                        <div className="form-group">
                            <span className="input-label">{text[37]}</span>
                            <Input onChange={this.props.nameChange}>{this.props.name}</Input>
                        </div>
                        <div className="form-group">
                            <span className="input-label">{text[38]}</span>
                            {this.props.type === 'add' ?
                            <Input onChange={this.props.emailChange}>{this.props.email}</Input>
                            :
                            <div className="form-control"><span className="title-select v-text">{this.props.email}</span></div>
                            }
                        </div>
                        <div className="form-group">
                            <div className="form-check-wrap">
                                <div className="form-check top">
                                    <div className="column-check">
                                        <span className="title-check">{text[39]}</span>
                                        <p className="desc-check">{text[40]}</p>
                                    </div>
                                    <Checkbox onChange={this.props.moderationChange}>{this.props.moderation}</Checkbox>
                                </div>
                                <div className="form-check top">
                                    <div className="column-check">
                                        <span className="title-check">{text[41]}</span>
                                        <p className="desc-check">{text[42]}</p>
                                    </div>
                                    <Checkbox onChange={this.props.urlChange}>{this.props.url}</Checkbox>
                                </div>
                                <div className="form-check top">
                                    <div className="column-check">
                                        <span className="title-check">{text[43]}</span>
                                        <p className="desc-check">{text[44]}</p>
                                    </div>
                                    <Checkbox onChange={this.props.historyChange}>{this.props.history}</Checkbox>
                                </div>
                                <div className="form-check top">
                                    <div className="column-check">
                                        <span className="title-check">{text[45]}</span>
                                        <p className="desc-check">{text[46]}</p>
                                    </div>
                                    <Checkbox onChange={this.props.view_postChange}>{this.props.view_post}</Checkbox>
                                </div>
                                <div className="form-check top">
                                    <div className="column-check">
                                        <span
                                            className="title-check">{text[47]}</span>
                                        <p className="desc-check">{text[48]}</p>
                                    </div>
                                    <Checkbox onChange={this.props.editing_postChange}>{this.props.editing_post}</Checkbox>
                                </div>
                                <div className="form-check top">
                                    <div className="column-check">
                                        <span className="title-check">{text[49]}</span>
                                        <p className="desc-check">{text[50]}</p>
                                    </div>
                                    <Checkbox onChange={this.props.statisticsChange}>{this.props.statistics}</Checkbox>
                                </div>
                                <div className="form-check top">
                                    <div className="column-check">
                                        <span className="title-check">{text[51]}</span>
                                        <p className="desc-check">{text[52]}</p>
                                    </div>
                                    <Checkbox onChange={this.props.directChange}>{this.props.direct}</Checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="more-btn bottom">
                        <ButtonLoad color action={this.props.enter}>{this.props.type === 'add' ? 'accountEmployeeAddAccount':'accountEmployeeRedAccount'}</ButtonLoad>
                        <Button action={this.props.actionBek}>cancellation</Button>
                    </div>
                </div>
            </div>;
        } else {
            return <div className="settings">
                <div className="back-link"><span className="link" onClick={this.props.actionBek}>{text[53]}</span></div>
                <div className="chief__typical h1">
                    <h1>{this.props.type === 'add' ? text[35]:text[36]}</h1>
                </div>

                <div className="settings-staff">
                    <div className="settings-staff__form">
                        <div className="form-group">
                            <span className="input-label">{text[37]}</span>
                            <Input onChange={this.props.nameChange}>{this.props.name}</Input>
                        </div>
                        <div className="form-group">
                            <span className="input-label">{text[38]}</span>
                            {this.props.type === 'add' ?
                                <Input onChange={this.props.emailChange}>{this.props.email}</Input>
                                :
                                <div className="form-control"><span className="title-select">{this.props.email}</span></div>
                            }
                        </div>
                        <div className="form-group">
                            <div className="form-check-wrap">
                                <div className="form-check top">
                                    <div className="column-check">
                                        <span className="title-check">{text[39]}</span>
                                        <p className="desc-check">{text[40]}</p>
                                    </div>
                                    <Checkbox onChange={this.props.moderationChange}>{this.props.moderation}</Checkbox>
                                </div>
                                <div className="form-check top">
                                    <div className="column-check">
                                        <span className="title-check">{text[41]}</span>
                                        <p className="desc-check">{text[42]}</p>
                                    </div>
                                    <Checkbox onChange={this.props.urlChange}>{this.props.url}</Checkbox>
                                </div>
                                <div className="form-check top">
                                    <div className="column-check">
                                        <span className="title-check">{text[43]}</span>
                                        <p className="desc-check">{text[44]}</p>
                                    </div>
                                    <Checkbox onChange={this.props.historyChange}>{this.props.history}</Checkbox>
                                </div>
                                <div className="form-check top">
                                    <div className="column-check">
                                        <span className="title-check">{text[45]}</span>
                                        <p className="desc-check">{text[46]}</p>
                                    </div>
                                    <Checkbox onChange={this.props.view_postChange}>{this.props.view_post}</Checkbox>
                                </div>
                                <div className="form-check top">
                                    <div className="column-check">
                                        <span
                                            className="title-check">{text[47]}</span>
                                        <p className="desc-check">{text[48]}</p>
                                    </div>
                                    <Checkbox onChange={this.props.editing_postChange}>{this.props.editing_post}</Checkbox>
                                </div>
                                <div className="form-check top">
                                    <div className="column-check">
                                        <span className="title-check">{text[49]}</span>
                                        <p className="desc-check">{text[50]}</p>
                                    </div>
                                    <Checkbox onChange={this.props.statisticsChange}>{this.props.statistics}</Checkbox>
                                </div>
                                <div className="form-check top">
                                    <div className="column-check">
                                        <span className="title-check">{text[51]}</span>
                                        <p className="desc-check">{text[52]}</p>
                                    </div>
                                    <Checkbox onChange={this.props.directChange}>{this.props.direct}</Checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="more-btn bottom">
                        <ButtonLoad color action={this.props.enter}>{this.props.type === 'add' ? 'accountEmployeeAddAccount':'accountEmployeeRedAccount'}</ButtonLoad>
                        <Button action={this.props.actionBek}>cancellation</Button>
                    </div>
                </div>
            </div>;
        }
    }
}