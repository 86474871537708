import React from 'react';

import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ReactDOM from "react-dom";

@inject('editorStore', 'initialStore')
@observer
class MediaRedBQuizTemplate extends React.Component {

    @observable openMenu = false;

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;


        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.window = React.createRef();

        this.openHistory = false;

    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        if (this.openMenu && !this.openHistory) {
            this.updateStatusCloseFon();
            this.openHistory = true;
        }

        if (!this.openMenu && this.openHistory) {
            this.updateStatusCloseFon();
            this.openHistory = false;
        }



        let classes = this.openMenu ? "event-menu g-center v-bottom show botton-element-color-box" : "event-menu g-center v-bottom show botton-element-color-box event-menu-none";

        const fonts = [
            {ket: 0, value: text[177]},
            {ket: 1, value: text[178]},
            {ket: 2, value: text[179]},
            {ket: 3, value: text[180]},
            {ket: 4, value: text[181]},
            {ket: 5, value: text[182]},
            {ket: 6, value: text[183]},
            {ket: 7, value: text[184]},
            {ket: 8, value: text[185]},
            {ket: 9, value: text[186]},
            {ket: 10, value: text[187]},
        ];

        const elemensColors = [];
        for (let element of fonts) {
            elemensColors.push(<span className="event-item" onClick={() => this.actiontemplate(element.ket)}>{element.value}</span>);
        }

        return (
            <>
                <div className="item-type">
                    <button type="button" className="btn red-button-color-text" onClick={this.open}></button>

                    <div className={classes} style={{width: '200px', marginLeft: '-100px'}}>
                        <div className="event-colors" ref={this.window}>
                            {elemensColors}
                        </div>
                    </div>

                </div>
            </>
        );
    }

    actiontemplate = (num) => {
        this.fabric.elementRedQuiz(false, false, false, false, false, false, num);
        this.openMenu = false;
    }

    open = () => {
        if (!this.openMenu) {
            this.openMenu = true;
        }
        //this.openMenu = !this.openMenu;
    }



    updateStatusCloseFon = () => {
        if (this.openMenu) {
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                if (this.openMenu !== false) {
                    this.openMenu = false;
                }
            }
        } catch(error) {
            return null;
        }
    }
}

export default MediaRedBQuizTemplate;