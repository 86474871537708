import qs from "qs";
import React from "react";
import instance from "./base";


const APIdirect = {

    index (account) {

        const jsons = JSON.stringify(account);

        let formData = new FormData();
        formData.append('data', jsons);

        return instance.post('direct2',
            formData
        ).then(data => {
            return data;
        });
    },
    nextdialog (account, thread_id) {

        const jsons = JSON.stringify({
            account: account,
            thread_id: thread_id,
        });

        let formData = new FormData();
        formData.append('data', jsons);

        return instance.post('direct2/nextdialog',
            formData
        ).then(data => {
            return data;
        });
    },
    nextlist (account) {

        const jsons = JSON.stringify({
            account: account,
        });

        let formData = new FormData();
        formData.append('data', jsons);

        return instance.post('direct2/nextlist',
            formData
        ).then(data => {
            return data;
        });
    },

    sending_message (account, dialog, text) {

        const jsons = JSON.stringify({
            account: account,
            dialog: dialog,
            text: text,
        });

        let formData = new FormData();
        formData.append('data', jsons);

        return instance.post('direct2/sending_message',
            formData
        ).then(data => {
            return data;
        });
    },

    sending_media (account, dialog, type, file) {

        const jsons = JSON.stringify({
            account: account,
            dialog: dialog,
            type: type,
        });

        let formData = new FormData();
        formData.append('file', file);
        formData.append('data', jsons);

        return instance.post( 'direct2/sending_media',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }
        );
    },


    sending_approvependingthreads (account, dialog) {

        const jsons = JSON.stringify({
            account: account,
            dialog: dialog,
        });

        let formData = new FormData();
        formData.append('data', jsons);

        return instance.post('direct2/approvependingthreads',
            formData
        ).then(data => {
            return data;
        });
    },
    sending_declinependingthreads (account, dialog) {

        const jsons = JSON.stringify({
            account: account,
            dialog: dialog,
        });

        let formData = new FormData();
        formData.append('data', jsons);

        return instance.post('direct2/declinependingthreads',
            formData
        ).then(data => {
            return data;
        });
    },

    sending_view (account, dialog, item) {

        const jsons = JSON.stringify({
            account: account,
            dialog: dialog,
            item: item,
        });

        let formData = new FormData();
        formData.append('data', jsons);

        return instance.post('direct2/view',
            formData
        ).then(data => {
            return data;
        });
    },




}

export default APIdirect;