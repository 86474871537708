import qs from "qs";
import React from "react";

import instance from "./base";
import axios from "axios";

const typeServer = true;

const APIinstagram = {

    getPost (url) {

        const installPost = (data) => {

            if (data.status !== 200) {
                return {
                    status: false,
                    code: 0,
                }
            }

            if (!data.data.graphql.shortcode_media) {

                return {
                    status: false,
                    code: 1,
                }
            }



            const post = data.data.graphql.shortcode_media;

            let typePost = 'image';
            if (post.__typename === 'GraphVideo') {
                typePost = 'video';
            }



            if (post.__typename === 'GraphSidecar') {
                typePost = 'gallery';
            }

            if (post.product_type) {
                if (post.product_type === 'igtv') {
                    typePost = 'igtv';
                } else if (post.product_type === 'clips') {
                    typePost = 'reels';
                }
            }

            let name = '';

            if (typePost === 'igtv') {
                name = post.title;
            }

            const account = post.owner.username;

            let text = '';
            if (post.edge_media_to_caption.edges[0]) {
                text = post.edge_media_to_caption.edges[0].node.text;
            }


            const media = [];

            //получаем медиа
            if (post.__typename === 'GraphVideo') {

                media.push({
                    tip: 'video',
                    urlCover: post.display_url,
                    urlVideo: post.video_url,
                    proportions: (post.dimensions.width / post.dimensions.height).toFixed(2), //округление до сотых
                });
            } else if (post.__typename === 'GraphImage') {
                media.push({
                    tip: 'image',
                    urlImage: post.display_url,
                    proportions: (post.dimensions.width / post.dimensions.height).toFixed(2), //округление до сотых
                });

            } else if (post.__typename === 'GraphSidecar') {

                const medias = post.edge_sidecar_to_children.edges;


                for (const mediasElement of medias) {

                    if (mediasElement.node.__typename === 'GraphImage') {
                        media.push({
                            tip: 'image',
                            urlImage: mediasElement.node.display_url,
                            proportions: (mediasElement.node.dimensions.width / mediasElement.node.dimensions.height).toFixed(2), //округление до сотых
                        });
                    } else {
                        media.push({
                            tip: 'video',
                            urlCover: mediasElement.node.display_url,
                            urlVideo: mediasElement.node.video_url,
                            proportions: (mediasElement.node.dimensions.width / mediasElement.node.dimensions.height).toFixed(2), //округление до сотых
                        });
                    }


                }
            }

            let alert = {
                type: typePost,
                account: account,
                text: text,
                media: media,
                name: name,
            }

            return {
                status: true,
                ...alert,
            }
        }


        return instance.post('instagram/post', qs.stringify({
            url: url,
            v: 2,
        })).then(data => {

            if (!data.data.graphql.shortcode_media) {

                return instance.post('instagram/post', qs.stringify({
                    url: url,
                    v: 2,
                })).then(installPost).catch((e) => {
                    return {
                        status: false,
                        code: 404,
                    };
                });

            } else {
                return installPost(data);
            }
        }).catch((e) => {

            return instance.post('instagram/post', qs.stringify({
                url: url,
                v: 2,
            })).then(installPost).catch((e) => {
                return {
                    status: false,
                    code: 404,
                };
            });
        });

    },

    getGeo (name) {

        const installGeo = (data) => {
            if (data.status !== 200) {
                return {
                    status: false,
                    info: 'Non connect Instagram, code: 1',
                }
            }

            if (data.data.status !== 'ok') {
                return {
                    status: false,
                    info: 'Non connect Instagram, code: 2 (Такая проблема бывает из за блокиировки IP адреса со стороны Instagram , попробуйте отключить VPN или перезагрузиить роутер что бы сменить IP адрес)',
                }
            }

            if (!data.data.places) {
                return {
                    status: false,
                    info: 'Non connect Instagram, code: 3',
                }
            }


            let arrayGEO = [];
            for (const place of data.data.places) {

                let address = place.place.location.address;
                if (place.place.location.city !== '') {
                    address+= ' ' + place.place.location.city;
                }

                arrayGEO.push({
                    lat: place.place.location.lat,
                    lng: place.place.location.lng,
                    name: place.place.location.name,
                    NAME: place.place.location.name,
                    address: address,
                    id: place.place.location.facebook_places_id,
                })
            }

            return {
                status: true,
                return: arrayGEO,
            }
        };

        /*return axios.get("https://www.instagram.com/web/search/topsearch/",{
            params: {
                context: 'place',
                query: name,
            },*/
        return instance.post('instagram/geo', qs.stringify({
            query: name,
        })).then(data => {

            if (data.data.status !== 'ok') {
                return instance.post('instagram/geo', qs.stringify({
                    query: name,
                })).then(installGeo).catch((e) => {
                    return {
                        status: false,
                        info: 'Non connect Instagram, code: 4',
                    };
                });
            } else {
                return installGeo(data);
            }

        }).catch((e) => {
            return instance.post('instagram/geo', qs.stringify({
                query: name,
            })).then(installGeo).catch((e) => {
                return {
                    status: false,
                    info: 'Non connect Instagram, code: 4',
                };
            });
        });
    },

    getUsers (name, idSearh) {

        const installUsers = (data) => {
            if (data.status !== 200) {
                return {
                    status: false,
                    info: 'Non connect Instagram, code: 1',
                    id: idSearh,
                }
            }

            if (data.data.status !== 'ok') {
                return {
                    status: false,
                    info: 'Non connect Instagram, code: 2',
                    id: idSearh,
                }
            }

            if (!data.data.places) {
                return {
                    status: false,
                    info: 'Non connect Instagram, code: 3',
                    id: idSearh,
                }
            }


            let arrayUsers = [];
            for (const users of data.data.users) {

                arrayUsers.push({
                    pk: users.user.pk,
                    username: users.user.username,
                    full_name: users.user.full_name,
                    img: users.user.profile_pic_url,

                })
            }

            return {
                status: true,
                return: arrayUsers,
                id: idSearh,
            }
        }

        /*return axios.get("https://www.instagram.com/web/search/topsearch/",{
            params: {
                context: 'user',
                query: name,
            },

        })*/
        return instance.post('instagram/users', qs.stringify({
            query: name,
        })).then(data => {

            if (data.data.status !== 'ok') {
                return instance.post('instagram/users', qs.stringify({
                    query: name,
                })).then(installUsers).catch((e) => {
                    return {
                        status: false,
                        info: 'Non connect Instagram, code: 4',
                    };
                });
            } else {
                return installUsers(data);
            }

        }).catch((e) => {
            return instance.post('instagram/users', qs.stringify({
                query: name,
            })).then(installUsers).catch((e) => {
                return {
                    status: false,
                    info: 'Non connect Instagram, code: 4',
                };
            });
        });
    },

    getHashtags (name, idSearh) {

        const installHashtags = (data) => {
            if (data.status !== 200) {
                return {
                    status: false,
                    info: 'Non connect Instagram, code: 1',
                    id: idSearh,
                }
            }

            if (data.data.status !== 'ok') {
                return {
                    status: false,
                    info: 'Non connect Instagram, code: 2',
                    id: idSearh,
                }
            }

            if (!data.data.hashtags) {
                return {
                    status: false,
                    info: 'Non connect Instagram, code: 3',
                    id: idSearh,
                }
            }


            let arrayHashtagrs = [];
            for (const hashtags of data.data.hashtags) {

                arrayHashtagrs.push({
                    name: hashtags.hashtag.name,
                    subtitle: hashtags.hashtag.search_result_subtitle,
                    img: hashtags.hashtag.profile_pic_url,

                })
            }

            return {
                status: true,
                return: arrayHashtagrs,
                id: idSearh,
            }
        };

        /*return axios.get("https://www.instagram.com/web/search/topsearch/",{
            params: {
                context: 'hashtag',
                query: name,
            },
        })*/
        return instance.post('instagram/hashtags', qs.stringify({
            query: name,
        })).then(data => {

            if (data.data.status !== 'ok') {
                return instance.post('instagram/hashtags', qs.stringify({
                    query: name,
                })).then(installHashtags).catch((e) => {
                    return {
                        status: false,
                        info: 'Non connect Instagram, code: 4',
                    };
                });
            } else {
                return installHashtags(data);
            }

        }).catch((e) => {
            return instance.post('instagram/hashtags', qs.stringify({
                query: name,
            })).then(installHashtags).catch((e) => {
                return {
                    status: false,
                    info: 'Non connect Instagram, code: 4',
                };
            });
        });
    },
}

export default APIinstagram;
