import React from 'react';
import {inject, observer} from "mobx-react";
import ButtonOpenMenuMobile from "../../../component/menu/ButtonOpenMenuMobile";
import MenuCreatePostButtonMobile from "../../../component/menu/menuCreatePostButtonMobile";
import Header from "../../../component/header/Header";
import {autorun} from "mobx";

export default
@inject('initialStore', 'menuStore', 'accountsStore')
@observer
class MenuMobileTop extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const initialStore = this.props.initialStore;


        /*const style = {
            top: ((-initialStore.mobileOpacityTop) * -60) - 60 + 'px',
        }


        let opacity = 1;
        if (initialStore.mobileOpacityTop <= 0.7) {
            opacity = 0;
        } else { //0.85 = 0.5
            const ostatok = 1-((1-initialStore.mobileOpacityTop) / 0.3);//0.15

            opacity = ostatok;
        }

        const style2 = {
            opacity: opacity,
        }*/

        const style = null;
        const style2 = null;

        let classSidebar = "sidebar";

        if (!initialStore.mobileOpacityTop) {
             classSidebar = "sidebar sidebar-close";
        }

        return <aside className={classSidebar} style={style}>
            <div className="sidebar-control">
                <div className="sidebar-control__nav" style={style2}>

                    <ButtonOpenMenuMobile/>

                    <MenuCreatePostButtonMobile/>

                    <Header/>
                </div>
            </div>

        </aside>;
    }
}

