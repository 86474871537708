import React from 'react';
import {inject, observer} from "mobx-react";

import {Spring, animated, config} from 'react-spring/renderprops';

import Time from "../../model/time";
import {observable} from "mobx";
import ReactDOM from "react-dom";
import Select from "../../templates/elements/select";
import APIposts from "../../api/posts";
import {createLoadPost} from "../../actions/posts/createLoadPost";
import APIinstagram from "../../api/instagram";
import MenuElementButtonUrlPosts from "../../templates/elements/menu/elementButtonUrlPosts";
import APIurl from "../../api/url";

@inject("libraryStore", "modalSearhUrlPost", "notificationStore", "initialStore")
@observer
class ButtonUrlPosts extends React.Component {

    @observable openMenu = false;
    @observable searhValue = '';
    @observable load = false;

    constructor (props) {
        super(props);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.window = React.createRef();

        this.openWin = false;
    }

    render() {

        if (!this.openMenu) {
            if (this.openWin) {
                this.openWin=false;
                this.updateStatusCloseFon();
            }
        } else {
            if (!this.openWin) {
                this.openWin=true;
                this.updateStatusCloseFon();
            }
        }

        return <MenuElementButtonUrlPosts
                    openMenu={this.openMenu}
                    load={this.load}
                    ref={this.window}
                    onChangeValue={this.onChangeValue}
                    searhValue={this.searhValue}
                    openModal={this.openModal}
                    open={this.open}
        />
    }

    onChangeValue = (value) => {

        if (this.load) {
            return false;
        }
        this.searhValue = value;

        const instagram = 'instagram.com/';
        const likepro = 'likepro.ru/p/';
        const vkontakte = 'vk.com/';
        const odnoklassniki = 'ok.ru/';

        //проверяем ссылку на сайт
        if(value.toUpperCase().indexOf(likepro.toUpperCase()) + 1) {
            const infoUrl = this.parseUrl(value);

            if (infoUrl.length > 2) {
                const url = infoUrl[2].split('_');
                const code = url[0];
                let id = false;
                if (url.length > 1) {
                    id = url[1];
                }
                this.getInfoPost(code, id);
            }
        }

        //проверяем ссылку на инстаграм
        if(value.toUpperCase().indexOf(instagram.toUpperCase()) + 1) {

            const infoUrl = this.parseUrl(value);

            if (infoUrl.length > 2) {
                if (infoUrl[2] === 'p') {
                    infoUrl[2] = infoUrl[3];
                }
                if (infoUrl[2] === 'tv') {
                    infoUrl[2] = infoUrl[3];
                }

                if (infoUrl[2] === 'reel') {
                    infoUrl[2] = infoUrl[3];
                }

                this.getPostIstagram(infoUrl[2]);
            }
            //debugger;
        }

        //проверяем ссылку на ВКонтакте
        if(value.toUpperCase().indexOf(vkontakte.toUpperCase()) + 1) {

            //const infoUrl = this.parseUrl(value);
            this.getPostVK(value);

        }

        //проверяем ссылку на Одноклассники
        if(value.toUpperCase().indexOf(odnoklassniki.toUpperCase()) + 1) {

            //const infoUrl = this.parseUrl(value);
            this.getPostOK(value);

        }
    }

    getPostOK = (url) => {
        this.load = true;

        APIurl.getOk(url).then((data) => {

            if (data.status) {

                let type = 'post';

                const media = [];
                let numElement = 0;
                for (const element of data.media) {

                    if (element.tip === 'video') {

                        media.push({
                            id: numElement,
                            type: 'video',
                            proportions: false,
                            proportionsBackground: false,
                            zoom: 1,
                            crop: {
                                unit: "%",
                                x: 0,
                                y: 0,
                                width: 100,
                                height: 100,
                                proportions: false,
                            },
                            draggable: {x:0,y:0},
                            duration: false,
                            color: "#ffffff",
                            url: element.urlVideo,
                            elements: {
                                video: [],
                                image: [],
                            },
                        });
                    } else {
                        media.push({
                            id: numElement,
                            type: 'image',
                            proportions: element.proportions,
                            proportionsBackground: element.proportions,
                            zoom: 1,
                            crop: {
                                unit: "%",
                                x: 0,
                                y: 0,
                                width: 100,
                                height: 100,
                                proportions: element.proportions
                            },
                            accounts: [],
                            draggable: {x:0, y:0},
                            color: "#ffffff",
                            url: element.urlImage,
                            elements: {
                                video: [],
                                image: [],
                            },
                        });
                    }

                    numElement++;
                }

                let name = '';

                const array = {
                    type: type,
                    media: media,
                    text: data.text,
                    coment: "",
                    geo: false,
                    commentOff: false,
                    comment: false,
                    commentText: '',
                    category: 0,
                    name: name,
                };



                createLoadPost(array);

            } else {
                const notificationStore = this.props.notificationStore;
                const initialStore = this.props.initialStore;
                const text = initialStore.text.code;

                notificationStore.add(text[28], text[data.code]);

                return false;
            }

        }).catch((error) => {
            alert('error load post VK');
        }).finally(() => {
            this.load = false;
        });
    }

    getPostVK = (url) => {
        this.load = true;

        APIurl.getVk(url).then((data) => {

            if (data.status) {

                let type = 'post';

                const media = [];
                let numElement = 0;
                for (const element of data.media) {

                    if (element.tip === 'video') {

                        media.push({
                            id: numElement,
                            type: 'video',
                            proportions: false,
                            proportionsBackground: false,
                            zoom: 1,
                            crop: {
                                unit: "%",
                                x: 0,
                                y: 0,
                                width: 100,
                                height: 100,
                                proportions: false,
                            },
                            draggable: {x:0,y:0},
                            duration: false,
                            color: "#ffffff",
                            url: element.urlVideo,
                            elements: {
                                video: [],
                                image: [],
                            },
                        });
                    } else {
                        media.push({
                            id: numElement,
                            type: 'image',
                            proportions: element.proportions,
                            proportionsBackground: element.proportions,
                            zoom: 1,
                            crop: {
                                unit: "%",
                                x: 0,
                                y: 0,
                                width: 100,
                                height: 100,
                                proportions: element.proportions
                            },
                            accounts: [],
                            draggable: {x:0, y:0},
                            color: "#ffffff",
                            url: element.urlImage,
                            elements: {
                                video: [],
                                image: [],
                            },
                        });
                    }

                    numElement++;
                }

                let name = '';

                const array = {
                    type: type,
                    media: media,
                    text: data.text,
                    coment: "",
                    geo: false,
                    commentOff: false,
                    comment: false,
                    commentText: '',
                    category: 0,
                    name: name,
                };



                createLoadPost(array);

            } else {
                const notificationStore = this.props.notificationStore;
                const initialStore = this.props.initialStore;
                const text = initialStore.text.code;

                notificationStore.add(text[28], text[data.code]);

                return false;
            }

        }).catch((error) => {
            alert('error load post VK');
        }).finally(() => {
            this.load = false;
        });
    }

    getPostIstagram = (code) => {
        this.load = true;

        const url = 'https://www.instagram.com/p/' + code;

        APIinstagram.getPost(url).then((data) => {

            if (data.status) {

                let type = 'post';

                if (data.type === 'igtv') {
                    type = 'igtv';
                }

                if (data.type === 'reels') {
                    type = 'reels';
                }

                const media = [];
                let numElement = 0;
                for (const element of data.media) {



                    if (element.tip === 'video') {

                        media.push({
                            id: numElement,
                            type: 'video',
                            proportions: false,
                            proportionsBackground: false,
                            zoom: 1,
                            crop: {
                                unit: "%",
                                x: 0,
                                y: 0,
                                width: 100,
                                height: 100,
                                proportions: false,
                            },
                            accounts: [],
                            draggable: {x: 0, y: 0},
                            duration: false,
                            color: "#ffffff",
                            url: element.urlVideo,
                            urlCover: element.urlCover ? element.urlCover:false,
                            elements: {
                                video: [],
                                image: [],
                            },
                        });
                    } else {
                        media.push({
                            id: numElement,
                            type: 'image',
                            proportions: element.proportions,
                            proportionsBackground: element.proportions,
                            zoom: 1,
                            crop: {
                                unit: "%",
                                x: 0,
                                y: 0,
                                width: 100,
                                height: 100,
                                proportions: element.proportions
                            },
                            accounts: [],
                            draggable: {x: 0, y: 0},
                            color: "#ffffff",
                            url: element.urlImage,
                            elements: {
                                video: [],
                                image: [],
                            },
                        });
                    }

                    numElement++;
                }

                let name = '';

                if (data.type === 'igtv') {
                    name = data.name;
                }

                const array = {
                    type: type,
                    media: media,
                    text: data.text,
                    coment: "",
                    geo: false,
                    commentOff: false,
                    comment: false,
                    commentText: '',
                    category: 0,
                    name: name,
                };

                createLoadPost(array);

            } else {
                const notificationStore = this.props.notificationStore;
                const initialStore = this.props.initialStore;
                const text = initialStore.text.code;

                notificationStore.add(text[28], text[78]);

                return false;
            }

        }).catch((e) => {

        }).finally(() => {
            this.load = false;
        });
    }



    getInfoPost = (code, id) => {

        this.load = true;

        setTimeout(() => {
            this.openMenu = false;
        }, 0);

        this.loadPost = true;

        APIposts.getInfoPost(id, code).then((data) => {
            if (!data.status) {
                const initialStore = this.props.initialStore;
                const text = initialStore.text.code;
                alert(text[79]);
                return false;
            } else {
                createLoadPost(data.date);

            }
        }).catch((e) => {

        }).finally(() => {
            this.load = false;
        });
    }

    parseUrl = (url) => {
        const element = document.createElement('a');
        element.href = url;

        return element.pathname.split('/');
    }

    openModal = () => {

        setTimeout(() => {
            this.openMenu = false;
        }, 0);

        const modalSearhUrlPost = this.props.modalSearhUrlPost;
        modalSearhUrlPost.openActive();
    }

    open = () => {
        if (!this.openMenu) {
            this.openMenu = true;
            this.searhValue = '';
        }
    }

    updateStatusCloseFon = () => {
        if (this.openWin) {
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {
        console.log(12);
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов
                ///const svgSmileBtn = this.buttonOpenEmoje;

                // Если клик не производился и на кнопку открытия окна смайлов, то скрываем блок.
                ///if (!e.path.includes(svgSmileBtn)) {

                    if (this.openMenu) {
                        this.openMenu = false;
                    }

                //}
            }
        } catch(error) {
            return null;
        }
    }
}

export default ButtonUrlPosts;
