import React from 'react';
import {inject, observer} from "mobx-react";
import directLoad from "../../../../media/gif/directLoad.gif";


export default
@inject('initialStore')
@observer
class AccountDirectMessageProfile extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {

            let content = [];
            for (const preview_image of this.props.contentImage) {
                content.push(<div className="item-gallery"><img src={preview_image} width={117} height={110}/></div>);
            }

            return (
                <a href={this.props.url} target="_blank" className="chat-message__inner">
                    <div className="chat-message__post">
                        <div className="user-post">
                            <div className="img"><img src={this.props.avatar} width={32} height={32}/></div>
                            <div className="cnt">
                                <span className="name">{this.props.username}</span>
                                <span className="position">{this.props.full_name}</span>
                            </div>
                        </div>
                        <div className="gallery-post">
                            {content}
                        </div>
                    </div>
                    <time dateTime={this.props.time} className="chat-message__date">{this.props.time}</time>
                </a>
            );
        } else {
            let content = [];
            for (const preview_image of this.props.contentImage) {
                content.push(<div className="item-gallery"><img src={preview_image} width={117} height={110}/></div>);
            }

            return (
                <a href={this.props.url} target="_blank" className="chat-message__inner">
                    <div className="chat-message__post">
                        <div className="user-post">
                            <div className="img"><img src={this.props.avatar} width={32} height={32}/></div>
                            <div className="cnt">
                                <span className="name">{this.props.username}</span>
                                <span className="position">{this.props.full_name}</span>
                            </div>
                        </div>
                        <div className="gallery-post">
                            {content}
                        </div>
                    </div>
                    <time dateTime={this.props.time} className="chat-message__date">{this.props.time}</time>
                </a>
            );
        }
    }

}