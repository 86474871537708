import React from 'react';
import {inject, observer} from "mobx-react";
import directLoad from "../../../../media/gif/directLoad.gif";


export default
@inject('initialStore')
@observer
class AccountDirectMessageLink extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {


            return (
                <>
                {this.props.url ?
                    <a href={this.props.url} target="_blank" className="chat-message__inner">
                        <div className="inner-content">
                            <div className="column-content">
                                <p className="chat-message__text">{this.props.text}</p>
                                {this.props.urlName ? <span className="chat-message__help">{this.props.urlName}</span>:null}
                                {this.props.urlText ? <span className="chat-message__help">{this.props.urlText}</span>:null}
                            </div>
                            <div className="column-content date">
                                <time dateTime={this.props.time} className="chat-message__date static">{this.props.time}</time>
                            </div>
                        </div>
                    </a>
                :
                    <div className="chat-message__inner">
                        <div className="inner-content">
                            <div className="column-content">
                                <p className="chat-message__text">{this.props.text}</p>
                                {this.props.urlName ? <span className="chat-message__help">{this.props.urlName}</span>:null}
                                {this.props.urlText ? <span className="chat-message__help">{this.props.urlText}</span>:null}
                            </div>
                            <div className="column-content date">
                                <time dateTime={this.props.time} className="chat-message__date static">{this.props.time}</time>
                            </div>
                        </div>
                    </div>
                }
                </>
            );
        } else {
            return (
                <>
                    {this.props.url ?
                        <a href={this.props.url} target="_blank" className="chat-message__inner">
                            <div className="inner-content">
                                <div className="column-content">
                                    <p className="chat-message__text">{this.props.text}</p>
                                    {this.props.urlName ? <span className="chat-message__help">{this.props.urlName}</span>:null}
                                    {this.props.urlText ? <span className="chat-message__help">{this.props.urlText}</span>:null}
                                </div>
                                <div className="column-content date">
                                    <time dateTime={this.props.time} className="chat-message__date static">{this.props.time}</time>
                                </div>
                            </div>
                        </a>
                        :
                        <div className="chat-message__inner">
                            <div className="inner-content">
                                <div className="column-content">
                                    <p className="chat-message__text">{this.props.text}</p>
                                    {this.props.urlName ? <span className="chat-message__help">{this.props.urlName}</span>:null}
                                    {this.props.urlText ? <span className="chat-message__help">{this.props.urlText}</span>:null}
                                </div>
                                <div className="column-content date">
                                    <time dateTime={this.props.time} className="chat-message__date static">{this.props.time}</time>
                                </div>
                            </div>
                        </div>
                    }
                </>
            );
        }
    }

}