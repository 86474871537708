import React from 'react';
import {inject, observer} from "mobx-react";

import ym from 'react-yandex-metrika';

import TemplateModalAddAccountInstagram from "../../templates/modal/addAccount/templateModalAddAccountInstagram";
import APIaccounts from "../../api/accounts";
import TemplateModalAddAccountInstagramTwoFactorCode from "../../templates/modal/addAccount/templateModalAddAccountInstagramTwoFactorCode";
import APIuser from "../../api/user";
import TemplateModalAddAccountInstagramVerificationTip
    from "../../templates/modal/addAccount/templateModalAddAccountInstagramVerificationTip";
import TemplateModalAddAccountInstagramCode
    from "../../templates/modal/addAccount/templateModalAddAccountInstagramCode";
import TemplateModalAddAccountInstagramRe from "../../templates/modal/addAccount/templateModalAddAccountInstagramRe";
import {observable} from "mobx";

@inject('modalAddAccount', 'notificationStore', 'initialStore', 'accountsStore')
@observer
class ModalAddAccountInstagram extends React.Component {

    @observable loadContent = false;

    constructor (props) {
        super(props);
        this.close = true;
    }

    render() {
        const modalAddAccount = this.props.modalAddAccount;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;

        let content = null;
        if (modalAddAccount.level === 4) {
            content = (
                <TemplateModalAddAccountInstagramRe
                    openProxy={modalAddAccount.openProxy}
                    login={modalAddAccount.login}
                    actionLogin={this.actionLogin}
                    password={modalAddAccount.password}
                    actionPassword={this.actionPassword}
                    actionOK={this.actionOK}
                    close={this.actionClose}
                    load={this.loadContent}
                />
            );
        } else if (modalAddAccount.level === 0) {
            //ввод логина и пароля
            content = (
                <TemplateModalAddAccountInstagram
                    openProxy={modalAddAccount.openProxy}
                    actionOpenProxy={() => {if(!this.loadContent) modalAddAccount.actionOpenProxy();}}
                    login={modalAddAccount.login}
                    actionLogin={this.actionLogin}
                    password={modalAddAccount.password}
                    actionPassword={this.actionPassword}
                    actionOK={this.actionOK}
                    close={this.actionClose}

                    proxyIP={modalAddAccount.proxyIP}
                    proxyPort={modalAddAccount.proxyPort}
                    proxyLogin={modalAddAccount.proxyLogin}
                    proxyPassword={modalAddAccount.proxyPassword}

                    actionProxyIP={(value) => this.actionRedProxyInput('ip', value)}
                    actionProxyPort={(value) => this.actionRedProxyInput('port', value)}
                    actionProxyLogin={(value) => this.actionRedProxyInput('login', value)}
                    actionProxyPassword={(value) => this.actionRedProxyInput('password', value)}
                    load={this.loadContent}
                />
            );

        } else if (modalAddAccount.level === 1) {
            //двухфакторная авторизация, ввод кода
            content = (
            <TemplateModalAddAccountInstagramTwoFactorCode
                code={modalAddAccount.code}
                actionCode={this.actionCode}
                actionOK={this.actionOK}
                close={this.actionClose}
                load={this.loadContent}
                totp={modalAddAccount.totp}
            />
            );
        } else if (modalAddAccount.level === 2) { // выбирааем куда отправить код

            let option = [];

            if (modalAddAccount.method.phone) {
                option.push({value: text[2]+': ' + modalAddAccount.method.phone, key: 'phone'});
            }

            if (modalAddAccount.method.email) {
                option.push({value: text[3]+': ' + modalAddAccount.method.email, key: 'email'});
            }

            content = (
                <TemplateModalAddAccountInstagramVerificationTip
                    options={option}
                    value={modalAddAccount.method_verification}
                    action={this.actionSelectVerification}
                    actionOK={this.actionOK}
                    close={this.actionClose}
                    load={this.loadContent}
                />
            );
        } else if (modalAddAccount.level === 3) { //ввод кода
            content = (
                <TemplateModalAddAccountInstagramCode
                    code={modalAddAccount.code}
                    actionCode={this.actionCode}
                    actionOK={this.actionOK}
                    close={this.actionClose}
                    load={this.loadContent}
                />
            );
        }

        return content;
    }

    actionRedProxyInput = (type, value) => {
        const modalAddAccount = this.props.modalAddAccount;

        if (type === 'ip') {
            modalAddAccount.proxyIP = value;
        } else if (type === 'port') {
            modalAddAccount.proxyPort = value;
        } else if (type === 'login') {
            modalAddAccount.proxyLogin = value;
        } else if (type === 'password') {
            modalAddAccount.proxyPassword = value;
        }
    }

    actionSelectVerification = (value) => {
        const modalAddAccount = this.props.modalAddAccount;
        modalAddAccount.method_verification = value;
    }

    actionClose = () => {
        const modalAddAccount = this.props.modalAddAccount;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;

        if (!this.close) {
            notificationStore.add(text[4], text[5]);
        } else {
            modalAddAccount.closeActive();
        }

    }

    actionCode = (value) => {
        const modalAddAccount = this.props.modalAddAccount;

        modalAddAccount.code = value;
    }

    actionLogin = (value) => {
        const modalAddAccount = this.props.modalAddAccount;
        modalAddAccount.login = value;
    }

    actionPassword = (value) => {
        const modalAddAccount = this.props.modalAddAccount;
        modalAddAccount.password = value;
    }

    actionOK = (resolve) => {

        const modalAddAccount = this.props.modalAddAccount;
        const accountsStore = this.props.accountsStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;

        this.loadContent = true;

        this.close = false;

        let proxy = {status: false};

        if (modalAddAccount.login === '' || modalAddAccount.password === '') {
            notificationStore.add(text[4], initialStore.text.code[63]);

            this.close = true;
            this.loadContent = false;
            resolve(true);
            return true;
        }

        if (modalAddAccount.openProxy) {
            if (modalAddAccount.proxyIP === '' || modalAddAccount.proxyPort === '' || modalAddAccount.proxyLogin === '' || modalAddAccount.proxyPassword === '') {
                notificationStore.add(text[4], initialStore.text.code[62]);

                this.close = true;
                this.loadContent = false;
                resolve(true);
                return true;
            } else {
                proxy = {
                    status: true,
                    ip: modalAddAccount.proxyIP,
                    port: modalAddAccount.proxyPort,
                    login: modalAddAccount.proxyLogin,
                    password: modalAddAccount.proxyPassword,
                };
            }
        }

        notificationStore.add(text[6], text[7], 7);

        APIaccounts.getAdd(
            modalAddAccount.login,
            modalAddAccount.password,
            modalAddAccount.level,
            modalAddAccount.twoFactorIdentifier,
            modalAddAccount.code,
            modalAddAccount.method_verification,
            modalAddAccount.path,
            modalAddAccount.nonce_code,
            modalAddAccount.user_id,
            proxy,
            ).then((data) => {

            if (!data.status) {
                if (data.code === false) {
                    alert(text[8] + data.response);
                } else {
                    notificationStore.add(text[4], initialStore.text.code[data.code]);
                }

                this.close = true;
                this.loadContent = false;
                resolve(true);
            } else {
                if (!data.login) {
                    if (data.level === 1) {

                        modalAddAccount.level = 1;
                        modalAddAccount.twoFactorIdentifier = data.twoFactorIdentifier;
                        modalAddAccount.totp = data.totp;

                    } else if (data.level === 2) {

                        modalAddAccount.level = 2;
                        modalAddAccount.method = data.method;
                        modalAddAccount.method_verification = data.method.phone ? 'phone':'email';
                        modalAddAccount.path = data.path;
                        modalAddAccount.nonce_code = data.nonce_code;
                        modalAddAccount.user_id = data.user_id;

                    } else if (data.level === 3) {
                        modalAddAccount.level = 3;
                    } else {
                        alert(text[9]);
                    }

                    this.close = true;
                    this.loadContent = false;
                    resolve(true);
                } else {
                    //Аккаунт добавлен!

                    //яндекс метрика
                    if (!initialStore.cordova) {
                        ym('reachGoal', '1');
                    }


                    APIuser.getLogin(true).then((datas) => {

                        //перенаправляем на новый аккаунт
                        notificationStore.add(text[10], text[11], 10);

                        accountsStore.updateInitialGroups(datas.groups);
                        accountsStore.updateInitial(datas.accounts);

                        this.close = true;
                        this.loadContent = false;
                        resolve(true);

                        initialStore.redirect('/account/'+data.id);

                        modalAddAccount.closeActive();
                    });
                }
            }
        }).catch((e) => {
            notificationStore.add(text[4], text[12], 30);

            this.close = true;
            this.loadContent = false;
            resolve(true);
        });
    }
}

export default ModalAddAccountInstagram;
