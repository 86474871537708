import {action, observable} from "mobx";

class modalSearhLibraryPost {

    @observable open = false;


    @action openActive = () => {
        this.open = true;
    }
}

export default modalSearhLibraryPost;