import React from 'react';
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import classes from "../../../component/header/HeaderAccount.module.css";
import {observable} from "mobx";
import Time from "../../../model/time";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {formatDate, parseDate} from "react-day-picker/moment";
import Searh from "../../../component/HistoryPosts/Searh";
import Select from "../../elements/select";
import DateMobile from "../../elements/dateMobile";
import iconInst from "../../../media/img/socialNetworks/inst.svg";
import iconVK from "../../../media/img/socialNetworks/vk.svg";
import iconFB from "../../../media/img/socialNetworks/fb.svg";
import iconOK from "../../../media/img/socialNetworks/ok.svg";
import iconTG from "../../../media/img/socialNetworks/tg.svg";
import AccountInfoTop from "../../elements/accountInfoTop";
import VisualPosts from "../../../component/HistoryPosts/VisualPosts";

export default
@inject('accountsStore', 'initialStore', 'menuStore')
@observer
class AccountElementsFilter extends React.Component {

    @observable date = {
        start: false,
        end: false,
        type: 'time',
    };

    constructor(props) {
        super(props);

        this.handleDayChangeStart = this.handleDayChangeStart.bind(this);
        this.handleDayChangeEnd = this.handleDayChangeEnd.bind(this);
    }

    render() {
        const initialStore = this.props.initialStore;
        const accountsStore = this.props.accountsStore;
        const text = initialStore.text.account;

        const active = accountsStore.active;

        const dateStart = Time.convertUnixToDate(accountsStore.list[active].postStore.search.start);
        const dateEnd = Time.convertUnixToDate(accountsStore.list[active].postStore.search.end);

        if (initialStore.templates === 'desktop') {
            return (
                <div className="filters top" key={active}>
                    <div className="filter-flex">
                        <div className="filter-check">
                            {this.props.tipPosts}
                        </div>

                        <div className="filter-date">
                            {this.props.activeSearh ?
                                <>
                                    <div className="filter-date__item is-active">
                                        <DayPickerInput
                                            inputProps={{ readOnly: true, className: 'form-control start form-control-date'}}
                                            formatDate={formatDate}
                                            parseDate={parseDate}
                                            format="D MMMM YYYY"
                                            placeholder={text[105]}
                                            dayPickerProps={{
                                                locale: initialStore.language,
                                                localeUtils: MomentLocaleUtils,
                                                /*disabledDays:(day) => day < (new Date()-86400000),*/
                                                selectedDay:accountsStore.list[active].postStore.search.start,
                                            }}
                                            onDayChange={(dey) => {this.handleDayChangeStart(dey, false, active)}}
                                            value={dateStart.date}
                                        />
                                    </div>
                                    <div className="filter-date__item">
                                        <DayPickerInput
                                            inputProps={{ readOnly: true, className: 'form-control finish form-control-date'}}
                                            formatDate={formatDate}
                                            parseDate={parseDate}
                                            format="D MMMM YYYY"
                                            placeholder={text[106]}
                                            dayPickerProps={{
                                                locale: initialStore.language,
                                                localeUtils: MomentLocaleUtils,
                                                /*disabledDays:(day) => day < (new Date()-86400000),*/
                                                selectedDay:accountsStore.list[active].postStore.search.end,
                                            }}
                                            onDayChange={(dey) => {this.handleDayChangeEnd(dey, false, active)}}
                                            value={dateEnd.date}
                                        />
                                        <i className="icon icon-abs icon-calendar-clear"></i>
                                    </div>
                                </>
                            :
                                <>
                                    {this.props.typeList !== null ?
                                        <Select nonTopValueElement action={this.props.actionTypeList} className={'event-drop dropdown is-open event-drop-type-list'} options={this.props.typeList}>{this.props.typeListValue}</Select>
                                    :null}
                                </>
                            }
                        </div>

                    </div>

                    {this.props.activeSearh ?
                        <Searh/>
                    :null}


                </div>
            );
        } else {

            return (
                <>

                <AccountInfoTop
                    type={this.props.typeAccount}
                    name={this.props.nameAccount}
                    img={this.props.imgAccount}
                />

                <div className="filters top" key={active}>
                    <div className="filter-flex">
                        <div className="filter-check">
                            {this.props.tipPosts}
                        </div>

                        <div className="filter-date mobile-max-width">
                            {this.props.activeSearh ?
                                <>
                                    <div className="filter-date__item is-active">
                                        <DateMobile
                                            placeholder={text[105]}
                                            onDayClick={(dey) => {this.handleDayChangeStart(dey, false, active)}}
                                            selectedDays={accountsStore.list[active].postStore.search.start}
                                        />
                                    </div>
                                    <div className="filter-date__item">
                                        <DateMobile
                                            placeholder={text[106]}
                                            onDayClick={(dey) => {this.handleDayChangeEnd(dey, false, active)}}
                                            selectedDays={accountsStore.list[active].postStore.search.end}
                                        />

                                        <i className="icon icon-abs icon-calendar-clear"></i>
                                    </div>
                                </>
                                :
                                <>
                                    {this.props.typeList !== null ?
                                        <VisualPosts
                                            action={this.props.actionTypeList}
                                            options={this.props.typeList}
                                            id={this.props.typeListValue}
                                        />
                                        :null}
                                </>
                            }
                        </div>

                    </div>

                    {this.props.activeSearh ?
                        <Searh/>
                        :null}


                </div>
                </>
            );
        }
    }

    handleDayChangeStart(day, unix, id) {

        const accountsStore = this.props.accountsStore;

        const time = Time.convertDateToUnix(day, unix);

        const timeUnixAndHearMinToUnix = Time.convertUnixAndHearMinToUnix(time, 0,0);

        accountsStore.list[id].postStore.search.start = timeUnixAndHearMinToUnix;

        if (!accountsStore.list[id].postStore.search.end || accountsStore.list[id].postStore.search.end < timeUnixAndHearMinToUnix) {
            accountsStore.list[id].postStore.search.end = Time.convertUnixAndHearMinToUnix(time, 23,59);
        }

    }

    handleDayChangeEnd(day, unix, id) {

        const accountsStore = this.props.accountsStore;

        const time = Time.convertDateToUnix(day, unix);

        const timeUnixAndHearMinToUnix = Time.convertUnixAndHearMinToUnix(time, 23,59);

        accountsStore.list[id].postStore.search.end = timeUnixAndHearMinToUnix;

        if (!accountsStore.list[id].postStore.search.start || accountsStore.list[id].postStore.search.start > timeUnixAndHearMinToUnix) {
            accountsStore.list[id].postStore.search.start = Time.convertUnixAndHearMinToUnix(time, 0,0);
        }
    }
}

