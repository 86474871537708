import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import APIdirect from "../../../../api/direct";
import MessageContent from "./MessageContent";

import directLoad from "../../../../media/gif/directLoad.gif";

import AccountDirectAccount from "../../../../templates/account/direct/account";
import AccountDirectUsers from "../../../../templates/account/direct/users";
import AccountDirectDialogs from "../../../../templates/account/direct/dialogs";

@inject('menuStore')
@inject('accountsStore')
@inject('initialStore')
@inject('notificationStore')
@observer
class Dialogs extends React.Component {

    accountActive = false;
    accuintPending = 1;

    constructor(props) {
        super(props);

        this.searhDialogs = this.searhDialogs.bind(this);

        this.listDialogsRef = React.createRef();
    }



    render() {

        const accountsStore = this.props.accountsStore;
        const activeAccount = accountsStore.active;
        const direct = accountsStore.list[activeAccount].direct;

        const active = direct.account.active;
        let activeID = false;
        let AllPending = 0;
        let titleDialog = '';
        //диалоги

        this.accuintPending = 1;
        let dialogs = direct.account.list.map((data, key) => {

            if (data.pending === 1) {
                AllPending++;
            }

            if (this.props.buttonPendingStatus !== data.pending) {
                return null;
            }

            let name = '';
            let icon = [];

            const activeClass = active === data.thread_id ? true:false;

            if (activeClass) {
                activeID = key;
                this.accuintPending = data.pending;
            }

            for (const user of data.user) {
                name = name === '' ? user.username : name + ' ' + user.username;
                icon.push(user.pic);
                if (activeClass) {
                    titleDialog = titleDialog === '' ? user.username: titleDialog + ', ' + user.username;
                }
            }

            if (name === '') {
                name = direct.viewer.username;
                icon.push(direct.viewer.pic);
                titleDialog = direct.viewer.username;
            }



            let item = {
                nameDialog: 'нет сообщений',
            }

            let newMessageInt = 0;

            if (data.posts.list.length) {
                const elementItem = data.posts.list[data.posts.list.length-1];
                item = MessageContent(elementItem, data.user.slice(), direct.viewer, direct.account.list[key].last_seen_at);

                //определение новых писем
                let timeVievPost = data.posts.list[0].timestamp;
                if (data.last_seen_at[direct.viewer.user_id] !== undefined) {
                    timeVievPost = data.last_seen_at[direct.viewer.user_id].timestamp;
                }

                for (const elementMessage of data.posts.list) {
                    if (elementMessage.user_id !== direct.viewer.user_id && elementMessage.timestamp > timeVievPost) {
                        newMessageInt++;
                    }
                }
            }



            let time = <img src={directLoad} style={{width:12, height: 12}}/>;
            if (item.time) {
                time = item.time;
            }


            return <AccountDirectAccount
                key={data.thread_id}
                img={icon}
                name={name}
                time={time}
                text={item.nameDialog}
                userNonViev={item.userNonViev}
                active={activeClass}
                click={() => {this.openDialog(data.thread_id)}}
                newMessage={newMessageInt}
            />;
        });

        if (!active) {
            this.accuintPending = 0;
        }

        let dialogNextLoad = null;
        if (direct.account.cursor) {
            dialogNextLoad = <div style={{textAlign: 'center', color: '#7b5c9b', textDecoration: 'underline', padding: 20, cursor: 'pointer'}} onClick={() => {this.nextLoadDialog()}}>Загрузить еще.</div>;

            if (direct.account.cursor === 'load') {
                dialogNextLoad = <div style={{textAlign: 'center', color: '#7b5c9b', textDecoration: 'underline', padding: 20}}><img width={16} height={16} src={directLoad}/></div>;
            }
        }



        //
        return(
            <AccountDirectDialogs
                activeID={this.props.activeID}
                buttonPendingClick={this.buttonPendingClick}
                AllPending={AllPending}

                buttonPendingStatus={this.props.buttonPendingStatus}
                AllPending={AllPending}
                cursor={direct.account.cursor}
                nextLoadDialog={this.nextLoadDialog}
                addListDialogsRef={this.addListDialogsRef}
                dialogs={dialogs}
                dialogNextLoad={dialogNextLoad}
            />



        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const accountsStore = this.props.accountsStore;

        const active = accountsStore.active;

        if (active !== this.accountActive) {
            this.props.buttonPendingStatusRed(this.accuintPending);

            //перематываем овно диалогов вверх
            this.listDialogsRef.scrollTop = 0;

            this.accountActive = active;
        }

    }

    componentDidMount() {

        const accountsStore = this.props.accountsStore;
        const active = accountsStore.active;

        if (active !== this.accountActive) {
            this.props.buttonPendingStatusRed(this.accuintPending);

            //перематываем овно диалогов вверх
            this.listDialogsRef.scrollTop = 0;

            this.accountActive = active;
        }

        this.props.menuStore.updateActiveComponentAccount('direct');
    }

    searhDialogs () {
        this.props.notificationStore.add('В разработке', 'Функция поиска по диалогам находится в разработке.', 3);
    }

    addListDialogsRef = (e) => {
        this.listDialogsRef = e;
    }

    buttonPendingClick = () => {
        const accountsStore = this.props.accountsStore;
        const active = accountsStore.active;
        const direct = accountsStore.list[active].direct;

        if(this.props.buttonPendingStatus === 1) {
            this.props.buttonPendingStatusRed(0);
        } else {
            this.props.buttonPendingStatusRed(1);
        }
        direct.account.active = false;
    }




    nextLoadDialog = () => {
        const accountsStore = this.props.accountsStore;
        const active = accountsStore.active;
        const account = accountsStore.list[active].id;
        const direct = accountsStore.list[active].direct;

        direct.account.cursor = 'load';

        APIdirect.nextlist(account).then((data) => {
            if (!data.status) {
                direct.account.cursor = true;
            }
        }).catch((error) => {
            direct.account.cursor = true;
        });

    }



    openDialog = (id) => {

        const accountsStore = this.props.accountsStore;
        const active = accountsStore.active;
        const direct = accountsStore.list[active].direct;

        this.props.accountsStore.list[accountsStore.active].direct.account.active = id;
    }


}

export default Dialogs;