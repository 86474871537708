import React from 'react';

import {inject, observer} from "mobx-react";
import Textbox from "../../../component/Textbox";
import {observable} from "mobx";
import Input from "../../../../../templates/elements/input";

@inject('editorStore', 'initialStore')
@observer
class MediaRedBUrlStr extends React.Component {

    @observable text = '';

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;
    }

    render() {
        const initialStore = this.props.initialStore;
        const textI18 = initialStore.text.addPost;

        let text = this.fabric.getElementRedUrlText();
        text = text ? text:'';

        let textAlt = this.fabric.getElementRedUrlTextAlt();
        textAlt = textAlt ? textAlt:'';

        let ff = this.text;

        return (
            <>
            <div className="editor-redactor__geo_name">
            <div className="add-publication__text-box-red">
                <Input className={'form-control view-2 form-control-emoje'} placeholder={textI18[216]} onChange={this.action}>{text}</Input>
            </div>
            </div>
            <div className="editor-redactor__geo_name">
                <div className="add-publication__text-box-red">
                    <Input emoje className={'form-control view-2'} placeholder={textI18[217]} onChange={this.actionAlt}>{textAlt}</Input>
                </div>
            </div>
            </>
        );

    }

    action = (value) => {
        let name = value;

        if (name === 'https://' || name === 'http://') {
            name = '';
        }

        if (name !== '') {
            let reg = /https:\/\//i;
            let reg2 = /http:\/\//i;
            if (!reg.test(name) && !reg2.test(name)) {
                name = 'http://'+name;
            }
        }

        let nameAlt = name.split(/\/+/)[1];

        if (nameAlt) {
            nameAlt = nameAlt.toUpperCase();
        } else {
            nameAlt = name.toUpperCase();
        }

        let infoUrl = this.fabric.elementInfoUrl();

        this.fabric.elementTemplate(nameAlt, false, {
            tip: 'url',
            url: name,
            opacity: infoUrl.opacity,
        });
        console.log(nameAlt + ' / ' + name);

        this.text = name;
    }
    actionAlt = (value) => {

        let text = this.fabric.getElementRedUrlText();
        let name = text ? text:'';

        let nameAlt = value;
        nameAlt = nameAlt.toUpperCase();

        let infoUrl = this.fabric.elementInfoUrl();

        this.fabric.elementTemplate(nameAlt, false, {
            tip: 'url',
            url: name,
            opacity: infoUrl.opacity,
        });

        this.text = nameAlt;
    }
}

export default MediaRedBUrlStr;
