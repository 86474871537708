import React from 'react';

import {inject, observer} from "mobx-react";
import Textbox from "../../../component/Textbox";
import {observable} from "mobx";
import Input from "../../../../../templates/elements/input";
import Select from "../../../../../templates/elements/select";
import PostTemplateMediaRedQuizTexts from "../../../../../templates/post/red/mediaRedQuizTexts";

@inject('editorStore', 'initialStore')
@observer
class MediaRedBQuizTexts extends React.Component {

    @observable text = '';

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;
    }

    render() {
        const initialStore = this.props.initialStore;
        const textI18 = initialStore.text.addPost;

        const text = this.fabric.getElementRedQuizText('text');



        let ff = this.text;

        let classesA = 'form-control view-2';
        let classesB = 'form-control view-2';
        let classesC = 'form-control view-2';
        let classesD = 'form-control view-2';

        if (text.textA === '') {
            classesA = 'form-control view-2 errorInput';
        }
        if (text.textB === '') {
            classesB = 'form-control view-2 errorInput';
        }

        if (text.textC === '' && text.textD !== '') {
            classesC = 'form-control view-2 errorInput';
        }

        let options = [
            {key: 0, value: textI18[162]},
            {key: 1, value: textI18[163]},
        ]

        if (text.textC !== '') {
            options.push({key: 2, value: textI18[174]});
        }

        if (text.textC !== '' && text.textD !== '') {
            options.push({key: 3, value: textI18[175]});
        }

        return (
            <PostTemplateMediaRedQuizTexts
                options={options}
                actionSelect={this.actionSelect}
                good={text.good}
                action={this.action}
                classesA={classesA}
                textAValue={text.textA}
                classesB={classesB}
                textBValue={text.textB}
                classesC={classesC}
                textCValue={text.textC}
                classesD={classesD}
                textDValue={text.textD}
            />
        );
    }

    actionSelect = (num) => {
        this.fabric.elementRedQuiz(false, false, false, false, false, num);
        this.text = num;
    }

    action = (type, value) => {

        if (type === 'textA') {
            this.fabric.elementRedQuiz(false, value);
        } else if (type === 'textB') {
            this.fabric.elementRedQuiz(false, false, value);
        } else if (type === 'textC') {
            this.fabric.elementRedQuiz(false, false, false, value);

            const text = this.fabric.getElementRedQuizText('text');
            if (value === '' && text.good === 2) {
                this.fabric.elementRedQuiz(false, false, false, false, false, 0);
            }

        } else if (type === 'textD') {
            this.fabric.elementRedQuiz(false, false, false, false, value);

            const text = this.fabric.getElementRedQuizText('text');
            if (value === '' && text.good === 3) {
                this.fabric.elementRedQuiz(false, false, false, false, false, 0);
            }
        }

        this.text = value;
    }
}

export default MediaRedBQuizTexts;