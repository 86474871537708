import React from 'react';
import {inject, observer} from "mobx-react";

export default
@inject('initialStore')
@observer
class LibraryButtonCategoriesAdd extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.library;

        if (initialStore.templates === 'desktop') {

            return <>

                {this.props.open ?
                    <div className="column-group column-edit">
                        <input placeholder={text[6]} className={this.props.addActionStatusLoad ? "form-control  btn-primary-animate-content" : "form-control"} onChange={(e) => this.props.inputAction(e.target.value)} value={this.props.inputValue} autoFocus/>
                        <button type="button" className={this.props.addActionStatusLoad ? "btn btn-primary btn-yes btn-primary-animate" : "btn btn-primary btn-yes"} onClick={this.props.addAction}><i className="icon icon-filter-yes"></i></button>
                        <button type="button" className={this.props.addActionStatusLoad ? "btn btn-primary btn-yes btn-primary-animate closeAddCategory" : "btn btn-primary btn-yes closeAddCategory"} onClick={this.props.closeAction}>{text[7]}</button>
                    </div>
                :
                    <div className="column-group column-add">
                        <button type="button" className="btn border btn-primary btn-add" onClick={this.props.openAction}><i className="icon icon-filter-add"></i></button>
                    </div>
                }

                </>;
        } else {
            return <>

                {this.props.open ?
                    <div className="column-group column-edit">
                        <input placeholder={text[6]} className={this.props.addActionStatusLoad ? "form-control  btn-primary-animate-content" : "form-control"} onChange={(e) => this.props.inputAction(e.target.value)} value={this.props.inputValue} autoFocus/>
                        <button type="button" className={this.props.addActionStatusLoad ? "btn btn-primary btn-yes btn-primary-animate" : "btn btn-primary btn-yes"} onClick={this.props.addAction}><i className="icon icon-filter-yes"></i></button>
                        <button type="button" className={this.props.addActionStatusLoad ? "btn btn-primary btn-yes btn-primary-animate closeAddCategory" : "btn btn-primary btn-yes closeAddCategory"} onClick={this.props.closeAction}>{text[7]}</button>
                    </div>
                    :
                    <div className="column-group column-add">
                        <button type="button" className="btn border btn-primary btn-add" onClick={this.props.openAction}><i className="icon icon-filter-add"></i></button>
                    </div>
                }

            </>;
        }

    }
}

