import React from 'react';
import {inject, observer} from "mobx-react";

import icons8Add from "../../media/img/account/icons-8-add.svg";
import InfoContainer from "../../templates/infoConteiner";


@inject("accountsStore", "modalAddAccount", "initialStore")
@observer
class Welcome extends React.Component {

    render() {

        const accountsStore = this.props.accountsStore.list.slice();
        const initialStore = this.props.initialStore;
        const text = initialStore.text.welcome;

        if (accountsStore.length > 0) {

            this.props.history.push(`/account/${accountsStore[0].id}`);

            return null;
        } else {
            return <InfoContainer

                title={text[0]}
                text={text[1]}
                buttonName={text[2]}
                buttonClick={this.addAccount}
            />;
        }

    }

    addAccount = () => {
        const modalAddAccount = this.props.modalAddAccount;
        modalAddAccount.openActive();
    }

    componentDidMount() {
        if (this.props.accountsStore.length > 0) {
            this.props.history.push(`/account/${this.props.accountsStore.list[0].id}`);
        } else {

            if (this.props.match.url === '/welcome') {
                const modalAddAccount = this.props.modalAddAccount;
                //modalAddAccount.openActive();
            }

        }
    }

}

export default Welcome;