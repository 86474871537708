import React from 'react';
import HeaderMobileName from "../../templates/header/mobileName";
import {inject, observer} from "mobx-react";
import HeaderBox from "../../templates/header/box";
import HeaderBoxLiElement from "../../templates/header/boxLIElement";

@inject('initialStore')
@observer
class HeaderLibrary extends React.Component {



    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.library;

        let activeElementName = 'Библиотека постов';

        return <HeaderBox activeElementName={activeElementName}>
            <HeaderBoxLiElement
                url={'/library'}
                active={true}
                name={text[0]}/>
        </HeaderBox>;
    }

}

export default HeaderLibrary;
