import React from "react";

const ru = {
    0: 'сегодня',
    1: 'завтра',
    3: 'вчера',
    4: ' в ',
    5: 'Сегодня',
    6: 'Завтра',
    7: 'Вчера',


}

const en = {
    0: 'today',
    1: 'tomorrow',
    3: 'yesterday',
    4: ' in ',
    5: 'Today',
    6: 'Tomorrow',
    7: 'Yesterday',

}

const time = {
    ru: ru,
    en: en,
}

export default time;