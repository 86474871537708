import React from 'react';

import {inject, observer} from "mobx-react";

import Button from "../../templates/elements/button";

@inject('postStore', 'notificationStore', 'initialStore')
@observer
class ButtonCloseMobile extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return null;
        }

        return (
            <div className="add-post__events close-mobile-min-top">
                <Button style={{width: "100%"}} action={this.props.action}>close</Button>
            </div>
        );


    }



}

export default ButtonCloseMobile;
