import React from 'react';
import {Route, Switch} from "react-router";

import Welcome from "../pages/welcome";
import Downloadpost from "../pages/downloadpost";
import Help from "../pages/help";
import Library from "../pages/library";
import Rate from "../pages/rate";
import Settings from "../pages/settings";
import Account from "../pages/account";
import Error404 from "../pages/error404";

export default class Content extends React.Component {

    render() {
        return <Switch>
            <Route exact path="/" component={Welcome}></Route>
            <Route exact path="/welcome" component={Welcome}></Route>
            <Route path="/downloadpost" component={Downloadpost}></Route>
            <Route path="/help" component={Help}></Route>

            <Route path="/library/:category" component={Library}></Route>
            <Route path="/library" component={Library}></Route>

            <Route path="/rate/:category" component={Rate}></Route>
            <Route path="/rate" component={Rate}></Route>

            <Route path="/settings" component={Settings}></Route>
            <Route path="/account/:idAccount" component={Account}></Route>
            <Route path="*" component={Error404}></Route>
        </Switch>;
    }

}