import React from "react";

const ru = {
    0: "Библиотека постов",
    1: (name) => 'Категория "'+name+'"',
    2: (name) => 'В категории "'+name+'" нет постов',
    3: 'Нет постов',
    4: <>Создайте первый пост в библиотеку, данные посты вы сможете<br/> отправлять другим людям по ссылке или использовать их при создании постов.</>,
    5: 'Создать пост в Библиотеку',
    6: 'Название категории',
    7: 'Отмена',
    8: 'Все',
    9: 'У Вас еще нет постов в библиотеке.',
    10: 'В данной категории нет постов.',
    11: 'Начало',
}

const en = {
    0: "Post library",
    1: (name) => 'Category "'+name+'"',
    2: (name) => 'There are no posts in the "'+name+'" category',
    3: 'No posts\n',
    4: <>Create the first post in the library, you can<br/> send these posts to other people using the link or use them when creating posts.</>,
    5: 'Create a post to the Library',
    6: 'Name of category',
    7: 'Cancel',
    8: 'All',
    9: "You don\'t have any library posts yet.",
    10: 'There are no posts in this category.',
    11: 'Start',

}

const library = {
    ru: ru,
    en: en,
}

export default library;