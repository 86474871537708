import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ReactDOM from "react-dom";

export default
@inject('initialStore')
@observer
class Select extends React.Component {

    @observable typeOpen = false;
    @observable searhValue = '';

    constructor(props) {
        super(props);

        this.window = React.createRef();

        this.onFocus = this.onFocus.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.openWin = false;

        this.elementActive = React.createRef();
        this.elementActiveBox = React.createRef();
    }

    render() {
        const options = this.props.options;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;


        if (!this.props.mobile /*initialStore.templates === 'desktop'*/) {

            let name = '';

            let searhOptions = [];

            for (const element in options) {
                if (this.props.children === options[element].key) {
                    name = options[element].value;
                    if (!this.props.nonTopValueElement) {
                        searhOptions.push({key: options[element].key, value: options[element].value});
                    }
                    break;
                }
            }

            if (!this.typeOpen) {
                if (this.openWin) {
                    this.openWin=false;

                    this.updateStatusCloseFon();
                }
            } else {
                if (!this.openWin) {
                    this.openWin=true;
                    this.updateStatusCloseFon();
                }
            }




            let content = null;

            if (this.typeOpen) {

                for (const element of options) {

                    if (!this.props.nonTopValueElement) {
                        if (this.props.children === element.key) {
                            continue;
                        }
                    }

                    if(element.value.toUpperCase().indexOf(this.searhValue.toUpperCase()) + 1) {
                        searhOptions.push({key: element.key, value: element.value});
                    }
                }

                const optiosJSX = searhOptions.map((value) => {

                    //this.elementActive
                    if (this.props.children === value.key) {
                        return <span ref={(el) => { this.elementActive = el; }} className={"item-result item-result-active"} onClick={() => {
                            this.props.action(value.key);
                            this.typeOpen = false;
                            this.searhValue = '';
                        }}>{value.value}</span>;
                    } else {
                        return <span className={"item-result"} onClick={() => {
                            this.props.action(value.key);
                            this.typeOpen = false;
                            this.searhValue = '';
                        }}>{value.value}</span>;
                    }

                });


                let eventSearchStyle={};

                if (this.props.top) {
                    eventSearchStyle.bottom=0;
                    eventSearchStyle.top = 'unset';
                }

                /*max-height: 320px;*/

                let innerResultStyle={maxHeight: 319};
                if (this.props.viewElementsList) {
                    innerResultStyle={maxHeight: this.props.viewElementsList*29};
                }

                content = (
                    <div className="event-search" style={eventSearchStyle}>
                        {this.props.search ?
                            <div className="chat-search">
                        <span className="chat-search__icon">
                            <i className="icon icon-chat-search"></i>
                        </span>
                                <div className="chat-search__input">
                                    <input value={this.searhValue} onChange={(e) => {this.searhValue=e.target.value}} placeholder={text[137]} autoFocus/>
                                </div>
                            </div>
                            :null}
                        <div className={ this.props.search ? "event-search__result" : "event-search__result event-search__result-non-searh"}>
                            <div ref={(el) => { this.elementActiveBox = el; }} className="inner-result" style={innerResultStyle}>
                                {optiosJSX}
                            </div>
                        </div>
                    </div>);
            }

            let className = 'event-drop dropdown is-open';
            if (this.props.className) {
                className = this.props.className;
            }

            let classNameTwu = 'event-select form-control';
            if (this.props.classNameTwu) {
                classNameTwu = this.props.classNameTwu;
            }



            return (
                <div className={className} ref={this.window} style={this.props.style ? this.props.style:null}>
                    <div className={classNameTwu} onClick={this.onFocus}>
                        <span className="title-select">{name}</span>
                    </div>
                    {content}
                </div>
            );

        } else {

            const optionsElements = [];

            for (const element of options) {

                if (this.props.children === element.key) {
                    optionsElements.push(<option value={element.key} >{element.value}</option>);
                } else {
                    optionsElements.push(<option value={element.key}>{element.value}</option>);
                }

            }

            let className = 'event-drop dropdown is-open';
            if (this.props.className) {
                className = this.props.className;
            }

            return (
                <div className={className} style={this.props.style ? this.props.style:null}>
                    <select className="event-select form-control event-select-mobile" value={this.props.children} onChange={this.handleChange}>{optionsElements}</select>
                </div>

            );
        }

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.typeOpen) {
            //this.elementActive.scrollIntoView({block: "start", inline: "nearest"});
            this.elementActiveBox.scrollTop = this.elementActive.offsetTop;
        }
    }

    handleChange = (event) => {
        //const value = Number(event.target.value);
        this.props.action(event.target.value);
    }

    onFocus () {

        if (this.props.load) {
            return false;
        }

        this.typeOpen = !this.typeOpen;

        if (document.activeElement) {
            if (document.activeElement.localName === 'textarea' || document.activeElement.localName === 'input') {
                document.activeElement.blur();
            }
        }
    }

    updateStatusCloseFon = () => {

        const initialStore = this.props.initialStore;
        let type = 'click';
        if (initialStore.templates !== 'desktop') {
            type = 'touchend';
        }

        if (this.openWin) {

            document.addEventListener(type, this.handleClickOutside, false);
        } else {
            document.removeEventListener(type, this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов
                    if (this.typeOpen) {
                        this.typeOpen = false;
                        this.searhValue = '';
                    }
            }
        } catch(error) {
            return null;
        }
    }
}

