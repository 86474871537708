import React from 'react';

import Time from "../../../../model/time";
import AccountDirectMessageReelShare from "../../../../templates/account/direct/message/reel_share";
import AccountDirectMessageLink from "../../../../templates/account/direct/message/link";
import AccountDirectMessageProfile from "../../../../templates/account/direct/message/profile";
import AccountDirectMessageMediaShare from "../../../../templates/account/direct/message/media_share";
import AccountDirectMessageText from "../../../../templates/account/direct/message/text";
import AccountDirectMessageMedia from "../../../../templates/account/direct/message/media";
import AccountDirectMessageErrorTwuText from "../../../../templates/account/direct/message/errorTwuText";
import AccountDirectMessageFelixShare from "../../../../templates/account/direct/message/felix_share";
import AccountDirectMessageRavenMedia from "../../../../templates/account/direct/message/raven_media";
import AccountDirectMessageGif from "../../../../templates/account/direct/message/gif";

export default function MessageContent (item, users, viewer, last_seen_at = false, itemInfoIDAccountBeck = false) {

    const array = {
        nameDialog: 'нет сообщений',
        box: <div>Не опознанный элемент: {item.item_type}</div>,
    }


    const myItem = viewer.user_id === item.user_id ? true : false;

    const item_type = item.item_type;

    let content = false;

    let userPIC = null;
    if(!myItem) {
        for (const user of users) {
            if (Number(user.user_id) === item.user_id) {
                userPIC = user.pic;
                break;
            }
        }
    }

    //Время
    array.time = false;
    array.userNonViev = false;
    if (item.timestamp > 0) {
        array.time = Time.convertUnixToDirect(item.timestamp);

        let userNonViev = '';
        if (item.user_id === viewer.user_id && last_seen_at) {
            for (const KeyUser in last_seen_at) {
                if (last_seen_at[KeyUser].timestamp < item.timestamp) {
                    for (const user of users) {
                        if (KeyUser === user.user_id) {
                            userNonViev = userNonViev === '' ? user.username : userNonViev+ ', ' + user.username;
                            break;
                        }
                    }
                }
            }
        }

        if (userNonViev !== '') {
            array.userNonViev = userNonViev;
        }

    }










    if (item_type === 'reel_share') {

        const reel_share = item.item_info.reel_share;

        const mentioned_user_id = reel_share.mentioned_user_id;
        let username = false;
        for (const user of users) {
            if (user.user_id === mentioned_user_id) {
                username = user.username;
                break;
            }
        }

        if (Number(reel_share.mentioned_user_id) === item.user_id && item.user_id === viewer.user_id) {
            username = viewer.username;
        }

        if (username) {
            array.nameDialog = 'Вы упомянули ' + username + ' в своей истории';
        } else {
            array.nameDialog = 'Упомянул(-а) вас в своей истории';
        }


        content = <div>Не опознанный элемент</div>;

        let contentMedia =false;
        let mediaType = false;
        let text = false;
        if (reel_share.reel_type === null) {
            content = array.nameDialog;
        } else if (reel_share.reel_type === 'archive_day_reel') {

            if (reel_share.media.media_type === 2) {
                contentMedia = reel_share.media.video_versions[0].url;
                mediaType = 'video';
            } else if (reel_share.media.media_type === 1) {
                contentMedia =reel_share.media.image_versions2.candidates[0].url
                mediaType = 'image';
            }
            text = 'Видите только вы';


        } else {
            text = 'Не опознанный тип сообщения reel_share > ' + reel_share.reel_type + '';
        }

        content = <AccountDirectMessageReelShare
            time={array.time}
            userNonViev={array.userNonViev}
            username={username}
            media={contentMedia}
            mediaType={mediaType}
            text={text}
        />;



    } else if (item_type === 'link') {
        const link = item.item_info.link;

        array.nameDialog = link.text;

        let urlName=false;
        let urlText = false;
        let url= false;
        if (link.link_context.link_title !== '') {
            urlName = link.link_context.link_title;
            urlText = link.link_context.link_summary;
            url = link.link_context.link_url;
        }

        content = <AccountDirectMessageLink
            text={link.text}
            url={url}
            urlName={urlName}
            urlText={urlText}
            time={array.time}
            userNonViev={array.userNonViev}
        />;

    } else if (item_type === 'profile') {
        const username = item.item_info.profile.username;
        array.nameDialog = '@' + username;

        const profile = item.item_info.profile;
        const preview_medias = item.item_info.preview_medias;

        let contentImage = [];
        for (const preview_media of preview_medias) {
            contentImage.push(preview_media.image_versions2.candidates[0].url);
        }

        content = <AccountDirectMessageProfile
            avatar={profile.profile_pic_url}
            username={profile.username}
            full_name={profile.full_name}
            contentImage={contentImage}
            url={'https://instagram.com/' + profile.username}
            time={array.time}
            userNonViev={array.userNonViev}
        />;

    } else if (item_type === 'media_share') {
        array.nameDialog = 'Отправила(-а) публикацию';

        const media_share = item.item_info.media_share;

        let contentImage = 'Не опознанный элемент';
        let tip = 'image';
        let width=null;
        let height=null;
        let image = null;
        //карусель
        if (media_share.media_type === 8) {
            image = media_share.carousel_media[0].image_versions2.candidates[0].url;
            width = media_share.carousel_media[0].image_versions2.candidates[0].width;
            height = media_share.carousel_media[0].image_versions2.candidates[0].height;
            tip = 'gallery';
        } else if (media_share.media_type === 1) {
            image = media_share.image_versions2.candidates[0].url;
            width = media_share.image_versions2.candidates[0].width;
            height = media_share.image_versions2.candidates[0].height;
        } else if (media_share.media_type === 2) {
            image = media_share.image_versions2.candidates[0].url;
            width = media_share.image_versions2.candidates[0].width;
            height = media_share.image_versions2.candidates[0].height;
            tip = 'video';
        }

        let text = '';
        if (media_share.caption !== null) {
            text = media_share.caption.text;
        }

        content = <AccountDirectMessageMediaShare
            avatar={media_share.user.profile_pic_url}
            username={media_share.user.username}
            tip={tip}
            image={image}
            width={width}
            height={height}
            text={text}
            url={'https://instagram.com/p/' + media_share.code}
            time={array.time}
            userNonViev={array.userNonViev}
        />

    } else if (item_type === 'like') {
        array.nameDialog = '❤️';

        content = '❤️';
    } else if (item_type === 'video_call_event') {
        console.log(item);
        array.nameDialog = 'Видеочат завершен';

        content = 'Видеочат завершен';

    } else if (item_type === 'text') {

        content = <AccountDirectMessageText
            text={item.item_info.text}
            time={array.time}
            userNonViev={array.userNonViev}
            myItem={myItem}
        />;

        array.nameDialog = item.item_info.text;

    } else if (item_type === 'media') {
        array.nameDialog = 'Отправила(-а) медиа';

        const media = item.item_info.media;

        content = 'не определенный элемент';

        let mediaType = false;
        let mediaURL = false;
        let width=0;
        let height=0;
        if (media.media_type === 1) {
            mediaURL = media.image_versions2.candidates[0].url;
            mediaType = 'image';
            width = media.image_versions2.candidates[0].width;
            height = media.image_versions2.candidates[0].height;
        } else if (media.media_type === 2) {
            mediaURL = media.video_versions[0].url;
            mediaType = 'video';
            width = media.video_versions[0].width;
            height = media.video_versions[0].height;
        }

        content = <AccountDirectMessageMedia
            mediaType={mediaType}
            media={mediaURL}
            width={width}
            height={height}
            time={array.time}
            userNonViev={array.userNonViev}
        />

    } else if (item_type === 'action_log') {

        array.nameDialog = 'Понравилась ваша публикация';

    } else if (item_type === 'story_share') {
        array.nameDialog = 'Отправил(-а) историю';

        const story_share = item.item_info.story_share;

        content = null;
        if ("message" in story_share) {

            content = <AccountDirectMessageErrorTwuText
                title={story_share.title}
                text={story_share.message}
                time={array.time}
                userNonViev={array.userNonViev}
            />;
        } else {
            content = <a href={'https://instagram.com/stories/' + story_share.media.user.username + '/' + story_share.media.pk} target="_blank">
                <div>Отправил(-а) историю {story_share.media.user.username}</div>
                <div><img style={{width: 100}} src={story_share.media.image_versions2.candidates[0].url}/></div>
            </a>;
        }
    } else if (item_type === 'felix_share') {
        array.nameDialog = 'Поделился(-ась) видео IGTV';

        const felix_share = item.item_info.felix_share;

        content = <AccountDirectMessageFelixShare
            avatar={felix_share.video.user.profile_pic_url}
            username={felix_share.video.user.username}
            url={'https://www.instagram.com/tv/' + felix_share.video.code + '/'}
            image={felix_share.video.image_versions2.candidates[0].url}
            width={felix_share.video.image_versions2.candidates[0].width}
            height={felix_share.video.image_versions2.candidates[0].height}
            time={array.time}
            userNonViev={array.userNonViev}
        />;

    } else if (item_type === 'voice_media') {

        if (myItem) {
            array.nameDialog = 'Вы отправили голосовое сообщение';
        } else {
            array.nameDialog = 'Вам отправили голосовое сообщение';
        }

        const voice_media = item.item_info.voice_media;

        content = <>
            <div><audio controls="controls" src={voice_media.media.audio.audio_src}/></div>
        </>;

    } else if (item_type === 'raven_media') {
        if (myItem) {
            array.nameDialog = 'Вы отправили изчезающее фото';
        } else {
            array.nameDialog = 'Вам отправили изчезающее фото';
        }

        const visual_media = item.item_info.visual_media;

        content = null;
        if (visual_media.replay_expiring_at_us === null) {
            content = 'PhotoInstagram';
        } else {
            if (visual_media.view_mode === 'once') {
                content = 'PhotoBum';
            } else {
                if (visual_media.media.media_type === 1) {
                    content = 'PhotoPley';
                } else if (visual_media.media.media_type === 2) {
                    content = 'VideoPley';
                }
            }
        }

        content = <AccountDirectMessageRavenMedia
            time={array.time}
            userNonViev={array.userNonViev}
            content={content}
        />
    } else if (item_type === 'placeholder') {

        const placeholder = item.item_info.placeholder;

        array.nameDialog = placeholder.title;

        content = <AccountDirectMessageErrorTwuText
            title={item.item_info.placeholder.title}
            text={item.item_info.placeholder.message}
            time={array.time}
            userNonViev={array.userNonViev}
        />;

    } else if (item_type === 'animated_media') {

        const animated_media = item.item_info.animated_media;

        array.nameDialog = 'GIF';

        content = <img src={animated_media.images.fixed_height.url} style={{width: 200}}/>;
        content = <AccountDirectMessageGif
            media={animated_media.images.fixed_height.url}
            width={Number(animated_media.images.fixed_height.width)}
            height={Number(animated_media.images.fixed_height.height)}
        />
    } else {
        array.nameDialog = 'Item: ' + item_type;
        content = 'Error: ' + item_type;
    }

    if (content) {

        let avatar = null;
        if (itemInfoIDAccountBeck !== item.user_id) {

            avatar = !myItem ? <div className="chat-message__user"><img src={userPIC}/></div> : null;
            //this.itemInfoIDAccountBeck = item.user_id;
        }

        array.box = <div key={item.item_id} className={myItem ? 'chat-message right':'chat-message left'}>
            {avatar}
            {content}
        </div>;
    } else {
        array.box = null;
    }

    return array;
};