import React from 'react';
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import {observable} from "mobx";
import APIlibrary from "../../api/library";
import APIposts from "../../api/posts";

export default
@inject('initialStore', 'libraryStore', 'notificationStore')
@observer
class LibraryButtonCategories extends React.Component {

    @observable nameRed=this.props.name;
    @observable addLoad = false;
    @observable delStatus = 0;

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {

            const classElement = this.props.active ? "btn btn-primary" : "btn border btn-primary";

            if (this.props.id === 0) {
                return <Link  to={`/library`} className="type-group dropdown">
                    <button style={{padding: '11.5px 16px'}} type="button" className={classElement}>{this.props.name}</button>
                </Link>;
            }

            if (this.props.setupStatus && this.props.active) {
                return <>
                    <div className="column-group column-edit" style={{marginTop: '0px'}}>
                        <input placeholder="Название категории" className={this.addLoad ? "form-control  btn-primary-animate-content" : "form-control"} onChange={(e) => this.inputAction(e.target.value)} value={this.nameRed} autoFocus/>
                        <button type="button" className={this.addLoad ? "btn btn-primary btn-yes btn-primary-animate" : "btn btn-primary btn-yes"} onClick={this.save}><i className="icon icon-filter-yes"></i></button>
                        <button type="button" className={this.addLoad ? "btn btn-primary btn-yes btn-primary-animate" : "btn btn-primary btn-yes"} onClick={this.delite}><i className="icon icon-filter-remove"></i></button>
                    </div>
                </>;
            }
            return <>
                    <Link to={`/library/${this.props.id}`} className="type-group dropdown">
                        <button type="button" className={classElement}>{this.props.name}</button>
                        {this.props.active ?
                        <span className="drop-group" onClick={this.props.setupAction}>
                             <i className="icon icon-filter-type-wite"></i>
                        </span>
                        :null}
                    </Link>
                </>;
        } else {
            const classElement = this.props.active ? "btn btn-primary" : "btn border btn-primary";

            if (this.props.id === 0) {
                return <Link  to={`/library`} className="type-group dropdown">
                    <button style={{padding: '8px 12px'}} type="button" className={classElement}>{this.props.name}</button>
                </Link>;
            }

            if (this.props.setupStatus && this.props.active) {
                return <>
                    <div className="column-group column-edit" style={{marginTop: '0px'}}>
                        <input placeholder="Название категории" className={this.addLoad ? "form-control  btn-primary-animate-content" : "form-control"} onChange={(e) => this.inputAction(e.target.value)} value={this.nameRed} autoFocus/>
                        <button type="button" className={this.addLoad ? "btn btn-primary btn-yes btn-primary-animate" : "btn btn-primary btn-yes"} onClick={this.save}><i className="icon icon-filter-yes"></i></button>
                        <button type="button" className={this.addLoad ? "btn btn-primary btn-yes btn-primary-animate" : "btn btn-primary btn-yes"} onClick={this.delite}><i className="icon icon-filter-remove"></i></button>
                    </div>
                </>;
            }
            return <>
                <Link to={`/library/${this.props.id}`} className="type-group dropdown">
                    <button type="button" className={classElement}>{this.props.name}</button>
                    {this.props.active ?
                        <span className="drop-group" onClick={this.props.setupAction}>
                             <i className="icon icon-filter-type-wite"></i>
                        </span>
                        :null}
                </Link>
            </>;
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const libraryStore = this.props.libraryStore;
        if (libraryStore.openRedactor) {
            if (libraryStore.openRedactor === this.props.id && !this.props.active) {
                libraryStore.openRedactor=false;
            }
        }

    }

    save = () => {
        const libraryStore = this.props.libraryStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;
        if (this.nameRed.length === 0) {
            return false;
        }

        if (this.addLoad) return false;

        this.addLoad=true;

        APIlibrary.categoryRed(this.props.id, this.nameRed).then((data) => {
            if (!data.status) {
                alert('Ошибка');
            } else {
                APIposts.getLibrary(libraryStore.hash).then((dataLibrary) => {
                    //сохраняем посты
                    libraryStore.getPost(dataLibrary.hashPosts, dataLibrary.posts);

                    libraryStore.getCategories(dataLibrary.categories);

                    libraryStore.openRedactor=false;

                }).catch((error) => {
                    alert(text[109]);
                    libraryStore.openRedactor=false;
                });
            }
            this.addLoad=false;
        }).catch(() => {
            libraryStore.openRedactor=false;
            alert(text[110]);
            this.addLoad=false;
        });
    }

    delite = () => {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;
        if (this.delStatus === 0) {
            this.props.notificationStore.add(text[111], text[112], 10);
            this.delStatus=1;
            setTimeout(() => {
                if (this.delStatus === 1) {
                    this.delStatus = 0;
                }
            }, 5000);
        } else {
            const libraryStore = this.props.libraryStore;
            const initialStore = this.props.initialStore;

            this.addLoad=true;

            APIlibrary.categoryDel(this.props.id).then((data) => {
                if (!data.status) {
                    alert(text[28]);
                } else {
                    APIposts.getLibrary(libraryStore.hash).then((dataLibrary) => {
                        //сохраняем посты
                        libraryStore.getPost(dataLibrary.hashPosts, dataLibrary.posts);

                        libraryStore.getCategories(dataLibrary.categories);

                        libraryStore.openRedactor=false;

                        //редиректим
                        initialStore.redirect(`/library`);

                    }).catch((error) => {
                        alert(text[109]);
                        libraryStore.openRedactor=false;
                    });
                }
                this.addLoad=false;
            }).catch(() => {
                libraryStore.openRedactor=false;
                alert(text[110]);
                this.addLoad=false;
            });
        }



    }

    inputAction = (value) => {

        if (this.addLoad) return false;

        if (String(value).length > 20) {
            return false;
        }

        this.nameRed = String(value);
    }
}

