import React from "react";

const ru = {
    0: 'Шаблоны',
    1: 'Расписание',
    2: 'Сотрудники',
    3: 'Прокси',
    4: 'Сбросить авторизацию',
    5: 'Удалить аккаунт',
    6: 'Посты',
    7: 'Истории',
    8: 'Статистика',
    9: 'Директ',
    10: 'Ночь',
    11: 'Утро',
    12: 'День',
    13: 'Вечер',
    14: 'Расписание постов',
    15: 'Расписание историй',
    16: 'Нет постов',
    17: '1 пост',
    18: '2 поста',
    19: '3 поста',
    20: '4 поста',
    21: '5 постов',
    22: <>Выберите количество постов в нужные промежутки времени,<br/>и сервис будет автоматически подставлять нужное время при создании постов.</>,
    23: 'Модерация',
    24: 'Ссылки для публикаций',
    25: 'Просмотр истории публикаций',
    26: 'Просмотр публ. пользователей',
    27: 'Редактир. публ. пользователей',
    28: 'Ссылки для создания постов',
    29: 'Просмотр истории постов',
    30: 'Просмотр постов пользователей',
    31: 'Редактир. постов пользователей',
    32: (account) => 'У аккаунта ' + account + ' нет сотрудников',
    33: 'Вы можете выдать необходимые права на использование данного аккаунта вашему сотруднику.',
    34: 'Добавить сотрудника',
    35: 'Добавление сотрудника',
    36: 'Редактирование сотрудника',
    37: 'Имя пользователя',
    38: 'E-mail зарегистрированного в системе пользователя',
    39: 'Создавать постов без модерации',
    40: 'Сотрудник может создавать посты без вашей проверки',
    41: 'Создавать ссылки для добавления постов',
    42: 'Сотрудник будет иметь доступ к созданию ссылок для удаленного создания постов',
    43: 'Просмотр истории постов',
    44: 'Сотрудник будет иметь доступ к просмотру истории постов',
    45: 'Просмотр постов других пользователей',
    46: 'Сотрудник будет иметь доступ к просмотру всех созданных постов',
    47: 'Редактирование постов других пользователей',
    48: 'Сотрудник будет иметь доступ к редактированию всех постов',
    49: 'Доступ к статистике',
    50: 'Сотрудник сможет просматривать статистику на сервисе вашего аккаунта',
    51: 'Доступ к директу',
    52: 'Сотрудник сможет использовать директ вашего аккаунта',
    53: 'Вернуться назад',
    54: 'Работает',
    55: 'Не работает',
    56: 'IP',
    57: 'Порт',
    58: 'Логин',
    59: 'Пароль',
    60: 'Сброс авторизации',
    61: (account) => 'Вы уверены, что хотите сбросить авторизацию у аккаунта ' + account + '?',
    62: 'Данное действие приведет к запросу повторной авторизации для данного аккаунта на сервисе.',
    63: 'Отмена',
    64: 'Авторизация сброшена',
    65: 'Не удалось обновить аккаунты, перезагрузите страницу',
    66: 'Ошибка',
    67: 'Неизвестная ошибка, повторите попытку через 2 минуты',
    68: 'Удаление аккаунта',
    69: (account) => 'Вы уверены, что хотите удалить аккаунт ' + account + '?',
    70: 'Данное действие приведет к полному удалению аккаунта с сервиса.',
    71: 'Данное действие может занять некоторое время.',
    72: 'Дождитесь удаления аккаунта, это может занять до 10 минут',
    73: 'Аккаунт удален',
    74: 'Создание шаблона',
    75: 'Редактирование шаблона',
    76: 'Название',
    77: 'Текст шаблона',
    78: 'Аккаунт',
    79: 'Сделать шаблон общим',
    80: 'Тарифный план закончился. Продлите ваш тариф в разделе "Тарифы".',
    81: 'Тарифный план закончился. Сообщите владельцу аккаунта что требуется продлить тарифный план.',
    82: 'Тариф скоро закончится. Осталось менее 3х дней. Продлите тариф в разделе "Тарифы".',
    83: 'Тариф скоро закончится. Осталось менее 3х дней. Сообщите владельцу аккаунта что требуется продлить тарифный план.',
    84: 'Тариф скоро закончится. Осталось менее 2х дней. Продлите тариф в разделе "Тарифы".',
    85: 'Тариф скоро закончится. Осталось менее 2х дней. Сообщите владельцу аккаунта что требуется продлить тарифный план.',
    86: 'Тариф скоро закончится. Осталось менее одного дня. Продлите тариф в разделе "Тарифы".',
    87: 'Тариф скоро закончится. Осталось менее одного дня. Сообщите владельцу аккаунта что требуется продлить тарифный план.',
    88: 'Тариф скоро закончится. Осталось менее 12 часов. Продлите тариф в разделе "Тарифы".',
    89: 'Тариф скоро закончится. Осталось менее 12 часов. Сообщите владельцу аккаунта что требуется продлить тарифный план.',
    90: ['Связь с Instagram потеряна.', 'Требуется повторная авторизация аккаунта'],
    91: 'Связь с Instagram потеряна. Сообщите администратору аккаунта, что требуется повторная авторизация данного аккаунта.',
    92: ['Связь с ВКонтакте потеряна.', 'Требуется повторная авторизация аккаунта'],
    93: 'Связь с ВКонтакте потеряна. Сообщите администратору аккаунта, что требуется повторная авторизация данного аккаунта.',
    94: ['Связь с Одноклассники потеряна.', 'Требуется повторная авторизация аккаунта'],
    95: 'Связь с Одноклассники потеряна. Сообщите администратору аккаунта, что требуется повторная авторизация данного аккаунта.',
    96: ['Связь с Facebook потеряна.', 'Требуется повторная авторизация аккаунта'],
    97: 'Связь с Facebook потеряна. Сообщите администратору аккаунта, что требуется повторная авторизация данного аккаунта.',
    98: ['Связь с Telegram потеряна.', 'Требуется повторная авторизация аккаунта'],
    99: 'Связь с Telegram потеряна. Сообщите администратору аккаунта, что требуется повторная авторизация данного аккаунта.',
    100: 'обычный',
    101: 'по дням',
    102: 'лента Instagram',
    103: 'Запланированные',
    104: 'Опубликованные',
    105: 'выберите дату от',
    106: 'выберите дату до',
    107: 'Ошибка публикации',
    108: 'Ожидают удаления или архивирования',
    109: (account) => 'На аккаунте ' + account + ' нет запланированных постов',
    110: 'Нет запланированных постов',
    111: 'Создать пост',
    112: 'Ранее опубликованные посты через сервис',
    113: 'Выберите дату публикации от и до, а так же вид сортировки',
    114: 'Нет запланированных истории',
    115: (account) => 'На аккаунте ' + account + ' нет запланированных историй',
    116: 'Создать историю',
    117: 'Подписчики',
    118: 'Лайки',
    119: 'Комментарии',
    120: 'Просмотры',
    121: 'Подписчиков',
    122: 'Лайков',
    123: 'Комментариев',
    124: 'Просмотров',
    125: 'Для использования "Директа" требуется добавить личный прокси',
    126: 'Директ временно не доступен',
    127: 'Запуск',
    128: 'Загрузка',
    129: 'Загрузить еще.',
    130: '< назад',
    131: 'Диалог не выбран',
    132: 'Выберите диалог в левом списке Директа',
    133: 'времени',
    134: 'лайкам',
    135: 'комментариям',
    136: 'просмотрам',
    137: 'По времени',
    138: 'По лайкам',
    139: 'По комментариям',
    140: 'По просмотрам',
    141: 'Сортировать по',
    142: 'День',
    143: 'Неделя',
    144: 'Месяц',
    145: 'Год',
    146: ['Статистика не доступна', 'Статистика еще собирается и будет доступна через 24 часа после добавления аккаунта на сервис.'],
    147: ['Посты не найдены', 'В выбранные дни на аккаунте не было публикаций через сервис.'],
    148: 'Вид отображения: ',
    149: ['Связь с Twitter потеряна.', 'Требуется повторная авторизация аккаунта'],
    150: 'Связь с Twitter потеряна. Сообщите администратору аккаунта, что требуется повторная авторизация данного аккаунта.',
}

const en = {
    0: 'Templates',
    1: 'Timetable',
    2: 'Staff',
    3: 'Proxy',
    4: 'Reset authorization',
    5: 'Delete account',
    6: 'Posts',
    7: 'Stories',
    8: 'Statistics',
    9: 'Direct',
    10: 'Night',
    11: 'Morning',
    12: 'Day',
    13: 'Evening',
    14: 'Post schedule',
    15: 'Story schedule',
    16: 'No posts',
    17: '1 post',
    18: '2 posts',
    19: '3 posts',
    20: '4 posts',
    21: '5 posts',
    22: <>Select the number of posts at the desired time intervals,<br/> and the service will automatically fill in the right time when creating posts.</>,
    23: 'Moderation',
    24: 'Links for publications',
    25: 'Viewing post history',
    26: 'Viewing user posts',
    27: 'Edit user posts',
    28: 'Links for creating posts',
    29: 'Viewing post history',
    30: 'View user posts',
    31: 'Edit user posts',
    32: (account) => account + ' account has no employees',
    33: 'You can grant the necessary rights to use this account to your employee.',
    34: 'Add employee',
    35: 'Adding an employee',
    36: 'Editing an employee',
    37: 'Username',
    38: 'E-mail of the user registered in the system',
    39: 'Create posts without moderation',
    40: 'An employee can create posts without your verification',
    41: 'Create links to add posts',
    42: 'The employee will have access to create links to create posts remotely',
    43: 'Viewing post history',
    44: 'The employee will have access to view the post\'s history',
    45: 'View posts of other users',
    46: 'The employee will have access to view all created posts',
    47: 'Editing posts of other users',
    48: 'The employee will have access to edit all the posts',
    49: 'Statistic\'s access',
    50: 'An employee will be able to view statistics on the service of your account',
    51: 'Direct access',
    52: 'The employee will be able to use your direct account',
    53: 'Come back',
    54: 'Work',
    55: 'Does not work',
    56: 'IP',
    57: 'Port',
    58: 'Login',
    59: 'Password',
    60: 'Reset authorization',
    61: (account) => 'Are you sure that you want to reset the ' + account + ' account authorization?',
    62: 'This action will lead to a request for re-authorization for this account on the service.',
    63: 'Cancel',
    64: 'Authorization reset',
    65: 'Failed to update accounts please reload the page',
    66: 'Error',
    67: 'Unknown error, try after 2 minutes',
    68: 'Lets delete an account',
    69: (account) => 'Are you sure you want to delete ' + account + ' account?',
    70: 'This action will remove all data from account.',
    71: 'This action may take some time.',
    72: 'Wait until your account is deleted, it may take up to 10 minutes',
    73: 'Account has been deleted',
    74: 'New template',
    75: 'Editing a template',
    76: 'Name',
    77: 'Text for templates',
    78: 'Account',
    79: 'Template for all accounts',
    80: 'The tariff plan has ended. Extend your tariff in the "Tariffs" section.',
    81: 'The tariff plan has ended. Inform the account owner that you want to renew your plan.',
    82: 'The tariff will end soon. Less than 3 days left. Extend your tariff in the "Tariffs" section.',
    83: 'The tariff will end soon. Less than 3 days left. Inform the account owner that you want to renew your plan.',
    84: 'The tariff will end soon. Less than 2 days left. Extend your tariff in the "Tariffs" section.',
    85: 'The tariff will end soon. Less than 2 days left. Inform the account owner that you want to renew your plan.',
    86: 'The tariff will end soon. Less than one day left. Extend your tariff in the "Tariffs" section.',
    87: 'The tariff will end soon. Less than one day left. Inform the account owner that you want to renew your plan.',
    88: 'The tariff will end soon. Less than 12 hours left. Extend your tariff in the "Tariffs" section.',
    89: 'The tariff will end soon. Less than 12 hours left. Inform the account owner that you want to renew your plan.',
    90: ['Communication with Instagram has been lost.', 'Account re-authorization required'],
    91: 'Communication with Instagram has been lost. Inform your account administrator that you need to re-authorize this account.',
    92: ['Communication with VKontakte has been lost.', 'Account re-authorization required'],
    93: 'Communication with VKontakte has been lost. Inform your account administrator that you need to re-authorize this account.',
    94: ['Communication with Odnoklassniki has been lost.', 'Account re-authorization required'],
    95: 'Communication with Odnoklassniki has been lost. Inform your account administrator that you need to re-authorize this account.',
    96: ['Communication with Facebook has been lost.', 'Account re-authorization required'],
    97: 'Communication with Facebook has been lost. Inform your account administrator that you need to re-authorize this account.',
    98: ['Communication with Telegram has been lost.', 'Account re-authorization required'],
    99: 'Communication with Telegram has been lost. Inform your account administrator that you need to re-authorize this account.',
    100: 'Standard',
    101: 'By days',
    102: 'Instagram feed',
    103: 'Planned',
    104: 'Published',
    105: 'select date from',
    106: 'select date before',
    107: 'Post error',
    108: 'Pending deletion or archiving',
    109: (account) => 'There are no scheduled posts on the ' + account + ' account',
    110: 'No scheduled posts',
    111: 'Create a post',
    112: 'Previously published posts through the service',
    113: 'Select the publication date from and to, as well as the type of sorting',
    114: 'No scheduled history',
    115: (account) => 'There are no scheduled stories on the ' + account + ' account',
    116: 'Create story',
    117: 'Followers',
    118: 'Likes',
    119: 'Comments',
    120: 'Views',
    121: 'Followers',
    122: 'Likes',
    123: 'Comments',
    124: 'Views',
    125: 'A personal proxy is required to use direct',
    126: 'Direct is temporarily unavailable',
    127: 'Start',
    128: 'Loading',
    129: 'Load more.',
    130: '< back',
    131: 'No dialogue selected',
    132: 'Select a dialog in the left list of direct',
    133: 'time',
    134: 'likes',
    135: 'comments',
    136: 'views',
    137: 'By time',
    138: 'By likes',
    139: 'By comments',
    140: 'By views',
    141: 'Sort by',
    142: 'Day',
    143: 'Week',
    144: 'Month',
    145: 'Year',
    146: ['Statistics not available', 'Statistics are still being collected and will be available in 24 hours after adding an account to service.'],
    147: ['No posts found', 'On the selected days, there were no publications on the account through the service.'],
    148: 'Display type: ',
    149: ['Communication with Twitter has been lost.', 'Account re-authorization required'],
    150: 'Communication with Twitter has been lost. Please inform your account administrator that you need to re-authorize this account.',
}

const account = {
    ru: ru,
    en: en,
}

export default account;
