import React from 'react';

import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import LoaderApp from "../../templates/loaderApp";
import VievPost from "../../component/modalVievPost/VievPost";
import Logo from "../../media/img/sidebar/logo.svg";
import ModalDelMedia from "../../component/modalDelMedia/ModalDelMedia";
import Notification from "../../component/Notification";



@inject('initialStore', 'postStore')
@observer
class Post extends React.Component {

    @observable loadCSS = false;

    @observable initial = false;

    @observable redirect = null;

    constructor (props) {
        super(props);

        /*
                Меньтше 769 полная мобилка
                Меньше 993 мобилка но модалки уже дисктопные
                Все что больше 993 уже полноценный режим
                 */
        const width=document.body.clientWidth;//992
        const height=window.innerHeight;
        this.props.initialStore.width = width;
        this.props.initialStore.height = height;



        if (width < 993) {
            this.props.initialStore.templates = 'mobile';

            this.props.initialStore.limitVisualPostsPlasDefoult = 9;

            (async () => {
                // import module for side effects
                await import ('../../media/css-mobile/main.css');
                await import ('../../media/css-mobile/root.css');
                await import ('../../media/css-mobile/media.css');

                this.loadCSS = true;
            })();
        } else {
            this.props.initialStore.templates = 'desktop';

            (async () => {
                // import module for side effects
                await import ('../../media/css/main.css');
                await import ('../../media/css/root.css');
                await import ('../../media/css/media.css');

                this.loadCSS = true;
            })();
        }
    }

    render() {
        //alert(this.props.match.params.post);
        if (!this.loadCSS) {
            return <LoaderApp name={this.redirect} loadingOff/>;
        }


        let content = <>
            <div className="main">
                <div className="main-wrapper">
                    <div className="lk-load">
                        <div className="lk-load-name lk-load-name-2">
                            ваш помощник по социальным сетям<br/>
                            <div className="viev-post-box-button">
                                <div className="viev-post-box-button-line-3">
                                    <button style={{border: '1px solid #fff'}} onClick={this.open} className="btn btn-primary">Открыть пост</button>
                                </div>
                                <div className="viev-post-box-button-line-1">
                                    <div className="viev-post-box-button-line-1-1">
                                        <a style={{border: '1px solid #fff'}} href={'https://likepro.ru/'} className="btn btn-primary">О сервисе</a>
                                    </div>
                                    <div className="viev-post-box-button-line-1-2">

                                        <a style={{border: '1px solid #fff'}} href={'/login'} className="btn btn-primary">Войти</a>
                                    </div>
                                </div>
                                <div className="viev-post-box-button-line-2">
                                    <a style={{border: '1px solid #fff'}} href={'/registration'} className="btn btn-primary">Зарегистрироваться</a>

                                </div>
                            </div>

                            </div>
                        <div className="inner-load">
                            <img src={Logo} className="brand-load"/>
                            <span className="title-load">LikePro.ru</span>
                        </div>
                    </div>
                </div>
            </div>
            <VievPost/>
            <ModalDelMedia/>
            <div className="notifications">
                <Notification/>
            </div>
        </>;
        return content;
    }

    open = () => {
        const postStore = this.props.postStore;

        let post = false;

        if (this.props.match.params.post) {
            post = this.props.match.params.post;
        }

        post = post.split("_");

        if (!post[1]) {
            post[1] = 'false';
        }


        if (this.loadCSS) {
            if (!postStore.open) {
                postStore.openActive(post[1], post[0], false);
            }
        }
    }

    componentDidUpdate() {
        this.open();
    }
}
export default Post;
