import React from 'react';
import {inject, observer} from "mobx-react";

import Time from "../../../model/time";

import iconInst from "../../../media/img/socialNetworks/inst.svg";
import iconFB from "../../../media/img/socialNetworks/fb.svg";
import iconVK from "../../../media/img/socialNetworks/vk.svg";
import iconOK from "../../../media/img/socialNetworks/ok.svg";
import iconTG from "../../../media/img/socialNetworks/tg.svg";
import iconTwitter from "../../../media/img/socialNetworks/twitter.svg";
import iconInstFb from "../../../media/img/socialNetworks/inst.fb.svg";

import {observable} from "mobx";
import PostTemplateAccounts from "../../../templates/post/accounts";

@inject('mediaStore', 'accountsStore', 'notificationStore', 'initialStore')
@observer
class Accounts extends React.Component {

    @observable searhValue = '';

    constructor (props) {
        super(props);
        this.accountAdd = this.accountAdd.bind(this);
        this.groupAdd = this.groupAdd.bind(this);
    }

    render() {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;
        const notificationStore = this.props.notificationStore;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        const accounts = accountsStore.list.slice();
        const accountsOK = mediaStore.accounts.slice();


        let groups = [];

        let activeAccountJSX = null;

        for (const element of accountsStore.groups) {

            let AccountsI = 0;
            let groupEnter = 0;
            let accountList = accountsStore.list.map((item, key) => {

                if (mediaStore.type === 'story' && (item.tip !== 'instagram' && item.tip !== 'vk')) {
                    return null;
                }
                if (mediaStore.type === 'story' && item.tip === 'vk' && item.storyTrue === false) {
                    return null;
                }
                if (mediaStore.type === 'multyStory' && item.tip !== 'instagram') {
                    return null;
                }
                if (mediaStore.type === 'igtv' && item.tip !== 'instagram') {
                    return null;
                }
                if (mediaStore.type === 'reels' && item.tip !== 'instagram') {
                    return null;
                }
                if (mediaStore.type === 'editStory' && item.tip !== 'instagram') {
                    return null;
                }
                if (mediaStore.type === 'editIgtv' && item.tip !== 'instagram') {
                    return null;
                }
                if (mediaStore.type === 'editReels' && item.tip !== 'instagram') {
                    return null;
                }

                if (item.group !== element.id) return;

                AccountsI++;

                let activeAccount = false;
                for (let keys in accountsOK) {
                    if (accountsOK[keys].id === accounts[key].id) {
                        activeAccount = true;
                        groupEnter++;
                    }
                }

                //поиск совпадения в поиске
                if (!activeAccount && this.searhValue !== '') {
                    if(item.name.toUpperCase().indexOf(this.searhValue.toUpperCase()) + 1 === 0) {
                        return;
                    }
                }

                //иконка
                let icon = '';
                if (item.tip === 'instagram') {
                    icon = iconInst;
                } else if (item.tip === 'vk') {
                    icon = iconVK;
                } else if (item.tip === 'fb') {
                    icon = iconFB;
                } else if (item.tip === 'ok') {
                    icon = iconOK;
                } else if (item.tip === 'tg') {
                    icon = iconTG;
                } else if (item.tip === 'twitter') {
                    icon = iconTwitter;
                } else if (item.tip === 'fb-instagram') {
                    icon = iconInstFb;
                }



                const elementBox = (
                    <li key={key}>
                        <div className="sidebar-user" onClick={() => this.accountAdd(item.id)}>
                            <div className="inner-user">
                                <div className="img-user">
                                    <span className="social-user">
                                        <img src={icon} />
                                    </span>
                                    <span className="inner-img">
                                        <img src={item.img}/>
                                    </span>
                                </div>
                                <span className={activeAccount? "name-user" : "name-user name-user-nonActive"}>{item.name}</span>
                                {activeAccount ? <i className="icon icon-user-yes"></i>:null}
                            </div>
                        </div>
                    </li>);

                //для редактирования поста
                if (activeAccount) {
                    activeAccountJSX = <li>
                        <div className="sidebar-user" onClick={() => {notificationStore.add('', 'При редактированни поста, нельзя редактировать аккаунт')}}>
                            <div className="inner-user">
                                <div className="img-user">
                                    <span className="social-user">
                                        <img src={icon} />
                                    </span>
                                    <span className="inner-img">
                                        <img src={item.img}/>
                                    </span>
                                </div>
                                <span className="name-user">{item.name}</span>
                                <i className="icon icon-user-yes"></i>
                            </div>
                        </div>
                    </li>;
                }

                return elementBox;
            });

            if (!AccountsI) {
                continue;
            }

            const functionClickGroupActivation = () => {
                if (element.name !== '') {
                    const type = AccountsI == groupEnter ? false : true;
                    this.groupAdd(element.id, type);
                }
            }

            groups.push(
                <div className="sidebar-users__group">
                    <span className="title-group title-group-post flex" onClick={functionClickGroupActivation}>
                        <span className={AccountsI == groupEnter && element.name !== '' ? null:'name-user-nonActive'}>{element.name === '' ? ' ':element.name}</span>
                        {AccountsI == groupEnter && element.name !== '' ? <i className="icon icon-user-yes"></i>:null}
                    </span>
                    <ul className="sidebar-users__list">

                        {accountList}

                    </ul>
                </div>
            );
        }

        let nameBox = text[0];

        /*if (mediaStore.type === 'multyPost' || mediaStore.type === 'multyStory') {
            nameBox = text[1];
        }*/

        if (mediaStore.type === 'edit') {
            nameBox = text[2];

            groups = <>
                <div className="sidebar-users__group">
                    <span className="title-group title-group-post flex">
                        <span></span>
                    </span>
                    <ul className="sidebar-users__list">
                        {activeAccountJSX}
                    </ul>
                </div>
            </>;
        }

        if (!accounts.length) {
            groups = <div className="non-account-add-post">{text[3]}</div>;
        }

        let render = (
            <PostTemplateAccounts
                nameBox={nameBox}
                onChange={(value)=> this.onChangeSearhValue(value)}
                searhValue={this.searhValue}
                groups={groups}
            />
        );

        if (mediaStore.type === 'edit' || mediaStore.type === 'editStory' || mediaStore.type === 'editIgtv' || mediaStore.type === 'editReels') {
            render = null;
        }

        return render;
    }

    onChangeSearhValue = (value) => {
        this.searhValue = value;
    }

    groupAdd (groupID, type) {

        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        /*if (mediaStore.type === 'multyPost' || mediaStore.type === 'multyStory') {
            return true;
        }*/

        const accounts = accountsStore.list.slice();
        const accountsOK = mediaStore.accounts.slice();

        //const element = accountsStore.groups[groupID];

        for (const element of accountsStore.list) {
            if (groupID !== element.group) continue;

            let ActiveAccount = false;
            for (let keys in accountsOK) {
                if (accountsOK[keys].id === element.id) {
                    if (!type) {
                        this.accountAdd(element.id);
                        continue;
                    }else {
                        ActiveAccount = true;
                    }
                }
            }

            if (!ActiveAccount && type) {
                this.accountAdd(element.id);
            }

        }
    }

    accountAdd (event) {

        const mediaStore = this.props.mediaStore;
        const accountsStore = this.props.accountsStore;
        const notificationStore = this.props.notificationStore;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        const accountsOK = mediaStore.accounts.slice();
        const accounts = accountsStore.list.slice();

        let image = 0;
        let video = 0;
        for (const media of mediaStore.media.slice()) {
            if (media.type === 'image') {
                image++;
            } else {
                video++;
            }
        }

        for (let keys in accounts) {
            if (accounts[keys].id === event) {
                if (accounts[keys].tip === 'fb') {

                    if (video && image) {
                        notificationStore.add(text[4], text[5]);
                        return false;
                    }

                    if (video > 1) {
                        notificationStore.add(text[4], text[5]);
                        return false;
                    }
                }
                if (accounts[keys].tip === 'fb-instagram') {
                    if (video && image) {
                        notificationStore.add(text[4], text[218]);
                        return false;
                    }

                    if (video> 1 || image > 1) {
                        notificationStore.add(text[4], text[218]);
                        return false;
                    }


                }
                if (accounts[keys].tip === 'ok') {

                    /*if (video && image) {
                        notificationStore.add('Аккаунт не выбран', 'В социальную сеть Одноклассники можно добавлять или одно видео или изображения до 10 штук');
                        return false;
                    }*/

                   /* if (video > 1) {
                        notificationStore.add('Аккаунт не выбран', 'В социальную сеть Одноклассники можно добавлять или одно видео или изображения до 10 штук');
                        return false;
                    }*/
                }

                if (accounts[keys].tip === 'vk' || accounts[keys].tip === 'fb' || accounts[keys].tip === 'ok' || accounts[keys].tip === 'tg') {

                    if (accountsOK[0] !== undefined) {
                        if (!mediaStore.timePro && accountsOK[0].del.status === 2) {
                            for (const accountReDelKey in accountsOK) {
                                if (accountsOK[accountReDelKey].del.status === 2) {
                                    mediaStore.accounts[accountReDelKey].del.status = 1;
                                }
                            }
                        }

                        /*if (mediaStore.multyPost.slice().length) {
                            for (const accountReDelKey in mediaStore.multyPost.slice()) {
                                if (mediaStore.multyPost[accountReDelKey].del.status === 2) {
                                    mediaStore.multyPost[accountReDelKey].del.status = 1;
                                }
                            }
                        }*/
                    }

                }

            }

        }





        let del = false;
        let textI = '';
        if (accountsOK.length) {
            const accountsTimeDonor = accountsOK.length-1;
            let time = accountsOK[accountsTimeDonor].time;
            textI = accountsOK[accountsTimeDonor].text;
            del = {...accountsOK[accountsTimeDonor].del};
            if (mediaStore.timePro) {
                time = Time.dateTimetableGenerator(event, 'post')[0];

                for (let keys in accounts) {
                    if (accounts[keys].id === event) {
                        if (accounts[keys].tip === 'vk' || accounts[keys].tip === 'ok' || accounts[keys].tip === 'fb' || accounts[keys].tip === 'tg') {
                            if (del.status === 2) {
                                del.status = 1;
                            }
                        }
                        break;
                    }
                }
            }

            this.props.mediaStore.actionAccount(event, time, textI, del);
        } else {
            textI = mediaStore.textSavePost;

            const time = Time.dateTimetableGenerator(event, 'post')[0];
            this.props.mediaStore.actionAccount(event, time, textI, del);
        }

        if (mediaStore.accounts.slice().length <= 1) {
            if (mediaStore.textPro) {
                mediaStore.actionTextPro(false);
            }

            if (mediaStore.timePro) {
                mediaStore.actionTimePro(false);
            }
        }
    }
}

export default Accounts;
