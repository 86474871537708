import {Link} from "react-router-dom";
import React from "react";
import Input from "../../templates/elements/input";
import Password from "../../templates/elements/password";
import Button from "../../templates/elements/button";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

export default
@inject('initialStore')
@observer
class form extends React.Component {

    @observable focus = false;

    onFocus = () => {
        this.focus = true;
    }

    onBlur = () => {
        this.focus = false;
    }

    render() {

        const initialStore = this.props.initialStore;
        const text = initialStore.text.login;

        const width=document.body.clientWidth;//992

        if (width >= 993) {
            return (
                <div className="floatScreen">
                    <div className="auth">

                        <div className="logo_white"></div>

                        <div className="title1">{text[7]}</div>


                        <div className="form_group">

                            <div className="input-box">
                                <span className="input-label1">{text[1]}</span>
                                <div className="group-form">
                                    <div className="column-form w-100">
                                        <Input className="form-control view-22" load={this.props.load} onChange={this.props.mailOnChange}>{this.props.mail}</Input>
                                    </div>
                                </div>
                            </div>

                            <div className="input-box">
                                <span className="input-label1">{text[9]}</span>
                                <div className="group-form">
                                    <div className="column-form w-100">
                                        <Password className="form-control view-22" load={this.props.load} onChange={this.props.passwordOnChange}>{this.props.password}</Password>
                                    </div>
                                </div>
                            </div>

                            {this.props.inform !== '' ?
                                <div className="informError">
                                    {this.props.inform}
                                </div>
                                :null}

                            <div className="input-box">

                                <Button div={true} className="btn1 border1 btn-primary1" style={{width: '100%'}} load={this.props.load} action={this.props.okOnClick}>registration</Button>
                            </div>

                            <div  className="input-box input-box-info">
                                {text[4]}
                            </div>

                        </div>

                        <div className="box-info-reg">
                            <div className="box-info-reg-info">{text[10]}</div>

                            <div className="box-info-reg-button"><Link to="/login">{text[11]}</Link></div>
                        </div>

                    </div>
                </div>
            );
        } else {

            //logo_white
            let logoWhiteClasses = "logo_white logo_white_animation";

            if (this.focus) {
                logoWhiteClasses = "logo_white logo_white_animation logo_white_animation_finish";
            }

            return (
                <div className="floatScreen">
                    <div className="auth">

                        <div className={logoWhiteClasses}></div>

                        <div className="title1">{text[7]}</div>


                        <div className="form_group">

                            <div className="input-box">
                                <span className="input-label1">{text[1]}</span>
                                <div className="group-form">
                                    <div className="column-form w-100">
                                        <Input onFocus={this.onFocus} onBlur={this.onBlur} className="form-control view-22" load={this.props.load} onChange={this.props.mailOnChange}>{this.props.mail}</Input>
                                    </div>
                                </div>
                            </div>

                            <div className="input-box">
                                <span className="input-label1">{text[9]}</span>
                                <div className="group-form">
                                    <div className="column-form w-100">
                                        <Password onFocus={this.onFocus} onBlur={this.onBlur} className="form-control view-22" load={this.props.load} onChange={this.props.passwordOnChange}>{this.props.password}</Password>
                                    </div>
                                </div>
                            </div>

                            {this.props.inform !== '' ?
                                <div className="informError">
                                    {this.props.inform}
                                </div>
                                :null}

                            <div className="input-box">

                                <Button div={true} className="btn1 border1 btn-primary1" style={{width: '100%'}} load={this.props.load} action={() => {this.onBlur(); this.props.okOnClick();}}>registration</Button>
                            </div>

                            <div  className="input-box input-box-info">
                                {text[4]}
                            </div>

                        </div>

                        <div className="box-info-reg">
                            <div className="box-info-reg-info">{text[10]}</div>

                            <div className="box-info-reg-button"><Link to="/login">{text[11]}</Link></div>
                        </div>




                    </div>
                </div>
            );
        }

    }

};