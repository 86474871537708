import React from 'react';
import {inject, observer} from "mobx-react";
import ReactDOM from "react-dom";
import {observable} from "mobx";

import APIposts from "../../api/posts";
import {createLoadPost} from "../../actions/posts/createLoadPost";

@inject('modalSearhLibraryPost', 'notificationStore', 'libraryStore', 'initialStore')
@observer
class ModalSearhLibraryPost extends React.Component{

    @observable category = 0;

    constructor (props) {
        super(props);

        this.close = this.close.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.window = React.createRef();

        this.open = false;
    }

    render() {

        const modalSearhLibraryPost = this.props.modalSearhLibraryPost;

        const libraryStore = this.props.libraryStore;
        const posts = libraryStore.list.slice();
        const categoriesList = libraryStore.categories.slice();

        const initialStore = this.props.initialStore;
        const text = initialStore.text.library;

        if (!modalSearhLibraryPost.open) {

            if (this.open) {
                this.open=false;
                this.updateStatusCloseFon();
            }

            return null;
        }

        if (!this.open) {
            this.open=true;
            this.updateStatusCloseFon();
        }

        let categories = [];

        for (const categoryElement of categoriesList) {
            categories.push(<button
                key={categoryElement.id}
                onClick={() => {this.category = categoryElement.id}}
                type="button"
                className={this.category === categoryElement.id ? "btn btn-primary":"btn border btn-primary"}>{categoryElement.name}</button>);
        }

        let postsArray = [];

        for (const item of posts) {
            if (this.category) {
                if (item.category !== this.category) {
                    continue;
                }
            }

            postsArray.push(<div key={item.post.code} className="item-link save" onClick={() => this.getInfoPost(item.post.code)}><span>{item.post.name}</span></div>);
        }


        if (!postsArray.length) {
            if (!this.category) {
                postsArray = <div className="non-post-library-modal">{text[9]}</div>;
            } else {
                postsArray = <div className="non-post-library-modal">{text[10]}</div>;
            }
        }

        const content = (
            <div className="post-save-tabs">
                <div className="links-tab">
                    <div className="filter-group">
                        <div className="column-group column-type">
                            {categories}
                        </div>
                    </div>
                </div>
                <div className="cnt-tab">
                    <div className="item-tab">
                        <div className="post-add-links">
                            {postsArray}
                        </div>
                    </div>
                </div>
            </div>
        );



        let render = <>
                <div className="info-wrap">
                    <div className="info-scroll">
                        <div className="info-box-wrap">
                            <div className="info-box chief width-2" ref={this.window}>
                                <span onClick={this.close} className="close-box"><i className="icon icon-close-info"></i></span>
                                <span className="title-box">{text[0]}</span>
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-overlay"></div>
            </>;

        return render;
    }

    getInfoPost = (code) => {

        setTimeout(() => {
            this.open = false;
        }, 0);

        APIposts.getInfoPost(false, code).then((data) => {
            if (!data.status) {
                alert('Ошибка получения данных');
                return false;
            } else {
                createLoadPost(data.date);
            }
        }).catch((e) => {

        }).finally(() => {

        });
    }

    updateStatusCloseFon = () => {
        if (this.open) {
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    close () {
        const modalSearhLibraryPost = this.props.modalSearhLibraryPost;
        modalSearhLibraryPost.open = false;
    }

    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже

                const modalSearhLibraryPost = this.props.modalSearhLibraryPost;

                if (modalSearhLibraryPost.open) {
                    modalSearhLibraryPost.open = false;
                }
            }
        } catch(error) {
            return null;
        }
    }
}

export default ModalSearhLibraryPost;