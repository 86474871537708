import React from 'react';
import Draggable from "react-draggable";

import MediaWindow from "./MediaWindow";
import {observable} from "mobx";
import {inject, observer} from "mobx-react";

import style from "./mediaCover.module.css";

@inject('editorStore')
@observer
class MediaCover extends React.Component {


    @observable widthRedactor = 600;
    @observable max = this.widthRedactor-60;
    @observable durationDisplay = 'none';

    constructor (props) {
        super(props);

        this.drag = this.drag.bind(this);

        this.durationDisplayUpdate = this.durationDisplayUpdate.bind(this);
        this.duration = 0;
        this.timerId = false;

        const width=document.body.clientWidth;

        if (width < 769) {
            this.max = width - 78 - 60;
            this.widthRedactor= width - 78;
        }

        this.video = this.props.videoCover;

    }

    render() {
        const editorStore = this.props.editorStore;

        const full = editorStore.file.duration.finish - editorStore.file.duration.start;
        const cover = editorStore.file.duration.cover - editorStore.file.duration.start;
        const scroll = cover/(full/100);

        const icons = editorStore.file.duration.iconsCover;
        const iconsDop = editorStore.file.duration.icons;

        const MediaWindowProps = {
            backgroud: this.props.backgroud,
            stateObertka: this.props.stateObertka,
            content: this.props.content,
            draggable: this.props.draggable,
            getDraggableBounds: this.props.getDraggableBounds,
            handleDrag: false,
            type: this.props.type,
            url: this.props.url,
            onMouseDownBox: this.props.onMouseDownBox,
            boxRef: this.props.boxRef,
            disabled: true,
            currentTime: editorStore.file.duration.cover,
            video: this.video,
        };






        const DraggableProps = {
            axis: 'x',
            position: {
                x: Math.round(this.max / 100 * scroll),
                y: 0,
            },
            bounds: {
                left: 0,
                right: this.max,
            },
            onDrag: (event, ui) =>{this.drag(event, ui)},
        };

        const iconsJSX = [];
        if (icons.slice().length > 0) {

            const widthIcon = (this.widthRedactor / icons.slice().length);

            for (const iconsKey in icons.slice()) {
                iconsJSX.push(<img width={widthIcon} height={60} src={icons[iconsKey]}/>);
            }
        } else if (iconsDop.slice().length > 0){

            const widthIcon = (this.widthRedactor / iconsDop.slice().length);

            for (const iconsKey in iconsDop.slice()) {
                iconsJSX.push(<img width={widthIcon} height={60} src={iconsDop[iconsKey]}/>);
            }
        } else {


            //Высчитываем количество иконок
            let nadoIcons = 10;
            const width=document.body.clientWidth;
            if (width < 769) {
                const widthMax = width - 78;//241
                nadoIcons = Math.ceil(widthMax/60);
            }

            const widthIcon = (this.widthRedactor / nadoIcons);

            for (let i=0; i<nadoIcons; i++) {
                iconsJSX.push(<img width={widthIcon} height={60} src={editorStore.file.icon}/>);
            }


        }


        const component = <>
            <div className="editor-top">
                <div className={style.sek} style={{display: this.durationDisplay, height: MediaWindowProps.backgroud.height, lineHeight: MediaWindowProps.backgroud.height+'px'}}>{this.duration}</div>
                <MediaWindow {...MediaWindowProps}/>
            </div>

            <div className="editor-cnt">
                <div className="editor-pruning">
                    <div className="editor-pruning__wrap">
                        <div className="editor-pruning__items">
                            <div style={{width: this.widthRedactor, height: 60, position: 'absolute'}}>
                                <Draggable {...DraggableProps}>
                                    <div style={{width: 60, height: 60, outline: 'solid 4px #7b5c9b', cursor: 'col-resize'}}>
                                        <span className="coder-element-time count-item">{Math.round(this.props.editorStore.file.duration.cover)}</span>
                                    </div>
                                </Draggable>
                            </div>
                            {iconsJSX}
                        </div>

                    </div>
                </div>
            </div>


        </>;

        return component;
    }

    componentDidMount() {
        const editorStore = this.props.editorStore;

        const duration = editorStore.file.duration.cover;

        this.duration = duration;
    }

    drag(event, ui) {
        const editorStore = this.props.editorStore;

        const x = ui.x;

        const scroll = Math.ceil((x / (this.max/100))*1000)/1000;

        const full = editorStore.file.duration.finish - editorStore.file.duration.start;

        const cover = Math.ceil(((full/100*scroll)+editorStore.file.duration.start)*100)/100;

        this.props.editorStore.file.duration.cover = cover;

        this.durationDisplayUpdate();
    }

    durationDisplayUpdate () {

        const editorStore = this.props.editorStore;

        const duration2 = editorStore.file.duration.cover;

        if (duration2 !== this.duration) {
            this.durationDisplay = 'initial';
            this.duration = Math.ceil((duration2)*10)/10;

            clearTimeout(this.timerId);

            this.timerId = setTimeout(() => {
                this.durationDisplay = 'none';
            }, 1500);
        }
    }

    componentWillUnmount () {
        const editorStore = this.props.editorStore;

        if (editorStore.file.duration.cover !== editorStore.fileBlobCoverTime) {
            this.props.createIcon(this.video);
        }
    }
}

export default MediaCover;
