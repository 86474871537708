import React from 'react';
import {inject, observer} from "mobx-react";
import ReactDOM from "react-dom";

import APIuser from "../../api/user";

import TemplateModalAddAccountFb from "../../templates/modal/addAccount/templateModalAddAccountFb";
import TemplateModalAddAccountFbRe from "../../templates/modal/addAccount/templateModalAddAccountFbRe";

@inject('modalAddAccount', 'notificationStore', 'initialStore', "accountsStore")
@observer
class ModalAddAccountFb extends React.Component {

    constructor(props) {
        super(props);
        this.timer = false;
    }

    render() {
        const modalAddAccount = this.props.modalAddAccount;

        if (modalAddAccount.level === 0) {
            return (
                <TemplateModalAddAccountFb
                    close={modalAddAccount.closeActive}
                    url={modalAddAccount.vk_url}
                    activation={this.activation}
                />
            );
        } else {
            return (
                <TemplateModalAddAccountFbRe
                    close={modalAddAccount.closeActive}
                    url={modalAddAccount.vk_url}
                    activation={this.activation}
                />
            );
        }
    }

    activation = () => {
        const accountsStore = this.props.accountsStore;
        const notificationStore = this.props.notificationStore;
        const modalAddAccount = this.props.modalAddAccount;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;

        this.timer = setInterval(() => {
            APIuser.getLogin(true).then((datas) => {

                if ( accountsStore.list.slice().length !== datas.accounts.length ) {
                    accountsStore.updateInitialGroups(datas.groups);
                    accountsStore.updateInitial(datas.accounts);

                    notificationStore.add(text[28], '', 20);
                    modalAddAccount.closeActive();
                } else {
                    let error = 0;
                    for (const element of accountsStore.list.slice()) {
                        if (element.error === true) error++;
                    }

                    let errorNew = 0;
                    for (const element of datas.accounts) {
                        if (element.error === true) errorNew++;
                    }

                    if (error > errorNew) {
                        accountsStore.updateInitialGroups(datas.groups);
                        accountsStore.updateInitial(datas.accounts);

                        notificationStore.add(text[28], '', 20);
                        modalAddAccount.closeActive();
                    }
                }

            });
        }, 3000);
    }
    componentWillUnmount () {
        clearInterval(this.timer);
    }
}

export default ModalAddAccountFb;