import React from 'react';
import {inject, observer} from "mobx-react";

export default
@inject('initialStore')
@observer
class TitleH1 extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return (
                <div style={this.props.style} className="chief__typical h1">
                    <h1>{this.props.children}</h1>
                </div>
            );
        } else {
            return (
                <div className="chief__typical h1">
                    <h1>{this.props.children}</h1>
                </div>
            );
        }

    }
}

