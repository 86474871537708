import React from 'react';
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import classes from "../../../component/header/HeaderAccount.module.css";
import {observable} from "mobx";
import Time from "../../../model/time";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {formatDate, parseDate} from "react-day-picker/moment";
import Searh from "../../../component/HistoryPosts/Searh";

export default
@inject('initialStore')
@inject('accountsStore')
@inject('menuStore')
@observer
class AccountTimeTableScheduleCol extends React.Component {

    constructor(props) {
        super(props);


    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return (
                <div className="schedule__col">
                    <div className="schedule__item">
                        <span className="title-item">{this.props.name}</span>
                        <ul className="list-item">
                            {this.props.children}
                        </ul>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="schedule__col">
                    <div className="schedule__item">
                        <span className="title-item">{this.props.name}</span>
                        <ul className="list-item">
                            {this.props.children}
                        </ul>
                    </div>
                </div>
            );
        }
    }
}

