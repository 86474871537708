import React from 'react';
import {inject, observer} from "mobx-react";

import Chart from "chart.js";

import APIstatistics from "../../../api/statistics";
import {observable} from "mobx";
import Time from "../../../model/time";
import LoaderComponent from "../../../templates/loaderComponent";
import AccountStatisticsFilterElement from "../../../templates/account/statistics/filterElement";
import AccountStatisticsFilter from "../../../templates/account/statistics/filter";
import AccountStatisticsBoxListStatistics from "../../../templates/account/statistics/boxListStatistics";
import AccountStatisticsBox from "../../../templates/account/statistics/box";
import InfoContainer from "../../../templates/infoConteiner";


@inject('menuStore', 'accountsStore', 'initialStore')
@observer
class Statistics extends React.Component {

    @observable statistic = false;
    @observable tip = 0;
    @observable user = false;

    @observable followersPlus = 0;
    @observable likesFull = 0;
    @observable commentsFull = 0;
    @observable viewsFull = 0;
    @observable visual = true;


    constructor(props) {
        super(props);

        this.chartRefFollowers = React.createRef();
        this.chartRefLikes = React.createRef();
        this.chartRefComments = React.createRef();
        this.chartRefViews = React.createRef();

        this.clickTip = this.clickTip.bind(this);
        this.loadStatistics = this.loadStatistics.bind(this);
    }

    clickTip(tip) {

        if (this.tip === tip) {
            return false;
        }

        this.tip = tip;
        this.statistic = false;

        this.loadStatistics();
    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        let content = null;
        if (!this.statistic) {
            content = <LoaderComponent/>;
        } else {

            if (!this.visual) {
                content = <InfoContainer
                    title={text[146][0]}
                    text={text[146][1]}
                />;
                return content;
            }

            content = <AccountStatisticsBoxListStatistics>
                <AccountStatisticsBox name={text[117]} info={this.followersPlus}>
                    <canvas id="myChart" ref={this.chartRefFollowers} />
                </AccountStatisticsBox>

                <AccountStatisticsBox name={text[118]} info={this.likesFull}>
                    <canvas id="myChart1" ref={this.chartRefLikes} />
                </AccountStatisticsBox>

                <AccountStatisticsBox name={text[119]} info={this.commentsFull}>
                    <canvas id="myChart2" ref={this.chartRefComments} />
                </AccountStatisticsBox>

                <AccountStatisticsBox name={text[120]} info={this.viewsFull}>
                    <canvas id="myChart3" ref={this.chartRefViews} />
                </AccountStatisticsBox>
            </AccountStatisticsBoxListStatistics>;
        }



        return (
            <>
                <AccountStatisticsFilter>
                    <AccountStatisticsFilterElement name={text[142]} click={() => {this.clickTip(0)}} active={this.tip === 0 ? true:false}/>
                    <AccountStatisticsFilterElement name={text[143]} click={() => {this.clickTip(1)}} active={this.tip === 1 ? true:false}/>
                    <AccountStatisticsFilterElement name={text[144]} click={() => {this.clickTip(2)}} active={this.tip === 2 ? true:false}/>
                    <AccountStatisticsFilterElement name={text[145]} click={() => {this.clickTip(3)}} active={this.tip === 3 ? true:false}/>
                </AccountStatisticsFilter>

                {content}

            </>
        )
    }

    componentDidMount() {

        this.loadStatistics();

        this.props.menuStore.updateActiveComponentAccount('statistics');
    }

    loadStatistics() {
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        let idAccount = null;

        const accountsStoreList = accountsStore.list.slice();

        for(let key in accountsStoreList) {
            if (accountsStore.list[key].id === this.props.match.params.idAccount) {
                idAccount = key;
            }
        }

        const user = accountsStore.list[idAccount].id;
        this.user = user;
        APIstatistics.set(user,this.tip).then((data)=> {

            if (this.tip !== data.tip) return false;

            if (data.status) {
                this.statistic = data.s;
                this.visual = data.visual;
            } else {
                alert(text[133]);
            }
        }).catch((e) => {
            alert(text[133]);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        const accountsStore = this.props.accountsStore;

        let idAccount = null;

        const accountsStoreList = accountsStore.list.slice();

        for(let key in accountsStoreList) {
            //debugger;
            if (accountsStore.list[key].id === this.props.match.params.idAccount) {
                idAccount = key;
            }
        }

        const user = accountsStore.list[idAccount].id;

        if (this.user !== user) {
            this.statistic = false;
            this.loadStatistics();
        }

        if (this.statistic) {

            let dataFollowers = [];
            let dataLikes = [];
            let dataComments = [];
            let dataViews = [];
            let labels = [];

            let startFollowers = false;
            let finishFollowers = false;
            let likesFull = 0;
            let commentFull = 0;
            let vievFull = 0;

            for (const element of this.statistic) {

                dataFollowers.push(element.f);
                dataLikes.push(element.l);
                dataComments.push(element.c);
                dataViews.push(element.v);

                let time = '';
                if (this.tip === 0) {
                    time = Time.convertUnixToStatistics(element.i, 'H');
                } else if (this.tip === 1) {
                    time = Time.convertUnixToStatistics(element.i, 'dd');
                } else if (this.tip === 2) {
                    time = Time.convertUnixToStatistics(element.i, 'D');
                } else if (this.tip === 3) {
                    time = Time.convertUnixToStatistics(element.i, 'MMM');
                }

                labels.push(time);

                if (startFollowers === false) {
                    startFollowers = element.f;
                }
                finishFollowers = element.f;

                likesFull+=element.l;
                commentFull+=element.c;
                vievFull+=element.v;
            }

            this.followersPlus = finishFollowers-startFollowers;
            this.likesFull = likesFull;
            this.commentsFull = commentFull;
            this.viewsFull = vievFull;
            if (this.visual) {
                this.grafic(this.chartRefFollowers, dataFollowers, labels, text[121]);
                this.grafic(this.chartRefLikes, dataLikes, labels, text[122]);
                this.grafic(this.chartRefComments, dataComments, labels, text[123]);
                this.grafic(this.chartRefViews, dataViews, labels, text[124]);
            }

        }
    }


    grafic (chart, data, labels, label) {

        const initialStore = this.props.initialStore;

        let plotform = 'mobile';

        if (initialStore.templates === 'desktop') {
            plotform = 'desktop';
        }

        //для создания тени от полоски
        const ShadowLineElement = Chart.elements.Line.extend({
            draw () {
                const { ctx } = this._chart

                const originalStroke = ctx.stroke

                ctx.stroke = function () {
                    ctx.save()
                    ctx.shadowColor = 'rgba(0, 0, 0, 0.3)'
                    ctx.shadowBlur = 12
                    ctx.shadowOffsetX = 0
                    ctx.shadowOffsetY = 4
                    originalStroke.apply(this, arguments)
                    ctx.restore()
                }

                Chart.elements.Line.prototype.draw.apply(this, arguments)

                ctx.stroke = originalStroke;
            }
        });

        Chart.defaults.ShadowLine = Chart.defaults.line
        Chart.controllers.ShadowLine = Chart.controllers.line.extend({
            datasetElementType: ShadowLineElement
        });

        //для создания белого фона
        Chart.pluginService.register({
            beforeDraw: function (chart, easing) {
                if (chart.config.options.chartArea && chart.config.options.chartArea.backgroundColor) {
                    var helpers = Chart.helpers;
                    var ctx = chart.chart.ctx;
                    var chartArea = chart.chartArea;

                    ctx.save();
                    ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
                    ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
                    ctx.restore();
                }
            }
        });

        const myChartRef = chart.current.getContext("2d");

        let {height: graphHeight} = myChartRef.canvas;

        if (plotform === 'desktop') {
            graphHeight = 450;
        } else {
            graphHeight = 300;
        }


        let gradientLine = myChartRef
            .createLinearGradient(0, 0, 0, graphHeight);
        gradientLine.addColorStop(0, "rgba(123, 92, 155, 0.85)");
        gradientLine.addColorStop(1, "rgba(123, 92, 155, 0.15)");

        new Chart(myChartRef, {
            type: "ShadowLine",
            data: {
                //Bring in data
                labels: labels,
                datasets: [
                    {
                        label: label,
                        data: data,
                        borderColor: "#7b5c9b",
                        borderWidth: 2,
                        backgroundColor: gradientLine,
                        pointRadius: 0,
                        pointHitRadius: 14,
                        pointBorderColor: '#7b5c9b',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 3,
                        pointStyle: 'circle',
                        hoverRadius: 12,
                        hoverBorderColor: '#7b5c9b',
                        hoverBackgroundColor: '#fff',
                        hoverBorderWidth: 3,

                    }
                ],
            },
            options: {

                animation: {
                    duration: 0,
                },
                hover: {
                    animationDuration: 100, // duration of animations when hovering an item
                },

                layout: {
                    padding: {
                        top: plotform === 'desktop' ? 20 : 0,
                        right: plotform === 'desktop' ? 20 : 0,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true,
                    intersect: true,
                    backgroundColor: '#63487f',
                    titleFontFamily: 'HelveticaNeue',
                    titleFontSize: 16,
                    bodyFontSize: 16,
                    xPadding: 25,
                    yPadding: 10,
                    caretSize: 7,
                    cornerRadius: 6,
                    displayColors: false,
                    caretPadding: 14,
                    callbacks: {
                        title: function(tooltipItem, chart) {
                            return null;
                        }
                    },
                },
                legend: {
                    display: false,
                    position: 'bottom',
                },
                scales: {
                    yAxes: [{
                        gridLines: {
                            drawTicks: false,
                            display: false
                        },
                        ticks: {
                            fontColor: "#1b1b1e",
                            maxTicksLimit: 6,
                            fontSize: plotform === 'desktop' ? 15:0,
                            padding: plotform === 'desktop' ? 10:0,

                        },
                        afterFit: function(scaleInstance) {
                            scaleInstance.width = plotform === 'desktop' ? 70:0; // sets the width to 70px
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            zeroLineColor: "transparent",
                            drawTicks: false,
                            color: "#f1f1f6"
                        },
                        ticks: {
                            fontColor: "#1b1b1e",
                            fontSize: 15,
                            maxTicksLimit: plotform === 'desktop' ? 30:10,
                            padding: 10

                        }
                    }],
                },
                chartArea: {
                    backgroundColor: 'rgba(255,255,255,1)'
                }
            }
        });
    }
}

export default Statistics;