import React from 'react';

import {inject, observer} from "mobx-react";
import Textbox from "../../../component/Textbox";
import {observable} from "mobx";
import Input from "../../../../../templates/elements/input";

@inject('editorStore', 'initialStore')
@observer
class MediaRedBPollTextLR extends React.Component {

    @observable text = '';

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;
    }

    render() {
        const initialStore = this.props.initialStore;
        const textI18 = initialStore.text.addPost;

        const textLeft = this.fabric.getElementRedPollText('left');
        const textRight = this.fabric.getElementRedPollText('right');
        let ff = this.text;
        return (
            <div className="item-form">
                <div className="group-form">
                    <div className="column-form w-50 w-50-l">
                        <span className="input-label">{textI18[170]}</span>
                        <Input emoje className={'form-control view-2'} placeholder={textI18[166]} onChange={this.actionLeft}>{textLeft}</Input>
                    </div>
                    <div className="column-form w-50 w-50-r">
                        <span className="input-label">{textI18[171]}</span>
                        <Input emoje className={'form-control view-2'} placeholder={textI18[167]} onChange={this.actionRight}>{textRight}</Input>
                    </div>
                </div>
            </div>
        );
    }

    actionLeft = (value) => {
        const text = value.toUpperCase();
        this.fabric.elementRedPoll(false, text);
        this.text = text;
    }
    actionRight = (value) => {
        const text = value.toUpperCase();
        this.fabric.elementRedPoll(false, false, text);
        this.text = text;
    }
}

export default MediaRedBPollTextLR;