import helpStore from "./helpStore";
import menuStore from "./menuStore";
import initialStore from "./initialStore";
import accountsStore from "./accountsStore";
import mediaStore from "./mediaStore";
import editorStore from "./editorStore";
import postStore from "./postStore";
import libraryStore from "./libraryStore";

//Уведомления
import notificationStore from "./notificationStore";

//Модальные окна
import modalPatternsStore from "./modalPatternsStore";
import modalTarifStore from "./modalTarifStore";
import modalAddAccount from "./modalAddAccount";
import modalSearhLibraryPost from "./modalSearhLibraryPost";
import modalSearhUrlPost from "./modalSearhUrlPost";
import redPostMiniStore from "./redPostMiniStore";
import modalDateMobile from "./modalDateMobile";
import chatStore from "./chatStore";

class mainStore {

    constructor () {
        this.helpStore = new helpStore();
        this.menuStore = new menuStore();
        this.initialStore = new initialStore();
        this.accountsStore = new accountsStore();
        this.mediaStore = new mediaStore();
        this.editorStore = new editorStore();
        this.postStore = new postStore();
        this.libraryStore = new libraryStore();
        this.modalPatternsStore = new modalPatternsStore();
        this.notificationStore = new notificationStore();
        this.modalTarifStore = new modalTarifStore();
        this.modalAddAccount = new modalAddAccount();
        this.modalSearhLibraryPost = new modalSearhLibraryPost();
        this.modalSearhUrlPost = new modalSearhUrlPost();
        this.redPostMiniStore = new redPostMiniStore();
        this.modalDateMobile = new modalDateMobile();
        this.chatStore = new chatStore();
    }

}

export default new mainStore();