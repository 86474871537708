import APIposts from "../../api/posts";
import store from "../../stor/mainStore";

export function getPosts(id, hashPost, hashStory, idAccountRedax) {

    const accountsStore = store.accountsStore;

    accountsStore.getPostLoad(idAccountRedax);
    accountsStore.getStoryLoad(idAccountRedax);

    APIposts.getPosts(id, hashPost, hashStory).then((data) => {

        accountsStore.getPost(idAccountRedax, data.hashPosts, data.posts, data.rate, data.errorAccount);
        accountsStore.getStory(idAccountRedax, data.hashStory, data.story);
        //error: item.error
    }).catch((error) => {

    });
}
