import React from 'react';
import {observer, inject} from "mobx-react";
import HelpIndex from "../../templates/help";
import HelpElementCategory from "../../templates/help/elementCategory";
import HelpElementTextBox from "../../templates/help/elementTextBox";
import HelpElementText from "../../templates/help/elementText";
import HelpElementTem from "../../templates/help/elementTem";
import HelpChat from "../../templates/help/chat";
import {observable} from "mobx";
import APIchat from "../../api/chat";
import InfoContainer from "../../templates/infoConteiner";
import Time from "../../model/time";
import AccountDirectMessageText from "../../templates/account/direct/message/text";
import directLoad from "../../media/gif/directLoad.gif";
import AccountDirectMessageMedia from "../../templates/account/direct/message/media";

@inject("helpStore", "chatStore", "initialStore")
@observer
class Chat extends React.Component {

    @observable messageText = '';

    @observable message = [];
    @observable load = true;

    @observable cursor = false;

    timerVievMessage = false;

    constructor (props) {
        super(props);

        this.messagesButton = React.createRef();
    }


    chatStatus = false;

    chatStatusFalse = () => {
        this.chatStatus = false;
    }

    render() {
        const initialStore = this.props.initialStore;
        const chatStore = this.props.chatStore;
        const text = initialStore.text.help;

        let message = null;
        let nextLoad = null;



        if (!chatStore.message) {
            message = <InfoContainer
                title={text[2]}
                text={text[3]}
            />;
        } else {

            if (!chatStore.message.slice().length) {
                message = <InfoContainer
                    title={text[4]}
                    text={text[5]}
                />;
            } else {
                message = [];
                let operatorBil = '';

                //время до какого просмотрены сообщения операторами
                let timeVievOperator = 0;

                if (chatStore.clientsData) {
                    if (chatStore.clientsData.readAt) {

                        for (let operator in chatStore.clientsData.readAt) {
                            if (chatStore.clientIdHash !== operator) {
                                let timeOperator = chatStore.clientsData.readAt[operator];
                                if (timeOperator > timeVievOperator) {
                                    timeVievOperator = timeOperator;
                                }
                            }
                        }
                    }

                }

                for (const mes of chatStore.message.slice()) {

                    const time = Time.convertUnixToDirect(Math.floor(mes.createdAt / 1000));
                    let content = null;
                    let operator = false;
                    let userNonViev = false; //прочитано

                    if (mes.userId) {
                        for (let operation of chatStore.operation.slice()) {
                            if (operation.id === mes.userId) {
                                operator = operation;
                            }
                        }
                        if (operator) {
                            if (!mes.type) {
                                content = <AccountDirectMessageText
                                    text={mes.message}
                                    time={time}
                                    userNonViev={userNonViev}
                                    myItem={mes._id}
                                />;
                            } else if (mes.type === 'joined') {
                                content = <AccountDirectMessageText

                                    text={text[6](operator.fields.profile.name)}
                                    time={time}
                                    userNonViev={userNonViev}
                                    myItem={mes._id}
                                />;
                            }
                        }
                    } else {
                        if (mes.createdAt > timeVievOperator) {
                            userNonViev = true;
                        }
                        content = <AccountDirectMessageText
                            text={mes.message}
                            time={time}
                            userNonViev={userNonViev}
                            myItem={mes._id}
                        />;
                    }

                    if (mes.file) {
                        let url = 'https://uc.chatra-usercontent.com/'+mes.file.id+'/';
                        content = <AccountDirectMessageText
                            text={<>Файл:<br/><a href={url} target="_blank">{mes.message}</a></>}
                            time={time}
                            userNonViev={userNonViev}
                            myItem={mes._id}
                        />;

                        if (mes.file.mimeType === 'image/webp' || mes.file.mimeType === 'image/png' || mes.file.mimeType === 'image/jpg' || mes.file.mimeType === 'image/webp') {
                            content = <AccountDirectMessageMedia
                                mediaType={'image'}
                                media={url}
                                width={mes.file.imageInfo.width}
                                height={mes.file.imageInfo.height}
                                time={time}
                                userNonViev={userNonViev}
                            />
                        }

                    }

                    message.push(<div className={mes.userId ? "chat-message left" : "chat-message right"}>
                        {mes.userId && operatorBil !== mes.userId ? <div className="chat-message__user"><img src={operator.fields.profile.photo.cdnUrl}/></div>:null}
                        {content}
                    </div>);




                    if (mes.userId) {
                        operatorBil = mes.userId;
                    } else {
                        operatorBil = '';
                    }


                }

                if (chatStore.operatorPrints) {
                    message.push(<div className="chat-message-operator-print">{text[7]}</div>);
                }
            }


            if (chatStore.messageNext) {
                nextLoad = <div style={{textAlign: 'center', color: '#7b5c9b', textDecoration: 'underline', padding: 20, cursor: 'pointer'}} onClick={this.nextLoadItems}>{'Load history'}</div>;

                if (chatStore.messageNext === 'load') {
                    nextLoad = <div style={{textAlign: 'center', color: '#7b5c9b', textDecoration: 'underline', padding: 20}}><img width={16} height={16} src={directLoad}/></div>;
                }
            }
        }


        return <HelpChat
            message={message}
            nextLoad={nextLoad}
            sendingMessageText={this.sendingMessageText}
            messageText={this.messageText}
            messageTextChange={this.messageTextChange}
            scrollToMessagesButton={this.scrollToMessagesButton}
            messagesButton={this.messagesButton}
            addMessagesButton={this.addMessagesButton}
            nextLoadItems={this.nextLoadItems}
            cursor={chatStore.messageNext}
            sendingMessageMedia={this.sendingMessageMedia}
        />
    }

    sendingMessageMedia = (file) => {
        APIchat.sending_media(file).then((data) => {

            if (data.status) {
                const url = "https://likepro.ru/cloud-chat/" + data.code;

                const value = "File:\n" + url;
                const chatStore = this.props.chatStore;
                chatStore.submitMessage(value);

            } else {
                alert(data.error);
            }
        }).catch((e) => {
            alert('Error load file.')
        });
    }

    nextLoadItems = () => {

        const chatStore = this.props.chatStore;

        if (!chatStore.message) {
            return false;
        }

        const coolback = () => {

        }
        chatStore.getHistoryMessages(coolback);
        chatStore.messageNext = 'load';


    }

    scrollToMessagesButton = (animated = false) => {
        this.messagesButton.scrollIntoView({ behavior: animated ? "smooth":"auto" });
    }

    addMessagesButton = (e) => {
        this.messagesButton = e;
    }

    randomInt(min, max) {
        return min + Math.floor((max - min) * Math.random());
    }

    sendingMessageText = () => {

        if (this.messageText === '') {
            return true;
        }
        const chatStore = this.props.chatStore;

        chatStore.submitMessage(this.messageText);

        this.messageText = '';
    }

    messageTextChange = (value) => {
        this.messageText = value;

        const chatStore = this.props.chatStore;
        chatStore.updateMessage(value);
    }

    componentDidMount() {
        const chatStore = this.props.chatStore;

        if (chatStore.openChat) {
            chatStore.openChat();
        }


        chatStore.nextButtonChat = this.scrollToMessagesButton;
        chatStore.chatStatusFalse = this.chatStatusFalse;
        this.timerVievMessage = setInterval(() => {
            const chatStore = this.props.chatStore;
            if (chatStore.newMessage) {
                if (chatStore.markClientAsRead) {
                    chatStore.markClientAsRead();
                }
            }
        }, 3000);
    }

    componentWillUnmount() {
        const chatStore = this.props.chatStore;

        if (chatStore.closeChat) {
            chatStore.closeChat();
        }


        chatStore.nextButtonChat = false;
        chatStore.chatStatusFalse = false;

        clearInterval(this.timerVievMessage);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const chatStore = this.props.chatStore;
        if (!this.chatStatus) {

            if (chatStore.message) {
                if (chatStore.message.slice().length) {
                    this.chatStatus = true;
                    this.scrollToMessagesButton();

                }
            }

        }

    }

}

export default Chat;
