import React from 'react';
import {inject, observer} from "mobx-react";

import Time from "../../model/time";

import ReactDOM from "react-dom";
import ElementMenuCreatePost from "../../templates/elements/ElementMenuCreatePost";

@inject("accountsStore", "mediaStore", "menuStore", "notificationStore", "initialStore")
@observer
class MenuCreatePost extends React.Component {

    //@observable openMenu = false; openMenuCreatePost

    constructor (props) {
        super(props);
        this.openPost = this.openPost.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.open = false;

        this.window = React.createRef();

        const menuStore = this.props.menuStore;

        menuStore.openMenuCreatePost = false;
    }

    render() {
        const mediaStore = this.props.mediaStore;
        const menuStore = this.props.menuStore;

        const content = null;

        if (!menuStore.openMenuCreatePost) {
            if (this.open) {
                this.open=false;
                this.updateStatusCloseFon();
            }
        } else {
            if (!this.open) {
                this.open=true;
                this.updateStatusCloseFon();
            }
        }

        return <ElementMenuCreatePost
            collapsed={mediaStore.collapsed}
            openPost={this.openPost}
            openMenuCreatePost={menuStore.openMenuCreatePost}
            openAllMenu={this.openAllMenu}
            window={this.window}
            openStory={this.openStory}
            openIgtv={this.openIgtv}
            openReels={this.openReels}
            openMultyPost={this.openMultyPost}
            openMultyStory={this.openMultyStory}
        />;
    }

    openPost = () => {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        //выставляем аккаунт который выбран
        if (mediaStore.accounts.length === 0 && accountsStore.active !== null) {

            const time = Time.dateTimetableGenerator(accountsStore.list[accountsStore.active].id, 'post');

            mediaStore.actionAccount(accountsStore.list[accountsStore.active].id, time[0]);
        }

        /*if (accountsStore.active === null && accountsStore.list.slice().length) {
            const time = Time.dateTimetableGenerator(accountsStore.list[0].id, 'post');

            mediaStore.actionAccount(accountsStore.list[0].id, time[0]);
        }*/

        this.props.mediaStore.actionOpen(true);
    }

    openStory = () => {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        let activeAccount = false;
        /*for (const accountID in accountsStore.list.slice()) {
            if (accountsStore.list[accountID].tip === 'instagram') {
                activeAccount = accountID;
                break;
            }
            if (accountsStore.list[accountID].tip === 'vk' && accountsStore.list[accountID].storyTrue === true) {
                activeAccount = accountID;
                break;
            }
        }*/

        //выставляем аккаунт который выбран
        if (mediaStore.accounts.length === 0 && accountsStore.active !== null) {

            if (accountsStore.list[accountsStore.active].tip === 'instagram') {
                activeAccount = accountsStore.active;
            }
            if (accountsStore.list[accountsStore.active].tip === 'vk' && accountsStore.list[accountsStore.active].storyTrue === true) {
                activeAccount = accountsStore.active;
            }
            if (activeAccount) {
                const time = Time.dateTimetableGenerator(accountsStore.list[activeAccount].id, 'story');

                mediaStore.actionAccount(accountsStore.list[activeAccount].id, time[0]);
            }


        }

        this.props.mediaStore.actionOpenStory(true);
    }

    openMultyStory = () => {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        let activeAccount = '0';

        if (!accountsStore.list.slice().length) {
            const notificationStore = this.props.notificationStore;
            notificationStore.add('Ошибка', 'Перед использованием данной функции, добавьте первый аккаунт Instagram в сервис.');
            return false;
        }

        let error = true;
        for (const key in accountsStore.list.slice()) {
            if (accountsStore.list[key].tip === 'instagram') {
                activeAccount = key;
                error = false;
                break;
            }
        }

        if (error) {
            const notificationStore = this.props.notificationStore;
            notificationStore.add('Ошибка', 'Перед использованием данной функции, добавьте первый аккаунт Instagram в сервис.');
            return false;
        }


        //выставляем аккаунт который выбран
        if (mediaStore.accounts.length === 0 && accountsStore.active !== null) {
            if (accountsStore.list[activeAccount].type === 'instagram') {
                activeAccount = accountsStore.active;
            }
        }

        const time = Time.dateTimetableGenerator(accountsStore.list[activeAccount].id, 'story');

        mediaStore.actionAccount(accountsStore.list[activeAccount].id, time[0]);

        this.props.mediaStore.actionMultyStory(true);
    }

    openMultyPost = () => {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        let activeAccount = '0';

        if (!accountsStore.list.slice().length) {
            const notificationStore = this.props.notificationStore;
            notificationStore.add('Ошибка', 'Перед использованием данной функции, добавьте первый аккаунт в сервис.');
            return false;
        }

        //выставляем аккаунт который выбран
        if (mediaStore.accounts.length === 0 && accountsStore.active !== null) {

            activeAccount = accountsStore.active;
        }

        const time = Time.dateTimetableGenerator(accountsStore.list[activeAccount].id, 'post');

        mediaStore.actionAccount(accountsStore.list[activeAccount].id, time[0]);

        this.props.mediaStore.actionMultyPost(true);
    }

    openIgtv = () => {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        let activeAccount = false;

        /*for (const accountID in accountsStore.list.slice()) {
            if (accountsStore.list[accountID].tip === 'instagram') {
                activeAccount = accountID;
                break;
            }
        }*/

        //выставляем аккаунт который выбран
        if (mediaStore.accounts.length === 0 && accountsStore.active !== null) {

            if (accountsStore.list[accountsStore.active].tip === 'instagram') {
                activeAccount = accountsStore.active;
            }

            if (activeAccount) {
                const time = Time.dateTimetableGenerator(accountsStore.list[activeAccount].id, 'post');

                mediaStore.actionAccount(accountsStore.list[activeAccount].id, time[0]);
            }
        }

        this.props.mediaStore.actionOpenIgtv(true);
    }

    openReels = () => {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        let activeAccount = false;
        /*for (const accountID in accountsStore.list.slice()) {
            if (accountsStore.list[accountID].tip === 'instagram') {
                activeAccount = accountID;
                break;
            }
        }*/

        //выставляем аккаунт который выбран
        if (mediaStore.accounts.length === 0 && accountsStore.active !== null) {

            if (accountsStore.list[accountsStore.active].tip === 'instagram') {
                activeAccount = accountsStore.active;
            }

            if (activeAccount) {
                const time = Time.dateTimetableGenerator(accountsStore.list[activeAccount].id, 'post');

                mediaStore.actionAccount(accountsStore.list[activeAccount].id, time[0]);
            }

        }

        this.props.mediaStore.actionOpenReels(true);
    }

    openAllMenu = () => {
        const menuStore = this.props.menuStore;

        menuStore.openMenuCreatePost=!menuStore.openMenuCreatePost;
    }

    updateStatusCloseFon = () => {
        const initialStore = this.props.initialStore;

        let type = 'click';
        if (initialStore.templates !== 'desktop') {
            type = 'touchend';
        }

        if (this.open) {
            document.addEventListener(type, this.handleClickOutside, false);
        } else {
            document.removeEventListener(type, this.handleClickOutside, false);
        }
    }

    componentWillUnmount() {
        const initialStore = this.props.initialStore;

        let type = 'click';
        if (initialStore.templates !== 'desktop') {
            type = 'touchend';
        }

        document.removeEventListener(type, this.handleClickOutside, false);
    }

    handleClickOutside(e) {

        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов
                const menuStore = this.props.menuStore;

                if (menuStore.openMenuCreatePost) {
                    menuStore.openMenuCreatePost = false;
                }
            }
        } catch(error) {
            console.log(error);
            return null;
        }
    }
}

export default MenuCreatePost;
