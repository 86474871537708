import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

import Draggable from "react-draggable";

import MediaWindow from "./MediaWindow";

import style from "./mediaDuration.module.css";
import file from "../../../model/file";
import mediaHelpers from "../../../model/mediaHelpers";
import APIfile from "../../../api/file";

@inject('editorStore', 'mediaStore')
@observer
class MediaDuration extends React.Component {



    @observable scrollLeft = 0;
    @observable scrollRight = 100;

    @observable cover = 0;

    @observable durationDisplay = 'none';

    @observable widthRedactor = 600;

    @observable max = this.widthRedactor - 6;

    constructor (props) {
        super(props);

        this.dragLeft = this.dragLeft.bind(this);
        this.dragRight = this.dragRight.bind(this);
        this.durationDisplayUpdate = this.durationDisplayUpdate.bind(this);
        this.duration = 0;
        this.timerId = false;

        const editorStore = this.props.editorStore;

        this.historyStart = editorStore.file.duration.start;
        this.historyFinish = editorStore.file.duration.finish;

        const width=document.body.clientWidth;

        if (width < 769) {
            this.max = width - 78 - 6;
            this.widthRedactor= width - 78;
        }
    }




    render() {

        const editorStore = this.props.editorStore;

        const full = editorStore.file.duration.full;

        const coverL = this.props.editorStore.file.duration.start;

        const start = coverL/(full/100);

        const coverR = this.props.editorStore.file.duration.finish;
        const finish = coverR/(full/100);

        const icons = this.props.editorStore.file.duration.icons;

        const MediaWindowProps = {
            backgroud: this.props.backgroud,
            stateObertka: this.props.stateObertka,
            content: this.props.content,
            draggable: this.props.draggable,
            getDraggableBounds: this.props.getDraggableBounds,
            handleDrag: false,
            type: this.props.type,
            url: this.props.url,
            onMouseDownBox: this.props.onMouseDownBox,
            boxRef: this.props.boxRef,
            disabled: true,
            currentTime: this.cover,
        };



        const DraggableLeftProps = {
            axis: 'x',
            position: {
                x: Math.round(this.max / 100 * start),
                y: 0
            },
            bounds: {
                left: 0,
                right: Math.round(this.max / 100 * finish)-10,
            },
            onDrag: (event, ui) =>{this.dragLeft(event, ui)},
        };

        const DraggableRightProps = {
            axis: 'x',
            position: {
                x: Math.round(this.max / 100 * finish),
                y: 0
            },
            bounds: {
                left: Math.round(this.max / 100 * start)+10,
                right: this.max,
            },
            onDrag: (event, ui) =>{this.dragRight(event, ui)},
        };

        const iconsJSX = [];
        if (icons.slice().length > 0){
            for (const iconsKey in icons.slice()) {

                const widthIcon = (this.widthRedactor / icons.slice().length);

                iconsJSX.push(<img width={widthIcon} height={60} src={icons[iconsKey]}/>);
            }
        } else {
            //Высчитываем количество иконок
            let nadoIcons = 10;
            const width=document.body.clientWidth;
            if (width < 769) {
                const widthMax = width - 78;//241
                nadoIcons = Math.ceil(widthMax/60);
            }

            const widthIcon = (this.widthRedactor / nadoIcons);

            for (let i=0; i<nadoIcons; i++) {
                iconsJSX.push(<img width={widthIcon} height={60} src={editorStore.file.icon}/>);
            }
        }


        let finishM = Math.floor(Math.ceil(editorStore.file.duration.full)/60);
        let finishS = Math.round(Math.ceil(editorStore.file.duration.full)%60);

        let startDurationM = Math.floor(Math.ceil(this.props.editorStore.file.duration.start)/60);
        let startDurationS = Math.round(Math.ceil(this.props.editorStore.file.duration.start)%60);

        let finishDurationM = Math.floor(Math.ceil(this.props.editorStore.file.duration.finish)/60);
        let finishDurationS = Math.round(Math.ceil(this.props.editorStore.file.duration.finish)%60);

        finishM = finishM < 10 ? '0' + finishM : finishM;
        finishS = finishS < 10 ? '0' + finishS : finishS;
        startDurationM = startDurationM < 10 ? '0' + startDurationM : startDurationM;
        startDurationS = startDurationS < 10 ? '0' + startDurationS : startDurationS;
        finishDurationM = finishDurationM < 10 ? '0' + finishDurationM : finishDurationM;
        finishDurationS = finishDurationS < 10 ? '0' + finishDurationS : finishDurationS;

        const timeBox = {
            start: '00:00',
            finish: finishM + ':' + finishS,
            startDuration: startDurationM + ':' + startDurationS,
            finishDuration: finishDurationM + ':' + finishDurationS,
        }


        const component = <>
            <div className="editor-top">
                <div className={style.sek} style={{display: this.durationDisplay, height: MediaWindowProps.backgroud.height, lineHeight: MediaWindowProps.backgroud.height+'px'}}>{this.duration}</div>
                <MediaWindow {...MediaWindowProps}/>
            </div>

            <div className="editor-cnt">
                <div className="editor-pruning">
                    <div className="editor-pruning__wrap">
                        <div className="editor-pruning__times">
                            <span className="item-times">{timeBox.start}</span>
                            <span className="item-times">{timeBox.finish}</span>
                        </div>
                        <div className="editor-pruning__items">
                            <div style={{width: this.widthRedactor, height: 60, position: 'absolute'}}>
                                <Draggable {...DraggableLeftProps}>
                                    <div style={{width: 6, height: 78, position: 'absolute', backgroundColor: '#7b5c9b', top: -8, cursor: 'pointer'}}>
                                        <span className={ DraggableLeftProps.position.x > 25 ? "count-scale element-time-start-and-end" : "count-scale element-time-start-right"}>
                                            {timeBox.startDuration}
                                        </span>
                                    </div>
                                </Draggable>

                                <Draggable {...DraggableRightProps}>
                                    <div style={{width: 6, height: 78, position: 'absolute', backgroundColor: '#7b5c9b', top: -8, cursor: 'pointer'}}>
                                        <span className={ DraggableRightProps.position.x < 570 ? "count-scale element-time-start-and-end" : "count-scale element-time-end-left"}>
                                            {timeBox.finishDuration}
                                        </span>
                                    </div>
                                </Draggable>
                            </div>
                            {iconsJSX}
                        </div>
                    </div>
                </div>
            </div>
        </>;

        return component;
    }

    componentDidMount() {
        const editorStore = this.props.editorStore;

        const duration = editorStore.file.duration.finish - editorStore.file.duration.start;

        this.duration = duration;
    }

    dragLeft(event, ui) {
        const editorStore = this.props.editorStore;

        const limitsMedia = mediaHelpers.limitsMedia();

        const x = ui.x;

        const scroll = Math.ceil((x / (this.max/100))*1000)/1000;

        const full = editorStore.file.duration.full;

        const cover = Math.ceil((full/100*scroll)*100)/100;

        const duration = editorStore.file.duration.finish - cover;
        if (duration < 3) {
            return false;
        } else if (duration > limitsMedia.duration) {
            return false;
        }

        this.scrollLeft = scroll;

        console.log(scroll);

        this.cover = cover;
        editorStore.file.duration.start = cover;

        if (editorStore.file.duration.cover < cover) {
            editorStore.file.duration.cover = cover;
        }

        this.durationDisplayUpdate();
    }

    dragRight(event, ui) {
        const editorStore = this.props.editorStore;

        const limitsMedia = mediaHelpers.limitsMedia();

        const x = ui.x;

        const scroll = Math.ceil((x / (this.max/100))*1000)/1000;

        const full = editorStore.file.duration.full;

        const cover = Math.ceil((full/100*scroll)*100)/100;

        const duration = cover - editorStore.file.duration.start;
        if (duration < 3) {
            return true;
        } else if (duration > limitsMedia.duration) {
            return true;
        }

        this.scrollRight = scroll;

        this.cover = cover;

        editorStore.file.duration.finish = cover;

        if (editorStore.file.duration.cover > cover) {
            editorStore.file.duration.cover = editorStore.file.duration.start;
        }

        this.durationDisplayUpdate();
    }

    //вызывается перед удалением
    componentWillUnmount () {
        const editorStore = this.props.editorStore;
        const mediaStore = this.props.mediaStore;

        //если длина отличвается от той которая была ранее, удаляем картинки у нарезки под обложку и гинерим новые
        if (this.historyStart !== editorStore.file.duration.start || this.historyFinish !== editorStore.file.duration.finish) {

            this.props.editorStore.file.duration.iconsCover = [];

            let nadoIcons = 10;
            const width=document.body.clientWidth;
            if (width < 769) {
                const widthMax = width - 78;//241
                nadoIcons = Math.ceil(widthMax/60);
            }

            let idLoad = mediaStore.idLoad;
            if (mediaStore.type === 'multyPost' || mediaStore.type === 'multyStory') {
                for (let key in mediaStore.multyPost.slice()) {
                    if (mediaStore.multyPost[key].id === editorStore.file.id) {
                        idLoad = mediaStore.multyPost[key].loadFileId;
                        break;
                    }
                }
            }

            let dataInfo = {
                start: editorStore.file.duration.start,
                finish: editorStore.file.duration.finish,
                icons: false,
                iconsCover: true,
                id: editorStore.file.id,
                key: idLoad,
                iconsNumber: nadoIcons,
            };



            APIfile.getImagesVideo(dataInfo.key, dataInfo.id, dataInfo.start, dataInfo.finish, dataInfo.icons, dataInfo.iconsCover, dataInfo.iconsNumber).then((data) => {
                if (data.status) {

                    if (data.iconsCover.length) {
                        this.props.editorStore.file.duration.iconsCover = [...data.iconsCover];
                    }
                }
            }).catch((e) => {

            });

            /*file.CreatorIconMiniature(editorStore.file.url, editorStore.file.duration.start, editorStore.file.duration.finish)
            .then((icons) => {
                this.props.editorStore.file.duration.iconsCover = icons;
            })
            .catch(() => {
                console.log('ошибка нарезки иконок');
            });*/
        }
    }

    durationDisplayUpdate () {

        const editorStore = this.props.editorStore;

        const duration2 = editorStore.file.duration.finish - editorStore.file.duration.start;

        if (duration2 !== this.duration) {
            this.durationDisplay = 'initial';
            this.duration = Math.ceil((duration2)*10)/10;

            clearTimeout(this.timerId);

            this.timerId = setTimeout(() => {
                this.durationDisplay = 'none';
            }, 1500);
        }
    }
}

export default MediaDuration;
