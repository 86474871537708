import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import style from "../../../component/menu/index.module.css";

export default
@inject('initialStore')
@observer
class MenuAccountMenuBoxElement extends React.Component {

    constructor(props) {
        super(props);
        this.funs = this.funs.bind(this);
    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            if (this.props.url !== null) {
                return <Link onClick={this.props.onClick} to={this.props.url} className={this.props.active ? 'event-item active':'event-item'}>{this.props.name}</Link>;
            } else {
                return <span onClick={this.props.onClick} className={this.props.active ? 'event-item active':'event-item'}>{this.props.name}</span>;
            }

        } else {
            if (this.props.url !== null) {
                return <Link onClick={this.props.onClick} to={this.props.url} className={this.props.active ? 'event-item active':'event-item'}>{this.props.name}</Link>;
            } else {
                return <span onClick={this.props.onClick} className={this.props.active ? 'event-item active':'event-item'}>{this.props.name}</span>;
            }
        }

    }

    funs () {
        this.props.fun(this.props.url);
    }
}

