import React from 'react';
import {inject, observer} from "mobx-react";

import iconFalse from "../../../media/img/icons/staff/path-2.svg";
import iconTrue from "../../../media/svg/staffOK.svg";
import iconNonPhoto from "../../../media/img/users/1.png";
import ButtonDel from "../../elements/buttonDel";

export default
@inject('initialStore')
@observer
class AccountEmployeesElement extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        if (initialStore.templates === 'desktop') {
            return <tr>
                        <td>
                            <div className="staff-table__info">
                                <div className="img-info">
                                    <span className="inner-img"><img src={this.props.photo ? 'https://likepro.ru' + this.props.photo : iconNonPhoto} /></span>
                                </div>
                                <div className="cnt-info">
                                    <span className="name-info">{this.props.name}</span>
                                    <span className="contacts-info v-text">{this.props.email}</span>
                                </div>
                            </div>
                        </td>
                            <td><img src={!this.props.moderation? iconTrue:iconFalse} /></td>
                            <td><img src={this.props.view_post? iconTrue:iconFalse} /></td>
                            <td><img src={this.props.editing_post? iconTrue:iconFalse} /></td>
                            <td><img src={this.props.history? iconTrue:iconFalse} /></td>
                            <td><img src={this.props.statistics? iconTrue:iconFalse} /></td>
                            <td><img src={this.props.url? iconTrue:iconFalse} /></td>
                            <td><img src={this.props.direct? iconTrue:iconFalse} /></td>
                        <td>
                            <span className="staff-table__control">
                                <i onClick={this.props.actionEdit} className="icon icon-settings-edit"></i>
                                <ButtonDel icon={true} action={this.props.actionDelite} color>employeesDel</ButtonDel>
                            </span>
                        </td>
                </tr>;
        } else {
            return <div className="staff-item">
                <div className="staff-table__info">
                    <div className="img-info">
                        <span className="inner-img"><img src={this.props.photo ? 'https://likepro.ru' + this.props.photo : iconNonPhoto} /></span>
                    </div>
                    <div className="cnt-info">
                        <span className="name-info">{this.props.name}</span>
                        <span className="contacts-info">{this.props.email}</span>
                    </div>
                </div>
                <ul className="staff-table__list">
                    <li>
                        <span className="title-list">{text[23]}</span>
                        <img src={!this.props.moderation? iconTrue:iconFalse} />
                    </li>
                    <li>
                        <span className="title-list">{text[24]}</span>
                        <img src={this.props.url? iconTrue:iconFalse} />
                    </li>
                    <li>
                        <span className="title-list">{text[25]}</span>
                        <img src={this.props.history? iconTrue:iconFalse} />
                    </li>
                    <li>
                        <span className="title-list">{text[26]}</span>
                        <img src={this.props.view_post? iconTrue:iconFalse} />
                    </li>
                    <li>
                        <span className="title-list">{text[27]}</span>
                        <img src={this.props.editing_post? iconTrue:iconFalse} />
                    </li>
                    <li>
                        <span className="title-list">{text[8]}</span>
                        <img src={this.props.statistics? iconTrue:iconFalse} />
                    </li>
                    <li>
                        <span className="title-list">{text[9]}</span>
                        <img src={this.props.direct? iconTrue:iconFalse} />
                    </li>
                </ul>
                <span className="staff-table__control">
                    <i onClick={this.props.actionEdit} className="icon icon-settings-edit"></i>
                    <ButtonDel icon={true} action={this.props.actionDelite}>employeesDel</ButtonDel>
                </span>
            </div>;
        }

    }
}
