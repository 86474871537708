import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

export default
@inject('initialStore')
@observer
class ButtonLoad extends React.Component {

    @observable status = true;

    constructor (props) {
        super(props);
        this.click = this.click.bind(this);
    }

    render() {
        const initialStore = this.props.initialStore;

        let classes = 'btn border btn-primary';

        if (this.props.color) classes = 'btn btn-primary';

        if (this.props.className) classes = this.props.className;

        if (!this.status) classes+=' btn-primary-animate';

        let infoButtonLoaf = null;
        if (this.props.children === 'createPosts' || this.props.children === 'createStorys') {
            infoButtonLoaf = initialStore.infoButtonLoaf;
        }

        if (initialStore.templates === 'desktop') {
            return <>

                {this.status ?
                    <button onClick={this.click} style={this.props.style} type="button" className={classes}>{initialStore.text.button[this.props.children]}{infoButtonLoaf}</button>
                :
                    <button type="button" style={this.props.style} className={classes}>{initialStore.text.button[this.props.children]}{infoButtonLoaf}</button>
                }
            </>;
        } else {
            return <>

                {this.status ?
                    <button onClick={this.click} style={this.props.style} type="button" className={classes}>{initialStore.text.button[this.props.children]}{infoButtonLoaf}</button>
                    :
                    <button type="button" style={this.props.style} className={classes}>{initialStore.text.button[this.props.children]}{infoButtonLoaf}</button>
                }
            </>;
        }

    }

    click () {
        if (this.status === true) {
            this.status = false;

            new Promise((resolve) => {
                this.props.action(resolve);
            }).then(() => {
                this.status = true;
            }).catch(() => {
                this.status = true;
            });

            if (document.activeElement) {
                if (document.activeElement.localName === 'textarea' || document.activeElement.localName === 'input') {
                    document.activeElement.blur();
                }
            }
        }
    }
}

