import qs from "qs";
import React from "react";

import instance from "./base";


const APIaccounts = {

    getAdd (
        login,
        password,
        level,
        twoFactorIdentifier,
        code,
        method_verification,
        path,
        nonce_code,
        user_id,
        proxy,
        ) {
        return instance.post('/accounts/add', qs.stringify({
            level: level,
            login:login,
            password:password,

            code: code,
            twoFactorIdentifier: twoFactorIdentifier,

            method_verification: method_verification,
            path: path,
            nonce_code: nonce_code,
            user_id: user_id,

            proxy: JSON.stringify(proxy),

        })).then(data => {
            return data;
        });
    },
    restart (id) {
        return instance.post('/accounts/restart', qs.stringify({
            id: id,
        })).then(data => {
            return data;
        });
    },
    delite (id) {
        return instance.post('/accounts/delite', qs.stringify({
            id: id,
        })).then(data => {
            return data;
        });
    },
}

export default APIaccounts;