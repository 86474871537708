import qs from "qs";
import React from "react";
import instance from "./base";


const APIrate = {

    setActive () {
        return instance.post('rate/index').then(data => {
            return data;
        });
    },

    getPayOK (accounts, month) {
        return instance.post('rate/initialpay', qs.stringify({
            key: '123',
            accounts: accounts,
            month: month,
        })).then(data => {
            return data;
        });
    },

    red (accounts) {
        return instance.post('rate/red', qs.stringify({
            accounts: accounts,
        })).then(data => {
            return data;
        });
    },
}

export default APIrate;