import React from 'react';
import Draggable from "react-draggable";

import stile from './mediaSizeScroll.module.css';
import mediaHelpers from "../../../model/mediaHelpers";
import {observable} from "mobx";
import {inject, observer} from "mobx-react";

@inject('editorStore')
@observer
class MediaColor extends React.Component {

    @observable xScroll = 0;


    constructor (props) {
        super(props);


    }

    render() {

        const editorStore = this.props.editorStore;

        const color = editorStore.file.color;


        const colors = [
            '#e02020', '#fa6400', '#f7b500', '#6dd400', '#44d7b6', '#32c5ff', '#0091ff', '#6236ff', '#b620e0', '#6d7278',
            '#e5e5e5', '#bfbfbf', '#7f7f7f', '#262525', '#000000', '#ffffff', '#999999', '#ee8c8b', '#8d6996', '#495666'
        ];

        const elements = [];
        for (const colorID of colors) {

            let classes = 'item-colors';

            if (colorID === '#ffffff') {
                classes+=' white';
            }

            if (colorID === color) {
                classes+=' is-checked';
            }

            elements.push(<div className={classes} style={{color: colorID}} onClick={() => this.redColor(colorID)}><span className="inner-colors"></span></div>);
        }


        const component = (
            <div className="editor-media__colors">
                {elements}
            </div>
        );

        return component;
    }


    redColor = (color) => {
        const editorStore = this.props.editorStore;

        editorStore.file.color = color;

        editorStore.editorMedia.getColor(color);
    }

}

export default MediaColor;