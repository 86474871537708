import React from "react";

const ru = {
    0: 'Вход в LikePro',
    1: 'Электронная почта',
    2: 'Пароль',
    3: 'Забыли пароль?',
    4: <>Регистрируясь, вы принимаете <a href="https://likepro.ru/agreement" target="_blank">Пользовательское соглашение</a></>,
    5: 'Нет аккаунта?',
    6: 'Зарегистрироваться',
    7: 'Регистрация в LikePro',
    9: 'Придумайте пароль',
    10: 'Уже зарегистрированы?',
    11: 'Авторизиваться',
    12: (mail) => <>На вашу почту "{mail}" было выслано письмо с
        ссылкой для подтверждения учетной записи.<br/><br/>
        Пожалуйста, зайдите в свою почту "{mail}" и подтвердите ее перейдя по ссылке в письме.</>,
    13: 'Понятно',
    14: 'Смена пароля на LikePro',
    15: 'Новый пароль успешно сохранен, теперь Вы можете авторизоваться.',
    16: 'Авторизоваться',
    17: 'Вы забыли заполнить поле',
    18: 'Пароль и подтверждение пароля не совпадают, заполните их повторно и повторите попытку.',
    19: 'Новый пароль',
    20: 'Повторите новый пароль',
    21: 'Авторизация',
    22: 'Укажите почту на которую вы зарегистрированы, мы вышлем на нее письмо с инструкцией по восстановлению доступа.',




}

const en = {
    0: 'Login to LikePro',
    1: 'Email',
    2: 'Password',
    3: 'Forgot your password?',
    4: <>By registering, you accept the <a href="https://likepro.ru/agreement" target="_blank">User Agreement</a></>,
    5: 'Don\'t have an account?',
    6: 'Check in',
    7: 'Registration in LikePro',
    9: 'Create a password',
    10: 'Already Registered?',
    11: 'Login',
    12: (mail) => <>A letter was sent to your mail "{mail}" link to verify your account.<br/><br/>
        Please go to your mail "{mail}" and confirm it by clicking on the link in the letter.</>,
    13: 'Got it',
    14: 'Change password for LikePro',
    15: 'The new password has been successfully saved, now you can log in.',
    16: 'Login',
    17: 'You forgot to fill field the form.',
    18: 'Password and confirmation password does not match, please re-fill and try again.',
    19: 'New Password',
    20: 'Repeat new password',
    21: 'Authorization',
    22: 'Indicate the mail to which you are registered, we will send a letter to it with instructions for restoring access.',

}

const login = {
    ru: ru,
    en: en,
}

export default login;
