import React from 'react';
import {inject, observer} from "mobx-react";

export default
@inject('initialStore')
@observer
class ModalTarifPayElementPay extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;

        const url = this.props.url;
        const classElement = this.props.classElement;
        const clickFunction = this.props.clickFunction;
        const name = this.props.name;

        if (initialStore.templates === 'desktop') {

            return <a href={url} className={'btn border btn-block '+classElement} target="_blank" onClick={clickFunction}>{name}</a>;
        } else {

            if (initialStore.platform === 'ios' || initialStore.platform === 'android') {
                return <div onClick={() => {clickFunction(); window.openUrl(url)}} className={'btn border btn-block no-fastclick no-fastclick-click-css '+classElement}>{name}</div>;
            }

            return <a href={url} className={'btn border btn-block no-fastclick '+classElement} target="_blank" onClick={clickFunction}>{name}</a>;
        }
    }
}