import React from 'react';

import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ReactDOM from "react-dom";

@inject('editorStore', 'initialStore')
@observer
class MediaRedBTextFont extends React.Component {

    @observable openMenu = false;

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;


        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.window = React.createRef();

        this.openHistory = false;

    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        if (this.openMenu && !this.openHistory) {
            this.updateStatusCloseFon();
            this.openHistory = true;
        }

        if (!this.openMenu && this.openHistory) {
            this.updateStatusCloseFon();
            this.openHistory = false;
        }



        let classes = this.openMenu ? "event-menu g-center v-bottom show botton-element-color-box" : "event-menu g-center v-bottom show botton-element-color-box event-menu-none";


        const fonts = [
            {name: text[140], font: 'AvenyT'},
            {name: text[141], font: 'fabric-mode'},
            {name: text[142], font: 'fabric-neon'},
            {name: text[143], font: 'fabric-alaRuss'},
            {name: text[205], font: 'TimesNewRoman'},
            {name: 'Yanone Kaffeesatz', font: 'YanoneKaffeesatz'},
            {name: 'Lobster', font: 'Lobster'},
            {name: 'Comfortaa', font: 'Comfortaa'},
            {name: 'Exo2', font: 'Exo2'},
            {name: 'Pacifico', font: 'Pacifico'},
            {name: 'Caveat', font: 'Caveat'},


        ];

        const elemensColors = [];
        for (let element of fonts) {
            elemensColors.push(<span className="event-item" style={{padding: '6px 0px', fontFamily: element.font}} onClick={() => this.font(element.font)}>{element.name}</span>);
        }

        return (
            <>
                <div className="item-type">
                    <button type="button" className="btn red-button-text-font" onClick={this.open}></button>

                    <div className={classes}>
                        <div className="event-colors" ref={this.window}>
                            {elemensColors}
                        </div>
                    </div>

                </div>
            </>
        );
    }

    open = () => {
        if (!this.openMenu) {
            this.openMenu = true;
        }
        //this.openMenu = !this.openMenu;
    }

    font = (name) => {
        this.fabric.elementTextFont(name);
        this.openMenu = false;
    }

    updateStatusCloseFon = () => {
        if (this.openMenu) {
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                if (this.openMenu !== false) {
                    this.openMenu = false;
                }
            }
        } catch(error) {
            return null;
        }
    }
}

export default MediaRedBTextFont;
