import React from 'react';
import {inject, observer} from "mobx-react";

export default
@inject('initialStore')
@observer
class Checkbox extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        const onChange = this.props.onChange;

        if (initialStore.templates === 'desktop') {

            return <div className={this.props.children ? "inp-choice is-checked":"inp-choice"} onClick={() => {onChange(!this.props.children)}}>
                <div className="label-choice">
                    <span className="choice__box"></span>
                </div>
            </div>;
        } else {
            return <div className={this.props.children ? "inp-choice is-checked":"inp-choice"} onClick={this.onCheck}>
                <div className="label-choice">
                    <span className="choice__box"></span>
                </div>
            </div>;
        }
    }

    onCheck = () => {
        this.props.onChange(!this.props.children);

        if (document.activeElement) {
            if (document.activeElement.localName === 'textarea' || document.activeElement.localName === 'input') {
                document.activeElement.blur();
            }
        }
    }
}

