import {action, observable, computed} from "mobx";

class accountsStore {

    @observable list = [];
    @observable groups = [];
    @observable patterns = {
        list: [],
        load: false,
        hesh: '',
    };
    @observable active = null;


    @observable openModalReAutorisation = false;
    @observable openModalDelAccount = false;

    @computed get newMessage () {

        let list = [];

        for (const accountID in this.list.slice()) {
            list[accountID] = 0;

            const direct = this.list[accountID].direct;

            //диалоги
            for (const directID in direct.account.list.slice()) {
                const dialog = direct.account.list[directID];

                if (dialog.pending === 1) {
                    list[accountID]++;
                    continue;
                }


                if (dialog.posts.list.length) {
                    const elementItem = dialog.posts.list[dialog.posts.list.length-1];

                    if (dialog.last_seen_at[direct.viewer.user_id] === undefined) {
                        continue;
                    }

                    const timeVievPost = dialog.last_seen_at[direct.viewer.user_id].timestamp;

                    if (elementItem.user_id !== direct.viewer.user_id && elementItem.timestamp > timeVievPost) {
                        //alert(1);
                        list[accountID]++;
                    }


                }
            }
        }

        return list;

    }

    @action updateInitialGroups = (groups) => {
        this.groups = [...groups, {
            id: 0,
            name: '',
        }];
    }



    @action updateInitialPatterns = (list, hesh) => {
        this.patterns.list = [...list];
        this.patterns.load = false;
        this.patterns.hesh = hesh;
    }

    @action updateInitial = (accounts) => {

        let list = accounts.map((item) => {

            //разбираем расписание
            let timetable = item.timetable.list.map((item, key) => {
                return {
                    id: key,
                    type: item,
                };
            });

            let timetable_story = item.timetable.list_story.map((item, key) => {
                return {
                    id: key,
                    type: item,
                };
            });
            //====================

            return {
                id: item.id,
                img: item.img,
                name: item.name,
                group: item.group,
                tip: item.tip,
                proxyInstall: item.proxy,
                error: item.error,
                urlStory: item.urlStory,
                typeList: item.typeList,
                storyTrue: item.story,
                rights: {
                    root: item.rights.root,
                    basic: item.rights.basic,
                    moderation: item.rights.moderation,
                    viewPost: item.rights.viewPost,
                    editingPost: item.rights.editingPost,
                    addUrl: item.rights.addUrl,
                    history: item.rights.history,
                    statistics: item.rights.statistics,
                    direct: item.rights.direct,
                    rate: item.rights.rate,
                },
                post: {
                    list: null,
                    load: false,
                    hash: false,
                },
                postStore: {
                    list: null,
                    listID: 0,
                    load: false,
                    hash: false,
                    search: {
                        start: false,
                        end: false,
                        type: 'time',
                    },
                    loadDate: {
                        start: false,
                        end: false,
                        type: 'time',
                    }
                },
                story: {
                    list: null,
                    load: false,
                    hash: false,
                },
                timetable: {
                    list: timetable,
                    list_story: timetable_story,
                    load: false,
                    hesh: item.timetable.hesh,
                },
                employees: {
                    list: null,
                    load: false,
                    hesh: false,
                },
                proxy: {
                    list: null,
                    load: false,
                    hesh: false,
                },
                direct: {
                    status: true,
                    info: 'launch',
                    account: {
                        list: [],
                        active: false,
                        hash: '',
                        cursor: false,
                    },
                    viewer: {},
                },
                hash_geo: item.hash_geo,
            };
        });

        this.list = list;

        this.sortByGroup();

    }

    sortByGroup() {

        let newArray = [];

        for(const key in this.groups.slice()) {
            if (this.groups[key].id === 0) continue;

            for (const element of this.list.slice()) {
                if (element.group === this.groups[key].id) {
                    newArray[newArray.length] = {...element};
                }
            }
        }

        for (const element of this.list.slice()) {
            if (element.group === 0) {
                newArray[newArray.length] = {...element};
            }
        }

        this.list = [...newArray];
    }

    @action updateDirectList = (idAccount, dialogs, hash, cursor, viewer) => {

        let list =  this.list[idAccount].direct.account.list.slice();

        if (this.list[idAccount].direct.account.hash !== hash) {
            for (const dailog of dialogs) {

                let IdDialog = false;
                for (const dailogSearhID in list) {
                    if (dailog.thread_id === list[dailogSearhID].thread_id) {
                        IdDialog = dailogSearhID;
                        continue;
                    }
                }

                if (IdDialog === false) {
                    list.push({
                        ...dailog,
                        posts: {
                            list: [],
                            status: true,
                            hash: '',
                            cursor: false,
                        },
                    });
                } else {
                    list[IdDialog] = {
                        ...list[IdDialog],
                        ...dailog,
                    };
                }
            }

            this.sortByActiveDirect(list);

            this.list[idAccount].direct.account.list = list;
            this.list[idAccount].direct.account.hash = hash;
            this.list[idAccount].direct.account.cursor = cursor;
            this.list[idAccount].direct.viewer = viewer;
        }
    }

    sortByActiveDirect(list) {

        list.sort((a, b) => a.last_activity_at < b.last_activity_at ? 1 : -1);
    }

    @action updateDirectListPost = (idAccount, dialogs, posts, hash, cursor) => {

        if (hash === '') return false;

        if (!posts) return false;

        let list =  this.list[idAccount].direct.account.list.slice();
        let IdDialog = false;

        for (const dailogSearhID in list) {
            if (dialogs === list[dailogSearhID].thread_id) {
                IdDialog = dailogSearhID;
                continue;
            }
        }

        if (IdDialog === false) return false;

        if (this.list[idAccount].direct.account.list[IdDialog].posts.hash === hash) return false;


        this.list[idAccount].direct.account.list[IdDialog].posts.list = posts;
        this.list[idAccount].direct.account.list[IdDialog].posts.hash = hash;
        this.list[idAccount].direct.account.list[IdDialog].posts.cursor = cursor;
    }

    @action updateActive = (idAccount) => {
        this.active = idAccount;
    }

    @action getPost = (idAccount, hash, list, rate, status = null) => {

        if (list) {
            let newList =list.map((item) => {
                return {
                    ...item,
                };
            });

            this.list[idAccount].post.list = newList;
            this.list[idAccount].post.hash = hash;



        }

        if (status !== null) {
            this.list[idAccount].error = status;
        }

        this.list[idAccount].rights.rate = rate;

        this.list[idAccount].post.load = false;
    }

    @action getPostStore = (idAccount, list) => {

        if (list) {

            let listOriginal = this.list[idAccount].postStore.list;
            if (listOriginal === null) {
                listOriginal = [];
            }


            for (const key in list) {
                listOriginal.push(list[key]);
            }

            this.list[idAccount].postStore.list = listOriginal;
        }

        this.list[idAccount].post.load = false;
    }

    @action getPostLoad = (acountId) => {
        this.list[acountId].post.load = true;
    }

    @action getStory = (idAccount, hash, list) => {

        if (list) {
            let newList =list.map((item) => {
                return {
                    ...item,
                };
            });
            this.list[idAccount].story.list = newList;
            this.list[idAccount].story.hash = hash;
        }

        this.list[idAccount].story.load = false;
    }

    @action getStoryLoad = (acountId) => {
        this.list[acountId].story.load = true;
    }

    @action getPatterns = (hesh, list) => {

        if (list) {
            let newList = list.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                    text: item.text,
                    account: item.account,
                };
            });
            this.patterns.list = newList;
            this.patterns.hesh = hesh;
        }

        this.patterns.load = false;
    }

    @action getPatternsLoad = (acountId) => {
        this.list[acountId].patterns.load = true;
    }

    @action getTimetable = (idAccount, timetable ,timetable_story, hesh) => {

        if (timetable) {
            let newList = timetable.map((item, key) => {
                return {
                    id: key,
                    type: item,
                };
            });
            this.list[idAccount].timetable.list = newList;
        }

        if (timetable_story) {
            let newList_story = timetable_story.map((item, key) => {
                return {
                    id: key,
                    type: item,
                };
            });
            this.list[idAccount].timetable.list_story = newList_story;
        }

        this.list[idAccount].timetable.hesh = hesh;
        this.list[idAccount].timetable.load = false;
    }

    @action getTimetableLoad = (acountId) => {
        this.list[acountId].timetable.load = true;
    }

    @action getEmployees = (idAccount, hash, employees) => {

        if (employees) {
            let newList = employees.map((item) => {
                return {
                    id: item.id,
                    email: item.email,
                    name: item.name,
                    moderation: item.moderation,
                    view_post: item.view_post,
                    editing_post: item.editing_post,
                    history: item.history,
                    statistics: item.statistics,
                    url: item.url,
                    direct: item.direct,
                    photo: item.photo,
                };
            });
            this.list[idAccount].employees.list = newList;
            this.list[idAccount].employees.hesh = hash;
        }

        this.list[idAccount].employees.load = false;
    }

    @action getEmployeesLoad = (idAccount) => {
        this.list[idAccount].employees.load = true;
    }

    @action getProxy = (idAccount, hesh, list) => {

        if (list) {

            let newList = {status: false};

            if (list.status) {
                newList = {
                    status: true,
                    ip: list.ip,
                    port: list.port,
                    login: list.login,
                    password: list.password,
                    statusJob: list.statusJob,
                };
            }

            this.list[idAccount].proxy.list = newList;
            this.list[idAccount].proxy.hesh = hesh;
        }

        this.list[idAccount].proxy.load = false;
    }

    @action getProxyLoad = (idAccount) => {
        this.list[idAccount].proxy.load = true;
    }
}

export default accountsStore;
