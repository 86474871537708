import React from 'react';
import {inject, observer} from "mobx-react";

import APIsettings from "../../api/settings";
import {observable} from "mobx";
import SettingsSecurity from "../../templates/settings/security";

@inject('initialStore')
@inject('notificationStore')
@observer
class Security extends React.Component {

    @observable password = '';
    @observable passwordNew = '';
    @observable passwordNew2 = '';

    constructor (props) {
        super(props);

        this.passwordChange = this.passwordChange.bind(this);
        this.passwordNewChange = this.passwordNewChange.bind(this);
        this.passwordNew2Change = this.passwordNew2Change.bind(this);
        this.saveClick = this.saveClick.bind(this);
    }

    render() {

        const props = {
            password: this.password,
            passwordNew: this.passwordNew,
            passwordNew2: this.passwordNew2,
            passwordChange: this.passwordChange,
            passwordNewChange: this.passwordNewChange,
            passwordNew2Change: this.passwordNew2Change,
            saveClick: (e) => {this.saveClick(e)}
        }

        return <SettingsSecurity {...props}/>;
    }

    passwordChange (event) {
        this.password = event;
    }

    passwordNewChange (event) {
        this.passwordNew = event;
    }

    passwordNew2Change (event) {
        this.passwordNew2 = event;
    }

    saveClick(resolve) {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.settings;
        const textCode = initialStore.text.code;

        if (this.password === '') {
            notificationStore.add(text.enterYourOldPassword);
            resolve();
            return false;
        }

        if (this.passwordNew === '') {
            notificationStore.add(text.enterNewPassword);
            resolve();
            return false;
        }

        if (this.passwordNew2 === '') {
            notificationStore.add(text.enterNewPasswordAgain);
            resolve();
            return false;
        }

        if (this.passwordNew !== this.passwordNew2) {
            notificationStore.add(text.newPasswordsDontMatch);
            resolve();
            return false;
        }

        APIsettings.setSecurity(this.password, this.passwordNew, this.passwordNew2).then((data) => {
            if (data.status) {
                this.password = '';
                this.passwordNew = '';
                this.passwordNew2 = '';
                notificationStore.add(text.passwordChanged);
            } else {
                if (data.code === 1) {
                    notificationStore.add(textCode[85]);
                } else {
                    notificationStore.add(textCode[86]);
                }
            }
        }).catch((e) => {
            notificationStore.add(textCode[87]);
        }).finally(() => {
            resolve();
        });
    }
}
export default Security;