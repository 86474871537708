import React from 'react';
import {inject, observer} from "mobx-react";

import Input from "../elements/input";
import ReactDOM from "react-dom";

export default
@inject('initialStore', 'mediaStore')
@observer
class PostTemplateAccounts extends React.Component {

    constructor(props) {
        super(props);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.window = React.createRef();
    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        if (initialStore.templates === 'desktop') {
            return(
                <aside className="sidebar">
                    <span className="sidebar-title">{this.props.nameBox}</span>
                    <div className="sidebar-search">
                        <div className="chat-search">
                            <span className="chat-search__icon"><i className="icon icon-chat-search"></i></span>
                            <div className="chat-search__input">
                                <Input placeholder={text[92]} className={'form-controlSearh'} onChange={this.props.onChange}>{this.props.searhValue}</Input>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-scroll">
                        <div className="sidebar-users">
                            {this.props.groups}
                        </div>
                    </div>
                </aside>
            );
        } else {

            const mediaStore = this.props.mediaStore;

            if (!mediaStore.openMenuMobile) {
                this.updateStatusCloseFon();
            } else {
                this.updateStatusCloseFon();
            }

            return(
                <div className={mediaStore.openMenuMobile ? "sidebar-panel sidebar-panel-open sidebar-panel-accounts" : "sidebar-panel sidebar-panel-accounts"} ref={this.window}>
                    <span className="sidebar-title">{this.props.nameBox}</span>
                    <div className="sidebar-search">
                        <div className="chat-search">
                            <span className="chat-search__icon"><i className="icon icon-chat-search"></i></span>
                            <div className="chat-search__input">
                                <Input placeholder={text[92]} className={'form-controlSearh'} onChange={this.props.onChange}>{this.props.searhValue}</Input>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-scroll">

                            {this.props.groups}

                    </div>
                </div>
            );
        }

    }

    updateStatusCloseFon = () => {

        const mediaStore = this.props.mediaStore;

        if (mediaStore.openMenuMobile) {
            document.addEventListener('touchend', this.handleClickOutside, false);
        } else {
            document.removeEventListener('touchend', this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент

            if (!node.contains(e.target)) {
                // Если клик не производился и на кнопку открытия окна смайлов, то скрываем блок.
                const mediaStore = this.props.mediaStore;

                mediaStore.openMenuMobile = false;
            }

        } catch(error) {
            return null;
        }
    }
}

