import React from 'react';
import {inject, observer} from "mobx-react";

import AccountDirectUsers from "./users";

export default
@inject('initialStore')
@observer
class AccountDirectDialogs extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {



            return (
                <div className="chat-info">
                    {/*<div className="chat-search">
                    <span className="chat-search__icon"><i className="icon icon-chat-search"></i></span>
                    <div className="chat-search__input"><input onChange={this.searhDialogs} placeholder="Поиск по диалогам"/></div>
                </div>*/}

                    {this.props.AllPending ?
                        <div className="chat-request" onClick={this.props.buttonPendingClick}>
                            <span className="title-request">{this.props.buttonPendingStatus === 0 ? <>Новые запросы <span className="count-request">{this.props.AllPending}</span></>: <>Все диалоги</>}</span>
                        </div>
                        :null}

                    <AccountDirectUsers
                        cursor={this.props.cursor}
                        cursorLoad={this.props.nextLoadDialog}
                        AddconteynerRef={this.props.addListDialogsRef}
                    >
                        {this.props.dialogs}
                        {this.props.dialogNextLoad}
                    </AccountDirectUsers>
                </div>
            );
        } else {
            return (<div className="chat-info" style={this.props.activeID ?{display: 'none'}:null}>

            {this.props.AllPending ?
                <div className="chat-request" onClick={this.props.buttonPendingClick}>
                    <span className="title-request">{this.props.buttonPendingStatus === 0 ? <>Новые запросы <span className="count-request">{this.props.AllPending}</span></>: <>Все диалоги</>}</span>
                </div>
                :null}

                    <AccountDirectUsers
                        cursor={this.props.cursor}
                        cursorLoad={this.props.nextLoadDialog}
                        AddconteynerRef={this.props.addListDialogsRef}
                    >
                        {this.props.dialogs}
                        {this.props.dialogNextLoad}
                    </AccountDirectUsers>
                </div>
            );
        }
    }
}