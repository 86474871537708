import React from "react";

const ru = {
    basicSettings: 'Основные настройки',
    avatar: 'Аватар',
    edit: 'Изменить',
    name: 'Имя',
    timezone: 'Часовой пояс',
    email: 'Электронная почта',
    phoneNumber: 'Номер телефона',
    telegram: 'Telegram',
    disable: ['Отключить', 'Отключить...'],
    refresh: ['Обновить', 'Обновить...'],
    telegramInstallText: ['Для привязки Telegram аккаунта, напишите нашему боту', 'код'],
    addTelegram: ['Подключить Telegram для уведомлений', 'Подключить Telegram для уведомлений...'],
    serviceLanguage: 'Язык сервиса',
    automatic: 'Автоматическое',
    changesSaved: 'Изменения сохранены',
    safety: 'Безопасность',
    safetyMobile: 'Сменить пароль',
    notifications: 'Уведомления',
    accountGroups: 'Группы аккаунтов',
    affiliateProgram: 'Партнерская программа',
    enterYourOldPassword: 'Введите старый пароль',
    enterNewPassword: 'Введите новый пароль',
    enterNewPasswordAgain: 'Введите новый пароль еще раз',
    newPasswordsDontMatch: "Новые пароли не совпадают",
    passwordChanged: 'Пароль изменен',
    loading: 'Загрузка',
    postPublished: 'Пост опубликован',
    posFailedToPublish: 'Пост не удалось опубликовать',
    postDeletedSchedule: 'Посту удален по расписанию',
    postCouldNotDeletedOnSchedule: 'Пост не удалось удалить по расписанию',
    postArchivedSchedule: 'Пост заархивирован по расписанию',
    postCouldNotArchivedOnSchedule: 'Пост не удалось заархивировать по расписанию',
    accountAuthorizationError: 'Ошибка авторизации аккаунта',
    postAddedByLink: 'Пост добавлен по ссылке',
    direct: 'Директ',
    popupWindows: 'Всплывающие окна',
    notificationsSaved: 'Уведомления сохранены',
    serviceNotifications: 'Уведомления сервиса',
    notificationType: 'Тип уведомления',
    post: 'Почта',
    window: 'Окно',
    telegram2: 'Телеграм',
    sound: 'Звук',
    telephone: 'Push',
    groupName: 'Group name',
    newGroup: 'Новая группа',
    textInformGroups: <>Здесь вы можете создавать группы аккааунтов и <br/>сортировать аккаунты в нужном вам порядке.</>,
    textInformReferrals: <>Наша партнерская программа позволит Вам зарабатывать 20% от всех платежей привлеченных Вами клиентов.<br/>Средства можно вывести по запросу, когда на вашем балансе не менее 1000 руб.</>,
    referralLink: 'Реферальная ссылка',
    copy: ['Скопировать', 'Скопировано'],
    numberOfClicksOnLink: 'Количество переходов по ссылке',
    numberOfRegistrationsReference: 'Количество регистраций по ссылке',
    totalEarned: 'Всего заработано',
    totalPaid: 'Всего выплачено',
    currentBalance: 'Текущий баланс',
    rub: 'Руб',
    referalInffo_1: 'Для выплаты партнерского вознаграждения, напишите заявку на support@likepro.ru с указанием реквизитов для выплаты - номер карты, либо ЮMoney. Выплаты производятся раз в месяц в течении 3-5 раб. дней.',
    referalInffo_2: 'Запрещена регистрация мультиаккаунтов с целью получения партнерских выплат, т.е. нельзя быть рефералом самому себе.',
}

const en = {
    basicSettings: 'Basic settings',
    avatar: 'Avatar',
    edit: 'Edit',
    name: 'Name',
    timezone: 'Timezone',
    email: 'E-mail',
    phoneNumber: 'Phone number',
    telegram: 'Telegram',
    disable: ['Disable', 'Disable...'],
    refresh: ['Refresh', 'Refresh...'],
    telegramInstallText: ['To link your Telegram account, write to our bot', 'code'],
    addTelegram: ['Connect Telegram for notifications', 'Connect Telegram for notifications...'],
    serviceLanguage: 'Service language',
    automatic: 'Automatic',
    changesSaved: 'Changes saved',
    safety: 'Security',
    safetyMobile: 'Reset password',
    notifications: 'Notifications',
    accountGroups: 'Account groups',
    affiliateProgram: 'Affiliate program',
    enterYourOldPassword: 'Enter your old password',
    enterNewPassword: 'Enter new password',
    enterNewPasswordAgain: 'Enter new password again',
    newPasswordsDontMatch: "New passwords don't match",
    passwordChanged: 'Password changed',
    loading: 'Loading',
    postPublished: 'Post published',
    posFailedToPublish: 'Post failed to publish',
    postDeletedSchedule: 'Post deleted on schedule',
    postCouldNotDeletedOnSchedule: 'The post could not be deleted on schedule',
    postArchivedSchedule: 'Post archived on schedule',
    postCouldNotArchivedOnSchedule: 'The post could not be archived on schedule',
    accountAuthorizationError: 'Account authorization error',
    postAddedByLink: 'Post added by link',
    direct: 'Direct',
    popupWindows: 'Pop-up windows',
    notificationsSaved: 'Notifications saved',
    serviceNotifications: 'Service notifications',
    notificationType: 'Notification type',
    post: 'E-Mail',
    window: 'Window',
    telegram2: 'Telegram',
    sound: 'Sound',
    telephone: 'Push',
    groupName: 'Group name',
    newGroup: 'New group',
    textInformGroups: <>Here you can create an account groups and <br/>sort the accounts in the order you want.</>,
    textInformReferrals: <>Our affiliate program will allow you to earn up to 20% of all the payments from your invited clients.<br/>Funds can be withdrawn upon request when your balance is at least 1000 rub.</>,
    referralLink: 'Referral link',
    copy: ['Copy', 'Copied'],
    numberOfClicksOnLink: 'Number of clicks on the link',
    numberOfRegistrationsReference: 'Number of registrations by reference',
    totalEarned: 'Total earned',
    totalPaid: 'Total paid',
    currentBalance: 'Current balance',
    rub: 'Rub',
    referalInffo_1: 'For the referral payment, write a request to support@likepro.ru include the payment details and / or a card number, or Yandex.Money. Payments are made once a month within 3-5 workdays. days.',
    referalInffo_2: 'Its forbidden to multiply or create similar accounts in order to get the commission , i.e. you cannot get a commission to yourself.',
}

const settings = {
    ru: ru,
    en: en,
}

export default settings;
