import React from 'react';
import {inject, observer} from "mobx-react";
import directLoad from "../../../../media/gif/directLoad.gif";


export default
@inject('initialStore')
@observer
class AccountDirectMessageErrorTwuText extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {


            return (
                <div className="chat-message__inner">
                    <div className="inner-content">
                        <div className="column-content">
                            <p className="chat-message__text">{this.props.title} {this.props.url ? <a href={this.props.url} target="_blank" className="user">{this.props.urlName}</a>:null}</p>
                            <span className="chat-message__help">{this.props.text}</span>
                        </div>
                        <div className="column-content date">
                            <time dateTime={this.props.time} className="chat-message__date static">{this.props.time}</time>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="chat-message__inner">
                    <div className="inner-content">
                        <div className="column-content">
                            <p className="chat-message__text">{this.props.title} {this.props.url ? <a href={this.props.url} target="_blank" className="user">{this.props.urlName}</a>:null}</p>
                            <span className="chat-message__help">{this.props.text}</span>
                        </div>
                        <div className="column-content date">
                            <time dateTime={this.props.time} className="chat-message__date static">{this.props.time}</time>
                        </div>
                    </div>
                </div>
            );
        }
    }

}