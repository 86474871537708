import React from 'react';
import {inject, observer} from "mobx-react";

export default
@inject('initialStore')
@observer
class templateModalAddAccountFbInst extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;

        if (initialStore.templates === 'desktop') {

            let render = <>
                <span className="title-box center">{text[58]}</span>
                <div className="login">
                    <div className="login-type">

                        <p style={{margin: '20px 0', lineHeight: '21px'}}>{text[59]}</p>

                        <a href={this.props.url} target="_blank" onClick={this.props.activation} className="btn btn-block instagram">{text[60]}</a>
                    </div>
                    <div className="more-btn btns-box">
                        <button type="button" className="btn border btn-primary" style={{width: '100%'}} onClick={this.props.close}>{text[32]}</button>
                    </div>
                </div>

            </>;

            return render;
        } else {

            let url = <a href={this.props.url} target="_blank" onClick={this.props.activation} className="btn btn-block instagram no-fastclick">{text[60]}</a>;
            if (initialStore.platform === 'ios' || initialStore.platform === 'android') {
                url = <div onClick={() => {this.props.activation(); window.openUrl(this.props.url)}} className="btn btn-block instagram no-fastclick no-fastclick-click-css">{text[60]}</div>;
            }

            let render = <>
                <span className="title-box center">{text[58]}</span>
                <div className="login">
                    <div className="login-type">

                        <p style={{margin: '20px 0', lineHeight: '21px'}}>{text[59]}</p>

                        {url}
                    </div>
                    <div className="more-btn btns-box">
                        <button type="button" className="btn border btn-primary" style={{width: '100%'}} onClick={this.props.close}>{text[32]}</button>
                    </div>
                </div>

            </>;

            return render;
        }
    }
}
