import React from 'react';
import {inject, observer} from "mobx-react";
import directLoad from "../../../../media/gif/directLoad.gif";


export default
@inject('initialStore')
@observer
class AccountDirectMessageMediaShare extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {

            let boxMedia = '';
            let width = 300;
            let height = 300;
            if (this.props.width > this.props.height) {
                height = Math.round(width / (this.props.width/this.props.height));
            } else {
                width = Math.round(height * (this.props.width/this.props.height));
            }

            //ТИП ПОСТА
            //[{this.props.tip}]

            return (
                <a href={this.props.url} target="_blank" className="chat-message__inner">
                    <div className="chat-message__post">
                        <div className="user-post">
                            <div className="img"><img src={this.props.avatar} width={32} height={32}/></div>
                            <div className="cnt">
                                <span className="name">{this.props.username}</span>
                            </div>
                        </div>
                        <div className="img-post">
                            <img src={this.props.image} width={width} height={height}/>
                        </div>
                        <div className="inner-content">
                            <div className="column-content">
                                <p className="chat-message__text chat-message__text_min">
                                    <b>{this.props.username}</b> {this.props.text}
                                </p>
                            </div>
                            <div className="column-content date">
                                <time dateTime={this.props.time} className="chat-message__date static">{this.props.time}</time>
                            </div>
                        </div>
                    </div>
                </a>
            );
        } else {
            let boxMedia = '';
            let width = 300;
            let height = 300;
            if (this.props.width > this.props.height) {
                height = Math.round(width / (this.props.width/this.props.height));
            } else {
                width = Math.round(height * (this.props.width/this.props.height));
            }

            //ТИП ПОСТА
            //[{this.props.tip}]

            return (
                <a href={this.props.url} target="_blank" className="chat-message__inner">
                    <div className="chat-message__post">
                        <div className="user-post">
                            <div className="img"><img src={this.props.avatar} width={32} height={32}/></div>
                            <div className="cnt">
                                <span className="name">{this.props.username}</span>
                            </div>
                        </div>
                        <div className="img-post">
                            <img src={this.props.image} width={width} height={height}/>
                        </div>
                        <div className="inner-content">
                            <div className="column-content">
                                <p className="chat-message__text chat-message__text_min">
                                    <b>{this.props.username}</b> {this.props.text}
                                </p>
                            </div>
                            <div className="column-content date">
                                <time dateTime={this.props.time} className="chat-message__date static">{this.props.time}</time>
                            </div>
                        </div>
                    </div>
                </a>
            );
        }
    }

}