import React from 'react';
import {inject, observer} from "mobx-react";

import {observable} from "mobx";
import Checkbox from "../../../templates/elements/checkbox";

import loadFileObrabotchikImageIgtv from "../../../model/loadFileObrabotchikImageIgtv";

@inject('mediaStore', 'accountsStore', 'notificationStore', 'initialStore')
@observer
class PrivievPost extends React.Component {

    @observable status = false;

    @observable style = null;

    constructor (props) {
        super(props);

        const mediaStore = this.props.mediaStore;

        this.status = mediaStore.PrivievPost;
    }

    render() {
        const accountsStore = this.props.accountsStore;
        const mediaStore = this.props.mediaStore;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        const accounts = accountsStore.list.slice();
        const accountsOK = mediaStore.accounts.slice();

        for (let key in accountsOK) {

            let idDB = 0;
            for (let keys in accounts) {
                if (accountsOK[key].id === accounts[keys].id) {
                    idDB = keys;
                }
            }
        }

        if (mediaStore.type !== 'igtv' && mediaStore.type !== 'editIgtv' && mediaStore.type !== 'reels' && mediaStore.type !== 'editReels') {
            return null;
        }

        let info = text[203];

        if (mediaStore.type === 'igtv' || mediaStore.type === 'editIgtv') {
            info = text[204];
        }

        let render = (
            <div className="group-publication">
                <div className="add-publication__comments">
                    <div className="form-group">
                        <div className="form-check-wrap">
                            <div className="form-check">
                                <div className="column-check">
                                    <span className="title-check">{text[202]}</span>
                                    <p className="desc-check">{info}</p>
                                </div>
                                <Checkbox onChange={this.OnAction}>{this.status}</Checkbox>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );

        return render;
    }

    componentDidUpdate() {
        const mediaStore = this.props.mediaStore;

        this.status = mediaStore.PrivievPost;
    }

    OnAction = (value) => {
        const mediaStore = this.props.mediaStore;

        this.status = value;

        mediaStore.PrivievPost = value;
    }
}

export default PrivievPost;
