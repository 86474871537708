import React from 'react';
import ReactDOM from "react-dom";

import {inject, observer} from "mobx-react";

import {observable} from "mobx";

import FileSaver from "file-saver";

import dowenloadMediaFile from "../../media/svg/dowenloadMediaFile.svg";

import axios from "axios";
import file from "../../model/file";

@inject('postStore')
@inject('accountsStore', 'initialStore', 'notificationStore')
@observer
class DuwenloadMediaButton extends React.Component{

    @observable load = false;

    constructor (props) {
        super(props);

    }

    render() {
        const initialStore = this.props.initialStore;

        //if (initialStore.cordova) return null;
        if (initialStore.platform === 'ios' || initialStore.platform === 'android') {

            if (!this.load) {
                return <span onClick={this.dowenloadMediaMobile} className='download-picture'><img src={dowenloadMediaFile}/></span>
            } else {
                return <span className='download-picture btn-primary-animate'><img src={dowenloadMediaFile}/></span>
            }

        } else {
            if (!this.load) {
                return <span onClick={this.dowenloadMedia} className='download-picture'><img src={dowenloadMediaFile}/></span>
            } else {
                return <span className='download-picture btn-primary-animate'><img src={dowenloadMediaFile}/></span>
            }
        }
    }

    dowenloadMediam = () => {
        const url = this.props.url;
        const type = this.props.type;

        this.load = true;

        axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const blob = new Blob([response.data]);

            //FileSaver.saveAs(blob, 'LikePro.ru.'+type);
            if (!window.saveFile) {
                alert('Non platform saveFile');
                return;
            }

            const coolback = (status) => {
                if (status) {
                    alert('Media file Save');
                } else {
                    alert('Error save media file.');
                }
                this.load = false;
            }

            window.saveFile(blob, type, coolback);

        }).catch(() => {
            alert('Error load file. No connect. ');
            this.load = false;
        });
    }

    dowenloadMediaMobile = () => {
        const url = this.props.url;
        const type = this.props.type;

        this.load = true;

        if (!window.saveFile) {
            alert('Non platform saveFile');
            return;
        }

        const coolback = (status) => {
            if (status) {
                this.props.notificationStore.add('', 'Файл сохранен в галлерею');
            } else {
                this.props.notificationStore.add('Ошибка', 'Не удалось сохранить файл, проверьте соединение с интернетом');
            }
            this.load = false;
        }

        if (type === 'jpg') {
            window.saveFile(url, 'image', coolback);
        } else {
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const blob = new Blob([response.data], { type: 'video/mp4' });

                window.saveFile(blob, 'video', coolback);
            }).catch(() => {
                alert('Error load file. No connect. ');
            }).finally(() => {
                this.load = false;
            });
        }


    }

    dowenloadMedia = () => {
        const url = this.props.url;
        const type = this.props.type;

        this.load = true;

        axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const blob = new Blob([response.data]);

            FileSaver.saveAs(blob, 'LikePro.ru.'+type);

        }).catch(() => {
            alert('Error load file. No connect. ');
        }).finally(() => {
            this.load = false;
        });
    }

}

export default DuwenloadMediaButton;