import React from 'react';
import {Redirect} from "react-router";

import APIuser from "../../api/user";
import Form from './form';
import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import LoaderApp from "../../templates/loaderApp";

@inject('initialStore')
@observer
class Login extends React.Component {

    @observable load = false;

    @observable initial = false;

    @observable redirect = null;

    constructor (props) {
        super(props);

        this.state = {
            mail: '',
            password: '',
            inform:'',
        };

        this.mailOnChange = this.mailOnChange.bind(this);
        this.passwordOnChange = this.passwordOnChange.bind(this);
        this.okOnClick = this.okOnClick.bind(this);
    }

    render() {

        if (!this.initial) {
            return <LoaderApp name={this.redirect}/>;

        }

        return (<Form mail={this.state.mail}
                      mailOnChange={this.mailOnChange}
                      password={this.state.password}
                      passwordOnChange={this.passwordOnChange}
                      inform={this.state.inform}
                      okOnClick={this.okOnClick}
                      load={this.load}
        />);
    }

    mailOnChange (value) {
        this.setState({mail: value});
    }

    passwordOnChange (value) {
        this.setState({password: value});
    }

    okOnClick () {

        const initialStore = this.props.initialStore;

        const text = initialStore.text.code;

        this.load = true;


        APIuser.getAuthorization(this.state.mail, this.state.password).then(data => {

            this.load = false;

            if (!data.status) {
                this.setState({inform: <div>{text[104]}</div>});
            } else if (data.code) {
                this.setState({inform: <div>{text[28]}: {text[data.code]}</div>});
            } else if (!data.authorization) {
                this.setState({inform: <div>{text[105]}</div>});
            } else {
                this.props.history.push(`/`);
            }
        });
    }



    componentDidMount() {
        const initialStore = this.props.initialStore;

        APIuser.getLogin().then((data) => {
            if (data) {
                this.props.history.push(`/`);
            } else {
                this.initial = true;
            }
        }).catch((error) => {
            this.redirect = 'Ошибка: проверьте подключение к интернету';
        });

    }
}
export default Login;