import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

export default
@inject('initialStore')
@observer
class CheckBoxGreen extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <div onClick={this.props.action} className={this.props.children ? 'inp-checkbox empty is-checked':'inp-checkbox empty'}>
                    <div className="label-check">
                        <span className="checkbox__box"></span>
                    </div>
                </div>;
        } else {
            return <div onClick={this.props.action} className={this.props.children ? 'inp-checkbox empty is-checked':'inp-checkbox empty'}>
                <div className="label-check">
                    <span className="checkbox__box"></span>
                </div>
            </div>;
        }

    }
}

