import store from "../stor/mainStore";
import mediaStore from "../stor/mediaStore";

export default function loadFileAndBloobUrl(url, id, blob = false, informBD = true, coolBackOnProgress = false, createObjectURL = true) {
    return new Promise((resolve, reject) => {

        const xhrReq = new XMLHttpRequest();
        xhrReq.open('GET', url, true);
        xhrReq.responseType = 'blob';


        xhrReq.onload = function () {
            if (this.status === 200) {

                if (!blob) {
                    const vid = URL.createObjectURL(this.response);
                    resolve(vid);
                } else {
                    if (createObjectURL) {
                        const vid = URL.createObjectURL(this.response);
                        resolve({url: vid, blob: this.response});
                    } else {
                        resolve({blob: this.response});
                    }

                }

            }
        };
        xhrReq.onloadend = function () {
            if (xhrReq.status == 404)
                reject(404);
        };
        xhrReq.onerror = function (error) {
            alert('Ваш браузер или среда не поддерживаает Blob файлы. Для начала попробуйте перезагрузить ваше устройство. Если не поможет, обновите браузер.');
            reject(false);
        };
        xhrReq.onprogress = function (e) {
            if (e.lengthComputable && informBD) {
                const percent = ((e.loaded / e.total) * 100 | 0);

                const mediaStore = store.mediaStore;

                let mediaLoadingPercentData = [...mediaStore.mediaLoadingPercentData];

                mediaLoadingPercentData[id] = percent;

                mediaStore.actionMediaLoadingPercentData(mediaLoadingPercentData);
            }
            if (coolBackOnProgress) {
                const percent = ((e.loaded / e.total) * 100 | 0);
                coolBackOnProgress(percent);
            }
        };
        xhrReq.send();
    })
}