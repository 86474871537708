import {action, observable} from "mobx";

class modalAddAccount {

    @observable open = false;

    @observable type = false;

    //Add Instagram
    @observable level = 0;
    @observable login = '';
    @observable password = '';
    @observable twoFactorIdentifier = '';
    @observable code = '';

    //Level 0
    @observable openProxy = false;
    @observable proxyIP = '';
    @observable proxyPort = '';
    @observable proxyLogin = '';
    @observable proxyPassword = '';

    //Level 2
    @observable method = [];
    @observable method_verification = '';
    @observable path = '';
    @observable nonce_code = '';
    @observable user_id = '';
    @observable totp = false;

    //VK
    @observable vk_url = '';

    //Tg
    @observable token = '';
    @observable chanel = '';

    @action openActive = () => {
        this.open = true;
    }

    @action closeActive = () => {
        this.open = false;
        this.type = false;
        this.level = 0;
        this.openProxy = false;
        this.proxyIP = '';
        this.proxyPort = '';
        this.proxyLogin = '';
        this.proxyPassword = '';
        this.login = '';
        this.password = '';
        this.twoFactorIdentifier = '';
        this.code = '';
        this.method = [];
        this.method_verification = '';
        this.path = '';
        this.nonce_code = '';
        this.user_id = '';
        this.totp = false;
        this.token = '';
        this.chanel = '';
    }

    @action actionType = (type) => {
        this.type = type;
    }

    @action openInstagram = () => {
        this.type = 'instagram';
        this.level = 0;
    }

    @action openTg = () => {
        this.type = 'tg';
        this.level = 0;
    }

    @action openVk = (url, level = 0) => {
        this.type = 'vk';
        this.level = level;
        this.vk_url = url;
        this.open = true;
    }

    @action openOk = (url, level = 0) => {
        this.type = 'ok';
        this.level = level;
        this.vk_url = url;
        this.open = true;
    }

    @action openFb = (url, level = 0) => {
        this.type = 'fb';
        this.level = level;
        this.vk_url = url;
        this.open = true;
    }

    @action openFbInstagram = (url, level = 0) => {
        this.type = 'fb-instagram';
        this.level = level;
        this.vk_url = url;
        this.open = true;
    }

    @action openTwitter = (url, level = 0) => {
        this.type = 'twitter';
        this.level = level;
        this.vk_url = url;
        this.open = true;
    }

    @action actionOpenProxy = () => {

        if (this.openProxy) {
            this.openProxy=false;
        } else {
            this.openProxy = true;
        }
        this.proxyIP = '';
        this.proxyPort = '';
        this.proxyLogin = '';
        this.proxyPassword = '';
    }
}
export default modalAddAccount;
