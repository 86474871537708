import React from 'react';
import {inject, observer} from "mobx-react";
export default
@inject('initialStore')
@observer
class InfoContainer extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <div className="lk-not-account">
                {this.props.icon? <img src={this.props.icon}/>:null}
                {this.props.title? <span className="title-account">{this.props.title}</span>:null}
                {this.props.text? <p className="desc-account">{this.props.text}</p>:null}
                {this.props.buttonName ?
                <div className="more-btn">
                    <button type="button" className="btn border btn-primary" onClick={this.props.buttonClick}>{this.props.buttonName}</button>
                </div>
                :null}
            </div>;
        } else {
            return <div className="lk-not-account">
                {this.props.icon? <img src={this.props.icon}/>:null}
                {this.props.title? <span className="title-account">{this.props.title}</span>:null}
                {this.props.text? <p className="desc-account">{this.props.text}</p>:null}
                {this.props.buttonName ?
                    <div className="more-btn">
                        <button type="button" className="btn border btn-primary" onClick={this.props.buttonClick}>{this.props.buttonName}</button>
                    </div>
                    :null}
            </div>;
        }

    }
}

