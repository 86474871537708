import React from "react";
import store from "../stor/mainStore";

import moment from "moment-timezone";

//устанавливаем часовой пояс
//moment.tz.setDefault("Etc/UTC+3");

const Time = {

    initTimeZone (timeZone) {
        if (timeZone === '') {
            moment.tz.setDefault(null);
        } else {
            moment.tz.setDefault(timeZone);
        }
    },

    timeZone () {
        let timezone = store.initialStore.timezone;
        if (timezone === 3 || timezone === '') {
            timezone = null;
        }

        //moment.tz.setDefault(timezone);

        return timezone;
    },

    getTimeOnlint (timezone = false) {

        let language = store.initialStore.language;
        moment.locale(language);

        //moment.tz.setDefault(timezone);
        if (timezone === '') {
            return moment(new Date()).tz(moment.tz.guess()).format('DD.MM.YY HH:mm:ss');
        } else {
            return moment(new Date()).tz(timezone).format('DD MMMM HH:mm:ss');
        }

    },
    getOnlineTimeZone () {
        let offset = new Date().getTimezoneOffset();
        offset = -offset/60;
        return offset;
    },
    getTimeShtamp (plas = 0) {

        const date = moment(Date.now()+(plas*1000));

        const unix = date.unix();

        return unix;
    },
    getTimeOnlintPost (timezone = false) {

        let language = store.initialStore.language;

        moment.locale(language);

        if (!timezone) {
            timezone = store.initialStore.timezone;
        }

        const unixTime = Math.floor(Date.now() / 1000);

        const d = moment(unixTime*1000).zone(-timezone).format('DD.MM.YYYY');

        return d;

    },
    convertDateToUnix(day, unix) {
        const convertUnixToDate = this.convertUnixToDate(unix);

        const date = moment(day);

        date.set({
            h: convertUnixToDate.h,
            m: convertUnixToDate.m,
        });
        const unixNew = date.unix();

        return unixNew;
    },


    convertUnixAndHearMinToUnix(unix, h=false, m=false) {

        const timeUnix = unix*1000;

        const date = moment(timeUnix);

        if (h !== false) {
            date.set({h: h});
        }

        if (m !== false) {
            date.set({m: m});
        }

        date.set({s: 0});

        const unixNew = date.unix();

        return unixNew;
    },
    convertUnixToDate(unix) {

        if (!unix) {
            return {
                date: false,
                h: '',
                m: '',
            };
        }

        let language = store.initialStore.language;
        moment.locale(language);

        const timeUnix = unix*1000;

        const date = moment(timeUnix).format('D MMMM YYYY');
        const h = moment(timeUnix).format('H');
        const m = moment(timeUnix).format('m');

        return {
            date: date,
            h: h,
            m: m,
        };
    },
    convertUnixToDatePostBox(unix) {

        let language = store.initialStore.language;
        let text = store.initialStore.text.time;
        moment.locale(language);

        const timeUnix = unix*1000;

        let date = moment(timeUnix).format('D MMMM');
        let h = moment(timeUnix).format('H');
        let m = moment(timeUnix).format('m');

        let data0 = moment(new Date()).format('D MMMM');
        let data1 = moment(new Date()).add(1,'days').format('D MMMM');
        let datamin = moment(new Date()).add(-1, 'days').format('D MMMM');

        date = date == data0 ? text[0]:date;
        date = date == data1 ? text[1]:date;
        date = date == datamin ? text[3]:date;


        m = "0".substring(m >= 10) + m;
        h = "0".substring(h >= 10) + h;

        return date + text[4] + h + ':' + m;
    },
    convertUnixToDatePostBoxDelitel(unix) {
        let language = store.initialStore.language;
        let text = store.initialStore.text.time;
        moment.locale(language);

        const timeUnix = unix*1000;

        let date = moment(timeUnix).format('D MMMM');
        let h = moment(timeUnix).format('H');
        let m = moment(timeUnix).format('m');

        let data0 = moment(new Date()).format('D MMMM');
        let data1 = moment(new Date()).add(1,'days').format('D MMMM');
        let datamin = moment(new Date()).add(-1, 'days').format('D MMMM');

        date = date == data0 ? text[5]:date;
        date = date == data1 ? text[6]:date;
        date = date == datamin ? text[7]:date;


        m = "0".substring(m >= 10) + m;
        h = "0".substring(h >= 10) + h;

        return date;
    },

    convertUnixToStatistics(unix, format) {
        let language = store.initialStore.language;
        moment.locale(language);

        const timeUnix = unix*1000;
        return moment(timeUnix).format(format);
    },

    TimetableStatus: function (userID, type) {

        const accountList = store.accountsStore.list;
        let listTime = [];

        for (const element of accountList) {
            if (userID === element.id) {
                if (type === 'post' || type === 'igtv' || type === 'reels') {
                    listTime = element.timetable.list.slice();

                } else {
                    listTime = element.timetable.list_story.slice();
                }
                break;
            }
        }

        let TimeStatus = false;
        for (const key in listTime) {
            if (listTime[key].type > 0) {
                TimeStatus = true;
                break;
            }
        }

        if (TimeStatus) {
            return true;
        } else {
            return false;
        }
    },
    dateTimetableGenerator: function (userID, type, addListTimePost = [], amount = 1) {

        let language = store.initialStore.language;
        moment.locale(language);

        const accountList = store.accountsStore.list;
        let listTime = [];
        let postList = [];
        let timeReturn = [];
        for (const element of accountList) {
            if (userID === element.id) {
                if (type === 'post' || type === 'igtv' || type === 'reels') {
                    listTime = element.timetable.list.slice();

                    postList = [];
                    if (element.post.list) {
                        postList = [...element.post.list];
                    }

                } else {
                    listTime = element.timetable.list_story.slice();

                    postList = [];
                    if (element.story.list) {
                        postList = [...element.story.list];
                    }
                }
                break;
            }
        }

        if (addListTimePost.length) {
            for (const postddd of addListTimePost) {
                postList.push({time: postddd});
            }
        }

        let TimeStatus = false;
        let listTimeTrue = [];
        for (const key in listTime) {
            if (listTime[key].type > 0) {
                TimeStatus = true;
                listTimeTrue.push({
                    hear: key,
                    posts: listTime[key].type,
                });
            }
        }

        if (!TimeStatus) {

            const date = moment();
            date.set({m: 0});
            date.set({s: 0});
            let unixDey = date.unix();

            for (let number = 1; number <= amount; number ++) {
               //timeReturn.push(unixDey + (number*3600) + (this.randomInteger(0, 10)*60));
                let date = moment();
                if (addListTimePost.length) {
                    date = moment((addListTimePost[addListTimePost.length-1]+3540)*1000);
                }

                date.set({s: 0});
                let unixDey = date.unix();
                unixDey+=60;
                timeReturn.push(unixDey);
            }

            return timeReturn;

        } else {
            for (let deyPlas = 0; deyPlas <= 365; deyPlas++) {

                const date = moment();
                date.add('days', deyPlas);
                date.set({h: 0});
                date.set({m: 0});
                date.set({s: 0});
                const unixDey = date.unix();

                for (const time of listTimeTrue) {
                    const timeUnixStartMoment = moment(unixDey*1000);
                    timeUnixStartMoment.set({h: time.hear});
                    let timeUnixStart = timeUnixStartMoment.unix();
                    const timeUnixEnd = timeUnixStart+3599;

                    if (deyPlas === 0) {
                        //для тестирования минимального времени, добавляем 1 час что бы в данный час не планировались публикации, тк публикация могла уже выйти
                        const dateTest = moment().unix()+3600;

                        if (timeUnixEnd <= dateTest) {
                            continue;
                        }
                    }

                    let postArrayHear = [];
                    for (const post of postList) {
                        if (post.time >= timeUnixStart && post.time <= timeUnixEnd) {
                            postArrayHear.push(post.time);
                        }
                    }

                    if (postArrayHear.length < time.posts) {

                        const posovNado = time.posts - postArrayHear.length;

                        if (postArrayHear.length) {
                            //timeUnixStart = postArrayHear[postArrayHear.length-1];
                        }

                        if (deyPlas === 0) {
                            const dateTest = moment().set({s: 0}).unix();
                            if (timeUnixStart+60 <= dateTest) {
                                timeUnixStart = dateTest + 120;
                            }
                        }

                        //оставшиеся время делим на количество постов, например осталось 60 минут, надо 2 поста, получаем 30 минут
                        let promejutokMinut = (timeUnixEnd - timeUnixStart) / 60;
                        let rovnihJastey = promejutokMinut / time.posts;

                        for (let numberPost = 0; numberPost < posovNado; numberPost++) {
                            //если осталось меньше 10 минут то данный час пропускаем
                            if (timeUnixEnd - timeUnixStart <= 10 * 60) {
                                break;
                            } else {

                                if (!numberPost && postArrayHear.length) {
                                    timeUnixStart = timeUnixStart + (rovnihJastey * postArrayHear.length  * 60 );
                                }

                                const timeReal = Math.ceil(timeUnixStart+(this.randomInteger(0, 10)*60));

                                timeUnixStart = timeUnixStart + ( rovnihJastey * 60 );
                                timeReturn.push(timeReal);

                            }

                            if (timeReturn.length >= amount) {
                                return timeReturn;
                            }
                        }
                    }
                }
            }
        }
    },
    randomInteger(min, max) {
        // случайное число от min до (max+1)
        let rand = min + Math.random() * (max + 1 - min);
        return Math.floor(rand);
    },
    getUnix() {
        return moment().unix();
    },
    convertUnixToDirect(unix) {

        let language = store.initialStore.language;
        let text = store.initialStore.text.time;
        moment.locale(language);

        const timeUnix = unix*1000;

        let date = moment(timeUnix).format('D MMMM Y');
        let dateOriginal = moment(timeUnix).format('D MMM ');
        let h = moment(timeUnix).format('H');
        let m = moment(timeUnix).format('m');

        let data0 = moment(new Date()).format('D MMMM Y');
        let data1 = moment(new Date()).add(1,'days').format('D MMMM Y');
        let datamin = moment(new Date()).add(-1, 'days').format('D MMMM Y');

        if (date === data0) {
            dateOriginal = '';
        } else if (date === datamin) {
            dateOriginal = text[7]+', ';
        }else if (date === data1) {
            dateOriginal = text[6]+', ';
        }

        m = "0".substring(m >= 10) + m;
        h = "0".substring(h >= 10) + h;

        // добавляем год если не совпадает
        let year = '';
        if (moment(timeUnix).format('Y') !== moment().format('Y')) {
            //year = moment(timeUnix).format('Y') + ' ';
        }

        return dateOriginal + year + '' + h + ':' + m;
    },
    convertUnixToDateRate(unix, trueTime = true) {
        let language = store.initialStore.language;
        let text = store.initialStore.text.time;
        moment.locale(language);

        const timeUnix = unix*1000;

        if (new Date() > timeUnix) {
            return false;
        }

        let date = moment(timeUnix).format('D MMMM YYYY');
        let h = moment(timeUnix).format('H');

        let m = moment(timeUnix).format('m');

        let data0 = moment(new Date()).format('D MMMM');
        let data1 = moment(new Date()).add(1,'days').format('D MMMM');
        let datamin = moment(new Date()).add(-1, 'days').format('D MMMM');

        date = date == data0 ? text[0]:date;
        date = date == data1 ? text[1]:date;
        date = date == datamin ? text[3]:date;


        m = "0".substring(m >= 10) + m;
        h = "0".substring(h >= 10) + h;
        if (trueTime) {
            return date + text[4] + h + ':' + m;
        } else {
            return date;
        }

    },

    timeBoxMinTime (time) {
        let language = store.initialStore.language;
        moment.locale(language);

        let date = moment(time);
        date.set({h: 0});
        date.set({m: 0});
        date.set({s: 0});
        const unixDey = date.unix();

        let date2 = moment();
        date2.set({h: 0});
        date2.set({m: 0});
        date2.set({s: 0});
        const unixDeyOnline = date2.unix();

        if (unixDey < unixDeyOnline) {
            return false;
        } else {
            return true;
        }


    },

    timeBoxMaxTime (time, rateTime) {

        if (!rateTime) {
            return false;
        }

        let language = store.initialStore.language;
        moment.locale(language);

        let date = moment(time);
        date.set({h: 0});
        date.set({m: 0});
        date.set({s: 0});
        const unixDey = date.unix();

        let date2 = moment(rateTime * 1000);
        date2.set({h: 0});
        date2.set({m: 0});
        date2.set({s: 0});
        const unixDeyOnline = date2.unix();

        if (unixDey > unixDeyOnline) {
            return false;
        } else {
            return true;
        }
    },

    timeFinishRate (time) {

        if (!time) {
            return false;
        }

        let language = store.initialStore.language;
        moment.locale(language);

        let date = moment();
        const unixOnline = date.unix();

        const ostalos = time - unixOnline;

        if (ostalos < 43200) {
            return 0;
        }
        if (ostalos < 86400) {
            return 1;
        }
        if (ostalos < 86400 * 2) {
            return 2;
        }
        if (ostalos < 86400 * 3) {
            return 3;
        }

        return 4;

    }

}

export default Time;
