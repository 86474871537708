import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";

export default
@inject('initialStore', 'notificationStore')
@observer
class ButtonDel extends React.Component {

    @observable status = false;
    @observable statusButton = true;

    constructor(props) {
        super(props);
        this.click = this.click.bind(this);
    }

    render() {
        const initialStore = this.props.initialStore;

        let classNameDiv = this.props.color ? "btn border btn-primary":"btn btn-primary";

        if (initialStore.templates === 'desktop') {

            if (this.props.icon) {
                return <i style={this.props.style} onClick={this.click} className="icon icon-settings-remove"></i>
            }

            return <>
                <div style={this.props.style} onClick={this.click} className={classNameDiv}>
                    {initialStore.text.buttonConfirmation[this.props.children].title}
                </div>
            </>;
        } else {

            if (this.props.icon) {
                return <i style={this.props.style} onClick={this.click} className="icon icon-settings-remove"></i>
            }

            return <>
                <div style={this.props.style} onClick={this.click} className={classNameDiv}>
                    {initialStore.text.buttonConfirmation[this.props.children].title}
                </div>
            </>;
        }

    }

    click () {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;


        initialStore.deliteButtonModalOpen = true;
        initialStore.deliteButtonModalFun = this.props.action;
        initialStore.deliteButtonModalName = initialStore.text.post[31];
        initialStore.deliteButtonModalText = initialStore.text.buttonConfirmation[this.props.children].text;
        return;

        if (this.status === false) {
            this.status = true;

            setTimeout(() => {
                if (this.statusButton && this.status) {
                    this.status = false;
                }
            }, 5000);

            //notificationStore.add(initialStore.text.post[31], , 10);

        } else {
            this.statusButton = false;

            new Promise((resolve) => {
                this.props.action(resolve);
            }).then(() => {
                this.status = false;
                this.statusButton = true;
            }).catch(() => {
                this.status = false;
                this.statusButton = true;
            });
        }

        if (document.activeElement) {
            if (document.activeElement.localName === 'textarea' || document.activeElement.localName === 'input') {
                document.activeElement.blur();
            }
        }

    }
}

