import APIgeo from "../../api/geo";
import store from "../../stor/mainStore";
import APIinstagram from "../../api/instagram";

export function getGeo() {

    const mediaStore = store.mediaStore;

    let z = mediaStore.geoObject.z;
    z = z+1;
    mediaStore.geoObject.z = z;
    mediaStore.geoObject.array = [];
    //mediaStore.geoObject.id = false;

    const value =  mediaStore.geoObject.search;

    if (value === '') {
        return false;
    }

    mediaStore.geoObject.load = true;

    APIgeo.setGeo(value, z).then((data) => {

        if (data.z == z) {

            mediaStore.geoObject.load = false;

            if (!data.status) {
                alert(data.info);
                return false;
            }

            mediaStore.geoObject.array = data.return;
        }

        //accountsStore.getStory(idAccountRedax, data.hashStory, data.story);

    }).catch((error) => {

    });
}


export function getGeoNew(accoint) {

    const mediaStore = store.mediaStore;
    const notificationStore = store.notificationStore;

    let z = mediaStore.geoObject.z;

    if (accoint) {
        for (let geoObjectFullElement of mediaStore.geoObjectFull.slice()) {
            if (geoObjectFullElement.idAccount === accoint) {
                z = geoObjectFullElement.z;
            }
        }
    }

    z = z+1;

    let value =  mediaStore.geoObject.search;

    if (!accoint) {
        mediaStore.geoObject.z = z;
        mediaStore.geoObject.array = [];
    } else {
        const newGeoObjectFull = [];
        for (let geoObjectFullElement of mediaStore.geoObjectFull.slice()) {
            if (geoObjectFullElement.idAccount === accoint) {
                newGeoObjectFull.push({
                    ...geoObjectFullElement,
                    z:z,
                    array: [],
                });
                value = geoObjectFullElement.search;
            } else {
                newGeoObjectFull.push({...geoObjectFullElement});
            }
        }
        mediaStore.geoObjectFull = newGeoObjectFull;
    }


    if (value === '') {
        return false;
    }

    if (!accoint) {
        mediaStore.geoObject.load = true;
    } else {
        const newGeoObjectFull = [];
        for (let geoObjectFullElement of mediaStore.geoObjectFull.slice()) {
            if (geoObjectFullElement.idAccount === accoint) {
                newGeoObjectFull.push({
                    ...geoObjectFullElement,
                    load:true,
                });

            } else {
                newGeoObjectFull.push({...geoObjectFullElement});
            }
        }
        mediaStore.geoObjectFull = newGeoObjectFull;
    }


    APIinstagram.getGeo(value).then((data) => {

        let actualZ = mediaStore.geoObject.z;

        if (accoint) {
            for (let geoObjectFullElement of mediaStore.geoObjectFull.slice()) {
                if (geoObjectFullElement.idAccount === accoint) {
                    actualZ = geoObjectFullElement.z;
                    break;
                }
            }
        }

        if (z === actualZ) {


            if (!accoint) {
                mediaStore.geoObject.load = false;
            } else {
                const newGeoObjectFull = [];
                for (let geoObjectFullElement of mediaStore.geoObjectFull.slice()) {
                    if (geoObjectFullElement.idAccount === accoint) {
                        newGeoObjectFull.push({
                            ...geoObjectFullElement,
                            load:false,
                        });

                    } else {
                        newGeoObjectFull.push({...geoObjectFullElement});
                    }
                }
                mediaStore.geoObjectFull = newGeoObjectFull;
            }

            if (!data.status) {
                notificationStore.add('Ошибка ГЕО', data.info);
                return false;
            }
            //console.log(data.return.length);
            if (data.return.length === 0) {
                notificationStore.add('Геопозиция', 'По вашему запросу "' + value + '" ничего не найдено.');
            }


            if (!accoint) {
                mediaStore.geoObject.array = data.return;
            } else {
                const newGeoObjectFull = [];
                for (let geoObjectFullElement of mediaStore.geoObjectFull.slice()) {
                    if (geoObjectFullElement.idAccount === accoint) {
                        newGeoObjectFull.push({
                            ...geoObjectFullElement,
                            array:data.return,
                        });

                    } else {
                        newGeoObjectFull.push({...geoObjectFullElement});
                    }
                }
                mediaStore.geoObjectFull = newGeoObjectFull;
            }
        }

    }).catch((error) => {

    });
}