import React from 'react';
import {inject, observer} from "mobx-react";
import Accounts from "../../component/post/component/Accounts";
import Text from "../../component/post/component/Text";
import Comment from "../../component/post/component/Comment";
import Checkbox from "../elements/checkbox";
import GeoSearh from "../../component/post/component/GeoSearh";
import ButtonDel from "../elements/buttonDel";
import Button from "../elements/button";
import AccountsData from "../../component/post/component/AccountsData";
import FilesLoading from "../../component/post/component/FilesLoading";
import LoadFile from "../../component/post/component/LoadFile";
import MediaIcons from "../../component/post/component/MediaIcons";
import Name from "../../component/post/component/Name";
import Category from "../../component/post/component/Category";

import ButtonLoad from "../elements/buttonLoad";
import ButtonMobileOpenAccounts from "../../component/post/ButtonMobileOpenAccounts";

//Загрузка файлов перетаскиванием
import { FileDrop } from 'react-file-drop';
import MediaIconsMulty from "../../component/post/component/MediaIconsMulty";
import SortTime from "../../component/post/component/SortTime";

export default
@inject('initialStore', 'mediaStore')
@observer
class PostTemplateMulty extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return(
                <>
                    <FileDrop onDrop={this.props.fileDrop}>
                        <div className="file-drop-dragging-over-frame-center-box">Отпустите файл<br/>чтобы добавить в пост</div>
                    </FileDrop>
                    <div className="main">

                        <div className="main-wrapper">

                            <section className="lk view-2">

                                <Accounts/>

                                <div className="content">
                                    <div className="content-inner">

                                        {this.props.infoRedAccount ?
                                        <div className="chief__typical h1 info-type-account">
                                            <h1><span className="color">Аккаунт: </span>
                                                <div className="img-user">
                                                    <span className="social-user"><img src={this.props.infoRedAccount.icon}/></span>
                                                    <span className="inner-img"><img src={this.props.infoRedAccount.img}/></span>
                                                </div>
                                                <div className="info-type-account-name">{this.props.infoRedAccount.name}</div>
                                            </h1>

                                        </div>
                                        :null}
                                        <div className="chief__typical h1 size-2">
                                            <h1>{this.props.nameBox}</h1>
                                        </div>

                                        <div className="add-publication width-2">
                                            <div className="groups-publication">

                                                {this.props.mediaLoadingStatus ? null :
                                                    <>
                                                        <MediaIconsMulty/>
                                                    </>
                                                }

                                                <div className="group-publication">
                                                    <div className="add-publication__photo">
                                                        <div className="more-btn">
                                                            {!this.props.sortTime ?
                                                                <LoadFile/>
                                                                :
                                                                <div className="add-publication__settings">
                                                                    <div className="group-settings">
                                                                        <div className="column-group w-50">
                                                                            <LoadFile/>
                                                                        </div>
                                                                        <div className="column-group w-50">
                                                                            <SortTime/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="more-btn bottom">
                                                <div className="add-publication__settings">
                                                    <div className="group-settings">
                                                        <div className="column-group w-25">
                                                            <ButtonDel style={{width: '100%'}} action={this.props.buttomCloseAction}>postClose</ButtonDel>
                                                        </div>
                                                        <div className="column-group w-25">
                                                            {this.props.buttomCollapseStatus ?
                                                                <Button style={{width: '100%'}} action={this.props.buttomCollapseAction}>collapse</Button>
                                                                :null}
                                                        </div>
                                                        <div className="column-group w-50">
                                                            <ButtonLoad color style={{width: '100%'}} action={(promise) => {this.props.buttonOkAction(promise)}}>{this.props.buttonOkName}</ButtonLoad>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>

                    </div>
                    {this.props.modalEditor}
                </>
            );
        } else {
            return(null);
        }

    }
}

