import React from 'react';
import {inject, observer} from "mobx-react";

import {observable} from "mobx";
import AccountEmployeesAddAndRed from "../../../templates/account/employees/addAndRed";
import APIemployees from "../../../api/employees";

@inject('accountsStore', 'menuStore', 'initialStore', 'notificationStore')
@observer
class EmployeesForm extends React.Component {

    @observable name = '';
    @observable email = '';
    @observable moderation = false;
    @observable view_post = false;
    @observable editing_post = false;
    @observable history = false;
    @observable statistics = false;
    @observable url = false;
    @observable direct = false;

    constructor(props) {
        super(props);

        this.nameChange = this.nameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);

        this.moderationChange = this.moderationChange.bind(this);
        this.view_postChange = this.view_postChange.bind(this);
        this.editing_postChange = this.editing_postChange.bind(this);
        this.historyChange = this.historyChange.bind(this);
        this.statisticsChange = this.statisticsChange.bind(this);
        this.urlChange = this.urlChange.bind(this);
        this.directChange = this.directChange.bind(this);

        this.enter = this.enter.bind(this);

        const accountsStore = this.props.accountsStore;
        const accounts = accountsStore.active;
        const employees = accountsStore.list[accounts].employees.list;

        const id = this.props.id;

        if (id !== true) {
            for (const element of employees) {
                if (element.id === id) {
                    this.name = element.name;
                    this.email = element.email;

                    this.moderation = element.moderation;
                    this.view_post = element.view_post;
                    this.editing_post = element.editing_post;
                    this.history = element.history;
                    this.statistics = element.statistics;
                    this.url = element.url;
                    this.direct = element.direct;
                }
            }
        }
    }

    render() {

        const accountsStore = this.props.accountsStore;
        const accounts = accountsStore.active;
        const employees = accountsStore.list[accounts].employees.list;

        const id = this.props.id;

        const type = id === true ? 'add' : 'red';

        const employeesJSX = <AccountEmployeesAddAndRed
            actionBek={this.props.actionBek}
            type={type}

            name={this.name}
            nameChange={this.nameChange}

            email={this.email}
            emailChange={this.emailChange}

            moderation={this.moderation}
            moderationChange={this.moderationChange}

            view_post={this.view_post}
            view_postChange={this.view_postChange}

            editing_post={this.editing_post}
            editing_postChange={this.editing_postChange}

            history={this.history}
            historyChange={this.historyChange}

            statistics={this.statistics}
            statisticsChange={this.statisticsChange}

            url={this.url}
            urlChange={this.urlChange}

            direct={this.direct}
            directChange={this.directChange}

            enter={this.enter}
        />;

        return <>
            {employeesJSX}
        </>;
    }

    enter (resolve) {
        const initialStore = this.props.initialStore;
        const notificationStore = this.props.notificationStore;
        const text = initialStore.text.code;

        const name = this.name.trim();
        const email = this.email.trim();

        if (name === '') {
            notificationStore.add(text[28], initialStore.text.code[6]);
            resolve(true);
            return;
        }

        if (email === '') {
            notificationStore.add(text[28], initialStore.text.code[7]);
            resolve(true);
            return;
        }

        //создание пользователя
        const accountsStore = this.props.accountsStore;
        const accounts = accountsStore.active;
        const accountsStoreList = accountsStore.list;

        let idAccount = accountsStoreList[accounts].id;

        if (this.props.id === true) {
            APIemployees.create(idAccount, name, email, this.moderation, this.view_post, this.editing_post, this.history, this.statistics, this.url, this.direct).then((data) => {
                if (data.status) {
                    APIemployees.getEmployees(idAccount, accountsStoreList[accounts].employees.hesh).then((data) => {
                        accountsStore.getEmployees(accounts, data.hesh, data.employees);
                        notificationStore.add(text[118]);
                        resolve(true);
                        this.props.actionBek();
                    }).catch((error) => {
                        notificationStore.add(text[28], initialStore.text.code[8]);
                        resolve(true);
                        this.props.actionBek();
                    });
                } else {
                    resolve(true);
                    notificationStore.add(text[28], initialStore.text.code[data.code]);

                }
            }).catch((e)=> {
                resolve(true);
                notificationStore.add(text[28], initialStore.text.code[0]);
            });

        } else {
            //редактирование
            APIemployees.red(this.props.id, name, this.moderation, this.view_post, this.editing_post, this.history, this.statistics, this.url, this.direct).then((data) => {
                if (data.status) {
                    APIemployees.getEmployees(idAccount, accountsStoreList[accounts].employees.hesh).then((data) => {
                        accountsStore.getEmployees(accounts, data.hesh, data.employees);
                        notificationStore.add(text[119]);
                        resolve(true);
                        this.props.actionBek();
                    }).catch((error) => {
                        notificationStore.add(text[28], initialStore.text.code[8]);
                        resolve(true);
                        this.props.actionBek();
                    });
                } else {
                    resolve(true);
                    notificationStore.add(text[28], initialStore.text.code[data.code]);
                }
            }).catch((e)=> {
                resolve(true);
                notificationStore.add(text[28], initialStore.text.code[0]);
            });
        }
    }

    nameChange (value) {this.name = value;}
    emailChange (value) {this.email = value;}
    moderationChange (value) {this.moderation = value;}
    view_postChange (value) {this.view_post = value;}
    editing_postChange (value) {this.editing_post = value;}
    historyChange (value) {this.history = value;}
    statisticsChange (value) {this.statistics = value;}
    urlChange (value) {this.url = value;}
    directChange (value) {this.direct = value;}
}
export default EmployeesForm;