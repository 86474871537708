import React from "react";

const ru = {
    0: ['1 месяц', '3 месяца (-5%)', '6 месяцев (-10%)', '12 месяцев (-15%)'],
    1: 'Продление тарифа',
    2: 'Перейти к оплате',
    3: 'Продление тарифа и увеличение количества аккаунтов',
    4: 'Уменьшение количества аккаунтов и перерасчет времени тарифа',
    5: 'Сменить тариф',
    6: 'Приобретение тарифа',
    7: 'Для перехода на данный тариф, удалите с сервиса лишнии аккаунты.',
    8: 'Минимальное количество аккаунтов 5 штук.',
    9: 'Максимальное количество аккаунтов 500 штук.',
    10: 'Тариф',
    11: 'PROфессиональный',
    12: 'Аккаунтов',
    13: 'Активен до',
    14: 'Тариф закончился',
    15: (dey) =>  <><b>Осталось</b> {dey} дней</>,
    16: '',
    17: 'Продлить тарифный плана:',
    18: 'Кол-во аккаунтов',
    19: 'На какой срок',
    20: 'Тип операции',
    21: 'Итоговая стоимость',
    22: 'Тариф будет действовать до',
    23: 'Руб',
    24: 'Использовать купон на скидку',
    25: 'Вставьте купон здесь',
    26: 'Способ оплаты',
    27: '₽',
    28: 'Банковская карта',
    29: 'Сбербанк Онлайн',
    30: 'ЮMoney',
    31: 'Qiwi Кошелек',
    32: 'Webmoney',
    33: ['Вы можете оплатить как юридическое лицо. Мин. сумма 3500 руб. Для проведения оперции свяжитесь с оператом ', 'чате', ' или ', 'по почте', 'в '],
    34: 'Отмена',
    35: 'Изменение тарифа',
    36: 'Бесплатно',
    37: (a) => 'Сменить тариф на '+a+' аккаунтов',
    38: 'Для уменьшения количества аккаунтов оплата не требуется. Ваше оставшееся время от тарифа бедет пересчитано.',
    39: 'Купон на скидку активирован',
    40: 'Выберите тариф',
    41: ['5 аккаунтов', '10 аккаунтов', '25 аккаунтов', '50 аккаунтов', '100 аккаунтов'],
    42: 'Купить',
    43: ['Подождите...', 'Идет процесс оплаты'],
    44: ['Готово', 'Покупка прошла успешно'],
    45: ['Ошибка', 'В момент покупки произошла ошибка'],
    46: ['Готово', 'Тариф успешно сменен'],
    47: ['1 месяц', '3 месяца', '6 месяцев', '12 месяцев'],
    48: ['Готово', 'Оплата прошла, тариф будет изменен в течении 10 минут'],
    49: (a, b) => <>Вы действительно хотите перейти на <b>тариф {a} аккаунтов</b> с тарифа {b} аккаунтов?</>,
    50: 'Изменить тариф',
    51: 'Для изменения тарифа, выберите сначала нужное количество аккаунтов',
    52: 'Купить тарифный плана:',
    53: 'Тариф:',
    54: 'аккаунтов',
    55: 'Тариф тестовый: на 3 аккаунта',
    56: 'Занято',
    57: 'из',
    58: 'Сохранить',
    59: 'Тариф закончится:',
    60: 'Новая цена:',
    61: 'в месяц',
    62: <>Выберите нужное количество аккаунтов и сохраните изменение тарифа.<br/><br/><b>Внимание:</b><br/>1. При смене тарифа отнимается 5% от оставшегося времени.<br/>2. Нельзя уменьшать тариф в течении 7 дней после его повышения.</>,
    63: 'Ошибка:',
    64: 'можно выбирать от 5 до 500 аккаунтов',
    65: 'вы выбрали меньше аккаунтов чем у вас добавлено в сервисе.',
    66: 'Скрыть',
    67: 'Изменить тариф',
    68: 'Мой тариф',
}

const en = {
    0: ['1 month', '3 months (-5%)', '6 months (-10%)', '12 months (-15%)'],
    1: 'Renewal of tariff',
    2: 'Proceed to checkout',
    3: 'Renewing the tariff and increasing the number of accounts',
    4: 'Reducing the number of accounts and recalculating the tariff time',
    5: 'Change tariff',
    6: 'Purchase of tariff',
    7: 'To switch to this tariff, remove unnecessary accounts from the service.',
    8: 'The minimum number of accounts is 5 pieces.',
    9: 'The maximum number of accounts is 500.',
    10: 'Rate',
    11: 'PROfessional',
    12: 'Accounts',
    13: 'Active until',
    14: 'Tariff has ended',
    15: (dey) => <><b>{dey} days of the tariff left</b></>,
    16: '',
    17: 'Renewal or change of the tariff plan:',
    18: 'Number of accounts',
    19: 'For what term',
    20: 'Type of operation',
    21: 'Total cost',
    22: 'The tariff will be valid until',
    23: 'Rub',
    24: 'Use a discount coupon',
    25: 'Insert the coupon here',
    26: 'Payment method',
    27: 'RUB',
    28: 'Bank card',
    29: 'Sberbank Online',
    30: 'Yandex.Money',
    31: 'Qiwi Wallet',
    32: 'Webmoney',
    33: ['You can pay as a legal entity. Min, the amount of 3500 rubles. To carry out the operation contact the operator ', 'chat', ' or ', 'email', 'in '],
    34: 'Cancel',
    35: 'Changing the fare',
    36: 'Is free',
    37: (a) => 'Change the rate for '+a+' accounts',
    38: 'To reduce the number of accounts, no payment is required. Your remaining time from the fare will be recalculated.',
    39: 'The discount coupon is activated',
    40: 'Choose a tariff',
    41: ['5 accounts', '10 accounts', '25 accounts', '50 accounts', '100 accounts'],
    42: 'Pay',
    43: ['Wait...', 'Payment in progress'],
    44: ['Done', 'Purchase has been successful'],
    45: ['Error', 'An error occurred at the time of purchase'],
    46: ['Done', 'Tariff changed successfully'],
    47: ['1 month', '3 months', '6 months', '12 months'],
    48: ['Done', 'Payment passed, the tariff will be changed within 10 minutes'],
    49: (a, b) => <>Are you sure you want to upgrade to <b> {a} accounts</b> from {b} accounts?</>,
    50: 'Change tariff',
    51: 'To change the tariff, first select the required number of accounts',
    52: 'Buy a tariff plan:',
    53: 'Rate:',
    54: 'accounts',
    55: 'Starting rate: for 3 accounts',
    56: 'Occupied',
    57: 'of',
    58: 'Save',
    59: 'The tariff will end:',
    60: 'New price:',
    61: 'per month',
    62: <>Select the required number of accounts and save the tariff change.<br/><br/><b>Attention:</b><br/>1. When the tariff is changed, 5% of the remaining time is subtracted.<br/>2. You cannot reduce the tariff within 7 days after its increase.</>,
    63: 'Error:',
    64: 'you can choose from 5 to 500 accounts',
    65: 'you have chosen fewer accounts than you have added in the service.',
    66: 'Hide',
    67: 'Change tariff',
    68: 'My tariff',
}

const rate = {
    ru: ru,
    en: en,
}

export default rate;
