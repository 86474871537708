import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ButtonLoad from "../../elements/buttonLoad";
import Password from "../../elements/password";
import Button from "../../elements/button";

export default
@inject('initialStore')
@observer
class TemplateModalAddAccountInstagramRe extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;

        let render = <>
            <span className="title-box center">{text[13]}</span>
            <div className="login">
                <p className="login-help">
                    {text[14]}
                </p>

                <div className="form-box">
                    <div className="form-group">
                        <span className="input-label">{text[15]}</span>
                        <div className="form-control view-2">{this.props.login}</div>
                    </div>
                    <div className="form-group">
                        <span className="input-label">{text[16]}</span>
                        <Password load={this.props.load} onChange={this.props.actionPassword}>{this.props.password}</Password>
                    </div>
                </div>
            </div>

            <div className="more-btn btns-box">
                <ButtonLoad color action={this.props.actionOK}>add</ButtonLoad>
                <Button load={this.props.load} action={this.props.close}>cancellation</Button>
            </div>
        </>;
        return render;
    }
}

