import React from 'react';
import HeaderMobileName from "../../templates/header/mobileName";
import {inject, observer} from "mobx-react";

@inject('initialStore')
@observer
class HeaderWelcome extends React.Component {



    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.library;

        return <HeaderMobileName name={text[11]} />;
    }

}

export default HeaderWelcome;