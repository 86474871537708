import React from 'react';
import {inject, observer} from "mobx-react";

export default
@inject('menuStore')
@inject('accountsStore')
@inject('initialStore')
@observer
class AccountDirectPosts extends React.Component {

    constructor(props) {
        super(props);

        this.scroll = this.scroll.bind(this);

        this.activeAccount=false;

        this.messages = React.createRef();

        this.scrollHeight = 0;
        this.scrollTop = 0;
        this.clientHeight = 0;
    }

    render() {
        //element.scrollHeight - element.scrollTop < (element.clientHeight



        const initialStore = this.props.initialStore;

        return (
            <div ref={(e) =>{this.messages =e}} onScroll={this.scroll} className="inner-dashboard">
                {this.props.children}
                <div style={{ float:"left", clear: "both" }}
                     ref={(el) => { this.props.addMessagesButton(el); }}>
                </div>
            </div>
        );
    }

    componentDidMount() {
        if (this.activeAccount !== this.props.account) {
            this.activeAccount = this.props.account;
            this.props.scrollToMessagesButton();
        }

        if (this.messages.scrollTop === 0 && this.messages.scrollHeight === this.messages.clientHeight) {
            this.startCursorLoad();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.activeAccount !== this.props.account) {
            this.activeAccount = this.props.account;
            this.props.scrollToMessagesButton();

            //что бы не сработала прокрутка после перехода в другой аккаунт;
            this.scrollHeight = 0;
        }

        if (this.messages.scrollTop === 0 && this.messages.scrollHeight === this.messages.clientHeight) {
            this.startCursorLoad();
        }


        if (this.props.cursor !== 'load') {
            if (this.scrollHeight !== 0 && this.messages.scrollHeight > this.scrollHeight && this.messages.scrollTop<100) {
                //console.log('componentDidUpdate0', this.messages.scrollHeight , this.messages.scrollTop, this.messages.clientHeight);
                this.messages.scrollTop = this.messages.scrollHeight-this.scrollHeight+this.messages.scrollTop;
                //console.log('componentDidUpdate1    ', this.messages.scrollHeight , this.messages.scrollTop, this.messages.clientHeight);
            }

        }

    }

    scroll (event) {

        let element = event.target;

        if (this.props.cursor !== 'load') {

            if (element.scrollTop < 60) {

                this.startCursorLoad();
            }

        }
    }

    startCursorLoad = () => {
        if (this.props.cursor) {
            if (this.props.cursor !== 'load') {
                //console.log('startCursorLoad', this.messages.scrollHeight, this.messages.scrollTop, this.messages.clientHeight);
                this.scrollHeight = this.messages.scrollHeight;
                this.scrollTop = this.messages.scrollTop;
                this.clientHeight = this.messages.clientHeight;
                this.props.cursorLoad();

            }
        }
    }

}