import React from "react";

const ru = {
    0: 'Выберите аккаунты',
    1: 'Выберите аккаунт',
    2: 'Аккаунт',
    3: 'У Вас нет аккаунтов.',
    4: 'Аккаунт не выбран',
    5: 'В социальную сеть Facebook можно добавлять или одно видео или изображения до 10 штук',
    6: <>Создание поста в <span className="nameColorFirm">Библиотеку постов</span></>,
    7: 'Создание поста',
    8: ['Создание поста в ', 'аккаунт', 'аккаунта', 'аккаунтов'],
    9: <>Создание поста в <span className="nameColorFirm">Библиотеку</span></>,
    10: 'Создание поста',
    11: 'Создание постов',
    12: 'Редактирование поста',
    13: 'Создание истории',
    14: 'Создание IGTV',
    15: 'Редактирование истории',
    16: 'Редактирование IGTV',
    17: 'Ошибка',
    18: 'Для добавления файлов, загрузите файлы для редактирования',
    19: 'Для создания первого комментария, требуется включить комментарии',
    20: 'Требуется 1 медиа файл',
    21: 'Требуется минимум 1 медиа файл',
    22: 'Вы забыли добавить описание',
    23: 'Вы забыли указать имя поста',
    24: 'Выберите аккаунты для создания Истории',
    25: 'Выберите аккаунты для создания IGTV',
    26: 'Измените время публикации, данное время уже прошло',
    27: (name) => 'У аккаунта ' + name + ' в выбранное время будет не активен тариф. Продлите тариф или измените время публикации',
    28: 'Превышены лимиты в описании или в первом комментарии по символам, хэштегам или упоминаниям',
    29: 'Превышен лимит в названии поста',
    30: 'Не все файлы еще загружены на сервер',
    31: 'Пост создан',
    32: 'Пост успешно создан, но не удалось обновить посты, перезагрузите страницу',
    33: 'Пост успешно создан',
    34: (code) => 'Неудачная попытка редактирования поста: ' + code,
    35: 'Пост успешно сохранен',
    36: 'Пост успешно сохранен, но не удалось обновить посты, перезагрузите страницу',
    37: 'Не удалось сохранить пост, повторите попытку через 1 минуту',
    38: <>Отпустите файл<br/>чтобы добавить его в пост</>,
    39: 'Аккаунт: ',
    40: '(доступно в Instagram)',
    41: 'Первый комментарий',
    42: 'Первый комментарий под вашей публикацией',
    43: 'Отключить комментарии',
    44: 'Не позволит пользователям оставлять комментарии',
    45: 'Геолокация',
    46: 'Добавление геолокации к посту',
    47: 'Разное описание постов',
    48: 'Форма текста поста для каждого выбранного аккаунта',
    49: 'Разное время публикации',
    50: 'Для всех аккаунтов будет индивидуальное время публикации',
    51: 'Название поста',
    52: 'Без категории',
    53: 'Категория поста',
    54: 'У Вас еще нет шаблонов',
    55: 'Шаблоны',
    56: 'Описание',
    57: 'Загрузить файлы для редактирования',
    58: 'Загрузить файлы для редактирования...',
    59: (info) => 'Загружаю файлы... (' + info + ')',
    60: (info) => 'Обработка файлов... (' + info + ')',
    61: 'Ошибка получения данных2',
    62: 'Ошибка загрузки обложки для видео, проверьте соединение с интернетом. (ошибка на стороне клиента)',
    63: 'Ошибка загрузки обложки для видео, проверьте соединение с интернетом. (ошибка на стороне сервера)',
    64: 'Ошибка 2 загрузки файлов, повторите попытку снова',
    65: 'Текст комментария',
    66: 'Загрузить фото или видео',
    67: 'Загрузить видео',
    68: 'Изображение не добавлено',
    69: 'Видео не добавлено',
    70: 'Видео не может быть добавлено так как оно короче 3х секунд',
    71: 'Для загрузки видео в IGTV, его длина должна быть от 1 минуты',
    72: 'Видео не может быть добавлено, его длина более 30 минут. Обрежьте его или загрузите другое видео',
    73: 'Произошла ошибка при тестировании видео. Загрузите другой файл',
    74: 'Ошибка загрузки',
    75: 'Повторить',
    76: 'Соотношение сторон было изменено у первого медиа файла\'',
    77: 'Пример: https://likepro.ru',
    78: 'Ссылка',
    79: 'Ссылка для истории',
    80: 'Загрузить обложку',
    81: 'Удалить обложку',
    82: 'Добавить обложку',
    83: 'Выберите файл в формате JPG или PNG. Минимальное рекомендуемое разрешение: 492 x 762 пикселя.',
    84: 'Введите название геолокации',
    85: 'Нажмите для поиска геолокации',
    86: 'Дата',
    87: 'выберите дату',
    88: 'Время',
    89: 'Удалить через',
    90: 'Архивировать через',
    91: ['Минут', 'Часов', 'Дней', 'Недель'],
    92: 'Поиск по аккаунтам',
    93: 'Размер',
    94: 'Соотношение',
    95: 'Цвет фона',
    96: 'Вырезать',
    97: 'Оригинальное',
    98: 'Вертикальное',
    99: 'Квадратное',
    100: 'Горизонтальное',
    101: 'Заполнить все',
    102: 'Поместить в кадр',
    103: 'Основное',
    104: 'Обложка',
    105: 'Обрезать',
    106: 'Редактор',
    107: 'Отметить пользователей',
    108: 'Отмена',
    109: 'Сохранить',
    110: 'Введите название аккаунта',
    111: 'Нажмите для поиска аккаунта',
    112: (userName) => 'Аккаунт ' + userName + ' уже добавлен',
    113: 'Превышено количество отметок аккаунтов на пост. Максимальное количество 20 отметок',
    114: 'Ошибка поиска',
    115: 'Поиск аккаунта',
    116: (userName) => 'По вашему запросу "' + userName + '" ничего не найдено',
    117: 'Редактировать все',
    118: 'Редактировать обложку',
    119: 'Редактировать видео',
    120: 'Выбранный элемент еще не доступен',
    121: 'Текст',
    122: 'Квадрат',
    123: 'Треугольник',
    124: 'Круг',
    125: 'Место',
    126: 'Хэштег',
    127: 'Упоминание',
    128: 'Вопросы',
    129: 'Опрос',
    130: 'Слайдер',
    131: 'Тест',
    132: 'GIF',
    133: 'Ссылка',
    134: 'Закрыть меню',
    135: 'Выбранный элемент будет удален',
    136: 'Добавить элемент',
    137: 'Поиск...',
    138: 'Напишите текст',
    139: 'Убрать подложку',
    140: 'Основной',
    141: 'Современный',
    142: 'Неоновый',
    143: 'Исторический',
    144: 'Поиск на GIPHY',
    145: 'Стикеры',
    146: 'GIFs',
    147: 'Текст',
    148: 'Смайлы',
    149: 'Назад',
    150: 'Нажмите для поиска геолокации',
    151: 'Ошибка ГЕО',
    152: 'Геопозиция',
    153: (value) => 'По вашему запросу "' + value + '" ничего не найдено.',
    154: 'Введите название хэштега',
    155: 'Нажмите для поиска хэштега',
    156: 'Ошибка Поиска',
    157: 'Поиск хэштега',
    158: (value) => 'По вашему запросу "' + value + '" ничего не найдено.',
    159: 'Задайте мне вопрос',
    160: 'Напишите что-нибудь...',
    161: 'УГАДАЙТЕ, ЧТО МНЕ НРАВИТСЯ...',
    162: 'Вариант A',
    163: 'Вариант B',
    164: 'УПОМИНАНИЕ',
    165: 'ГЕОДАННЫЕ',
    166: 'ДА',
    167: 'НЕТ',
    168: 'ХЭШТЕГ',
    169: 'Описание...',
    170: 'Вариант 1',
    171: 'Вариант 2',
    172: 'Элемент удален',
    173: 'Заголовок...',
    174: 'Вариант C',
    175: 'Вариант D',
    176: 'Правильный вариант',
    177: 'черный',
    178: 'фиолетово-синий',
    179: 'зелено-голубой',
    180: 'оранжево-желтый',
    181: 'розовый',
    182: 'фиолетовый',
    183: 'красный',
    184: 'оранжевый',
    185: 'желтый',
    186: 'зеленый',
    187: 'синий',
    188: 'Стандартный',
    189: 'Радуга',
    190: 'Прозрачный',
    191: 'В социальную сеть Twitter можно добавлять или одно видео или изображения до 4 штук',
    192: 'Подождите пока все файлы обработаются',
    193: 'Файл слишком большой для мобильного устройства, он отправлен на сервер для перекодироваания',
    194: 'Упс...',
    195: 'Данный формат видео не поддерживается вашей средой, видео отправлено на сервер для перекодировки.',
    196: 'Видео файл поврежден или ваш браузер не поддерживает данный формат видео',
    197: 'Разная геолокация',
    198: 'Выбрать для каждого аккаунта индивидуальную геолокацию',
    199: 'Создание Reels',
    200: 'Редактирование Reels',
    201: 'Выберите аккаунты для создания Reels',
    202: 'Также делиться в ленте',
    203: 'Reels будет опубликован также и в ленту',
    204: 'IGTV будет опубликован также и в ленту',
    205: 'Times New Roman',
    206: 'Текст',
    207: 'Также делиться в истории',
    208: 'Пост будет опубликован и в историю',
    209: 'IGTV будет опубликован и в историю',
    210: 'Reels будет опубликован и в историю',
    211: 'Еще',
    212: 'Удалить звук из видео файла',
    213: 'Из видео файла будет удалена звуковая дорожка',
    214: 'ССЫЛКА',
    215: 'Синий',
    216: 'Ссылка, пример: https://likepro.ru',
    217: 'Альтернативное название',
    218: 'В социальную сеть Instagram через Facebook можно добавлять или одно видео или одно изображения галлереи не доступны',

    219: 'Ошибка авторизации. Попробуйте перезапустить приложение',
    220: 'Ошибка проверки id и key',
    221: 'Ошибка проверки key, скорее всего пост вы начали создавать очень давно и файлы уже утеряны.',
    222: 'Ошибка в передачи данных, повторите попытку',
    223: 'Не удалось скачать файл по ссылке, скорее всего его нет',
    224: 'Недопустимый формат файла или файл передался с ошибкой. Повторите попытку',
    225: 'Видео слишком короткое, меньше 2.8 секунд, замените это видео другим.',
    226: 'Видео слишком длинное, более 30 минут, замените это видео другим.',
    227: 'Пожалуйста, перекодируйте или замените это видео, сервис не сможет его обработать. Вероятно файл поврежден.',
    228: 'Изображение слишком маленькое, замените его другим.',
    229: 'Пожалуйста, перекодируйте или замените это фото, сервис не сможет его обработать. Вероятно файл поврежден.',
    230: 'Подождите',
    231: 'Не все добавленные медиафайлы обработались.',


}

const en = {
    0: 'Select accounts',
    1: 'Select an account',
    2: 'Account',
    3: 'You have no accounts.',
    4: 'Account not selected',
    5: 'You can add one video or up to 10 images to the Facebook social network',
    6: <>Creating a Post to the <span className="nameColorFirm">Library Post</span></>,
    7: 'Post creation',
    8: ['Creating a post in ', 'account', 'account', 'accounts'],
    9: <>Creating a post to the <span className="nameColorFirm">Library</span></>,
    10: 'Post creation',
    11: 'Creating posts',
    12: 'Editing a post',
    13: 'Story creation',
    14: 'IGTV creation',
    15: 'Story editing',
    16: 'IGTV editing',
    17: 'Error',
    18: 'To add files, upload files for editing',
    19: 'To create the first comment, you need to include comments',
    20: '1 media file required',
    21: 'Minimum 1 media file required',
    22: 'You forgot to add a description',
    23: 'You forgot to enter the name of the post',
    24: 'Select accounts to create Story',
    25: 'Select accounts to create IGTV',
    26: 'Change the post time, this time has already passed',
    27: (name) => 'Account ' + name + ' will have no tariff active at the selected time. Extend your tariff or change the posting time',
    28: 'Exceeded limits in description or in first comment for symbols, hashtags or mentions',
    29: 'Post title limit exceeded',
    30: 'Not all files have been uploaded to the server yet',
    31: 'Post created',
    32: 'Post created successfully but failed to update posts, reload the page',
    33: 'Post successfully created',
    34: (code) => 'Unsuccessful attempt to edit a post: ' + code,
    35: 'Post successfully saved',
    36: 'Post successfully saved, but failed to update posts, reload the page',
    37: 'Failed to save post, please try again in 1 minute',
    38: <>Drop the file<br/>to add it to the post</>,
    39: 'Account: ',
    40: '(available on Instagram)',
    41: 'First comment',
    42: 'First comment under your post',
    43: 'Disable comments',
    44: 'Will not allow users to leave comments',
    45: 'Geolocation',
    46: 'Adding geolocation to a post',
    47: 'Different post descriptions',
    48: 'Post text form for each selected account',
    49: 'Different publication times',
    50: 'All accounts will have an individual publication time',
    51: 'Post title',
    52: 'No category',
    53: 'Post category',
    54: "You don't have templates yet",
    55: 'Templates',
    56: 'Description',
    57: 'Upload files for editing',
    58: 'Upload files for editing ...',
    59: (info) => 'Uploading files ... (' + info + ')',
    60: (info) => 'File handling ... (' + info + ')',
    61: 'Data retrieval error 2',
    62: 'Error loading video cover, please check your internet connection. (client side error)',
    63: 'Error loading video cover, please check your internet connection. (server side error)',
    64: 'Error 2 uploading files, please try again',
    65: 'Comment text',
    66: 'Upload photo or video',
    67: 'Upload video',
    68: 'Image not added',
    69: 'No video added',
    70: 'Video cannot be added because it is shorter than 3 seconds',
    71: 'To upload a video to IGTV, its length must be at least 1 minute',
    72: 'The video cannot be added, it is over 30 minutes long. Crop it or upload another video',
    73: 'An error occurred while testing the video. Upload another file',
    74: 'Loading error',
    75: 'Repeat',
    76: 'The aspect ratio has been changed for the first media file',
    77: 'Paste the URL',
    78: 'Link',
    79: 'Link for history',
    80: 'Download cover',
    81: 'Remove cover',
    82: 'Add cover',
    83: 'Select a JPG or PNG file. Minimum recommended resolution: 492 x 762 pixels.',
    84: 'Enter the name of the geolocation',
    85: 'Click to search for geolocation',
    86: 'Date',
    87: 'select date',
    88: 'Time',
    89: 'Delete after',
    90: 'Archive after',
    91: ['Minutes', 'Hours', 'Days', 'Weeks'],
    92: 'Search by accounts',
    93: 'Size',
    94: 'Ratio',
    95: 'Background color',
    96: 'To cut',
    97: 'Original',
    98: 'Vertical',
    99: 'Square',
    100: 'Horizontal',
    101: 'Fill in all',
    102: 'Fit to frame',
    103: 'Main',
    104: 'Cover',
    105: 'Trim',
    106: 'Editor',
    107: 'Flag users',
    108: 'Cancellation',
    109: 'Save',
    110: 'Enter your account name',
    111: 'Click to search for an account',
    112: (userName) => userName + ' account has already been added',
    113: 'Exceeded the number of tagged accounts per post. Maximum number of 20 marks',
    114: 'Search error',
    115: 'Account search',
    116: (userName) => 'Your request "' + userName + '" did not match any results',
    117: 'Edit all',
    118: 'Edit cover',
    119: 'Edit video',
    120: 'The selected item is not yet available',
    121: 'Text',
    122: 'Square',
    123: 'Triangle',
    124: 'Circle',
    125: 'Place',
    126: 'Hashtag',
    127: 'Mention',
    128: 'Questions',
    129: 'Interview',
    130: 'Slider',
    131: 'Test',
    132: 'GIF',
    133: 'Link',
    134: 'Close menu',
    135: 'The selected item will be deleted',
    136: 'Add item',
    137: 'Search...',
    138: 'Write text',
    139: 'Remove backing',
    140: 'Main',
    141: 'Modern',
    142: 'Neon',
    143: 'Historical',
    144: 'Search on GIPHY',
    145: 'Stickers',
    146: 'GIFs',
    147: 'Text',
    148: 'Smilies',
    149: 'Back to',
    150: 'Click to search for geolocation',
    151: 'GEO error',
    152: 'Geo position',
    153: (value) => 'Nothing was found for your query "' + value + '".',
    154: 'Enter hashtag name',
    155: 'Click to find hashtag',
    156: 'Search Error',
    157: 'Search hashtag',
    158: (value) => 'Nothing was found for your query "' + value + '".',
    159: 'Ask me a question',
    160: 'Write something...',
    161: 'GUESS WHAT I LIKE ...',
    162: 'Option A',
    163: 'Option B',
    164: 'REMINDER',
    165: 'GEODATA',
    166: 'YES',
    167: 'NO',
    168: 'HASHTAG',
    169: 'Description...',
    170: 'Options 1',
    171: 'Options 2',
    172: 'Item removed',
    173: 'Title ...',
    174: 'Option C',
    175: 'Option D',
    176: 'Correct option',
    177: 'black',
    178: 'violet-blue',
    179: 'green-blue',
    180: 'orange-yellow',
    181: 'pink',
    182: 'violet',
    183: 'red',
    184: 'orange',
    185: 'yellow',
    186: 'green',
    187: 'blue',
    188: 'Standard',
    189: 'Rainbow',
    190: 'Transparent',
    191: 'You can add either one video or up to 4 images to the Twitter social network',
    192: 'Wait while all files are processed',
    193: 'The file is too large for a mobile device, it has been sent to the server for transcoding',
    194: 'Oops ...',
    195: 'This video format is not supported by your environment, the video has been sent to the server for transcoding',
    196: 'The video file is damaged or your browser does not support this video format',
    197: 'Different geolocation',
    198: 'Select an individual geolocation for each account',
    199: 'Reels creation',
    200: 'Reels editing',
    201: 'Select accounts to create Reels',
    202: 'Also share in the feed',
    203: 'Reels will also be published in the feed',
    204: 'IGTV will also be published in the feed',
    205: 'Times New Roman',
    206: 'Text',
    207: 'Also share in the history',
    208: 'The publication will also be published in the history',
    209: 'IGTV will also be published in history',
    210: 'Reels will also be published in history',
    211: 'Still',
    212: 'Remove audio from a video file',
    213: 'The audio track will be deleted from the video file',
    214: 'LINK',
    215: 'Blue',
    216: 'Link, example: https://likepro.ru',
    217: 'Alt name',
    218: 'You can add either one video to the social network Instagram via Facebook or one image of the gallery is not available',

    219: 'Authorisation Error. Try restarting the app',
    220: 'id and key validation error',
    221: 'Key check error, most likely you started creating a post a long time ago and the files have already been lost.',
    222: 'Communication error, please try again',
    223: 'Failed to download the file from the link, most likely it does not exist',
    224: 'The file format is invalid or the file was transferred with an error. Try again',
    225: 'The video is too short, less than 2.8 seconds, please replace this video with another one.',
    226: 'The video is too long, more than 30 minutes, please replace this video with another one.',
    227: 'Please re-encode or replace this video, the service will not be able to process it. Probably the file is corrupted.',
    228: 'The image is too small, replace it with another one.',
    229: 'Please recode or replace this photo, the service will not be able to process it. Probably the file is corrupted.',
    230: 'Wait',
    231: 'Not all added media files were processed.',
}

const addPost = {
    ru: ru,
    en: en,
}

export default addPost;
