import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import APIpromocode from "../../api/promocode";
import ModalTarifPayElementPay from "./ModalTarifPayElementPay";

@inject('modalTarifStore', 'notificationStore', 'initialStore')
@observer
class ModalTarifPay extends React.Component{

    @observable openPromocode = false;
    @observable promocod = '';
    @observable promocodLoad = false;
    @observable promocodeColorType = 0; // 0 1 2
    @observable promocodMoney = 0;


    constructor (props) {
        super(props);

        this.close = this.close.bind(this);
    }

    render() {

        const modalTarifStore = this.props.modalTarifStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.rate;

        if (!modalTarifStore.open) {
            return null;
        }



        let urlBase= 'https://likepro.ru/api2/rate/pay?accounts=' + modalTarifStore.accounts + '&month=' + modalTarifStore.month + '&uID='+initialStore.idUser+'&pay=';
        let urlBaseNew= 'https://likepro.ru/api2/rate/tinkoff?accounts=' + modalTarifStore.accounts + '&month=' + modalTarifStore.month + '&uID='+initialStore.idUser;

        if (this.promocodMoney) {
            urlBase= 'https://likepro.ru/api2/rate/pay?accounts=' + modalTarifStore.accounts + '&month=' + modalTarifStore.month + '&promocode=' + this.promocod + '&uID='+initialStore.idUser+'&pay=';
            urlBaseNew= 'https://likepro.ru/api2/rate/tinkoff?accounts=' + modalTarifStore.accounts + '&month=' + modalTarifStore.month + '&promocode=' + this.promocod + '&uID='+initialStore.idUser;
        }


        let promocod = <div className="help-purchase-promocode" onClick={() => {this.openPromocode = true}}>{text[24]}</div>;

        if (this.openPromocode) {

            let style = {};

            if (this.promocodeColorType === 1) {
                style = {borderColor: '#ea4747'};
            }else if (this.promocodeColorType === 2) {
                style = {borderColor: '#43c748'};
            }

            promocod = <div className="help-purchase-promocode-val">
                <input style={style} autoFocus value={this.promocod} placeholder={text[25]} className={this.promocodLoad ? "form-control btn-primary-animate-content":"form-control"} onChange={this.promoAction}/>
            </div>;
        }

        let content = null;
        if (modalTarifStore.money > 0) {
            content = <>
                <span className="title-box center">{text[26]}</span>

                <div className="tariff-purchase">
                    {this.promocodMoney ?
                        <span className="sum-purchase"><span className="sum-purchase-none">{modalTarifStore.money} {text[27]}</span> {this.promocodMoney} {text[27]}</span>
                    :
                        <span className="sum-purchase">{modalTarifStore.money} {text[27]}</span>
                    }
                    <div className="tariff-purchase__way">

                        <ModalTarifPayElementPay
                            url={urlBaseNew}
                            classElement={'bank'}
                            clickFunction={this.close}
                            name={text[28]}
                        />

                        <ModalTarifPayElementPay
                            url={urlBase + 'sberbank'}
                            classElement={'sberbank'}
                            clickFunction={this.close}
                            name={text[29]}
                        />

                        <ModalTarifPayElementPay
                            url={urlBase + 'yandex'}
                            classElement={'yandex'}
                            clickFunction={this.close}
                            name={text[30]}
                        />

                        <ModalTarifPayElementPay
                            url={urlBase + 'qiwi'}
                            classElement={'qiwi'}
                            clickFunction={this.close}
                            name={text[31]}
                        />

                        {/*<ModalTarifPayElementPay
                            url={urlBase + 'webmoney'}
                            classElement={'webmoney'}
                            clickFunction={this.close}
                            name={text[32]}
                        />*/}

                    </div>

                    {promocod}

                    <p className="help-purchase">
                        {text[33][0]} {initialStore.platform === 'web' ? text[33][4]:null}
                        {initialStore.platform === 'web' ? <span className="info-pay-yur" onClick={() => {window.Chatra('openChat', true)}}>{text[33][1]}</span>:null}
                        {initialStore.platform === 'web' ? text[33][2] : null}
                        <a href="mailto:support@likepro.ru">{text[33][3]}</a>.</p>
                </div>

                <div className="more-btn btns-box">
                    <button onClick={this.close} type="button" className="btn border btn-primary btn-block">{text[34]}</button>
                </div>
            </>;
        } else {
            content = <>
                <span className="title-box center">{text[35]}</span>

                <div className="tariff-purchase">
                    <span className="sum-purchase">{text[36]}</span>
                    <div className="tariff-purchase__way">

                        <ModalTarifPayElementPay
                            url={urlBase + 'card'}
                            classElement={'none_pay'}
                            clickFunction={() => {}}
                            name={text[37](modalTarifStore.accounts)}
                        />

                    </div>
                    <p className="help-purchase">{text[38]}</p>
                </div>

                <div className="more-btn btns-box">
                    <button onClick={this.close} type="button" className="btn border btn-primary btn-block">{text[34]}</button>
                </div>
            </>;
        }



        let render = <>
                <div className="info-wrap">
                    <div className="info-scroll">
                        <div className="info-box-wrap">
                            <div className="info-box chief width-3">
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-overlay"></div>
            </>;

        return render;
    }

    promoAction = (event) => {
        const modalTarifStore = this.props.modalTarifStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.rate;
        let value = event.target.value;
        this.promocod = value;

        this.promocodLoad = true;
        this.promocodeColorType = 0;
        this.promocodMoney = 0;

        APIpromocode.getStatus(value, modalTarifStore.money).then((data) => {
            if (data.status) {
                this.promocodeColorType = 2;
                this.promocodMoney = data.money;
                notificationStore.add(text[39]);
            } else {
                this.promocodeColorType = 1;

                if (data.info) {
                    notificationStore.add('', data.info, 10);
                }
            }
        }).catch(() => {
            this.promocodeColorType = 1;
        }).finally(() => {
            this.promocodLoad = false;
        });

    }

    close () {
        const modalTarifStore = this.props.modalTarifStore;
        modalTarifStore.open = false;

        this.openPromocode = false;
        this.promocod = '';
        this.promocodLoad = false;
        this.promocodeColorType = 0;
        this.promocodMoney = 0;
    }

}

export default ModalTarifPay;
