import APIposts from "../../api/posts";

import mainStore from "../../stor/mainStore";

export function editLoad(id, code, beckFunction = false) {

        APIposts.edit(id, code).then((data) => {

            if (!data.status) {
                mainStore.postStore.open = false;

                if (data.info) {
                    mainStore.notificationStore.add('Ошибка редактирования', data.info, 15);
                }
                return;
            }

            mainStore.postStore.open = false;

            //мини редактирование, только время удаления/архивирования
            if (!data.fullRed) {
                const redPostMiniStore = mainStore.redPostMiniStore;

                if (data.time_delite) {
                    redPostMiniStore.status = 1;
                    redPostMiniStore.int = data.time_delite_int;
                    redPostMiniStore.tip = data.time_delite_tip;
                } else {
                    redPostMiniStore.status = 2;
                    redPostMiniStore.int = data.time_arhive_int;
                    redPostMiniStore.tip = data.time_arhive_tip;
                }


                redPostMiniStore.open = true;

                redPostMiniStore.id = id;
                redPostMiniStore.code = code;

                if (beckFunction) beckFunction();
                return;
            }

            const mediaStore = mainStore.mediaStore;

            //меняем тип на редактор
            if (data.story) {
                mediaStore.actionEditorType('editStory');
            } else if (data.igtv) {
                mediaStore.actionEditorType('editIgtv');
            } else if (data.reels) {
                mediaStore.actionEditorType('editReels');
            } else {
                mediaStore.actionEditorType('edit');
            }

            mediaStore.actionIdAndCode(id, code);

            mediaStore.actionTextSavePost(data.text);

            mediaStore.name = data.name;

            if (data.account) {

                let delitePost = false;
                if (data.time_delite) {
                    delitePost = {
                        status: 1,
                        int: data.time_delite_int,
                        tip: data.time_delite_tip,
                    };
                } else if (data.time_arhive) {
                    delitePost = {
                        status: 2,
                        int: data.time_arhive_int,
                        tip: data.time_arhive_tip,
                    };
                }
                mediaStore.actionAccount(data.account, data.time, data.text, delitePost);
            }


            //комментарий
            if (data.comment !== false && !data.commentOff) {
                mediaStore.actionComment(true);
                mediaStore.actionCommentText(data.comment);
            }

            //Гео точка
            if (data.geo) {
                mediaStore.geoObject = {
                    search: data.geo_name,
                    z: 1,
                    load: false,
                    array: [
                        {lat: data.geo_lat, lng: data.geo_lng, name: data.geo_name},
                    ],
                    id: 0,
                };

                mediaStore.geo = true;
            }

            //отключение комментария
            if (data.commentOff) {
                mediaStore.actionCommentOff(data.commentOff);
            }

            mediaStore.urlStory = data.urlStory;

            mediaStore.PrivievPost = data.previewPost;
            mediaStore.ReStory = data.previewStory;



            mediaStore.actionMediaLoading(data.media);

            //категория
            mediaStore.category = data.category;

            mediaStore.open = true;
            //console.log(data);
            if (beckFunction) beckFunction();

        }).catch((error) => {
            //console.log(error);
            if (beckFunction) beckFunction();
            alert('Ошибка получения данных2');
        });
}
