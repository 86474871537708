import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ButtonLoad from "../../elements/buttonLoad";
import Password from "../../elements/password";
import Button from "../../elements/button";
import Input from "../../elements/input";

export default
@inject('initialStore')
@observer
class TemplateModalAddAccountInstagram extends React.Component {

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addAccount;




        let render = <>
            <span className="title-box center">{text[17]}</span>
            <div className="login">

                <p className="login-help">
                    {text[14]}
                </p>

                <div className="form-box">
                    <div className="form-group">
                        <span className="input-label">{text[15]}</span>
                        <Input load={this.props.load} onChange={this.props.actionLogin}>{this.props.login}</Input>
                    </div>
                    <div className="form-group">
                        <span className="input-label">{text[16]}</span>
                        <Password load={this.props.load} onChange={this.props.actionPassword}>{this.props.password}</Password>
                    </div>

                    <div className="login-proxies">
                        <span className="login-label label-proxies" onClick={this.props.actionOpenProxy}>{!this.props.openProxy ? text[18]:text[19]}</span>
                        {this.props.openProxy ?
                            <div className="cnt-proxies">
                                <div className="form-group">
                                    <span className="input-label">{text[20]}</span>
                                    <Input load={this.props.load} onChange={this.props.actionProxyIP}>{this.props.proxyIP}</Input>
                                </div>
                                <div className="form-group">
                                    <span className="input-label">{text[21]}</span>
                                    <Input load={this.props.load} onChange={this.props.actionProxyPort}>{this.props.proxyPort}</Input>
                                </div>
                                <div className="form-group">
                                    <span className="input-label">{text[15]}</span>
                                    <Input load={this.props.load} onChange={this.props.actionProxyLogin}>{this.props.proxyLogin}</Input>
                                </div>
                                <div className="form-group">
                                    <span className="input-label">{text[16]}</span>
                                    <Input load={this.props.load} onChange={this.props.actionProxyPassword}>{this.props.proxyPassword}</Input>
                                </div>
                            </div>
                        :null}
                    </div>
                </div>

            </div>

            <div className="more-btn btns-box">
                <ButtonLoad color action={this.props.actionOK}>add</ButtonLoad>
                <Button load={this.props.load} action={this.props.close}>cancellation</Button>
            </div>
        </>;

        return render;
    }
}