import React from 'react';
import {inject, observer} from "mobx-react";
import {Link, Route, Switch} from "react-router-dom";

import Timeonline from "./components/timepnline";
import Time from "../../model/time";
import APIsettings from "../../api/settings";
import History from "../account/component/History";

import Main from "./main";
import Security from "./security";
import Notifications from "./notifications";
import Groups from "./groups";
import Referrals from "./referrals";



@inject('initialStore')
@inject('menuStore')
@observer
class Settings extends React.Component {

    render() {

        return <Switch>
                <Route path="/settings/security" component={Security}></Route>
                <Route path="/settings/notifications/:type" component={Notifications}></Route>
                <Route path="/settings/groups" component={Groups}></Route>
                <Route path="/settings/referrals" component={Referrals}></Route>
                <Route path="/settings" component={Main}></Route>
            </Switch>;
    }

    componentDidMount() {
        this.props.menuStore.updateActiveUrlMenu('/settings');
    }

}

export default Settings;