import React from "react";

import imgPosts from "../media/svg/menu/menu-create-post-posts.svg";
import imgEdit from "../media/svg/icons-edit-3.svg";

const ru = {
    accountEmployeeEdit: <><img src={imgEdit} className="dubl-button-img"/>Редактировать</>,
    accountEmployeeAdd: 'Добавить сотрудника',
    accountEmployeeAddAccount: 'Добавить сотрудника',
    accountEmployeeRedAccount: 'Сохранить изменения',
    cancellation: 'Отмена',
    saveChanges: 'Сохранить изменения',
    addProxy: 'Добавить прокси',
    addGroup: 'Добавить группу',
    downloadMorePublications: 'Загрузить еще',
    saveNewPassword: 'Сохранить новый пароль',
    save: 'Сохранить',
    download: 'Скачать',
    createPatterns: 'Создать шаблон',
    collapse: 'Свернуть',
    createPost: 'Запланировать пост',
    createPostLibrary: 'Сохранить в библиотеку',
    createPosts: 'Запланировать посты',
    createStorys: 'Запланировать истории',
    savePost: 'Сохранить пост',
    createStory: 'Запланировать историю',
    createIGTV: 'Запланировать IGTV',
    createReels: 'Запланировать Reels',
    saveStory: 'Сохранить историю',
    saveIGTV: 'Сохранить IGTV',
    saveReels: 'Сохранить Reels',
    close: 'Закрыть',
    registration: 'Зарегистрироваться',
    enter: 'Войти',
    rePassword: 'Сбросить пароль',
    rePasswordFinish: 'Сменить пароль',

    //редактор
    sizeImage: 'Размер изображения',
    sizeVideo: 'Размер видео',
    aspect: 'Соотношение',
    backgroundColor: 'Цвет фона',
    crop: 'Вырезать',
    confirm: 'Подтвердить',
    add: 'Добавить',
    send: 'Отправить',
    reAutorisation: 'Сбросить авторизацию',
    delAccount: 'Удалить аккаунт',
    vk: 'ВКонтакте',
    ok: 'Одноклассники',
    fb: 'Facebook',
    twitter: 'Twitter',
    dubl: <><img src={imgPosts} className="dubl-button-img"/>Дублировать пост</>,
    divButtonDel: <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18">
        <path
            fill="#ffffff"
            fill-rule="nonzero"
            d="M5.625 0l-.938.9H0v1.8h.938v13.5c0 .984.85 1.8 1.875 1.8h9.374c1.025 0 1.876-.816 1.876-1.8V2.7H15V.9H10.312L9.376 0h-3.75zM2.812 2.7h9.376v13.5H2.812V2.7z"></path>
    </svg>,
    fb_inst: 'Instagram Business',
}

const en = {
    accountEmployeeEdit: <><img src={imgEdit} className="dubl-button-img"/>Edit</>,
    accountEmployeeAdd: 'Add employee',
    accountEmployeeAddAccount: 'Add employee',
    accountEmployeeRedAccount: 'Save changes',
    cancellation: 'Cancellation',
    saveChanges: 'Save changes',
    addProxy: 'Add proxy',
    addGroup: 'Add group',
    downloadMorePublications: 'Load more',
    saveNewPassword: 'Save new password',
    save: 'Save',
    download: 'Download',
    createPatterns: 'Create template',
    collapse: 'Collapse',
    createPost: 'Create post',
    createPosts: 'Create posts',
    createPostLibrary: 'Save to library',
    createStorys: 'Create stories',
    savePost: 'Save post',
    createStory: 'Create a story',
    createIGTV: 'Create IGTV',
    createReels: 'Create Reels',
    saveStory: 'Save history',
    saveIGTV: 'Save IGTV',
    saveReels: 'Save Reels',
    close: 'Close',
    registration: 'Register now',
    enter: 'Login',
    rePassword: 'Reset the password',
    rePasswordFinish: 'Change password',

    //редактор
    sizeImage: 'Image size',
    sizeVideo: 'Video size',
    aspect: 'Ratio',
    backgroundColor: 'Background color',
    crop: 'Crop',
    confirm: 'Confirm',
    add: 'Add',
    send: 'Send message',
    reAutorisation: 'Reset authorization',
    delAccount: 'Delete account',
    vk: 'VKontakte',
    ok: 'Odnoklassniki',
    fb: 'Facebook',
    twitter: 'Twitter',
    dubl: <><img src={imgPosts} className="dubl-button-img"/>Duplicate post</>,
    divButtonDel: <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18">
        <path
            fill="#ffffff"
            fill-rule="nonzero"
            d="M5.625 0l-.938.9H0v1.8h.938v13.5c0 .984.85 1.8 1.875 1.8h9.374c1.025 0 1.876-.816 1.876-1.8V2.7H15V.9H10.312L9.376 0h-3.75zM2.812 2.7h9.376v13.5H2.812V2.7z"></path>
    </svg>,
    fb_inst: 'Instagram Business',
}

const button = {
    ru: ru,
    en: en,
}

export default button;
