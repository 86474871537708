import React from 'react';
import {inject, observer} from "mobx-react";
import ReactDOM from "react-dom";

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Button from "../../templates/elements/button";
import {Spring, config} from "react-spring/renderprops";
import MomentLocaleUtils from "react-day-picker/moment";


@inject('modalDateMobile', 'initialStore')
@observer
class ModalDateMobile extends React.Component{

    constructor (props) {
        super(props);

        this.close = this.close.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.window = React.createRef();

        this.open = false;
    }

    render() {
        const modalDateMobile = this.props.modalDateMobile;
        const initialStore = this.props.initialStore;

        if (!modalDateMobile.open) {

            if (this.open) {
                this.open=false;
                this.updateStatusCloseFon();
            } else {
                return null;
            }
        } else {
            if (!this.open) {
                this.open=true;
                this.updateStatusCloseFon();
            }
        }


        let selectedDays = modalDateMobile.selectedDays;

        const calendar = <DayPicker
            onDayClick={this.onDayClick}
            selectedDays={selectedDays}
            month={selectedDays}
            localeUtils={MomentLocaleUtils}
            locale={initialStore.language}
            disabledDays={modalDateMobile.disabledDays}
            showOutsideDays={true} />;


        let render = <>
            <Spring
                from={{ n: 0 , b: -150}}
                to={{ n: !this.open ? 0:1, b: !this.open ? -150:2}}
                config={config.stiff}>
                {props =>  {

                    if (!props.n) {
                        return null;
                    }

                    return <>
                        <div className="info-wrap">
                            <div className="date-mobile" style={{marginBottom: props.b+'%'}} ref={this.window}>
                                <div className="date-mobile-date">
                                    {calendar}
                                </div>
                                <div className="date-mobile-buttons">
                                    <Button action={this.close} color={true}>close</Button>
                                </div>
                            </div>
                        </div>

                        <div className="info-overlay" style={{opacity: props.n}}></div>
                    </>
                }}
            </Spring>

            </>;

        return render;
    }

    onDayClick = (day) => {
        const modalDateMobile = this.props.modalDateMobile;
        modalDateMobile.selectedDays = day;
        modalDateMobile.onDayClick(day);

        this.close();
    }

    updateStatusCloseFon = () => {
        if (this.open) {
            document.addEventListener('touchend', this.handleClickOutside, false);
        } else {
            document.removeEventListener('touchend', this.handleClickOutside, false);
        }
    }


    close () {
        const modalDateMobile = this.props.modalDateMobile;
        modalDateMobile.open = false;
    }


    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                const modalDateMobile = this.props.modalDateMobile;
                if (modalDateMobile.open) {
                    this.close();
                }
            }
        } catch(error) {
            return null;
        }
    }
}

export default ModalDateMobile;