import qs from "qs";
import React from "react";

import instance from "./base";


const APIaccountsAdd = {

    getVkUrl () {
        return instance.post('/accountsadd/vk_url', qs.stringify({

        })).then(data => {
            return data;
        });
    },
    restart (id) {
        return instance.post('/accounts/restart', qs.stringify({
            id: id,
        })).then(data => {
            return data;
        });
    },
    delite (id) {
        return instance.post('/accounts/delite', qs.stringify({
            id: id,
        })).then(data => {
            return data;
        });
    },
}

export default APIaccountsAdd;