import React from 'react';
import {inject, observer} from "mobx-react";

import classes from './PostBox.module.css';
import loafFile from '../../model/loadFile';
import loaderGif from './loader.gif';
import Time from "../../model/time";
import {observable} from "mobx";
import ReactDOM from "react-dom";


@inject('accountsStore', 'initialStore')
@observer
class VisualPosts extends React.Component {

    @observable typeOpen = false;

    constructor(props) {
        super(props);

        this.window = React.createRef();

        this.onFocus = this.onFocus.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.openWin = false;
    }

    render() {
        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;
        const active = accountsStore.active;
        const type = accountsStore.list[active].postStore.search.type;
        const text = initialStore.text.account;

        console.log(this.props.options);


        let typeLanguage = this.props.options[this.props.id].value;

        if (!this.typeOpen) {
            if (this.openWin) {
                this.openWin=false;
                this.updateStatusCloseFon();
            }
        } else {
            if (!this.openWin) {
                this.openWin=true;
                this.updateStatusCloseFon();
            }
        }

        let contentType = null;

        let listElement = [];
        for (const optionID in this.props.options) {

            listElement.push(<span className="event-item" onClick={() => {this.newType(optionID)}}>{this.props.options[optionID].value}</span>)
        }

        if (this.typeOpen) {
            contentType = <div className="event-menu g-center v-bottom show">
                {listElement}
                </div>;
        }

        let content = <div className="filter-sort filter-sort-non-left" ref={this.window}>
                {text[148]}&nbsp;
                <div className="filter-sort__nav">
                    <span className="label-nav" onClick={this.onFocus}>{typeLanguage}</span>
                    {contentType}
                </div>
            </div>;

        return content;
    }

    newType = (optionID) => {
        this.props.action(this.props.options[optionID].key)
        this.typeOpen = false;
    }

    onFocus () {
        this.typeOpen = !this.typeOpen;
    }

    updateStatusCloseFon = () => {
        if (this.openWin) {
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов

                if (this.typeOpen) {
                    this.typeOpen = false;
                }
            }
        } catch(error) {
            return null;
        }
    }
}
export default VisualPosts;