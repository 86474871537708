import React from 'react';

import {inject, observer} from "mobx-react";
import {observable} from "mobx";

@inject('editorStore', 'notificationStore')
@observer
class MediaRedBAlign extends React.Component {

    @observable type = 0;

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;
    }

    render() {
        const TypeAlign = this.fabric.elementGetAligenText();

        let icon = 'btn red-button-align-right';

        if (TypeAlign === 'center') {
            icon = 'btn red-button-align-center';
        } else if (TypeAlign === 'left') {
            icon = 'btn red-button-align-left';
        }

        let demo = this.type;

        return (
            <>
                <div className="item-type">
                    <button type="button" className={icon} onClick={this.align}></button>
                </div>
            </>
        );
    }

    align = () => {
        this.fabric.elementSetAligenText();
        this.type++;
    }
}

export default MediaRedBAlign;