import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import Input from "../../../templates/elements/input";
import Select from "../../../templates/elements/select";

@inject('initialStore', 'libraryStore', 'accountsStore', 'mediaStore')
@observer
class Category extends React.Component {

    @observable emojeOpen = false;

    constructor (props) {
        super(props);
    }

    render() {
        const mediaStore = this.props.mediaStore;
        const libraryStore = this.props.libraryStore;

        const category = mediaStore.category;

        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        if (mediaStore.accounts.length > 0) {
            return  null;
        }

        if (mediaStore.type !== 'post' && mediaStore.type !== 'edit') {
            return  null;
        }

        let options = [];

        for (const catElement of libraryStore.categories) {

            let name = catElement.name;
            if (catElement.id === '0') {
                name = text[52];
            }
            options.push({
                key: catElement.id,
                value: name,
            });
        }

        let render = <>
            <div className="input-label flex">
                <span className="title">{text[53]}</span>
                <div className="add-publication__template">
                </div>
            </div>
            <Select options={options} action={this.action} nonTopValueElement>{category}</Select>
        </>;

        return render;
    }

    action = (id) => {
        this.props.mediaStore.category = id;
    }
}
export default Category;