import axios from "axios";
import {autorisation} from "../actions/app/initial";


const instance = axios.create({
    baseURL: 'https://likepro.ru/api2/',
    withCredentials: true,
    //responseType: 'json'
});

instance.interceptors.response.use(function (response) {




    if (typeof response.data === 'object') {

        //проверка на авторизацию
        if (typeof response.data.autorisation != "undefined") {
            if (response.data.autorisation === false) {
                autorisation();
            }
        }

        return response.data;
    } else {
        return Promise.reject(1);
    }


}, function (error) {

    if (!error.response) {
        // network error

        //this.errorStatus = 'Error: Network Error';
        console.log(`Ошибка соединения с сервером: Проверьте соединение с интернетом`);

        //alert(`Ошибка соединения с сервером: Проверьте соединение с интернетом`);
        //alert('Ошибка соединения с сервером: ' + error.response);
        return Promise.reject(0);
    } else {

        console.log(error.response.data.message);

        //alert(`Ошибка соединения с сервером: ${error.response.data.message}`);
        return Promise.reject(1);
    }

    //return Promise.reject(error);
    return false;
});

export default instance;

