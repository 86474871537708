import React from 'react';
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import classes from "../../../component/header/HeaderAccount.module.css";

export default
@inject('initialStore')
@inject('accountsStore')
@inject('menuStore')
@observer
class AccountTimeTableFilterElement extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <div className="item-check">
                <Link to={this.props.url} className={this.props.active ? 'label-check label-check-activ':'label-check'}>
                    <span>{this.props.name}</span>
                </Link>
            </div>;
        } else {
            return <div className="item-check">
                <Link to={this.props.url} className={this.props.active ? 'label-check label-check-activ':'label-check'}>
                    <span>{this.props.name}</span>
                </Link>
            </div>;
        }

    }
}

