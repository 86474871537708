import APIfile from "../api/file";
import file, {getIconImage} from "./file";
import mediaHelpers from "./mediaHelpers";
import editorMediaClass from "./eritorMedia";

import mainStore from "../stor/mainStore";
import Time from "./time";

export default async function loadFileObrabotchikImageIgtv(files) {

    const mediaStore = mainStore.mediaStore;
    const notificationStore = mainStore.notificationStore;

    if (mediaStore.obrabotkaMediaFiles) {
        notificationStore.add('Ошибка', 'Подождите пока все файлы обработаются');
        return  false;
    }

    if (!files.length) {
        return false;
    }

    //получаем ключ для загрузки медиа если его нет
    if (mediaStore.type !== 'multyPost') {
        if (mediaStore.idLoad === false) {

            const getKey = await APIfile.getKey();

            if (getKey.status) {
                mediaStore.actionIdLoad(getKey.id);
            } else {
                alert('Error setIdLoad');
                return false;
            }
        }
    }


    try {

        let filesNew = await file.FileMaxSizeAndTypeAndMaxFiles(files,1);


        let fileAndUrl = false;
        for (const element of filesNew) {

            //проверка на допустимость добавления такого файла для данных соц сетей
            const url = await file.createURL(element);

            //если фото, создаем блуб файл, для избежания не корректных цветов в фото
            if (element.type === 'image') {

                const blob = await file.createBlobPre(url);

                const icon = await getIconImage(url);

                mediaStore.IgtvImage = {file: blob, url:url, icon: icon};

            }
        }


    } catch (e) {

    }

}