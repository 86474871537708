import {action, observable} from "mobx";

class helpStore {

    @observable idCategory = false;
    @observable idTeam = false;

    @observable params = [
        {
            name: 'Начало использования сервиса',
            element: [
                {
                    name: 'Что умеет сервис',
                    text: "Сервис LikePro.ru предназначен для работы в социальных сетях.\n" +
                        "Главная цель - это автоматическая публикация во все популярные социальные сети.\n" +
                        "С помощью сервиса можно публиковать как посты так и истории с IGTV, редактировать медиа файлы, публиковать сразу в несколько социальных сетей, вести статистику и работать в директ.\n" +
                        "Мы сделали обширную и подробную документацию сервиса, но вы в любое время можете написать нам в чат и получить быстрый ответ на ваш вопрос.",
                },
                {
                    name: 'Добавление аккаунта',
                    text: "Для добавления аккаунта, выберите в левом меню раздел “Добавить аккаунт”, в открывшемся окне выберите нужную вам социальную сети и нажмите на нее.\n" +
                        "\n" +
                        "Instagram - в открывшемся окне укажите название аккаунта и пароль, если есть личный прокси то его добавить можно по клику на кнопку “Добавить прокси”. После заполнения всех данных нажмите на кнопку “Добавить” и ожидайте, добавление может занять до 5 минут. Инстаграм может запросить подтверждение по номеру телефона или электронной почте.\n" +
                        "\n" +
                        "Vkontakte - вас перенаправит на сайт vk.com, где наше приложение запросит у вас необходимые разрешения для корректной работы с вашими аккаунтами. После их получения вам потребуется выбрать аккаунты, которые вы хотите добавить на сервис.\n" +
                        "Если вы хотите добавить еще аккаунты от другого пользователя, для начала зайдите на сайт vk.com и авторизуйтесь под ним, после чего повторите попытку добавления аккаунта Vkontakte.\n" +
                        "\n" +
                        "Facebook - вас перенаправит на сайт facebook.com, где наше приложение запросит у вас необходимые разрешения для корректной работы с вашими аккаунтами. После их получения вам потребуется выбрать аккаунты, которые вы хотите добавить на сервис.\n" +
                        "Если вы хотите добавить еще аккаунты от другого пользователя, для начала зайдите на сайт facebook.com и авторизуйтесь под ним, после чего повторите попытку добавления аккаунта Facebook.\n" +
                        "\n" +
                        "Одноклассники - вас перенаправит на сайт ok.ru, где наше приложение запросит у вас необходимые разрешения для корректной работы с вашими аккаунтами. После их получения вам потребуется выбрать аккаунты, которые вы хотите добавить на сервис.\n" +
                        "Если вы хотите добавить еще аккаунты от другого пользователя, для начала зайдите на сайт ok.ru и авторизуйтесь под ним, после чего повторите попытку добавления аккаунта Одноклассники.\n" +
                        "\n" +
                        "Telegram - в открывшемся окне укажите токен и логин/ID. Из Телеграмм можно добавить канал или группу. Для подробной информации как добавить аккаунт Telegam нажмите на кнопку в окне “Как добавить?”, там рассказываются подробно все этапы.\n",
                },
                {
                    name: 'Создание поста',
                    text: "Для создания поста на сервисе в верхнем левом углу есть кнопка “Создать пост”, она имеет скрытые возможности, чтобы их открыть на этой кнопке справа есть стрелка вниз, нажмите на нее и раскроется меню со всеми возможностями о которых вы можете узнать из нижних разделов, а пока нажмем обратно на стрелку или кликнем за пределами меню чтобы оно закрылось.\n" +
                        "Для создания простого поста нам это меню не понадобится и мы просто нажмем на эту кнопку и откроется редактор.\n" +
                        "\n" +
                        "В редакторе мы первым делом выберем слева нужные аккаунты, можно выбрать один или сразу несколько, если не одни аккаунт не выбран то пост будет создан в библиотеку постов. \n" +
                        "Также для удобства хочу заметить, что если у вас в меню был выбран какой то аккаунт, то и при открытии редактора он также по стандарту будет выбран, а если вы были перед этим например в разделе настройки то не один аккаунт не будет изначально выбран.\n" +
                        "\n" +
                        "Теперь добавим описание для поста, это можно сделать в самом первом поле с название “Текст поста”, выше над ним вы можете заметить кнопку для добавления шаблонов, о них читайте ниже в другом разделе. В правом нижнем углу есть кнопка для добавления смайлов, а под самим полем есть лимиты по тексту которые нельзя нарушать.\n" +
                        "Если вам потребуется разное описание для каждого поста, для этого есть снизу кнопка по нажатию на которую для каждого аккаунта будет свой блок с текстом поста.\n" +
                        "\n" +
                        "Теперь добавим фото и видео. Нажмите на кнопку “Загрузить фото или видео” и откроется окно для выбора медиа файлов на вашем устройстве. Вы можете за раз выбрать сразу несколько медиа файлов и загрузить их или выбирать каждый по очереди. После загрузки и обработки медиа файлов при наведении на них будут появлятся 3 кнопки, для редактирования, удаления и перемещения файлов.\n" +
                        "\n" +
                        "Осталось только выбрать время публикации. Прокрутим страницу в самый низ и увидем поле для выбора даты, и времени, укажем здесь время когда мы хотим чтобы данный пост опубликовался.\n" +
                        "Сбоку есть кнопки для удаления/архивирования поста, благодаря им вы можете задать через какое время пост сам удалится из аккаунта после публикации. Если это вам не надо, то просто не нажимайте на данные кнопки или отмените действие повторным нажатием.\n" +
                        "Если вам требуется разное время публикации на аккаунтах, выше есть пункт “Разное время публикации”, выберите его и для каждого аккаунта появится свое личное время.\n" +
                        "\n" +
                        "Первый пост готов, можно нажать на кнопку “Создать пост”.\n" +
                        "После создания окно закроется и вы можете выбрать в левом меню нужный вам аккаунт и посмотреть на пост, если медиа нет и идут на фоне пунктирные линии значит медиафайлы еще в стадии обработки, время обработки зависит от количества медиафайлов и их размера.\n" +
                        "После обработки медиафайлов вы можете нажать на выбранный пост и посмотреть каким он получился.\n",
                },
            ],
        },
        {
            name: 'Посты',
            element: [
                {
                    name: 'Создание поста',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Создание поста в несколько аккаунтов',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Создание поста в Библиотеку',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Создание нескольких постов',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Создание поста по ссылке на публикацию из Instagram',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Создание поста по ссылке на пост из сервисе',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Создание поста на основе поста из Библиотеки',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Просмотр уже опубликованных постов',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Повторная публикация уже опубликованных постов',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Просмотр созданного поста',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Ссылка на созданный пост',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Редактирование поста',
                    text: 'Данная документация еще не готова.',
                },
            ],
        },
        {
            name: 'Истории',
            element: [
                {
                    name: 'Создание истории',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Создание истории в несколько аккаунтов',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Создание нескольких историй',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Добавление стикеров',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Добавление ссылки',
                    text: 'Данная документация еще не готова.',
                },
            ],
        },
        {
            name: 'IGTV',
            element: [
                {
                    name: 'Создание IGTV',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Создание IGTV в несколько аккаунтов',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Создание IGTV по ссылке на публикацию IGTV из Instagram',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Добавление обложки',
                    text: 'Данная документация еще не готова.',
                },

            ],
        },
        {
            name: 'Редактор медиа файлов',
            element: [
                {
                    name: 'Основное',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Изменение размера медиа',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Изменение соотношения сторон',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Вырезать',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Добавить на медиа: текст, фигуры, стикеры, изображения и gif',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Изменить обложку видео',
                    text: 'Данная документация еще не готова.',
                },
            ],
        },
        {
            name: 'Статистика',
            element: [
                {
                    name: 'Статистика по аккаунтам',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Статистика по опубликованным постам',
                    text: 'Данная документация еще не готова.',
                },
            ],
        },
        {
            name: 'Директ',
            element: [
                {
                    name: 'Как подключить',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Какие есть возможности использования',
                    text: 'Данная документация еще не готова.',
                },
            ],
        },
        {
            name: 'Шаблоны',
            element: [
                {
                    name: 'Что такое шаблоны',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Создание шаблона',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Редактирование и удаление шаблона',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Использование шаблона',
                    text: 'Данная документация еще не готова.',
                },
            ],
        },
        {
            name: 'Расписание',
            element: [
                {
                    name: 'Что такое расписание',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Создание расписания',
                    text: 'Данная документация еще не готова.',
                },
            ],
        },
        {
            name: 'Сотрудники',
            element: [
                {
                    name: 'Что такое сотрудники',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Добавление сотрудника',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Редактирование и удаление сотрудника',
                    text: 'Данная документация еще не готова.',
                },
            ],
        },
        {
            name: 'Прокси',
            element: [
                {
                    name: 'Что такое прокси',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Добавление прокси',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Редактирование и удаление прокси',
                    text: 'Данная документация еще не готова.',
                },
            ],
        },
        {
            name: 'Уведомления сервиса',
            element: [
                {
                    name: 'Что такое уведомления сервиса',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Добавление номера телефона и Telegram для уведомлений',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Настройка уведомлений',
                    text: 'Данная документация еще не готова.',
                },
            ],
        },
        {
            name: 'Группы аккаунтов и сортировка аккаунтов',
            element: [
                {
                    name: 'Добавление групп',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Редактирование и удаление групп',
                    text: 'Данная документация еще не готова.',
                },
                {
                        name: 'Перемещение и сортировка аккаунтов',
                    text: 'Данная документация еще не готова.',
                },
            ],
        },
        {
            name: 'Настройки',
            element: [
                {
                    name: 'Часовой пояс',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Номер телефона',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Язык интерфейса',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Сменить пароль',
                    text: 'Данная документация еще не готова.',
                },
            ],
        },
        {
            name: 'Проблемы в работе сервиса',
            element: [
                {
                    name: 'Ошибка публикации',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Пост долго публикуется',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Поддерживаемые разрешения медиа файлов',
                    text: 'Данная документация еще не готова.',
                },
                {
                    name: 'Ограничения и лимиты сервиса',
                    text: 'Данная документация еще не готова.',
                },
            ],
        },
    ];
}

export default helpStore;