import React from 'react';
import {inject, observer} from "mobx-react";
import directLoad from "../../../../media/gif/directLoad.gif";


export default
@inject('initialStore')
@observer
class AccountDirectMessageMedia extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {

            let boxMedia = '';
            let width = 300;
            let height = 300;
            if (this.props.width > this.props.height) {
                height = Math.round(width / (this.props.width/this.props.height));
            } else {
                width = Math.round(height * (this.props.width/this.props.height));
            }

            if (this.props.mediaType) {
                if (this.props.mediaType === 'image') {
                    boxMedia = <img src={this.props.media} width={width} height={height}/>;
                } else {
                    boxMedia = <video src={this.props.media} width={width} height={height}/>;
                }
            }

            return (
                <div className="chat-message__history">

                    <div className="wrap-history">
                        <div className="inner-history" style={{width: width, height: height}}>
                            {this.props.mediaType ? boxMedia:null}
                            {!this.props.time ?
                                <div className="chat-message__date" style={{backgroundColor: '#fff', height: 22}}><img src={directLoad} style={{width:12, height: 12}} /></div>
                                :
                                <time dateTime={this.props.time} className="chat-message__date">{this.props.time}</time>
                            }

                        </div>
                    </div>
                </div>
            );
        } else {
            let boxMedia = '';
            let width = 300;
            let height = 300;
            if (this.props.width > this.props.height) {
                height = Math.round(width / (this.props.width/this.props.height));
            } else {
                width = Math.round(height * (this.props.width/this.props.height));
            }

            if (this.props.mediaType) {
                if (this.props.mediaType === 'image') {
                    boxMedia = <img src={this.props.media} width={width} height={height}/>;
                } else {
                    boxMedia = <video src={this.props.media} width={width} height={height}/>;
                }
            }

            return (
                <div className="chat-message__history">

                    <div className="wrap-history">
                        <div className="inner-history" style={{width: width, height: height}}>
                            {this.props.mediaType ? boxMedia:null}
                            {!this.props.time ?
                                <div className="chat-message__date" style={{backgroundColor: '#fff', height: 22}}><img src={directLoad} style={{width:12, height: 12}} /></div>
                                :
                                <time dateTime={this.props.time} className="chat-message__date">{this.props.time}</time>
                            }

                        </div>
                    </div>
                </div>
            );
        }
    }

}