import React from 'react';
import {inject, observer} from "mobx-react";
import ButtonLoad from "../elements/buttonLoad";
import Input from "../elements/input";

export default
@inject('initialStore')
@observer
class DownloadpostIndex extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <>
            <div className="chief__typical h1">
                <h1>Скачать публикацию</h1>
            </div>
            <div className="settings">


                        <p className="settings__help">
                            Вставьте URL адрес поста и нажмите кнопку “Скачать”.
                        </p>

                        <div className="settings-download">
                            <div className="settings-download__add">
                                <span className="input-label">URL адрес поста</span>
                                <div className="input-group">
                                    <Input onChange={this.props.onChange}>{this.props.value}</Input>
                                    <ButtonLoad color action={this.props.action}>download</ButtonLoad>
                                </div>
                            </div>
                        </div>
                    </div>
                </>;
        } else {
            return <>
                <div className="chief__typical h1">
                    <h1>Скачать публикацию</h1>
                </div>
                <div className="settings">


                    <p className="settings__help">
                        Вставьте URL адрес поста и нажмите кнопку “Скачать”.
                    </p>

                    <div className="settings-download">
                        <div className="settings-download__add">
                            <span className="input-label">URL адрес поста</span>
                            <div className="input-group">
                                <Input onChange={this.props.onChange}>{this.props.value}</Input>
                                <ButtonLoad color action={this.props.action}>download</ButtonLoad>
                            </div>
                        </div>
                    </div>
                </div>
            </>;
        }

    }
}

