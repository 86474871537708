import React from 'react';
import {inject, observer} from "mobx-react";

import Input from "../elements/input";
import ReactDOM from "react-dom";
import iconEdit from "../../media/svg/icons-edit-2.svg";
import iconDel from "../../media/svg/icons-del.svg";
import iconDrop from "../../media/svg/media-draggable.svg";
import {observable} from "mobx";
import PostTemplateMediaIconsDraggable from "./mediaIconsDraggable";

export default
@inject('initialStore', 'mediaStore')
@observer
class PostTemplateMediaIconsConteiner extends React.Component {

    @observable open = false;

    render() {
        const initialStore = this.props.initialStore;

        let classConteyner = 'add-publication__download';
        if (this.props.typePost === 'story' || this.props.typePost === 'igtv' || this.props.typePost === 'reels' || this.props.typePost === 'editStory' || this.props.typePost === 'editIgtv' || this.props.typePost === 'editReels') {
            classConteyner = 'add-publication__history';
        }

        if (initialStore.templates === 'desktop') {

            if (this.props.type === 'obrabotka') {

                return(
                    <div className="column-photo">
                        <div className={classConteyner}>
                            <div className="inner-img">
                                <span className="type-post"><i className="icon icon-post-video"></i></span>
                                {this.props.percent == 'processing' ? <span className="type-post-icons-create"></span> : null}
                                <div className="wrap-img btn-primary-animate">{this.props.icon ? <img src={this.props.icon}/> : null}</div>
                            </div>

                            {this.props.percent == 'processing' ? <span className="reLoadError reLoadError-processing" ><br/>Предварительная<br/>обработка видео</span> : null}

                            {this.props.percent && this.props.percent !== 'processing' ? <span className="percent-download">{this.props.percent}%</span>:null}

                        </div>
                    </div>
                );
            }

            return(
                <div className="column-photo">
                    <div className={classConteyner}>
                        <div className="inner-img">
                            {this.props.iconVideo ? <span className="type-post"><i className="icon icon-post-video"></i></span>:null}
                            {!this.props.durationIcon ? <span className="type-post-icons-create"></span> : null}
                            <div className="wrap-img">{this.props.icon ? <img src={this.props.icon}/> : null}</div>
                        </div>

                        {this.props.errorLoad ? <span className="reLoadError" onClick={this.props.errorLoad}>Ошибка загрузки<br/><span>Повторить</span></span>:null}

                        {this.props.percent ? <span className="percent-download">{this.props.percent}%</span>:null}

                        {this.props.content && !this.props.errorLoad && this.props.durationIcon ?
                            <PostTemplateMediaIconsDraggable
                                editorOpen={this.props.editorOpen}
                                DelElement={this.props.DelElement}
                                DragHandle={this.props.DragHandle}
                            />
                            :null}

                    </div>
                </div>
            );
        } else {

            let classConteyner2 = "column-photo"
            if (this.props.typePost === 'story' || this.props.typePost === 'igtv' || this.props.typePost === 'reels' || this.props.typePost === 'editStory' || this.props.typePost === 'editIgtv' || this.props.typePost === 'editReels') {
                classConteyner2 = "column-photo column-photo-center";
            }

            if (this.props.type === 'obrabotka') {
                return(
                    <div className="column-photo">
                        <div className={classConteyner}>
                            <div className="inner-img">
                                <span className="type-post"><i className="icon icon-post-video"></i></span>
                                {this.props.percent == 'processing' ? <span className="type-post-icons-create"></span> : null}
                                <div className="wrap-img btn-primary-animate">{this.props.icon ? <img src={this.props.icon}/> : null}</div>
                            </div>

                            {this.props.percent == 'processing' ? <span className="reLoadError reLoadError-processing" ><br/>Предварительная<br/>обработка видео</span> : null}

                            {this.props.percent && this.props.percent !== 'processing' ? <span className="percent-download">{this.props.percent}%</span>:null}

                        </div>
                    </div>
                );
            }

            return(
                <div className={classConteyner2}>
                    <div className={classConteyner}>
                        <div className="inner-img">
                            {this.props.iconVideo ? <span className="type-post"><i className="icon icon-post-video"></i></span>:null}
                            {!this.props.durationIcon ? <span className="type-post-icons-create"></span> : null}
                            <div className="wrap-img">{this.props.icon ? <img src={this.props.icon}/> : null}</div>
                        </div>

                        {this.props.errorLoad ? <span className="reLoadError" onClick={this.props.errorLoad}>Ошибка загрузки<br/><span>Повторить</span></span>:null}

                        {this.props.percent ? <span className="percent-download">{this.props.percent}%</span>:null}

                        {this.props.content && !this.props.errorLoad && this.props.durationIcon ?
                            <PostTemplateMediaIconsDraggable
                                editorOpen={this.props.editorOpen}
                                DelElement={this.props.DelElement}
                                DragHandle={this.props.DragHandle}
                            />
                            :null}

                    </div>
                </div>
            );
        }

    }

    openElementsEvent = () => {
        if (!this.open) {
            this.open = true;

            setTimeout(() => {
                this.open = false;
            }, 5000);
        }
    }


}

