import React from 'react';
import {inject, observer} from "mobx-react";

@inject("mediaStore", "accountsStore")
@observer
class ButtonMobileOpenAccounts extends React.Component {

    constructor (props) {
        super(props);
    }

    render() {
        const mediaStore = this.props.mediaStore;
        const accountsStore = this.props.accountsStore;

        if (mediaStore.type === 'edit' || mediaStore.type === 'editStory' || mediaStore.type === 'editIgtv' || mediaStore.type === 'editReels') {
            return null;
        }

        if (!mediaStore.accounts.slice().length) {
            return (
                <div onClick={this.open} className="sidebar-burger view-2 sidebar-burger_non_image">
                    <span className="count-burger">{mediaStore.accounts.slice().length}</span>
                </div>
            );
        } else if (mediaStore.accounts.slice().length === 1) {
            let accountID = mediaStore.accounts[0].id;
            let img = null;
            for (const account of accountsStore.list.slice()) {
                if (account.id === accountID) {
                    img = account.img;
                    break;
                }
            }

            return (
                <div onClick={this.open} className="sidebar-burger view-2" style={{backgroundImage: 'none'}}>
                    <div className="sidebar-burger-icon-account"><img src={img}/></div>
                    <span className="count-burger">{mediaStore.accounts.slice().length}</span>
                </div>
            );
        } else if (mediaStore.accounts.slice().length === 2) {
            let accountID1 = mediaStore.accounts[0].id;
            let accountID2 = mediaStore.accounts[1].id;

            let img1 = null;
            let img2 = null;
            for (const account of accountsStore.list.slice()) {
                if (account.id === accountID1) {
                    img1 = account.img;
                }
                if (account.id === accountID2) {
                    img2 = account.img;
                }

                if (img1 !== null && img2 !== null) {
                    break;
                }
            }

            return (
                <div onClick={this.open} className="sidebar-burger view-2" style={{backgroundImage: 'none'}}>
                    <div className="sidebar-burger-icon-account sidebar-burger-icon-account">
                        <img src={img2} className="sidebar-burger-icon-account-img-2"/>
                        <img src={img1} className="sidebar-burger-icon-account-img-1"/>
                    </div>
                    <span className="count-burger count-burger-2">{mediaStore.accounts.slice().length}</span>
                </div>
            );
        } else {
            let accountID1 = mediaStore.accounts[0].id;
            let accountID2 = mediaStore.accounts[1].id;
            let accountID3 = mediaStore.accounts[2].id;

            let img1 = null;
            let img2 = null;
            let img3 = null;
            for (const account of accountsStore.list.slice()) {
                if (account.id === accountID1) {
                    img1 = account.img;
                }
                if (account.id === accountID2) {
                    img2 = account.img;
                }
                if (account.id === accountID3) {
                    img3 = account.img;
                }

                if (img1 !== null && img2 !== null && img3 !== null) {
                    break;
                }
            }

            return (
                <div onClick={this.open} className="sidebar-burger view-2" style={{backgroundImage: 'none'}}>
                    <div className="sidebar-burger-icon-account sidebar-burger-icon-account">
                        <img src={img3} className="sidebar-burger-icon-account-img-5"/>
                        <img src={img2} className="sidebar-burger-icon-account-img-4"/>
                        <img src={img1} className="sidebar-burger-icon-account-img-3"/>
                    </div>
                    <span className="count-burger count-burger-3">{mediaStore.accounts.slice().length}</span>
                </div>
            );
        }


    }

    componentDidMount() {
        const mediaStore = this.props.mediaStore;

        if (mediaStore.type === 'edit' || mediaStore.type === 'editStory' || mediaStore.type === 'editIgtv' || mediaStore.type === 'editReels') {
            return null;
        }

        //открываем меню если нет аккаунтов выделенных
        if (!mediaStore.accounts.slice().length) {
            setTimeout(() => {
                this.open();
            }, 500);
        }
    }

    open = () => {

        const mediaStore = this.props.mediaStore;

        if (mediaStore.openMenuMobile) {
            mediaStore.actionOpenMenuMobile(false);
        } else {
            mediaStore.actionOpenMenuMobile(true);
        }
    }

}

export default ButtonMobileOpenAccounts;
