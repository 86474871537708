import React from "react";
import img1 from "../media/instruction/telegram/1.png";
import img2 from "../media/instruction/telegram/2.png";
import img4 from "../media/instruction/telegram/4.png";
import img5 from "../media/instruction/telegram/5.png";
import img6 from "../media/instruction/telegram/6.png";
import img12 from "../media/instruction/telegram/12.png";
import img8 from "../media/instruction/telegram/8.png";
import img9 from "../media/instruction/telegram/9.png";
import img10 from "../media/instruction/telegram/10.png";
import img3 from "../media/instruction/telegram/3.png";

const ru = {
    0: 'Добавить аккаунт',
    1: 'Закрыть',
    2: 'Телефон',
    3: 'Почта',
    4: 'Ошибка',
    5: 'Идет процесс добавления аккаунта, подождите пока он будет завершен.',
    6: 'Добавление аккаунта',
    7: 'Началось добавление аккаунта, подождите, это может занять до 5 минут.',
    8: 'Ошибка обработки, неизвестный ответ: ',
    9: 'Неизвестный вариант ответа типа операции',
    10: 'Аккаунт добавлен',
    11: 'Запланируйте в него первый пост.',
    12: 'Откройте приложение Instagram, обновите ленту и подтвердите что это Вы авторизовываетесь. После этого закройте окно добавления аккаунта и повторите попытку добавления аккаунта.',
    13: 'Авторизация в Instagram',
    14: <><b>Ваша безопасность — наша главная забота</b><br/>
        Ваш пароль не будет храниться после входа.<br/>
        Мы запрашиваем пароль, чтобы установить соединение с Instagram.</>,
    15: 'Логин',
    16: 'Пароль',
    17: 'Добавление Instagram',
    18: 'Добавить прокси',
    19: 'Удалить прокси',
    20: 'IP адрес',
    21: 'Порт',
    22: <>Вам был отправлен код на телефон,<br/>
        введите его в поле ниже.<br/>
        Перед отправкой кода, зайдите в приложение Instagram и обновите ленту, если будет запрос авторизации, подтвердите что это Вы.<br/>
        Это производит авторизацию сервис.<br/>
        Если код не приходит, Вы можете ввести "Резервный код"</>,
    23: 'Код',
    24: <>Выберите куда отправить код подтверждения.<br/>
        Зайдите в приложение Instagram и обновите ленту, если будет запрос авторизации, подтвердите что это Вы.<br/>
        Это производит авторизаацию сервис.</>,
    25: 'Тип подтверждения',
    26: <>Вам был отправлен код,<br/>
        введите его в поле ниже.<br/>
        Перед отправкой кода, зайдите в приложение Instagram и обновите ленту, если будет запрос авторизации, подтвердите что это Вы.<br/>
        Это производит авторизаацию сервис.</>,
    27: 'Код',
    28: 'Аккаунты успешно добавлены',
    29: 'Добавление ВКонтакте',
    30: 'Для добавления аккаунта из социальной сети ВКонтакте, нажмите кнопку ниже и предоставьте все необходимые права нашему приложению.',
    31: 'ВКонтакте',
    32: 'Отмена',
    33: 'Авторизация ВКонтакте',
    34: <>Для повторной авторизации аккаунта ВКонтакте,
        вначале удоставертись что вы авторизованы на сайте vk.com под тем же пользователем под каким были во время добавления данного аккаунта.
        После этого, нажмите кнопку снизу и предоставьте все необходимые права для нашего приложения.
        По оканчанию Вам будет предложено выбрать аккаунты, выберите аккаунты которые уже добавлены на сервис.</>,
    35: 'Добавление Одноклассники',
    36: <>Для добавления аккаунта из социальной сети Одноклассники, нажмите кнопку ниже и предоставьте все необходимые права нашему приложению.</>,
    37: 'Одноклассники',
    38: 'Авторизация Одноклассники',
    39: <>Для повторной авторизации аккаунта Одноклассники,
        вначале удоставертись что вы авторизованы на ok.ru под тем же пользователем под каким были во время добавления данного аккаунта.
        После этого, нажмите кнопку снизу и предоставьте все необходимые права для нашего приложения.
        По оканчанию Вам будет предложено выбрать аккаунты, выберите аккаунты которые уже добавлены на сервис.</>,
    40: 'Добавление Facebook',
    41: <>Для добавления аккаунта из социальной сети Facebook, нажмите кнопку ниже, предоставьте все необходимые права нашему приложению и выберите все необходимые страницы.</>,
    42: 'Facebook',
    43: 'Авторизация Facebook',
    44: <>Для повторной авторизации аккаунта Facebook,
        вначале удоставертись что вы авторизованы на facebook.com под тем же пользователем под каким были во время добавления данного аккаунта.
        После этого, нажмите кнопку снизу и предоставьте все необходимые права для нашего приложения.
        По оканчанию Вам будет предложено выбрать аккаунты, выберите аккаунты которые уже добавлены на сервис.</>,
    45: 'Добавление Telegram',
    46: 'Можно добавить Канал или Группу.',
    47: 'Токен бота',
    48: 'Логин или ID',
    49: 'Как добавить?',
    50: 'Скрыть',
    51: <>
        <p>Для начала нам потребуется Telegram бот через которого сервис будет публиковать в ваш аккаунт посты.</p>
        <p>
            Для создания бота, найдите @BotFather в телеграм, это главный бот через которого можно создавать других ботов.
            <img src={img1} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>
            Отправьте ему команду "/newbot"
            <img src={img2} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>
            Придумайте имя бота и отправьте ему в ответ.
            <img src={img4} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>Теперь Вам надо создать имя бота, должно быть на английском языке и заканчиваться должно на «bot», например "tetris_bot"</p>
        <p>
            Если имя не занято, то вы должны получить сообщение что бот успешно создан, в теле сообщения должен присутствовать Токен бота.
            <img src={img5} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>Отлично, наш бот готов!</p>
        <br/>
        <p>Теперь нашего бота надо добавить в администраторы Канала или Группы.</p>
        <p>
            Открываем информацию о Канале или Группе, нажимаем кнопку сверху Настройки и выбираем первый пункт Управление каналом/Управление группой.
            <img src={img6} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>
            В открывшемся окне выбираем пункт Администраторы.
            <img src={img12} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>
            Внизу нажимаем кнопку Добавить администратора.
            <img src={img8} width={200} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>
            Вводим в поиске имя нашего бота, как он найдется нажимаем на него и добавляем его в администраторы.
            <img src={img9} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>
            Выдайте минимальные возможности для Канала "Публикация сообшений" а для Группы можете выдать самые минимальные права.
            <img src={img10} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>

        <p>Самое главное мы сделали, теперь нам надо добавить данные в сервис, в поле "Токен бота" вставляем Токен который выдал нам @BotFather по окончанию создания бота, а в поле "Логин или ID" нам надо вставить логин канала если он открытый или его ссылку которую можно скопировать в информации о канале.</p>
        <p>Если вы добавляете закрытый канал или группу то тогда у вас нет открытого логина, надо добавить ID.</p>
        <p>
            Что бы получить ID Канала или Группы, добавьте в нее нашего бота @likepro_ru_bot, в случаи группы бота можно добавить только как администратора. После чего напишите в добавляемый Канал или Группу сообщение «/start@likepro_ru_bot» в ответ Вам придет сообщение с ID. Вставьте этот ID, (только цифры) в сервис и нажмите кнопку Добавить.
            <img src={img3} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>Если Вы все сделали правильно, аккаунт будет успешно добавлено, если возникнут ошибки то проверьте все ли вы правильно сделали, а если возникнут трудности то пишите в чат поддержки, мы Вам постараемся поможем.</p>
    </>,
    52: 'Добавление Twitter',
    53: 'Авторизация Twitter',
    54: <>Для добавления аккаунта из социальной сети Twitter, нажмите кнопку ниже и предоставьте все необходимые права нашему приложению.</>,
    55: 'Twitter',
    56: <>Для повторной авторизации аккаунта Twitter,
        вначале удоставертись что вы авторизованы на сайте twitter.com под нужный пользователем.
        После этого, нажмите кнопку снизу и предоставьте все необходимые права нашему приложения.</>,
    57: <>Введите код аутентификации из приложения.<br/>
        Введите шестезначный код из вашего приложения для аутентификации (Duo Mobile, Google Authenticator или другого установленного вами приложения).<br/>
        Перед отправкой кода, зайдите в приложение Instagram и обновите ленту, если будет запрос авторизации, подтвердите что это Вы.<br/>
        Это производит авторизацию сервис.</>,
    58: 'Добавление Instagram',
    59: 'Для добавления бизнес аккаунта, он должен быть привязан к Facebook странице. Нажмите на кнопку ниже и на странице Facebook выберите нужные аккаунты Instagram и их страницы',
    60: 'Instagram Business',
}

const en = {
    0: 'Add account',
    1: 'Close',
    2: 'Phone',
    3: 'Mail',
    4: 'Error',
    5: 'The process of adding an account is in progress, please wait until it is completed.',
    6: 'Adding an account',
    7: 'Account adding has started, please wait, it may take up to 5 minutes.',
    8: 'Processing error, unknown response: ',
    9: 'Unknown response type of operation',
    10: 'Account added',
    11: 'Plan your first post in it.',
    12: 'Open the Instagram app, refresh the feed and confirm that you are logging in. After that, close the window for adding an account and try to add an account again.',
    13: 'Instagram authorization',
    14: <><b>Your safety is our main concern</b><br/>
        Your password will not be stored after login.<br/>
        We ask for a password to connect to Instagram.</>,
    15: 'Login',
    16: 'Password',
    17: 'Adding Instagram',
    18: 'Add proxy',
    19: 'Remove proxy',
    20: 'IP address',
    21: 'Port',
    22: <>You have been sent a code to your phone,<br/>
        enter it in the box below.<br/>
        Before sending the code, go to the Instagram application and update the feed, if there is a request for authorization, confirm that it is you.<br/>
        This will authorize the service.<br/>
        If the code does not come, you can enter the "Reserve code"</>,
    23: 'Code',
    24: <>Choose where to send the verification code.<br/>
        Go to the Instagram application and update the feed, if there is a request for authorization, confirm that it is you.<br/>
        This will authorize the service.</>,
    25: 'Confirmation type',
    26: <>You have been sent a code,<br/>
        enter it in the box below.<br/>
        Before sending the code, go to the Instagram application and update the feed, if there is a request for authorization, confirm that it is you.<br/>
        This will authorize the service.</>,
    27: 'Code',
    28: 'Accounts added successfully',
    29: 'Adding VKontakte',
    30: 'To add an account from the social network VKontakte, click the button below and grant all the necessary rights to our application.',
    31: 'VKontakte',
    32: 'Cancellation',
    33: 'VKontakte login',
    34: <>To re-authorize your VKontakte account,
        first make sure that you are authorized on the vk.com website under the same user as you were when you added this account.
        After that, click the button below and grant all the necessary rights for our application.
        Upon completion, you will be prompted to select accounts, select the accounts that have already been added to the service.</>,
    35: 'Adding Odnoklassniki',
    36: <>To add an account from the Odnoklassniki social network, click the button below and grant all the necessary rights to our application.</>,
    37: 'Odnoklassniki',
    38: 'Odnoklassniki login',
    39: <>To re-authorize your Odnoklassniki account,
        first, make sure that you are authorized on ok.ru under the same user as you were when you added this account.
        After that, click the button below and grant all the necessary rights for our application.
        Upon completion, you will be prompted to select accounts, select the accounts that have already been added to the service.</>,
    40: 'Adding Facebook',
    41: <>To add an account from the social network Facebook, click the button below, grant all the necessary rights to our application and select all the necessary pages.</>,
    42: 'Facebook',
    43: 'Facebook login',
    44: <>To re-authorize your Facebook account,
        first, make sure that you are logged in to facebook.com under the same user as you were when you added this account.
        After that, click the button below and grant all the necessary rights for our application.
        Upon completion, you will be prompted to select accounts, select the accounts that have already been added to the service.</>,
    45: 'Adding Telegram',
    46: 'You can add a Channel or Group.',
    47: 'Bot token',
    48: 'Login or ID',
    49: 'How to add?',
    50: 'Hide',
    51: <>
        <p>First, we need a Telegram bot through which the service will publish posts to your account.</p>
        <p>
            To create a bot, find @BotFather in telegram, this is the main bot through which you can create other bots.<br/>
            <img src={img1} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>
            Send him the command "/newbot"
            <img src={img2} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>
            Come up with a name for the bot and send it back.
            <img src={img4} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>Now you need to create a bot name, it must be in English and must end with "bot", for example "tetris_bot"</p>
        <p>
            If the name is not used, then you should receive a message that the bot was successfully created; the bot's Token must be present in the message body.
            <img src={img5} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>Great, our bot is ready!</p>
        <br/>
        <p>Now our bot needs to be added to the Channel or Group administrators.</p>
        <p>
            Open the information about the Channel or Group, click the button on top of Settings and select the first item Channel Management / Group Management.
            <img src={img6} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>
            In the window that opens, select the Administrators item.<br/>
            <img src={img12} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>
            At the bottom, click the Add administrator button.
            <img src={img8} width={200} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>
            We enter the name of our bot in the search, as it is found, click on it and add it to the administrators.
            <img src={img9} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>
            Give the minimum options for the Posting Channel and for the Group you can give the minimum rights.
            <img src={img10} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>

        <p>The most important thing we have done, now we need to add data to the service, in the "Bot Token" field we insert the Token that @BotFather gave us at the end of the bot creation, and in the "Login or ID" field we need to insert the channel login if it is open or its link which can be copied in the channel information.</p>
        <p>If you add a private channel or group, then you do not have an open login, you need to add an ID.</p>
        <p>
            To get the ID of a Channel or Group, add our bot @likepro_ru_bot to it, in cases of a bot group you can add only as an administrator. Then write to the added Channel or Group the message "/start@likepro_ru_bot" and you will receive a message with ID in response. Insert this ID (numbers only) into the service and click the Add button.
            <img src={img3} width={300} style={{margin: '10px', borderRadius: '4px', border: '1px solid #c7c7c7'}}/>
        </p>
        <p>If you did everything correctly, the account will be successfully added, if errors occur, then check if you did everything correctly, and if you have any difficulties, write to the support chat, we will try to help you.</p>
    </>,
    52: 'Adding Twitter',
    53: 'Twitter login',
    54: <>To add an account from the social network Twitter, click the button below and grant all the necessary rights to our application.</>,
    55: 'Twitter',
    56: <>To re-authorize your Twitter account,
        first, make sure that you are logged in on twitter.com under the desired user.
        After that, click the button below and grant all the necessary rights to our application.</>,
    57: <>Enter the authentication code from the app.<br/>
        Enter the six-digit code from your authentication app (Duo Mobile, Google Authenticator, or another app you installed).<br/>
        Before sending the code, go to the Instagram application and update the feed, if there is a request for authorization, confirm that it is you.<br/>
        This will authorize the service.</>,
    58: 'Adding to Instagram',
    59: 'To add a business account, it must be linked to a Facebook page. Click on the button below and on the Facebook page, select the desired Instagram accounts and their pages',
    60: 'Instagram Business',
}

const addAccount = {
    ru: ru,
    en: en,
}

export default addAccount;
