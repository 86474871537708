import React from 'react';

import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ReactDOM from "react-dom";

@inject('editorStore', 'initialStore')
@observer
class MediaRedBColorTextBackground extends React.Component {

    @observable openMenu = false;

    constructor (props) {
        super(props);
        this.fabric = this.props.fabric;
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.window = React.createRef();
        this.openHistory = false;
    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.addPost;

        if (this.openMenu && !this.openHistory) {
            this.updateStatusCloseFon();
            this.openHistory = true;
        }

        if (!this.openMenu && this.openHistory) {
            this.updateStatusCloseFon();
            this.openHistory = false;
        }

        let classes = this.openMenu ? "event-menu g-center v-bottom show botton-element-color-box" : "event-menu g-center v-bottom show botton-element-color-box event-menu-none";

        const styles = [
            {color: '#fefefe'},
            {color: '#000000'},
            {color: '#5395e9'},
            {color: '#83be5f'},
            {color: '#f5cc6e'},
            {color: '#ee9248'},
            {color: '#db565b'},
            {color: '#bf2a68'},
            {color: '#9520b3'},
            {color: '#d92f27'},
            {color: '#df8a8f'},
            {color: '#f7d3d3'},
            {color: '#f9dcb9'},
            {color: '#f5c58b'},
            {color: '#c89253'},
            {color: '#916640'},
            {color: '#3f2425'},
            {color: '#28492c'},
            {color: '#262626'},
            {color: '#363636'},
            {color: '#555555'},
            {color: '#737373'},
            {color: '#999999'},
            {color: '#b2b2b2'},
            {color: '#c7c7c7'},
            {color: '#dbdbdb'},
            {color: '#efefef'},
        ];

        const elemensColors = [];
        elemensColors.push(<div className="button-del-beckground-color" onClick={() => this.color('')}>{text[139]}</div>);
        for (let element of styles) {
            elemensColors.push(<span className={element.color === "#fefefe" ? "item-colors white" : "item-colors"} style={{backgroundColor: element.color}} onClick={() => this.color(element.color)}></span>);
        }

        return (
            <>
                <div className="item-type">
                    <button type="button" className="btn red-button-color-fon" onClick={this.open}></button>

                    <div className={classes}>
                        <div className="event-colors" ref={this.window}>
                            {elemensColors}
                        </div>
                    </div>

                </div>
            </>
        );
    }

    open = () => {
        if (!this.openMenu) {
            this.openMenu = true;
        }
    }

    color = (color) => {
        this.fabric.elementTextBackground(color);
        this.openMenu = false;
    }

    updateStatusCloseFon = () => {
        if (this.openMenu) {
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                if (this.openMenu !== false) {
                    this.openMenu = false;
                }
            }
        } catch(error) {
            return null;
        }
    }
}

export default MediaRedBColorTextBackground;