import React from 'react';
import {inject, observer} from "mobx-react";
import RateIndexActiveStandart from "../../templates/rate/activeStandart";
import {observable} from "mobx";
import LoaderComponent from "../../templates/loaderComponent";
import APIrate from "../../api/rate";
import Time from "../../model/time";
import APIuser from "../../api/user";
import RateIndexActiveIOS from "../../templates/rate/activeIOS";

@inject('initialStore', 'menuStore', 'modalTarifStore', 'accountsStore', 'notificationStore')
@observer
class RateActive extends React.Component {

    @observable date = false;

    @observable accounts = 5;
    @observable maunt = 1;

    @observable payStart = false;

    money = false;

    onlineReInfoRef = null;

    @observable openRate = false;
    @observable openRateInfo = false;


    useriOStest = 1;

    render() {

        const modalTarifStore = this.props.modalTarifStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.rate;

        if (window.payInfoPrice) {
            let moneyd = window.payInfoPrice(5, 1);

            if (moneyd === '449,00 ₽') {
                //не надо
            } else if (moneyd === '449,00 ₽') {
                //не надо
            } else if (moneyd === null) {
                //не надо
            } else {
                //надо
                this.useriOStest = initialStore.idUser;
            }
        }

        //Тарифы для IOS
        const optionsTarifAccountiOS = [
            {key: 5, value: text[41][0]},
            {key: 10, value: text[41][1]},
            {key: 25, value: text[41][2]},
            {key: 50, value: text[41][3]},
            {key: 100, value: text[41][4]},
        ];
        let optionsTarifIOS = [
            {key: 1, value: text[47][0]},
            {key: 3, value: text[47][1]},
            {key: 6, value: text[47][2]},
            {key: 12, value: text[47][3]},
        ];

        if (!this.date) {
            return <LoaderComponent/>;
        }

        const optionsTarif = [
            {key: 1, value: text[0][0]},
            {key: 3, value: text[0][1]},
            {key: 6, value: text[0][2]},
            {key: 12, value: text[0][3]},
        ];

        const time = Time.convertUnixToDateRate(this.date.time);

        const timeOstatokInDey = this.date.time > (new Date()/1000) ? Math.floor((this.date.time - (new Date()/1000))/86400) : 0;

        let newTimeRate = this.newTimeTarof(this.accounts, 0);

        let tarifTimeReal = this.newTimeTarof(this.date.account, this.maunt);

        if (this.date.account < 5 || !time) {
            tarifTimeReal = this.newTimeTarof(this.accounts, this.maunt);
        }

        //просчет цены тарифа

        let money = this.moneyTarif(this.accounts, 1);

        let realMoney = this.moneyTarif(this.date.account, this.maunt);

        if (this.date.account < 5 || !time) {
            realMoney = this.moneyTarif(this.accounts, this.maunt);
        }

        let title = text[1];
        let buttonName = text[2];

        if (this.date.account < this.accounts && time) {
            title = text[3];
        } else if (this.date.account > this.accounts && time) {
            title = text[4];
            //money = 0;
            buttonName=text[5];
        }

        if (!time) {
            title = text[6];
        }

        let error = false;

        if (this.accounts < this.date.accountActive) {
            error=text[7];
        }

        if (this.accounts < 5) {
            error=text[8];
        }

        if (this.accounts > 500) {
            error=text[9];
        }
        let moneyProps = money + ' ₽';
        let moneyPropsReal = realMoney + ' ₽';
        this.money = money;

        /**/

        let accountNextRate = this.date.account;
        if (initialStore.platform !== 'web' && initialStore.idUser === this.useriOStest) {
            if (this.date.account <= 3) {
                accountNextRate = 3;
            } else if (this.date.account <= 5) {
                accountNextRate = 5;
            } else if (this.date.account <= 10) {
                accountNextRate = 10;
            } else if (this.date.account <= 25) {
                accountNextRate = 25;
            } else if (this.date.account <= 50) {
                accountNextRate = 50;
            } else if (this.date.account <= 100) {
                accountNextRate = 100;
            }
        }

        if (money > 0) {
            for (let i = 0; i< optionsTarifAccountiOS.length; i++) {
                if (optionsTarifAccountiOS[i].key === this.accounts) {

                    if (window.payInfoPrice && initialStore.idUser === this.useriOStest) {
                        moneyProps = window.payInfoPrice(this.accounts, this.maunt);
                        moneyPropsReal = window.payInfoPrice(accountNextRate < 5 || !time ? this.accounts:accountNextRate, this.maunt);
                    }
                }
            }

        }
        //> 500 || this.props.formAccount < 5

        let errorRedRate = 0;

        let formAccountRed = false;

        if (this.accounts > 500 || this.accounts < 5) {
            formAccountRed = true;
            errorRedRate = 1;
        }

        if (this.accounts < this.date.accountActive) {
            formAccountRed = true;
            errorRedRate = 2;
        }

        const props = {
            account: this.date.account,
            accountActive: this.date.accountActive,
            time: time,
            timeOstatokInDey: timeOstatokInDey,
            formAccount: this.accounts,
            formChange: this.formChange,
            formAccountPlas: this.formAccountPlas,
            formAccountMinus: this.formAccountMinus,
            selectTarifOptions: optionsTarif,
            selectTarifValue: this.maunt,
            selectTarifAction: this.tarifAction,
            tarifMoney: moneyProps,
            tarifTime: newTimeRate,
            tarifMoneyReal: moneyPropsReal,
            tarifTimeReal: tarifTimeReal,
            title: title,
            error: error,
            buttonName: buttonName,
            formAccountRed: formAccountRed,
            openRate: this.openRate,
            openRateRed: this.openRateRed,
            errorRedRate: errorRedRate,
            buttonClick: () => {

                let accountsPay = this.date.account;
                if (this.date.account < 5 || !time) {
                    accountsPay = this.accounts;
                }
                modalTarifStore.openActive(accountsPay, realMoney, this.maunt);

            },
            buttonClickRed: () => {modalTarifStore.openActiveRed(this.accounts, this.date.account)},
        };

        if (initialStore.platform !== 'web' && initialStore.idUser === this.useriOStest) {

            if (this.accounts === 100) {
                optionsTarifIOS = [
                    {key: 1, value: text[47][0]},
                    {key: 3, value: text[47][1]},
                ];
            }

            return <RateIndexActiveIOS {...props} optionsTarifAccountiOS={optionsTarifAccountiOS} optionsTarifIOS={optionsTarifIOS} payIOS={this.payIOS} payStart={this.payStart}/>;
        } else {
            return <RateIndexActiveStandart {...props}/>;
        }

    }

    openRateRed = () => {
        const initialStore = this.props.initialStore;

        if (this.openRate) {
            this.openRate = false;

            this.accounts = this.date.account >= 5 ? this.date.account : 5;

            if (initialStore.platform !== 'web' && initialStore.idUser === this.useriOStest) {
                if (this.date.account <= 5) {
                    this.accounts = 5;
                } else if (this.date.account <= 10) {
                    this.accounts = 10;
                } else if (this.date.account <= 25) {
                    this.accounts = 25;
                } else if (this.date.account <= 50) {
                    this.accounts = 50;
                } else if (this.date.account <= 100) {
                    this.accounts = 100;
                }
            }

            this.openRateInfo = false;
        } else {
            this.openRate = true;
        }
    }

    payIOS = () => {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.rate;

        let idUser = initialStore.idUser;

        if (this.payStart) {
            notificationStore.add(text[43][0], text[43][1]);
            return false;
        }

        //Если тариф ниже чем надо
        if (this.money <= 0) {
            APIrate.getPayOK(this.accounts, this.maunt).then((date) => {
                if (date.status) {
                    notificationStore.add(text[46][0], text[46][1]);
                } else {
                    notificationStore.add(text[45][0], text[45][1]);
                }
            }).catch(() => {
                notificationStore.add(text[45][0], text[45][1]);
            });

            return true;
        }

        const rePay = (status) => {
            if (status === 0) { //ошибка оплаты
                notificationStore.add(text[45][0], text[45][1]);
            } else if (status === 1) { //оплата прошла но не подтверждена
                notificationStore.add(text[48][0], text[48][1]);
            } else { //оплата подтверждена
                notificationStore.add(text[44][0], text[44][1]);
            }
            this.payStart = false;
        }



        this.payStart = true;

        if (window.pay) {
            window.pay(this.accounts, this.maunt, idUser, rePay);
        } else {
            alert('Non direct platform');
            this.payStart = false;
        }

    }

    newTimeTarof = (accounts, maunt) => {

        //осталось в секундах от прошлого тарифа
        const ostatokSekRate = this.date.time > (new Date()/1000) ? this.date.time - (new Date()/1000) : false;

        //осталось в месяцах от прошлого тарифа
        const ostatokMountRate = ostatokSekRate / 2592000;

        //цена одного месяца текущего тарифа
        const moneyTekushiyTarif = this.moneyTarif(this.date.account, 1);

        const ostatokMoney = Math.ceil(ostatokMountRate * moneyTekushiyTarif);

        //console.log('осталось денег', ostatokMoney);

        const moneyNewTarif = this.moneyTarif(accounts, 1);

        //количество месяцев конвертировано из старого тарифа
        const newRateTimeMaunt = ostatokMoney/moneyNewTarif;

        //количество секунд конвертировано из старого тарифа
        const newRateTimeSek = newRateTimeMaunt * 2592000;

        //количество секунд уже с новым месяцем
        let newRateTimeSekPlasNewTimeTarif = Math.ceil(newRateTimeSek);


        //отнимаем 5% из за смены тарифа
        if (this.date.account !== accounts) {
            newRateTimeSekPlasNewTimeTarif  = Math.ceil(newRateTimeSekPlasNewTimeTarif/100*95);
        }


        if (maunt) {
            //количество секунд уже с новым месяцем
            newRateTimeSekPlasNewTimeTarif = Math.ceil(newRateTimeSekPlasNewTimeTarif + (maunt*2592000));
        }
        //console.log('sek',newRateTimeSekPlasNewTimeTarif);

        return Time.convertUnixToDateRate(newRateTimeSekPlasNewTimeTarif + (new Date()/1000), true);
    }

    moneyTarif = (accounts, mount) => {
        let money = 99;

        if (accounts > 4 && accounts < 501) {
            for (let iAccount = 1; iAccount<= accounts; iAccount++) {

                let plasMoney = 50;

                if (iAccount>20) {
                    plasMoney = 40;
                }

                if (iAccount>30) {
                    plasMoney = 35;
                }

                if (iAccount>100) {
                    plasMoney = 30;
                }

                if (iAccount>200) {
                    plasMoney = 27;
                }


                money+=plasMoney;
            }

            money = money*mount;

            //делим и получаем скидку при покупке более 1 месяца
            if (mount === 3) {
                money = money/100 * 95;
            } else if (mount === 6) {
                money = money/100 * 90;
            } else if (mount === 12) {
                money = money/100 * 85;
            }

            money = Math.ceil(money);
        }

        return money;

    }

    tarifAction = (value) => {
        this.maunt = value;
    }

    formChange = (value, typeInt = false) => {
        const initialStore = this.props.initialStore;

        let valueObp = typeInt ? value : parseInt(value.replace(/\D+/g,""));

        let accaunts = '';
        if (valueObp) {
            accaunts = Number(valueObp);
        }

        if (valueObp === 0) {
            accaunts = 0;
        }

        if (accaunts > 10000) {
            return;
        }

        if (initialStore.platform !== 'web' && initialStore.idUser === this.useriOStest) {
            if (accaunts === 100) {
                if (this.maunt === 6 || this.maunt === 12) {
                    this.maunt = 3;
                }
            }
        }
        //debugger;
        this.accounts = accaunts;
    }

    formAccountPlas = () => {

        if (this.accounts < 4) {
            this.accounts = 4;
        }

        if (this.accounts < 500) {
            this.accounts++;
        }

        if (this.accounts > 500) {
            this.accounts = 500;
        }
    }

    formAccountMinus = () => {

        if (this.accounts > 501) {
            this.accounts = 501;
        }

        if (this.accounts > 5) {
            this.accounts--;
        }

        if (this.accounts < 5) {
            this.accounts = 5;
        }
    }


    componentDidMount() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;
        if (!this.date) {
            APIrate.setActive().then((date) => {

                const time = Time.convertUnixToDateRate(date.time);
                if (!time && date.account < 5) {
                    date.account = 5;
                }

                this.date = date;

                this.accounts = date.account > 5 ? date.account : 5;

                //Редактирование под учет iOS платформы
                //тарификация фиксированная для iOS

                if (initialStore.platform !== 'web' && initialStore.idUser === this.useriOStest) {

                    if (this.accounts <= 5) {
                        this.accounts = 5;
                    } else if (this.accounts <= 10) {
                        this.accounts = 10;
                    } else if (this.accounts <= 25) {
                        this.accounts = 25;
                    } else if (this.accounts <= 50) {
                        this.accounts = 50;
                    } else if (this.accounts <= 100) {
                        this.accounts = 100;
                    }

                }

                this.onlineReInfoRef = this.onlineReInfo();

            }).catch((e) => {
                alert(text[79]);
            });
        }
    }


    componentWillUnmount () {
        clearInterval(this.onlineReInfoRef);
    }

    //обновляем информацию по активному тарифу
    onlineReInfo = () => {
        const accountsStore = this.props.accountsStore;

        let online = false;

        const onlineReInfoRef = setInterval(() => {
            if (!online) {
                online = true;
                APIrate.setActive().then((date) => {


                    const time = Time.convertUnixToDateRate(date.time);

                    if (!time && date.account < 5) {
                        date.account = 5;
                    }

                    if (this.date.account !== date.account || this.date.accountActive !== date.accountActive || this.date.time !== date.time) {
                        this.date = date;

                        APIuser.getLogin(true).then((datas) => {
                            accountsStore.updateInitialGroups(datas.groups);
                            accountsStore.updateInitial(datas.accounts);

                            online = false;
                        }).catch(() => {
                            online = false;
                        });
                    } else {
                        online = false;
                    }


                }).catch(() => {
                    online = false;
                });
            }

        }, 3000);
        return onlineReInfoRef;
    }
}

export default RateActive;
