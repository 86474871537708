import React from 'react';
import {inject, observer} from "mobx-react";

import iconOK from "../../media/svg/rateOK.svg";
import {Link} from "react-router-dom";

export default
@inject('initialStore')
@observer
class RateIndexCharging extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <div className="tariffs">
                        <div className="chief__typical h1">
                            <h1>Изменить тариф</h1>
                        </div>

                        <div className="tariffs-list">

                            <div className="tariffs-list__item">
                                <span className="name-item">PROфессиональный</span>
                                <span className="count-item">от 250 руб.</span>
                                <ul className="list-item">
                                    <li className="rateOK">От 5 до 500 разных аккаунтов</li>
                                    <li className="rateOK">Неограниченное кол-во постов</li>
                                    <li className="rateOK">Отложенный постинг фото и видео</li>
                                    <li className="rateOK">Отложенный постинг историй</li>
                                    <li className="rateOK">Загрузка видео до 200 мб.</li>
                                    <li className="rateOK">История постов</li>
                                    <li className="rateOK">Директ</li>
                                    <li className="rateOK">Статистика</li>
                                    <li className="rateOK">Сотрудники</li>
                                </ul>
                                <div className="more-item">
                                    <Link to={'/rate'} style={{cursor:'pointer'}} type="button" className="btn btn-primary btn-block is-checked">Ваш текущий тариф</Link>
                                </div>
                            </div>

                            <div className="tariffs-list__item" style={{filter: 'blur(4px)'}}>
                                <span className="name-item">Начальный</span>
                                <span className="count-item">от 0 руб.</span>
                                <ul className="list-item">
                                    <li className="rateOK">От 1 до 4 разных аккаунтов</li>
                                    <li className="rateOK">50 бесплатных постов в месяц</li>
                                    <li className="rateOK">Посты за выполнение заданий</li>
                                    <li className="rateOK">Возможность докупить посты</li>
                                    <li className="rateOK">Отложенный постинг фото и видео</li>
                                    <li className="rateOK">Отложенный постинг историй</li>
                                    <li className="rateOK">Загрузка видео до 30 мб.</li>
                                    <li className="rateOFF">История постов</li>
                                    <li className="rateOFF">Директ</li>
                                    <li className="rateOFF">Статистика</li>
                                    <li className="rateOFF">Сотрудники</li>
                                </ul>
                                <div className="more-item">
                                    <button type="button" className="btn btn-primary btn-block">Применить тариф</button>
                                </div>
                            </div>

                        </div>
                    </div>;
        } else {
            return <div className="tariffs">
                <div className="chief__typical h1">
                    <h1>Изменить тариф</h1>
                </div>

                <div className="tariffs-list">

                    <div className="tariffs-list__item">
                        <span className="name-item">PROфессиональный</span>
                        <span className="count-item">от 250 руб.</span>
                        <ul className="list-item">
                            <li className="rateOK">От 5 до 500 разных аккаунтов</li>
                            <li className="rateOK">Неограниченное кол-во постов</li>
                            <li className="rateOK">Отложенный постинг фото и видео</li>
                            <li className="rateOK">Отложенный постинг историй</li>
                            <li className="rateOK">Загрузка видео до 200 мб.</li>
                            <li className="rateOK">История постов</li>
                            <li className="rateOK">Директ</li>
                            <li className="rateOK">Статистика</li>
                            <li className="rateOK">Сотрудники</li>
                        </ul>
                        <div className="more-item">
                            <Link to={'/rate'} style={{cursor:'pointer'}} type="button" className="btn btn-primary btn-block is-checked">Ваш текущий тариф</Link>
                        </div>
                    </div>

                    <div className="tariffs-list__item" style={{filter: 'blur(4px)'}}>
                        <span className="name-item">Начальный</span>
                        <span className="count-item">от 0 руб.</span>
                        <ul className="list-item">
                            <li className="rateOK">От 1 до 4 разных аккаунтов</li>
                            <li className="rateOK">50 бесплатных постов в месяц</li>
                            <li className="rateOK">Посты за выполнение заданий</li>
                            <li className="rateOK">Возможность докупить посты</li>
                            <li className="rateOK">Отложенный постинг фото и видео</li>
                            <li className="rateOK">Отложенный постинг историй</li>
                            <li className="rateOK">Загрузка видео до 30 мб.</li>
                            <li className="rateOFF">История постов</li>
                            <li className="rateOFF">Директ</li>
                            <li className="rateOFF">Статистика</li>
                            <li className="rateOFF">Сотрудники</li>
                        </ul>
                        <div className="more-item">
                            <button type="button" className="btn btn-primary btn-block">Применить тариф</button>
                        </div>
                    </div>

                </div>
            </div>;
        }

    }
}

