import React from 'react';
import {inject, observer} from "mobx-react";
import ReactDOM from "react-dom";

import ButtonLoad from "../../templates/elements/buttonLoad";
import APIaccounts from "../../api/accounts";
import APIuser from "../../api/user";

@inject("notificationStore", "initialStore", "accountsStore")
@observer
class ModalReAutorisation extends React.Component {

    constructor (props) {
        super(props);

        this.close = this.close.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.window = React.createRef();

        this.open = false;
    }

    render() {

        const accountsStore = this.props.accountsStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        if (accountsStore.openModalReAutorisation === false) {
            if (this.open) {
                this.open=false;

                this.updateStatusCloseFon();
            }
            return null;
        }

        if (accountsStore.openModalReAutorisation !== false) {
            this.open=true;
            this.updateStatusCloseFon();
        }

        let accountName = accountsStore.list[accountsStore.active].name;

        const content = <div className="delMediaFile">
            <div className="modal-inner">
                <span className="title-modal">{text[60]}</span>
                <p className="desc-modal">{text[61](accountName)}</p>
                <p className="desc-modal">{text[62]}<br/></p>
                <div className="more-btn">
                    <ButtonLoad type="button" className="btn btn-primary" action={this.reAutorisation} color>reAutorisation</ButtonLoad>
                    <button type="button" className="btn border btn-primary" onClick={this.close}>{text[63]}</button>
                </div>
            </div>
        </div>;

        let render = <>
                <div className="info-wrap">
                    <div className="info-scroll">
                        <div className="info-box-wrap">
                            <div className="info-box chief width-5" ref={this.window}>
                                <span onClick={this.close} className="close-box"><i className="icon icon-close-info"></i></span>
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-overlay"></div>
            </>;

        return render;
    }

    reAutorisation = (resolve) => {
        const accountsStore = this.props.accountsStore;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        let accountID = accountsStore.list[accountsStore.active].id;

        APIaccounts.restart(accountID).then((data) => {
            if (data.status) {

                APIuser.getLogin(true).then((datas) => {
                    accountsStore.updateInitialGroups(datas.groups);
                    accountsStore.updateInitial(datas.accounts);

                    accountsStore.openModalReAutorisation=false;
                    resolve(true);

                    notificationStore.add(text[64], '');
                }).catch(() => {
                    notificationStore.add(text[64], text[65]);
                    accountsStore.openModalReAutorisation=false;
                    resolve(true);
                });

            } else {
                notificationStore.add(text[66], initialStore.text.code[data.code]);
                resolve(true);
            }
        }).catch(() => {
            notificationStore.add(text[66], text[67]);
            resolve(true);
        });
    }

    updateStatusCloseFon = () => {

        const initialStore = this.props.initialStore;

        let type = 'click';
        if (initialStore.templates !== 'desktop') {
            type = 'touchend';
        }
        if (this.open) {
            document.addEventListener(type, this.handleClickOutside, false);
        } else {
            document.removeEventListener(type, this.handleClickOutside, false);
        }
    }

    close () {

        const accountsStore = this.props.accountsStore;
        accountsStore.openModalReAutorisation = false;
    }


    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов

                const accountsStore = this.props.accountsStore;

                if (accountsStore.openModalReAutorisation !== false) {
                    accountsStore.openModalReAutorisation = false;
                }
            }
        } catch(error) {
            return null;
        }
    }

}

export default ModalReAutorisation;