import React from 'react';
import {inject, observer} from "mobx-react";
import ButtonConfirmation from "../../elements/buttonDel";
import Button from "../../elements/button";
import Input from "../../elements/input";
import ButtonLoad from "../../elements/buttonLoad";
import ButtonDel from "../../elements/buttonDel";

export default
@inject('initialStore')
@observer
class AccountPatternsElement extends React.Component {

    render() {
        const initialStore = this.props.initialStore;

        if (initialStore.templates === 'desktop') {
            return <div className="message-templates__item">
                        <span className="title-item">{this.props.name}</span>
                        <p className="desc-item">{this.props.text}</p>
                        <span className="control-item">
                            <i onClick={this.props.redAction} className="icon icon-settings-edit"></i>
                            <ButtonDel icon={true} action={this.props.delAction}>accountPatterns</ButtonDel>
                        </span>
                    </div>;
        } else {
            return <div className="message-templates__item">
                <span className="title-item">{this.props.name}</span>
                <p className="desc-item">{this.props.text}</p>
                <span className="control-item">
                            <i onClick={this.props.redAction} className="icon icon-settings-edit"></i>
                            <ButtonDel icon={true} action={this.props.delAction}>accountPatterns</ButtonDel>
                        </span>
            </div>;
        }

    }
}

