import React from 'react';
import {inject, observer} from "mobx-react";
import Button from "../../templates/elements/button";
import {observable} from "mobx";
import APIrate from "../../api/rate";

@inject('modalTarifStore', 'notificationStore', 'initialStore')
@observer
class ModalTarifRed extends React.Component{

    @observable load = false;

    constructor (props) {
        super(props);
    }

    render() {

        const modalTarifStore = this.props.modalTarifStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.rate;

        if (!modalTarifStore.openRed) {
            return null;
        }

        let content = <>
            <span className="title-box center">{text[35]}</span>

            <div className="tariff-purchase">
                <p className="help-purchase">{text[49](modalTarifStore.accounts, modalTarifStore.accountsBil)}</p>
                <div className="more-btn btns-box">

                    <Button load={this.load} action={this.ok} className="btn border btn-primary btn-block" name={text[50]}/>

                </div>

            </div>

            <div className="more-btn btns-box">
                <button onClick={this.close} type="button" className="btn border btn-primary btn-block">{text[34]}</button>
            </div>
        </>;

        if (modalTarifStore.accounts === modalTarifStore.accountsBil) {
            content = <>
                <span className="title-box center">{text[35]}</span>

                <div className="tariff-purchase">
                    <p className="help-purchase">{text[51]}</p>
                    <div className="more-btn btns-box">

                    </div>

                </div>

                <div className="more-btn btns-box">
                    <button onClick={this.close} type="button" className="btn border btn-primary btn-block">{text[34]}</button>
                </div>
            </>;
        }




        let render = <>
                <div className="info-wrap">
                    <div className="info-scroll">
                        <div className="info-box-wrap">
                            <div className="info-box chief width-3">
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-overlay"></div>
            </>;

        return render;
    }

    close = () => {
        const modalTarifStore = this.props.modalTarifStore;
        modalTarifStore.openRed = false;
    }

    ok = () => {
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const modalTarifStore = this.props.modalTarifStore;
        const code = initialStore.text.code;

        this.load = true;

        APIrate.red(modalTarifStore.accounts).then((date) => {
            if (date.status) {
                notificationStore.add(code[147], code[148]);
            } else {
                notificationStore.add(code[28], code[date.code]);
            }
        }).catch((e) => {
            notificationStore.add(code[28]);
        }).finally(() => {
            this.load = false;

            const modalTarifStore = this.props.modalTarifStore;
            modalTarifStore.openRed = false;
        });
    }

}

export default ModalTarifRed;