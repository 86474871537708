import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ReactDOM from "react-dom";

@inject('accountsStore', 'menuStore', 'initialStore')
@observer
class TimeBox extends React.Component {

    @observable open = false;

    constructor(props) {
        super(props);

        this.boxRef = React.createRef();

        this.openList = this.openList.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.clickType = this.clickType.bind(this);
    }

    render() {
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        const time = this.props.time;
        const type = this.props.type;
        const plasUane = time + 1;

        return <li className={this.open ? "is-active": ""}>
                <div className="time-item" onClick={this.openList}>
                    <div ref={this.boxRef} style={{padding: '8px 12px'}}>
                    <span>
                        {"0".substring(time >= 10) + time}:00 - {"0".substring(plasUane >= 10) + plasUane}:00
                        {type > 0 ? <span className="count-item">{type}</span> : null}
                    </span>
                    </div>
                    {this.open ?
                    <div className="event-menu g-center v-bottom show">
                        <span className={type === 0? "event-item event-item-active":"event-item"}  onClick={()=>{this.clickType(0)}}>{text[16]}</span>
                        <span className={type === 1? "event-item event-item-active":"event-item"} onClick={()=>{this.clickType(1)}}>{text[17]}</span>
                        <span className={type === 2? "event-item event-item-active":"event-item"} onClick={()=>{this.clickType(2)}}>{text[18]}</span>
                        <span className={type === 3? "event-item event-item-active":"event-item"} onClick={()=>{this.clickType(3)}}>{text[19]}</span>
                        <span className={type === 4? "event-item event-item-active":"event-item"} onClick={()=>{this.clickType(4)}}>{text[20]}</span>
                        <span className={type === 5? "event-item event-item-active":"event-item"} onClick={()=>{this.clickType(5)}}>{text[21]}</span>
                    </div>
                    :null}
                </div>
            </li>;
    }

    clickType (type) {
        this.open = false;
        this.props.typeRed(this.props.time, type);
    }

    openList () {
        this.open = !this.open;
    }

    handleClickOutside(e) {
        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.boxRef.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент
            if (!node.contains(e.target)) {
                // Если в области кликнутого элемента нету "emojiBlock", то проверяем ниже
                // Не произведен ли клик на кнопку, открывающую окно смайлов
                if (this.open) {
                    this.open = false;
                }
            }
        } catch(error) {
            return null;
        }
    }

    // Вызывается после удаления компонента из DOM
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, false);
    }

    // Вызывается до рендера
    componentWillMount() {
        document.addEventListener('click', this.handleClickOutside, false);
    }
}

export default TimeBox;