import React from "react";
import store from "../stor/mainStore";


//устанавливаем часовой пояс
//moment.tz.setDefault("Etc/UTC+3");

const TextModel = {

    limitedText (text, TextAndComment = '', InstagramTrue = true, VKTrue = false, FBTrue = false, OKTrue = false, TGTrue = false, TwitterTrue = false, FbInstagramTrue = false) {

        let maxSimvol = 63206;
        let maxHest = 0;
        let maxAccount = 0;

        if (InstagramTrue) {
            if (maxSimvol > 2200) maxSimvol = 2200;

            maxHest = 30;
            maxAccount = 20;
        }

        if (FbInstagramTrue) {
            if (maxSimvol > 2200) maxSimvol = 2200;

            maxHest = 30;
            maxAccount = 20;
        }

        if (VKTrue) {
            if (maxSimvol > 15895) maxSimvol = 15895;
        }

        if (FBTrue) {
            if (maxSimvol > 63206) maxSimvol = 63206;
        }

        if (OKTrue) {
            if (maxSimvol > 15895) maxSimvol = 15895;
        }

        if (TGTrue) {
            if (maxSimvol > 4096) maxSimvol = 4096;
        }

        if (TwitterTrue) {
            if (maxSimvol > 280) maxSimvol = 280;
        }



        let status = true;

        let simbolStr = '';
        let heshStr = '';
        let accountStr = '';

        //количество символов
        const simbol = this.lingthStr(text);

        let simbolErrorClass = simbol > maxSimvol ? 'info-text-simbol-error':null;

        if (simbol > maxSimvol) {
            status = false;
        }

        simbolStr = <span className={simbolErrorClass}>{simbol}/{maxSimvol}</span>;

        //количество хэштегов
        if (maxHest) {
            const hesh = this.lingthHesh(TextAndComment);

            let heshErrorClass = hesh > maxHest ? 'info-text-simbol-error':null;

            if (hesh > maxHest) {
                status = false;
            }

            heshStr = <span className={heshErrorClass}> #{hesh}/{maxHest}</span>;
        }

        //количество упоминаний
        if (maxAccount) {
            const account = this.lingthAccount(TextAndComment);

            let accountErrorClass = account > maxAccount ? 'info-text-simbol-error':null;

            if (account > maxAccount) {
                status = false;
            }

            accountStr = <span className={accountErrorClass}> @{account}/{maxAccount}</span>;
        }

        return {
            status: status,
            str: <>{simbolStr}{heshStr}{accountStr}</>,
        }

    },

    limitedName (name) {

        const maxSimvol = 75;

        let status = true;

        let simbolStr = '';
        let heshStr = '';
        let accountStr = '';

        //количество символов
        const simbol = this.lingthStr(name);

        let simbolErrorClass = simbol > maxSimvol ? 'info-text-simbol-error':null;

        if (simbol > maxSimvol) {
            status = false;
        }

        simbolStr = <span className={simbolErrorClass}>{simbol}/{maxSimvol}</span>;

        return {
            status: status,
            str: <>{simbolStr}</>,
        }

    },

    lingthStr (text) {

        if (text === '') return 0;

        let simbol = text.length;

        const enter = text.split('\n');
        for (const enterStr of enter) {
            const str = enterStr.replace(/\s+/g,'');
            if (str === '') {
                simbol+=2;
            }
        }
        return simbol;
    },

    lingthHesh (text) {
        let result = [],
            h;
        for (let i = 0; i < text.length; i++) {
            if (text[i] == "#") {
                h = result.length;
                result[h] = ""
            } else if (/\S/.test(text[i]) && h !== void 0) result[h] += text[i];
            else h = void 0
        }

        let simbol= 0;
        for (const enterStr of result) {

            if (enterStr !== '') {
                simbol++;
            }
        }
        return simbol;
    },

    lingthAccount (text) {
        let result = [],
            h;
        for (let i = 0; i < text.length; i++) {
            if (text[i] == "@") {
                h = result.length;
                result[h] = ""
            } else if (/\S/.test(text[i]) && h !== void 0) result[h] += text[i];
            else h = void 0
        }

        let simbol= 0;
        let accountsArray = [];
        for (const enterStr of result) {

            let sovpadenie = false;
            for (const accountA of accountsArray) {
                if (accountA === enterStr) {
                    sovpadenie = true;
                    continue;
                }
            }

            if (enterStr !== '' && !sovpadenie) {
                accountsArray.push(enterStr);
                simbol++;
            }
        }
        return simbol;
    },

};

export default TextModel;
