import qs from "qs";
import React from "react";
import instance from "./base";


const APImedia = {

    getInfoStatus (keys) {
        return instance.post('posts/status', qs.stringify({
            keys: JSON.stringify(keys),
        })).then(data => {
            return data;
        });
    },



}

export default APImedia;