import React from 'react';
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import style from "../../../component/menu/index.module.css";
import {animated, config, Spring} from "react-spring/renderprops";
import ButtonUrlPosts from "../../../component/menu/ButtonUrlPosts";
import ButtonLibraryPosts from "../../../component/menu/ButtonLibraryPosts";
import ReactDOM from "react-dom";

export default
@inject('initialStore', 'menuStore')
@observer
class MenuConteiner extends React.Component {

    constructor(props) {
        super(props);

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.window = React.createRef();
    }

    render() {
        const initialStore = this.props.initialStore;
        const menuStore = this.props.menuStore;

        if (initialStore.templates === 'desktop') {
            return <div className="sidebar-scroll">
                    {this.props.children}
                    <div className="sidebar-brand">
                        <span className="link-brand">LikePro.ru</span>
                    </div>
            </div>;
        } else {

            if (!menuStore.openMenuMobile) {
                this.updateStatusCloseFon();
            } else {
                this.updateStatusCloseFon();
            }

            let nameLogo = 'LikePro.ru';
            if (initialStore.cordova) {
                nameLogo = 'LikePro';
            }

            return (
                <div className={menuStore.openMenuMobile ? "sidebar-panel sidebar-panel-open" : "sidebar-panel"} ref={this.window}>
                    <div className="sidebar-scroll">
                        {this.props.children}
                        <div className="sidebar-brand">
                            <span className="link-brand">{nameLogo}</span>
                        </div>
                    </div>
                </div>
            );
        }

    }

    updateStatusCloseFon = () => {
        const menuStore = this.props.menuStore;

        if (menuStore.openMenuMobile) {
            document.addEventListener('touchend', this.handleClickOutside, false);
        } else {
            document.removeEventListener('touchend', this.handleClickOutside, false);
        }


    }

    handleClickOutside(e) {

        try {
            // Получаем ссылку на элемент, при клике на который, скрытие не будет происходить
            //const emojiBlock = this.PickerRef.current;
            let node = ReactDOM.findDOMNode(this.window.current);
            // Проверяем, есть ли в списке родительских или дочерних элементов, вышеуказанный компонент

            if (!node.contains(e.target)) {
                // Если клик не производился и на кнопку открытия окна смайлов, то скрываем блок.
                const menuStore = this.props.menuStore;

                menuStore.openMenuMobile = false;
            }

        } catch(error) {
            return null;
        }
    }
}

