import React from 'react';
import {inject, observer} from "mobx-react";
import {Route, Switch} from "react-router";



import Posts from "./component/post/Posts";
import Story from "./component/Story";
import History from "./component/History";
import PostsInstagram from "./component/post/PostsInstagram";
import Statistics from "./component/Statistics";
import Direct from "./component/direct/Direct";
import Patterns from "./component/Patterns";
import Timetable from "./component/Timetable";
import Employees from "./component/Employees";
import Proxy from "./component/Proxy";

import AccountElementsFilter from "../../templates/account/elements/filter";
import AccountElementsFilterElement from "../../templates/account/elements/filterElement";
import Select from "../../templates/elements/select";
import APIaccountsAdd from "../../api/accountsadd";
import APIaccountsOk from "../../api/accountsok";
import APIaccountsFb from "../../api/accountsfb";
import APIsettings from "../../api/settings";
import PostsDate from "./component/post/PostsDate";
import Time from "../../model/time";
import APIaccountsTwitter from "../../api/accountstwitter";

@inject("menuStore", "initialStore", "modalAddAccount", "accountsStore")
@observer
class Account extends React.Component {

    constructor(props) {
        super(props);

        this.topElement = this.topElement.bind(this);

        this.activationAccount = this.activationAccount.bind(this);
    }

    render() {

        const accountsStore = this.props.accountsStore;
        const menuStore = this.props.menuStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;
        let idAccount = accountsStore.active;

        if (idAccount === null) {
            return null;
        }

        const accountsStoreList = accountsStore.list.slice();

        for(let key in accountsStoreList) {
            //debugger;
            if (accountsStore.list[key].id === this.props.match.params.idAccount) {
                idAccount = key;
            }
        }

        if (!idAccount) {
            alert('Нет аккаунта');
        }

        let errorLogin = null;

        if (!accountsStore.list[idAccount].rights.rate) {
           if (accountsStore.list[idAccount].rights.root) {
               errorLogin = (
                   <div class="help-box error">
                       <p class="desc-help">{text[80]}</p>
                   </div>
               );
           } else {
               errorLogin = (
                   <div class="help-box error">
                       <p class="desc-help">{text[81]}</p>
                   </div>
               );
           }
        } else {
           const infoStatus = Time.timeFinishRate(accountsStore.list[idAccount].rights.rate);

           if (infoStatus === 3) {
               if (accountsStore.list[idAccount].rights.root) {
                   errorLogin = (
                       <div class="help-box error">
                           <p class="desc-help">{text[82]}</p>
                       </div>
                   );
               } else {
                   errorLogin = (
                       <div class="help-box error">
                           <p class="desc-help">{text[83]}</p>
                       </div>
                   );
               }

           } else if (infoStatus === 2) {
               if (accountsStore.list[idAccount].rights.root) {
                   errorLogin = (
                       <div class="help-box error">
                           <p class="desc-help">{text[84]}</p>
                       </div>
                   );
               } else {
                   errorLogin = (
                       <div class="help-box error">
                           <p class="desc-help">{text[85]}</p>
                       </div>
                   );
               }

           } else if (infoStatus === 1) {
               if (accountsStore.list[idAccount].rights.root) {
                   errorLogin = (
                       <div class="help-box error">
                           <p class="desc-help">{text[86]}</p>
                       </div>
                   );
               } else {
                   errorLogin = (
                       <div class="help-box error">
                           <p class="desc-help">{text[87]}</p>
                       </div>
                   );
               }

           } else if (infoStatus === 0) {
               if (accountsStore.list[idAccount].rights.root) {
                   errorLogin = (
                       <div class="help-box error">
                           <p class="desc-help">{text[88]}</p>
                       </div>
                   );
               } else {
                   errorLogin = (
                       <div class="help-box error">
                           <p class="desc-help">{text[89]}</p>
                       </div>
                   );
               }
           }

        }

        if (accountsStore.list[idAccount].error) {
            if (accountsStore.list[idAccount].tip === 'instagram') {
                if (accountsStore.list[idAccount].rights.root) {
                    errorLogin = (
                        <div class="help-box error">
                            <p class="desc-help">{text[90][0]} <span className="help-box-click" onClick={this.openAutorizationInstagram}>{text[90][1]}</span>.</p>
                        </div>
                    );
                } else {
                    errorLogin = (
                        <div class="help-box error">
                            <p class="desc-help">{text[91]}</p>
                        </div>
                    );
                }

            } else if (accountsStore.list[idAccount].tip === 'vk') {
                if (accountsStore.list[idAccount].rights.root) {
                    errorLogin = (
                        <div class="help-box error">
                            <p class="desc-help">{text[92][0]} <span className="help-box-click" onClick={this.openAutorizationVK}>{text[92][1]}</span>.</p>
                        </div>
                    );
                } else {
                    errorLogin = (
                        <div class="help-box error">
                            <p class="desc-help">{text[93]}</p>
                        </div>
                    );
                }
            } else if (accountsStore.list[idAccount].tip === 'ok') {
                if (accountsStore.list[idAccount].rights.root) {
                    errorLogin = (
                        <div class="help-box error">
                            <p class="desc-help">{text[94][0]} <span className="help-box-click" onClick={this.openAutorizationOK}>{text[94][1]}</span>.</p>
                        </div>
                    );
                } else {
                    errorLogin = (
                        <div class="help-box error">
                            <p class="desc-help">{text[95]}</p>
                        </div>
                    );
                }

            } else if (accountsStore.list[idAccount].tip === 'fb') {
                if (accountsStore.list[idAccount].rights.root) {
                    errorLogin = (
                        <div class="help-box error">
                            <p class="desc-help">{text[96][0]} <span className="help-box-click" onClick={this.openAutorizationFB}>{text[96][1]}</span>.</p>
                        </div>
                    );
                } else {
                    errorLogin = (
                        <div class="help-box error">
                            <p class="desc-help">{text[97]}</p>
                        </div>
                    );
                }
            } else if (accountsStore.list[idAccount].tip === 'tg') {
                if (accountsStore.list[idAccount].rights.root) {
                    errorLogin = (
                        <div class="help-box error">
                            <p class="desc-help">{text[98][0]} <span className="help-box-click" onClick={() => alert('Временно не работает.')}>{text[98][1]}</span>.</p>
                        </div>
                    );
                } else {
                    errorLogin = (
                        <div class="help-box error">
                            <p class="desc-help">{text[99]}</p>
                        </div>
                    );
                }
            } else if (accountsStore.list[idAccount].tip === 'twitter') {
                if (accountsStore.list[idAccount].rights.root) {
                    errorLogin = (
                        <div class="help-box error">
                            <p class="desc-help">{text[149][0]} <span className="help-box-click" onClick={this.openAutorizationTwitter}>{text[149][1]}</span>.</p>
                        </div>
                    );
                } else {
                    errorLogin = (
                        <div class="help-box error">
                            <p class="desc-help">{text[150]}</p>
                        </div>
                    );
                }
            } else if (accountsStore.list[idAccount].tip === 'fb-instagram') {
                if (accountsStore.list[idAccount].rights.root) {
                    errorLogin = (
                        <div class="help-box error">
                            <p class="desc-help">{text[96][0]} <span className="help-box-click" onClick={this.openAutorizationFB}>{text[96][1]}</span>.</p>
                        </div>
                    );
                } else {
                    errorLogin = (
                        <div class="help-box error">
                            <p class="desc-help">{text[97]}</p>
                        </div>
                    );
                }
            }
        }



        const filters = this.topElement();

        let typeList = [
            {key: 0, value: text[100]},
            {key: 1, value: text[101]},
        ];

        if (accountsStore.list[idAccount].tip === 'instagram') {
            typeList.push({key: 2, value: text[102]});
        }

        let typeListValue = accountsStore.list[idAccount].typeList;

        let actionTypeList = (key) => {
            accountsStore.list[idAccount].typeList = key;
            APIsettings.setTypeList(accountsStore.list[idAccount].id, key);
        }



        let PostVisual = null;
        if (typeListValue === 0) {
            PostVisual = Posts;
        } else if (typeListValue === 1) {
            PostVisual = PostsDate;
        } else if (typeListValue === 2) {
            PostVisual = PostsInstagram;
        }

        return <>

            {errorLogin}

            {menuStore.activeComponentAccount === 'post' || menuStore.activeComponentAccount === 'history' ?
            <AccountElementsFilter
                tipPosts={filters}
                activeSearh={menuStore.activeComponentAccount === 'history' ? true:false}
                typeList={typeList}
                typeListValue={typeListValue}
                actionTypeList={actionTypeList}
                nameAccount={accountsStore.list[idAccount].name}
                typeAccount={accountsStore.list[idAccount].tip}
                imgAccount={accountsStore.list[idAccount].img}
            />
            :null}


            <Switch>
                <Route path="/account/:idAccount/history" component={History}></Route>
                {/*<Route path="/account/:idAccount/preview" component={PostsInstagram}></Route>*/}
                <Route path="/account/:idAccount/story" component={Story}></Route>
                <Route path="/account/:idAccount/statistics" component={Statistics}></Route>
                <Route path="/account/:idAccount/direct" component={Direct}></Route>
                <Route path="/account/:idAccount/patterns" component={Patterns}></Route>
                <Route path="/account/:idAccount/timetable/:type" component={Timetable}></Route>
                <Route path="/account/:idAccount/timetable" component={Timetable}></Route>
                <Route path="/account/:idAccount/employees" component={Employees}></Route>
                <Route path="/account/:idAccount/proxy" component={Proxy}></Route>
                <Route path="/account/:idAccount" component={PostVisual}></Route>
            </Switch>
        </>

    }

    openAutorizationTwitter = () => {
        const modalAddAccount = this.props.modalAddAccount;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        APIaccountsTwitter.getUrl().then((data) => {
            if (data.status) {
                modalAddAccount.openTwitter(data.url, 1);
            } else {
                notificationStore.add(text[28], initialStore.text.code[data.code]);
            }
        }).catch(() => {
            notificationStore.add(text[28], text[113]);
        });
    }
    openAutorizationFB = () => {
        const modalAddAccount = this.props.modalAddAccount;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        APIaccountsFb.getFBUrl().then((data) => {
            if (data.status) {
                modalAddAccount.openFb(data.url, 1);
            } else {
                notificationStore.add(text[28], initialStore.text.code[data.code]);
            }
        }).catch(() => {
            notificationStore.add(text[28], text[113]);
        });
    }

    openAutorizationOK = () => {
        const modalAddAccount = this.props.modalAddAccount;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        APIaccountsOk.getOKUrl().then((data) => {
            if (data.status) {
                modalAddAccount.openOk(data.url, 1);
            } else {
                notificationStore.add(text[28], initialStore.text.code[data.code]);
            }
        }).catch(() => {
            notificationStore.add(text[28], text[113]);
        });
    }

    openAutorizationVK = () => {
        const modalAddAccount = this.props.modalAddAccount;
        const notificationStore = this.props.notificationStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        APIaccountsAdd.getVkUrl().then((data) => {
            if (data.status) {
                modalAddAccount.openVk(data.url, 1);
            } else {
                notificationStore.add(text[28], initialStore.text.code[data.code]);
            }
        }).catch(() => {
            notificationStore.add(text[28], text[113]);
        });
    }

    openAutorizationInstagram = () => {
        const accountsStore = this.props.accountsStore;
        const modalAddAccount = this.props.modalAddAccount;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.code;

        let idAccount = accountsStore.active;

        const accountsStoreList = accountsStore.list.slice();

        for(let key in accountsStoreList) {
            //debugger;
            if (accountsStore.list[key].id === this.props.match.params.idAccount) {
                idAccount = key;
            }
        }

        if (!idAccount) {
            alert(text[131]);
        }

        let errorLogin = null;

        modalAddAccount.open = true;
        modalAddAccount.type = 'instagram';
        modalAddAccount.level = 4;
        modalAddAccount.login = accountsStore.list[idAccount].name;
    }

    componentDidMount() {
        this.activationAccount();
    }

    componentDidUpdate() {
        this.activationAccount();
    }

    activationAccount () {
        const accountsStore = this.props.accountsStore;
        const menuStore = this.props.menuStore;

        let idAccount = null;

        const accountsStoreList = accountsStore.list.slice();

        for(let key in accountsStoreList) {
            //debugger;
            if (accountsStore.list[key].id === this.props.match.params.idAccount) {
                idAccount = key;
            }
        }

        if (accountsStore.active !== idAccount)  {

            accountsStore.updateActive(idAccount);
            menuStore.actionOpenSetup(false);

            const initialStore = this.props.initialStore;

            //Скролл на верх
            if (initialStore.refContentInner !== null) {
                if (initialStore.refContentInner.scrollTop) {
                    initialStore.refContentInner.scrollTo(0, 0);
                }
            }

        }
    }

    topElement () {
        const accountsStore = this.props.accountsStore;
        const menuStore = this.props.menuStore;
        const initialStore = this.props.initialStore;
        const text = initialStore.text.account;

        if (accountsStore.active === null) {
            return;
        }


        const id = accountsStore.list[accountsStore.active].id;

        let element;

        if (menuStore.activeComponentAccount === 'post' || menuStore.activeComponentAccount === 'history' || menuStore.activeComponentAccount === 'preview') {
            if (1===1) {
                let post = <AccountElementsFilterElement
                    name={text[103]}
                    active={menuStore.activeComponentAccount === 'post' ? true:false}
                    url={'/account/'+id}
                />;

                let history = <AccountElementsFilterElement
                    name={text[104]}
                    active={menuStore.activeComponentAccount === 'history' ? true:false}
                    url={'/account/'+id + '/history'}
                />;

                element = <>
                    {post}
                    {history}
                </>;
            } else {

                const option = [
                    {key: 0, value:text[103]},
                    {key: 1, value:text[104]},
                ];

                let active = 0;

                if (menuStore.activeComponentAccount === 'history') {
                    active = 1;
                }

                element = <Select
                    nonTopValueElement
                    action={(e) => {
                            if (active === e) return null;
                            if (e === 0) initialStore.redirect('/account/'+id);
                            else if (e === 1) initialStore.redirect('/account/'+id + '/history');
                        }
                    }
                    options={option}
                >{active}</Select>
            }
        }
        return element;
    }



    componentWillUnmount() {

        const accountsStore = this.props.accountsStore;
        const menuStore = this.props.menuStore;

        accountsStore.updateActive(null);
        menuStore.actionOpenSetup(false);
    }
}

export default Account;
